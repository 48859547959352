import { permissions, permissionNames } from "./constants";

export function userIsOrgAdmin() {
  let user = localStorage.getItem("User");
  return user && user.IsOrgAdmin;
}

export function userIsSysAdmin() {
  let user = localStorage.getItem("User");
  return user && user.isSysAdmin;
}

export function getUserName() {
  let user = localStorage.getItem("User");
  if (user === undefined) return "";

  return `${user.First} ${user.Last}`;
}

export function detectPortraitMode() {
  let docHeight = window.innerHeight;
  let docWidth = window.innerWidth;

  if (docWidth < 1380) {
    return true;
  } else {
    let ratio = gcd(docWidth, docHeight);
    let widthAspect = Math.floor(docWidth / ratio);
    let heightAspect = Math.floor(docHeight / ratio);

    return heightAspect > widthAspect;
  }
}

const gcd = (a, b) => {
  return b == 0 ? a : gcd(b, a % b);
};

export function getPermissionNames(userPermissions) {
  let allPermissions = Object.keys(permissions);
  let output = [];
  for (let i = 0; i < allPermissions.length; i++) {
    let bitvalue = userPermissions & permissions[allPermissions[i]];
    if (bitvalue !== 0) {
      output.push(permissionNames[bitvalue]);
    }
  }
  return output;
}

export function userHasPermission(userInfo, permissionName) {
  if (
    userInfo === null ||
    userInfo === undefined ||
    permissionName === null ||
    permissionName === undefined
  ) {
    return false;
  }
  let userPermissions = userInfo.permissions;

  if ((userPermissions & permissions["sysadmin"]) !== 0) {
    return true;
  } else if (
    permissionName.toLowerCase() != "sysadmin" &&
    (userPermissions & permissions["orgadmin"]) !== 0
  ) {
    return true;
  } else if (
    permissionName.toLowerCase() != "sysadmin" &&
    permissionName.toLowerCase() != "orgadmin" &&
    (userPermissions & permissions["poweruser"]) !== 0
  ) {
    return true;
  } else {
    return (userPermissions & permissions[permissionName.toLowerCase()]) !== 0;
  }
}

export function userOnlyHasPermission(userInfo, permissionName) {
  if (
    userInfo === null ||
    userInfo === undefined ||
    permissionName === null ||
    permissionName === undefined
  ) {
    return false;
  }
  let userPermissions = userInfo.permissions;
  let permissionValue = permissions[permissionName.toLowerCase()];
  if (userPermissions == permissionValue) {
    return true;
  }

  return false;
}

export function getRuleFromRuleList(ruleType, ruleList) {
  let retVal;
  if (ruleType && ruleList) {
    let typeLC = ruleType.toLowerCase();
    retVal = ruleList.find((x) => x.ruleType.toLowerCase() === typeLC);
    // ruleList.forEach(rule => {
    //   let rType = rule.ruleType.toLowerCase();
    //   if (rType === typeLC){
    //     retVal = rule;
    //   }
    // });
  }
  return retVal;
}

export function getRulesByTypeAsObject(ruleTypes, ruleList) {
  let obj = {};
  // console.log(ruleList);
  if (ruleList && ruleTypes) {
    ruleList.forEach((rule) => {
      let rType = rule.ruleType.toLowerCase();
      if (ruleTypes.includes(rType) && obj[rType] == undefined) {
        obj[rType] = rule;
      }
    });
  }
  return obj;
}
