import React, { useState, useEffect } from "react";
import OuterDiv from "../components/OuterDiv";
import Header from "../components//Header";
import {
  Grid,
  Typography,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Divider,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { LogoButton } from "../components/Buttons";
import UploadDataset from "../components/UploadDataset";
import Api from "../services/Api";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ReadableLocalDateTime } from "../utils/momentUtils";
import { SuccessSnack } from "../components/Snackbars";
import { useLayoutContext } from "../components/Layout";

export const DatasetView = (props) => {
  const [openUpload, setOpenUpload] = useState(false);
  const [datasets, setSets] = useState([]);
  const [successSnack, setSuccessSnack] = useState(false);
  const [successText, setSuccessText] = useState("Dataset succesfully deleted");
  const { setCurrentView } = useLayoutContext();

  useEffect(() => {
    fetchDataSets();
    setCurrentView("Datasets");
  }, []);

  const close = async (success) => {
    let awaitable = fetchDataSets();
    setOpenUpload(false);
    //if success snackbar
    await awaitable;
  };

  const deleteDataset = async (dataset) => {
    const deleted = await Api.deleteDataset(dataset.id);
    if (deleted) {
      await fetchDataSets();
      setSuccessText("Dataset succesfully deleted");
      setSuccessSnack(true);
    }
  };

  const fetchDataSets = async () => {
    const datasets = await Api.getDatasets();
    if (datasets !== null) {
      setSets(datasets);
    }
  };

  return (
    <OuterDiv>
      <Dialog open={openUpload} onClose={close}>
        <DialogTitle>Upload New Dataset</DialogTitle>
        <DialogContent>
          <UploadDataset close={close} />
        </DialogContent>
      </Dialog>
      <>
        <SuccessSnack
          message={"Dataset successfully deleted"}
          open={successSnack}
          onClose={() => setSuccessSnack(false)}
        />
        <Header>Your Datasets:</Header>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
          style={{ marginTop: "1em" }}
        >
          <Grid item style={{ width: "85%" }}>
            <List>
              {datasets.map((val) => {
                return (
                  <React.Fragment key={val.id}>
                    <ListItem>
                      <ListItemText
                        primary={val.name}
                        secondary={
                          "Last Updated: " +
                          ReadableLocalDateTime(val.lastUpdated)
                        }
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Delete">
                          <IconButton
                            edge="end"
                            onClick={() => deleteDataset(val)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                );
              })}
            </List>
          </Grid>
          <Grid item>
            <LogoButton onClick={() => setOpenUpload(true)}>
              Upload New Dataset
            </LogoButton>
          </Grid>
        </Grid>
      </>
    </OuterDiv>
  );
};

export default DatasetView;
