import React from "react";
import {
  Typography,
  Snackbar,
  makeStyles,
  SnackbarContent,
  IconButton,
  DialogTitle,
  Dialog,
  Grid,
  Button,
} from "@material-ui/core";
// import {buttonTheme} from '../helpers/Theme';
// import {ThemeProvider} from '@material-ui/styles'
import { green, red } from "@material-ui/core/colors";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    padding: "1.5em 0 1em 1.5em",
  },
  root: {
    padding: theme.spacing(1),
    width: "100%",
    overflowX: "hidden",
  },
  successSnack: {
    backgroundColor: green[600],
  },
  failSnack: {
    backgroundColor: red[600],
  },
  successMessage: {
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    fontSize: 20,
  },
  successIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

export function SuccessSnack(props) {
  const classes = useStyles();
  const { message, open, onClose, ...other } = props;
  let vert = props.vertical || "bottom";
  let horiz = props.horizontal || "left";
  let snackybar = (
    <Snackbar
      anchorOrigin={{
        vertical: vert,
        horizontal: horiz,
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
    >
      <SnackbarContent
        className={classes.successSnack}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.successMessage}>
            <SuccessIcon className={classes.successIcon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );

  return snackybar;
}

export function FailSnack(props) {
  const classes = useStyles();
  const { message, open, onClose, ...other } = props;
  let vert = props.vertical || "bottom";
  let horiz = props.horizontal || "left";
  let autoHide = props.autohide || 5000;
  let snackybar = (
    <Snackbar
      anchorOrigin={{
        vertical: vert,
        horizontal: horiz,
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
    >
      <SnackbarContent
        className={classes.failSnack}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.successMessage}>
            <WarningIcon className={classes.successIcon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );

  return snackybar;
}
