import moment from "moment";

import {
  apm15data,
  apm30data,
  apm60data,
  pphdata,
  assignmentdata,
} from "./DemoEngineData";

export class DemoEngine {
  constructor() {
    this.assignmentHistory = fakeAssignmentHistory.slice(0);
    this.availableProviders = fakeAvailableProviders.slice(0);
    this.nextProviders = fakeNextProviders.slice(0);
    this.availableRooms = fakeRooms.slice(0);
    this.nextAssignmentId = Number(localStorage.getItem("nextAssignmentId"));
    this.currentSchedule = currentSchedule;
    if (this.nextAssignmentId === 0) {
      this.nextAssignmentId = 10;
    }
  }

  getAssignmentHistory = () => {
    let historyJson = localStorage.getItem("assignmentHistory");
    let assignmentHistory;
    if (historyJson === undefined || historyJson === null) {
      assignmentHistory = fakeAssignmentHistory.slice(0);
    } else {
      assignmentHistory = JSON.parse(historyJson);
    }

    return assignmentHistory;
  };

  getAvailableProviders = () => {
    return this.availableProviders;
  };

  getNextProviders = () => {
    let nextProviders = [];
    let assignmentHistory = this.getAssignmentHistory();

    let provider,
      nextProviderInfo,
      assignment,
      lastAssignment,
      firstAssignment,
      providerAssignments,
      numHours,
      numPatients;
    let lastHour = moment().subtract(1, "hour");
    let now = moment();
    for (let i = 0; i < this.availableProviders.length; i++) {
      provider = this.availableProviders[i];
      nextProviderInfo = {
        provider: provider,
      };

      providerAssignments = [];
      for (let r = 0; r < assignmentHistory.length; r++) {
        assignment = assignmentHistory[r];

        if (assignment.provider.name === provider.name) {
          if (
            nextProviderInfo.lastAssignment === undefined ||
            moment(nextProviderInfo.lastAssignment.assignmentTime).isBefore(
              moment(assignment.assignmentTime)
            )
          ) {
            nextProviderInfo.lastAssignment = assignment;
          }

          if (
            nextProviderInfo.firstAssignment === undefined ||
            moment(nextProviderInfo.firstAssignment.assignmentTime).isAfter(
              moment(assignment.assignmentTime)
            )
          ) {
            nextProviderInfo.firstAssignment = assignment;
          }

          providerAssignments.push(assignment);
          if (moment(assignment.assignmentTime).isAfter(lastHour)) {
            providerAssignments.push(assignment);
          }
        }
      }

      if (nextProviderInfo.lastAssignment) {
        numHours = moment
          .duration(
            now.diff(moment(nextProviderInfo.firstAssignment.assignmentTime))
          )
          .asHours();
      }

      numPatients = 0;
      for (let s = 0; s < providerAssignments.length; s++) {
        assignment = providerAssignments[s];
        numPatients += assignment.patients.length;
      }

      if (numHours > 0) {
        nextProviderInfo.patientsPerHour = (numPatients / numHours).toFixed(2);
      } else {
        nextProviderInfo.patientsPerHour = 0;
      }

      nextProviders.push(nextProviderInfo);
    }

    nextProviders.sort((a, b) => {
      if (
        a.provider.status == "available" &&
        b.provider.status != "available"
      ) {
        return -1;
      } else if (
        a.provider.status != "available" &&
        b.provider.status == "available"
      ) {
        return 1;
      } else if (
        a.provider.status == "procedure" &&
        (b.provider.status == "code" || b.provider.status == "unavailable")
      ) {
        return -1;
      } else if (
        a.provider.status == "unavailable" &&
        (b.provider.status == "procedure" || b.provider.status == "code")
      ) {
        return 1;
      } else if (a.provider.status == "code") {
        if (b.provider.status == "procedure") {
          return 1;
        } else if (b.provider.status == "unavailable") {
          return -1;
        }
      }

      if (a.lastAssignment && !b.lastAssignment) {
        return 1;
      } else if (b.lastAssignment && !a.lastAssignment) {
        return -1;
      } else if (!a.lastAssignment) {
        return 0;
      }

      if (
        moment(a.lastAssignment.assignmentTime).isBefore(
          moment(b.lastAssignment.assignmentTime)
        )
      ) {
        return -1;
      } else if (
        moment(a.lastAssignment.assignmentTime).isAfter(
          moment(b.lastAssignment.assignmentTime)
        )
      ) {
        return 1;
      }

      return 0;
    });

    return nextProviders;
  };

  addAssignment = (assignmentInfo) => {
    assignmentInfo.id = this.nextAssignmentId;
    this.nextAssignmentId++;

    let assignmentHistory = this.getAssignmentHistory();
    assignmentHistory.unshift(assignmentInfo);
    let historyJson = JSON.stringify(assignmentHistory);
    localStorage.setItem("assignmentHistory", historyJson);
    localStorage.setItem("nextAssignmentId", this.nextAssignmentId);
  };

  deleteAssignment = (assignmentInfo) => {
    let assignmentHistory = this.getAssignmentHistory();
    let index;
    for (let i = 0; i < assignmentHistory.length; i++) {
      let oldAssignment = assignmentHistory[i];
      if (assignmentInfo.id === oldAssignment.id) {
        index = i;
        break;
      }
    }
    if (index !== undefined) {
      assignmentHistory.splice(index, 1);
    }

    let historyJson = JSON.stringify(assignmentHistory);
    localStorage.setItem("assignmentHistory", historyJson);
  };

  getAllRooms = () => {
    return this.availableRooms;
  };

  setProviderStatus = (provider, status, duration) => {
    provider.status = status;
    provider.duration = duration;
  };

  editAssignment = (assignmentInfo) => {
    let assignmentHistory = this.getAssignmentHistory();
    let index;
    for (let i = 0; i < assignmentHistory.length; i++) {
      let oldAssignment = assignmentHistory[i];
      if (assignmentInfo.id === oldAssignment.id) {
        assignmentHistory[i] = assignmentInfo;
        break;
      }
    }

    let historyJson = JSON.stringify(assignmentHistory);
    localStorage.setItem("assignmentHistory", historyJson);
  };

  getSimulatedSchedules = (maxDailyShift, minShiftLength, maxShiftLength) => {
    // return [testSchedule1];
    // return [testSchedule1, testSchedule2, testSchedule3];
    return [
      simulatedSchedule1,
      simulatedSchedule2,
      simulatedSchedule3,
      simulatedSchedule4,
    ];
  };

  getPastSchedules = () => {
    return [pastSchedule1, pastSchedule2];
  };

  getCurrentSchedule = () => {
    return this.currentSchedule;
  };

  setCurrentSchedule = (schedule) => {
    this.currentSchedule = schedule;
  };

  getDatasets = () => {
    return [{ name: "Test Dataset 1" }, { name: "Test Dataset 2" }];
  };

  getMetricsForSchedule = (schedule) => {
    let metrics = [];
    metrics.push({ name: "Metric 1", value: Number(Math.random().toFixed(2)) });
    metrics.push({ name: "Metric 2", value: Number(Math.random().toFixed(2)) });
    metrics.push({ name: "Metric 3", value: Number(Math.random().toFixed(2)) });

    return metrics;
  };

  getAnalyticsData = (schedule) => {
    return analyticsData;
  };
}

export const testSchedule1 = {
  shifts: [
    [
      {
        name: "A1",
        startTime: 7,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 15,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 23,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
};

export const testSchedule2 = {
  shifts: [
    [
      {
        name: "D1",
        startTime: 10,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "D2",
        startTime: 18,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
};

export const testSchedule3 = {
  shifts: [
    [
      {
        name: "A1",
        startTime: 5.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 11.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 17.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A4",
        startTime: 23.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
};

export const testSchedule4 = {
  shifts: [
    [
      {
        name: "A1",
        startTime: 8,
        duration: 8,
        preload: { duration: 0, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
};

export const currentSchedule = {
  name: "Current Schedule",
  shifts: [
    [
      {
        name: "A1",
        startTime: 5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 13,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 21,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 6,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 14,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 22,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 8.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 16.5,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "D1",
        startTime: 10,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "D2",
        startTime: 18,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.85,
    },
    {
      name: "Metric 2",
      value: 0.72,
    },
    {
      name: "Metric 3",
      value: 0.35,
    },
  ],
};

export const pastSchedule1 = {
  name: "Past Schedule 1",
  shifts: [
    [
      {
        name: "A1",
        startTime: 5.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 13.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 21.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 6,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 14,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 22,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 8.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 16.5,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "D1",
        startTime: 10,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "D2",
        startTime: 18,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.825,
    },
    {
      name: "Metric 2",
      value: 0.725,
    },
    {
      name: "Metric 3",
      value: 0.325,
    },
  ],
};

export const pastSchedule2 = {
  name: "Past Schedule 2",
  shifts: [
    [
      {
        name: "A1",
        startTime: 5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 13,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 21,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 6,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 14,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 22,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 8.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 16.5,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "D1",
        startTime: 8,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "D2",
        startTime: 16,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.8,
    },
    {
      name: "Metric 2",
      value: 0.67,
    },
    {
      name: "Metric 3",
      value: 0.4,
    },
  ],
};

export const simulatedSchedule1 = {
  name: "Schedule 1",
  shifts: [
    [
      {
        name: "A1",
        startTime: 8,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 16,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 0,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 8.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 16.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 0.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 9,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 17,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.7,
    },
    {
      name: "Metric 2",
      value: 0.75,
    },
    {
      name: "Metric 3",
      value: 0.55,
    },
  ],
};

export const simulatedSchedule2 = {
  name: "Schedule 2",
  shifts: [
    [
      {
        name: "A1",
        startTime: 7,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 15,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 23,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 8,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 16,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 0,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 9,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 16.5,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.75,
    },
    {
      name: "Metric 2",
      value: 0.7,
    },
    {
      name: "Metric 3",
      value: 0.5,
    },
  ],
};

export const simulatedSchedule3 = {
  name: "Schedule 3",
  shifts: [
    [
      {
        name: "A1",
        startTime: 5.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 11.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 17.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A4",
        startTime: 23.5,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 8,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 14,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 20,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B4",
        startTime: 2,
        duration: 6,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
        maxAcuity: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 7,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 15,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "D1",
        startTime: 12,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.45,
    },
    {
      name: "Metric 2",
      value: 0.72,
    },
    {
      name: "Metric 3",
      value: 0.8,
    },
  ],
};

export const simulatedSchedule4 = {
  name: "Schedule 4",
  shifts: [
    [
      {
        name: "A1",
        startTime: 5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A2",
        startTime: 13,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "A3",
        startTime: 21,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "B1",
        startTime: 6,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B2",
        startTime: 14,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "B3",
        startTime: 22,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "C1",
        startTime: 6.5,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "C2",
        startTime: 14.5,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
    [
      {
        name: "D1",
        startTime: 8,
        duration: 8,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
      {
        name: "D2",
        startTime: 16,
        duration: 7.5,
        preload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        offload: { duration: 0.5, maxAcuity: 2, maxPatients: 2 },
        maxPatients: 2,
      },
    ],
  ],
  metrics: [
    {
      name: "Metric 1",
      value: 0.6,
    },
    {
      name: "Metric 2",
      value: 0.72,
    },
    {
      name: "Metric 3",
      value: 0.7,
    },
  ],
};

// data: [{ name: "A1", value: }, { name: "A2", value: }, { name: "A3", value: }, { name: "B1", value: }, {name: "B2", value: }, {name: "B3", value: }, {name: "C1", value:}, {name: "C2", value:}, {name: "D1", value:}, {name: "D2", value:}]

export const analyticsData = {
  apsmsd: {
    mean: 13.757,
    meanPlusSd: 14.718,
    meanMinusSd: 12.796,
    data: [
      { name: "A1", value: 12.757 },
      { name: "A2", value: 13.667 },
      { name: "A3", value: 15.658 },
      { name: "B1", value: 12.721 },
      { name: "B2", value: 13.964 },
      { name: "B3", value: 15.063 },
      { name: "C1", value: 13.27 },
      { name: "C2", value: 13.396 },
      { name: "D1", value: 12.721 },
      { name: "D2", value: 14.351 },
    ],
  },
  aps: {
    data: [
      { name: "A1", value: 12.757 },
      { name: "A2", value: 13.667 },
      { name: "A3", value: 15.658 },
      { name: "B1", value: 12.721 },
      { name: "B2", value: 13.964 },
      { name: "B3", value: 15.063 },
      { name: "C1", value: 13.27 },
      { name: "C2", value: 13.396 },
      { name: "D1", value: 12.721 },
      { name: "D2", value: 14.351 },
    ],
  },
  aphmsd: {
    mean: 1.741,
    meanPlusSd: 1.873,
    meanMinusSd: 1.61,
    data: [
      { name: "A1", value: 1.595 },
      { name: "A2", value: 1.708 },
      { name: "A3", value: 1.957 },
      { name: "B1", value: 1.59 },
      { name: "B2", value: 1.745 },
      { name: "B3", value: 1.883 },
      { name: "C1", value: 1.659 },
      { name: "C2", value: 1.786 },
      { name: "D1", value: 1.59 },
      { name: "D2", value: 1.914 },
    ],
  },
  aph: {
    data: [
      { name: "A1", value: 1.595 },
      { name: "A2", value: 1.708 },
      { name: "A3", value: 1.957 },
      { name: "B1", value: 1.59 },
      { name: "B2", value: 1.745 },
      { name: "B3", value: 1.883 },
      { name: "C1", value: 1.659 },
      { name: "C2", value: 1.786 },
      { name: "D1", value: 1.59 },
      { name: "D2", value: 1.914 },
    ],
  },
  apm15: {
    data: apm15data,
  },
  apm30: { data: apm30data },
  apm60: { data: apm60data },
  pph: { data: pphdata },
  acpmsd: {
    mean: 2.811,
    meanPlusSd: 2.955,
    meanMinusSd: 2.668,
    data: [
      { name: "A1", value: 2.912 },
      { name: "A2", value: 2.647 },
      { name: "A3", value: 3.03 },
      { name: "B1", value: 2.81 },
      { name: "B2", value: 2.655 },
      { name: "B3", value: 3.066 },
      { name: "C1", value: 2.722 },
      { name: "C2", value: 2.777 },
      { name: "D1", value: 2.664 },
      { name: "D2", value: 2.827 },
    ],
  },
  acps1: {
    data: [
      { name: "A1", value: 0 },
      { name: "A2", value: 0 },
      { name: "A3", value: 0 },
      { name: "B1", value: 0 },
      { name: "B2", value: 0 },
      { name: "B3", value: 0 },
      { name: "C1", value: 0 },
      { name: "C2", value: 0 },
      { name: "D1", value: 0 },
      { name: "D2", value: 0 },
    ],
  },
  acps2: {
    data: [
      { name: "A1", value: 3.64 },
      { name: "A2", value: 5.68 },
      { name: "A3", value: 3.49 },
      { name: "B1", value: 4.35 },
      { name: "B2", value: 5.54 },
      { name: "B3", value: 3.38 },
      { name: "C1", value: 4.86 },
      { name: "C2", value: 4.42 },
      { name: "D1", value: 5.08 },
      { name: "D2", value: 4.88 },
    ],
  },
  acps3: {
    data: [
      { name: "A1", value: 6.8 },
      { name: "A2", value: 7.17 },
      { name: "A3", value: 8.4 },
      { name: "B1", value: 6.59 },
      { name: "B2", value: 7.72 },
      { name: "B3", value: 7.7 },
      { name: "C1", value: 7.3 },
      { name: "C2", value: 7.64 },
      { name: "D1", value: 6.87 },
      { name: "D2", value: 7.28 },
    ],
  },
  acps4: {
    data: [
      { name: "A1", value: 2.12 },
      { name: "A2", value: 0.78 },
      { name: "A3", value: 3.56 },
      { name: "B1", value: 1.63 },
      { name: "B2", value: 0.68 },
      { name: "B3", value: 3.59 },
      { name: "C1", value: 1.04 },
      { name: "C2", value: 1.23 },
      { name: "D1", value: 0.72 },
      { name: "D2", value: 1.98 },
    ],
  },
  acps5: {
    data: [
      { name: "A1", value: 0.2 },
      { name: "A2", value: 0.04 },
      { name: "A3", value: 0.21 },
      { name: "B1", value: 0.15 },
      { name: "B2", value: 0.03 },
      { name: "B3", value: 0.4 },
      { name: "C1", value: 0.07 },
      { name: "C2", value: 0.11 },
      { name: "D1", value: 0.05 },
      { name: "D2", value: 0.21 },
    ],
  },
  acps2p: {
    data: [
      { name: "A1", value: 0 },
      { name: "A2", value: 0 },
      { name: "A3", value: 0 },
      { name: "B1", value: 0 },
      { name: "B2", value: 0 },
      { name: "B3", value: 0 },
      { name: "C1", value: 0 },
      { name: "C2", value: 0 },
      { name: "D1", value: 0 },
      { name: "D2", value: 0 },
    ],
  },
  assignments: { data: assignmentdata },
};

export const fakePatient1 = {
  arrivalTime: moment().subtract(0.05, "hours"),
  acuity: 5,
  triage: "",
};

export const fakePatient2 = {
  arrivalTime: moment().subtract(0.25, "hours"),
  acuity: 2,
  triage: "",
};

export const fakePatient3 = {
  arrivalTime: moment().subtract(0.8, "hours"),
  acuity: 4,
  triage: "",
};

export const fakePatient4 = {
  arrivalTime: moment().subtract(1.2, "hours"),
  acuity: 6,
  triage: "",
};

export const fakePatient5 = {
  arrivalTime: moment().subtract(1.5, "hours"),
  acuity: 3,
  triage: "",
};

export const fakePatient6 = {
  arrivalTime: moment().subtract(1.7, "hours"),
  acuity: 1,
  triage: "",
};

export const fakePatient7 = {
  arrivalTime: moment().subtract(1.9, "hours"),
  acuity: 3,
  triage: "",
};

export const fakePatient8 = {
  arrivalTime: moment().subtract(2.2, "hours"),
  acuity: 4,
  triage: "",
};

export const fakePatient9 = {
  arrivalTime: moment().subtract(2.4, "hours"),
  acuity: 2,
  triage: "",
};

export const fakePatient10 = {
  arrivalTime: moment().subtract(2.75, "hours"),
  acuity: 1,
  triage: "",
};

export const fakeProviderA1 = {
  name: "A1",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderA2 = {
  name: "A2",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderA3 = {
  name: "A3",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderA4 = {
  name: "A4",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderB1 = {
  name: "B1",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderB2 = {
  name: "B2",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderB3 = {
  name: "B3",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderB4 = {
  name: "B4",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderC1 = {
  name: "C1",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderC2 = {
  name: "C2",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderC3 = {
  name: "C3",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderC4 = {
  name: "C4",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderD1 = {
  name: "D1",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderD2 = {
  name: "D2",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderD3 = {
  name: "D3",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeProviderD4 = {
  name: "D4",
  providerType: 0,
  status: "available",
  duration: -1,
};

export const fakeRoom1 = {
  name: "1",
  location: "ER",
};

export const fakeRoom2 = {
  name: "2",
  location: "ER",
};

export const fakeRoom3 = {
  name: "3",
  location: "ER",
};

export const fakeRoom4 = {
  name: "4",
  location: "ER",
};

export const fakeRoom5 = {
  name: "5",
  location: "ER",
};

export const fakeRoom6 = {
  name: "6",
  location: "ER",
};

export const fakeRoom7 = {
  name: "7",
  location: "ER",
};

export const fakeRoom8 = {
  name: "8",
  location: "ER",
};

export const fakeRoom9 = {
  name: "9",
  location: "ER",
};

export const fakeRoom10 = {
  name: "10",
  location: "ER",
};

export const fakeRoom11 = {
  name: "11",
  location: "ER",
};

export const fakeRoom12 = {
  name: "12",
  location: "ER",
};

export const fakeRoom13 = {
  name: "13",
  location: "ER",
};

export const fakeRoom14 = {
  name: "14",
  location: "ER",
};

export const fakeRoom15 = {
  name: "15",
  location: "ER",
};

export const fakeRoom16 = {
  name: "16",
  location: "ER",
};

export const fakeRoom17 = {
  name: "17",
  location: "ER",
};

export const fakeRoom18 = {
  name: "18",
  location: "ER",
};

export const fakeRoom19 = {
  name: "19",
  location: "ER",
};

export const fakeRoom20 = {
  name: "20",
  location: "ER",
};

export const fakeRoom21 = {
  name: "21",
  location: "ER",
};

export const fakeRoom22 = {
  name: "22",
  location: "ER",
};

export const fakeRoom23 = {
  name: "23",
  location: "ER",
};

export const fakeRoom24 = {
  name: "24",
  location: "ER",
};

export const fakeRoom25 = {
  name: "25",
  location: "ER",
};

export const fakeRoom26 = {
  name: "26",
  location: "ER",
};

export const fakeRoom27 = {
  name: "27",
  location: "ER",
};

export const fakeRoom28 = {
  name: "28",
  location: "ER",
};

export const fakeRoom29 = {
  name: "29",
  location: "ER",
};

export const fakeRoom30 = {
  name: "30",
  location: "ER",
};

export const fakeRoom31 = {
  name: "31",
  location: "ER",
};

export const fakeRoom32 = {
  name: "32",
  location: "ER",
};

export const fakeRoom33 = {
  name: "33",
  location: "ER",
};

export const fakeRoom34 = {
  name: "34",
  location: "ER",
};

export const fakeRoom35 = {
  name: "35",
  location: "ER",
};

export const fakeRoom36 = {
  name: "36",
  location: "ER",
};

export const fakeRoom37 = {
  name: "37",
  location: "ER",
};

export const fakeRoom38 = {
  name: "38",
  location: "ER",
};

export const fakeRoom39 = {
  name: "39",
  location: "ER",
};

export const fakeRoom40 = {
  name: "40",
  location: "ER",
};

export const fakeRoomHallBed1 = {
  name: "Hall 1",
  location: "Hall",
};

export const fakeRoomHallBed2 = {
  name: "Hall 2",
  location: "Hall",
};

export const fakeRoomHallBed3 = {
  name: "Hall 3",
  location: "Hall",
};

export const fakeRoomHallBed4 = {
  name: "Hall 4",
  location: "Hall",
};

export const fakeRoomTriage1 = {
  name: "Triage 1",
  location: "Triage",
};

export const fakeRoomTriage2 = {
  name: "Triage 2",
  location: "Triage",
};

export const fakeRoomTriage3 = {
  name: "Triage 3",
  location: "Triage",
};

export const fakeRoomUnknown = {
  name: "Unknown",
  location: "ER",
};

export const fakeAssignment1 = {
  id: 1,
  patients: [fakePatient2],
  assignmentTime: moment().subtract(0.15, "hours"),
  provider: fakeProviderB1,
  room: fakeRoom1,
};

export const fakeAssignment2 = {
  id: 2,
  patients: [fakePatient3],
  assignmentTime: moment().subtract(0.6, "hours"),
  provider: fakeProviderC1,
  room: fakeRoom2,
};

export const fakeAssignment3 = {
  id: 3,
  patients: [fakePatient4],
  assignmentTime: moment().subtract(1, "hours"),
  provider: fakeProviderA1,
  room: fakeRoom5,
};

export const fakeAssignment4 = {
  id: 4,
  patients: [fakePatient5],
  assignmentTime: moment().subtract(1.45, "hours"),
  provider: fakeProviderB1,
  room: fakeRoom10,
};

export const fakeAssignment5 = {
  id: 5,
  patients: [fakePatient6],
  assignmentTime: moment().subtract(1.5, "hours"),
  provider: fakeProviderA1,
  room: fakeRoom9,
};

export const fakeAssignment6 = {
  id: 6,
  patients: [fakePatient7],
  assignmentTime: moment().subtract(1.8, "hours"),
  provider: fakeProviderC1,
  room: fakeRoom8,
};

export const fakeAssignment7 = {
  id: 7,
  patients: [fakePatient8],
  assignmentTime: moment().subtract(2, "hours"),
  provider: fakeProviderB1,
  room: fakeRoom7,
};

export const fakeAssignment8 = {
  id: 8,
  patients: [fakePatient9],
  assignmentTime: moment().subtract(2.15, "hours"),
  provider: fakeProviderC1,
  room: fakeRoom6,
};

export const fakeAssignment9 = {
  id: 9,
  patients: [fakePatient10],
  assignmentTime: moment().subtract(2.5, "hours"),
  provider: fakeProviderA2,
  room: fakeRoom3,
};

export const fakeNextProviders = [
  {
    provider: fakeProviderA2,
    lastAssignment: fakeAssignment9,
    patientsPerHour: 2.25,
    status: "code",
    duration: 15,
  },
  {
    provider: fakeProviderC1,
    lastAssignment: fakeAssignment2,
    patientsPerHour: 2.75,
    status: "available",
    duration: -1,
  },
  {
    provider: fakeProviderA1,
    lastAssignment: fakeAssignment3,
    patientsPerHour: 3,
    status: "procedure",
    duration: 10,
  },
  {
    provider: fakeProviderB1,
    lastAssignment: fakeAssignment1,
    patientsPerHour: 2.5,
    status: "unavailable",
    duration: -1,
  },
];

export const fakeAssignmentHistory = [
  fakeAssignment1,
  fakeAssignment2,
  fakeAssignment3,
  fakeAssignment4,
  fakeAssignment5,
  fakeAssignment6,
  fakeAssignment7,
  fakeAssignment8,
  fakeAssignment9,
];

export const fakeAvailableProviders = [
  fakeProviderA1,
  fakeProviderB1,
  fakeProviderA2,
  fakeProviderC1,
];

export const fakeRooms = [
  fakeRoomUnknown,
  fakeRoom1,
  fakeRoom2,
  fakeRoom3,
  fakeRoom4,
  fakeRoom5,
  fakeRoom6,
  fakeRoom7,
  fakeRoom8,
  fakeRoom9,
  fakeRoom10,
  fakeRoom11,
  fakeRoom12,
  fakeRoom13,
  fakeRoom14,
  fakeRoom15,
  fakeRoom16,
  fakeRoom17,
  fakeRoom18,
  fakeRoom19,
  fakeRoom20,
  fakeRoom21,
  fakeRoom22,
  fakeRoom23,
  fakeRoom24,
  fakeRoom25,
  fakeRoom26,
  fakeRoom27,
  fakeRoom28,
  fakeRoom29,
  fakeRoom30,
  fakeRoom31,
  fakeRoom32,
  fakeRoom33,
  fakeRoom34,
  fakeRoom35,
  fakeRoom36,
  fakeRoom37,
  fakeRoom38,
  fakeRoom39,
  fakeRoom40,
  fakeRoomHallBed1,
  fakeRoomHallBed2,
  fakeRoomHallBed3,
  fakeRoomHallBed4,
  fakeRoomTriage1,
  fakeRoomTriage2,
  fakeRoomTriage3,
];
