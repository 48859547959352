import React, { useState, useEffect } from "react";

import { makeStyles, Grid, Paper, Typography, Button } from "@material-ui/core";

import Api from "../services/Api";

import { useLayoutContext } from "../components/Layout";
import Header from "../components/Header";
import Schedule from "../components/Schedule";
import { LogoButton } from "../components/Buttons";

const useStyles = makeStyles((theme) => ({}));

export const PastScheduleViewer = (props) => {
  const { onSelectPastSchedule, close } = props;
  const { portraitMode } = useLayoutContext();
  const [pastSchedules, setPastSchedules] = useState([]);

  useEffect(() => {
    getPastSchedules();
  }, []);

  const getPastSchedules = async () => {
    let awaitSchedules = Api.getPastSchedules();
    awaitSchedules.then((pastSchedules) => {
      setPastSchedules(pastSchedules);
    });
  };

  const selectPastSchedule = (schedule) => {
    onSelectPastSchedule(schedule);
  };

  const useSchedule = async (schedule) => {
    await Api.setCurrentSchedule(schedule);
    close();
  };

  const closeViewer = () => {
    close();
  };

  return (
    <div>
      <Header
        sticky
        leftAdornment={
          <div style={{ marginLeft: "8px", marginRight: "-4px" }}>
            <LogoButton onClick={closeViewer} style={{ height: "32px" }}>
              Close Viewer
            </LogoButton>
          </div>
        }
      >
        View Past Schedules
      </Header>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="flex-start"
      >
        {pastSchedules.map((schedule) => {
          return (
            <Grid item style={{ width: "685px", margin: "16px" }}>
              <Paper
                style={{
                  paddingTop: "12px",
                  paddingBottom: "16px",
                  paddingLeft: "8px",
                  paddingRight: "12px",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginLeft: "16px",
                  }}
                >
                  {schedule.name}
                </Typography>
                <Schedule schedule={schedule} dense={true} />
                <Grid container justify="center" spacing={4}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={useSchedule}
                    >
                      Use
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        selectPastSchedule(schedule);
                      }}
                    >
                      Compare
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default PastScheduleViewer;
