import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import AccessTime from "@material-ui/icons/AccessTime";

import Api from "../services/Api";

import { useLayoutContext } from "../components/Layout";

import OuterDiv from "../components/OuterDiv";
import { LogoButton } from "../components/Buttons";

import {
  ApsMsdDisplay,
  ApsDisplay,
  AphMsdDisplay,
  AphDisplay,
  Apm15Display,
  Apm30Display,
  Apm60Display,
  PphDisplay,
  AcpMsdDisplay,
  Acps1Display,
  Acps2Display,
  Acps2pDisplay,
  Acps3Display,
  Acps4Display,
  Acps5Display,
  AssignmentListDisplay,
} from "../components/AnalysisDisplays";
import Schedule from "../components/Schedule";
import ScheduleAnalyzer from "../views/ScheduleAnalyzer";

const useStyles = makeStyles((theme) => ({
  scheduleName: {
    fontWeight: "600",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  closeAnalyzer: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  displayAll: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  descriptionText: {
    marginTop: "16px",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  datasetSelector: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "320px",
    "& .MuiInputBase-root": { height: "61px" },
  },
}));

const getDisplayOptions = () => {
  return [
    {
      label: "Assignments/Shift MSD",
      value: "apsmsd",
      description: "Mean Assignments Per Shift, +/- Standard Deviation",
    },
    {
      label: "Assignments/Shift Bars",
      value: "aps",
      description: "Mean Assignments Per Shift",
    },
    {
      label: "Assignments/Hour MSD",
      value: "aphmsd",
      description: "Mean Assignments Per Hour, +/- Standard Deviation",
    },
    {
      label: "Assignments/Hour Bars",
      value: "aph",
      description: "Mean Assignments Per Hour",
    },
    {
      label: "Current Assignments/15 Minutes",
      value: "apm15current",
      description: "Mean Assignments Per 15 Minutes",
    },
    {
      label: "Proposed Assignments/15 Minutes",
      value: "apm15proposed",
      description: "Mean Assignments Per 15 Minutes",
    },
    {
      label: "Current Assignments/30 Minutes",
      value: "apm30current",
      description: "Mean Assignments Per 30 Minutes (Current)",
    },
    {
      label: "Proposed Assignments/30 Minutes",
      value: "apm30proposed",
      description: "Mean Assignments Per 30 Minutes (Proposed)",
    },
    {
      label: "Current Assignments/60 Minutes",
      value: "apm60current",
      description: "Mean Assignments Per 60 Minutes",
    },
    {
      label: "Proposed Assignments/60 Minutes",
      value: "apm60proposed",
      description: "Mean Assignments Per 60 Minutes",
    },
    {
      label: "Current Patients/Hour of Shift",
      value: "pphcurrent",
      description: "Patients Per Hour of Shift",
    },
    {
      label: "Proposed Patients/Hour of Shift",
      value: "pphproposed",
      description: "Patients Per Hour of Shift",
    },
    {
      label: "Acuity/Shift MSD",
      value: "acpmsd",
      description: "Mean Acuity Per Shift, +/- Standard Deviation",
    },
    {
      label: "Acuity 1/Shift",
      value: "acps1",
      description: "Mean Acuity 1 Assignments Per Shift",
    },
    {
      label: "Acuity 2/Shift",
      value: "acps2",
      description: "Mean Acuity 2 Assignments Per Shift",
    },
    {
      label: "Acuity 2 Psych/Shift",
      value: "acps2p",
      description: "Mean Acuity 2 Psych Assignments Per Shift",
    },
    {
      label: "Acuity 3/Shift",
      value: "acps3",
      description: "Mean Acuity 3 Assignments Per Shift",
    },
    {
      label: "Acuity 4/Shift",
      value: "acps4",
      description: "Mean Acuity 4 Assignments Per Shift",
    },
    {
      label: "Acuity 5/Shift",
      value: "acps5",
      description: "Mean Acuity 5 Assignments Per Shift",
    },
    {
      label: "Assignments List",
      value: "assignments",
      description: "List of Assignments Used in Analysis",
    },
  ];
};

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

// const removeProposedOptions = () => {
//   for (let i = displayOptions.length - 1; i >= 0; i--) {
//     let option = displayOptions[i];
//     if (option.label.endsWith("proposed")) {
//       displayOptions.splice(i, 1);
//     }
//   }
// };

export const ScheduleAnalysisChartsHOC = (props) => {
  const { proposedData } = props;
  const [selectedAnalyses, setSelectedAnalyses] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(getDisplayOptions());

  const selectAllAnalyses = () => {
    let allAnalyses = [];
    for (let i = 0; i < displayOptions.length; i++) {
      allAnalyses.push(displayOptions[i]);
    }
    setSelectedAnalyses(allAnalyses);
  };

  const removeProposedOptions = () => {
    let options = getDisplayOptions();
    for (let i = options.length - 1; i >= 0; i--) {
      let option = options[i];
      if (option.value.endsWith("proposed")) {
        options.splice(i, 1);
      }
    }
    setDisplayOptions(options);
  };

  useEffect(() => {
    if (proposedData == null) {
      removeProposedOptions();
    }
  }, [proposedData]);

  return (
    <ScheduleAnalysisCharts
      {...props}
      selectedAnalyses={selectedAnalyses}
      setSelectedAnalyses={setSelectedAnalyses}
      displayOptions={displayOptions}
      setDisplayOptions={setDisplayOptions}
      selectAllAnalyses={selectAllAnalyses}
    />
  );
};

export const ScheduleAnalysisCharts = (props) => {
  const {
    closeAnalyzer,
    currentData,
    proposedData,
    selectedAnalyses,
    setSelectedAnalyses,
    displayOptions,
    setDisplayOptions,
    selectAllAnalyses,
  } = props;

  const { portraitMode } = useLayoutContext();
  const classes = useStyles();
  //const [loading, setLoading] = useState(true);

  // useEffect(() => {

  // }

  return (
    <OuterDiv>
      <Grid
        container
        justify="center"
        alignContent="center"
        style={{ marginTop: "16px" }}
      >
        <Grid item xs={10}>
          <Autocomplete
            multiple
            id="display-options"
            value={selectedAnalyses}
            options={displayOptions}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setSelectedAnalyses(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Analyses To Display"
                placeholder="Select Analyses"
              />
            )}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        style={{ marginTop: "8px" }}
        spacing={portraitMode ? 2 : 4}
      >
        {!Boolean(closeAnalyzer) && (
          <Grid item>
            <LogoButton
              onClick={closeAnalyzer}
              className={classes.closeAnalyzer}
            >
              Close Analyzer
            </LogoButton>
          </Grid>
        )}
        <Grid item>
          <LogoButton
            onClick={selectAllAnalyses}
            className={classes.displayAll}
          >
            Display All Analyses
          </LogoButton>
        </Grid>
        <Grid item>
          <LogoButton
            onClick={() => {
              setSelectedAnalyses([]);
            }}
          >
            Clear All Analyses
          </LogoButton>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        style={
          portraitMode
            ? { marginTop: "16px", textAlign: "center" }
            : { marginTop: "16px", textAlign: "center" }
        }
        spacing={2}
      >
        {/* {loading && (
        <Grid item>
          <CircularProgress
            color="primary"
            size={128}
            style={{ marginTop: "64px" }}
          />
        </Grid>
      )}
      {!loading && ( */}
        <>
          {selectedAnalyses.length === 0 && (
            <Grid item>
              <Typography variant="h5" className={classes.descriptionText}>
                Select one or more Analyses to display using the above controls
              </Typography>
            </Grid>
          )}
          {selectedAnalyses.length > 0 &&
            selectedAnalyses.map((analysis) => {
              return (
                <AnalysesDisplay
                  currentData={currentData}
                  proposedData={proposedData}
                  displayOption={analysis}
                />
              );
            })}
        </>
      </Grid>
    </OuterDiv>
  );
};

const AnalysesDisplay = (props) => {
  const { currentData, proposedData, displayOption } = props;
  switch (displayOption.value) {
    case "apsmsd":
      return (
        <ApsMsdDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "aps":
      return (
        <ApsDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "aphmsd":
      return (
        <AphMsdDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "aph":
      return (
        <AphDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "apm15current":
      return (
        <Apm15Display
          data={currentData}
          key={displayOption.value}
          title="Current Assignments per Shift"
        />
      );
      break;
    case "apm15proposed":
      return (
        <Apm15Display
          data={proposedData}
          key={displayOption.value}
          title="Proposed Assignments per Shift"
        />
      );
      break;
    case "apm30current":
      return (
        <Apm30Display
          data={currentData}
          key={displayOption.value}
          title="Current Assignments per Shift"
        />
      );
      break;
    case "apm30proposed":
      return (
        <Apm30Display
          data={proposedData}
          key={displayOption.value}
          title="Proposed Assignments per Shift"
        />
      );
      break;
    case "apm60current":
      return (
        <Apm60Display
          data={currentData}
          key={displayOption.value}
          title="Current Assignments per Shift"
        />
      );
      break;
    case "apm60proposed":
      return (
        <Apm60Display
          data={proposedData}
          key={displayOption.value}
          title="Proposed Assignments per Shift"
        />
      );
      break;
    case "pphcurrent":
      return (
        <PphDisplay
          data={currentData}
          key={displayOption.value}
          title="Current Patients per Hour"
        />
      );
      break;
    case "pphproposed":
      return (
        <PphDisplay
          data={proposedData}
          key={displayOption.value}
          title="Proposed Patients per Hour"
        />
      );
      break;
    case "acpmsd":
      return (
        <AcpMsdDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps1":
      return (
        <Acps1Display
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps2":
      return (
        <Acps2Display
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps2p":
      return (
        <Acps2pDisplay
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps3":
      return (
        <Acps3Display
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps4":
      return (
        <Acps4Display
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "acps5":
      return (
        <Acps5Display
          currentData={currentData}
          proposedData={proposedData}
          key={displayOption.value}
        />
      );
      break;
    case "assignments":
      return (
        <AssignmentListDisplay data={currentData} key={displayOption.value} />
      );
      break;
    default:
      return <div />;
      break;
  }
};

export default ScheduleAnalysisChartsHOC;
