import React from "react";

import {
  makeStyles,
  Button,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";

import { ErrorButton } from "./Buttons";

import clsx from "clsx";

import moment from "moment";

import { EditAssignmentDialog } from "../components/EditAssignmentDialog";

const useAssignmentHistoryStyles = makeStyles((theme) => ({
  assignmentInfoPaper: {
    width: "calc(100% - 48px)",
    padding: "16px",
    height: "80px",
  },
  marginBottom: {
    marginBottom: "16px",
  },
  assignmentInfoTimeSpan: {
    marginTop: "7px",
    width: "100%",
    textAlign: "left",
  },
  assignmentInfoNameSpan: {
    fontWeight: "600",
    marginTop: "8px",
    marginLeft: "8px",
  },
  assignmentInfoAcuity: {
    marginTop: "4px",
  },
  assignmentInfoRule: {
    marginTop: "4px",
  },
  assignmentInfoRoom: {
    marginTop: "4px",
    marginLeft: "16px",
  },
  reassignmentButton: {
    fontWeight: "600",
  },
  unassignmentButton: {
    marginLeft: "16px",
    fontWeight: "600",
  },
  avatar: {
    height: "64px",
    width: "64px",
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary,
    fontSize: "1em",
  },
  borderRed: {
    borderColor: "red",
  },
}));

export const AssignmentHistoryInfo = (props) => {
  const classes = useAssignmentHistoryStyles();
  const {
    historyInfo,
    acuity,
    isLastItem,
    hideControls,
    onReassignClick,
    onUnassignClick,
  } = props;

  if (historyInfo === undefined) {
    return <div />;
  }

  const name = historyInfo.provider.name;
  const time = moment(historyInfo.assignmentTime).format(
    "MMMM Do YYYY, HH:mm:ss"
  );
  const room = historyInfo.room.name;
  const borderColor = acuity === "-Untriaged-" ? classes.borderRed : "";
  const rule = historyInfo.rule != null ? historyInfo.rule.ruleType : false;

  return (
    <Paper
      className={clsx(
        classes.assignmentInfoPaper,
        classes.marginBottom,
        borderColor
      )}
      variant="outlined"
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h4" className={classes.assignmentInfoNameSpan}>
          <Avatar className={classes.avatar}>{name}</Avatar>
        </Typography>
        <div
          style={{
            flex: "1 1 auto",
            marginLeft: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "3px" }}>
            <span className={classes.assignmentInfoTimeSpan}>{time}</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "1px" }}
          >
            <div className={classes.assignmentInfoAcuity}>
              Acuity: {acuity === "-Untriaged-" ? <b>UNTRIAGED</b> : acuity}
            </div>
            <div className={classes.assignmentInfoRoom}>Room: {room}</div>
          </div>
          <div>
            {rule && (
              <div className={classes.assignmentInfoRule}>
                Rule Used: {rule}
              </div>
            )}
          </div>
        </div>
        {!hideControls && (
          <div style={{ flex: "0 0 auto", display: "flex", marginTop: "6px" }}>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onReassignClick(historyInfo)}
                className={classes.reassignmentButton}
              >
                Re-Assign
              </Button>
            </div>
            <div>
              <ErrorButton
                variant="contained"
                onClick={() => onUnassignClick(historyInfo)}
                className={classes.unassignmentButton}
              >
                Unassign
              </ErrorButton>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default AssignmentHistoryInfo;
