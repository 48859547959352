import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";

import { getPermissionNames } from "../utils/utilities";

import OuterDiv from "../components/OuterDiv";
import BackArrow from "../components/BackArrow";
import { useLayoutContext } from "../components/Layout";
import { useAuth0 } from "../react-auth0-spa";
import { LogoButton } from "../components/Buttons";
import { ResetPasswordDialog } from "../components/ResetPasswordDialog";

const useStyles = makeStyles((theme) => ({}));

export const Account = (props) => {
  const classes = useStyles();
  const { setCurrentView, portraitMode } = useLayoutContext();
  const { user, userInfo } = useAuth0();
  const [resetPasswordShown, setResetPasswordShown] = useState(false);
  const [socialAccount, setSocialAccount] = useState(false);

  useEffect(() => {
    setCurrentView("Account");
  }, []);

  useEffect(() => {
    if (user !== undefined && user !== null) {
      setSocialAccount(!user.sub.startsWith("auth0|"));
    }
  }, [user, userInfo]);

  const showResetPassword = () => {
    setResetPasswordShown(true);
  };

  const closeResetPassword = () => {
    setResetPasswordShown(false);
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ height: "100%" }}
        spacing={4}
      >
        <Grid item style={{ marginTop: "16px" }}>
          <Paper
            style={{
              width: "600px",
              height: "400px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "16px",
              }}
            >
              <img
                src={user.picture}
                style={{ width: "64px", height: "64px" }}
              />
              <Typography
                variant="h4"
                style={{ lineHeight: "1.9", marginLeft: "16px" }}
              >
                {user.name}
              </Typography>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {userInfo ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "16px",
                      flex: "0 0 auto",
                    }}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "600", width: "175px" }}
                      >
                        Organization:
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                      <Typography variant="h5">
                        {userInfo.organization
                          ? userInfo.organization.name
                          : "Unknown"}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginTop: "8px",
                      flex: "0 0 auto",
                    }}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "600", width: "175px" }}
                      >
                        Permissions:
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                      <Typography variant="h5">
                        {getPermissionNames(userInfo.permissions).map(
                          (permissionName) => {
                            return <div>{permissionName}</div>;
                          }
                        )}
                      </Typography>
                    </div>
                  </div>
                </>
              ) : (
                <Typography variant="h5" style={{ marginTop: "16px" }}>
                  <i>User Account Details Unavailable</i>
                </Typography>
              )}
              <div style={{ flex: "1 1 auto", height: "100%" }} />
              {socialAccount ? (
                <div />
              ) : (
                <div
                  style={{
                    flex: "0 0 auto",
                    textAlign: "center",
                  }}
                >
                  <LogoButton onClick={showResetPassword}>
                    Reset Password
                  </LogoButton>
                </div>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <ResetPasswordDialog
        email={user.email}
        open={resetPasswordShown}
        onClose={closeResetPassword}
      />
    </>
  );
};

export default Account;
