import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";

import Header, { CollapsableHeader } from "../components/Header";
import MetricComparisonPane from "../components/MetricComparisonPane";
import Schedule from "../components/Schedule";
import ObjectiveGradeTable from "../components/ObjectiveGradeTable";

export const OptimizerScheduleResults = (props) => {
  const { request, portraitMode, details } = props;

  return (
    <>
      {request.results.length > 0 && (
        <>
          <CollapsableHeader
            title="Metric Comparisons"
            sticky={!portraitMode}
            startCollapsed={false}
          >
            {/* <MetricComparisonPane results={request.results} />  */}
            <ObjectiveGradeTable
              results={request.results}
              portraidMode={portraitMode}
            />
          </CollapsableHeader>
          <Header>Schedules</Header>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {request.results.map((result, idx) => {
              let index = idx;
              console.log(result);
              let schedule = result.schedule;
              let number = index + 1;
              const objectives = [];
              Object.keys(result.objectiveGrades).forEach((key) => {
                objectives.push({
                  name: key,
                  value: result.objectiveGrades[key],
                });
              });
              // let overall = ((metric1 + metric2 + metric3) / 3).toFixed(2);
              return (
                <Grid
                  item
                  style={{ width: "685px", margin: "16px" }}
                  key={index}
                >
                  <Paper
                    style={{
                      paddingTop: "12px",
                      paddingBottom: "16px",
                      paddingLeft: "8px",
                      paddingRight: "12px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "600",
                        marginLeft: "16px",
                      }}
                    >
                      {schedule.name}
                    </Typography>
                    <Schedule schedule={schedule} dense={true} />
                    {result.objectives && (
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        style={{ marginBottom: "16px" }}
                      >
                        {objectives.map((obj) => {
                          return (
                            <Grid item key={obj.name}>
                              <Typography>
                                {obj.name}: {obj.value}
                              </Typography>
                            </Grid>
                          );
                        })}

                        {/* <Grid item>
                          <Typography>Overall: {overall}</Typography>
                        </Grid> */}
                      </Grid>
                    )}
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="row"
                      spacing={4}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={schedule.optimizerSchedule == false}
                          onClick={() => {
                            props.saveSchedule(schedule);
                          }}
                        >
                          Keep
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            details(schedule);
                          }}
                        >
                          Details
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={schedule.optimizerSchedule == false}
                          onClick={() => {
                            props.deleteSchedule(schedule);
                          }}
                        >
                          Discard
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
};

export default OptimizerScheduleResults;
