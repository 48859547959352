import React, { useState, useContext, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useAuth0 } from ".././react-auth0-spa";

import clsx from "clsx";

import moment, { duration } from "moment";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { v4 as uuidv4 } from "uuid";

import Api from "../services/Api";

import { acuities, numPatientOptions } from "../utils/constants";
import { detectPortraitMode } from "../utils/utilities";

import OuterDiv from "../components/OuterDiv";
import { useLayoutContext } from "../components/Layout";

import { AssignmentControls } from "../components/AssignmentControls";

import { AvailableProviderDisplay } from "../components/AvailableProviderDisplay";
import { AssignmentHistoryDisplay } from "../components/AssignmentHistoryDisplay";
import { UntriagedPatientDisplay } from "../components/UntriagedPatientDisplay";

import { RoomSelectionDialog } from "../components/RoomSelectionDialog";
import { EditAssignmentDialog } from "../components/EditAssignmentDialog";
import { FlagBusyDialog } from "../components/FlagBusyDialog";
import {
  LogoButton,
  ErrorButton,
  InfoButton,
  WarningButton,
} from "../components/Buttons";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    position: "relative",
  },
  autocomplete: {
    width: "210px",
  },
  providerControls: {
    marginTop: "16px",
    marginBottom: "13px",
    position: "relative",
    paddingBottom: "84px",
  },
  controlLabel: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
  assignButton: {
    width: "200px",
    marginTop: "32px",
    left: "calc(50% - 100px)",
    position: "absolute",
  },
  untriagedProviderDisplay: {
    marginTop: "-480px",
  },
}));

const useAutocompleteStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

export const Assignment = (props) => {
  const { providerView } = props;
  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();
  const { setCurrentView, portraitMode } = useLayoutContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [nextProviders, setNextProviders] = useState([]);
  const [assignmentHistory, setAssignmentHistory] = useState([]);
  const [acuity, setAcuity] = useState(acuities[4]);
  const [provider, setProvider] = useState(null);
  const [room, setRoom] = useState({ name: "Unknown" });
  const [assignmentTime, setAssignmentTime] = useState(null);
  const [availableProviders, setAvailableProviders] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [showRoomSelector, setShowRoomSelector] = useState(false);
  const [flagBusyProvider, setFlagBusyProvider] = useState(null);
  const [showFlagBusyDialog, setShowFlagBusyDialog] = useState(false);
  const [numPatients, setNumPatients] = useState("1");
  const [assignmentTimeLabel, setAssignmentTimeLabel] = useState(
    "Use Current Time"
  );
  const [assignmentToEdit, setAssignmentToEdit] = useState({});
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [untriagedShown, setUntriagedShown] = useState(false);

  useEffect(() => {
    const call = async () => {
      setCurrentView("Assignment");
      await Promise.all([
        updateNextProvider(),
        updateAssignmentHistory(),
        updateResourceList(),
      ]);
    };
    call();
  }, []);

  useEffect(() => {
    if (assignmentTime == null) {
      setAssignmentTimeLabel("Use Current Time");
    } else {
      setAssignmentTimeLabel("");
    }
  }, [assignmentTime]);

  useEffect(() => {
    async function func() {
      await Promise.all([updateNextProvider(), updateResourceList()]);
    }
    func();
  }, [assignmentTime, acuity]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [portraitMode]);

  const updateNextProvider = async () => {
    let timeToUse = assignmentTime
      ? moment(assignmentTime).format()
      : moment().format();
    let awaitNextProviders = Api.getNextProviders({
      acuity: acuity,
      arrivalTime: timeToUse,
    });
    awaitNextProviders.then((nextProviders) => {
      setNextProviders(nextProviders);
      if (
        nextProviders !== null &&
        nextProviders !== undefined &&
        nextProviders.length > 0
      ) {
        setProvider(nextProviders[0].provider);
      }
    });
  };

  const updateAssignmentHistory = async () => {
    let awaitAssignmentHistory = Api.getAssignmentHistory();
    awaitAssignmentHistory.then((assignmentHistory) => {
      setAssignmentHistory(assignmentHistory);
    });
  };

  const updateResourceList = async () => {
    let timeToUse = assignmentTime
      ? moment(assignmentTime).format()
      : moment().format();
    let awaitProviders = Api.getAvailableProviders({
      acuity: acuity,
      arrivalTime: timeToUse,
    });
    let awaitRooms = Api.getAvailableRooms();
    awaitProviders.then((providers) => {
      setAvailableProviders(providers);
    });
    awaitRooms.then((rooms) => {
      setAvailableRooms(rooms);
      if (rooms.length > 0) {
        setRoom(rooms[0]);
      }
    });
  };

  const onSelectProviderClick = (event, provider) => {
    setProvider(provider);
  };

  const onFlagBusyClick = (event, provider) => {
    setFlagBusyProvider(provider);
    setShowFlagBusyDialog(true);
  };

  const onFlagAvailableClick = async (event, provider) => {
    provider.Status = "available";
    provider.AvailabilityTime = null;
    await Api.setProviderStatus(provider, "available", null);
    updateNextProvider();
  };

  const closeFlagBusy = async (reason, duration) => {
    setShowFlagBusyDialog(false);
    if (reason == null) {
      return;
    }
    let availTime = null;
    if (duration > 0) {
      availTime = moment();
      availTime.add(duration, "minutes");
      availTime = availTime.format();
    }
    flagBusyProvider.Status = reason;
    flagBusyProvider.AvailabilityTime = availTime;
    // console.log(availTime);
    // console.log(duration);
    // console.log(flagBusyProvider);

    await Api.setProviderStatus(flagBusyProvider, reason, duration);
    setFlagBusyProvider({});
    updateNextProvider();
  };

  const getAssignInfoFromProvider = (providerName) => {
    let assignInfo = null;
    nextProviders.forEach((info) => {
      if (info.provider.name == providerName) {
        assignInfo = info;
      }
    });
    return assignInfo;
  };

  const assignProvider = async () => {
    let patients = [];
    let numNumPatients = Number(numPatients);
    let assigned = moment();
    if (assignmentTime !== null) {
      assigned = assignmentTime;
    }

    for (let i = 0; i < numNumPatients; i++) {
      patients.push({
        arrivalTime: assigned.format(),
        acuity: acuity.value,
        triage: "",
      });
    }

    let origInfo = getAssignInfoFromProvider(provider.name);
    let ruleUsed = origInfo != null ? origInfo.rule : null;
    let assignmentInfo = {
      patients: patients,
      room: room,
      provider: provider,
      assignmentTime: assigned.format(),
      rule: ruleUsed,
    };

    await Api.addAssignment(assignmentInfo);
    let prom = Promise.all([updateNextProvider(), updateAssignmentHistory()]);
    setAcuity(acuities[4]);
    setRoom(availableRooms[0]);
    setNumPatients("1");
    setAssignmentTime(null);
    await prom;
  };

  const showAssignmentEditor = (historyInfo) => {
    setAssignmentToEdit(historyInfo);
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  const onReassignClick = (historyInfo) => {
    showAssignmentEditor(historyInfo);
    // setTimeout(100, () => {
    //   updateAssignmentHistory();
    //   updateNextProvider();
    //   updateResourceList();
    //   setRenderId(uuidv4());
    // });
  };

  const onEditAssignment = async (assignment) => {
    await Api.editAssignment(assignment);
    updateAssignmentHistory();
    updateNextProvider();
    updateResourceList();
    setRenderId(uuidv4());
  };

  const onUnassignClick = async (historyInfo) => {
    await Api.deleteAssignment(historyInfo);
    updateAssignmentHistory();
    updateNextProvider();
    updateResourceList();
    setRenderId(uuidv4());
  };

  const onUntriagedDisplayChanged = (shown) => {
    setUntriagedShown(shown);
    setRenderId(uuidv4());
  };

  return (
    <OuterDiv id={renderId}>
      <AssignmentControls
        provider={provider}
        setProvider={setProvider}
        availableProviders={availableProviders}
        assignmentTime={assignmentTime}
        setAssignmentTime={setAssignmentTime}
        acuity={acuity}
        setAcuity={setAcuity}
        room={room}
        setRoom={setRoom}
        availableRooms={availableRooms}
        numPatients={numPatients}
        setNumPatients={setNumPatients}
        assignmentTimeLabel={assignmentTimeLabel}
        assignProvider={assignProvider}
        showTitle={true}
        showAssign={true}
        useRoomSelector={true}
      />
      <Grid
        container
        direction="row"
        justify="space-evenly"
        style={{ paddingTop: "5px" }}
      >
        <Grid item xs={portraitMode ? 12 : 6} direction="row">
          <UntriagedPatientDisplay
            assignmentHistory={assignmentHistory}
            useHeaderSpacing={true}
            onReassignClick={onReassignClick}
            onUnassignClick={onUnassignClick}
            onDisplayChange={onUntriagedDisplayChanged}
          />
          <AvailableProviderDisplay
            nextProviders={nextProviders}
            acuity={acuity}
            useHeaderSpacing={true}
            onSelect={onSelectProviderClick}
            onFlagBusy={onFlagBusyClick}
            onFlagAvailable={onFlagAvailableClick}
            untriagedShown={untriagedShown}
          />
        </Grid>
        <AssignmentHistoryDisplay
          assignmentHistory={assignmentHistory}
          useHeaderSpacing={true}
          onReassignClick={onReassignClick}
          onUnassignClick={onUnassignClick}
        />
        <FlagBusyDialog
          provider={flagBusyProvider}
          closeDialog={closeFlagBusy}
          dialogOpen={showFlagBusyDialog}
        />
        <EditAssignmentDialog
          dialogOpen={showEditDialog}
          closeDialog={closeEditDialog}
          assignment={assignmentToEdit}
          availableRooms={availableRooms}
          availableProviders={availableProviders}
          onEditAssignment={onEditAssignment}
        />
      </Grid>
    </OuterDiv>
  );
};

export default Assignment;
