import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, IconButton, Grid } from "@material-ui/core";

import { useLayoutContext } from "../components/Layout";
import Api from "../services/Api";
import OuterDiv from "../components/OuterDiv";
import ScheduleAnalyzer from "./ScheduleAnalyzer";

const useStyles = makeStyles((theme) => ({}));

//get the current schedule information
//send it in!

export const LiveScheduleAnalysis = (props) => {
  const classes = useStyles();
  const { setCurrentView } = useLayoutContext();
  const [currSchedule, setCurrSchedule] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentView("LiveAnalysis");
  }, []);

  useEffect(() => {
    async function call() {
      let sched = await Api.getCurrentSchedule();
      if (sched != null) {
        console.log(sched);
        sched.isCurrent = true;
        setCurrSchedule(sched);
        setLoading(true);
      }
    }
    call();
  }, []);

  return (
    <OuterDiv>
      {loading && <ScheduleAnalyzer liveMode={true} schedule={currSchedule} />}
    </OuterDiv>
  );
};
