import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
  ClickAwayListener,
  Portal,
  Paper,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useLayoutContext } from "../components/Layout";
import { Header, CollapsableHeader } from "../components/Header";
import { NewOptimizationControls } from "../components/NewOptimizationControls";
import { FailSnack, SuccessSnack } from "../components/Snackbars";
import OuterDiv from "../components/OuterDiv";
import Api from "../services/Api";
import { OptimizerRequestList } from "../components/OptimizerRequestList";

export const OptimizerHome = (props) => {
  const { setCurrentView, portraitMode } = useLayoutContext();

  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [failSnackOpen, setFailSnackOpen] = useState(false);
  const [failSnackMsg, setFailSnackMsg] = useState(
    "Request Failed to be Queued"
  );
  const [successSnackMsg, setSuccessSnackMsg] = useState(
    "Request Succesfully Queued"
  );
  const [optimizationRequests, setOptimizationRequests] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setCurrentView("Staffing");
    getRequests();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      getRequests();
    }, 15000);

    return () => clearInterval(timer);
  });

  const onSnackClose = (successOpen) => {
    if (successOpen) {
      setSuccessSnackOpen(false);
    } else {
      setFailSnackOpen(false);
    }
  };

  const createNewRequest = async (request) => {
    if (request !== null) {
      let success = await Api.newOptimizationRequest(request);
      if (success) {
        setSuccessSnackMsg("Request Succesfully Queued");
        setSuccessSnackOpen(true); //success snack
        //refresh list
        await getRequests();
        return true; //clear
      } else {
        setFailSnackMsg("Request Failed to be Queued");
        setFailSnackOpen(true); //fail snack
        return false; //dont clear
      }
    }
  };

  const onRequestView = (request) => {
    if (request !== null && request.status === 3) {
      history.push(`/optimizerResults/${request.id}`);
    } else if (request !== null && request.Status !== 3) {
      setFailSnackMsg("Request is Not Complete Yet");
      setFailSnackOpen(true);
    }
  };

  const onRequestDelete = async (request) => {
    var newOpts = [];
    var oldOpts = optimizationRequests.slice();
    oldOpts.forEach((x, i) => {
      if (x.id !== request.id) {
        newOpts.push(x);
      }
    });
    setOptimizationRequests(newOpts);
    let success = await Api.deleteOptimizationRequest(request.id);
    if (success) {
      let awaiter = getRequests();
      setSuccessSnackMsg("Succesfully Deleted");
      setSuccessSnackOpen(true);
      await awaiter;
    } else {
      setOptimizationRequests(oldOpts);
      setFailSnackMsg("Error Deleting, Try Again Later");
      setFailSnackOpen(true);
    }
  };

  const getRequests = async () => {
    let results = await Api.getOptimizationRequests();
    // console.log(results);
    if (results && results !== []) {
      setOptimizationRequests(results);
    }
  };

  return (
    <OuterDiv>
      <Header>Optimization Requests</Header>
      <Grid container justify={"center"}>
        <Grid item xs={12}>
          <OptimizerRequestList
            onView={onRequestView}
            requests={optimizationRequests}
            onDelete={onRequestDelete}
          />
        </Grid>
      </Grid>
      <CollapsableHeader title="Create New Request" startCollapsed={false}>
        <NewOptimizationControls onCreate={createNewRequest} />
      </CollapsableHeader>
      {/* <Grid item style={{marginTop: "2em"}}>

        </Grid>
      </Grid> */}
      <SuccessSnack
        open={successSnackOpen}
        message={successSnackMsg}
        onClose={() => onSnackClose(true)}
      />
      <FailSnack
        open={failSnackOpen}
        message={failSnackMsg}
        onClose={() => onSnackClose(false)}
      />
    </OuterDiv>
  );
};

export default OptimizerHome;
