import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";

import { SuccessButton } from "./Buttons";

const useStyles = makeStyles((theme) => ({
  dialogText: {
    color: theme.palette.text.primary,
  },
  scheduleNameField: {},
}));

export const UseScheduleDialog = (props) => {
  const { schedule, onConfirm, onCancel } = props;
  const classes = useStyles();
  const [scheduleName, setScheduleName] = useState("");
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  useEffect(() => {
    setConfirmEnabled(scheduleName.length > 0);
  }, [scheduleName]);

  const confirmClicked = () => {
    schedule.name = scheduleName;
    onConfirm(schedule);
  };

  const cancelClicked = () => {
    onCancel();
  };

  return (
    <Dialog open={schedule !== undefined}>
      <DialogTitle className={classes.dialogText}>Use Schedule?</DialogTitle>
      <DialogContent>
        <div className={classes.dialogText}>
          Please enter a name for this schedule and click 'Confirm' to switch to
          this schedule.
        </div>
        <TextField
          value={scheduleName}
          onChange={(event) => {
            setScheduleName(event.target.value);
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <SuccessButton variant="contained" onClick={confirmClicked}>
          Confirm
        </SuccessButton>
        <Button variant="contained" onClick={cancelClicked}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UseScheduleDialog;
