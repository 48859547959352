import React, { useState, useContext, useEffect } from "react";

import { makeStyles, withTheme } from "@material-ui/core";

import { slide as SlideMenu } from "react-burger-menu";

import { detectPortraitMode } from "../utils/utilities";

import { MenuControls } from "./MenuControls";

import NavBar from "./NavBar";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
  },
}));

export const LayoutContext = React.createContext();
export const useLayoutContext = () => useContext(LayoutContext);
export const Layout = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentView, setCurrentView] = useState("home");
  const [portraitMode, setPortraitMode] = useState(true);

  useEffect(() => {
    let detectPortraitDelegate = () => {
      setPortraitMode(detectPortraitMode());
    };

    detectPortraitDelegate();

    window.addEventListener("resize", detectPortraitDelegate);
    return () => {
      window.removeEventListener("resize", detectPortraitDelegate);
    };
  }, []);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const updateMenuState = (state) => {
    setMenuOpen(state.isOpen);
  };

  const classes = useStyles();
  return (
    <LayoutContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        toggleMenuOpen,
        currentView,
        setCurrentView,
        portraitMode,
      }}
    >
      <div id="outer-container" className={classes.mainContainer}>
        <SlideMenu
          menuClassName={"bm-settingsMenu"}
          pageWrapId={"main-container"}
          outerContainerId={"outer-container"}
          customBurgerIcon={false}
          customCrossIcon={false}
          isOpen={menuOpen}
          width={360}
          onStateChange={updateMenuState}
        >
          <MenuControls {...props} />
        </SlideMenu>
        <div id="main-container" className={classes.mainContainer}>
          <NavBar />
          <div className="flex-fullHeight-container">{props.children}</div>
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
