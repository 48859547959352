import React, { useState, useEffect, useRef } from "react";

import { BoldTableCell } from "../utils/tableUtils";
import { ObjectiveNames } from "../utils/constants";

import {
  makeStyles,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";

export const ObjectiveGradeTable = (props) => {
  const { results, objectiveCount, portraitMode } = props;

  const getObjectiveHeaders = () => {
    let content = [];
    for (let i = 0; i < objectiveCount; i++) {
      let name = ObjectiveNames[i];
      content.push(<BoldTableCell key={name}>{name} Grade</BoldTableCell>);
    }
    return content;
  };
  const getObjectiveNames = () => {
    let toReturn = [];
    if (results && results[0]) {
      toReturn = Object.keys(results[0].objectives);
    }
    return toReturn;
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      spacing={portraitMode ? 2 : 4}
    >
      <Grid item>
        <TableContainer>
          <TableHead>
            <TableRow>
              <BoldTableCell>Schedule Name</BoldTableCell>
              {getObjectiveNames().map((name) => (
                <BoldTableCell key={name} align="right">
                  {name} Score
                </BoldTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, idx) => (
              <TableRow key={result.id}>
                <TableCell>{result.schedule.name}</TableCell>
                {Object.keys(result.objectives).map((key) => (
                  <TableCell key={key} align="right">
                    {result.objectiveGrades[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ObjectiveGradeTable;
