import React, { useState } from "react";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MomentUtils from "@date-io/moment";

export const TimePicker = (props) => {
  // const [selectedDate, handleDateChange] = useState(null);
  const { time, onChange, ...otherProps } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        // label="Masked timepicker"
        // clearable
        ampm={false}
        // placeholder="08:00 AM"
        mask="__:__"
        value={time}
        onChange={onChange}
        // timeIcon={<AccessTimeIcon/>}
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
};
