import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import {
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
  ClickAwayListener,
  Portal,
  Paper,
  Container,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";

import {
  acuities,
  getAcuityByValue,
  alphabet,
  assignmentMethods,
} from "../utils/constants";

import { useLayoutContext } from "../components/Layout";

import { LogoButton, ErrorButton, SuccessButton } from "../components/Buttons";
import { Header } from "../components/Header";
import { Schedule } from "../components/Schedule";
import { PreloadOffloadEditDialog } from "../components/PreloadOffloadEditDialog";
import { getRuleFromRuleList } from "../utils/utilities";

const getShiftOptions = () => {
  // let shifts = {
  //   A: { letter: "A", nextIndex: 1, availableHours: 24 },
  //   B: { letter: "B", nextIndex: 1, availableHours: 24 },
  //   C: { letter: "C", nextIndex: 1, availableHours: 24 },
  //   D: { letter: "D", nextIndex: 1, availableHours: 24 },
  //   E: { letter: "E", nextIndex: 1, availableHours: 24 },
  //   F: { letter: "F", nextIndex: 1, availableHours: 24 },
  //   G: { letter: "G", nextIndex: 1, availableHours: 24 },
  //   H: { letter: "H", nextIndex: 1, availableHours: 24 },
  //   I: { letter: "I", nextIndex: 1, availableHours: 24 },
  //   J: { letter: "J", nextIndex: 1, availableHours: 24 },
  //   K: { letter: "K", nextIndex: 1, availableHours: 24 },
  //   L: { letter: "L", nextIndex: 1, availableHours: 24 },
  //   M: { letter: "M", nextIndex: 1, availableHours: 24 },
  //   N: { letter: "N", nextIndex: 1, availableHours: 24 },
  //   O: { letter: "O", nextIndex: 1, availableHours: 24 },
  //   P: { letter: "P", nextIndex: 1, availableHours: 24 },
  //   Q: { letter: "Q", nextIndex: 1, availableHours: 24 },
  //   R: { letter: "R", nextIndex: 1, availableHours: 24 },
  //   S: { letter: "S", nextIndex: 1, availableHours: 24 },
  //   T: { letter: "T", nextIndex: 1, availableHours: 24 },
  //   U: { letter: "U", nextIndex: 1, availableHours: 24 },
  //   V: { letter: "V", nextIndex: 1, availableHours: 24 },
  //   W: { letter: "W", nextIndex: 1, availableHours: 24 },
  //   X: { letter: "X", nextIndex: 1, availableHours: 24 },
  //   Y: { letter: "Y", nextIndex: 1, availableHours: 24 },
  //   Z: { letter: "Z", nextIndex: 1, availableHours: 24 },
  // };

  let shiftOptions = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return shiftOptions;
};

const useAutocompleteStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "56px",
  },
  input: {
    marginTop: "-4px",
  },
}));

const useStyles = makeStyles((theme) => ({
  shiftLetterSelector: {
    width: "80px",
  },
  controlLabel: {
    color: theme.palette.text.primary,
  },
  maxAcuitySelector: {
    width: "180px",
  },
  assignmentMethodSelector: {
    width: "200px",
  },
}));

export const ScheduleEditor = (props) => {
  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();

  const { schedule, onSave, onCancel } = props;
  const { portraitMode } = useLayoutContext();
  const [dense, setDense] = useState(false);
  const [renderId, setRenderId] = useState(uuidv4());
  const [originalSchedule, setOriginalSchedule] = useState(schedule);

  const [availableShiftOptions, setAvailableShiftOptions] = useState([]);
  const [unavailableShiftOptions, setUnavailableShiftOptions] = useState([]);
  const [selectedShiftOption, setSelectedShiftOption] = useState("A");

  const [selectedSubShift, setSelectedSubShift] = useState(null);
  const [selectedSubShiftStart, setSelectedSubShiftStart] = useState(" ");
  const [startError, setStartError] = useState(false);
  const [startErrorText, setStartErrorText] = useState(" ");

  const [selectedSubShiftLength, setSelectedSubShiftLength] = useState(" ");
  const [maxPatients, setMaxPatients] = useState(" ");
  const [maxAcuity, setMaxAcuity] = useState(acuities[4]);

  const [showEditPreload, setShowEditPreload] = useState(false);
  const [showEditOffload, setShowEditOffload] = useState(false);
  const [showEditSchedPreload, setShowEditSchedPreload] = useState(false);
  const [showEditSchedOffload, setShowEditSchedOffload] = useState(false);
  const [scheduleName, setScheduleName] = useState("");

  const [assignmentMethod, setAssignmentMethod] = useState(
    assignmentMethods[0]
  );

  const editPreloadRef = useRef();
  const editSchedPreloadRef = useRef();
  const editOffloadRef = useRef();
  const editSchedOffloadRef = useRef();

  useEffect(() => {
    updateShiftOptions();
  }, []);

  useEffect(() => {
    updateShiftOptions();
    let strSched = JSON.stringify(schedule);
    setOriginalSchedule(JSON.parse(strSched));
    if (schedule.assignmentMethod) {
      setAssignmentMethod(assignmentMethods[schedule.assignmentMethod]);
    }
  }, [schedule]);

  useEffect(() => {
    setDense(portraitMode);
  }, [portraitMode]);

  useEffect(() => {
    schedule.assignmentMethod = assignmentMethod.value;
  }, [assignmentMethod]);

  const updateShiftOptions = () => {
    let shiftOptions = getShiftOptions();
    updateShiftOptionsForSchedule(shiftOptions, schedule);
  };

  const updateShiftOptionsForSchedule = (shiftOptions, schedule) => {
    let availableOptions = [];
    let unavailableOptions = [];
    for (let i = shiftOptions.length - 1; i >= 0; i--) {
      let shiftOption = shiftOptions[i];
      let shift = findShift(schedule, shiftOption, false);
      if (shift === null) {
        availableOptions.unshift(shiftOption);
      } else {
        let startTime = findAvailableStartTime(shift);
        if (startTime === -1) {
          unavailableOptions.unshift(shiftOption);
        } else {
          availableOptions.unshift(shiftOption);
        }
      }
    }

    setAvailableShiftOptions(availableOptions);
    setUnavailableShiftOptions(unavailableOptions);
    setSelectedShiftOption(availableOptions[0]);
  };

  const findShift = (schedule, shiftLetter, createShift) => {
    for (let i = 0; i < schedule.shiftsByPrefix.length; i++) {
      let shift = schedule.shiftsByPrefix[i];
      for (let k = 0; k < shift.length; k++) {
        if (shift[k].name.startsWith(shiftLetter)) {
          return shift;
        }
      }
    }

    if (createShift) {
      let newShift = [];
      let shiftIndex = alphabet.indexOf(shiftLetter);
      let newIndex = 0;
      for (let i = 0; i < schedule.shiftsByPrefix.length; i++) {
        let shift = schedule.shiftsByPrefix[i];
        if (shift.length === 0) {
          continue;
        }
        let otherIndex = alphabet.indexOf(shift[0].name.substring(0, 1));
        if (shiftIndex < otherIndex) {
          newIndex = i;
          schedule.shiftsByPrefix.splice(i, 0, newShift);
          break;
        }
      }

      if (newIndex === 0) {
        schedule.shiftsByPrefix.push(newShift);
      }

      return newShift;
    } else {
      return null;
    }
  };

  const addShift = () => {
    let selectedShift = selectedShiftOption;
    let shiftLetter = selectedShift;
    let shift = findShift(schedule, shiftLetter, true);
    let startTime = findAvailableStartTime(shift);

    while (startTime === -1) {
      availableShiftOptions.splice(availableShiftOptions, 1);
      unavailableShiftOptions.push(selectedShiftOption);
      shiftLetter = availableShiftOptions[0];
      shift = findShift(schedule, shiftLetter, true);
      if (shift === null) {
        break;
      }
      startTime = findAvailableStartTime(shift);
      if (startTime !== -1) {
        setSelectedShiftOption(shiftLetter);
      }
    }

    let newSubShift = {
      name: shiftLetter,
      startTime: startTime,
      duration: 4,
      rules: [],
      selected: true,
    };

    let nextIndex = addSubShiftToShift(newSubShift, shift);

    let nextStartTime = findAvailableStartTime(shift);
    let nextShift;
    while (nextStartTime === -1) {
      availableShiftOptions.splice(
        availableShiftOptions.indexOf(selectedShiftOption),
        1
      );
      unavailableShiftOptions.push(selectedShiftOption);
      shiftLetter = availableShiftOptions[0];
      shift = findShift(schedule, shiftLetter);
      if (shift === null) {
        setSelectedShiftOption(shiftLetter);
        break;
      }
      nextStartTime = findAvailableStartTime(shift);
      if (nextStartTime !== -1) {
        setSelectedShiftOption(shiftLetter);
      }
    }

    onColumnSelected(newSubShift);
    setRenderId(uuidv4());
  };

  const findAvailableStartTime = (shift) => {
    if (shift === undefined) {
      return -1;
    }
    let usedHours = [];
    for (let i = 0; i < 96; i++) {
      usedHours.push(0);
    }

    for (let i = 0; i < shift.length; i++) {
      let subShift = shift[i];
      let start = Math.floor(subShift.startTime * 4);
      for (let k = start; k < start + subShift.duration * 4; k++) {
        if (k >= 96) {
          usedHours[k - 96] = 1;
        } else {
          usedHours[k] = 1;
        }
      }
    }

    let timeIndex = -1;
    for (let i = 0; i < usedHours.length; i++) {
      let timeFree = true;
      for (let k = 0; k < 16; k++) {
        let index = i + k;
        if (index >= 96) {
          index = index - 96;
        }
        if (usedHours[index] === 1) {
          timeFree = false;
        }
      }
      if (timeFree) {
        timeIndex = i / 4;
        break;
      }
    }

    return timeIndex;
  };

  const removeShift = () => {
    let shift = findShiftForSubShift(selectedSubShift);
    let shiftLetter = selectedSubShift.name.substring(0, 1);

    let wasAvailable = true;
    for (let i = 0; i < unavailableShiftOptions.length; i++) {
      if (unavailableShiftOptions[i] === shiftLetter) {
        wasAvailable = false;
        let shiftOption = unavailableShiftOptions[i];
        unavailableShiftOptions.splice(i, 1);
        let shiftLetterIndex = alphabet.indexOf(shiftLetter);
        for (let i = 0; i < availableShiftOptions.length; i++) {
          let optionLetterIndex = alphabet.indexOf(availableShiftOptions[i]);
          if (optionLetterIndex > shiftLetterIndex) {
            availableShiftOptions.splice(i, 0, shiftOption);
            break;
          }
        }
        break;
      }
    }

    if (wasAvailable) {
      for (let i = 0; i < availableShiftOptions.length; i++) {
        if (availableShiftOptions[i] === shiftLetter) {
          let shiftOption = availableShiftOptions[i];
        }
      }
    }

    removeSubShiftFromShift(selectedSubShift, shift);
    if (shift.length === 0) {
      schedule.shiftsByPrefix.splice(schedule.shiftsByPrefix.indexOf(shift), 1);
    }

    setSelectedSubShift(null);
    setRenderId(uuidv4());
  };

  const addSubShiftToShift = (subShift, shift) => {
    let shiftLetter = subShift.name;
    let newIndex = -1;
    let newNumber = 1;
    for (let i = 0; i < shift.length; i++) {
      let otherSubShift = shift[i];
      if (subShift.startTime < otherSubShift.startTime) {
        newNumber = Number(otherSubShift.name.substring(1));
        subShift.name = `${shiftLetter}${newNumber}`;
        newIndex = i;
        shift.splice(i, 0, subShift);
        break;
      } else {
        newNumber = Number(otherSubShift.name.substring(1)) + 1;
      }
    }

    if (newIndex === -1) {
      subShift.name = `${shiftLetter}${newNumber}`;
      shift.push(subShift);
      return newNumber;
    }

    for (let i = newIndex + 1; i < shift.length; i++) {
      let otherSubShift = shift[i];
      newNumber = Number(otherSubShift.name.substring(1)) + 1;
      otherSubShift.name = `${shiftLetter}${newNumber}`;
    }

    return newNumber;
  };

  const removeSubShiftFromShift = (subShift, shift) => {
    let shiftLetter = subShift.name.substring(0, 1);
    let subShiftNumber = Number(subShift.name.substring(1));
    let removed = shift.splice(shift.indexOf(subShift), 1);
    for (let i = 0; i < shift.length; i++) {
      let otherSubShift = shift[i];
      let otherSubShiftNumber = Number(otherSubShift.name.substring(1));
      if (otherSubShiftNumber > subShiftNumber) {
        otherSubShift.name = `${shiftLetter}${otherSubShiftNumber - 1}`;
      }
    }
  };

  const findShiftForSubShift = (subShift) => {
    let shifts = schedule.shiftsByPrefix;
    for (let i = 0; i < shifts.length; i++) {
      let shift = shifts[i];
      if (shift.indexOf(subShift) !== -1) {
        return shift;
      }
    }

    return null;
  };

  const onColumnSelected = (column) => {
    for (let i = 0; i < schedule.shiftsByPrefix.length; i++) {
      let shift = schedule.shiftsByPrefix[i];
      for (let k = 0; k < shift.length; k++) {
        let subShift = shift[k];
        if (subShift !== column) {
          subShift.selected = undefined;
        }
      }
    }

    setSelectedSubShift(column);

    if (column !== null && column !== undefined) {
      setSelectedSubShiftLength(column.duration);
      setSelectedSubShiftStart(column.startTime);
      setMaxPatients(column.maxPatients);
      setMaxAcuity(getAcuityByValue(column.maxAcuity));
    } else {
      setSelectedSubShiftLength(" ");
      setSelectedSubShiftStart(" ");
      setMaxPatients(" ");
      setMaxAcuity(null);
      setStartError(false);
      setStartErrorText("");
    }
  };

  const changeSelectedSubShiftStart = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue)) {
      return;
    } else if (numericValue < 0 && numericValue >= -1) {
      numericValue = numericValue + 24;
    } else if (numericValue > 23.75 && numericValue <= 25) {
      numericValue = numericValue - 24;
    }

    setSelectedSubShiftStart(numericValue);

    if (
      !validateSubShiftStartDuration(
        selectedSubShift,
        numericValue,
        selectedSubShift.duration
      )
    ) {
      setStartError(true);
      setStartErrorText("Shift conflict!");
      return;
    } else {
      setStartError(false);
      setStartErrorText("");
    }
    selectedSubShift.startTime = numericValue;

    updateAvailableOptions();
    setRenderId(uuidv4());
  };

  const validateSubShiftStartDuration = (selectedSubShift, start, duration) => {
    let shift = findShiftForSubShift(selectedSubShift);
    let conflictFound = false;
    let usedHours = [];
    for (let i = 0; i < 96; i++) {
      usedHours.push(0);
    }

    for (let i = 0; i < shift.length; i++) {
      let subShift = shift[i];
      if (subShift === selectedSubShift) {
        continue;
      }

      let start = Math.floor(subShift.startTime * 4);
      for (let k = start; k < start + subShift.duration * 4; k++) {
        if (k >= 96) {
          usedHours[k - 96] = 1;
        } else {
          usedHours[k] = 1;
        }
      }
    }

    for (let i = start * 4; i < (start + duration) * 4; i++) {
      if (i >= 96) {
        conflictFound = conflictFound || usedHours[i - 96] === 1;
      } else {
        conflictFound = conflictFound || usedHours[i] === 1;
      }
    }

    return !conflictFound;
  };

  const changeSelectedSubShiftLength = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue) || numericValue < 4 || numericValue > 16) {
      return;
    }

    if (
      !validateSubShiftStartDuration(
        selectedSubShift,
        selectedSubShift.startTime,
        numericValue
      )
    ) {
      return;
    }

    selectedSubShift.duration = numericValue;

    setSelectedSubShiftLength(numericValue);

    updateAvailableOptions();
    setRenderId(uuidv4());
  };

  const updateAvailableOptions = () => {
    let shift = findShiftForSubShift(selectedSubShift);
    let startTime = findAvailableStartTime(shift);
    let unavailable = startTime === -1;
    let shiftLetter = selectedSubShift.name.substring(0, 1);
    if (unavailable) {
      for (let i = 0; i < availableShiftOptions.length; i++) {
        if (availableShiftOptions[i] === shiftLetter) {
          let shiftOption = availableShiftOptions[i];
          availableShiftOptions.splice(i, 1);
          unavailableShiftOptions.push(shiftOption);
          if (selectedShiftOption === shiftOption) {
            setSelectedShiftOption(availableShiftOptions[0]);
          }
        }
      }
    } else {
      for (let i = 0; i < unavailableShiftOptions.length; i++) {
        if (unavailableShiftOptions[i] === shiftLetter) {
          let shiftOption = unavailableShiftOptions[i];
          unavailableShiftOptions.splice(i, 1);
          let optionIndex = alphabet.indexOf(shiftLetter);
          for (let k = 0; k < availableShiftOptions.length; k++) {
            let otherIndex = alphabet.indexOf(availableShiftOptions[k]);
            if (otherIndex > optionIndex) {
              availableShiftOptions.splice(k, 0, shiftOption);
              break;
            }
          }
          break;
        }
      }
    }
  };

  const changeSelectedSubShiftMaxPatients = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue) || numericValue < 1 || numericValue > 10) {
      return;
    }

    setMaxPatients(numericValue);
    selectedSubShift.maxPatients = numericValue;
    setRenderId(uuidv4());
  };

  const changeSelectedSubShiftMaxAcuity = (newValue) => {
    selectedSubShift.maxAcuity = newValue.value;
    setMaxAcuity(newValue);
  };

  const editSubShiftPreload = () => {
    setShowEditPreload(!showEditPreload);
  };

  const editSchedPreload = () => {
    setShowEditSchedPreload(!showEditSchedPreload);
  };

  const editSchedOffload = () => {
    setShowEditSchedOffload(!showEditSchedOffload);
  };

  const handlePreloadClickAway = (selectedShift) => {
    if (selectedShift) {
      const preloadRule = getRuleFromRuleList("preload", selectedShift.rules);
      if (preloadRule && preloadRule.ruleSettings.Duration === 0) {
        let idx = selectedShift.rules.indexOf(preloadRule);
        selectedShift.rules.splice(idx, 1);
      }
    }
    setShowEditPreload(false);
  };

  const handleSchedulePreloadClickAway = (offload) => {
    let ruleName = offload ? "offload" : "preload";
    const preloadRule = getRuleFromRuleList(ruleName, schedule.rules);
    if (preloadRule && preloadRule.ruleSettings.Duration === 0) {
      let idx = schedule.rules.indexOf(preloadRule);
      schedule.rules.splice(idx, 1);
    }
    if (offload) {
      setShowEditSchedOffload(false);
    } else {
      setShowEditSchedPreload(false);
    }
  };

  //doesn't need to delete the rule can be handeled onClose inside
  const closeEditPreload = () => {
    setShowEditPreload(false);
  };

  const editSubShiftOffload = () => {
    setShowEditOffload(!showEditOffload);
  };

  const handleOffloadClickAway = (selectedShift) => {
    if (selectedShift) {
      const offloadRule = getRuleFromRuleList("offload", selectedShift.rules);
      if (offloadRule && offloadRule.ruleSettings.Duration === 0) {
        let idx = selectedShift.rules.indexOf(offloadRule);
        selectedShift.rules.splice(idx, 1);
      }
    }
    setShowEditOffload(false);
  };

  const closeEditOffload = () => {
    setShowEditOffload(false);
  };

  const onSaveClick = () => {
    schedule.name = scheduleName;
    schedule.shifts = [];
    const scheduleInfo = {
      Name: scheduleName,
      AssignmentMethod: schedule.assignmentMethod,
      Shifts: [],
      Rules: schedule.rules,
    };
    schedule.shiftsByPrefix.forEach((prefixList) => {
      prefixList.forEach((shift) => {
        let shiftInfo = {
          start: moment().startOf("day").add(shift.startTime, "hours").format(),
          duration: moment()
            .startOf("day")
            .add(shift.duration, "hours")
            .format("hh:mm:ss"),
          provider: {
            name: shift.name,
            providerType: 1,
            duration: -1,
            status: "available",
          },
          rules: shift.rules,
        };
        scheduleInfo.Shifts.push(shiftInfo);
      });
      //schedule.shifts = schedule.shifts.concat(prefixList);
    });

    if (onSave && scheduleName !== "") {
      onSave(scheduleInfo);
    }
  };

  const onCancelClick = () => {
    schedule.shiftsByPrefix.length = 0;
    for (let i = 0; i < originalSchedule.shiftsByPrefix.length; i++) {
      schedule.shiftsByPrefix.push(originalSchedule.shiftsByPrefix[i]);
    }

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className="flex-fullheight-container">
      <Header>Schedule Editor</Header>
      <Grid
        container
        justify="center"
        direction="row"
        spacing={portraitMode ? 2 : 4}
        style={portraitMode ? { marginTop: "8px" } : { marginTop: "4px" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {/* <Grid container justify="center">
            <Grid item>
              <Typography>
                Schedule Name:
              </Typography>
            </Grid>
            <Grid item>
              <TextField value={scheduleName} onChange={setScheduleName} />
            </Grid>
          </Grid> */}
          <TextField
            variant={"outlined"}
            label={"Schedule Name"}
            value={scheduleName}
            onChange={(event) => {
              setScheduleName(event.target.value);
            }}
          />
        </Grid>
        <Grid item style={{ marginLeft: "16px", marginRight: "16px" }}>
          <Grid
            container
            direction="row"
            style={portraitMode ? { marginTop: "0px" } : {}}
            spacing={portraitMode ? 1 : 2}
          >
            <Grid item>
              <Autocomplete
                id="provider-combo-box"
                options={availableShiftOptions}
                getOptionLabel={(option) => option}
                // classes={autocompleteClasses}
                value={selectedShiftOption}
                selectOnFocus
                handleHomeEndKeys
                clearOnBlur
                autoHighlight
                disableClearable
                autoSelect
                onChange={(event, newValue) => {
                  setSelectedShiftOption(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={
                      portraitMode
                        ? { style: { fontWeight: "600", fontSize: ".75rem" } }
                        : {
                            style: { fontWeight: "600", fontSize: "1.25rem" },
                          }
                    }
                  />
                )}
                aria-label="select shift letter"
                className={classes.shiftLetterSelector}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={addShift}
                style={{
                  height: "56px",
                  fontWeight: "600",
                  width: "112px",
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid item>
              <ErrorButton
                variant="contained"
                onClick={removeShift}
                disabled={
                  selectedSubShift === null || selectedSubShift === undefined
                }
                style={{ height: "56px", width: "112px" }}
              >
                Remove
              </ErrorButton>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                type="number"
                onChange={changeSelectedSubShiftStart}
                label="Start"
                value={selectedSubShiftStart}
                disabled={
                  selectedSubShift === null || selectedSubShift === undefined
                }
                error={startError}
                helperText={startErrorText}
                style={{ width: "80px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                type="number"
                onChange={changeSelectedSubShiftLength}
                label="Length"
                value={selectedSubShiftLength}
                disabled={
                  selectedSubShift === null || selectedSubShift === undefined
                }
                style={{ width: "100px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                type="number"
                onChange={changeSelectedSubShiftMaxPatients}
                label="Max Pts"
                value={maxPatients}
                disabled={
                  selectedSubShift === null || selectedSubShift === undefined
                }
                style={{ width: "100px" }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="acuity-combo-box"
                options={acuities}
                getOptionLabel={(option) => option.name}
                value={maxAcuity}
                selectOnFocus
                handleHomeEndKeys
                clearOnBlur
                autoHighlight
                autoSelect
                onChange={(event, newValue) => {
                  changeSelectedSubShiftMaxAcuity(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Max Acuity"
                  />
                )}
                aria-label="select acuity"
                disabled={
                  selectedSubShift === null || selectedSubShift === undefined
                }
                className={classes.maxAcuitySelector}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row">
        <Grid
          item
          style={portraitMode ? { width: "665px" } : { width: "1265px" }}
        >
          <Schedule
            schedule={schedule}
            editable={true}
            externalRenderId={renderId}
            onColumnSelected={onColumnSelected}
            dense={portraitMode === true}
            style={portraitMode ? { width: "665px" } : { width: "1265px" }}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row" spacing={2}>
        <ClickAwayListener
          onClickAway={() => handlePreloadClickAway(selectedSubShift)}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editSubShiftPreload}
              disabled={
                selectedSubShift === null || selectedSubShift === undefined
              }
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editPreloadRef}
            >
              Preload
            </Button>
            {showEditPreload ? (
              <PreloadOffloadEditDialog
                title="Preload"
                closeDialog={closeEditPreload}
                data={selectedSubShift.rules}
                ruleName="preload"
                portalRef={editPreloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => handleOffloadClickAway(selectedSubShift)}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editSubShiftOffload}
              disabled={
                selectedSubShift === null || selectedSubShift === undefined
              }
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editOffloadRef}
            >
              Offload
            </Button>
            {showEditOffload ? (
              <PreloadOffloadEditDialog
                title="Offload"
                closeDialog={closeEditOffload}
                ruleName="offload"
                data={selectedSubShift.rules}
                portalRef={editOffloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
        <Grid item>
          <Autocomplete
            id="assignment-method-combo-box"
            options={assignmentMethods}
            getOptionLabel={(option) => option.name}
            classes={autocompleteClasses}
            value={assignmentMethod}
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            autoHighlight
            disableClearable
            autoSelect
            onChange={(event, newValue) => {
              setAssignmentMethod(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: "600", fontSize: "1.25rem" },
                }}
                label="Assignment"
              />
            )}
            aria-label="select assignmentMethod"
            className={classes.assignmentMethodSelector}
          />
        </Grid>
        <Grid item>
          <SuccessButton
            variant="contained"
            style={{
              height: "56px",
              fontWeight: "600",
              width: "112px",
            }}
            onClick={onSaveClick}
          >
            Save
          </SuccessButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{ height: "56px", fontWeight: "600", width: "112px" }}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
        </Grid>
        <ClickAwayListener
          onClickAway={() => handleSchedulePreloadClickAway(false)}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editSchedPreload}
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editSchedPreloadRef}
            >
              Schedule Preload
            </Button>
            {showEditSchedPreload ? (
              <PreloadOffloadEditDialog
                title="Schedule Preload"
                data={schedule.rules}
                ruleName="preload"
                portalRef={editSchedPreloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => handleSchedulePreloadClickAway(true)}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editSchedOffload}
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editSchedOffloadRef}
            >
              Schedule Offload
            </Button>
            {showEditSchedOffload ? (
              <PreloadOffloadEditDialog
                title="Schedule Offload"
                ruleName="offload"
                data={schedule.rules}
                portalRef={editSchedOffloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
      </Grid>
    </div>
  );
};

export default ScheduleEditor;
