import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import { ErrorButton } from "./Buttons";

const useStyles = makeStyles((theme) => ({}));

export const DiscardScheduleDialog = (props) => {
  const { schedule, onDiscard, onCancel } = props;

  const discardClicked = () => {
    onDiscard(schedule);
  };

  const cancelClicked = () => {
    onCancel();
  };

  return (
    <Dialog open={schedule !== undefined}>
      <DialogTitle>Discard Schedule?</DialogTitle>
      <DialogContent>
        Would you like to discard the schedule named '{schedule.name}'? This
        action cannot be undone.
      </DialogContent>
      <DialogActions>
        <ErrorButton
          variant="contained"
          onClick={discardClicked}
          style={{ marginBottom: "16px", marginRight: "8px" }}
        >
          Discard
        </ErrorButton>
        <Button
          variant="contained"
          color="primary"
          onClick={cancelClicked}
          style={{ marginBottom: "16px", marginRight: "16px" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardScheduleDialog;
