import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ReadableDateTime } from "../utils/momentUtils";

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles({
  headerText: {
    fontWeight: "bold",
  },
});

const ScheduleTable = ({
  schedules,
  editOnClick,
  viewOnClick,
  deleteOnClick,
  onClick,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <BoldTableCell>Name</BoldTableCell>
            <BoldTableCell align="right">Last Updated</BoldTableCell>
            <BoldTableCell align="right">View</BoldTableCell>
            {editOnClick && <BoldTableCell align="right">Edit</BoldTableCell>}
            {deleteOnClick && (
              <BoldTableCell align="right">Delete</BoldTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th">{row.name}</TableCell>
              <TableCell align="right">
                {ReadableDateTime(row.lastUpdated)}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => viewOnClick(row)}>
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              {editOnClick && (
                <TableCell align="right">
                  <IconButton onClick={() => editOnClick(row)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              )}
              {deleteOnClick && (
                <TableCell align="right" onClick={() => deleteOnClick(row)}>
                  <IconButton>
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BoldTableCell = (props) => {
  const classes = useStyles();

  return (
    <TableCell {...props} className={classes.headerText}>
      {props.children}
    </TableCell>
  );
};

export default ScheduleTable;
