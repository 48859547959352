import moment from "moment";

export function ReadableDateTime(dateTime) {
  let momTime = moment(dateTime);
  if (momTime.isValid()) {
    return momTime.format("MM/DD/YYYY HH:mm:ss");
  } else {
    return "Invalid Date";
  }
}

export function ReadableLocalDateTime(dateTime) {
  let momTime = moment(dateTime);
  if (momTime.isValid()) {
    momTime = momTime.local();
    return momTime.format("MM/DD/YYYY HH:mm:ss");
  } else {
    return "Invalid Date";
  }
}

export function GetPeriodFromMomentTime(time) {
  if (time && moment.isMoment(time)) {
    let period = time.hour() * 2;
    if (time.minute() > 29) {
      period += 1;
    }
    console.log(period);
    return period;
  }
}

export function SetTimeToHalfHour(time) {
  if (time && moment.isMoment(time)) {
    if (time.minute() >= 30) {
      time.minute(30);
    } else {
      time.minute(0);
    }
  }
}
