import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";

import c3 from "c3";
import "c3/c3.css";

import { v4 as uuidv4 } from "uuid";

import { hours } from "../utils/constants";

import { Timeline } from "./Timeline";
import { useThemeContext } from "../ThemeProviderHost";

const useStyles = makeStyles((theme) => ({}));

export const Schedule = (props) => {
  const {
    editable,
    schedule,
    dense,
    onColumnSelected,
    externalRenderId,
  } = props;
  const classes = useStyles();
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(1);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {}, []);

  useEffect(() => {
    if (onColumnSelected) {
      onColumnSelected(selectedColumn);
    }
  }, [selectedColumn]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [externalRenderId]);

  if (schedule == null) {
    return <div />;
  }

  const onHoursChanged = (startHour, numHours) => {
    setStartHour(startHour);
    let endHour = startHour + numHours;
    if (endHour > 23) {
      endHour = endHour - 24;
    }
    setEndHour(endHour);
    setRenderId(uuidv4());
  };

  const onColumnClick = (column) => {
    if (!editable) {
      return;
    }

    if (column.selected) {
      column.selected = false;
      setSelectedColumn(undefined);
    } else {
      column.selected = true;
      setSelectedColumn(column);
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Timeline
        onHoursChanged={onHoursChanged}
        onColumnClick={onColumnClick}
        rowHeight="48"
        rows={schedule.shiftsByPrefix}
        selectedColumn={selectedColumn}
        scheduleRules={schedule.rules}
        dense={dense}
      />
      <CapacityChart
        startHour={startHour}
        endHour={endHour}
        renderId={renderId}
        shifts={schedule.shiftsByPrefix}
        dense={dense}
      />
    </div>
  );
};

export default Schedule;

export const CapacityChart = (props) => {
  const { startHour, endHour, shifts, dense, renderId } = props;
  const { usingDarkMode } = useThemeContext();
  const [chart, setChart] = useState(null);
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [numTicks, setNumTicks] = useState([]);

  useEffect(() => {
    const onWindowResize = () => {
      calcDisplayableTicks();
    };

    window.addEventListener("resize", onWindowResize);

    // setTimeout(() => {calcDisplayableTicks();}, 10);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    createChart();
  }, [shifts, numTicks, renderId, usingDarkMode]);

  useEffect(() => {
    calcDisplayableTicks();
  }, [dense]);

  const calcDisplayableTicks = (ignoreSet) => {
    let outerDiv = document.getElementById("outerChartDiv");
    if (outerDiv !== undefined && outerDiv !== null) {
      let availableWidth = outerDiv.offsetWidth - 62; // -112 for the arrow buttons and margin
      let displayableHours = Math.floor(availableWidth / 50);
      let maxHours = dense ? 12 : 24;
      displayableHours = Math.min(maxHours, Math.max(displayableHours, 7));
      let numTicks = [];
      for (let i = 0; i < displayableHours; i++) {
        numTicks.push(i);
      }
      setNumTicks(numTicks);
    }
    return numTicks;
  };

  const createChart = () => {
    if (!shifts) {
      return;
    }

    let hoursPerTick = dense ? 2 : 1;

    let unshiftedColumns = [];
    for (let i = 0; i < 96; i++) {
      unshiftedColumns.push(0);
    }

    for (let i = 0; i < shifts.length; i++) {
      let shiftSet = shifts[i];
      for (let k = 0; k < shiftSet.length; k++) {
        let shift = shiftSet[k];
        let unshiftedIndex = shift.startTime * 4;
        let preload = shift.preload;
        let offload = shift.offload;
        for (let m = 0; m < shift.duration; m = m + 0.25) {
          let patientNumber;
          if (
            preload &&
            preload.maxPatients &&
            preload.duration > 0 &&
            m < preload.duration
          ) {
            patientNumber = Number(preload.maxPatients);
          } else if (
            offload &&
            offload.maxPatients &&
            m >= shift.duration - offload.duration
          ) {
            patientNumber = Number(offload.maxPatients);
          } else {
            patientNumber = Number(shift.maxPatients);
          }

          if (!Number.isNaN(patientNumber)) {
            let index =
              unshiftedIndex <= 95 ? unshiftedIndex : unshiftedIndex - 96;
            unshiftedColumns[index] += patientNumber;
          }

          unshiftedIndex++;
        }
      }
    }

    let displayedHours =
      startHour > endHour ? endHour + 24 - startHour : endHour - startHour;

    if (dense) {
      displayedHours = displayedHours * 2 + 1;
    }

    let colValue = unshiftedColumns.pop();
    unshiftedColumns.unshift(colValue);
    colValue = unshiftedColumns.pop();
    unshiftedColumns.unshift(colValue);
    if (dense) {
      colValue = unshiftedColumns.pop();
      unshiftedColumns.unshift(colValue);
      colValue = unshiftedColumns.pop();
      unshiftedColumns.unshift(colValue);
    }
    for (let i = 0; i < startHour; i++) {
      let value = unshiftedColumns.shift();
      unshiftedColumns.push(value);
      value = unshiftedColumns.shift();
      unshiftedColumns.push(value);
      value = unshiftedColumns.shift();
      unshiftedColumns.push(value);
      value = unshiftedColumns.shift();
      unshiftedColumns.push(value);
    }

    let maxPatients = 0;
    for (let i = 0; i < unshiftedColumns.length; i++) {
      maxPatients = Math.max(maxPatients, unshiftedColumns[i]);
    }

    for (let i = 0; i < 23 - displayedHours; i++) {
      unshiftedColumns.pop();
      unshiftedColumns.pop();
      unshiftedColumns.pop();
      unshiftedColumns.pop();
    }

    unshiftedColumns.unshift("Max Patients");

    let yAxisMax = maxPatients;
    let yAxisMin = 0;
    let yAxisTick = maxPatients / 2;

    let chartElement = document.getElementById(chartId);
    if (!chartElement) {
      return;
    }

    if (chart !== null && chart !== undefined) {
      chart.load({ columns: [unshiftedColumns] });
      chart.axis.range({
        min: {
          y: yAxisMin,
        },
        max: { y: yAxisMax },
      });
    } else {
      let c3chart = c3.generate({
        bindto: `#${chartId}`,
        data: {
          columns: [unshiftedColumns],
          type: "area",
        },
        zoom: { enabled: true },
        axis: {
          x: {
            height: 0,
            tick: { values: [] },
            label: "Max Patients",
            padding: { left: 0 },
          },
          y: {
            max: yAxisMax,
            min: yAxisMin,
            padding: { top: 0, bottom: 0 },
            tick: { count: 3, outer: false },
          },
        },
        transition: { duration: 0 },
        point: { show: false },
        size: {
          height: "100",
        },
        padding: {
          top: 0,
          bottom: 0,
          left: 32,
          right: -4,
        },
        legend: {
          hide: true,
        },
      });
      setChart(c3chart);
    }
  };

  return (
    <div id="outerChartDiv">
      <div
        id={chartId}
        style={{
          width: `${numTicks.length * 50 + 38}px`,
          height: "70px",
          // marginLeft: "32px",
          // marginRight: "32px",
        }}
        className={usingDarkMode ? "c3-dark-mode" : ""}
      />
    </div>
  );
};
