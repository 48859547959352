import React, { useState, useContext, Component, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import history from "./utils/history";

import Layout from "./components/Layout";
import Loading from "./components/Loading";
import { useAuth0 } from "./react-auth0-spa";

import Home from "./views/Home";
import Staffing from "./views/Staffing";
import Login from "./views/Login";
import Assignment from "./views/Assignment";
import ShiftHistory from "./views/ShiftHistory";
import AssignmentHistory from "./views/AssignmentHistory";
import ManageUsers from "./views/ManageUsers";
import Account from "./views/Account";
import UpNext from "./views/UpNext";
import LiveAnalysis, {
  LiveScheduleAnalysis,
} from "./views/LiveScheduleAnalysis";
import SimulationAnalysis from "./views/SimulationAnalysis";
import { SimAnalyzerLink } from "./views/SimAnalyzer";
import Schedules from "./views/Schedules";
import { ScheduleViewLink } from "./views/ScheduleView";
import Datasets, { DatasetView } from "./views/Datasets";
import OptimizerHome from "./views/OptimizerHome";
import { OptimizerResultsViewHOC } from "./views/OptimizerResultView";

import "./App.css";

function App(props) {
  const { loading, isAuthenticated } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <Layout>
        <Switch>
          {/* <AuthorizedRoute path="/staffing" exact component={Staffing} /> */}
          <AuthorizedRoute path="/assignment" exact component={Assignment} />
          <AuthorizedRoute
            path="/shiftHistory"
            exact
            component={ShiftHistory}
          />
          <AuthorizedRoute
            path="/assignmentHistory"
            exact
            component={AssignmentHistory}
          />
          <AuthorizedRoute path="/manageUsers" exact component={ManageUsers} />
          <AuthorizedRoute path="/account" exact component={Account} />
          <AuthorizedRoute path="/upNext" exact component={UpNext} />
          <AuthorizedRoute
            path="/liveAnalysis"
            exact
            component={LiveScheduleAnalysis}
          />
          <AuthorizedRoute path="/simAnalyzer" component={SimAnalyzerLink} />
          <AuthorizedRoute path="/simulation" component={SimulationAnalysis} />
          <AuthorizedRoute path="/schedules" component={Schedules} />
          <AuthorizedRoute path="/scheduleView" component={ScheduleViewLink} />
          <AuthorizedRoute path="/datasets" component={DatasetView} />
          <AuthorizedRoute path="/staffing" component={OptimizerHome} />
          <AuthorizedRoute
            path="/optimizerResults/:id"
            component={OptimizerResultsViewHOC}
          />
          <Route path="/login" component={Login} />
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;

const AuthorizedRoute = ({ component: Component, ...props }) => {
  const { loading, isAuthenticated, isAuthorized } = useAuth0();
  return (
    <Route
      {...props}
      render={(props) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
