import React, { useState, useEffect } from "react";
import { Upload } from "./upload/Upload";
import OuterDiv from "./OuterDiv";
import Header from "./Header";
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { LogoButton } from "./Buttons";
import { SuccessSnack } from "./Snackbars";

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: "center",
  },
}));

export const UploadDataset = (props) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [useMatrix, setUseMatrix] = useState(false);
  const [successSnack, setSuccessSnack] = useState(false);
  const classes = useStyles();

  const successFunc = () => {
    setOpen(false);
    //trigger success snacc
    setSuccessSnack(true);
    setName("");
  };

  return (
    <OuterDiv>
      <Upload
        open={open}
        name={name}
        title={"Upload Datasets"}
        hide={() => {
          setOpen(false);
        }}
        multiple={false}
        useMatrix={useMatrix}
        returnResult={successFunc}
      />
      <SuccessSnack
        message={"Dataset successfully uploaded"}
        open={successSnack}
        onClose={() => setSuccessSnack(false)}
      />
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        spacing={1}
        style={{ padding: ".75em", marginTop: ".25em" }}
        direction="column"
      >
        <Grid item className={classes.textCenter} style={{ width: "70%" }}>
          <TextField
            label="Dataset Name"
            value={name}
            placeholder="Enter a Unique Name"
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Typography>
            <strong>Note:</strong> Files must be uploaded in CSV format and must
            at minimum contain: <br />
            Column Name (Datatype) <br />
            ArrivalTime (date time)
            <br />
            Acuity (integer) <br />
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={useMatrix}
                onChange={(e) => setUseMatrix(e.target.checked)}
                name="useMatrix"
                color="primary"
              />
            }
            label="Use historic BMS Matrix Fileformat"
          />
        </Grid>
        <Grid
          item
          container
          justify={"center"}
          className={classes.textCenter}
          spacing={2}
        >
          <Grid item>
            <LogoButton
              onClick={() => {
                setOpen(!open);
              }}
              disabled={name === undefined || name === ""}
            >
              Upload Files
            </LogoButton>
          </Grid>
          {props.close && (
            <Grid item className={classes.textCenter}>
              <LogoButton onClick={props.close}>Close Uploader</LogoButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </OuterDiv>
  );
};

export default UploadDataset;
