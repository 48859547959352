import React, { useState, useEffect, useRef } from "react";

import PropTypes from "prop-types";

import {
  makeStyles,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
  ClickAwayListener,
  Portal,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import AccessTime from "@material-ui/icons/AccessTime";
import Hotel from "@material-ui/icons/Hotel";

import clsx from "clsx";

import moment, { duration } from "moment";
import MomentUtils from "@date-io/moment";

import { acuities, numPatientOptions } from "../utils/constants";

import { useLayoutContext } from "./Layout";

import { LogoButton } from "./Buttons";
import { RoomSelectionDialog } from "./RoomSelectionDialog";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    position: "relative",
  },
  headerText: {
    color: theme.palette.background.default,
    marginLeft: "16px",
  },
  headerDiv: {
    backgroundColor: theme.palette.text.primary,
    paddingTop: "6px",
    paddingBottom: "6px",
    width: "100%",
  },
  doctorLarge: {
    width: "160px",
  },
  doctorSmall: {
    width: "110px",
  },
  acuityLarge: { width: "210px" },
  acuitySmall: { width: "175px" },
  roomLarge: { width: "210px" },
  roomSmall: { width: "175px" },
  numPatientsLarge: { width: "170px" },
  numPatientsSmall: { width: "140px" },
  providerPadding: {
    paddingBottom: "84px",
  },
  providerControls: {
    marginTop: "16px",
    marginBottom: "16px",
    position: "relative",
  },
  controlLabelLarge: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.334",
  },
  controlLabelSmall: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.1",
  },
  assignButton: {
    width: "200px",
    marginTop: "32px",
    fontSize: "1.5em",
    left: "calc(50% - 100px)",
    position: "absolute",
  },
}));

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

const useRoomSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
  input: {
    marginTop: "-4px",
  },
}));

export const AssignmentControls = (props) => {
  const {
    provider,
    setProvider,
    availableProviders,
    assignmentTime,
    setAssignmentTime,
    acuity,
    setAcuity,
    room,
    setRoom,
    availableRooms,
    numPatients,
    setNumPatients,
    assignProvider,
    showTitle,
    showAssign,
    useRoomSelector,
  } = props;

  const { portraitMode } = useLayoutContext();

  const classes = useStyles();
  const autocompleteLargeClasses = useAutocompleteLargeStyles();
  const autocompleteSmallClasses = useAutocompleteSmallStyles();
  const roomSmallClasses = useRoomSmallStyles();

  const [assignmentTimeLabel, setAssignmentTimeLabel] = useState(
    "Use Current Time"
  );
  const [showRoomSelector, setShowRoomSelector] = useState(false);

  const roomSelectorPortalRef = useRef();

  useEffect(() => {
    if (assignmentTime === null) {
      setAssignmentTimeLabel("Use Current Time");
    } else {
      setAssignmentTimeLabel("");
    }
  }, [assignmentTime]);

  const toggleRoomSelector = (event) => {
    setShowRoomSelector(!showRoomSelector);
  };

  const closeRoomSelector = (room) => {
    setShowRoomSelector(false);
    if (room !== null) {
      setRoom(room);
    }
  };

  const handleClickAway = () => {
    setShowRoomSelector(false);
  };

  return (
    <>
      {showTitle && (
        <div className={classes.headerDiv}>
          <Typography variant="h5" className={classes.headerText}>
            ASSIGN DOCTOR
          </Typography>
        </div>
      )}
      <div
        className={clsx(
          classes.providerControls,
          showAssign && classes.providerPadding
        )}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={portraitMode ? 2 : 4}
          justify="center"
        >
          <Grid item>
            <div
              variant="h5"
              className={clsx(
                portraitMode
                  ? classes.controlLabelSmall
                  : classes.controlLabelLarge
              )}
            >
              Doctor
            </div>
            <Autocomplete
              id="provider-combo-box"
              options={availableProviders}
              getOptionLabel={(option) => option.name}
              classes={
                portraitMode
                  ? autocompleteSmallClasses
                  : autocompleteLargeClasses
              }
              value={provider}
              selectOnFocus
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              disableClearable
              autoSelect
              onChange={(event, newValue) => {
                setProvider(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={
                    portraitMode
                      ? { style: { fontWeight: "600", fontSize: ".75rem" } }
                      : {
                          style: { fontWeight: "600", fontSize: "1.25rem" },
                        }
                  }
                />
              )}
              aria-label="select provider"
              className={clsx(
                portraitMode ? classes.doctorSmall : classes.doctorLarge
              )}
            />
          </Grid>
          <Grid item>
            <div
              variant="h5"
              className={clsx(
                portraitMode && classes.controlLabelSmall,
                !portraitMode && classes.controlLabelLarge
              )}
            >
              Assignment Time
            </div>
            <Typography variant="h5" className={classes.assignmentTimeValue}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  clearable
                  value={assignmentTime}
                  onChange={setAssignmentTime}
                  ampm={false}
                  format="HH:mm:ss MM-DD-YY"
                  label={assignmentTimeLabel}
                  aria-label="assignment time"
                  InputProps={{
                    style: portraitMode
                      ? {
                          fontSize: "1.25em",
                          fontWeight: "600",
                          height: "61px",
                        }
                      : { fontSize: ".75em", fontWeight: "600" },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "-8px" }}
                      >
                        <IconButton>
                          <AccessTime />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={
                    portraitMode
                      ? {
                          style: {
                            fontWeight: "600",
                            fontSize: "large",
                            marginTop: "3px",
                          },
                        }
                      : {
                          style: {
                            fontWeight: "600",
                            fontSize: "large",
                            marginTop: "6px",
                          },
                        }
                  }
                  style={
                    portraitMode
                      ? { fontSize: "1.25rem", width: "295px", height: "61px" }
                      : { fontSize: "1.5em", width: "325px" }
                  }
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Typography>
          </Grid>
          <Grid item>
            <div
              variant="h5"
              className={clsx(
                portraitMode && classes.controlLabelSmall,
                !portraitMode && classes.controlLabelLarge
              )}
            >
              Acuity
            </div>
            <Autocomplete
              id="acuity-combo-box"
              options={acuities}
              getOptionLabel={(option) => option.name}
              classes={
                portraitMode
                  ? autocompleteSmallClasses
                  : autocompleteLargeClasses
              }
              value={acuity}
              selectOnFocus
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              disableClearable
              autoSelect
              onChange={(event, newValue) => {
                setAcuity(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    style: { fontWeight: "600", fontSize: "1.25rem" },
                  }}
                />
              )}
              aria-label="select acuity"
              className={clsx(
                portraitMode ? classes.acuitySmall : classes.acuityLarge
              )}
            />
          </Grid>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grid item style={{ position: "relative" }}>
              <div
                variant="h5"
                className={clsx(
                  portraitMode && classes.controlLabelSmall,
                  !portraitMode && classes.controlLabelLarge
                )}
              >
                Room
              </div>
              <Autocomplete
                id="room-combo-box"
                options={availableRooms}
                getOptionLabel={(option) => option.name}
                classes={
                  portraitMode ? roomSmallClasses : autocompleteLargeClasses
                }
                value={room}
                selectOnFocus
                handleHomeEndKeys
                clearOnBlur
                autoHighlight
                autoSelect
                disableClearable
                forcePopupIcon
                onChange={(event, newValue) => {
                  setRoom(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{
                      style: { fontWeight: "600", fontSize: "1.25rem" },
                    }}
                    InputProps={
                      useRoomSelector
                        ? {
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                <Tooltip title="Toggle Room Selector">
                                  <IconButton
                                    onClick={toggleRoomSelector}
                                    style={
                                      portraitMode
                                        ? {
                                            marginRight: "-32px",
                                            marginTop: "-3px",
                                          }
                                        : { marginRight: "-32px" }
                                    }
                                  >
                                    <Hotel />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ),
                          }
                        : { ...params.InputProps }
                    }
                  />
                )}
                aria-label="select room"
                className={clsx(
                  portraitMode ? classes.roomSmall : classes.roomLarge
                )}
                ref={roomSelectorPortalRef}
              />
              {showRoomSelector ? (
                <RoomSelectionDialog
                  closeDialog={closeRoomSelector}
                  availableRooms={availableRooms}
                  room={room}
                  portalRef={roomSelectorPortalRef}
                />
              ) : (
                <div />
              )}
            </Grid>
          </ClickAwayListener>
          <Grid item>
            <div
              variant="h5"
              className={clsx(
                portraitMode && classes.controlLabelSmall,
                !portraitMode && classes.controlLabelLarge
              )}
            >
              # of Patients
            </div>
            <Autocomplete
              id="numPatients-combo-box"
              options={numPatientOptions}
              classes={
                portraitMode
                  ? autocompleteSmallClasses
                  : autocompleteLargeClasses
              }
              value={numPatients}
              selectOnFocus
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              autoSelect
              disableClearable
              onChange={(event, newValue) => {
                setNumPatients(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    style: { fontWeight: "600", fontSize: "1.25rem" },
                  }}
                />
              )}
              aria-label="select number of patients"
              className={clsx(
                portraitMode
                  ? classes.numPatientsSmall
                  : classes.numPatientsLarge
              )}
            />
          </Grid>
        </Grid>
        {showAssign && (
          <LogoButton
            variant="contained"
            className={classes.assignButton}
            id="assignButton"
            onClick={assignProvider}
          >
            Assign
          </LogoButton>
        )}
      </div>
    </>
  );
};

AssignmentControls.propTypes = {
  provider: PropTypes.object,
  setProvider: PropTypes.func.isRequired,
  availableProviders: PropTypes.array.isRequired,
  assignmentTime: PropTypes.object,
  setAssignmentTime: PropTypes.func.isRequired,
  acuity: PropTypes.object,
  setAcuity: PropTypes.func.isRequired,
  room: PropTypes.object,
  setRoom: PropTypes.func.isRequired,
  availableRooms: PropTypes.array.isRequired,
  numPatients: PropTypes.string,
  setNumPatients: PropTypes.func.isRequired,
  assignProvider: PropTypes.func,
  openRoomSelector: PropTypes.func,
  showTitle: PropTypes.bool,
  showAssign: PropTypes.bool,
  useRoomSelector: PropTypes.bool,
};
