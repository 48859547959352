import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

import Api from "./services/Api";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [user, setUser] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const { audience } = initOptions;

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      try {
        await auth0FromHook
          .getTokenSilently({ audience: audience })
          .then(async (token) => {
            setAccessToken(token);
            Api.setAuthorizationHeader(token);
            let data = await Api.getUserInfo();
            if (data == null) {
              setIsAuthorized(false);
            }
            setUserInfo(data);
          });

        // let token = await auth0FromHook.getTokenSilently({
        //   audience: audience,
        // });
        // setAccessToken(token);
        // await Api.setAuthorizationHeader(token);
        // let data = await Api.getUserInfo(); setUserInfo(data);
      } catch {}
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      setEmailVerified(user.email_verified);
    }
  }, [user]);

  useEffect(() => {}, [accessToken]);

  useEffect(() => {
    if (userInfo !== null && userInfo !== undefined && emailVerified === true) {
      setIsAuthorized(true);
    }
  }, [userInfo, emailVerified]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isAuthorized,
        emailVerified,
        user,
        userInfo,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        accessToken,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => {
          auth0Client.logout(...p);
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
