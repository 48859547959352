import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  IconButton,
  Typography,
  Grid,
  Button,
  TableRow,
  TableCell,
} from "@material-ui/core";

import MUIDataTables from "mui-datatables";

import moment from "moment";

import Api from "../services/Api";

import { useLayoutContext } from "../components/Layout";

const columns = [
  "Email",
  "First",
  "Middle",
  "Last",
  "Organization",
  "Last Login",
  "Edit User",
];

const useStyles = makeStyles((theme) => ({}));

export const ManageUsers = (props) => {
  const classes = useStyles();
  const { setCurrentView } = useLayoutContext();
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [addPanelShown, setAddPanelShown] = useState(false);
  const [editPanelShown, setEditPanelShown] = useState(false);

  useEffect(() => {
    setCurrentView("ManageUsers");
    getUsers();
  }, []);

  useEffect(() => {
    let data = [];
    if (users !== null && users !== undefined) {
      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        let info = [];
        info.push(user.email);
        info.push(user.first);
        info.push(user.middle);
        info.push(user.last);
        info.push(user.organization ? user.organization.name : "");
        info.push(
          user.lastLogin
            ? moment(user.lastLogin).format("YYYY-MM-DD HH:mm:ss")
            : "Never"
        );
        info.push(i);
        data.push(info);
      }
    }

    setData(data);
  }, [users]);

  const onEdit = (index) => {};

  const options = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [email, first, middle, last, organization, lastLogin, index] = data;
      return (
        <UserDetailsRow
          key={index}
          classes={classes}
          email={email}
          first={first}
          middle={middle}
          last={last}
          organization={organization}
          lastLogin={lastLogin}
          permissions={"*"}
          index={index}
          onEdit={onEdit}
        />
      );
    },
  };

  const getUsers = async () => {
    let awaitGetUsers = Api.getUsers();
    awaitGetUsers.then((users) => {
      setUsers(users);
    });
  };

  return <div className="flex-fullHeight-container"></div>;
};

export default ManageUsers;

const UserDetailsRow = (props) => {
  const {
    email,
    first,
    middle,
    last,
    organization,
    lastLogin,
    index,
    permissions,
    onEdit,
  } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  return (
    <TableRow>
      <TableCell size="small">{email}</TableCell>
      <TableCell size="small">{first}</TableCell>
      <TableCell size="small">{middle}</TableCell>
      <TableCell size="small">{last}</TableCell>
      <TableCell size="small">{organization}</TableCell>
      <TableCell size="small">{lastLogin}</TableCell>
      <TableCell size="small">{permissions}</TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-6px" }} onClick={onEditClick}>
          Click to Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};
