import React, { useState, useEffect } from "react";

import moment from "moment";

import {
  makeStyles,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
  withStyles,
  Grid,
} from "@material-ui/core";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { v4 as uuidv4 } from "uuid";

import { useThemeContext } from "../ThemeProviderHost";
import { hours } from "../utils/constants";
import { getRulesByTypeAsObject } from "../utils/utilities";

const useStyles = makeStyles((theme) => ({
  timelineTickLabel: {
    width: "50px",
    color: theme.palette.text.primary,
  },
}));

const lightLineStyle = { stroke: "rgb(58,58,60)", strokeWidth: "3" };
const lightTopTickStyle = { stroke: "rgb(58,58,60)", strokeWidth: "1" };
const lightBottomTickStyle = { stroke: "rgb(183,183,185)", strokeWidth: "1" };

const darkLineStyle = { stroke: "rgb(241,242,246)", strokeWidth: "3" };
const darkTopTickStyle = { stroke: "rgb(241,242,246)", strokeWidth: "1" };
const darkBottomTickStyle = { stroke: "rgb(80,80,81)", strokeWidth: "1" };

const TimelineSegmentSvg = (props) => {
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  return (
    <svg width="50" height="25" id={renderId}>
      <line
        x1="25"
        y1="15"
        x2="25"
        y2="20"
        style={usingDarkMode ? darkTopTickStyle : lightTopTickStyle}
      />
      <line
        x1="25"
        y1="20"
        x2="25"
        y2="25"
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
      <line
        x1="50"
        y1="17"
        x2="50"
        y2="20"
        style={usingDarkMode ? darkTopTickStyle : lightTopTickStyle}
      />
      <line
        x1="50"
        y1="20"
        x2="50"
        y2="25"
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
      <line
        x1="00"
        y1="20"
        x2="50"
        y2="20"
        style={usingDarkMode ? darkLineStyle : lightLineStyle}
      />
    </svg>
  );
};

const TimelineEndSegmentSvg = (props) => {
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  return (
    <svg width="50" height="25" id={renderId}>
      <line
        x1="25"
        y1="15"
        x2="25"
        y2="20"
        style={usingDarkMode ? darkTopTickStyle : lightTopTickStyle}
      />
      <line
        x1="25"
        y1="20"
        x2="25"
        y2="25"
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
      <line
        x1="00"
        y1="20"
        x2="50"
        y2="20"
        style={usingDarkMode ? darkLineStyle : lightLineStyle}
      />
    </svg>
  );
};

const TimelineBottomLineSvg = (props) => {
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {}, [usingDarkMode]);

  return (
    <svg width="50" height="3" id={renderId}>
      <line
        x1="0"
        y1="0"
        x2="50"
        y2="0"
        style={usingDarkMode ? darkLineStyle : lightLineStyle}
      />
    </svg>
  );
};

const TimelineSegmentBackgroundSvg = (props) => {
  const { height } = props;
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  return (
    <svg width="50" height={height} id={renderId}>
      <line
        x1="25"
        y1="0"
        x2="25"
        y2={height}
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
      <line
        x1="50"
        y1="0"
        x2="50"
        y2={height}
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
    </svg>
  );
};

const TimelineEndSegmentBackgroundSvg = (props) => {
  const { height } = props;
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  return (
    <svg width="50" height={height} id={renderId}>
      <line
        x1="25"
        y1="0"
        x2="25"
        y2={height}
        style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle}
      />
      {/* <line x1="50" y1="0" x2="50" y2={height} style={usingDarkMode ? darkBottomTickStyle : lightBottomTickStyle} /> */}
    </svg>
  );
};

export const Timeline = (props) => {
  const {
    onHoursChanged,
    onColumnClick,
    rows,
    rowHeight,
    dense,
    scheduleRules,
  } = props;
  const classes = useStyles();
  const [startIndex, setStartIndex] = useState(0);
  const [renderId, setRenderId] = useState(uuidv4());
  const [numTicks, setNumTicks] = useState([]);

  const onHoursChangedBase = (startIndex, numHours) => {
    if (onHoursChanged) {
      onHoursChanged(startIndex, numHours);
    }
    setStartIndex(startIndex);
    setNumTicks(numHours);
  };

  return (
    <>
      <TimelineHeader onHoursChanged={onHoursChangedBase} dense={dense} />
      {rows.map((row) => {
        let columnLetter = row.length > 0 ? row[0].name.substring(0, 1) : "?";
        return (
          <TimelineRow
            row={row}
            scheduleRules={scheduleRules}
            height={rowHeight}
            dense={dense}
            startIndex={startIndex}
            numTicks={numTicks}
            onColumnClick={onColumnClick}
            key={columnLetter}
          />
        );
      })}
      <TimelineFooter dense={dense} />
    </>
  );
};

export const TimelineHeader = (props) => {
  const { onHoursChanged, dense } = props;
  const classes = useStyles();
  const [startIndex, setStartIndex] = useState(0);
  const [renderId, setRenderId] = useState(uuidv4());
  const [numTicks, setNumTicks] = useState([]);

  useEffect(() => {
    calcDisplayableTicks();

    const onWindowResize = () => {
      calcDisplayableTicks();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (onHoursChanged) {
      onHoursChanged(startIndex, numTicks.length);
    }
  }, [startIndex, numTicks]);

  useEffect(() => {
    calcDisplayableTicks();
  }, [dense]);

  const calcDisplayableTicks = () => {
    let outerDiv = document.getElementById("outerTimelineDiv");
    if (outerDiv !== undefined && outerDiv !== null) {
      let availableWidth = outerDiv.offsetWidth - 112; // -112 for the arrow buttons and margin
      let displayableHours = Math.floor(availableWidth / 50);
      let maxHours = dense ? 11 : 23;
      displayableHours = Math.min(maxHours, Math.max(displayableHours, 7));
      let numTicks = [];
      for (let i = 0; i < displayableHours; i++) {
        numTicks.push(i);
      }

      setNumTicks(numTicks);
      setRenderId(uuidv4());
    }
  };

  const getHourLabels = () => {
    let labels = [];
    for (let i = 0; i <= numTicks.length; i++) {
      let index;
      if (dense) {
        index = startIndex + i * 2;
      } else {
        index = startIndex + i;
      }

      if (index >= hours.length) {
        index = index - hours.length;
      }
      labels.push(hours[index]);
    }

    return labels;
  };

  const onBackClicked = () => {
    let newIndex = startIndex - 1;
    if (newIndex < 0) {
      newIndex = 23;
    }
    setStartIndex(newIndex);
  };

  const onForwardClicked = () => {
    let newIndex = startIndex + 1;
    if (newIndex > 23) {
      newIndex = 0;
    }
    setStartIndex(newIndex);
  };

  return (
    <div id="outerTimelineDiv">
      <div id={renderId} style={{ display: "flex" }}>
        <IconButton
          onClick={onBackClicked}
          style={{ height: "32px", width: "32px" }}
        >
          <ChevronLeft style={{ height: "32px", width: "32px" }} />
        </IconButton>
        <div style={{ height: "32px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              marginBottom: "-12px",
            }}
          >
            {getHourLabels().map((value) => {
              return (
                <div className={classes.timelineTickLabel} key={value}>
                  <div>{value}</div>
                </div>
              );
            })}
          </div>
          <div>
            {numTicks.map((num) => {
              return <TimelineSegmentSvg key={num} />;
            })}
            <TimelineEndSegmentSvg />
          </div>
        </div>
        <IconButton
          onClick={onForwardClicked}
          style={{ height: "32px", width: "32px" }}
        >
          <ChevronRight style={{ height: "32px", width: "32px" }} />
        </IconButton>
      </div>
    </div>
  );
};

export const TimelineRow = (props) => {
  const {
    height,
    dense,
    row,
    startIndex,
    onColumnClick,
    scheduleRules,
  } = props;
  const classes = useStyles();
  const [numTicks, setNumTicks] = useState([]);
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {}, []);

  useEffect(() => {
    calcDisplayableTicks();

    const onWindowResize = () => {
      calcDisplayableTicks();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    setRenderId(uuidv4());
  }, []);

  useEffect(() => {
    calcDisplayableTicks();
  }, [dense]);

  const calcDisplayableTicks = (ignoreSet) => {
    let outerDiv = document.getElementById("outerTimelineDiv");
    if (outerDiv !== undefined && outerDiv !== null) {
      let availableWidth = outerDiv.offsetWidth - 112; // -112 for the arrow buttons and margin
      let displayableHours = Math.floor(availableWidth / 50);
      let maxHours = dense ? 11 : 23;
      displayableHours = Math.min(maxHours, Math.max(displayableHours, 7));
      let numTicks = [];
      for (let i = 0; i < displayableHours; i++) {
        numTicks.push(i);
      }
      setNumTicks(numTicks);
      setRenderId(uuidv4());
    }
    return numTicks;
  };

  /// This function is confusing. I'm sorry if you have to read it and try and understand how it works.
  const buildColumnElement = (column) => {
    if (column === undefined || column === null) {
      return <div />;
    }
    column.fullRules = column.rules.concat(scheduleRules);

    let startMoment = moment(column.start);
    if (isNaN(column.startTime) && startMoment.isValid()) {
      let durationMoment = moment.duration(column.duration);
      column.startPeriod =
        startMoment.minute() >= 30
          ? startMoment.hour() + 0.5
          : startMoment.hour();
      column.durationPeriod =
        durationMoment.minutes() >= 30
          ? durationMoment.hours() + 0.5
          : durationMoment.hours();
    } else {
      column.startPeriod = column.startTime;
      column.durationPeriod = column.duration;
    }

    let hoursPerTick = dense ? 2 : 1;

    let chartStart = dense ? startIndex - 1 : startIndex - 0.5;
    if (chartStart < 0) {
      chartStart = chartStart + 24;
    }
    let startHour = dense ? startIndex - 1 : startIndex - 0.5;
    if (startHour > column.startPeriod) {
      startHour = dense ? startIndex - 25 : startIndex - 24.5;
    }
    let chartLength = numTicks.length * hoursPerTick + (dense ? 2 : 1);
    let chartEnd = startHour + chartLength;
    let columnEnd = column.startPeriod + column.durationPeriod;

    let remainder = 0;
    if (startHour < 0 && chartStart < column.startPeriod) {
    } else if (columnEnd > chartEnd) {
      // && columnEnd < startHour) {
      remainder = columnEnd - chartEnd;
    }

    let hiddenHours =
      startHour < chartEnd ? startHour + 24 - chartEnd : startHour - chartEnd;

    // hiddenHours = dense ? hiddenHours - 1 : hiddenHours

    let leftDuration = remainder - hiddenHours;
    let rightPosition = (column.startPeriod - startHour) / hoursPerTick;
    if (startHour < 0 && column.startPeriod > chartStart) {
      // console.log("adjusting right position based on chartStart")
      rightPosition = (column.startPeriod - chartStart) / hoursPerTick;
    }

    rightPosition = rightPosition - 0.5; // : rightPosition;

    let ruleObj = getRulesByTypeAsObject(
      ["preload", "offload"],
      column.fullRules
    );

    // preload = (ruleObj.preload) ? ruleObj.preload : undefined;
    // offload = (ruleObj.offload) ? ruleObj.offload : undefined;

    // let preloadWidth = preload
    //   ? preload * (50 / hoursPerTick)
    //   : 0;
    let preloadWidth = 0;
    if (ruleObj.preload) {
      let preload = ruleObj.preload;
      let duration =
        preload.ruleSettings.Duration !== undefined
          ? preload.ruleSettings.Duration
          : 0.5;
      preloadWidth = duration * (50 / hoursPerTick);
    }
    // let offloadWidth = offload
    //   ? offload * (50 / hoursPerTick)
    //   : 0;
    let offloadWidth = 0;
    if (ruleObj.offload) {
      let offload = ruleObj.offload;
      let duration =
        offload.ruleSettings.Duration !== undefined
          ? offload.ruleSettings.Duration
          : 0.5;
      offloadWidth = duration * (50 / hoursPerTick);
    }

    // console.log(startIndex);
    // console.log(startHour);
    // console.log(chartStart);
    // console.log(chartEnd);
    // console.log(chartLength);
    // console.log(column.startPeriod);
    // console.log(rightPosition);
    // console.log(columnEnd);
    // console.log(remainder);
    // console.log(hiddenHours);
    // console.log(leftDuration);
    // console.log(preloadWidth);
    // console.log(offloadWidth);

    // console.log(column.durationPeriod);

    if (leftDuration > 0 && leftDuration < column.durationPeriod) {
      if (column.startPeriod <= chartEnd) {
        // console.log("left and right fragments");
        let baseLeftWidth = 50 * (leftDuration / hoursPerTick);
        let baseRightWidth =
          50 * ((column.durationPeriod - remainder) / hoursPerTick);
        return (
          <React.Fragment key={`${column.name}RL`}>
            <RightTimelineRowElementFragment
              left="32px"
              width={baseLeftWidth - offloadWidth}
              offloadWidth={offloadWidth}
              height={height}
              column={column}
              onClick={onColumnClick}
              // key={`${column.name}R`}
            />
            <LeftTimelineRowElementFragment
              left={57 + 50 * rightPosition}
              width={baseRightWidth - preloadWidth}
              preloadWidth={preloadWidth}
              height={height}
              column={column}
              onClick={onColumnClick}
              // key={`${column.name}L`}
            />
          </React.Fragment>
        );
      } else {
        // console.log("left partially obscured");
        let baseLeftWidth =
          50 * ((leftDuration + 1) / hoursPerTick) - offloadWidth;
        let leftWidth = hoursPerTick == 1 ? baseLeftWidth - 25 : baseLeftWidth;
        return (
          <RightTimelineRowElementFragment
            left="32px"
            width={leftWidth}
            offloadWidth={offloadWidth}
            height={height}
            column={column}
            onClick={onColumnClick}
            key={`${column.name}R`}
          />
        );
      }
    } else {
      if (column.startPeriod > chartEnd && !(column.startPeriod >= startHour)) {
        // console.log(column.startPeriod >= startHour);
        // console.log("bailout");
        return <div />;
      }
      let baseWidth = 50 * ((column.durationPeriod - remainder) / hoursPerTick);
      let width = hoursPerTick == 1 ? baseWidth : baseWidth - 25;
      if (remainder > 0 && remainder < column.durationPeriod) {
        // console.log("right partially obscured");
        return (
          <LeftTimelineRowElementFragment
            left={`${57 + 50 * rightPosition}px`}
            width={width + 25 - preloadWidth}
            preloadWidth={preloadWidth}
            height={height}
            column={column}
            onClick={onColumnClick}
            key={`${column.name}L`}
          />
        );
      } else {
        // console.log("full period displayed");
        if (remainder === column.durationPeriod) {
          // console.log("based on startHour");
          return (
            <FullTimelineRowElement
              left="32px"
              width={50 * ((column.durationPeriod - 1) / hoursPerTick)}
              preloadWidth={preloadWidth}
              offloadWidth={offloadWidth}
              height={height}
              column={column}
              onClick={onColumnClick}
              key={column.name}
            />
          );
        } else {
          // console.log("based on right position");
          return (
            <FullTimelineRowElement
              left={`${57 + 50 * rightPosition}px`}
              width={baseWidth - preloadWidth - offloadWidth}
              preloadWidth={preloadWidth}
              offloadWidth={offloadWidth}
              height={height}
              column={column}
              onClick={onColumnClick}
              key={column.name}
            />
          );
        }
      }
    }
  };

  return (
    <div id="outerTimelineRowDiv">
      <div id={renderId} style={{ display: "flex", position: "relative" }}>
        <div
          style={{
            maxHeight: "32px",
            maxWidth: "32px",
            minHeight: "32px",
            minWidth: "32px",
          }}
        >
          {props.startAdornment}
        </div>
        <div style={{ height: `${height}px` }}>
          <div>
            {numTicks.map((num) => {
              return <TimelineSegmentBackgroundSvg height={height} key={num} />;
            })}
            <TimelineEndSegmentBackgroundSvg height={height} />
          </div>
        </div>
        {row.map((column) => {
          // console.log(column);
          return buildColumnElement(column);
        })}
        <div
          style={{
            maxHeight: "32px",
            maxWidth: "30px",
            minHeight: "32px",
            minWidth: "30px",
          }}
        >
          {props.endAdornment}
        </div>
      </div>
    </div>
  );
};

export const TimelineFooter = (props) => {
  const { height, dense } = props;
  const classes = useStyles();
  const [numTicks, setNumTicks] = useState([]);
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    calcDisplayableTicks();

    const onWindowResize = () => {
      calcDisplayableTicks();
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    calcDisplayableTicks();
  }, [dense]);

  const calcDisplayableTicks = () => {
    let outerDiv = document.getElementById("outerTimelineDiv");
    if (outerDiv !== undefined && outerDiv !== null) {
      let availableWidth = outerDiv.offsetWidth - 112; // -112 for the arrow buttons and margin
      let displayableHours = Math.floor(availableWidth / 50);
      let maxHours = dense ? 11 : 23;
      displayableHours = Math.min(maxHours, Math.max(displayableHours, 7));
      let numTicks = [];
      for (let i = 0; i < displayableHours; i++) {
        numTicks.push(i);
      }
      setNumTicks(numTicks);
      setRenderId(uuidv4());
    }
  };

  return (
    <div id="outerTimelineBottomLineDiv" style={{ height: "3px" }}>
      <div id={renderId} style={{ display: "flex", height: "3px" }}>
        <div style={{ width: "32px", height: "3px" }} />
        <div style={{ height: "3px", marginTop: "-12px" }}>
          {numTicks.map((num) => {
            return <TimelineBottomLineSvg key={num} />;
          })}
          <TimelineBottomLineSvg />
        </div>
        <div style={{ width: "30px", height: "3px" }} />
      </div>
    </div>
  );
};

export const RightTimelineRowElementFragment = (props) => {
  const { usingDarkMode } = useThemeContext();
  let background = usingDarkMode ? "#8D99AE99" : "#40476D99";
  let selectedBackground = usingDarkMode ? "#F1F2F699" : "#8D99AE99";
  let foreground = "#F1F2F6";
  let selectedForeground = "#3A3A3C";
  const { left, width, height, column, offloadWidth, onClick } = props;

  const onClickBase = () => {
    onClick(column);
  };

  const borderWidth = column.selected ? 4 : 2; // -2 for the default border, -4 for selected;
  const divWidth = width + offloadWidth - borderWidth;
  const mainWidth = Math.max(width - borderWidth / 2, 0);

  return (
    <Tooltip title={<TimelineTooltip data={column} />}>
      <div
        style={{
          position: "absolute",
          left: left,
          top: column.selected ? "0px" : "1px",
          display: "flex",
          width: `${divWidth}px`,
          height: `${height - 4}px`,
          borderColor: column.selected ? "#4361EE" : "black",
          borderStyle: "solid",
          borderWidth: column.selected ? "2px" : "1px",
          textAlign: "center",
          fontSize: "1.25em",
          fontWeight: column.selected ? "600" : "400",
          zIndex: column.selected ? "10" : "0",
        }}
        onClick={onClickBase}
      >
        <div
          style={{
            flex: "1 0 auto",
            background: column.selected ? selectedBackground : background,
            width: mainWidth - (offloadWidth === 0 ? borderWidth / 2 : 0),
          }}
        />
        {width > 32 && (
          <div
            style={{
              lineHeight: `${height - 4}px`,
              position: "absolute",
              width: "100%",
              color: column.selected ? selectedForeground : foreground,
            }}
          >
            {column.name}
          </div>
        )}
        {offloadWidth > 0 && (
          <div
            style={{
              flex: "0 0 auto",
              background: "#C3120999",
              width: offloadWidth - borderWidth / 2,
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export const LeftTimelineRowElementFragment = (props) => {
  const { usingDarkMode } = useThemeContext();
  let background = usingDarkMode ? "#8D99AE99" : "#40476D99";
  let selectedBackground = usingDarkMode ? "#F1F2F699" : "#8D99AE99";
  let foreground = "#F1F2F6";
  let selectedForeground = "#3A3A3C";
  const { left, width, height, column, preloadWidth, onClick } = props;

  const onClickBase = () => {
    onClick(column);
  };

  const borderWidth = column.selected ? 4 : 2;
  const divWidth = width + preloadWidth - borderWidth; // -2 for the default border, -4 for selected;
  const mainWidth = Math.max(width - borderWidth / 2, 0);

  return (
    <Tooltip title={<TimelineTooltip data={column} />}>
      <div
        style={{
          position: "absolute",
          left: left,
          top: column.selected ? "0px" : "1px",
          width: `${divWidth}px`,
          height: `${height - 4}px`,
          borderColor: column.selected ? "#4361EE" : "black",
          borderStyle: "solid",
          borderWidth: column.selected ? "2px" : "1px",
          textAlign: "center",
          fontSize: "1.25em",
          fontWeight: column.selected ? "600" : "400",
          display: "flex",
          zIndex: column.selected ? "10" : "0",
        }}
        onClick={onClickBase}
      >
        {preloadWidth > 0 && (
          <div
            style={{
              flex: "0 0 auto",
              background: "#C3120999",
              width: preloadWidth - borderWidth / 2,
            }}
          />
        )}
        <div
          style={{
            flex: "1 0 auto",
            background: column.selected ? selectedBackground : background,
            width: mainWidth - (preloadWidth === 0 ? borderWidth / 2 : 0),
          }}
        />
        {width > 32 && (
          <div
            style={{
              lineHeight: `${height - 4}px`,
              position: "absolute",
              width: "100%",
              color: column.selected ? selectedForeground : foreground,
            }}
          >
            {column.name}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export const FullTimelineRowElement = (props) => {
  const { usingDarkMode } = useThemeContext();
  let background = usingDarkMode ? "#8D99AE99" : "#40476D99";
  let selectedBackground = usingDarkMode ? "#F1F2F699" : "#8D99AE99";
  let foreground = "#F1F2F6";
  let selectedForeground = "#3A3A3C";
  const {
    left,
    width,
    height,
    column,
    preloadWidth,
    offloadWidth,
    onClick,
  } = props;

  const onClickBase = () => {
    onClick(column);
  };

  const borderWidth = column.selected ? 4 : 2;
  const divWidth = width + preloadWidth + offloadWidth - borderWidth; // -2 for the default border, -4 for selected;

  return (
    <Tooltip title={<TimelineTooltip data={column} />}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          left: left,
          top: column.selected ? "0px" : "1px",
          width: `${divWidth}px`,
          height: `${height - 4}px`,
          borderColor: column.selected ? "#4361EE" : "black",
          borderStyle: "solid",
          borderWidth: column.selected ? "2px" : "1px",
          textAlign: "center",
          fontWeight: column.selected ? "600" : "400",
          fontSize: "1.25em",
          zIndex: column.selected ? "10" : "0",
        }}
        onClick={onClickBase}
      >
        {preloadWidth > 0 && (
          <div
            style={{
              flex: "0 0 auto",
              background: "#C3120999",
              width: preloadWidth - borderWidth / 2,
            }}
          />
        )}
        <div
          style={{
            flex: "1 0 auto",
            background: column.selected ? selectedBackground : background,
            width:
              width -
              (preloadWidth == 0 ? borderWidth / 2 : 0) -
              (offloadWidth == 0 ? borderWidth / 2 : 0),
          }}
        />
        {offloadWidth > 0 && (
          <div
            style={{
              flex: "0 0 auto",
              background: "#C3120999",
              width: offloadWidth - borderWidth / 2,
            }}
          />
        )}
        <div
          style={{
            lineHeight: `${height - 4}px`,
            position: "absolute",
            width: "100%",
            color: column.selected ? selectedForeground : foreground,
          }}
        >
          {column.name}
        </div>
      </div>
    </Tooltip>
  );
};

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#F1F2F6",
    color: "#3A3A3C",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
  },
}));

export const TimelineTooltip = (props) => {
  const { data } = props;
  let ruleObj = getRulesByTypeAsObject(["preload", "offload"], data.fullRules);
  let preload = ruleObj.preload
    ? {
        duration: 0.5,
        maxPatients: 1,
        maxAcuity: ruleObj.preload.ruleSettings.MaxAcuity,
      }
    : undefined;
  let offload = ruleObj.offload
    ? {
        duration: 0.5,
        maxPatients: 1,
        maxAcuity: ruleObj.offload.ruleSettings.MaxAcuity,
      }
    : undefined;

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">{data.name}</Typography>
        </Grid>
        <Grid container item direction="row" justify="space-evenly" spacing={2}>
          <Grid item style={{ width: "75px" }}>
            Start: {data.start}
          </Grid>
          <Grid item style={{ width: "75px" }}>
            Duration: {data.duration}
          </Grid>
          <Grid item style={{ width: "75px" }}>
            Max Pts: {data.maxPatients}
          </Grid>
        </Grid>
        {preload && (
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            spacing={2}
          >
            <Grid item style={{ width: "75px" }}>
              Preload: {preload.duration}
            </Grid>
            <Grid item style={{ width: "75px" }}>
              Max Pts: {preload.maxPatients}
            </Grid>
            <Grid item style={{ width: "75px" }}>
              Acuity: {preload.maxAcuity}
            </Grid>
          </Grid>
        )}
        {offload && (
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            spacing={2}
          >
            <Grid item style={{ width: "75px" }}>
              Offload: {offload.duration}
            </Grid>
            <Grid item style={{ width: "75px" }}>
              Max Pts: {offload.maxPatients}
            </Grid>
            <Grid item style={{ width: "75px" }}>
              Acuity: {offload.maxAcuity}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
