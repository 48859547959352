import React, { Component } from "react";
// import {
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Card,
//   CardBody
// } from "reactstrap";

const styles = {
  fileInput: {
    display: "none",
  },
  icon: {
    opacity: 0.3,
    height: "64px",
    width: "64px",
  },
};

export class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: false,
    };

    this.fileInputRef = React.createRef();
  }

  render() {
    return (
      <div
        className={`FileDropzone ${
          this.state.highlight ? "FileDropzoneHighlight" : ""
        }`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{
          cursor: this.props.disabled ? "default" : "pointer",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <input
          ref={this.fileInputRef}
          style={styles.fileInput}
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <img
          alt="upload"
          style={styles.icon}
          src="baseline-cloud_upload-24px.svg"
        />
        <span style={{ display: "block" }}>
          {(this.props.multiple && "Upload Files") || "Upload File"}
        </span>
      </div>
    );
  }

  openFileDialog = () => {
    if (this.props.disabled) {
      return;
    }

    this.fileInputRef.current.click();
  };

  onFilesAdded = (evt) => {
    if (this.props.disabled) {
      return;
    }

    const files = evt.target.files;

    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  };

  onDragOver = (evt) => {
    evt.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.setState({ highlight: true });
  };

  onDragLeave = () => {
    this.setState({ highlight: false });
  };

  onDrop = (evt) => {
    evt.preventDefault();

    if (this.props.disabled) {
      return;
    }

    const files = evt.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }

    this.setState({ highlight: false });
  };

  fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }

    return array;
  };
}
