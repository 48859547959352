import React, { useState, useEffect } from "react";

import { makeStyles, Typography, Button } from "@material-ui/core";

import flag from "../images/bmsflag.png";

import { useAuth0 } from "../react-auth0-spa";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    textAlign: "center",
    paddingTop: "8px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  loginButton: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  welcomeMessage: {
    color: theme.palette.text.primary,
  },
  welcomeAppName: {
    fontFamily: "Kanit",
    fontWeight: "600",
    display: "flex",
  },
  welcomeEDSpan: {
    color: "#BA1200",
    fontFamily: "Kanit",
    fontWeight: "600",
  },
  loginMessage: {
    color: theme.palette.text.primary,
  },
}));

export const Login = (props) => {
  const { isAuthenticated, loginWithRedirect, userInfo } = useAuth0();
  const classes = useStyles();
  const { from } = (props.location && props.location.state) || {
    from: { pathname: "/" },
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <div className={classes.loginContainer}>
        <Typography variant="h4" className={classes.welcomeMessage}>
          Welcome to{" "}
          <div className={classes.welcomeAppName}>
            <img style={{ height: "38px", marginBottom: "-5px" }} src={flag} />
            <div
              style={{
                marginLeft: "12px",
                marginTop: "-6px",
                letterSpacing: "4px",
                fontSize: "1.1em",
              }}
            >
              MATRIX
            </div>
          </div>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.loginButton}
          onClick={() => loginWithRedirect()}
        >
          Login
        </Button>
        <Typography variant="h5" className={classes.loginMessage}>
          Please login to continue.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default Login;
