import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ReadableDateTime } from "../utils/momentUtils";

import { TableCell } from "@material-ui/core";

export const BoldTableCell = (props) => {
  return (
    <TableCell {...props} style={{ fontWeight: "bold" }}>
      {props.children}
    </TableCell>
  );
};
