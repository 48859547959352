import React, { useState, useEffect, useRef } from "react";

import { ReadableLocalDateTime } from "../utils/momentUtils";

import {
  makeStyles,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { getAssignMethodByValue } from "../utils/constants";

export const OptimizerRequestList = (props) => {
  const { requests, onView, onDelete } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <BoldTableCell>Request Name</BoldTableCell>
            <BoldTableCell align="right">Status</BoldTableCell>
            <BoldTableCell align="right">Date Created</BoldTableCell>
            <BoldTableCell align="right">Assignment Method</BoldTableCell>
            <BoldTableCell align="right">View</BoldTableCell>
            <BoldTableCell align="right">Delete</BoldTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.statusText}</TableCell>
              <TableCell align="right">
                {ReadableLocalDateTime(row.dateRun)}
              </TableCell>
              <TableCell align="right">
                {getAssignMethodByValue(row.assignmentMethod).name}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onView(row)}>
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onDelete(row)}>
                  <DeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles({
  headerText: {
    fontWeight: "bold",
  },
});

const BoldTableCell = (props) => {
  const classes = useStyles();

  return (
    <TableCell {...props} className={classes.headerText}>
      {props.children}
    </TableCell>
  );
};
