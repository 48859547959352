import React, { useState, useEffect } from "react";

import moment, { duration } from "moment";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import AccessTime from "@material-ui/icons/AccessTime";

import Api from "../services/Api";

import { useLayoutContext } from "../components/Layout";
import ScheduleAnalysisCharts from "../components/ScheduleAnalysisCharts";

import BackArrow from "../components/BackArrow";
import OuterDiv from "../components/OuterDiv";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { LogoButton } from "../components/Buttons";

import {
  ApsMsdDisplay,
  ApsDisplay,
  AphMsdDisplay,
  AphDisplay,
  Apm15Display,
  Apm30Display,
  Apm60Display,
  PphDisplay,
  AcpMsdDisplay,
  Acps1Display,
  Acps2Display,
  Acps2pDisplay,
  Acps3Display,
  Acps4Display,
  Acps5Display,
  AssignmentListDisplay,
} from "../components/AnalysisDisplays";
import Schedule from "../components/Schedule";

const useStyles = makeStyles((theme) => ({
  scheduleName: {
    fontWeight: "600",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  closeAnalyzer: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  displayAll: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  descriptionText: {
    marginTop: "16px",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  datasetSelector: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "320px",
    "& .MuiInputBase-root": { height: "61px" },
  },
}));

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

export const ScheduleAnalyzer = (props) => {
  const { schedule, close, liveMode } = props;
  const classes = useStyles();
  const autocompleteSmallClasses = useAutocompleteSmallStyles();
  const autocompleteLargeClasses = useAutocompleteLargeStyles();
  const { portraitMode } = useLayoutContext();
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState({});
  const [datasets, setDatasets] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [proposedData, setProposedData] = useState(null);
  const today = moment().hour(0).minute(0).second(0);
  const lastMonth = moment(today).subtract("month", 1);
  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(today);
  const [useDate, setUseDate] = useState(Boolean(liveMode));
  const [noPatients, setNoPatients] = useState(false);
  const [readyToAnalyze, setReadyToAnalyze] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   updateDatasets();
  //   console.log("Call 1");
  //   if (schedule !== undefined && schedule.isCurrent) {
  //     setCurrentSchedule(schedule);
  //     getCurrentAnalyticsData(schedule);
  //   } else if (schedule !== undefined) {
  //     getCurrentSchedule();
  //     getProposedAnalyticsData(schedule);
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
    updateDatasets();
    if (schedule !== undefined && schedule.isCurrent) {
      setCurrentSchedule(schedule);
      //getCurrentAnalyticsData(schedule);
    } else if (schedule !== undefined) {
      getCurrentSchedule();
      //getProposedAnalyticsData(schedule);
    }
  }, [schedule]);

  // useEffect(() => {
  //   updateScheduleData();
  // }, [dataset]);

  const updateDatasets = async () => {
    let awaitDatasets = Api.getDatasets();
    awaitDatasets.then((datasets) => {
      if (datasets === null) {
        setDatasets([]);
        setDataset({});
      } else {
        setDatasets(datasets);
        if (dataset === null || datasets.indexOf(dataset) === -1) {
          setDataset(datasets[0]);
        }
      }
    });
  };

  const updateScheduleData = async () => {
    await Promise.all([
      getCurrentAnalyticsData(currentSchedule),
      getProposedAnalyticsData(schedule),
    ]);
  };

  const onLoadAnalysisDataClick = async () => {
    await updateScheduleData();
    setReadyToAnalyze(true);
  };

  //getAnalysesDisplay, selectAllAnalyses and removeProposedOptions were moved to ScheduleAnalysis Charts

  const getProposedAnalyticsData = async (schedule) => {
    if (!liveMode) {
      let awaitProposedData;
      if (useDate) {
        awaitProposedData = Api.getStatisticsWithDateRange(
          schedule.id,
          startDate,
          endDate
        );
      } else {
        awaitProposedData = Api.getStatisticsWithDataset(schedule.id, dataset);
      }

      await awaitProposedData.then((proposedData) => {
        setProposedData(proposedData);
        setLoading(false);
      });
    }
  };

  const getCurrentSchedule = async () => {
    let awaitCurrentSchedule = Api.getCurrentSchedule();
    awaitCurrentSchedule.then((schedule) => {
      setCurrentSchedule(schedule);
      getCurrentAnalyticsData(schedule);
    });
  };

  const getCurrentAnalyticsData = async (schedule) => {
    let awaitCurrentData;
    if (useDate) {
      const startString = moment(startDate).format();
      const endString = moment(endDate).format();
      awaitCurrentData = Api.getStatisticsWithDateRange(
        schedule.id,
        startString,
        endString
      );
    } else {
      awaitCurrentData = Api.getStatisticsWithDataset(schedule.id, dataset);
    }

    await awaitCurrentData.then((currentData) => {
      setNoPatients(currentData === null);
      setCurrentData(currentData);
    });
  };

  return (
    <>
      <OuterDiv>
        <Header>Schedule Analysis</Header>
        <Grid
          container
          justify="center"
          alignContent="center"
          direction="column"
          style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}
        >
          {!schedule.isCurrent && currentSchedule && (
            <Grid
              item
              style={portraitMode ? { width: "665px" } : { width: "1265px" }}
            >
              <Typography variant="h5" className={classes.scheduleName}>
                Current Schedule
              </Typography>
              <Schedule schedule={currentSchedule} dense={portraitMode} />
            </Grid>
          )}
          <Grid
            item
            style={portraitMode ? { width: "665px" } : { width: "1265px" }}
          >
            <Typography variant="h5" className={classes.scheduleName}>
              {schedule.isCurrent ? "Current Schedule" : schedule.name}
            </Typography>
            <Schedule schedule={schedule} dense={portraitMode}></Schedule>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          style={{ textAlign: "center" }}
          spacing={1}
        >
          <Grid item>
            <div
              className={clsx(
                portraitMode && classes.controlLabelSmall,
                !portraitMode && classes.controlLabelLarge
              )}
            >
              <Typography variant="subtitle1" gutterBottom>
                Set Date Range:
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={portraitMode ? 2 : 4}
            >
              {useDate && (
                <>
                  {/* <Grid container
              justify="center"
              alignContent="center"
              direction="column"> */}
                  <Grid item>
                    <Typography variant="h5" className={classes.datePicker}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          clearable
                          value={startDate}
                          onChange={setStartDate}
                          ampm={false}
                          format="HH:mm:ss MM-DD-YY"
                          label={"Start Date"}
                          aria-label="start date"
                          InputProps={{
                            style: portraitMode
                              ? {
                                  fontSize: "1.25em",
                                  fontWeight: "600",
                                  height: "61px",
                                }
                              : { fontSize: ".75em", fontWeight: "600" },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "-8px" }}
                              >
                                <IconButton>
                                  <AccessTime />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={
                            portraitMode
                              ? {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "3px",
                                  },
                                }
                              : {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "6px",
                                  },
                                }
                          }
                          style={
                            portraitMode
                              ? {
                                  fontSize: "1.25rem",
                                  width: "295px",
                                  height: "61px",
                                }
                              : { fontSize: "1.5em", width: "325px" }
                          }
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className={classes.datePicker}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          clearable
                          value={endDate}
                          onChange={setEndDate}
                          ampm={false}
                          format="HH:mm:ss MM-DD-YY"
                          label={"End Date"}
                          aria-label="start date"
                          InputProps={{
                            style: portraitMode
                              ? {
                                  fontSize: "1.25em",
                                  fontWeight: "600",
                                  height: "61px",
                                }
                              : { fontSize: ".75em", fontWeight: "600" },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "-8px" }}
                              >
                                <IconButton>
                                  <AccessTime />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={
                            portraitMode
                              ? {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "3px",
                                  },
                                }
                              : {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "6px",
                                  },
                                }
                          }
                          style={
                            portraitMode
                              ? {
                                  fontSize: "1.25rem",
                                  width: "295px",
                                  height: "61px",
                                }
                              : { fontSize: "1.5em", width: "325px" }
                          }
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Typography>
                  </Grid>
                  {/* </Grid> */}
                  {/* </Grid> */}
                </>
              )}
              {!useDate && (
                <Grid item>
                  <div
                    className={
                      portraitMode
                        ? classes.controlLabelSmall
                        : classes.controlLabelLarge
                    }
                  >
                    Choose Data Set
                  </div>
                  <Autocomplete
                    id="dataset-combo-box"
                    options={datasets}
                    getOptionLabel={(option) => option.name}
                    classes={
                      portraitMode
                        ? autocompleteSmallClasses
                        : autocompleteLargeClasses
                    }
                    value={dataset}
                    selectOnFocus
                    handleHomeEndKeys
                    clearOnBlur
                    autoHighlight
                    disableClearable
                    autoSelect
                    onChange={(event, newValue) => {
                      setDataset(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontWeight: "600", fontSize: "1.25rem" },
                        }}
                      />
                    )}
                    aria-label="select dataset"
                    className={classes.datasetSelector}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <LogoButton onClick={onLoadAnalysisDataClick}>
                Load Analysis Data
              </LogoButton>
            </div>
          </Grid>
          {noPatients && (
            <Grid item>
              <Typography>
                No recorded assignments during the given date range. Please
                select a new range and try again.
              </Typography>
            </Grid>
          )}
        </Grid>
        {readyToAnalyze && !noPatients && (
          <OuterDiv>
            <ScheduleAnalysisCharts
              currentData={currentData}
              closeAnalyzer={close}
              proposedData={proposedData}
            />
          </OuterDiv>
        )}
      </OuterDiv>
    </>
  );
};

export default ScheduleAnalyzer;
