import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "../components/Layout";

import { Grid, Typography } from "@material-ui/core";
import OuterDiv from "../components/OuterDiv";
import Api from "../services/Api";
import Header from "../components/Header";
import ScheduleList from "../components/ScheduleList";
import ScheduleTable from "../components/ScheduleTable";
import { LogoButton } from "../components/Buttons";
import ScheduleEditor from "./ScheduleEditor";
import { CreateBlankSchedule } from "../utils/constants";

const Schedules = (props) => {
  const [schedules, setSchedules] = useState([]);
  const [editingSchedule, setEditingSchedule] = useState(undefined);
  const history = useHistory();
  const { setCurrentView, portraitMode } = useLayoutContext();

  useEffect(() => {
    const call = async () => {
      await fetchSchedules();
    };
    call();
    setCurrentView("Schedules");
  }, []);

  const fetchSchedules = async () => {
    const scheds = await Api.getAllSchedules();
    if (scheds != null) {
      setSchedules(scheds);
    }
  };

  const listOnClick = (schedule) => {
    if (schedule) {
      history.push("/simAnalyzer", { schedule: schedule });
    }
  };

  const closeScheduleEditor = () => {
    setEditingSchedule(undefined);
  };

  const saveSchedule = async (schedule) => {
    await Api.createSchedule(schedule);
    await fetchSchedules();
    setEditingSchedule(undefined);
  };

  const createNewSchedule = () => {
    let schedule = CreateBlankSchedule();
    setEditingSchedule(schedule);
  };

  const viewOnClick = (schedule) => {
    console.log(schedule);
    if (schedule) {
      history.push("/scheduleView", { schedule: schedule });
    }
  };

  const deleteOnClick = async (schedule) => {
    if (schedule) {
      let success = await Api.deleteSchedule(schedule);
      if (success) {
        let currScheds = schedules.slice();
        currScheds.splice(currScheds.indexOf(schedule), 1);
        setSchedules(currScheds);
      }
    }
  };

  return (
    <OuterDiv>
      {editingSchedule !== undefined && (
        <ScheduleEditor
          schedule={editingSchedule}
          onCancel={closeScheduleEditor}
          onSave={saveSchedule}
        />
      )}
      {editingSchedule === undefined && (
        <>
          <Header>Schedule List</Header>
          <Grid container justify="center" spacing="1">
            <Grid item xs={12}>
              <ScheduleTable
                schedules={schedules}
                onClick={listOnClick}
                deleteOnClick={deleteOnClick}
                viewOnClick={listOnClick}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LogoButton
                style={{ width: "20%", fontSize: "1em" }}
                onClick={createNewSchedule}
              >
                Create Schedule
              </LogoButton>
            </Grid>
          </Grid>
        </>
      )}
    </OuterDiv>
  );
};

export default Schedules;
