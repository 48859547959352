import React, { useEffect, useState } from "react";

import { makeStyles, Grid, Typography } from "@material-ui/core";

import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";

import { acuities } from "../utils/constants";

import { useLayoutContext } from "./Layout";

import AssignmentHistoryInfo from "./AssignmentHistoryInfo";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  outerGridMargin: { marginTop: "16px" },
  outerGridWidth: { minWidth: "680px" },
  recentAssignments: {
    overflowY: "auto",
    marginTop: "16px",
    marginLeft: "16px",
    overflowY: "auto",
  },
  widthMinus16: {
    width: "calc(100% - 16px)",
  },
  widthMinus20: {
    width: "calc(100% - 20px)",
  },
  assignmentHistoryItem: { width: "100%" },
}));

export const UntriagedPatientDisplay = (props) => {
  const {
    assignmentHistory,
    onReassignClick,
    onUnassignClick,
    hideControls,
    useHeaderSpacing,
    onDisplayChange,
  } = props;
  const { portraitMode } = useLayoutContext();
  const classes = useStyles();
  const [renderId, setRenderId] = useState(uuidv4());

  const [hasUntriagedAssignments, setHasUntriagedAssignments] = useState(false);
  const [untriagedAssignments, setUntriagedAssignments] = useState([]);

  const sortAssignmentHistory = () => {
    let untriagedAssignments = [];
    for (let i = 0; i < assignmentHistory.length; i++) {
      let assignment = assignmentHistory[i];
      for (let r = 0; r < assignment.patients.length; r++) {
        let patient = assignment.patients[r];
        if (patient.acuity === 7) {
          untriagedAssignments.push(assignment);
          break;
        }
      }
    }
    setUntriagedAssignments(untriagedAssignments);
    setHasUntriagedAssignments(untriagedAssignments.length > 0);
  };

  useEffect(() => {
    onDisplayChange(hasUntriagedAssignments);
  }, [hasUntriagedAssignments]);

  useEffect(() => {
    sortAssignmentHistory();
  }, []);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [portraitMode]);

  useEffect(() => {
    sortAssignmentHistory();
  }, [assignmentHistory]);

  if (!hasUntriagedAssignments) {
    return <div />;
  }

  return (
    <Grid
      container
      item
      // xs={portraitMode ? 12 : 6}
      alignItems="flex-start"
      justify="flex-start"
      direction="column"
      className={clsx(
        classes.outerGridWidth,
        (useHeaderSpacing || portraitMode) && classes.outerGridMargin
      )}
      id={renderId}
    >
      <Header>UNTRIAGED ASSIGNMENTS</Header>
      <Grid
        item
        container
        className={clsx(classes.recentAssignments, classes.widthMinus20)}
      >
        {untriagedAssignments.map((historyInfo) => {
          let index = untriagedAssignments.indexOf(historyInfo);
          let isLastItem = index == untriagedAssignments.length - 1;

          let acuity = "-Untriaged-";

          return (
            <Grid
              item
              key={historyInfo.id}
              className={classes.assignmentHistoryItem}
            >
              <AssignmentHistoryInfo
                historyInfo={historyInfo}
                acuity={acuity}
                isLastItem={isLastItem}
                hideControls={hideControls}
                onReassignClick={onReassignClick}
                onUnassignClick={onUnassignClick}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
