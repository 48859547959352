import React, { useState, useEffect } from "react";
import OuterDiv from "../components/OuterDiv";
import Api from "../services/Api";
import Header from "../components/Header";
import OptimizerScheduleResults from "../components/OptimizerScheduleResults";
import { FailSnack, SuccessSnack } from "../components/Snackbars";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";

export const OptimizerResultsViewHOC = (props) => {
  const { id } = props.match.params;

  return <OptimizerResultsView requestId={id} />;
};

export const OptimizerResultsView = (props) => {
  const { requestId } = props;

  const [request, setRequest] = useState(null);
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);
  const [failSnackOpen, setFailSnackOpen] = useState(false);
  const [failSnackMsg, setFailSnackMsg] = useState(
    "Schedule Succesfully Saved"
  );
  const [successSnackMsg, setSuccessSnackMsg] = useState(
    "Schedule failed to save. Please try again later."
  );

  const history = useHistory();

  const listOnClick = (schedule) => {
    if (schedule) {
      history.push("/simAnalyzer", { schedule: schedule });
    }
  };

  useEffect(() => {
    fetchResult();
  }, [requestId]);

  const fetchResult = async () => {
    let data = await Api.getOptimizationRequestWithResultsById(requestId);
    if (data != null) {
      console.log(data);
      setRequest(data);
    }
  };

  const saveSchedule = async (schedule) => {
    let success = await Api.saveOptimizationSchedule(schedule.id);
    if (success) {
      schedule.optimizerSchedule = false;
      setSuccessSnackMsg("Schedule Succesfully Saved");
      setSuccessSnackOpen(true);
    } else {
      setFailSnackMsg("Schedule failed to save. Please try again later.");
      setFailSnackOpen(true);
    }
  };

  const deleteSchedule = async (schedule) => {
    let success = await Api.deleteSchedule(schedule.id);
    if (success) {
      setSuccessSnackMsg("Schedule Succesfully Deleted");
      setSuccessSnackOpen(true);
      await fetchResult();
    } else {
      setFailSnackMsg("Schedule failed to delete. Please ty again later.");
      setFailSnackOpen(true);
    }
  };

  return (
    <OuterDiv>
      <Header>Optimization Result: {request && request.name}</Header>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12}>
          {request != null && (
            <OptimizerScheduleResults
              request={request}
              saveSchedule={saveSchedule}
              deleteSchedule={deleteSchedule}
              details={listOnClick}
            />
          )}
        </Grid>
      </Grid>
      <SuccessSnack
        open={successSnackOpen}
        message={successSnackMsg}
        onClose={() => setSuccessSnackOpen(false)}
      />
      <FailSnack
        open={failSnackOpen}
        message={failSnackMsg}
        onClose={() => setFailSnackOpen(false)}
      />
    </OuterDiv>
  );
};
