export const acuities = [
  { name: "-Untriaged-", value: 7 },
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "2 Psych", value: 6 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
];

export const getAcuityByValue = (value) => {
  for (let i = 0; i < acuities.length; i++) {
    if (acuities[i].value === value) {
      return acuities[i];
    }
  }
};

export const numPatientOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
]; // Ten patients in a room sounds like a bad day.

export const assignmentMethods = [
  { name: "Rotational", value: 1 },
  { name: "AI Based", value: 5 },
];

export const getAssignMethodByValue = (value) => {
  for (let i = 0; i < assignmentMethods.length; i++) {
    if (assignmentMethods[i].value === value) {
      return assignmentMethods[i];
    }
  }
};

export const viewNames = {
  UpNext: "Up Next",
  Staffing: "Staffing Planner",
  Assignment: "Patient Assignment",
  Account: "Account Details",
  Simulation: "Simulate Schedules",
  LiveAnalysis: "Live Schedule Analysis",
  Schedules: "Schedules",
  Datasets: "Dataset Home",
};

export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const permissions = {
  sysadmin: 1,
  orgadmin: 2,
  poweruser: 4,
  scheduler: 8,
  coordinator: 16,
  provider: 32,
};

export const permissionNames = {
  1: "System Admin",
  2: "Organization Admin",
  4: "Power User",
  8: "Scheduler",
  16: "Coordinator",
  32: "Provider",
};

export const dateFormatStrings = {
  MonthDayYearTime: "M/D/Y HH:mm:ss",
};

export const ObjectiveNames = [
  "PerfectShift",
  "Deviation",
  "ShiftEntropy",
  "Flatness",
  "Longterm",
  "MissedAssigns",
];

// export const ObjectiveNamesWithSpaces = [
//   "PerfectShift",
//   "Deviation",
//   "ShiftEntropy",
//   "Flatness",
//   "Longterm",
//   "MissedAssigns"
// ]

export const halfhours = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const hours = [
  "0:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const apm15categoryLabels = [
  "01:30",
  "03:00",
  "04:30",
  "06:00",
  "07:30",
  "09:00",
  "10:30",
  "12:00",
  "13:30",
  "15:00",
  "16:30",
  "18:00",
  "19:30",
  "21:00",
  "22:30",
];

export const apm15categories = [
  "x",
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

export const apm30categories = [
  "x",
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const apm60categories = [
  "x",
  "0:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const CreateBlankSchedule = () => {
  let blank = {
    name: "",
    shifts: [],
    shiftsByPrefix: [],
    rules: [],
  };
  return blank;
};

export const CreateDefaultPreloadOffloadRule = (ruleName) => {
  let blank = {
    ruleType: ruleName,
    priority: 1,
    ruleTypeInfo: 1,
    ruleSettings: {
      MaxAcuity: 4,
      Duration: 0.5,
    },
  };
  return blank;
};
