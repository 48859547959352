import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Grid,
  Typography,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";

import moment from "moment";
import { dateFormatStrings } from "../utils/constants";

import c3 from "c3";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

import { useLayoutContext } from "./Layout";
import { useThemeContext } from "../ThemeProviderHost";
import {
  apm15categories,
  apm30categories,
  apm60categories,
} from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  controlText: {
    color: theme.palette.text.primary,
  },
}));

export const ApsMsdDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [currentMeanMinusSd, setCurrentMeanMinusSd] = useState("");
  const [currentMeanPlusSd, setCurrentMeanPlusSd] = useState("");
  const [proposedMean, setProposedMean] = useState("");
  const [proposedMeanMinusSd, setProposedMeanMinusSd] = useState("");
  const [proposedMeanPlusSd, setProposedMeanPlusSd] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [currentData, proposedData, renderId, usingDarkMode]);

  const setupChart = (dataArray) => {
    let processedData = processData(dataArray);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "scatter",
        colors: {
          "Current Mean APS": "#4361EE",
          "Proposed Mean APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
      point: {
        r: 7,
      },
      grid: {
        y: {
          lines: [
            { value: processedData.mean, text: "Mean", position: "middle" },
            {
              value: processedData.meanPlusSd,
              text: "MPSD",
              position: "middle",
            },
            {
              value: processedData.meanMinusSd,
              text: "MMSD",
              position: "middle",
            },
          ],
        },
      },
    });

    setCurrentMean(processedData.currentMean);
    setCurrentMeanPlusSd(processedData.currentMeanPlusSd);
    setCurrentMeanMinusSd(processedData.currentMeanMinusSd);
    setProposedMean(processedData.proposedMean);
    setProposedMeanPlusSd(processedData.proposedMeanPlusSd);
    setProposedMeanMinusSd(processedData.proposedMeanMinusSd);
    setChart(c3chart);
  };

  const processData = (data) => {
    let processedData = {};
    let currentData = data[0].apsmsd;
    processedData.currentMean = currentData.mean;
    processedData.currentMeanPlusSd = currentData.meanPlusSd;
    processedData.currentMeanMinusSd = currentData.meanMinusSd;

    let categories = [];
    let column = ["Current Mean APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;

    Object.keys(currentData.data).forEach((key) => {
      let value = currentData.data[key];
      let name = key;

      categories.push(name);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    // }

    if (data.length > 1 && data[1] !== null) {
      let proposedData = data[1].apsmsd;
      processedData.proposedMean = proposedData.mean;
      processedData.proposedMeanPlusSd = proposedData.meanPlusSd;
      processedData.proposedMeanMinusSd = proposedData.meanMinusSd;

      column = ["Proposed Mean APS"];
      columns.push(column);

      Object.keys(proposedData.data).forEach((key) => {
        let value = proposedData.data[key];
        let name = key;

        categories.push(name);
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
      });

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   categories.push(datum.name);
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      // }
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        Mean Assignments Per Shift +/- Standard Deviation
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Current Mean Minus SD: {currentMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean Plus SD: {currentMeanPlusSd}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Proposed Mean Minus SD: {proposedMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean Plus SD: {proposedMeanPlusSd}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx("c3-opaque-circle", usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const ApsDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [currentData, proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean APS": "#4361EE",
          "Proposed Mean APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let currentData = data[0].apsmsd;

    let categories = [];
    let column = ["Current Mean APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(currentData.data).forEach((key) => {
      let value = currentData.data[key];
      let name = key;
      categories.push(name);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    setCurrentMean((total / Object.keys(currentData.data).length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      let proposedData = data[1].apsmsd;

      column = ["Proposed Mean APS"];
      columns.push(column);

      total = 0;

      Object.keys(proposedData.data).forEach((key) => {
        let value = proposedData.data[key];
        let name = key;
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      setProposedMean(
        (total / Object.keys(proposedData.data).length).toFixed(3)
      );
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={usingDarkMode && "c3-dark-mode"}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const AphMsdDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [currentMeanMinusSd, setCurrentMeanMinusSd] = useState("");
  const [currentMeanPlusSd, setCurrentMeanPlusSd] = useState("");
  const [proposedMean, setProposedMean] = useState("");
  const [proposedMeanMinusSd, setProposedMeanMinusSd] = useState("");
  const [proposedMeanPlusSd, setProposedMeanPlusSd] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [currentData, proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (data) => {
    let processedData = processData(data);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "scatter",
        colors: {
          "Current Mean APH": "#4361EE",
          "Proposed Mean APH": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
      point: {
        r: 7,
      },
      grid: {
        y: {
          lines: [
            { value: processedData.mean, text: "Mean", position: "middle" },
            {
              value: processedData.meanPlusSd,
              text: "MPSD",
              position: "middle",
            },
            {
              value: processedData.meanMinusSd,
              text: "MMSD",
              position: "middle",
            },
          ],
        },
      },
    });

    setCurrentMean(processedData.currentMean);
    setCurrentMeanPlusSd(processedData.currentMeanPlusSd);
    setCurrentMeanMinusSd(processedData.currentMeanMinusSd);
    setProposedMean(processedData.proposedMean);
    setProposedMeanPlusSd(processedData.proposedMeanPlusSd);
    setProposedMeanMinusSd(processedData.proposedMeanMinusSd);
    setChart(c3chart);
  };

  const processData = (data) => {
    let processedData = {};
    let currentData = data[0].aphmsd;
    processedData.currentMean = currentData.mean;
    processedData.currentMeanPlusSd = currentData.meanPlusSd;
    processedData.currentMeanMinusSd = currentData.meanMinusSd;

    let categories = [];
    let column = ["Current Mean APH"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;

    Object.keys(currentData.data).forEach((key) => {
      let value = currentData.data[key];
      let name = key;
      categories.push(name);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    // }

    if (data.length > 1 && data[1] !== null) {
      let proposedData = data[1].aphmsd;
      processedData.proposedMean = proposedData.mean;
      processedData.proposedMeanPlusSd = proposedData.meanPlusSd;
      processedData.proposedMeanMinusSd = proposedData.meanMinusSd;

      column = ["Proposed Mean APH"];
      columns.push(column);

      Object.keys(proposedData.data).forEach((key) => {
        let value = proposedData.data[key];
        let name = key;
        categories.push(name);
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
      });

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   categories.push(datum.name);
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      // }
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        Mean Assignments Per Hour +/- Standard Deviation
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Current Mean Minus SD: {currentMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean Plus SD: {currentMeanPlusSd}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Proposed Mean Minus SD: {proposedMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean Plus SD: {proposedMeanPlusSd}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx("c3-opaque-circle", usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const AphDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [currentData, proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean APH": "#4361EE",
          "Proposed Mean APH": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let currentData = data[0].aphmsd;

    let categories = [];
    let column = ["Current Mean APH"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(currentData.data).forEach((key) => {
      let value = currentData.data[key];
      let name = key;
      categories.push(name);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    setCurrentMean((total / Object.keys(currentData.data).length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      let proposedData = data[1].aph;

      column = ["Proposed Mean APH"];
      columns.push(column);

      total = 0;

      Object.keys(proposedData.data).forEach((key) => {
        let value = proposedData.data[key];
        let name = key;
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      setProposedMean(
        (total / Object.keys(proposedData.data).length).toFixed(3)
      );
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Assignments Per Hour
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Apm15Display = (props) => {
  const classes = useStyles();
  const { data, title } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    setupChart(data);
  }, []);

  useEffect(() => {
    setupChart(data);
  }, [data, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        x: "x",
        columns: processedData.columns,
        type: "line",
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          type: "category",
          tick: { multiline: false, culling: { max: 16 } },
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let rawData = data.apm15;
    let categories = apm15categories;
    let columns = [categories];
    let minY = 100000;
    let maxY = 0;
    Object.keys(rawData).forEach((key) => {
      let values = rawData[key];
      let column = [key];

      for (let k = 0; k < values.length; k++) {
        column.push(values[k]);
        maxY = Math.max(values[k], maxY);
        minY = Math.min(values[k], minY);
      }

      columns.push(column);
    });
    // for (let i = 0; i < rawData.data.length; i++) {
    //   let datum = rawData.data[i];
    //   let column = [datum.name];
    //   for (let k = 0; k < datum.values.length; k++) {
    //     column.push(datum.values[k]);
    //     maxY = Math.max(datum.values[k], maxY);
    //     minY = Math.min(datum.values[k], minY);
    //   }

    //   columns.push(column);
    // }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        {title}
      </Typography>
      <div
        style={{ marginTop: "4px", marginBottom: "8px" }}
        className={classes.controlText}
      >
        15 Minute Interval
      </div>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Apm30Display = (props) => {
  const classes = useStyles();
  const { data, title } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    setupChart(data);
  }, []);

  useEffect(() => {
    setupChart(data);
  }, [data, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);
    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        x: "x",
        columns: processedData.columns,
        type: "line",
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          type: "category",
          tick: { multiline: false, culling: { max: 16 } },
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let rawData = data.apm30;
    let categories = apm30categories;
    let columns = [categories];
    let minY = 100000;
    let maxY = 0;

    Object.keys(rawData).forEach((key) => {
      let values = rawData[key];
      let column = [key];

      for (let k = 0; k < values.length; k++) {
        column.push(values[k]);
        maxY = Math.max(values[k], maxY);
        minY = Math.min(values[k], minY);
      }

      columns.push(column);
    });

    // for (let i = 0; i < rawData.data.length; i++) {
    //   let datum = rawData.data[i];
    //   let column = [datum.name];
    //   for (let k = 0; k < datum.values.length; k++) {
    //     column.push(datum.values[k]);
    //     maxY = Math.max(datum.values[k], maxY);
    //     minY = Math.min(datum.values[k], minY);
    //   }

    //   columns.push(column);
    // }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        {title}
      </Typography>
      <div
        style={{ marginTop: "4px", marginBottom: "8px" }}
        className={classes.controlText}
      >
        30 Minute Interval
      </div>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Apm60Display = (props) => {
  const classes = useStyles();
  const { data, title } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    setupChart(data);
  }, []);

  useEffect(() => {
    setupChart(data);
  }, [data, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);
    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        x: "x",
        columns: processedData.columns,
        type: "line",
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          type: "category",
          tick: { multiline: false, culling: { max: 16 } },
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let rawData = data.apm60;
    let categories = apm60categories;
    let columns = [categories];
    let minY = 100000;
    let maxY = 0;

    Object.keys(rawData).forEach((key) => {
      let values = rawData[key];
      let column = [key];

      for (let k = 0; k < values.length; k++) {
        column.push(values[k]);
        maxY = Math.max(values[k], maxY);
        minY = Math.min(values[k], minY);
      }

      columns.push(column);
    });

    // for (let i = 0; i < rawData.data.length; i++) {
    //   let datum = rawData.data[i];
    //   let column = [datum.name];
    //   for (let k = 0; k < datum.values.length; k++) {
    //     column.push(datum.values[k]);
    //     maxY = Math.max(datum.values[k], maxY);
    //     minY = Math.min(datum.values[k], minY);
    //   }

    //   columns.push(column);
    // }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        {title}
      </Typography>
      <div
        style={{ marginTop: "4px", marginBottom: "8px" }}
        className={classes.controlText}
      >
        60 Minute Interval
      </div>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const PphDisplay = (props) => {
  const classes = useStyles();
  const { data, title } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    setupChart(data);
  }, []);

  useEffect(() => {
    setupChart(data);
  }, [data, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);
    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        x: "x",
        columns: processedData.columns,
        type: "line",
      },
      point: {
        show: false,
      },
      axis: {
        x: {
          type: "category",
          tick: { multiline: false, culling: { max: 16 } },
          padding: { left: 0, right: 0 },
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    let rawData = data.pph;
    let categories = [
      "x",
      "Hr 1",
      "Hr 2",
      "Hr 3",
      "Hr 4",
      "Hr 5",
      "Hr 6",
      "Hr 7",
      "Hr 8",
    ];
    let columns = [categories];
    let minY = 100000;
    let maxY = 0;

    Object.keys(rawData).forEach((key) => {
      let datum = rawData[key];
      let column = [key];
      for (let k = 0; k < datum.length; k++) {
        column.push(datum[k]);
        maxY = Math.max(datum[k], maxY);
        minY = Math.min(datum[k], minY);
      }
      columns.push(column);
    });

    // for (let i = 0; i < rawData.data.length; i++) {
    //   let datum = rawData.data[i];
    //   let column = [datum.name];
    //   for (let k = 0; k < datum.values.length; k++) {
    //     column.push(datum.values[k]);
    //     maxY = Math.max(datum.values[k], maxY);
    //     minY = Math.min(datum.values[k], minY);
    //   }

    //   columns.push(column);
    // }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        {title}
      </Typography>
      <div
        style={{ marginTop: "4px", marginBottom: "8px" }}
        className={classes.controlText}
      >
        By Hour of Shift
      </div>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const AcpMsdDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [currentMeanMinusSd, setCurrentMeanMinusSd] = useState("");
  const [currentMeanPlusSd, setCurrentMeanPlusSd] = useState("");
  const [proposedMean, setProposedMean] = useState("");
  const [proposedMeanMinusSd, setProposedMeanMinusSd] = useState("");
  const [proposedMeanPlusSd, setProposedMeanPlusSd] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (data) => {
    let processedData = processData(data);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "scatter",
        colors: {
          "Mean Acuity": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
      point: {
        r: 7,
      },
      grid: {
        y: {
          lines: [
            { value: processedData.mean, text: "Mean", position: "middle" },
            {
              value: processedData.meanPlusSd,
              text: "MPSD",
              position: "middle",
            },
            {
              value: processedData.meanMinusSd,
              text: "MMSD",
              position: "middle",
            },
          ],
        },
      },
    });

    setCurrentMean(processedData.currentMean);
    setCurrentMeanPlusSd(processedData.currentMeanPlusSd);
    setCurrentMeanMinusSd(processedData.currentMeanMinusSd);
    setProposedMean(processedData.proposedMean);
    setProposedMeanPlusSd(processedData.proposedMeanPlusSd);
    setProposedMeanMinusSd(processedData.proposedMeanMinusSd);
    setChart(c3chart);
  };

  const processData = (data) => {
    let processedData = {};
    let currentData = data[0].acpmsd;
    processedData.currentMean = currentData.mean;
    processedData.currentMeanPlusSd = currentData.meanPlusSd;
    processedData.currentMeanMinusSd = currentData.meanMinusSd;

    let categories = [];
    let column = ["Current Mean Acuity"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;

    Object.keys(currentData.data).forEach((key) => {
      let value = currentData.data[key];
      let name = key;
      categories.push(name);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    // }

    if (data.length > 1 && data[1] !== null) {
      let proposedData = data[1].acpmsd;
      processedData.proposedMean = proposedData.mean;
      processedData.proposedMeanPlusSd = proposedData.meanPlusSd;
      processedData.proposedMeanMinusSd = proposedData.meanMinusSd;

      column = ["Proposed Mean Acuity"];
      columns.push(column);

      Object.keys(proposedData.data).forEach((key) => {
        let value = proposedData.data[key];
        let name = key;
        categories.push(name);
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
      });

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   categories.push(datum.name);
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      // }
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600" }}
        className={classes.controlText}
      >
        Mean Acuity Per Shift +/- Standard Deviation
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Current Mean Minus SD: {currentMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Current Mean Plus SD: {currentMeanPlusSd}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignContent="space-evenly"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "4px",
          marginBottom: "8px",
        }}
      >
        <Grid item className={classes.controlText}>
          Proposed Mean Minus SD: {proposedMeanMinusSd}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean Plus SD: {proposedMeanPlusSd}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx("c3-opaque-circle", usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps1Display = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L1 APS": "#4361EE",
          "Proposed Mean L1 APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps1;

    let raw = data[0].acps;
    const acuityIdx = 1;
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });
    //console.log(currentData);

    let categories = [];
    let column = ["Current Mean L1 APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      //let proposedData = data[1].acps1;
      let raw = data[1].acps;

      column = ["Proposed Mean L1 APS"];
      columns.push(column);

      total = 0;

      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 1 Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps2Display = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L2 APS": "#4361EE",
          "Proposed Mean L2 APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps2;

    let raw = data[0].acps;
    const acuityIdx = 2;
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });

    let categories = [];
    let column = ["Current Mean L2 APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });
    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    if (data.length > 1 && data[1] !== null) {
      //let proposedData = data[1].acps2;

      let raw = data[1].acps;

      column = ["Proposed Mean L2 APS"];
      columns.push(column);

      total = 0;

      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // total = 0;
      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      // setProposedMean((total / proposedData.data.length).toFixed(3));
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 2 Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps2pDisplay = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L2P APS": "#4361EE",
          "Proposed Mean L2P APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps1;

    let raw = data[0].acps;
    const acuityIdx = 6;
    // let currentData = {data: []};
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });

    let categories = [];
    let column = ["Current Mean L2P APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });
    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    // setCurrentMean((total / currentData.data.length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      //let proposedData = data[1].acps1;

      let raw = data[1].acps;

      column = ["Proposed Mean L2P APS"];
      columns.push(column);

      total = 0;

      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // total = 0;
      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      //setProposedMean((total / proposedData.data.length).toFixed(3));
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 2-Psych Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps3Display = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L3 APS": "#4361EE",
          "Proposed Mean L3 APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps3;

    let raw = data[0].acps;
    const acuityIdx = 3;
    // let currentData = {data: []};
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });

    let categories = [];
    let column = ["Current Mean L3 APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });
    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    // setCurrentMean((total / currentData.data.length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      //let proposedData = data[1].acps3;

      let raw = data[1].acps;
      // let proposedData = {data: []};
      // Object.keys(raw).forEach((key) => {
      //   proposedData.data.push({[key]: raw[key][acuityIdx]});
      // });

      column = ["Proposed Mean L3 APS"];
      columns.push(column);

      total = 0;

      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      // setProposedMean((total / proposedData.data.length).toFixed(3));
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 3 Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps4Display = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L4 APS": "#4361EE",
          "Proposed Mean L4 APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps4;

    let raw = data[0].acps;
    const acuityIdx = 4;
    // let currentData = {data: []};
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });

    let categories = [];
    let column = ["Current Mean L4 APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });
    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    // setCurrentMean((total / currentData.data.length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      //let proposedData = data[1].acps4;

      let raw = data[1].acps;
      // let proposedData = {data: []};
      // Object.keys(raw).forEach((key) => {
      //   proposedData.data.push({[key]: raw[key][acuityIdx]});
      // });

      column = ["Proposed Mean L4 APS"];
      columns.push(column);

      total = 0;
      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      // setProposedMean((total / proposedData.data.length).toFixed(3));
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 4 Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

export const Acps5Display = (props) => {
  const classes = useStyles();
  const { currentData, proposedData } = props;
  const { portraitMode } = useLayoutContext();
  const { usingDarkMode } = useThemeContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [chartId, setChartId] = useState("c3-chart-" + uuidv4());
  const [chart, setChart] = useState(undefined);
  const [currentMean, setCurrentMean] = useState("");
  const [proposedMean, setProposedMean] = useState("");

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, []);

  useEffect(() => {
    setupChart([currentData, proposedData]);
  }, [proposedData, renderId, usingDarkMode]);

  useEffect(() => {
    setRenderId(uuidv4());
  }, [usingDarkMode]);

  const setupChart = (rawData) => {
    let processedData = processData(rawData);

    let c3chart = c3.generate({
      bindto: `#${chartId}`,
      data: {
        columns: processedData.columns,
        type: "bar",
        colors: {
          "Current Mean L5 APS": "#4361EE",
          "Proposed Mean L5 APS": "#F1592A",
        },
      },
      axis: {
        x: {
          type: "category",
          categories: processedData.categories,
        },
        y: {
          max: processedData.yAxisMax,
          min: processedData.yAxisMin,
          padding: { top: 0, bottom: 0 },
        },
      },
    });
  };

  const processData = (data) => {
    let processedData = {};
    //let currentData = data[0].acps5;

    let raw = data[0].acps;
    const acuityIdx = 5;
    // let currentData = {data: []};
    // Object.keys(raw).forEach((key) => {
    //   currentData.data.push({[key]: raw[key][acuityIdx]});
    // });

    let categories = [];
    let column = ["Current Mean L5 APS"];
    let columns = [column];
    let minY = 100000;
    let maxY = 0;
    let total = 0;

    Object.keys(raw).forEach((key) => {
      let value = raw[key][acuityIdx];
      categories.push(key);
      column.push(value);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
      total += value;
    });
    setCurrentMean((total / Object.keys(raw).length).toFixed(3));

    // for (let i = 0; i < currentData.data.length; i++) {
    //   let datum = currentData.data[i];
    //   categories.push(datum.name);
    //   column.push(datum.value);
    //   maxY = Math.max(maxY, datum.value);
    //   minY = Math.min(minY, datum.value);
    //   total += datum.value;
    // }

    // setCurrentMean((total / currentData.data.length).toFixed(3));

    if (data.length > 1 && data[1] !== null) {
      // let proposedData = data[1].acps5;

      let raw = data[1].acps;
      // let proposedData = {data: []};
      // Object.keys(raw).forEach((key) => {
      //   proposedData.data.push({[key]: raw[key][acuityIdx]});
      // });

      column = ["Proposed Mean L5 APS"];
      columns.push(column);

      total = 0;
      Object.keys(raw).forEach((key) => {
        let value = raw[key][acuityIdx];
        column.push(value);
        maxY = Math.max(maxY, value);
        minY = Math.min(minY, value);
        total += value;
      });
      setProposedMean((total / Object.keys(raw).length).toFixed(3));

      // for (let i = 0; i < proposedData.data.length; i++) {
      //   let datum = proposedData.data[i];
      //   column.push(datum.value);
      //   maxY = Math.max(maxY, datum.value);
      //   minY = Math.min(minY, datum.value);
      //   total += datum.value;
      // }

      // setProposedMean((total / proposedData.data.length).toFixed(3));
    }

    processedData.categories = categories;
    processedData.columns = columns;
    processedData.yAxisMin = Math.floor(minY);
    processedData.yAxisMax = Math.ceil(maxY);

    return processedData;
  };

  return (
    <Grid item xs={portraitMode ? 12 : 6} id={renderId}>
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "20px" }}
        className={classes.controlText}
      >
        Mean Level 5 Assignments Per Shift
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          marginTop: "8px",
          marginBottom: "19px",
        }}
      >
        <Grid
          item
          className={classes.controlText}
          style={{ marginRight: "16px" }}
        >
          Current Mean: {currentMean}
        </Grid>
        <Grid item className={classes.controlText}>
          Proposed Mean: {proposedMean}
        </Grid>
      </Grid>
      <div
        id={chartId}
        className={clsx(usingDarkMode && "c3-dark-mode")}
        style={{ margin: "8px" }}
      />
    </Grid>
  );
};

const getMuiTheme = (theme) =>
  createMuiTheme({
    overrides: {
      MuiToolbar: {
        regular: {
          minHeight: "48px",
          "@media (min-width: 600px)": {
            minHeight: "48px",
          },
        },
      },
      MuiTableCell: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "4px",
          paddingBottom: "4px",
        },
      },
      MuiTablePagination: {
        toolbar: {
          minHeight: "32px",
        },
      },
    },
  });

export const AssignmentListDisplay = (props) => {
  const classes = useStyles();
  const { data } = props;
  const { portraitMode } = useLayoutContext();
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    processData(data);
  }, []);

  useEffect(() => {
    processData(data);
  }, [data]);

  // We don't actually process anything here.
  // ...nevermind;
  const processData = (data) => {
    console.log(data);
    let assignments = data.assignments;
    let processedData = [];
    for (let i = 0; i < assignments.length; i++) {
      let timeStr = moment(assignments[i].assignmentTime).format(
        dateFormatStrings.MonthDayYearTime
      );
      processedData.push([
        timeStr,
        assignments[i].provider.name,
        assignments[i].patients[0].acuity,
        "",
      ]);
    }
    setProcessedData(processedData);
  };

  const columns = ["Assignment Time", "Shift", "Acuity", "Remarks"];

  const opts = {
    filterType: "dropdown",
    responsive: "stacked",
    selectableRows: "none",
    rowsPerPage: "7",
    rowsPerPageOptions: [7],
    elevation: 0,
  };

  return (
    <Grid
      item
      xs={portraitMode ? 12 : 6}
      style={{ paddingLeft: "32px", paddingRight: "32px" }}
    >
      <Typography
        variant="h6"
        style={{ fontWeight: "600", marginBottom: "8px" }}
        className={classes.controlText}
      >
        Assignment List
      </Typography>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={processedData} columns={columns} options={opts} />
      </MuiThemeProvider>
    </Grid>
  );
};
