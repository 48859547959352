import React from "react";

import {
  makeStyles,
  Button,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";

import clsx from "clsx";

import moment from "moment";

import {
  SuccessButton,
  ErrorButton,
  WarningButton,
  InfoButton,
} from "./Buttons";

const useProviderInfoStyles = makeStyles((theme) => ({
  providerInfoPaper: {
    width: "100%",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    flex: "1 1 auto",
    height: "80px",
  },
  marginBottom: {
    marginBottom: "16px",
  },
  changeStatusButton: {
    marginTop: "22px",
    fontWeight: "600",
  },
  statusText: {
    fontWeight: "600",
    fontSize: "1.5em",
    marginTop: "20px",
  },
  codeText: {
    color: "#C31209",
  },
  unavailableText: {
    color: "#F5BB00",
  },
  procedureText: {
    color: "#4361EE",
  },
  avatar: {
    height: "64px",
    width: "64px",
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary,
    fontSize: "1em",
  },
}));

export const AvailableProviderInfo = (props) => {
  const classes = useProviderInfoStyles();
  const {
    providerInfo,
    onSelect,
    onFlagBusy,
    onFlagAvailable,
    hideControls,
    isLastItem,
  } = props;

  if (providerInfo === undefined) {
    return <div />;
  }

  const provider = providerInfo.provider;
  const name = provider ? provider.name : "Unknown";
  const lastAssignment = providerInfo.lastAssignment;
  const patientRate = Number(providerInfo.patientsPerHour).toFixed(3);
  const providerBusy = provider.status !== "available";
  const isCode = provider.status === "code"; // this is code
  const isProcedure = provider.status === "procedure"; // this is not a procedure
  const isUnavailable = provider.status === "unavailable"; // what lies on the bottom of the ocean floor and twitches? ...a nervous wreck.
  let minutes = provider.availabilityTime ? 0 : -1;
  const rule = providerInfo.rule ? providerInfo.rule.ruleType : null;
  if (minutes === 0) {
    let now = moment.now();
    let time = moment(provider.availabilityTime);
    minutes = time.diff(now, "minutes") + 1;
  }

  const onFlagBusyClicked = (event) => {
    if (onFlagBusy) {
      onFlagBusy(event, provider);
    }
  };

  const onFlagAvailableClicked = (event) => {
    if (onFlagAvailable) {
      onFlagAvailable(event, provider);
    }
  };

  return (
    <Paper
      className={clsx(
        classes.providerInfoPaper,
        !isLastItem && classes.marginBottom
      )}
      variant="outlined"
    >
      <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "row" }}>
        <Typography
          variant="h4"
          style={{ fontWeight: "600", marginLeft: "8px", marginTop: "8px" }}
        >
          <Avatar className={classes.avatar}>{name}</Avatar>
        </Typography>
        <div
          style={{
            marginLeft: "24px",
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            Last Assignment:{" "}
            {lastAssignment
              ? moment(lastAssignment.assignmentTime).format("HH:mm:ss")
              : "Never"}
          </div>
          <div style={{ marginTop: "5px" }}>Patients / Hour: {patientRate}</div>
          {rule && <div style={{ marginTop: "5px" }}>Rule: {rule}</div>}
        </div>
      </div>
      {hideControls && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
          }}
        >
          {providerBusy && (
            <Typography
              variant="h6"
              className={clsx(
                classes.statusText,
                isCode && classes.codeText,
                isProcedure && classes.procedureText,
                isUnavailable && classes.unavailableText
              )}
            >
              <span style={{ textTransform: "capitalize" }}>
                {provider.status}
              </span>{" "}
              {minutes == "-1" ? "indefinitely" : `for ${minutes} minutes`}
            </Typography>
          )}
        </div>
      )}
      {!hideControls && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "265px" }}
        >
          {providerBusy == false && (
            <>
              <SuccessButton
                variant="contained"
                className={classes.changeStatusButton}
                onClick={onFlagBusyClicked}
              >
                Free
              </SuccessButton>
            </>
          )}
          {isCode && (
            <ErrorButton
              variant="contained"
              className={classes.changeStatusButton}
              onClick={onFlagAvailableClicked}
            >
              {provider.status}{" "}
              {minutes == "-1" ? "indefinitely" : `for ${minutes} minutes`}
            </ErrorButton>
          )}
          {isProcedure && (
            <InfoButton
              variant="contained"
              className={classes.changeStatusButton}
              onClick={onFlagAvailableClicked}
            >
              {provider.status}{" "}
              {minutes == "-1" ? "indefinitely" : `for ${minutes} minutes`}
            </InfoButton>
          )}
          {isUnavailable && (
            <WarningButton
              variant="contained"
              className={classes.changeStatusButton}
              onClick={onFlagAvailableClicked}
            >
              {provider.status}{" "}
              {minutes == "-1" ? "indefinitely" : `for ${minutes} minutes`}
            </WarningButton>
          )}
        </div>
      )}
    </Paper>
  );
};

export default AvailableProviderInfo;
