import axios from "axios";
import { useAuth0 } from "../react-auth0-spa";

import { DemoEngine } from "./DemoEngine";

const API_BASE_URL =
  process.env.REACT_APP_ENV == "development"
    ? "https://localhost:5001/api"
    : "/api";

const demoMode = false;

export default class Api {
  static setAuthorizationHeader(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

    axios.interceptors.response.use(undefined, function (error) {
      if (error.response.status === 401) {
        const { loginWithPopup } = useAuth0();
        loginWithPopup();
      }
    });
  }

  static async getUserInfo() {
    try {
      let response = await axios.get(`${API_BASE_URL}/user/info`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAssignmentHistory(start, end) {
    if (demoMode) {
      return this.Engine.getAssignmentHistory();
    } else {
      //done!
      try {
        let response = await axios.get(`${API_BASE_URL}/assignment/history`, {
          params: { start: start, end: end },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }

  static async getAvailableProviders(patientInfo) {
    if (demoMode) {
      return this.Engine.getAvailableProviders();
    } else {
      //availableproviders DONE
      try {
        let response = await axios.get(
          `${API_BASE_URL}/assignment/availableProviders`,
          {
            params: {
              arrivalTime: patientInfo.arrivalTime,
              acuity: patientInfo.acuity.value,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }

  static async getNextProviders(patientInfo) {
    if (demoMode) {
      return this.Engine.getNextProviders();
    } else {
      //api call nextProviders DONE
      try {
        let response = await axios.get(
          `${API_BASE_URL}/assignment/nextProviders`,
          {
            params: {
              arrivalTime: patientInfo.arrivalTime,
              acuity: patientInfo.acuity.value,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }

  static async getAvailableRooms(patientInfo) {
    if (demoMode) {
      return this.Engine.getAllRooms();
    } else {
      //done
      try {
        let response = await axios.get(
          `${API_BASE_URL}/assignment/availableRooms`
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }

  static async addAssignment(assignmentInfo) {
    if (demoMode) {
      return this.Engine.addAssignment(assignmentInfo);
    } else {
      try {
        let response = await axios.put(
          `${API_BASE_URL}/assignment/create`,
          assignmentInfo
        );
        return response.status === 200;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }

  static async editAssignment(assignmentInfo) {
    if (demoMode) {
      return this.Engine.editAssignment(assignmentInfo);
    } else {
      try {
        let response = await axios.put(
          `${API_BASE_URL}/assignment/update`,
          assignmentInfo
        );
        return response.status === 200;
      } catch (error) {
        console.error(error);
      }
    }
  }

  static async deleteAssignment(assignmentInfo) {
    if (demoMode) {
      return this.Engine.deleteAssignment(assignmentInfo);
    } else {
      try {
        let response = await axios.delete(`${API_BASE_URL}/assignment/delete`, {
          params: { id: assignmentInfo.id },
        });
        return response.status === 200;
      } catch (error) {
        console.error(error);
      }
    }
  }

  static async setProviderStatus(provider, status, duration) {
    if (demoMode) {
      return this.Engine.setProviderStatus(provider, status, duration);
    } else {
      try {
        let response = await axios.put(
          `${API_BASE_URL}/assignment/UpdateStatus`,
          provider
        );
        return response.status == 200;
      } catch (error) {
        console.error(error);
      }
    }
  }

  static async getCurrentSchedule() {
    if (demoMode) {
      let x = this.Engine.getCurrentSchedule();
      x.shiftsByPrefix = x.shifts;
      console.log(x);
      return x;
    } else {
      try {
        let response = await axios.get(
          `${API_BASE_URL}/staffing/currentSchedule`
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }

  static async setCurrentSchedule(schedule) {
    if (demoMode || true) {
      this.Engine.setCurrentSchedule(schedule);
    }
  }

  static async getPastSchedules() {
    if (demoMode || true) {
      let schedules = this.Engine.getPastSchedules();
      for (let i = 0; i < schedules.length; i++) {
        schedules[i].shiftsByPrefix = schedules[i].shifts;
      }
      return schedules;
    }
  }

  static async getSimulatedSchedules(
    maxDailyShift,
    earliestStart,
    minShiftLength,
    maxShiftLength,
    dataset,
    numSchedules
  ) {
    if (demoMode || true) {
      let schedules = this.Engine.getSimulatedSchedules(
        maxDailyShift,
        earliestStart,
        minShiftLength,
        maxShiftLength,
        dataset,
        numSchedules
      );

      for (let i = 0; i < schedules.length; i++) {
        schedules[i].shiftsByPrefix = schedules[i].shifts;
      }
      return schedules;
    }
  }

  static async getAllSchedules() {
    try {
      let response = await axios.get(`${API_BASE_URL}/staffing/allSchedules`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async createSchedule(schedule) {
    try {
      let response = await axios.put(
        `${API_BASE_URL}/staffing/schedule`,
        schedule
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteSchedule(schedule) {
    try {
      let response = await axios.delete(`${API_BASE_URL}/staffing/schedule`, {
        params: { id: schedule.id },
      });
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getMetricsForSchedule(schedule) {
    if (demoMode || true) {
      let metrics = this.Engine.getMetricsForSchedule(schedule);
      schedule.metrics = metrics;
      return metrics;
    }
  }

  static async getStatisticsWithDataset(schedule, dataset) {
    if (demoMode || true) {
      return this.Engine.getAnalyticsData(schedule, dataset);
    }
  }

  static async simulateScheduleWithDataset(scheduleId, datasetId) {
    try {
      let response = await axios.get(`${API_BASE_URL}/simulation/run/dataset`, {
        params: {
          datasetId: datasetId,
          scheduleId: scheduleId,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getDatasets() {
    if (demoMode) {
      return this.Engine.getDatasets();
    } else {
      try {
        let response = await axios.get(`${API_BASE_URL}/datasets`);
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }

  static async deleteDataset(datasetId) {
    try {
      let response = await axios.delete(`${API_BASE_URL}/datasets`, {
        params: { id: datasetId },
      });
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static getAnalyticsDemo() {
    return this.Engine.getAnalyticsData();
  }

  static async getStatisticsWithDateRange(scheduleId, start, end) {
    if (demoMode) {
      return this.Engine.getAnalyticsData(scheduleId);
    } else {
      try {
        let response = await axios.get(
          `${API_BASE_URL}/statistics/scheduleWithDateRange`,
          {
            params: {
              scheduleId: scheduleId,
              startTime: start,
              endTime: end,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }

  static async resetPassword(email) {
    let response = await axios
      .post(
        "https://bmsmatrix.us.auth0.com/dbconnections/change_password",
        {
          client_id: "7BkQ3aaa4j7pDw3GoO2XoLJAAluNy3tQ",
          email: email,
          connection: "Username-Password-Authentication",
        },
        { headers: { "content-type": "application/json" } }
      )
      .catch((error) => {
        console.log(error);
        return false;
      });

    return true;
  }

  static async testMethod(data) {
    try {
      let resposne = await axios.get(`${API_BASE_URL}/testing/testData`, {
        params: { name: "testSet" },
      });
      return resposne.status === 200;
    } catch (error) {
      console.error(error);
    }
  }

  static async newOptimizationRequest(requestInfo) {
    try {
      let response = await axios.post(
        `${API_BASE_URL}/optimizer/new`,
        requestInfo
      );
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async getOptimizationRequests() {
    try {
      let response = await axios.get(`${API_BASE_URL}/optimizer/optReqs`);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getOptimizationRequestWithResultsById(id) {
    try {
      let response = await axios.get(`${API_BASE_URL}/optimizer/request/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async saveOptimizationSchedule(id) {
    try {
      let response = await axios.post(
        `${API_BASE_URL}/optimizer/saveSchedule/${id}`
      );
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async deleteOptimizationRequest(id) {
    try {
      let response = await axios.delete(
        `${API_BASE_URL}/optimizer/delete/${id}`
      );
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async uploadDataset(
    formData,
    progressListener,
    loadListener,
    errorListener,
    cancelTokenSource,
    name,
    useMatrix
  ) {
    try {
      let response = await axios.post(
        `${API_BASE_URL}/Datasets/UploadFile`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: progressListener,
          cancelToken: cancelTokenSource.token,
          params: { name: name, useMatrix: useMatrix },
        }
      );

      if (loadListener) loadListener(response.data);
      return response.data;
    } catch (error) {
      if (errorListener) errorListener(error);

      return null;
    }
  }
}

Api.Engine = new DemoEngine();
