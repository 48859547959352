import React, { useState, useEffect, useContext } from "react";

import { Link as RouterLink } from "react-router-dom";

import { makeStyles, IconButton, Grid } from "@material-ui/core";

import BackArrow from "../components/BackArrow";
import OuterDiv from "../components/OuterDiv";
import { useLayoutContext } from "../components/Layout";

import Api from "../services/Api";

const useStyles = makeStyles((theme) => ({}));

export const ShiftHistory = (props) => {
  const classes = useStyles();
  const { setCurrentView } = useLayoutContext();

  useEffect(() => {
    setCurrentView("ShiftHistory");
    //Api.testMethod();
  }, []);

  return (
    <OuterDiv>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item xs={5}></Grid>
        <Grid item xs={5}></Grid>
      </Grid>
    </OuterDiv>
  );
};

export default ShiftHistory;
