import React, { useEffect, useRef } from "react";

import {
  makeStyles,
  Popover,
  Paper,
  GridList,
  GridListTile,
  Button,
  Portal,
} from "@material-ui/core";

const useRoomSelectionStyles = makeStyles((theme) => ({
  largeToggleButton: {
    paddingTop: "16px",
    paddingBottom: "16px",
    minWidth: "80px",
  },
}));

export const RoomSelectionDialog = (props) => {
  const classes = useRoomSelectionStyles();
  const { availableRooms, closeDialog, room, portalRef } = props;

  let clientRect = portalRef.current.getBoundingClientRect();

  let rsdPaperStyle = {
    position: "fixed",
    width: "600px",
    zIndex: "100",
    left: `${clientRect.x - 150}px`,
  };

  if (!availableRooms) {
    return <div />;
  }

  const handleClose = () => {
    if (closeDialog) {
      closeDialog(room);
    }
  };

  const handleRoom = (event, newRoom) => {
    if (closeDialog) {
      closeDialog(newRoom);
    }
  };

  return (
    <Portal container={portalRef.current}>
      <Paper style={rsdPaperStyle}>
        <div style={{ overflowX: "hidden", overflowY: "auto" }}>
          <div style={{}}>
            <GridList
              cellHeight={36}
              cols={5}
              spacing={1}
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              {availableRooms.map((availableRoom) => {
                let index = availableRooms.indexOf(availableRoom);
                return (
                  <GridListTile style={{ textAlign: "center" }} key={index}>
                    <Button
                      id={availableRoom.name}
                      aria-label={availableRoom.name}
                      onClick={(event) => {
                        handleRoom(event, availableRoom);
                      }}
                      style={{
                        border: "unset",
                        width: "100%",
                        fontWeight: "600",
                      }}
                    >
                      {availableRoom.name}
                    </Button>
                  </GridListTile>
                );
              })}
            </GridList>
          </div>
        </div>
      </Paper>
    </Portal>
  );
};
