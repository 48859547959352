import React, { useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  ButtonBase,
  Button,
} from "@material-ui/core";

import flag from "../images/bmsflag.png";

import PieChartIcon from "@material-ui/icons/PieChart";

import ScheduleIcon from "@material-ui/icons/Today";
import assignment from "../assignment.png";

import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Timeline from "@material-ui/icons/Timeline";
import History from "@material-ui/icons/History";
import ListAlt from "@material-ui/icons/ListAlt";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import StorageIcon from "@material-ui/icons/Storage";

import { useAuth0 } from "../react-auth0-spa";
import { useLayoutContext } from "../components/Layout";
import { Login } from "./Login";
import { UpNext } from "./UpNext";
import { Unauthorized } from "./Unauthorized";

import { userHasPermission, userOnlyHasPermission } from "../utils/utilities";

import Assignment from "./Assignment";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    textAlign: "center",
    paddingTop: "8px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  container: {
    marginTop: "48px",
  },
  navButton: {
    paddingTop: "8px",
  },
  navPaper: {
    height: "300px",
    width: "480px",
    textTransform: "none",
    marginBottom: "4px",
  },
  titleDiv: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    margin: "16px",
  },
  settingsIcon: {
    height: "48px",
    width: "48px",
  },
  icon: {
    height: "48px",
    width: "48px",
    color: theme.palette.text.primary,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "2em",
    marginLeft: "16px",
    marginTop: "4px",
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "1.5em",
    textAlign: "justify",
    margin: "16px",
  },
  loginButton: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  welcomeMessage: {
    color: theme.palette.text.primary,
  },
  welcomeAppName: {
    fontFamily: "Kanit",
    fontWeight: "600",
    display: "flex",
  },
  welcomeEDSpan: {
    color: "#BA1200",
    fontFamily: "Kanit",
    fontWeight: "600",
  },
  loginMessage: {
    color: theme.palette.text.primary,
  },
}));

export const Home = (props) => {
  const classes = useStyles();
  const {
    isAuthenticated,
    isAuthorized,
    emailVerified,
    loginWithRedirect,
    userInfo,
  } = useAuth0();
  const { setCurrentView } = useLayoutContext();

  useEffect(() => {
    setCurrentView("Home");
  }, []);

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!isAuthorized) {
    return <Unauthorized emailVerified={emailVerified} />;
  }

  if (userOnlyHasPermission(userInfo, "coordinator")) {
    return <Assignment providerView={true} />;
  }

  if (userOnlyHasPermission(userInfo, "provider")) {
    return <UpNext />;
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={4} justify="center">
        {userHasPermission(userInfo, "scheduler") && (
          <>
            <Grid item>
              <NavButton
                to="/staffing"
                icon={<ScheduleIcon className={classes.icon} />}
                title="Staffing Planner"
                description="Use the Staffing Planner's advanced staffing simulator to
            help you determine an efficient and resilient schedule, or construct one manually using the editor."
              />
            </Grid>
            <Grid item>
              <NavButton
                to="/liveAnalysis"
                icon={<Timeline className={classes.icon} />}
                title="Live Schedule Analysis"
                description="View information and statistics about current schedule and recent assignments."
              />
            </Grid>
            <Grid item>
              <NavButton
                to="/assignmentHistory"
                icon={<History className={classes.icon} />}
                title="Assignment History"
                description="View information and statistics about past patient assignments and see which shifts they were assigned to."
              />
            </Grid>
            <Grid item>
              <NavButton
                to="/simulation"
                icon={<PieChartIcon className={classes.icon} />}
                title="Simulate Schedules"
                description="View schedules, run simulations, and analyze results"
              />
            </Grid>
            <Grid item>
              <NavButton
                to="/schedules"
                title="Schedule Home"
                description="View, create, or delete schedules for your organization"
                icon={<LibraryBooksIcon className={classes.icon} />}
              />
            </Grid>
            <Grid item>
              <NavButton
                to="/datasets"
                title="Datasets Home"
                description="Manage the datasets for your account"
                icon={<StorageIcon className={classes.icon} />}
              />
            </Grid>
          </>
        )}
        {userHasPermission(userInfo, "coordinator") && (
          <Grid item>
            <NavButton
              to="/assignment"
              icon={<LocalHospitalIcon className={classes.icon} />}
              title="Patient Assignments"
              description="Use the Patient Assignment tool to determine the right provider
            for your patient based on their triage level and your shift's
            staffing rotation."
            />
          </Grid>
        )}
        {userHasPermission(userInfo, "provider") && (
          <Grid item>
            <NavButton
              to="/upNext"
              icon={<ListAlt className={classes.icon} />}
              title="Up Next"
              description="View which providers are up next in the rotation and the assignment history for the last 12 hours."
            />
          </Grid>
        )}
        {userHasPermission(userInfo, "orgadmin") && (
          <Grid item>
            <NavButton
              to="/manageUsers"
              icon={<PeopleAlt className={classes.icon} />}
              title="Manage Users"
              description="Add or remove users and grant them permissions to manage users, run staffing simulations, and create patient assignments."
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function NavButton(props) {
  const { to, icon, title, description } = props;
  const classes = useStyles();
  return (
    <Button className={classes.navButton} component={RouterLink} to={to}>
      <Paper className={classes.navPaper}>
        <div className={classes.titleDiv}>
          {icon}
          <Typography className={classes.title}>{title}</Typography>
        </div>
        <Divider variant="middle" />
        <Typography className={classes.description}>{description}</Typography>
      </Paper>
    </Button>
  );
}

export default Home;
