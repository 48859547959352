import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

import Api from "../services/Api";

import { ErrorButton } from "./Buttons";

const useStyles = makeStyles((theme) => ({}));

export const ResetPasswordDialog = (props) => {
  const classes = useStyles();
  const { email, open, onClose } = props;

  const resetPassword = async () => {
    await Api.resetPassword(email);
    onClose();
  };

  const close = async () => {
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        If you would like to reset the password for the account {email} click
        the "Reset" button below and an email will be sent to that address.
      </DialogContent>
      <DialogActions>
        <ErrorButton onClick={resetPassword}>Reset</ErrorButton>
        <Button
          onClick={close}
          variant="contained"
          color="primary"
          style={{ fontWeight: "600" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
