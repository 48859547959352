import React, { useState, useEffect } from "react";
import moment from "moment";
import Api from "../services/Api";

import { Link as RouterLink } from "react-router-dom";

import { makeStyles, IconButton, Grid, Container } from "@material-ui/core";

import BackArrow from "../components/BackArrow";
import { useLayoutContext } from "../components/Layout";
import OuterDiv from "../components/OuterDiv";
import AssignmentHistoryDisplay from "../components/AssignmentHistoryDisplay";

const useStyles = makeStyles((theme) => ({}));

export const AssignmentHistory = (props) => {
  const classes = useStyles();
  const { setCurrentView } = useLayoutContext();
  const [assignmentHistory, setAssignmentHistory] = useState([]);

  useEffect(() => {
    setCurrentView("AssignmentHistory");
    const call = async () => {
      await updateAssignmentHistory();
    };
    call();
  }, []);

  const updateAssignmentHistory = async () => {
    let startDate = moment().subtract(7, "days");
    let awaitAssignmentHistory = Api.getAssignmentHistory(startDate);
    awaitAssignmentHistory.then((assignmentHistory) => {
      console.log(assignmentHistory);
      setAssignmentHistory(assignmentHistory);
    });
  };

  return (
    <OuterDiv>
      <Grid container justify="center">
        <Grid item xs={12}>
          <AssignmentHistoryDisplay
            hideControls={true}
            assignmentHistory={assignmentHistory}
            fullWidth
          />
        </Grid>
      </Grid>
    </OuterDiv>
  );

  // return (
  //   <OuterDiv>
  //     <Grid
  //       container
  //       spacing={4}
  //       direction="row"
  //       justify="center"
  //       alignItems="flex-start"
  //     >
  //       {/* <Grid item xs={5}></Grid> */}
  //       <Grid item xs={12}>
  //         <Grid
  //           container
  //           spacing={4}
  //           direction="column"
  //           justify="flex-start"
  //           alignItems="center"
  //         >
  //           <Grid item xs ={12}>
  //             <AssignmentHistoryDisplay hideControls={true} assignmentHistory={assignmentHistory}/>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   </OuterDiv>
  // );
};

export default AssignmentHistory;
