import React, { useState, useContext, useM } from "react";

import {
  createMuiTheme,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";

import { useEffect } from "react";

const getLightTheme = () => {
  return createMuiTheme({
    palette: {
      background: {
        default: "#F1F2F6",
      },
      text: {
        primary: "#3A3A3C",
      },
      primary: {
        main: "#40476D",
      },
      secondary: {
        main: "#8D99AE",
      },
      error: {
        main: "#C31209",
      },
      warning: {
        main: "#F5BB00",
      },
      info: {
        main: "#4361EE",
      },
      success: {
        main: "#379925",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "Kanit", "Ubuntu", "Segoe UI"],
    },
  });
};

const getDarkTheme = () => {
  return createMuiTheme({
    palette: {
      type: "dark",
      background: {
        default: "#3A3A3C",
      },
      text: {
        primary: "#F1F2F6",
      },
      primary: {
        main: "#8D99AE",
      },
      secondary: {
        main: "#40476D",
      },
      error: {
        main: "#C31209",
      },
      warning: {
        main: "#F5BB00",
      },
      info: {
        main: "#4361EE",
      },
      success: {
        main: "#379925",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "Kanit", "Ubuntu", "Segoe UI"],
    },
  });
};

export const ThemeContext = React.createContext();
export const useThemeContext = () => useContext(ThemeContext);
export const ThemeProviderHost = ({ children }) => {
  const [themeName, setThemeName] = useState("");
  const [theme, setTheme] = useState(getLightTheme);
  const [usingDarkMode, setUsingDarkMode] = useState(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    const savedThemeName = localStorage.getItem("themeName");
    if (savedThemeName !== null) {
      if (savedThemeName === "Dark") {
        setTheme(getDarkTheme());
      }
      setThemeName(savedThemeName);
    } else if (prefersDarkMode) {
      setThemeName("Dark");
      setTheme(getDarkTheme());
    } else {
      setThemeName("Light");
    }
  }, []);

  useEffect(() => {
    if (themeName === "Dark") {
      setTheme(getDarkTheme());
      setUsingDarkMode(true);
    } else {
      setTheme(getLightTheme());
      setUsingDarkMode(false);
    }

    localStorage.setItem("themeName", themeName);
  }, [themeName]);

  const toggleTheme = () => {
    if (themeName === "Dark") {
      setThemeName("Light");
    } else {
      setThemeName("Dark");
    }
  };

  const changeTheme = (name) => {
    setThemeName(name);
  };

  return (
    <ThemeContext.Provider
      value={{
        themeName,
        theme,
        toggleTheme,
        changeTheme,
        usingDarkMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
