import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import Api from "../services/Api";

import ScheduleEditor from "./ScheduleEditor";
import PastScheduleViewer from "./PastScheduleViewer";

import { useLayoutContext } from "../components/Layout";
import OuterDiv from "../components/OuterDiv";
import Schedule from "../components/Schedule";
import Header, { CollapsableHeader } from "../components/Header";
import SimulatorControls from "../components/SimulatorControls";
import ScheduleAnalyzer from "./ScheduleAnalyzer";
import MetricComparisonPane from "../components/MetricComparisonPane";
import DiscardScheduleDialog from "../components/DiscardScheduleDialog";
import UseScheduleDialog from "../components/UseScheduleDialog";
import { SuccessButton } from "../components/Buttons";
import { CreateBlankSchedule } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  useScheduleButton: {},
  editScheduleButton: {},
  helperText: {
    marginTop: "16px",
    color: theme.palette.text.primary,
  },
}));

export const Staffing = (props) => {
  const classes = useStyles();
  const { setCurrentView, portraitMode } = useLayoutContext();
  const [schedules, setSchedules] = useState([]);
  const [analyzingSchedule, setAnalyzingSchedule] = useState();
  const [editingSchedule, setEditingSchedule] = useState();
  const [discardingSchedule, setDiscardingSchedule] = useState();
  const [usingSchedule, setUsingSchedule] = useState();
  const [viewPastSchedules, setViewPastSchedules] = useState(false);
  const [nextScheduleNum, setNextScheduleNum] = useState(1);
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setCurrentView("Staffing");
  }, []);

  const closeScheduleAnalyzer = () => {
    setAnalyzingSchedule(undefined);
  };

  const closeScheduleEditor = () => {
    setEditingSchedule(undefined);
  };

  const createSchedule = () => {
    let newSchedule = CreateBlankSchedule();
    setEditingSchedule(newSchedule);
  };

  const onPastSchedules = () => {
    setViewPastSchedules(true);
  };

  const selectPastSchedule = async (schedule) => {
    if (schedules.length === 0) {
      await pushCurrentSchedule();
    }

    schedules.push(schedule);
    setViewPastSchedules(false);
  };

  const closePastScheduleViewer = () => {
    setViewPastSchedules(false);
  };

  const saveSchedule = async (schedule) => {
    if (schedules.indexOf(schedule) === -1) {
      // if (schedules.length === 0) {
      //   await pushCurrentSchedule();
      // }

      //await Api.getMetricsForSchedule(schedule);
      await Api.createSchedule(schedule);
      // schedule.name = `Created Schedule ${nextScheduleNum}`;
      // setNextScheduleNum(nextScheduleNum + 1);
      // schedules.push(schedule);
    }
    setEditingSchedule(undefined);
  };

  const pushCurrentSchedule = async () => {
    let currentSchedule = await Api.getCurrentSchedule();
    currentSchedule.isCurrent = true;
    schedules.push(currentSchedule);
  };

  const confirmUseSchedule = async (schedule) => {
    await Api.setCurrentSchedule(schedule);
    schedule.name = "Current Schedule";
    schedule.isCurrent = true;
    schedules.shift();
    schedules.splice(schedules.indexOf(schedule), 1);
    schedules.unshift(schedule);
    setUsingSchedule(undefined);
  };

  const cancelUseSchedule = () => {
    setUsingSchedule(undefined);
  };

  const toggleScheduleHold = (schedule) => {
    schedule.retain = !schedule.retain;
    setRenderId(uuidv4());
  };

  const discardSchedule = (schedule) => {
    schedules.splice(schedules.indexOf(schedule), 1);
    setSchedules(schedules.slice());
    setDiscardingSchedule(undefined);
  };

  const cancelDiscardSchedule = () => {
    setDiscardingSchedule(undefined);
  };

  return (
    <OuterDiv id={renderId}>
      {analyzingSchedule !== undefined && (
        <ScheduleAnalyzer
          schedule={analyzingSchedule}
          close={closeScheduleAnalyzer}
          liveMode={false}
        />
      )}
      {editingSchedule !== undefined && (
        <ScheduleEditor
          schedule={editingSchedule}
          onCancel={closeScheduleEditor}
          onSave={saveSchedule}
        />
      )}
      {discardingSchedule !== undefined && (
        <DiscardScheduleDialog
          schedule={discardingSchedule}
          onDiscard={discardSchedule}
          onCancel={cancelDiscardSchedule}
        />
      )}
      {usingSchedule !== undefined && (
        <UseScheduleDialog
          schedule={usingSchedule}
          onConfirm={confirmUseSchedule}
          onCancel={cancelUseSchedule}
        />
      )}
      {viewPastSchedules && (
        <PastScheduleViewer
          onSelectPastSchedule={selectPastSchedule}
          close={closePastScheduleViewer}
        />
      )}
      {analyzingSchedule === undefined &&
        editingSchedule === undefined &&
        viewPastSchedules === false && (
          <>
            <Header>Planner Settings</Header>
            <SimulatorControls
              schedules={schedules}
              setSchedules={setSchedules}
              createSchedule={createSchedule}
              onPastSchedules={onPastSchedules}
            />
            {schedules.length === 0 && (
              <Grid container justify="center">
                <Grid item style={{ width: "665px" }}>
                  <Typography variant="h5" className={classes.helperText}>
                    Click Generate Schedules above to get schedule
                    recommendations, or Create Schedule to create your own.
                  </Typography>
                </Grid>
              </Grid>
            )}
            {schedules.length > 0 && (
              <>
                <CollapsableHeader
                  title="Metric Comparisons"
                  sticky={!portraitMode}
                  startCollapsed={false}
                >
                  <MetricComparisonPane schedules={schedules} />
                </CollapsableHeader>
                <Header>Schedules</Header>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  {schedules.map((schedule) => {
                    let index = schedules.indexOf(schedule);
                    let number = index + 1;
                    let metric1 = schedule.metrics[0].value;
                    let metric2 = schedule.metrics[1].value;
                    let metric3 = schedule.metrics[2].value;
                    let overall = ((metric1 + metric2 + metric3) / 3).toFixed(
                      2
                    );
                    return (
                      <Grid
                        item
                        style={{ width: "685px", margin: "16px" }}
                        key={index}
                      >
                        <Paper
                          style={{
                            paddingTop: "12px",
                            paddingBottom: "16px",
                            paddingLeft: "8px",
                            paddingRight: "12px",
                          }}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: "600",
                              marginLeft: "16px",
                            }}
                          >
                            {schedule.name}
                          </Typography>
                          <Schedule schedule={schedule} dense={true} />
                          {schedule.metrics && (
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              direction="row"
                              spacing={1}
                              style={{ marginBottom: "16px" }}
                            >
                              <Grid item>
                                <Typography>
                                  {schedule.metrics[0].name}: {metric1}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {schedule.metrics[1].name}: {metric2}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {schedule.metrics[2].name}: {metric3}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>Overall: {overall}</Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            direction="row"
                            spacing={4}
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={index === 0}
                                onClick={() => {
                                  setUsingSchedule(schedule);
                                }}
                              >
                                Use
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (index === 0) {
                                    let scheduleCopy = JSON.parse(
                                      JSON.stringify(schedule)
                                    );
                                    setEditingSchedule(scheduleCopy);
                                  } else {
                                    setEditingSchedule(schedule);
                                  }
                                }}
                              >
                                Edit
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  schedule.shiftsByPrefix = schedule.shifts;
                                  setAnalyzingSchedule(schedule);
                                }}
                              >
                                Details
                              </Button>
                            </Grid>
                            {(schedule.retain === false ||
                              schedule.retain === undefined) && (
                              <Grid item>
                                <Tooltip title="Keep this schedule when generating new ones">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => toggleScheduleHold(schedule)}
                                    disabled={index === 0}
                                  >
                                    Hold
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                            {schedule.retain === true && (
                              <Grid item>
                                <Tooltip title="Keep this schedule when generating new ones">
                                  <SuccessButton
                                    variant="contained"
                                    onClick={() => toggleScheduleHold(schedule)}
                                  >
                                    Hold
                                  </SuccessButton>
                                </Tooltip>
                              </Grid>
                            )}
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setDiscardingSchedule(schedule);
                                }}
                                disabled={index === 0}
                              >
                                Discard
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </>
        )}
    </OuterDiv>
  );
};

export default Staffing;
