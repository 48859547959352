import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    position: "relative",
  },
}));

export const OuterDiv = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.outerDiv} {...props}>
      {children}
    </div>
  );
};

export default OuterDiv;
