import React from "react";

import { Link as RouterLink } from "react-router-dom";

import { makeStyles, IconButton } from "@material-ui/core";

import ArrowBack from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  backButton: {
    position: "absolute",
    top: "8px",
    left: "8px",
  },
  backArrow: {
    color: theme.palette.text.primary,
    width: "64px",
    height: "64px",
  },
}));

export const BackArrow = (props) => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} className={classes.backButton}>
      <ArrowBack className={classes.backArrow} />
    </IconButton>
  );
};

export default BackArrow;
