import React, { useState, useEffect, useContext } from "react";

import { List, ListItem, ListItemText, Typography } from "@material-ui/core";

const ScheduleList = (props) => {
  const { schedules, onClick } = props;

  return (
    <List>
      {schedules &&
        schedules.map((item) => {
          return (
            <ListItem button key={item.id} onClick={() => onClick(item)}>
              <ListItemText inset primary={item.name} />
            </ListItem>
          );
        })}
    </List>
  );
};

export default ScheduleList;
