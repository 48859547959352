import React, { Component } from "react";

const style = {
  ProgressBar: {
    width: "100%",
    height: "8px",
    backgroundColor: "rgb(183, 155, 229)",
    borderRadius: "5px",
  },
};

export class Progress extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={style.ProgressBar}>
        <div
          style={{
            backgroundColor: "rgba(103, 58, 183, 1)",
            height: "100%",
            margin: "0px",
            borderRadius: "5px",
            width: this.props.progress + "%",
          }}
        />
      </div>
    );
  }
}
