import React, { useState, useEffect, useRef } from "react";

import {
  makeStyles,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  ClickAwayListener,
} from "@material-ui/core";
import { getRuleFromRuleList } from "../utils/utilities";

import { PreloadOffloadEditDialog } from "./PreloadOffloadEditDialog";
import { TimePicker } from "../utils/pickers";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import clsx from "clsx";

import { assignmentMethods } from "../utils/constants";

import { useLayoutContext } from "./Layout";
import moment from "moment";
import {
  GetPeriodFromMomentTime,
  SetTimeToHalfHour,
} from "../utils/momentUtils";

import Api from "../services/Api";

import { LogoButton } from "./Buttons";

import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  outerContainerLarge: {
    marginTop: "0px",
    marginBottom: "35px",
  },
  outerContainerSmall: {
    marginTop: "8px",
    marginBottom: "35px",
  },
  controlsContainerLarge: {
    marginTop: "0px",
    // marginBottom: "16px",
  },
  controlsContainerSmall: {
    marginTop: "0px",
    // marginBottom: "24px",
  },
  controlLabelLarge: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.334",
  },
  controlLabelSmall: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.1",
  },
  numericSelectorLarge: {
    fontSize: "1.5rem",
    fontWeight: "600",
    width: "200px",
    "& .MuiInputBase-root": { height: "69px" },
  },
  numericSelectorSmall: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "170px",
    "& .MuiInputBase-root": { height: "61px" },
  },
  datasetSelectorLarge: {
    fontSize: "1.5rem",
    fontWeight: "600",
    width: "350px",
    "& .MuiInputBase-root": { height: "69px" },
  },
  datasetSelectorSmall: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "320px",
    "& .MuiInputBase-root": { height: "61px" },
  },
  generateSchedules: {
    width: "370px",
    fontSize: "1.5em",
  },
  createSchedule: {
    width: "380px",
    fontSize: "1.5em",
  },
  pastSchedules: {
    width: "360px",
    fontSize: "1.5em",
  },
}));

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

export const NewOptimizationControls = (props) => {
  const classes = useStyles();
  const { portraitMode } = useLayoutContext();
  const autocompleteLargeClasses = useAutocompleteLargeStyles();
  const autocompleteSmallClasses = useAutocompleteSmallStyles();

  const { onCreate } = props;

  const [earliestStart, setEarliestStart] = useState(
    moment().hour(0).minute(0)
  );
  const [dailyShifts, setDailyShifts] = useState(8);
  const [shiftLength, setShiftLength] = useState(moment().hour(0).minute(0));
  const [assignmentMethod, setAssignmentMethod] = useState(
    assignmentMethods[0]
  );
  const [datasets, setDatasets] = useState([]);
  const [dataset, setDataset] = useState({});
  const [rules, setRules] = useState([]);
  const [showEditPreload, setShowEditPreload] = useState(false);
  const [showEditOffload, setShowEditOffload] = useState(false);
  const [requestName, setRequestName] = useState("");
  const editPreloadRef = useRef();
  const editOffloadRef = useRef();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    updateDatasets();
  }, []);

  const updateDatasets = async () => {
    let awaitDatasets = Api.getDatasets();
    awaitDatasets.then((datasets) => {
      console.log(datasets);
      setDatasets(datasets);
      if (
        dataset === null ||
        (datasets.indexOf(dataset) === -1 && datasets.length > 0)
      ) {
        setDataset(datasets[0]);
      }
    });
  };

  const maxminDailyShiftsChanged = (event, max) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue)) {
      return;
    }
    if (max) setDailyShifts(numericValue);
    // else setMinDailyShifts(numericValue);
  };

  const earliestStartChanged = (event) => {
    SetTimeToHalfHour(event);
    setEarliestStart(event);
  };

  const maxShiftLengthChanged = (event) => {
    SetTimeToHalfHour(event);

    setShiftLength(event);
  };

  // const minShiftLengthChanged = (event) => {
  //   let numericValue = Number(event.target.value);
  //   if (Number.isNaN(numericValue) || numericValue > 24 || numericValue < 0) {
  //     return;
  //   }

  //   setMinShiftLength(numericValue);
  // };

  const assignmentMethodChanged = (event) => {
    setAssignmentMethod(event.target.value);
  };

  const handlePreloadClickAway = (offload) => {
    let ruleName = offload ? "offload" : "preload";
    const preloadRule = getRuleFromRuleList(ruleName, rules);
    if (preloadRule && preloadRule.ruleSettings.Duration === 0) {
      let idx = rules.indexOf(preloadRule);
      rules.splice(idx, 1);
    }
    if (offload) {
      setShowEditOffload(false);
    } else {
      setShowEditPreload(false);
    }
  };

  const editPreload = () => {
    setShowEditPreload(!showEditPreload);
  };

  const editOffload = () => {
    setShowEditOffload(!showEditOffload);
  };

  const closePreload = () => {
    setShowEditPreload(false);
  };

  const closeOffload = () => {
    setShowEditOffload(false);
  };

  const createOptimizationRequest = () => {
    let constraints = [];
    // if (preventGaps) {
    //   let gapsConstraint = {
    //     ConstraintSettings: { prevent: true },
    //     TypeInfo: 2,
    //   };
    //   constraints.push(gapsConstraint);
    // }
    if (
      earliestStart !== null &&
      (earliestStart.hour() > 0 || earliestStart.minute() > 0)
    ) {
      let earlyConstraint = {
        ConstraintSettings: { time: GetPeriodFromMomentTime(earliestStart) },
        TypeInfo: 2,
      };
      constraints.push(earlyConstraint);
    }

    let requestInfo = {
      ShiftCount: dailyShifts,
      ShiftLength: GetPeriodFromMomentTime(shiftLength),
      NumberOfObjectives: 3,
      AssignmentMethod: assignmentMethod.value,
      DatasetId: dataset.id,
      Rules: rules,
      Status: 0,
      OptimizerConstraints: constraints,
      Name: requestName,
    };
    console.log(requestInfo);

    if (onCreate) {
      let success = onCreate(requestInfo);
      if (success) {
        setShiftLength(moment().hour(0).minute(0));
        setEarliestStart(moment().hour(0).minute(0));
        setDailyShifts(8);
        setAssignmentMethod(assignmentMethods[0]);
        setRules([]);
        setRequestName("");
      }
    }
  };

  // const generateSchedules = async () => {
  //   let awaitCurrentSchedule = Api.getCurrentSchedule();
  //   let awaitSchedules = Api.getSimulatedSchedules(
  //     maxDailyShifts,
  //     maxShiftLength,
  //     minShiftLength,
  //     assignmentMethod,
  //     dataset,
  //     4
  //   );
  //   awaitCurrentSchedule.then((currentSchedule) => {
  //     awaitSchedules.then((newSchedules) => {
  //       if (schedules.length > 0) {
  //         schedules.unshift(currentSchedule);
  //       }
  //       for (let i = 0; i < newSchedules.length; i++) {
  //         schedules.push(newSchedules[i]);
  //       }

  //       setSchedules(schedules.slice());
  //     });
  //   });
  // };

  // const viewPastSchedules = () => {
  //   onPastSchedules();
  // };

  return (
    <div
      className={clsx(
        !portraitMode && classes.outerContainerLarge,
        portraitMode && classes.outerContainerSmall
      )}
    >
      <Grid
        container
        spacing={portraitMode ? 2 : 4}
        justify="center"
        alignContent="center"
        className={clsx(
          !portraitMode && classes.controlsContainerLarge,
          portraitMode && classes.controlsContainerSmall
        )}
      >
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Daily Shift Count
          </div>
          <TextField
            variant="outlined"
            value={dailyShifts}
            onChange={(e) => maxminDailyShiftsChanged(e, true)}
            type="number"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
            style={{}}
          ></TextField>
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Earliest Shift Start
          </div>
          <TimePicker
            time={earliestStart}
            onChange={earliestStartChanged}
            clearable
            inputVariant="outlined"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
          />
        </Grid>

        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Shift Length
          </div>
          <TimePicker
            time={shiftLength}
            onChange={maxShiftLengthChanged}
            clearable
            inputVariant="outlined"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
          />
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Assignment Method
          </div>{" "}
          <Autocomplete
            id="assignment-method-combo-box"
            options={assignmentMethods}
            getOptionLabel={(option) => option.name}
            classes={
              portraitMode ? autocompleteSmallClasses : autocompleteLargeClasses
            }
            value={assignmentMethod}
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            autoHighlight
            disableClearable
            autoSelect
            onChange={(event, newValue) => {
              setAssignmentMethod(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: "600", fontSize: "1.25rem" },
                }}
              />
            )}
            aria-label="select assignmentMethod"
            className={classes.assignmentMethod}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={portraitMode ? 2 : 4}
        justify="center"
        alignContent="center"
        className={clsx(
          !portraitMode && classes.controlsContainerLarge,
          portraitMode && classes.controlsContainerSmall
        )}
      >
        {/* <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={preventGaps}
                onChange={(e) => setPreventGaps(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            style={{ marginTop: ".75em" }}
            label="Prevent Gaps in Schedule"
          />
        </Grid> */}
        <ClickAwayListener onClickAway={() => handlePreloadClickAway(false)}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editPreload}
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editPreloadRef}
            >
              Preload
            </Button>
            {showEditPreload ? (
              <PreloadOffloadEditDialog
                title="Optimization Preload"
                data={rules}
                ruleName="preload"
                portalRef={editPreloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={() => handlePreloadClickAway(true)}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={editOffload}
              style={{
                height: "56px",
                fontWeight: "600",
                width: "112px",
              }}
              ref={editOffloadRef}
            >
              Offload
            </Button>
            {showEditOffload ? (
              <PreloadOffloadEditDialog
                title="Optimization Offload"
                ruleName="offload"
                data={rules}
                portalRef={editOffloadRef}
                onUpdate={() => {
                  setRenderId(uuidv4());
                }}
              />
            ) : (
              <div />
            )}
          </Grid>
        </ClickAwayListener>
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        spacing={portraitMode ? 2 : 4}
      >
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Choose Data Set
          </div>
          <Autocomplete
            id="dataset-combo-box"
            options={datasets}
            getOptionLabel={(option) => option.name}
            classes={
              portraitMode ? autocompleteSmallClasses : autocompleteLargeClasses
            }
            value={dataset}
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            autoHighlight
            disableClearable
            autoSelect
            onChange={(event, newValue) => {
              setDataset(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: "600", fontSize: "1.25rem" },
                }}
              />
            )}
            aria-label="select dataset"
            className={
              portraitMode
                ? classes.datasetSelectorSmall
                : classes.datasetSelectorLarge
            }
          />
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Request Name
          </div>
          <TextField
            variant={"outlined"}
            value={requestName}
            onChange={(e) => setRequestName(e.target.value)}
            className={
              portraitMode
                ? classes.datasetSelectorSmall
                : classes.datasetSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        alignContent="center"
        spacing={portraitMode ? 2 : 4}
      >
        <Grid item>
          <LogoButton
            variant="contained"
            onClick={createOptimizationRequest}
            className={classes.createSchedule}
          >
            Create Request
          </LogoButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewOptimizationControls;
