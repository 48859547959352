import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Link,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@material-ui/icons/AccountBox";
import Brightness4Icon from "@material-ui/icons/Brightness4";

import flag from "../images/bmsflag.png";
import logo from "../images/logo.png";

import { viewNames } from "../utils/constants";

import { useThemeContext } from "../ThemeProviderHost";
import { useLayoutContext } from "./Layout";
import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.text.default,
    zIndex: theme.zIndex.drawer + 1,
  },
  titleLink: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    paddingRight: "16px",
  },
  titleMatrix: {
    fontFamily: "Kanit",
    fontWeight: "600",
    color: theme.palette.text.default,
    marginTop: "-2px",
    letterSpacing: "4px",
    marginLeft: "14px",
  },
  currentViewLabel: {
    fontFamily: "Kanit",
    fontWeight: "600",
    color: theme.palette.text.default,
    marginTop: "-2px",
    letterSpacing: "4px",
    display: "inline-block",
    textTransform: "uppercase",
  },
  logo: {
    height: "48px",
    width: "162px",
    marginTop: "4px",
  },
  menuIcon: {
    height: "48px",
    width: "48px",
    color: theme.palette.text.default,
  },
}));

export const NavBar = () => {
  const classes = useStyles();
  const { toggleTheme, usingDarkMode } = useThemeContext();
  const { toggleMenuOpen, currentView } = useLayoutContext();
  const { isAuthenticated, logout } = useAuth0();

  let backgroundStyle, viewLabelStyle;
  if (usingDarkMode) {
    backgroundStyle = { backgroundColor: "#3A3A3C" };
    viewLabelStyle = { color: "#F1F2F6" };
  } else {
    backgroundStyle = { backgroundColor: "#F1F2F6" };
    viewLabelStyle = { color: "#3A3A3C" };
  }

  const getCurrentViewName = () => {
    return viewNames[currentView];
  };

  return (
    <AppBar
      className={classes.appBar}
      id="appbar"
      position="sticky"
      style={backgroundStyle}
    >
      <Toolbar style={{ position: "relative" }}>
        {isAuthenticated && (
          <IconButton onClick={toggleMenuOpen} style={{ marginRight: "16px" }}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        )}
        <div className={classes.titleDiv}>
          <Link
            component={RouterLink}
            to="/"
            className={classes.titleLink}
            underline="none"
            color="textPrimary"
          >
            <img src={flag} className={classes.logo} />
            <Typography variant="h3" className={classes.titleMatrix}>
              MATRIX
            </Typography>
          </Link>
        </div>
        <div style={{ flexGrow: 1, marginLeft: "16px" }}>
          <Typography
            variant="h4"
            style={viewLabelStyle}
            className={classes.currentViewLabel}
          >
            {getCurrentViewName()}
          </Typography>
        </div>
        {isAuthenticated && (
          <Button className={classes.logoutButton} onClick={() => logout()}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
