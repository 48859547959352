import React, { useEffect, useState } from "react";

import { makeStyles, Grid, Typography } from "@material-ui/core";

import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";

import { acuities, getAcuityByValue } from "../utils/constants";

import { useLayoutContext } from "./Layout";

import AssignmentHistoryInfo from "./AssignmentHistoryInfo";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  outerGridMargin: { marginTop: "16px" },
  outerGridWidth: { minWidth: "680px" },
  recentAssignments: {
    overflowY: "auto",
    marginTop: "16px",
    marginLeft: "16px",
    overflowY: "auto",
  },
  widthMinus16: {
    width: "calc(100% - 16px)",
  },
  widthMinus20: {
    width: "calc(100% - 20px)",
  },
  assignmentHistoryItem: { width: "100%" },
}));

export const AssignmentHistoryDisplay = (props) => {
  const {
    assignmentHistory,
    onReassignClick,
    onUnassignClick,
    hideControls,
    useHeaderSpacing,
    fullWidth,
  } = props;
  const { portraitMode } = useLayoutContext();
  const classes = useStyles();
  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [portraitMode]);

  return (
    <Grid
      container
      item
      xs={portraitMode || fullWidth ? 12 : 6}
      alignItems="flex-start"
      justify="flex-start"
      direction="column"
      className={clsx(
        classes.outerGridWidth,
        (useHeaderSpacing || portraitMode) && classes.outerGridMargin
      )}
      id={renderId}
    >
      <Header>RECENT ASSIGNMENTS</Header>
      <Grid
        item
        container
        className={clsx(
          classes.recentAssignments,
          portraitMode && classes.widthMinus20,
          !portraitMode && classes.widthMinus16
        )}
      >
        {assignmentHistory.map((historyInfo) => {
          let index = assignmentHistory.indexOf(historyInfo);
          let isLastItem = index == assignmentHistory.length - 1;

          let acuity = "Unknown";
          if (historyInfo.patients.length > 0) {
            let patient0 = historyInfo.patients[0];

            // console.log(patient0);
            if (patient0.acuity !== undefined) {
              let acuityObj = getAcuityByValue(patient0.acuity);
              acuity = acuityObj.name;
            }
          }

          return (
            <Grid
              item
              key={historyInfo.id}
              className={classes.assignmentHistoryItem}
            >
              <AssignmentHistoryInfo
                historyInfo={historyInfo}
                acuity={acuity}
                isLastItem={isLastItem}
                hideControls={hideControls}
                onReassignClick={onReassignClick}
                onUnassignClick={onUnassignClick}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AssignmentHistoryDisplay;
