import React, { useState } from "react";

import {
  makeStyles,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  TextField,
  Divider,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { ErrorButton, InfoButton, WarningButton } from "./Buttons";

const useFlagBusyStyles = makeStyles((theme) => ({
  procedureButton: {
    fontWeight: "600",
    width: "140px",
  },
  unavailableButton: {
    fontWeight: "600",
    width: "140px",
  },
  codeButton: {
    fontWeight: "600",
    width: "140px",
  },
  cancelButton: {},
}));
const filter = createFilterOptions();

const durationOptions = [
  { title: "Indefinite", value: "-1" },
  { title: "15 minutes", value: "15" },
  { title: "20 minutes", value: "20" },
  { title: "25 minutes", value: "25" },
  { title: "30 minutes", value: "30" },
  { title: "45 minutes", value: "45" },
];

export const FlagBusyDialog = (props) => {
  const classes = useFlagBusyStyles();
  const { dialogOpen, provider, closeDialog } = props;
  const [busyDuration, setBusyDuration] = useState(durationOptions[0]);

  const providerName = provider ? provider.name : "Unknown";

  const closeFlagBusyClicked = (busyReason) => {
    let busyMinutes = Number(busyDuration.value);
    if (busyMinutes && Number.isNaN(busyMinutes)) {
      alert("Busy duration must be a number!");
      return;
    }

    closeDialog(busyReason, busyDuration.value);
    setTimeout(resetBusyDuration, 1000);
  };

  const resetBusyDuration = async () => {
    setBusyDuration({ title: "Indefinite", value: "-1" });
  };

  const procedureClicked = () => {
    closeFlagBusyClicked("procedure");
  };

  const unavailableClicked = () => {
    closeFlagBusyClicked("unavailable");
  };

  const codeClicked = () => {
    closeFlagBusyClicked("code");
  };

  const cancelClicked = () => {
    closeFlagBusyClicked(null);
  };

  return (
    <Dialog open={dialogOpen} aria-labelledby="flagbusy-dialog-title">
      <DialogTitle id="flagbusy-dialog-title">
        <Typography variant="h5" id="flagbusy-dialog-title">
          <b>Reason and Duration Provider is Busy</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <Autocomplete
            style={{ marginBottom: "8px" }}
            value={busyDuration}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                if (newValue.endsWith(" minutes")) {
                  let minutes = newValue.split(" ");
                  setBusyDuration({
                    title: newValue,
                    value: Number(minutes[0]),
                  });
                } else {
                  setBusyDuration({ title: newValue, value: Number(newValue) });
                }
              } else if (newValue && newValue.inputValue) {
                setBusyDuration({
                  title: newValue.inputValue,
                  value: newValue,
                });
              } else {
                setBusyDuration(newValue);
              }
            }}
            filterOptions={(durationOptions, params) => {
              const filtered = filter(durationOptions, params);

              let numMinutes = Number(params.inputValue);
              if (Number.isNaN(numMinutes) || numMinutes == 0) {
                return filtered;
              }

              let numFiltered = filtered.length;
              for (let i = 0; i < numFiltered; i++) {
                if (filtered[i].value == numMinutes) {
                  return filtered;
                }
              }

              filtered.push({
                value: numMinutes,
                title: `${numMinutes} minutes`,
              });

              return filtered;
            }}
            options={durationOptions}
            selectOnFocus
            clearOnBlur
            autoHighlight
            autoSelect
            handleHomeEndKeys
            freeSolo
            id=""
            renderInput={(params) => (
              <TextField {...params} label="Busy for" variant="outlined" />
            )}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }

              if (option.title) {
                return option.title;
              }

              return option.value;
            }}
          />
          <div></div>
        </div>
        <Divider />
        <Typography
          variant="h6"
          style={{ fontWeight: "500", marginTop: "8px", marginBottom: "8px" }}
        >
          Select Reason Provider {providerName} is Busy
        </Typography>
        <Divider />
        <div style={{ marginTop: "16px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "8px",
            }}
          >
            <InfoButton
              variant="contained"
              onClick={procedureClicked}
              className={classes.procedureButton}
            >
              Procedure
            </InfoButton>
            <div
              style={{
                fontWeight: "600",
                lineHeight: "36px",
                marginLeft: "16px",
              }}
            >
              The provider is starting a procedure
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "8px",
            }}
          >
            <WarningButton
              variant="contained"
              onClick={unavailableClicked}
              className={classes.unavailableButton}
            >
              Unavailable
            </WarningButton>
            <div
              style={{
                fontWeight: "600",
                lineHeight: "36px",
                marginLeft: "16px",
              }}
            >
              The provider is now unavailable
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "8px",
            }}
          >
            <ErrorButton
              variant="contained"
              onClick={codeClicked}
              className={classes.codeButton}
            >
              CODE
            </ErrorButton>
            <div
              style={{
                fontWeight: "600",
                lineHeight: "36px",
                marginLeft: "16px",
              }}
            >
              The provider is starting a CODE case
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={cancelClicked}
          className={classes.cancelButton}
          style={{ marginRight: "8px", marginBottom: "8px", fontWeight: "600" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
