import React, { useState, useEffect } from "react";

import { makeStyles, Grid, Typography } from "@material-ui/core";

import clsx from "clsx";

import { v4 as uuidv4 } from "uuid";

import { useLayoutContext } from "../components/Layout";

import AvailableProviderInfo from "./AvailableProviderInfo";

import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  outerGrid: { minWidth: "680px", marginTop: "16px" },
  untriagedOuterGrid: { minWidth: "680px", marginTop: "0px" },
  outerProviderGrid: { overflowY: "auto", width: "100%" },
  innerProviderGrid: { width: "calc(100% - 68px)", marginTop: "16px" },
  providerGridItem: { width: "100%", marginLeft: "16px", marginRight: "16px" },
}));

export const AvailableProviderDisplay = (props) => {
  const {
    nextProviders,
    acuity,
    hideControls,
    useHeaderSpacing,
    onSelect,
    onFlagBusy,
    onFlagAvailable,
    useGridSizing,
    untriagedShown,
  } = props;
  const classes = useStyles();
  const { portraitMode } = useLayoutContext();

  const [renderId, setRenderId] = useState(uuidv4());

  useEffect(() => {
    setRenderId(uuidv4());
  }, [portraitMode]);

  return (
    <Grid
      container
      item
      xs={portraitMode || !useGridSizing ? 12 : 6}
      alignItems="flex-start"
      justify="flex-start"
      direction="column"
      className={clsx(
        (!untriagedShown || !portraitMode) &&
          useHeaderSpacing &&
          classes.outerGrid,
        !portraitMode && untriagedShown && classes.untriagedOuterGrid
      )}
      id={renderId}
    >
      <Header>
        {" "}
        UP NEXT FOR LEVEL{" "}
        {acuity.name == "-Untriaged-" ? "UNTRIAGED" : acuity.name.toUpperCase()}
      </Header>
      <Grid item className={classes.outerProviderGrid}>
        <Grid
          container
          // spacing={4}
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          id="upnext-list-grid"
          className={classes.innerProviderGrid}
        >
          {nextProviders.map((providerInfo) => {
            let index = nextProviders.indexOf(providerInfo);
            let isLastItem = index === nextProviders.length - 1;

            return (
              <Grid key={index} item className={classes.providerGridItem}>
                <AvailableProviderInfo
                  providerInfo={providerInfo}
                  hideControls={hideControls}
                  isLastItem={isLastItem}
                  onSelect={onSelect}
                  onFlagBusy={onFlagBusy}
                  onFlagAvailable={onFlagAvailable}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailableProviderDisplay;
