import React, { useEffect, useState } from "react";

import { makeStyles, Grid } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";

import { acuities } from "../utils/constants";
import { detectPortraitMode } from "../utils/utilities";

import Api from "../services/Api";

import OuterDiv from "../components/OuterDiv";
import { useLayoutContext } from "../components/Layout";
import { AvailableProviderDisplay } from "../components/AvailableProviderDisplay";
import { AssignmentHistoryDisplay } from "../components/AssignmentHistoryDisplay";

export const UpNext = (props) => {
  const { setCurrentView, portraitMode } = useLayoutContext();
  const [renderId, setRenderId] = useState(uuidv4());
  const [nextProviders, setNextProviders] = useState([]);
  const [assignmentHistory, setAssignmentHistory] = useState([]);

  const updateNextProvider = () => {
    let awaitNextProviders = Api.getNextProviders({
      acuity: acuities[4],
      arrivalTime: moment().format(),
    });
    awaitNextProviders.then((nextProviders) => {
      setNextProviders(nextProviders);
    });
  };

  const updateAssignmentHistory = () => {
    let awaitAssignmentHistory = Api.getAssignmentHistory();
    awaitAssignmentHistory.then((assignmentHistory) => {
      setAssignmentHistory(assignmentHistory);
    });
  };

  const updateDisplay = () => {
    updateNextProvider();
    updateAssignmentHistory();
    setRenderId(uuidv4());
  };

  useEffect(() => {
    setCurrentView("UpNext");

    const updateDisplayInterval = setInterval(() => {
      updateDisplay();
    }, 5000);

    return () => {
      clearInterval(updateDisplayInterval);
    };
  }, []);

  useEffect(() => {
    updateDisplay();
  }, [portraitMode]);

  return (
    <OuterDiv id={renderId}>
      <Grid container direction="row" justify="space-evenly">
        <AvailableProviderDisplay
          hideControls={true}
          nextProviders={nextProviders}
          acuity={acuities[4]}
          useGridSizing={true}
        />
        <AssignmentHistoryDisplay
          hideControls={true}
          assignmentHistory={assignmentHistory}
        />
      </Grid>
    </OuterDiv>
  );
};

export default UpNext;
