import React from "react";

import { makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontFamily: "Montserrat, Kanit, Ubuntu, Segoe UI",
    marginTop: "-96px",
    marginBottom: "16px",
  },
  descriptionText: {
    color: theme.palette.text.primary,
    fontFamily: "Kanit, Montserrat, Ubuntu, Segoe UI",
  },
}));

export const Unauthorized = (props) => {
  const classes = useStyles();
  const { emailVerified } = props;

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {emailVerified && (
        <Grid item style={{ width: "600px" }}>
          <Typography variant="h4" className={classes.headerText}>
            Unauthorized
          </Typography>
          <Typography variant="h6" className={classes.descriptionText}>
            Your email address has been verified but you do not currently have
            an active user account for this application. If you believe you have
            received this message in error, please contact your supervisor or
            email <a href="mailto:support@levrum.com">support@levrum.com</a> for
            assistance.
          </Typography>
        </Grid>
      )}
      {!emailVerified && (
        <Grid item style={{ width: "600px" }}>
          <Typography variant="h4" className={classes.headerText}>
            Email Verification Required
          </Typography>
          <Typography variant="h6" className={classes.descriptionText}>
            You must verify your email address before you can use this
            application. Please check your inbox for an email from BMS Matrix
            (no-reply@auth0user.net) to complete the verification process, or
            contact <a href="mailto:support@levrum.com">support@levrum.com</a>{" "}
            for assistance.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Unauthorized;
