import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Portal,
  Paper,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  acuities,
  getAcuityByValue,
  CreateDefaultPreloadOffloadRule,
} from "../utils/constants";
import { getRuleFromRuleList } from "../utils/utilities";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    marginTop: "-8px",
    marginBottom: "8px",
  },
  maxAcuitySelector: {
    width: "180px",
  },
}));

export const PreloadOffloadEditDialog = (props) => {
  const classes = useStyles();
  const { closeDialog, data, portalRef, title, onUpdate, ruleName } = props;
  const [length, setLength] = useState("");
  const [maxPatients, setMaxPatients] = useState("");
  const [maxAcuity, setMaxAcuity] = useState(null);
  const [rule, setRule] = useState(null);

  useEffect(() => {
    updateFields();
  }, []);

  useEffect(() => {
    updateFields();
  }, [data, ruleName]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate();
    }
  }, [length, maxPatients, maxAcuity]);

  const updateFields = () => {
    if (data === undefined) {
      return;
    }
    let ruleT = getRuleFromRuleList(ruleName, data);
    console.log(ruleT);
    if (!Boolean(ruleT)) {
      ruleT = CreateDefaultPreloadOffloadRule(ruleName);
      data.push(ruleT);
    }

    setRule(ruleT);
    setLength(ruleT.ruleSettings.Duration);
    setMaxPatients(ruleT.ruleSettings.MaxPatients);
    setMaxAcuity(getAcuityByValue(ruleT.ruleSettings.MaxAcuity));
  };

  let clientRect = portalRef.current.getBoundingClientRect();

  let paperStyle = {
    position: "fixed",
    width: "412px",
    zIndex: "100",
    top: `${clientRect.y + 56}px`,
    left: `${clientRect.x - 170}px`,
    padding: "16px",
  };

  if (!data) {
    return <div />;
  }

  const handleClose = () => {
    if (closeDialog) {
      closeDialog();
    }
  };

  return (
    <Portal container={portalRef.current}>
      <Paper style={paperStyle}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Grid container direction="row">
          <Grid item>
            <TextField
              variant="outlined"
              type="number"
              onChange={(event) => {
                let numericValue = Number(event.target.value);
                if (numericValue < 0 || numericValue > 1) {
                  return;
                }
                rule.ruleSettings.Duration = numericValue;
                setLength(numericValue);
              }}
              inputProps={{ step: 0.25 }}
              label="Length"
              value={length}
              style={{ width: "100px", marginRight: "16px" }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              type="number"
              onChange={(event) => {
                let numericValue = Number(event.target.value);
                if (numericValue < 0 || numericValue > 10) {
                  return;
                }
                rule.ruleSettings.MaxPatients = numericValue;
                setMaxPatients(numericValue);
              }}
              label="Max Pts"
              value={maxPatients}
              style={{ width: "100px", marginRight: "16px" }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              id="acuity-combo-box"
              options={acuities}
              getOptionLabel={(option) => option.name}
              value={maxAcuity}
              selectOnFocus
              handleHomeEndKeys
              clearOnBlur
              autoHighlight
              autoSelect
              onChange={(event, newValue) => {
                setMaxAcuity(newValue);
                if (newValue) {
                  rule.ruleSettings.MaxAcuity = newValue.value;
                } else {
                  rule.ruleSettings.MaxAcuity = null;
                }
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Max Acuity" />
              )}
              aria-label="select acuity"
              className={classes.maxAcuitySelector}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button variant="contained" color="secondary" style={{marginTop: ".5em"}} component="div">
              Remove
            </Button>
          </Grid> */}
        </Grid>
      </Paper>
    </Portal>
  );
};

export default PreloadOffloadEditDialog;
