import React, { useState, useEffect } from "react";

import { makeStyles, Grid, Button, TextField } from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import clsx from "clsx";

import { assignmentMethods } from "../utils/constants";

import { useLayoutContext } from "./Layout";

import Api from "../services/Api";

import { LogoButton } from "./Buttons";

const useStyles = makeStyles((theme) => ({
  outerContainerLarge: {
    marginTop: "0px",
    marginBottom: "35px",
  },
  outerContainerSmall: {
    marginTop: "8px",
    marginBottom: "35px",
  },
  controlsContainerLarge: {
    marginTop: "0px",
    // marginBottom: "16px",
  },
  controlsContainerSmall: {
    marginTop: "0px",
    // marginBottom: "24px",
  },
  controlLabelLarge: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.334",
  },
  controlLabelSmall: {
    marginBottom: "4px",
    fontWeight: "400",
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
    fontFamily: "Montserrat, Kanit, Ubuntu",
    lineHeight: "1.1",
  },
  numericSelectorLarge: {
    fontSize: "1.5rem",
    fontWeight: "600",
    width: "200px",
    "& .MuiInputBase-root": { height: "69px" },
  },
  numericSelectorSmall: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "170px",
    "& .MuiInputBase-root": { height: "61px" },
  },
  datasetSelectorLarge: {
    fontSize: "1.5rem",
    fontWeight: "600",
    width: "350px",
    "& .MuiInputBase-root": { height: "69px" },
  },
  datasetSelectorSmall: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "320px",
    "& .MuiInputBase-root": { height: "61px" },
  },
  generateSchedules: {
    width: "370px",
    fontSize: "1.5em",
  },
  createSchedule: {
    width: "380px",
    fontSize: "1.5em",
  },
  pastSchedules: {
    width: "360px",
    fontSize: "1.5em",
  },
}));

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

export const SimulatorControls = (props) => {
  const classes = useStyles();
  const { portraitMode } = useLayoutContext();
  const autocompleteLargeClasses = useAutocompleteLargeStyles();
  const autocompleteSmallClasses = useAutocompleteSmallStyles();

  const { schedules, setSchedules, createSchedule, onPastSchedules } = props;

  const [earliestStart, setEarliestStart] = useState(4);
  const [maxDailyShifts, setMaxDailyShifts] = useState(8);
  const [maxShiftLength, setMaxShiftLength] = useState(10);
  const [minShiftLength, setMinShiftLength] = useState(4);
  const [assignmentMethod, setAssignmentMethod] = useState(
    assignmentMethods[0]
  );
  const [datasets, setDatasets] = useState([]);
  const [dataset, setDataset] = useState({});

  useEffect(() => {
    updateDatasets();
  }, []);

  const updateDatasets = async () => {
    let awaitDatasets = Api.getDatasets();
    awaitDatasets.then((datasets) => {
      console.log(datasets);
      setDatasets(datasets);
      if (
        dataset === null ||
        (datasets.indexOf(dataset) === -1 && datasets.length > 0)
      ) {
        setDataset(datasets[0]);
      }
    });
  };

  const maxDailyShiftsChanged = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue)) {
      return;
    }

    setMaxDailyShifts(numericValue);
  };

  const earliestStartChanged = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue)) {
      return;
    }

    setEarliestStart(numericValue);
  };

  const maxShiftLengthChanged = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue) || numericValue > 24 || numericValue < 0) {
      return;
    }

    setMaxShiftLength(numericValue);
  };

  const minShiftLengthChanged = (event) => {
    let numericValue = Number(event.target.value);
    if (Number.isNaN(numericValue) || numericValue > 24 || numericValue < 0) {
      return;
    }

    setMinShiftLength(numericValue);
  };

  const assignmentMethodChanged = (event) => {
    setAssignmentMethod(event.target.value);
  };

  const generateSchedules = async () => {
    let awaitCurrentSchedule = Api.getCurrentSchedule();
    let awaitSchedules = Api.getSimulatedSchedules(
      maxDailyShifts,
      maxShiftLength,
      minShiftLength,
      assignmentMethod,
      dataset,
      4
    );
    awaitCurrentSchedule.then((currentSchedule) => {
      awaitSchedules.then((newSchedules) => {
        if (schedules.length > 0) {
          schedules.unshift(currentSchedule);
        }
        for (let i = 0; i < newSchedules.length; i++) {
          schedules.push(newSchedules[i]);
        }

        setSchedules(schedules.slice());
      });
    });
  };

  const viewPastSchedules = () => {
    onPastSchedules();
  };

  return (
    <div
      className={clsx(
        !portraitMode && classes.outerContainerLarge,
        portraitMode && classes.outerContainerSmall
      )}
    >
      <Grid
        container
        spacing={portraitMode ? 2 : 4}
        justify="center"
        alignContent="center"
        className={clsx(
          !portraitMode && classes.controlsContainerLarge,
          portraitMode && classes.controlsContainerSmall
        )}
      >
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Max Daily Shifts
          </div>
          <TextField
            variant="outlined"
            value={maxDailyShifts}
            onChange={maxDailyShiftsChanged}
            type="number"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
            style={{}}
          ></TextField>
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Earliest Start
          </div>
          <TextField
            variant="outlined"
            value={earliestStart}
            onChange={earliestStartChanged}
            type="number"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
            style={{}}
          ></TextField>
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Max Shift Length
          </div>
          <TextField
            variant="outlined"
            value={maxShiftLength}
            onChange={maxShiftLengthChanged}
            type="number"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
          ></TextField>
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Min Shift Length
          </div>
          <TextField
            variant="outlined"
            value={minShiftLength}
            onChange={minShiftLengthChanged}
            type="number"
            className={
              portraitMode
                ? classes.numericSelectorSmall
                : classes.numericSelectorLarge
            }
            inputProps={{ style: { fontWeight: "600", fontSize: "1.5rem" } }}
          ></TextField>
        </Grid>
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Assignment Method
          </div>{" "}
          <Autocomplete
            id="assignment-method-combo-box"
            options={assignmentMethods}
            getOptionLabel={(option) => option.name}
            classes={
              portraitMode ? autocompleteSmallClasses : autocompleteLargeClasses
            }
            value={assignmentMethod}
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            autoHighlight
            disableClearable
            autoSelect
            onChange={(event, newValue) => {
              setAssignmentMethod(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: "600", fontSize: "1.25rem" },
                }}
              />
            )}
            aria-label="select assignmentMethod"
            className={classes.assignmentMethod}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        spacing={portraitMode ? 2 : 4}
      >
        <Grid item>
          <div
            className={
              portraitMode
                ? classes.controlLabelSmall
                : classes.controlLabelLarge
            }
          >
            Choose Data Set
          </div>
          <Autocomplete
            id="dataset-combo-box"
            options={datasets}
            getOptionLabel={(option) => option.name}
            classes={
              portraitMode ? autocompleteSmallClasses : autocompleteLargeClasses
            }
            value={dataset}
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            autoHighlight
            disableClearable
            autoSelect
            onChange={(event, newValue) => {
              setDataset(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  style: { fontWeight: "600", fontSize: "1.25rem" },
                }}
              />
            )}
            aria-label="select dataset"
            className={
              portraitMode
                ? classes.datasetSelectorSmall
                : classes.datasetSelectorLarge
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        alignContent="center"
        spacing={portraitMode ? 2 : 4}
      >
        <Grid item>
          <LogoButton
            variant="contained"
            onClick={generateSchedules}
            className={classes.generateSchedules}
          >
            Generate Schedules
          </LogoButton>
        </Grid>
        <Grid item>
          <LogoButton
            variant="contained"
            onClick={createSchedule}
            className={classes.createSchedule}
          >
            Create Schedule
          </LogoButton>
        </Grid>
        <Grid item>
          <LogoButton
            variant="contained"
            onClick={viewPastSchedules}
            className={classes.pastSchedules}
          >
            Past Schedules
          </LogoButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SimulatorControls;
