import React, { useState, useEffect, useContext } from "react";
import { makeStyles, IconButton, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useLayoutContext } from "../components/Layout";
import Api from "../services/Api";
import OuterDiv from "../components/OuterDiv";
import Header from "../components/Header";
import ScheduleList from "../components/ScheduleList";

const useStyles = makeStyles((theme) => ({}));

export const SimulationAnalysis = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [schedules, setSchedules] = useState([]);
  const [selectedSched, setSelectedSched] = useState(null);
  const { setCurrentView, portraitMode } = useLayoutContext();

  // const [useState]

  useEffect(() => {
    const call = async () => {
      await fetchSchedules();
    };
    call();
    setCurrentView("Simulation");
  }, []);

  const fetchSchedules = async () => {
    const scheds = await Api.getAllSchedules();
    if (scheds != null) {
      setSchedules(scheds);
    } else {
      setSchedules([]);
    }
  };

  const listOnClick = (schedule) => {
    if (schedule) {
      history.push("/simAnalyzer", { schedule: schedule });
    }
  };

  return (
    <OuterDiv>
      <Header>Select Schedule to Simulate</Header>
      <Grid container alignContent="center" style={{ margin: ".5em" }}>
        <Grid item xs={12}>
          <ScheduleList schedules={schedules} onClick={listOnClick} />
        </Grid>
      </Grid>
    </OuterDiv>
  );
};

export default SimulationAnalysis;

// const ScheduleList = (props) => {

//   const {schedules} = props;

//   return (
// <Grid container justify="center">
// <Grid
//   item
//   style={{ width: "685px", margin: "16px" }}
//   key={index}
// >
//   <Paper
//     style={{
//       paddingTop: "12px",
//       paddingBottom: "16px",
//       paddingLeft: "8px",
//       paddingRight: "12px",
//     }}
//   >
//     <Typography
//       variant="h5"
//       style={{
//         fontWeight: "600",
//         marginLeft: "16px",
//       }}
//     >
//       {schedule.name}
//     </Typography>
//     <Schedule schedule={schedule} dense={true} />
//     <Grid
//       container
//       justify="center"
//       alignItems="center"
//       direction="row"
//       spacing={4}
//     >
//       <Grid item>
//         <Button
//           variant="contained"
//           color="primary"
//           disabled={index === 0}
//           onClick={() => {
//             setUsingSchedule(schedule);
//           }}
//         >
//           Use
//         </Button>
//       </Grid>
//       <Grid item>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => {
//             if (index === 0) {
//               let scheduleCopy = JSON.parse(
//                 JSON.stringify(schedule)
//               );
//               setEditingSchedule(scheduleCopy);
//             } else {
//               setEditingSchedule(schedule);
//             }
//           }}
//         >
//           Edit
//         </Button>
//       </Grid>
//       <Grid item>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => {
//             schedule.shiftsByPrefix = schedule.shifts;
//             setAnalyzingSchedule(schedule);
//           }}
//         >
//           Details
//         </Button>
//       </Grid>
//       {(schedule.retain === false ||
//         schedule.retain === undefined) && (
//         <Grid item>
//           <Tooltip title="Keep this schedule when generating new ones">
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => toggleScheduleHold(schedule)}
//               disabled={index === 0}
//             >
//               Hold
//             </Button>
//           </Tooltip>
//         </Grid>
//       )}
//       {schedule.retain === true && (
//         <Grid item>
//           <Tooltip title="Keep this schedule when generating new ones">
//             <SuccessButton
//               variant="contained"
//               onClick={() => toggleScheduleHold(schedule)}
//             >
//               Hold
//             </SuccessButton>
//           </Tooltip>
//         </Grid>
//       )}
//       <Grid item>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => {
//             setDiscardingSchedule(schedule);
//           }}
//           disabled={index === 0}
//         >
//           Discard
//         </Button>
//       </Grid>
//     </Grid>
//   </Paper>
// </Grid>
// </Grid>
//   )

// }
