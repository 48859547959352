import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";

import clsx from "clsx";
import moment from "moment";

import { acuities, getAcuityByValue } from "../utils/constants";

import Api from "../services/Api";

import { AssignmentControls } from "./AssignmentControls";
import { RoomSelectionDialog } from "./RoomSelectionDialog";
import OuterDiv from "./OuterDiv";

import { LogoButton } from "./Buttons";

const PaperComponent = (props) => {
  return <Paper style={{ width: "1366px" }}>{props.children}</Paper>;
};

export const EditAssignmentDialog = (props) => {
  const {
    assignment,
    dialogOpen,
    closeDialog,
    availableProviders,
    availableRooms,
  } = props;

  const [provider, setProvider] = useState({});
  const [assignmentTime, setAssignmentTime] = useState(null);
  const [acuity, setAcuity] = useState({});
  const [room, setRoom] = useState({});
  const [numPatients, setNumPatients] = useState("1");
  const [assignmentTimeLabel, setAssignmentTimeLabel] = useState(
    "Use Current Time"
  );

  useEffect(() => {
    setRoom(assignment.room);
    if (assignment.patients) {
      setNumPatients(Number(assignment.patients.length).toString());
      if (assignment.patients.length > 0) {
        if (assignment.patients[0].acuity) {
          setAcuity(getAcuityByValue(assignment.patients[0].acuity));
        }
      }
    }

    setProvider(assignment.provider);
    setAssignmentTime(assignment.assignmentTime);
  }, [assignment]);

  const updateAssignment = async () => {
    assignment.provider = provider;
    if (assignmentTime !== null) {
      assignment.assignmentTime = moment(assignmentTime).format();
    } else {
      assignment.assignmentTime = moment().format();
    }
    assignment.room = room;

    for (let i = 0; i < assignment.patients.length; i++) {
      assignment.patients[i].acuity = acuity.value;
    }

    while (assignment.patients.length > Number(numPatients)) {
      assignment.patients.pop();
    }

    while (assignment.patients.length < Number(numPatients)) {
      assignment.patients.push({
        arrivalTime: assignment.patients[0].arrivalTime,
        acuity: acuity.value,
        triage: "",
      });
    }
    console.log(assignment);

    await props.onEditAssignment(assignment);

    closeDialog(true);
  };

  return (
    <Dialog open={dialogOpen} PaperComponent={PaperComponent}>
      <DialogTitle>
        <Typography variant="h3">Edit Assignment</Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "16px", paddingBottom: "16px" }}>
        <AssignmentControls
          provider={provider}
          setProvider={setProvider}
          availableProviders={availableProviders}
          assignmentTime={assignmentTime}
          setAssignmentTime={setAssignmentTime}
          acuity={acuity}
          setAcuity={setAcuity}
          room={room}
          setRoom={setRoom}
          availableRooms={availableRooms}
          numPatients={numPatients}
          setNumPatients={setNumPatients}
          assignmentTimeLabel={assignmentTimeLabel}
          useRoomSelector={true}
        />
      </DialogContent>
      <DialogActions style={{ marginRight: "32px", marginBottom: "16px" }}>
        <LogoButton
          variant="contained"
          color="primary"
          style={{ fontWeight: "600" }}
          onClick={updateAssignment}
        >
          Update
        </LogoButton>
        <Button
          variant="contained"
          style={{ fontWeight: "600", fontFamily: "Kanit" }}
          onClick={closeDialog}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssignmentDialog;
