import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "../components/Layout";
import { Schedule } from "../components/Schedule";

import { Grid, Button, makeStyles } from "@material-ui/core";
import OuterDiv from "../components/OuterDiv";
import Header from "../components/Header";

export const ScheduleViewLink = (props) => {
  const { schedule } = props.location.state;
  const { portraitMode } = useLayoutContext();

  return <ScheduleView schedule={schedule} portraitMode={portraitMode} />;
};

const ScheduleView = ({ schedule, portraitMode }) => {
  return (
    <OuterDiv>
      <Header>Schedule: {schedule.name}</Header>
      <Grid
        container
        justify={"center"}
        alignContent={"center"}
        style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}
      >
        <Grid
          item
          style={portraitMode ? { width: "665px" } : { width: "1265px" }}
        >
          <Schedule schedule={schedule} dense={portraitMode} />
        </Grid>
      </Grid>
    </OuterDiv>
  );
};

export default ScheduleView;
