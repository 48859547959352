import React, { useState, useEffect } from "react";

import { makeStyles, Grid, Paper, Typography } from "@material-ui/core";

import { ObjectiveNames } from "../utils/constants";

import c3 from "c3";

import { v4 as uuidv4 } from "uuid";

import Api from "../services/Api";

import { useThemeContext } from "../ThemeProviderHost";
import { useLayoutContext } from "./Layout";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  chartTitle: {
    color: theme.palette.text.primary,
  },
  overallChartTitle: {
    color: theme.palette.text.primary,
    marginLeft: "76px",
  },
  portraitOverallChartTitle: {
    color: theme.palette.text.primary,
    marginLeft: "172px",
  },
  overallChartDiv: { width: "312px", height: "230px", marginTop: "-6px" },
  portraitOverallChartDiv: {
    width: "312px",
    height: "230px",
    marginTop: "-6px",
  },
  chart: { width: "300px", height: "230px" },
}));

export const MetricComparisonPane = (props) => {
  const classes = useStyles();
  const { results, height } = props;
  const { usingDarkMode } = useThemeContext();
  const { portraitMode } = useLayoutContext();
  const [metricData, setMetricData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [metric1Name, setMetric1Name] = useState(undefined);
  const [metric1ChartId, setMetric1ChartId] = useState("c3-metric1-chart");
  const [metric1Chart, setMetric1Chart] = useState(undefined);
  const [metric2Name, setMetric2Name] = useState(undefined);
  const [metric2ChartId, setMetric2ChartId] = useState("c3-metric2-chart");
  const [metric2Chart, setMetric2Chart] = useState(undefined);
  const [metric3Name, setMetric3Name] = useState(undefined);
  const [metric3ChartId, setMetric3ChartId] = useState("c3-metric3-chart");
  const [metric3Chart, setMetric3Chart] = useState(undefined);
  // const [summaryChartId, setSummaryChartId] = useState("c3-summary-chart");
  // const [summaryChart, setSummaryChart] = useState(undefined);

  useEffect(() => {
    parseMetricData();
  }, []);

  useEffect(() => {
    parseMetricData();
  }, [results, usingDarkMode]);

  const parseMetricData = async () => {
    if (results === null || results === undefined || results.length === 0) {
      return;
    }

    let metric1Columns = [];
    let metric2Columns = [];
    let metric3Columns = [];
    // let summaryColumns = [];
    console.log(results);

    for (let i = 0; i < results.length; i++) {
      let result = results[i];
      // if (!schedule.metrics) {
      //   let metrics = await Api.getMetricsForSchedule(schedule);
      //   if (metrics === null) {
      //     continue;
      //   }
      // }

      let mean = 0;
      metric1Columns.push([
        result.schedule.name,
        result.objectives[ObjectiveNames[0]],
      ]);
      metric2Columns.push([
        result.schedule.name,
        result.objectives[ObjectiveNames[1]],
      ]);
      metric3Columns.push([
        result.schedule.name,
        result.objectives[ObjectiveNames[2]],
      ]);

      if (metric1Name === undefined) {
        setMetric1Name(ObjectiveNames[0]);
      }
      if (metric2Name === undefined) {
        setMetric2Name(ObjectiveNames[1]);
      }
      if (metric3Name === undefined) {
        setMetric3Name(ObjectiveNames[2]);
      }

      // mean += schedule.metrics[0].value;
      // mean += schedule.metrics[1].value;
      // mean += schedule.metrics[2].value;
      // mean = mean / 3;

      // summaryColumns.push([schedule.name, mean]);
    }

    let metric1Chart = c3.generate({
      bindto: `#${metric1ChartId}`,
      data: {
        columns: metric1Columns,
        type: "pie",
      },
      legend: {
        position: "right",
      },
      pie: {
        label: {
          format: function (value, ratio, id) {
            let position = 1;
            for (let i = 0; i < metric1Columns.length; i++) {
              if (metric1Columns[i][1] > value) {
                position++;
              }
            }

            return position;
          },
        },
      },
    });

    let metric2Chart = c3.generate({
      bindto: `#${metric2ChartId}`,
      data: {
        columns: metric2Columns,
        type: "pie",
      },
      legend: {
        position: "right",
      },
      pie: {
        label: {
          format: function (value, ratio, id) {
            let position = 1;
            for (let i = 0; i < metric2Columns.length; i++) {
              if (metric2Columns[i][1] > value) {
                position++;
              }
            }

            return position;
          },
        },
      },
    });

    let metric3Chart = c3.generate({
      bindto: `#${metric3ChartId}`,
      data: {
        columns: metric3Columns,
        type: "pie",
      },
      legend: {
        position: "right",
      },
      pie: {
        label: {
          format: function (value, ratio, id) {
            let position = 1;
            for (let i = 0; i < metric3Columns.length; i++) {
              if (metric3Columns[i][1] > value) {
                position++;
              }
            }

            return position;
          },
        },
      },
    });

    // let summaryChart = c3.generate({
    //   bindto: `#${summaryChartId}`,
    //   data: {
    //     columns: summaryColumns,
    //     type: "pie",
    //   },
    //   legend: {
    //     position: "right",
    //   },
    // });

    setMetric1Chart(metric1Chart);
    setMetric2Chart(metric2Chart);
    setMetric3Chart(metric3Chart);
    // setSummaryChart(summaryChart);
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      spacing={portraitMode ? 2 : 4}
    >
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.chartTitle}>
          {metric1Name}
        </Typography>
        <div
          id={metric1ChartId}
          className={clsx(usingDarkMode && "c3-dark-mode", classes.chart)}
        />
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.chartTitle}>
          {metric2Name}
        </Typography>
        <div
          id={metric2ChartId}
          className={clsx(usingDarkMode && "c3-dark-mode", classes.chart)}
        />
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.chartTitle}>
          {metric3Name}
        </Typography>
        <div
          id={metric3ChartId}
          className={clsx(usingDarkMode && "c3-dark-mode", classes.chart)}
        />
      </Grid>
      {/* <Grid item style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.chartTitle}>
          Overall
        </Typography>
        <div id={summaryChartId} style={{ width: "300px", height: "230px" }} />
      </Grid> */}
    </Grid>
  );
};

export default MetricComparisonPane;
