import React, { useState, useEffect } from "react";

import moment, { duration } from "moment";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import AccessTime from "@material-ui/icons/AccessTime";

import Api from "../services/Api";

import { useLayoutContext } from "../components/Layout";
import ScheduleAnalysisCharts from "../components/ScheduleAnalysisCharts";

import BackArrow from "../components/BackArrow";
import OuterDiv from "../components/OuterDiv";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { LogoButton } from "../components/Buttons";

import {
  ApsMsdDisplay,
  ApsDisplay,
  AphMsdDisplay,
  AphDisplay,
  Apm15Display,
  Apm30Display,
  Apm60Display,
  PphDisplay,
  AcpMsdDisplay,
  Acps1Display,
  Acps2Display,
  Acps2pDisplay,
  Acps3Display,
  Acps4Display,
  Acps5Display,
  AssignmentListDisplay,
} from "../components/AnalysisDisplays";
import Schedule from "../components/Schedule";
import { currentSchedule } from "../services/DemoEngine";

const useStyles = makeStyles((theme) => ({
  scheduleName: {
    fontWeight: "600",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  closeAnalyzer: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  displayAll: {
    fontWeight: "600",
    // fontSize: "1.5em"
  },
  descriptionText: {
    marginTop: "16px",
    color: theme.palette.text.primary,
    fontSize: "1.5em",
  },
  datasetSelector: {
    fontSize: "1.25rem",
    fontWeight: "600",
    width: "320px",
    "& .MuiInputBase-root": { height: "61px" },
  },
}));

const useAutocompleteLargeStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "69px",
  },
}));

const useAutocompleteSmallStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "600",
    textRendering: "geometricPrecision",
    height: "61px",
  },
}));

export const SimAnalyzerLink = (props) => {
  let { schedule } = props.location.state;

  return <SimAnalyzer schedule={schedule} close={false} {...props} />;
};

export const SimAnalyzer = (props) => {
  const { schedule, close } = props;
  const classes = useStyles();
  const autocompleteSmallClasses = useAutocompleteSmallStyles();
  const autocompleteLargeClasses = useAutocompleteLargeStyles();
  const { setCurrentView, portraitMode } = useLayoutContext();
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState({});
  const [datasets, setDatasets] = useState([]);
  const [simData, setsimData] = useState(null);
  const today = moment().hour(0).minute(0).second(0);
  const lastMonth = moment(today).subtract("month", 1);
  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(today);
  const [useDate, setUseDate] = useState(false);
  const [readyToAnalyze, setReadyToAnalyze] = useState(false);

  useEffect(() => {
    const call = async () => {
      updateDatasets();
    };
    setCurrentView("Simulation");
    call();
  }, []);

  const updateDatasets = async () => {
    let awaitDatasets = Api.getDatasets();
    awaitDatasets.then((datasets) => {
      if (datasets === null) {
        setDatasets([]);
        setDataset({});
      } else {
        setDatasets(datasets);
        if (
          (dataset === null || datasets.indexOf(dataset) === -1) &&
          datasets.length > 0
        ) {
          setDataset(datasets[0]);
        }
      }
    });
  };

  const onRunSimulationClick = async () => {
    await runSimulation();
    setReadyToAnalyze(true);
  };

  //getAnalysesDisplay, selectAllAnalyses and removeProposedOptions were moved to ScheduleAnalysis Charts

  const runSimulation = async () => {
    let awaitCurrentData;
    setLoading(true);
    if (useDate) {
      const startString = moment(startDate).format();
      const endString = moment(endDate).format();
      awaitCurrentData = Api.getStatisticsWithDateRange(
        schedule.id,
        startString,
        endString
      );
    } else {
      awaitCurrentData = Api.simulateScheduleWithDataset(
        schedule.id,
        dataset.id
      );
    }

    await awaitCurrentData.then((currentData) => {
      console.log(currentData);
      setsimData(currentData);
      setLoading(false);
    });
  };

  return (
    <>
      <OuterDiv>
        <Header>Run Simulation</Header>
        <Grid
          container
          justify="center"
          alignContent="center"
          direction="column"
          style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}
        >
          <Grid
            item
            style={portraitMode ? { width: "665px" } : { width: "1265px" }}
          >
            <Typography variant="h5" className={classes.scheduleName}>
              {schedule.name}
            </Typography>
            <Schedule schedule={schedule} dense={portraitMode}></Schedule>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          style={{ textAlign: "center" }}
          spacing={1}
        >
          {useDate && (
            <Grid item>
              <div
                className={clsx(
                  portraitMode && classes.controlLabelSmall,
                  !portraitMode && classes.controlLabelLarge
                )}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Set Date Range:
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item>
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={portraitMode ? 2 : 4}
            >
              {useDate && (
                <>
                  {/* <Grid container
              justify="center"
              alignContent="center"
              direction="column"> */}
                  <Grid item>
                    <Typography variant="h5" className={classes.datePicker}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          clearable
                          value={startDate}
                          onChange={setStartDate}
                          ampm={false}
                          format="HH:mm:ss MM-DD-YY"
                          label={"Start Date"}
                          aria-label="start date"
                          InputProps={{
                            style: portraitMode
                              ? {
                                  fontSize: "1.25em",
                                  fontWeight: "600",
                                  height: "61px",
                                }
                              : { fontSize: ".75em", fontWeight: "600" },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "-8px" }}
                              >
                                <IconButton>
                                  <AccessTime />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={
                            portraitMode
                              ? {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "3px",
                                  },
                                }
                              : {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "6px",
                                  },
                                }
                          }
                          style={
                            portraitMode
                              ? {
                                  fontSize: "1.25rem",
                                  width: "295px",
                                  height: "61px",
                                }
                              : { fontSize: "1.5em", width: "325px" }
                          }
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className={classes.datePicker}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          clearable
                          value={endDate}
                          onChange={setEndDate}
                          ampm={false}
                          format="HH:mm:ss MM-DD-YY"
                          label={"End Date"}
                          aria-label="start date"
                          InputProps={{
                            style: portraitMode
                              ? {
                                  fontSize: "1.25em",
                                  fontWeight: "600",
                                  height: "61px",
                                }
                              : { fontSize: ".75em", fontWeight: "600" },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "-8px" }}
                              >
                                <IconButton>
                                  <AccessTime />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={
                            portraitMode
                              ? {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "3px",
                                  },
                                }
                              : {
                                  style: {
                                    fontWeight: "600",
                                    fontSize: "large",
                                    marginTop: "6px",
                                  },
                                }
                          }
                          style={
                            portraitMode
                              ? {
                                  fontSize: "1.25rem",
                                  width: "295px",
                                  height: "61px",
                                }
                              : { fontSize: "1.5em", width: "325px" }
                          }
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Typography>
                  </Grid>
                  {/* </Grid> */}
                  {/* </Grid> */}
                </>
              )}
              {!useDate && (
                <Grid item>
                  <div
                    className={
                      portraitMode
                        ? classes.controlLabelSmall
                        : classes.controlLabelLarge
                    }
                  >
                    Choose Data Set
                  </div>
                  <Autocomplete
                    id="dataset-combo-box"
                    options={datasets}
                    getOptionLabel={(option) => option.name}
                    classes={
                      portraitMode
                        ? autocompleteSmallClasses
                        : autocompleteLargeClasses
                    }
                    value={dataset}
                    selectOnFocus
                    handleHomeEndKeys
                    clearOnBlur
                    autoHighlight
                    disableClearable
                    autoSelect
                    onChange={(event, newValue) => {
                      setDataset(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputLabelProps={{
                          style: { fontWeight: "600", fontSize: "1.25rem" },
                        }}
                      />
                    )}
                    aria-label="select dataset"
                    className={classes.datasetSelector}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {!loading && (
              <div>
                <LogoButton onClick={onRunSimulationClick}>
                  Run Simulation
                </LogoButton>
              </div>
            )}
            {loading && (
              <div>
                <CircularProgress size={80} />
              </div>
            )}
          </Grid>
        </Grid>
        {readyToAnalyze && (
          <OuterDiv>
            <ScheduleAnalysisCharts
              currentData={simData.assignmentStatisticsInfo} //TODO stuff here
              closeAnalyzer={close}
              proposedData={null}
            />
          </OuterDiv>
        )}
      </OuterDiv>
    </>
  );
};

export default SimAnalyzer;
