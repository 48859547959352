export const apm15data = [
  {
    name: "A1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.694,
      0.405,
      0.802,
      0.532,
      0.559,
      0.432,
      0.18,
      0.207,
      0.126,
      0.45,
      0.387,
      0.45,
      0.532,
      0.486,
      0.279,
      0.333,
      0.153,
      0.577,
      0.586,
      0.559,
      0.261,
      0.423,
      0.063,
      0.288,
      0.333,
      0.45,
      0.45,
      0.387,
      0.324,
      0.396,
      0.36,
      0.288,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "A2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.757,
      0.306,
      1.279,
      0.577,
      0.315,
      0.396,
      0.072,
      0.351,
      0.288,
      0.468,
      0.459,
      0.477,
      0.423,
      0.405,
      0.369,
      0.396,
      0.162,
      0.378,
      0.369,
      0.459,
      0.315,
      0.441,
      0.162,
      0.378,
      0.342,
      0.486,
      0.523,
      0.505,
      0.459,
      0.495,
      0.405,
      0.441,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "A3",
    values: [
      0.658,
      0.468,
      0.486,
      0.495,
      0.586,
      0.297,
      0.712,
      0.559,
      0.568,
      0.55,
      0.486,
      0.477,
      0.459,
      0.477,
      0.387,
      0.441,
      0.297,
      0.396,
      0.306,
      0.171,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.874,
      0.378,
      1.423,
      0.622,
      0.324,
      0.468,
      0.153,
      0.378,
      0.432,
      0.523,
      0.441,
      0.36,
    ],
  },
  {
    name: "B1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.387,
      0.351,
      0.766,
      0.486,
      0.477,
      0.658,
      0.559,
      0.595,
      0.432,
      0.432,
      0.288,
      0.351,
      0.216,
      0.486,
      0.622,
      0.568,
      0.225,
      0.459,
      0.126,
      0.27,
      0.324,
      0.405,
      0.414,
      0.369,
      0.396,
      0.342,
      0.432,
      0.45,
      0.171,
      0.333,
      0.054,
      0.27,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "B2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.721,
      0.36,
      1.351,
      0.64,
      0.315,
      0.405,
      0.477,
      0.559,
      0.36,
      0.468,
      0.27,
      0.532,
      0.081,
      0.486,
      0.324,
      0.378,
      0.279,
      0.459,
      0.162,
      0.324,
      0.441,
      0.441,
      0.523,
      0.568,
      0.459,
      0.468,
      0.468,
      0.414,
      0.324,
      0.414,
      0.099,
      0.387,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "B3",
    values: [
      0.595,
      0.559,
      0.387,
      0.532,
      0.568,
      0.378,
      0.604,
      0.595,
      0.658,
      0.486,
      0.523,
      0.45,
      0.423,
      0.459,
      0.423,
      0.387,
      0.414,
      0.387,
      0.306,
      0.162,
      0.36,
      0.225,
      0.045,
      0.063,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.874,
      0.378,
      1.432,
      0.586,
      0.523,
      0.468,
      0.414,
      0.396,
    ],
  },
  {
    name: "C1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.703,
      0.378,
      1.378,
      1.027,
      0.541,
      0.568,
      0.342,
      0.387,
      0.072,
      0.261,
      0.351,
      0.405,
      0.423,
      0.414,
      0.396,
      0.288,
      0.441,
      0.324,
      0.27,
      0.351,
      0.081,
      0.333,
      0.261,
      0.306,
      0.09,
      0.36,
      0.297,
      0.523,
      0.459,
      0.369,
      0.441,
      0.423,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "C2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.892,
      0.279,
      1.541,
      0.559,
      0.351,
      0.414,
      0.252,
      0.486,
      0.171,
      0.342,
      0.297,
      0.532,
      0.486,
      0.532,
      0.505,
      0.486,
      0.378,
      0.468,
      0.369,
      0.45,
      0.081,
      0.342,
      0.459,
      0.396,
      0.126,
      0.441,
      0.495,
      0.405,
      0.468,
      0.387,
    ],
  },
  {
    name: "D1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.802,
      0.351,
      1.153,
      0.676,
      0.351,
      0.505,
      0.36,
      0.369,
      0.378,
      0.45,
      0.315,
      0.423,
      0.216,
      0.351,
      0.054,
      0.27,
      0.234,
      0.36,
      0.072,
      0.45,
      0.297,
      0.414,
      0.514,
      0.45,
      0.423,
      0.396,
      0.36,
      0.414,
      0.153,
      0.441,
      0.324,
      0.387,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "D2",
    values: [
      0.676,
      0.577,
      0.387,
      0.559,
      0.532,
      0.342,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.874,
      0.27,
      1.351,
      0.64,
      0.477,
      0.414,
      0.586,
      0.414,
      0.586,
      0.387,
      0.477,
      0.405,
      0.315,
      0.505,
      0.108,
      0.378,
      0.351,
      0.505,
      0.072,
      0.396,
      0.486,
      0.486,
      0.432,
      0.36,
    ],
  },
];

export const apm30data = [
  {
    name: "A1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.099,
      1.333,
      0.991,
      0.387,
      0.577,
      0.838,
      1.018,
      0.613,
      0.73,
      1.144,
      0.685,
      0.351,
      0.784,
      0.838,
      0.721,
      0.649,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "A2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.063,
      1.856,
      0.712,
      0.423,
      0.757,
      0.937,
      0.829,
      0.766,
      0.541,
      0.829,
      0.757,
      0.541,
      0.829,
      1.027,
      0.955,
      0.847,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "A3",
    values: [
      1.126,
      0.982,
      0.883,
      1.27,
      1.117,
      0.964,
      0.937,
      0.829,
      0.694,
      0.477,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.252,
      2.045,
      0.793,
      0.532,
      0.955,
      0.802,
    ],
  },
  {
    name: "B1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0.739,
      1.252,
      1.135,
      1.153,
      0.865,
      0.64,
      0.703,
      1.189,
      0.685,
      0.396,
      0.73,
      0.784,
      0.739,
      0.883,
      0.505,
      0.324,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "B2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.081,
      1.991,
      0.721,
      1.036,
      0.829,
      0.802,
      0.568,
      0.703,
      0.739,
      0.486,
      0.883,
      1.09,
      0.928,
      0.883,
      0.739,
      0.486,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "B3",
    values: [
      1.153,
      0.919,
      0.946,
      1.198,
      1.144,
      0.973,
      0.883,
      0.811,
      0.802,
      0.468,
      0.586,
      0.108,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.252,
      2.018,
      0.991,
      0.811,
    ],
  },
  {
    name: "C1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.081,
      2.405,
      1.108,
      0.73,
      0.333,
      0.757,
      0.838,
      0.685,
      0.766,
      0.622,
      0.414,
      0.568,
      0.45,
      0.82,
      0.829,
      0.865,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "C2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.171,
      2.099,
      0.766,
      0.739,
      0.514,
      0.829,
      1.018,
      0.991,
      0.847,
      0.82,
      0.423,
      0.856,
      0.568,
      0.901,
      0.856,
    ],
  },
  {
    name: "D1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.153,
      1.829,
      0.856,
      0.73,
      0.829,
      0.739,
      0.568,
      0.324,
      0.595,
      0.523,
      0.712,
      0.964,
      0.82,
      0.775,
      0.595,
      0.712,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "D2",
    values: [
      1.252,
      0.946,
      0.874,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.144,
      1.991,
      0.892,
      1,
      0.973,
      0.883,
      0.82,
      0.486,
      0.856,
      0.468,
      0.973,
      0.793,
    ],
  },
];

export const apm60data = [
  {
    name: "A1",
    values: [
      0,
      0,
      0,
      0,
      0,
      2.432,
      1.378,
      1.414,
      1.631,
      1.874,
      1.036,
      1.622,
      1.369,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "A2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      2.919,
      1.135,
      1.694,
      1.595,
      1.369,
      1.297,
      1.856,
      1.802,
      0,
      0,
      0,
    ],
  },
  {
    name: "A3",
    values: [
      2.108,
      2.153,
      2.081,
      1.766,
      1.171,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3.297,
      1.324,
      1.757,
    ],
  },
  {
    name: "B1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      1.991,
      2.288,
      1.505,
      1.892,
      1.081,
      1.514,
      1.622,
      0.829,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "B2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3.072,
      1.757,
      1.631,
      1.27,
      1.225,
      1.973,
      1.811,
      1.225,
      0,
      0,
    ],
  },
  {
    name: "B3",
    values: [
      2.072,
      2.144,
      2.117,
      1.694,
      1.27,
      0.694,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3.27,
      1.802,
    ],
  },
  {
    name: "C1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.081,
      3.514,
      1.063,
      1.595,
      1.45,
      1.036,
      1.018,
      1.649,
      0.865,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "C2",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1.171,
      2.865,
      1.252,
      1.847,
      1.838,
      1.243,
      1.423,
      1.757,
    ],
  },
  {
    name: "D1",
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      2.982,
      1.586,
      1.568,
      0.892,
      1.117,
      1.676,
      1.595,
      1.306,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
  {
    name: "D2",
    values: [
      2.198,
      0.874,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3.135,
      1.892,
      1.856,
      1.306,
      1.324,
      1.766,
    ],
  },
];

export const pphdata = [
  {
    name: "A1",
    values: [2.432, 1.378, 1.414, 1.631, 1.874, 1.036, 1.622, 1.369],
  },
  {
    name: "B1",
    values: [1.991, 2.288, 1.505, 1.892, 1.081, 1.514, 1.622, 0.829],
  },
  {
    name: "C1",
    values: [3.486, 1.838, 1.09, 1.523, 1.387, 0.982, 1.27, 1.694],
  },
  {
    name: "D1",
    values: [2.982, 1.586, 1.568, 0.892, 1.117, 1.676, 1.595, 1.306],
  },
  {
    name: "A2",
    values: [2.919, 1.135, 1.694, 1.595, 1.369, 1.297, 1.856, 1.802],
  },
  {
    name: "B2",
    values: [3.072, 1.757, 1.631, 1.27, 1.225, 1.973, 1.811, 1.225],
  },
  {
    name: "C2",
    values: [3.27, 1.505, 1.342, 2.009, 1.667, 1.279, 1.468, 0.856],
  },
  {
    name: "D2",
    values: [3.135, 1.892, 1.856, 1.306, 1.324, 1.766, 2.198, 0.874],
  },
  {
    name: "A3",
    values: [3.297, 1.324, 1.757, 2.108, 2.153, 2.081, 1.766, 1.171],
  },
  { name: "B3", values: [3.27, 1.802, 2.072, 2.144, 2.117, 1.694, 1.27, 0.69] },
];

export const assignmentdata = [
  { date: "5/16/2019 05:26:51", shift: "A1", acuity: 3 },
  { date: "5/16/2019 05:36:45", shift: "A1", acuity: 3 },
  { date: "5/16/2019 05:53:48", shift: "A1", acuity: 3 },
  { date: "5/16/2019 06:02:42", shift: "B1", acuity: 5 },
  { date: "5/16/2019 06:04:02", shift: "A1", acuity: 3 },
  { date: "5/16/2019 06:05:42", shift: "B1", acuity: 3 },
  { date: "5/16/2019 06:08:58", shift: "A1", acuity: 2 },
  { date: "5/16/2019 06:33:45", shift: "B1", acuity: 3 },
  { date: "5/16/2019 06:34:01", shift: "B1", acuity: 3 },
  { date: "5/16/2019 07:00:41", shift: "A1", acuity: 3 },
  { date: "5/16/2019 07:14:50", shift: "B1", acuity: 4 },
  { date: "5/16/2019 07:26:02", shift: "A1", acuity: 3 },
  { date: "5/16/2019 07:45:07", shift: "B1", acuity: 2 },
  { date: "5/16/2019 07:50:49", shift: "A1", acuity: 2 },
  { date: "5/16/2019 08:04:04", shift: "B1", acuity: 4 },
  { date: "5/16/2019 08:04:26", shift: "A1", acuity: 3 },
  { date: "5/16/2019 08:24:34", shift: "B1", acuity: 3 },
  { date: "5/16/2019 08:24:49", shift: "A1", acuity: 3 },
  { date: "5/16/2019 09:03:32", shift: "C1", acuity: 3 },
  { date: "5/16/2019 09:16:40", shift: "C1", acuity: 3 },
  { date: "5/16/2019 09:16:55", shift: "C1", acuity: 3 },
  { date: "5/16/2019 09:28:15", shift: "B1", acuity: 2 },
  { date: "5/16/2019 09:34:55", shift: "A1", acuity: 3 },
  { date: "5/16/2019 09:52:39", shift: "C1", acuity: 2 },
  { date: "5/16/2019 09:52:53", shift: "B1", acuity: 2 },
  { date: "5/16/2019 09:53:03", shift: "A1", acuity: 2 },
  { date: "5/16/2019 10:14:38", shift: "D1", acuity: 2 },
  { date: "5/16/2019 10:14:51", shift: "C1", acuity: 2 },
  { date: "5/16/2019 10:15:11", shift: "B1", acuity: 3 },
  { date: "5/16/2019 10:25:33", shift: "A1", acuity: 3 },
  { date: "5/16/2019 10:36:03", shift: "D1", acuity: 3 },
  { date: "5/16/2019 11:21:26", shift: "D1", acuity: 3 },
  { date: "5/16/2019 11:27:13", shift: "C1", acuity: 2 },
  { date: "5/16/2019 11:35:16", shift: "B1", acuity: 2 },
  { date: "5/16/2019 11:41:13", shift: "A1", acuity: 3 },
  { date: "5/16/2019 12:00:46", shift: "D1", acuity: 2 },
  { date: "5/16/2019 12:04:12", shift: "C1", acuity: 3 },
  { date: "5/16/2019 12:07:41", shift: "B1", acuity: 2 },
  { date: "5/16/2019 12:22:46", shift: "A1", acuity: 3 },
  { date: "5/16/2019 12:26:43", shift: "D1", acuity: 2 },
  { date: "5/16/2019 12:30:11", shift: "C1", acuity: 2 },
  { date: "5/16/2019 12:37:00", shift: "B1", acuity: 2 },
  { date: "5/16/2019 12:51:17", shift: "A1", acuity: 3 },
  { date: "5/16/2019 12:51:36", shift: "D1", acuity: 3 },
  { date: "5/16/2019 12:59:22", shift: "C1", acuity: 3 },
  { date: "5/16/2019 12:59:38", shift: "B1", acuity: 3 },
  { date: "5/16/2019 13:11:47", shift: "A2", acuity: 2 },
  { date: "5/16/2019 13:34:28", shift: "A2", acuity: 2 },
  { date: "5/16/2019 13:35:02", shift: "A2", acuity: 3 },
  { date: "5/16/2019 14:13:28", shift: "B2", acuity: 3 },
  { date: "5/16/2019 14:29:16", shift: "D1", acuity: 3 },
  { date: "5/16/2019 14:38:42", shift: "B2", acuity: 2 },
  { date: "5/16/2019 14:58:58", shift: "B2", acuity: 3 },
  { date: "5/16/2019 15:15:04", shift: "C1", acuity: 3 },
  { date: "5/16/2019 15:16:46", shift: "A2", acuity: 2 },
  { date: "5/16/2019 15:24:22", shift: "D1", acuity: 3 },
  { date: "5/16/2019 15:34:48", shift: "B2", acuity: 3 },
  { date: "5/16/2019 15:44:19", shift: "C1", acuity: 2 },
  { date: "5/16/2019 15:53:12", shift: "A2", acuity: 3 },
  { date: "5/16/2019 16:10:28", shift: "D1", acuity: 3 },
  { date: "5/16/2019 16:11:06", shift: "B2", acuity: 3 },
  { date: "5/16/2019 16:11:40", shift: "C1", acuity: 3 },
  { date: "5/16/2019 16:11:59", shift: "A2", acuity: 2 },
  { date: "5/16/2019 16:16:24", shift: "D1", acuity: 3 },
  { date: "5/16/2019 16:30:54", shift: "C2", acuity: 3 },
  { date: "5/16/2019 16:31:15", shift: "B2", acuity: 3 },
  { date: "5/16/2019 16:39:06", shift: "A2", acuity: 2 },
  { date: "5/16/2019 16:51:09", shift: "D1", acuity: 2 },
  { date: "5/16/2019 17:06:13", shift: "C2", acuity: 3 },
  { date: "5/16/2019 17:06:27", shift: "C2", acuity: 2 },
  { date: "5/16/2019 17:26:27", shift: "B2", acuity: 2 },
  { date: "5/16/2019 17:35:10", shift: "A2", acuity: 3 },
  { date: "5/16/2019 17:38:46", shift: "D1", acuity: 3 },
  { date: "5/16/2019 17:56:47", shift: "C2", acuity: 2 },
  { date: "5/16/2019 17:57:02", shift: "B2", acuity: 2 },
  { date: "5/16/2019 18:13:09", shift: "D2", acuity: 3 },
  { date: "5/16/2019 18:13:27", shift: "A2", acuity: 2 },
  { date: "5/16/2019 18:21:55", shift: "C2", acuity: 2 },
  { date: "5/16/2019 18:35:40", shift: "D2", acuity: 2 },
  { date: "5/16/2019 18:43:51", shift: "D2", acuity: 3 },
  { date: "5/16/2019 18:44:05", shift: "B2", acuity: 3 },
  { date: "5/16/2019 19:21:27", shift: "A2", acuity: 3 },
  { date: "5/16/2019 19:22:56", shift: "C2", acuity: 3 },
  { date: "5/16/2019 19:25:50", shift: "D2", acuity: 3 },
  { date: "5/16/2019 19:28:29", shift: "B2", acuity: 3 },
  { date: "5/16/2019 19:51:27", shift: "A2", acuity: 3 },
  { date: "5/16/2019 20:06:37", shift: "C2", acuity: 3 },
  { date: "5/16/2019 20:06:52", shift: "D2", acuity: 3 },
  { date: "5/16/2019 20:08:25", shift: "B2", acuity: 3 },
  { date: "5/16/2019 20:13:14", shift: "A2", acuity: 2 },
  { date: "5/16/2019 20:21:08", shift: "C2", acuity: 3 },
  { date: "5/16/2019 20:28:07", shift: "D2", acuity: 3 },
  { date: "5/16/2019 20:34:08", shift: "B2", acuity: 3 },
  { date: "5/16/2019 20:37:21", shift: "A2", acuity: 3 },
  { date: "5/16/2019 20:52:27", shift: "C2", acuity: 3 },
  { date: "5/16/2019 20:55:58", shift: "D2", acuity: 3 },
  { date: "5/16/2019 21:04:18", shift: "A3", acuity: 2 },
  { date: "5/16/2019 21:10:20", shift: "B2", acuity: 2 },
  { date: "5/16/2019 21:13:39", shift: "C2", acuity: 3 },
  { date: "5/16/2019 21:34:07", shift: "A3", acuity: 3 },
  { date: "5/16/2019 21:34:15", shift: "A3", acuity: 3 },
  { date: "5/16/2019 21:46:49", shift: "D2", acuity: 2 },
  { date: "5/16/2019 22:02:11", shift: "B3", acuity: 3 },
  { date: "5/16/2019 22:14:26", shift: "C2", acuity: 3 },
  { date: "5/16/2019 22:14:46", shift: "A3", acuity: 4 },
  { date: "5/16/2019 22:24:41", shift: "D2", acuity: 2 },
  { date: "5/16/2019 22:24:54", shift: "B3", acuity: 3 },
  { date: "5/16/2019 22:25:07", shift: "C2", acuity: 3 },
  { date: "5/16/2019 22:34:35", shift: "B3", acuity: 3 },
  { date: "5/16/2019 22:46:42", shift: "B3", acuity: 3 },
  { date: "5/16/2019 22:49:22", shift: "A3", acuity: 3 },
  { date: "5/16/2019 22:59:04", shift: "D2", acuity: 2 },
  { date: "5/16/2019 23:11:02", shift: "C2", acuity: 3 },
  { date: "5/16/2019 23:16:39", shift: "B3", acuity: 3 },
  { date: "5/16/2019 23:23:18", shift: "A3", acuity: 4 },
  { date: "5/16/2019 23:32:15", shift: "D2", acuity: 4 },
  { date: "5/16/2019 23:32:27", shift: "C2", acuity: 3 },
  { date: "5/16/2019 23:53:35", shift: "B3", acuity: 2 },
  { date: "5/17/2019 00:03:32", shift: "A3", acuity: 3 },
  { date: "5/17/2019 00:13:27", shift: "D2", acuity: 4 },
  { date: "5/17/2019 00:52:52", shift: "B3", acuity: 3 },
  { date: "5/17/2019 01:01:26", shift: "A3", acuity: 3 },
  { date: "5/17/2019 01:40:21", shift: "B3", acuity: 4 },
  { date: "5/17/2019 01:51:41", shift: "A3", acuity: 4 },
  { date: "5/17/2019 01:55:30", shift: "B3", acuity: 3 },
  { date: "5/17/2019 02:14:55", shift: "A3", acuity: 2 },
  { date: "5/17/2019 02:17:49", shift: "B3", acuity: 5 },
  { date: "5/17/2019 02:30:14", shift: "A3", acuity: 2 },
  { date: "5/17/2019 02:42:53", shift: "B3", acuity: 2 },
  { date: "5/17/2019 02:45:06", shift: "A3", acuity: 3 },
  { date: "5/17/2019 02:55:50", shift: "B3", acuity: 3 },
  { date: "5/17/2019 03:13:16", shift: "A3", acuity: 3 },
  { date: "5/17/2019 03:23:05", shift: "B3", acuity: 4 },
  { date: "5/17/2019 03:23:25", shift: "A3", acuity: 3 },
  { date: "5/17/2019 04:07:59", shift: "B3", acuity: 3 },
  { date: "5/17/2019 04:26:59", shift: "A3", acuity: 3 },
  { date: "5/17/2019 06:11:16", shift: "A1", acuity: 2 },
  { date: "5/17/2019 06:15:44", shift: "A1", acuity: 3 },
  { date: "5/17/2019 06:30:26", shift: "A1", acuity: 4 },
  { date: "5/17/2019 06:39:25", shift: "B1", acuity: 2 },
  { date: "5/17/2019 07:31:28", shift: "B1", acuity: 4 },
  { date: "5/17/2019 07:35:12", shift: "B1", acuity: 3 },
  { date: "5/17/2019 07:37:36", shift: "A1", acuity: 3 },
  { date: "5/17/2019 07:56:31", shift: "B1", acuity: 3 },
  { date: "5/17/2019 08:14:07", shift: "A1", acuity: 2 },
  { date: "5/17/2019 08:31:31", shift: "C1", acuity: 3 },
  { date: "5/17/2019 08:37:39", shift: "B1", acuity: 3 },
  { date: "5/17/2019 08:37:59", shift: "A1", acuity: 2 },
  { date: "5/17/2019 08:48:32", shift: "C1", acuity: 4 },
  { date: "5/17/2019 08:59:19", shift: "B1", acuity: 4 },
  { date: "5/17/2019 09:12:06", shift: "C1", acuity: 3 },
  { date: "5/17/2019 09:14:13", shift: "C1", acuity: 3 },
  { date: "5/17/2019 09:17:17", shift: "A1", acuity: 2 },
  { date: "5/17/2019 09:29:35", shift: "B1", acuity: 3 },
  { date: "5/17/2019 09:38:46", shift: "C1", acuity: 2 },
  { date: "5/17/2019 09:42:23", shift: "A1", acuity: 3 },
  { date: "5/17/2019 09:52:55", shift: "B1", acuity: 2 },
  { date: "5/17/2019 09:53:15", shift: "C1", acuity: 2 },
  { date: "5/17/2019 09:56:55", shift: "A1", acuity: 3 },
  { date: "5/17/2019 10:03:47", shift: "D1", acuity: 3 },
  { date: "5/17/2019 10:05:34", shift: "B1", acuity: 2 },
  { date: "5/17/2019 10:15:03", shift: "C1", acuity: 3 },
  { date: "5/17/2019 10:21:55", shift: "A1", acuity: 2 },
  { date: "5/17/2019 10:22:53", shift: "D1", acuity: 3 },
  { date: "5/17/2019 10:39:42", shift: "D1", acuity: 3 },
  { date: "5/17/2019 10:41:43", shift: "D1", acuity: 3 },
  { date: "5/17/2019 11:11:41", shift: "B1", acuity: 2 },
  { date: "5/17/2019 11:19:06", shift: "C1", acuity: 2 },
  { date: "5/17/2019 11:19:22", shift: "A1", acuity: 3 },
  { date: "5/17/2019 11:24:18", shift: "D1", acuity: 2 },
  { date: "5/17/2019 11:50:32", shift: "B1", acuity: 3 },
  { date: "5/17/2019 12:13:07", shift: "C1", acuity: 2 },
  { date: "5/17/2019 12:25:51", shift: "A1", acuity: 3 },
  { date: "5/17/2019 12:36:25", shift: "D1", acuity: 3 },
  { date: "5/17/2019 12:36:45", shift: "B1", acuity: 3 },
  { date: "5/17/2019 13:03:32", shift: "A2", acuity: 2 },
  { date: "5/17/2019 13:18:29", shift: "C1", acuity: 2 },
  { date: "5/17/2019 13:30:57", shift: "A2", acuity: 3 },
  { date: "5/17/2019 14:02:27", shift: "A2", acuity: 2 },
  { date: "5/17/2019 14:08:08", shift: "B2", acuity: 4 },
  { date: "5/17/2019 14:08:54", shift: "D1", acuity: 3 },
  { date: "5/17/2019 14:09:18", shift: "C1", acuity: 2 },
  { date: "5/17/2019 14:28:06", shift: "A2", acuity: 3 },
  { date: "5/17/2019 14:32:35", shift: "B2", acuity: 3 },
  { date: "5/17/2019 14:32:57", shift: "B2", acuity: 3 },
  { date: "5/17/2019 14:45:20", shift: "D1", acuity: 3 },
  { date: "5/17/2019 15:11:35", shift: "C1", acuity: 3 },
  { date: "5/17/2019 15:35:17", shift: "A2", acuity: 2 },
  { date: "5/17/2019 15:36:30", shift: "B2", acuity: 3 },
  { date: "5/17/2019 15:58:51", shift: "D1", acuity: 3 },
  { date: "5/17/2019 16:08:59", shift: "C1", acuity: 2 },
  { date: "5/17/2019 16:29:15", shift: "A2", acuity: 2 },
  { date: "5/17/2019 16:30:17", shift: "C2", acuity: 3 },
  { date: "5/17/2019 16:30:41", shift: "B2", acuity: 3 },
  { date: "5/17/2019 16:32:50", shift: "D1", acuity: 3 },
  { date: "5/17/2019 16:50:22", shift: "A2", acuity: 3 },
  { date: "5/17/2019 16:52:46", shift: "C2", acuity: 3 },
  { date: "5/17/2019 17:10:35", shift: "C2", acuity: 2 },
  { date: "5/17/2019 17:10:57", shift: "C2", acuity: 2 },
  { date: "5/17/2019 17:21:22", shift: "B2", acuity: 3 },
  { date: "5/17/2019 17:31:59", shift: "D1", acuity: 3 },
  { date: "5/17/2019 17:32:41", shift: "A2", acuity: 3 },
  { date: "5/17/2019 17:39:56", shift: "C2", acuity: 3 },
  { date: "5/17/2019 17:49:01", shift: "B2", acuity: 3 },
  { date: "5/17/2019 18:01:58", shift: "D2", acuity: 3 },
  { date: "5/17/2019 18:14:37", shift: "A2", acuity: 2 },
  { date: "5/17/2019 18:14:52", shift: "C2", acuity: 3 },
  { date: "5/17/2019 18:21:27", shift: "B2", acuity: 2 },
  { date: "5/17/2019 18:29:32", shift: "D2", acuity: 3 },
  { date: "5/17/2019 18:33:21", shift: "D2", acuity: 3 },
  { date: "5/17/2019 18:41:13", shift: "D2", acuity: 2 },
  { date: "5/17/2019 18:48:12", shift: "A2", acuity: 3 },
  { date: "5/17/2019 19:14:22", shift: "C2", acuity: 3 },
  { date: "5/17/2019 19:14:39", shift: "B2", acuity: 2 },
  { date: "5/17/2019 19:14:55", shift: "D2", acuity: 3 },
  { date: "5/17/2019 19:22:30", shift: "A2", acuity: 3 },
  { date: "5/17/2019 19:32:59", shift: "C2", acuity: 3 },
  { date: "5/17/2019 19:39:21", shift: "B2", acuity: 2 },
  { date: "5/17/2019 19:43:30", shift: "D2", acuity: 3 },
  { date: "5/17/2019 19:43:54", shift: "A2", acuity: 3 },
  { date: "5/17/2019 19:44:07", shift: "C2", acuity: 3 },
  { date: "5/17/2019 19:44:20", shift: "B2", acuity: 2 },
  { date: "5/17/2019 19:44:36", shift: "D2", acuity: 3 },
  { date: "5/17/2019 19:44:48", shift: "A2", acuity: 2 },
  { date: "5/17/2019 19:53:26", shift: "C2", acuity: 2 },
  { date: "5/17/2019 19:56:24", shift: "B2", acuity: 2 },
  { date: "5/17/2019 20:24:54", shift: "D2", acuity: 2 },
  { date: "5/17/2019 20:31:14", shift: "A2", acuity: 4 },
  { date: "5/17/2019 20:31:36", shift: "C2", acuity: 2 },
  { date: "5/17/2019 20:35:01", shift: "B2", acuity: 3 },
  { date: "5/17/2019 20:50:10", shift: "D2", acuity: 2 },
  { date: "5/17/2019 20:55:48", shift: "A2", acuity: 2 },
  { date: "5/17/2019 21:06:37", shift: "A3", acuity: 3 },
  { date: "5/17/2019 21:07:01", shift: "C2", acuity: 2 },
  { date: "5/17/2019 21:21:10", shift: "B2", acuity: 3 },
  { date: "5/17/2019 21:47:53", shift: "A3", acuity: 2 },
  { date: "5/17/2019 21:48:05", shift: "A3", acuity: 3 },
  { date: "5/17/2019 21:48:20", shift: "D2", acuity: 3 },
  { date: "5/17/2019 22:09:04", shift: "B3", acuity: 3 },
  { date: "5/17/2019 22:09:15", shift: "C2", acuity: 3 },
  { date: "5/17/2019 22:14:10", shift: "A3", acuity: 3 },
  { date: "5/17/2019 22:20:12", shift: "D2", acuity: 3 },
  { date: "5/17/2019 22:59:04", shift: "B3", acuity: 4 },
  { date: "5/17/2019 22:59:16", shift: "B3", acuity: 3 },
  { date: "5/17/2019 22:59:29", shift: "C2", acuity: 3 },
  { date: "5/17/2019 23:14:44", shift: "A3", acuity: 2 },
  { date: "5/17/2019 23:24:12", shift: "D2", acuity: 2 },
  { date: "5/17/2019 23:30:34", shift: "B3", acuity: 3 },
  { date: "5/17/2019 23:39:05", shift: "C2", acuity: 3 },
  { date: "5/17/2019 23:39:18", shift: "A3", acuity: 4 },
  { date: "5/17/2019 23:40:54", shift: "D2", acuity: 3 },
  { date: "5/17/2019 23:53:59", shift: "B3", acuity: 4 },
  { date: "5/17/2019 23:55:34", shift: "C2", acuity: 2 },
  { date: "5/18/2019 00:00:23", shift: "A3", acuity: 4 },
  { date: "5/18/2019 00:21:08", shift: "D2", acuity: 2 },
  { date: "5/18/2019 00:26:30", shift: "B3", acuity: 2 },
  { date: "5/18/2019 00:28:55", shift: "A3", acuity: 2 },
  { date: "5/18/2019 00:48:47", shift: "D2", acuity: 3 },
  { date: "5/18/2019 01:06:11", shift: "B3", acuity: 3 },
  { date: "5/18/2019 01:06:30", shift: "A3", acuity: 4 },
  { date: "5/18/2019 01:14:15", shift: "D2", acuity: 3 },
  { date: "5/18/2019 01:24:07", shift: "B3", acuity: 2 },
  { date: "5/18/2019 01:28:59", shift: "A3", acuity: 3 },
  { date: "5/18/2019 01:44:01", shift: "B3", acuity: 3 },
  { date: "5/18/2019 01:52:58", shift: "A3", acuity: 3 },
  { date: "5/18/2019 02:11:22", shift: "B3", acuity: 4 },
  { date: "5/18/2019 02:52:38", shift: "A3", acuity: 3 },
  { date: "5/18/2019 03:03:38", shift: "B3", acuity: 3 },
  { date: "5/18/2019 04:34:26", shift: "A3", acuity: 3 },
  { date: "5/18/2019 05:09:55", shift: "A1", acuity: 3 },
  { date: "5/18/2019 05:31:15", shift: "A1", acuity: 3 },
  { date: "5/18/2019 06:01:14", shift: "A1", acuity: 4 },
  { date: "5/18/2019 06:14:36", shift: "B1", acuity: 5 },
  { date: "5/18/2019 06:14:56", shift: "A1", acuity: 3 },
  { date: "5/18/2019 07:05:22", shift: "B1", acuity: 4 },
  { date: "5/18/2019 07:29:05", shift: "B1", acuity: 2 },
  { date: "5/18/2019 07:29:20", shift: "A1", acuity: 3 },
  { date: "5/18/2019 07:51:29", shift: "B1", acuity: 4 },
  { date: "5/18/2019 08:18:44", shift: "A1", acuity: 2 },
  { date: "5/18/2019 09:03:50", shift: "C1", acuity: 3 },
  { date: "5/18/2019 09:04:01", shift: "C1", acuity: 2 },
  { date: "5/18/2019 09:23:28", shift: "C1", acuity: 2 },
  { date: "5/18/2019 09:23:50", shift: "B1", acuity: 4 },
  { date: "5/18/2019 09:24:10", shift: "A1", acuity: 3 },
  { date: "5/18/2019 09:46:10", shift: "C1", acuity: 2 },
  { date: "5/18/2019 09:46:50", shift: "B1", acuity: 5 },
  { date: "5/18/2019 09:48:53", shift: "A1", acuity: 3 },
  { date: "5/18/2019 10:15:36", shift: "D1", acuity: 2 },
  { date: "5/18/2019 10:25:15", shift: "C1", acuity: 3 },
  { date: "5/18/2019 10:32:31", shift: "D1", acuity: 3 },
  { date: "5/18/2019 10:34:00", shift: "D1", acuity: 3 },
  { date: "5/18/2019 10:50:56", shift: "B1", acuity: 3 },
  { date: "5/18/2019 11:08:52", shift: "A1", acuity: 2 },
  { date: "5/18/2019 11:13:35", shift: "C1", acuity: 2 },
  { date: "5/18/2019 11:41:31", shift: "D1", acuity: 2 },
  { date: "5/18/2019 11:42:35", shift: "B1", acuity: 2 },
  { date: "5/18/2019 11:43:09", shift: "A1", acuity: 3 },
  { date: "5/18/2019 11:55:17", shift: "C1", acuity: 3 },
  { date: "5/18/2019 12:18:41", shift: "D1", acuity: 2 },
  { date: "5/18/2019 12:19:00", shift: "B1", acuity: 2 },
  { date: "5/18/2019 12:36:02", shift: "A1", acuity: 3 },
  { date: "5/18/2019 12:40:45", shift: "C1", acuity: 3 },
  { date: "5/18/2019 12:41:02", shift: "D1", acuity: 3 },
  { date: "5/18/2019 12:59:15", shift: "B1", acuity: 2 },
  { date: "5/18/2019 13:19:51", shift: "A2", acuity: 3 },
  { date: "5/18/2019 13:30:34", shift: "A2", acuity: 3 },
  { date: "5/18/2019 13:41:08", shift: "A2", acuity: 2 },
  { date: "5/18/2019 13:41:39", shift: "C1", acuity: 3 },
  { date: "5/18/2019 13:54:46", shift: "D1", acuity: 3 },
  { date: "5/18/2019 14:11:48", shift: "B2", acuity: 2 },
  { date: "5/18/2019 14:18:47", shift: "A2", acuity: 3 },
  { date: "5/18/2019 14:41:58", shift: "B2", acuity: 2 },
  { date: "5/18/2019 15:10:02", shift: "B2", acuity: 2 },
  { date: "5/18/2019 15:18:45", shift: "C1", acuity: 3 },
  { date: "5/18/2019 15:20:21", shift: "D1", acuity: 4 },
  { date: "5/18/2019 15:20:39", shift: "A2", acuity: 3 },
  { date: "5/18/2019 15:28:57", shift: "B2", acuity: 3 },
  { date: "5/18/2019 15:34:21", shift: "C1", acuity: 2 },
  { date: "5/18/2019 15:41:03", shift: "D1", acuity: 3 },
  { date: "5/18/2019 15:42:29", shift: "A2", acuity: 4 },
  { date: "5/18/2019 15:50:47", shift: "B2", acuity: 4 },
  { date: "5/18/2019 15:53:15", shift: "C1", acuity: 4 },
  { date: "5/18/2019 16:02:42", shift: "D1", acuity: 2 },
  { date: "5/18/2019 16:02:57", shift: "A2", acuity: 2 },
  { date: "5/18/2019 16:03:34", shift: "B2", acuity: 3 },
  { date: "5/18/2019 16:17:48", shift: "C1", acuity: 3 },
  { date: "5/18/2019 16:40:30", shift: "C2", acuity: 2 },
  { date: "5/18/2019 16:48:41", shift: "D1", acuity: 2 },
  { date: "5/18/2019 16:51:45", shift: "A2", acuity: 3 },
  { date: "5/18/2019 16:53:53", shift: "B2", acuity: 3 },
  { date: "5/18/2019 16:55:33", shift: "C2", acuity: 4 },
  { date: "5/18/2019 17:15:03", shift: "C2", acuity: 3 },
  { date: "5/18/2019 17:15:11", shift: "C2", acuity: 2 },
  { date: "5/18/2019 17:21:35", shift: "D1", acuity: 2 },
  { date: "5/18/2019 17:22:07", shift: "A2", acuity: 2 },
  { date: "5/18/2019 18:01:54", shift: "D2", acuity: 2 },
  { date: "5/18/2019 18:13:35", shift: "B2", acuity: 2 },
  { date: "5/18/2019 18:19:13", shift: "C2", acuity: 2 },
  { date: "5/18/2019 18:30:43", shift: "D2", acuity: 2 },
  { date: "5/18/2019 18:58:03", shift: "D2", acuity: 3 },
  { date: "5/18/2019 18:58:27", shift: "A2", acuity: 3 },
  { date: "5/18/2019 18:59:11", shift: "B2", acuity: 3 },
  { date: "5/18/2019 18:59:26", shift: "C2", acuity: 3 },
  { date: "5/18/2019 19:09:09", shift: "D2", acuity: 3 },
  { date: "5/18/2019 19:09:48", shift: "A2", acuity: 3 },
  { date: "5/18/2019 19:10:00", shift: "B2", acuity: 3 },
  { date: "5/18/2019 19:17:42", shift: "C2", acuity: 3 },
  { date: "5/18/2019 19:31:05", shift: "D2", acuity: 2 },
  { date: "5/18/2019 19:31:33", shift: "A2", acuity: 4 },
  { date: "5/18/2019 19:44:24", shift: "B2", acuity: 7 },
  { date: "5/18/2019 19:44:43", shift: "C2", acuity: 2 },
  { date: "5/18/2019 19:51:56", shift: "D2", acuity: 7 },
  { date: "5/18/2019 19:53:44", shift: "A2", acuity: 4 },
  { date: "5/18/2019 19:54:44", shift: "B2", acuity: 2 },
  { date: "5/18/2019 20:00:24", shift: "C2", acuity: 3 },
  { date: "5/18/2019 20:05:13", shift: "D2", acuity: 3 },
  { date: "5/18/2019 20:13:45", shift: "A2", acuity: 3 },
  { date: "5/18/2019 20:26:01", shift: "B2", acuity: 3 },
  { date: "5/18/2019 20:31:36", shift: "C2", acuity: 7 },
  { date: "5/18/2019 20:36:26", shift: "D2", acuity: 7 },
  { date: "5/18/2019 20:46:21", shift: "A2", acuity: 2 },
  { date: "5/18/2019 20:58:56", shift: "B2", acuity: 2 },
  { date: "5/18/2019 20:59:57", shift: "C2", acuity: 3 },
  { date: "5/18/2019 21:17:15", shift: "A3", acuity: 3 },
  { date: "5/18/2019 21:35:05", shift: "A3", acuity: 3 },
  { date: "5/18/2019 21:35:26", shift: "A3", acuity: 2 },
  { date: "5/18/2019 21:38:34", shift: "D2", acuity: 7 },
  { date: "5/18/2019 21:44:03", shift: "B2", acuity: 3 },
  { date: "5/18/2019 21:50:21", shift: "C2", acuity: 7 },
  { date: "5/18/2019 22:03:46", shift: "B3", acuity: 2 },
  { date: "5/18/2019 22:18:05", shift: "A3", acuity: 3 },
  { date: "5/18/2019 22:19:33", shift: "D2", acuity: 7 },
  { date: "5/18/2019 22:23:35", shift: "C2", acuity: 2 },
  { date: "5/18/2019 22:24:24", shift: "B3", acuity: 2 },
  { date: "5/18/2019 22:24:43", shift: "A3", acuity: 2 },
  { date: "5/18/2019 22:30:50", shift: "B3", acuity: 3 },
  { date: "5/18/2019 22:44:44", shift: "B3", acuity: 4 },
  { date: "5/18/2019 22:47:13", shift: "D2", acuity: 3 },
  { date: "5/18/2019 22:51:58", shift: "C2", acuity: 2 },
  { date: "5/18/2019 22:53:44", shift: "A3", acuity: 3 },
  { date: "5/18/2019 22:56:47", shift: "B3", acuity: 3 },
  { date: "5/18/2019 23:25:04", shift: "D2", acuity: 3 },
  { date: "5/18/2019 23:25:24", shift: "C2", acuity: 2 },
  { date: "5/18/2019 23:26:21", shift: "A3", acuity: 2 },
  { date: "5/18/2019 23:33:45", shift: "B3", acuity: 3 },
  { date: "5/18/2019 23:53:44", shift: "D2", acuity: 3 },
  { date: "5/18/2019 23:53:56", shift: "C2", acuity: 3 },
  { date: "5/18/2019 23:54:12", shift: "A3", acuity: 5 },
  { date: "5/19/2019 00:14:12", shift: "B3", acuity: 2 },
  { date: "5/19/2019 00:50:53", shift: "D2", acuity: 3 },
  { date: "5/19/2019 00:56:08", shift: "A3", acuity: 3 },
  { date: "5/19/2019 00:59:13", shift: "B3", acuity: 4 },
  { date: "5/19/2019 01:13:14", shift: "D2", acuity: 3 },
  { date: "5/19/2019 01:13:33", shift: "A3", acuity: 2 },
  { date: "5/19/2019 01:32:05", shift: "B3", acuity: 3 },
  { date: "5/19/2019 01:32:20", shift: "A3", acuity: 3 },
  { date: "5/19/2019 01:48:32", shift: "B3", acuity: 3 },
  { date: "5/19/2019 01:51:54", shift: "A3", acuity: 3 },
  { date: "5/19/2019 02:08:43", shift: "B3", acuity: 3 },
  { date: "5/19/2019 02:31:19", shift: "A3", acuity: 3 },
  { date: "5/19/2019 02:32:37", shift: "B3", acuity: 3 },
  { date: "5/19/2019 03:06:33", shift: "A3", acuity: 3 },
  { date: "5/19/2019 03:12:38", shift: "B3", acuity: 3 },
  { date: "5/19/2019 03:28:27", shift: "A3", acuity: 3 },
  { date: "5/19/2019 04:28:59", shift: "B3", acuity: 3 },
  { date: "5/19/2019 04:29:12", shift: "A3", acuity: 4 },
  { date: "5/19/2019 04:51:33", shift: "B3", acuity: 3 },
  { date: "5/19/2019 05:01:20", shift: "A1", acuity: 2 },
  { date: "5/19/2019 05:15:26", shift: "B3", acuity: 2 },
  { date: "5/19/2019 05:22:11", shift: "A1", acuity: 4 },
  { date: "5/19/2019 05:22:29", shift: "B3", acuity: 4 },
  { date: "5/19/2019 05:46:33", shift: "A1", acuity: 3 },
  { date: "5/19/2019 06:50:07", shift: "A1", acuity: 2 },
  { date: "5/19/2019 07:17:33", shift: "B1", acuity: 2 },
  { date: "5/19/2019 07:24:20", shift: "B1", acuity: 3 },
  { date: "5/19/2019 07:28:13", shift: "B1", acuity: 3 },
  { date: "5/19/2019 07:44:02", shift: "B1", acuity: 2 },
  { date: "5/19/2019 08:16:41", shift: "A1", acuity: 3 },
  { date: "5/19/2019 08:28:12", shift: "B1", acuity: 3 },
  { date: "5/19/2019 08:38:35", shift: "C1", acuity: 3 },
  { date: "5/19/2019 09:25:22", shift: "C1", acuity: 3 },
  { date: "5/19/2019 09:32:47", shift: "C1", acuity: 3 },
  { date: "5/19/2019 09:41:53", shift: "A1", acuity: 3 },
  { date: "5/19/2019 09:44:09", shift: "B1", acuity: 4 },
  { date: "5/19/2019 09:58:45", shift: "C1", acuity: 3 },
  { date: "5/19/2019 10:00:28", shift: "D1", acuity: 3 },
  { date: "5/19/2019 10:07:19", shift: "A1", acuity: 3 },
  { date: "5/19/2019 10:11:56", shift: "B1", acuity: 2 },
  { date: "5/19/2019 10:14:15", shift: "C1", acuity: 3 },
  { date: "5/19/2019 10:16:40", shift: "D1", acuity: 2 },
  { date: "5/19/2019 10:40:12", shift: "D1", acuity: 3 },
  { date: "5/19/2019 10:49:34", shift: "D1", acuity: 2 },
  { date: "5/19/2019 11:00:08", shift: "A1", acuity: 2 },
  { date: "5/19/2019 11:00:36", shift: "B1", acuity: 2 },
  { date: "5/19/2019 11:22:27", shift: "C1", acuity: 2 },
  { date: "5/19/2019 11:31:38", shift: "D1", acuity: 2 },
  { date: "5/19/2019 11:32:21", shift: "A1", acuity: 3 },
  { date: "5/19/2019 11:33:50", shift: "B1", acuity: 2 },
  { date: "5/19/2019 11:50:06", shift: "C1", acuity: 3 },
  { date: "5/19/2019 12:00:48", shift: "D1", acuity: 3 },
  { date: "5/19/2019 12:12:13", shift: "A1", acuity: 2 },
  { date: "5/19/2019 12:12:28", shift: "B1", acuity: 3 },
  { date: "5/19/2019 12:16:35", shift: "C1", acuity: 3 },
  { date: "5/19/2019 12:33:11", shift: "D1", acuity: 2 },
  { date: "5/19/2019 12:33:28", shift: "A1", acuity: 3 },
  { date: "5/19/2019 12:33:37", shift: "B1", acuity: 3 },
  { date: "5/19/2019 12:38:28", shift: "C1", acuity: 2 },
  { date: "5/19/2019 12:40:04", shift: "D1", acuity: 2 },
  { date: "5/19/2019 12:57:43", shift: "A1", acuity: 3 },
  { date: "5/19/2019 13:02:56", shift: "A2", acuity: 2 },
  { date: "5/19/2019 13:14:06", shift: "B1", acuity: 3 },
  { date: "5/19/2019 13:21:07", shift: "C1", acuity: 3 },
  { date: "5/19/2019 13:48:36", shift: "A2", acuity: 3 },
  { date: "5/19/2019 13:54:16", shift: "A2", acuity: 3 },
  { date: "5/19/2019 14:09:19", shift: "B2", acuity: 2 },
  { date: "5/19/2019 14:28:12", shift: "D1", acuity: 3 },
  { date: "5/19/2019 14:30:14", shift: "B2", acuity: 2 },
  { date: "5/19/2019 14:30:35", shift: "B2", acuity: 2 },
  { date: "5/19/2019 14:37:10", shift: "C1", acuity: 3 },
  { date: "5/19/2019 14:49:09", shift: "A2", acuity: 4 },
  { date: "5/19/2019 14:57:59", shift: "D1", acuity: 3 },
  { date: "5/19/2019 15:00:12", shift: "B2", acuity: 4 },
  { date: "5/19/2019 15:06:56", shift: "C1", acuity: 3 },
  { date: "5/19/2019 15:18:11", shift: "A2", acuity: 2 },
  { date: "5/19/2019 15:23:13", shift: "D1", acuity: 3 },
  { date: "5/19/2019 15:23:32", shift: "B2", acuity: 3 },
  { date: "5/19/2019 15:23:52", shift: "C1", acuity: 3 },
  { date: "5/19/2019 15:26:00", shift: "A2", acuity: 3 },
  { date: "5/19/2019 15:28:22", shift: "D1", acuity: 3 },
  { date: "5/19/2019 15:46:42", shift: "B2", acuity: 3 },
  { date: "5/19/2019 15:51:24", shift: "C1", acuity: 3 },
  { date: "5/19/2019 15:52:19", shift: "A2", acuity: 3 },
  { date: "5/19/2019 15:53:30", shift: "D1", acuity: 3 },
  { date: "5/19/2019 15:54:07", shift: "B2", acuity: 2 },
  { date: "5/19/2019 16:06:50", shift: "C1", acuity: 3 },
  { date: "5/19/2019 16:22:13", shift: "A2", acuity: 4 },
  { date: "5/19/2019 16:22:39", shift: "D1", acuity: 3 },
  { date: "5/19/2019 16:30:00", shift: "C2", acuity: 2 },
  { date: "5/19/2019 16:44:58", shift: "B2", acuity: 2 },
  { date: "5/19/2019 16:51:36", shift: "A2", acuity: 3 },
  { date: "5/19/2019 17:02:32", shift: "C2", acuity: 4 },
  { date: "5/19/2019 17:05:59", shift: "C2", acuity: 2 },
  { date: "5/19/2019 17:23:41", shift: "D1", acuity: 2 },
  { date: "5/19/2019 17:24:00", shift: "B2", acuity: 2 },
  { date: "5/19/2019 17:35:22", shift: "A2", acuity: 3 },
  { date: "5/19/2019 17:46:48", shift: "C2", acuity: 2 },
  { date: "5/19/2019 17:51:38", shift: "D1", acuity: 2 },
  { date: "5/19/2019 18:00:20", shift: "D2", acuity: 2 },
  { date: "5/19/2019 18:04:50", shift: "B2", acuity: 2 },
  { date: "5/19/2019 18:07:03", shift: "A2", acuity: 2 },
  { date: "5/19/2019 18:15:00", shift: "C2", acuity: 2 },
  { date: "5/19/2019 18:21:18", shift: "D2", acuity: 4 },
  { date: "5/19/2019 18:21:42", shift: "B2", acuity: 3 },
  { date: "5/19/2019 18:34:06", shift: "D2", acuity: 3 },
  { date: "5/19/2019 18:39:20", shift: "D2", acuity: 3 },
  { date: "5/19/2019 18:44:48", shift: "A2", acuity: 3 },
  { date: "5/19/2019 18:46:33", shift: "C2", acuity: 3 },
  { date: "5/19/2019 18:47:02", shift: "B2", acuity: 2 },
  { date: "5/19/2019 18:59:50", shift: "D2", acuity: 2 },
  { date: "5/19/2019 19:07:30", shift: "A2", acuity: 2 },
  { date: "5/19/2019 19:18:36", shift: "C2", acuity: 3 },
  { date: "5/19/2019 19:20:24", shift: "B2", acuity: 2 },
  { date: "5/19/2019 19:21:15", shift: "D2", acuity: 3 },
  { date: "5/19/2019 19:22:22", shift: "A2", acuity: 3 },
  { date: "5/19/2019 19:23:33", shift: "C2", acuity: 3 },
  { date: "5/19/2019 19:59:56", shift: "B2", acuity: 4 },
  { date: "5/19/2019 20:23:25", shift: "D2", acuity: 7 },
  { date: "5/19/2019 20:24:02", shift: "A2", acuity: 3 },
  { date: "5/19/2019 20:29:41", shift: "C2", acuity: 7 },
  { date: "5/19/2019 20:40:27", shift: "B2", acuity: 2 },
  { date: "5/19/2019 21:02:25", shift: "A3", acuity: 3 },
  { date: "5/19/2019 21:02:47", shift: "D2", acuity: 7 },
  { date: "5/19/2019 21:08:05", shift: "C2", acuity: 3 },
  { date: "5/19/2019 21:46:54", shift: "A3", acuity: 3 },
  { date: "5/19/2019 21:47:11", shift: "A3", acuity: 3 },
  { date: "5/19/2019 21:47:31", shift: "B2", acuity: 7 },
  { date: "5/19/2019 21:48:40", shift: "D2", acuity: 3 },
  { date: "5/19/2019 21:52:30", shift: "C2", acuity: 3 },
  { date: "5/19/2019 21:52:44", shift: "A3", acuity: 3 },
  { date: "5/19/2019 22:15:36", shift: "B3", acuity: 2 },
  { date: "5/19/2019 22:21:51", shift: "D2", acuity: 3 },
  { date: "5/19/2019 22:22:12", shift: "C2", acuity: 7 },
  { date: "5/19/2019 22:22:28", shift: "A3", acuity: 2 },
  { date: "5/19/2019 22:39:59", shift: "B3", acuity: 3 },
  { date: "5/19/2019 22:54:31", shift: "B3", acuity: 3 },
  { date: "5/19/2019 23:03:16", shift: "D2", acuity: 7 },
  { date: "5/19/2019 23:12:57", shift: "C2", acuity: 7 },
  { date: "5/19/2019 23:18:59", shift: "A3", acuity: 2 },
  { date: "5/19/2019 23:20:09", shift: "B3", acuity: 4 },
  { date: "5/19/2019 23:29:57", shift: "D2", acuity: 4 },
  { date: "5/19/2019 23:30:11", shift: "C2", acuity: 2 },
  { date: "5/19/2019 23:31:46", shift: "A3", acuity: 4 },
  { date: "5/19/2019 23:43:34", shift: "B3", acuity: 4 },
  { date: "5/19/2019 23:43:46", shift: "D2", acuity: 3 },
  { date: "5/20/2019 00:02:56", shift: "A3", acuity: 4 },
  { date: "5/20/2019 00:05:06", shift: "B3", acuity: 3 },
  { date: "5/20/2019 00:11:25", shift: "D2", acuity: 2 },
  { date: "5/20/2019 00:11:37", shift: "A3", acuity: 3 },
  { date: "5/20/2019 00:48:17", shift: "B3", acuity: 3 },
  { date: "5/20/2019 00:48:28", shift: "D2", acuity: 4 },
  { date: "5/20/2019 00:58:30", shift: "A3", acuity: 3 },
  { date: "5/20/2019 00:58:43", shift: "B3", acuity: 3 },
  { date: "5/20/2019 00:59:00", shift: "D2", acuity: 4 },
  { date: "5/20/2019 01:04:46", shift: "A3", acuity: 4 },
  { date: "5/20/2019 01:04:57", shift: "B3", acuity: 3 },
  { date: "5/20/2019 01:19:37", shift: "D2", acuity: 7 },
  { date: "5/20/2019 01:30:25", shift: "A3", acuity: 3 },
  { date: "5/20/2019 02:12:42", shift: "B3", acuity: 3 },
  { date: "5/20/2019 02:20:33", shift: "A3", acuity: 3 },
  { date: "5/20/2019 02:31:28", shift: "B3", acuity: 3 },
  { date: "5/20/2019 03:05:30", shift: "A3", acuity: 2 },
  { date: "5/20/2019 03:31:56", shift: "B3", acuity: 3 },
  { date: "5/20/2019 04:31:18", shift: "A3", acuity: 3 },
  { date: "5/20/2019 04:46:40", shift: "B3", acuity: 3 },
  { date: "5/20/2019 04:46:53", shift: "A3", acuity: 2 },
  { date: "5/20/2019 04:53:43", shift: "B3", acuity: 2 },
  { date: "5/20/2019 04:54:15", shift: "B3", acuity: 3 },
  { date: "5/20/2019 05:06:16", shift: "A1", acuity: 3 },
  { date: "5/20/2019 05:06:40", shift: "B3", acuity: 3 },
  { date: "5/20/2019 05:31:28", shift: "A1", acuity: 2 },
  { date: "5/20/2019 05:38:50", shift: "A1", acuity: 2 },
  { date: "5/20/2019 05:42:11", shift: "B3", acuity: 3 },
  { date: "5/20/2019 06:05:45", shift: "B1", acuity: 3 },
  { date: "5/20/2019 06:23:11", shift: "A1", acuity: 3 },
  { date: "5/20/2019 06:33:51", shift: "B1", acuity: 3 },
  { date: "5/20/2019 06:55:54", shift: "B1", acuity: 3 },
  { date: "5/20/2019 07:17:11", shift: "A1", acuity: 3 },
  { date: "5/20/2019 07:17:20", shift: "B1", acuity: 3 },
  { date: "5/20/2019 07:39:10", shift: "A1", acuity: 3 },
  { date: "5/20/2019 07:49:14", shift: "B1", acuity: 2 },
  { date: "5/20/2019 07:55:44", shift: "A1", acuity: 3 },
  { date: "5/20/2019 08:14:39", shift: "B1", acuity: 2 },
  { date: "5/20/2019 08:31:46", shift: "C1", acuity: 3 },
  { date: "5/20/2019 08:31:55", shift: "A1", acuity: 2 },
  { date: "5/20/2019 08:53:17", shift: "B1", acuity: 2 },
  { date: "5/20/2019 09:18:21", shift: "C1", acuity: 3 },
  { date: "5/20/2019 09:22:19", shift: "C1", acuity: 2 },
  { date: "5/20/2019 09:33:06", shift: "A1", acuity: 3 },
  { date: "5/20/2019 09:33:19", shift: "B1", acuity: 3 },
  { date: "5/20/2019 09:33:28", shift: "C1", acuity: 3 },
  { date: "5/20/2019 09:40:22", shift: "A1", acuity: 3 },
  { date: "5/20/2019 09:41:02", shift: "B1", acuity: 3 },
  { date: "5/20/2019 09:41:18", shift: "C1", acuity: 2 },
  { date: "5/20/2019 09:41:27", shift: "A1", acuity: 2 },
  { date: "5/20/2019 09:55:02", shift: "B1", acuity: 3 },
  { date: "5/20/2019 09:55:13", shift: "C1", acuity: 2 },
  { date: "5/20/2019 10:06:54", shift: "D1", acuity: 2 },
  { date: "5/20/2019 10:27:34", shift: "A1", acuity: 3 },
  { date: "5/20/2019 10:30:40", shift: "D1", acuity: 3 },
  { date: "5/20/2019 10:30:56", shift: "D1", acuity: 2 },
  { date: "5/20/2019 10:34:12", shift: "B1", acuity: 3 },
  { date: "5/20/2019 10:57:57", shift: "C1", acuity: 2 },
  { date: "5/20/2019 11:05:39", shift: "A1", acuity: 2 },
  { date: "5/20/2019 11:20:00", shift: "D1", acuity: 2 },
  { date: "5/20/2019 11:32:07", shift: "B1", acuity: 3 },
  { date: "5/20/2019 11:46:55", shift: "C1", acuity: 3 },
  { date: "5/20/2019 11:47:10", shift: "A1", acuity: 2 },
  { date: "5/20/2019 11:47:20", shift: "D1", acuity: 3 },
  { date: "5/20/2019 12:20:13", shift: "B1", acuity: 3 },
  { date: "5/20/2019 12:29:25", shift: "C1", acuity: 3 },
  { date: "5/20/2019 12:38:39", shift: "A1", acuity: 3 },
  { date: "5/20/2019 12:50:36", shift: "D1", acuity: 3 },
  { date: "5/20/2019 12:51:44", shift: "B1", acuity: 3 },
  { date: "5/20/2019 12:53:44", shift: "C1", acuity: 3 },
  { date: "5/20/2019 13:07:27", shift: "A2", acuity: 3 },
  { date: "5/20/2019 13:07:36", shift: "D1", acuity: 3 },
  { date: "5/20/2019 13:07:55", shift: "B1", acuity: 3 },
  { date: "5/20/2019 13:30:49", shift: "A2", acuity: 2 },
  { date: "5/20/2019 13:33:32", shift: "A2", acuity: 3 },
  { date: "5/20/2019 13:46:11", shift: "C1", acuity: 2 },
  { date: "5/20/2019 14:01:48", shift: "B2", acuity: 2 },
  { date: "5/20/2019 14:02:06", shift: "D1", acuity: 3 },
  { date: "5/20/2019 14:06:13", shift: "A2", acuity: 3 },
  { date: "5/20/2019 14:10:37", shift: "C1", acuity: 3 },
  { date: "5/20/2019 14:16:50", shift: "B2", acuity: 2 },
  { date: "5/20/2019 14:17:04", shift: "D1", acuity: 3 },
  { date: "5/20/2019 14:27:54", shift: "A2", acuity: 2 },
  { date: "5/20/2019 14:36:49", shift: "B2", acuity: 3 },
  { date: "5/20/2019 14:40:04", shift: "B2", acuity: 2 },
  { date: "5/20/2019 15:01:06", shift: "C1", acuity: 3 },
  { date: "5/20/2019 15:04:56", shift: "D1", acuity: 3 },
  { date: "5/20/2019 15:20:27", shift: "A2", acuity: 3 },
  { date: "5/20/2019 15:27:09", shift: "B2", acuity: 3 },
  { date: "5/20/2019 15:29:07", shift: "C1", acuity: 3 },
  { date: "5/20/2019 15:36:26", shift: "D1", acuity: 2 },
  { date: "5/20/2019 15:40:04", shift: "A2", acuity: 3 },
  { date: "5/20/2019 15:43:37", shift: "B2", acuity: 3 },
  { date: "5/20/2019 15:43:49", shift: "C1", acuity: 2 },
  { date: "5/20/2019 16:21:05", shift: "D1", acuity: 3 },
  { date: "5/20/2019 16:23:55", shift: "A2", acuity: 2 },
  { date: "5/20/2019 16:27:28", shift: "B2", acuity: 2 },
  { date: "5/20/2019 16:51:45", shift: "C2", acuity: 2 },
  { date: "5/20/2019 17:08:23", shift: "C2", acuity: 3 },
  { date: "5/20/2019 17:12:18", shift: "C2", acuity: 3 },
  { date: "5/20/2019 17:13:37", shift: "D1", acuity: 2 },
  { date: "5/20/2019 17:18:16", shift: "A2", acuity: 2 },
  { date: "5/20/2019 17:22:00", shift: "B2", acuity: 3 },
  { date: "5/20/2019 17:22:22", shift: "C2", acuity: 3 },
  { date: "5/20/2019 17:43:10", shift: "D1", acuity: 3 },
  { date: "5/20/2019 17:43:30", shift: "A2", acuity: 2 },
  { date: "5/20/2019 17:43:59", shift: "B2", acuity: 2 },
  { date: "5/20/2019 18:08:30", shift: "D2", acuity: 3 },
  { date: "5/20/2019 18:08:53", shift: "C2", acuity: 3 },
  { date: "5/20/2019 18:32:03", shift: "D2", acuity: 2 },
  { date: "5/20/2019 18:55:37", shift: "D2", acuity: 2 },
  { date: "5/20/2019 18:57:16", shift: "A2", acuity: 3 },
  { date: "5/20/2019 18:57:52", shift: "B2", acuity: 3 },
  { date: "5/20/2019 19:10:13", shift: "C2", acuity: 2 },
  { date: "5/20/2019 19:13:20", shift: "D2", acuity: 2 },
  { date: "5/20/2019 19:13:53", shift: "A2", acuity: 3 },
  { date: "5/20/2019 19:15:36", shift: "B2", acuity: 2 },
  { date: "5/20/2019 19:16:05", shift: "C2", acuity: 2 },
  { date: "5/20/2019 19:17:24", shift: "D2", acuity: 3 },
  { date: "5/20/2019 19:39:09", shift: "A2", acuity: 2 },
  { date: "5/20/2019 19:43:02", shift: "B2", acuity: 3 },
  { date: "5/20/2019 19:46:21", shift: "C2", acuity: 3 },
  { date: "5/20/2019 20:00:55", shift: "D2", acuity: 3 },
  { date: "5/20/2019 20:28:27", shift: "A2", acuity: 4 },
  { date: "5/20/2019 20:31:26", shift: "B2", acuity: 2 },
  { date: "5/20/2019 20:36:19", shift: "C2", acuity: 3 },
  { date: "5/20/2019 21:01:26", shift: "A3", acuity: 3 },
  { date: "5/20/2019 21:01:49", shift: "D2", acuity: 4 },
  { date: "5/20/2019 21:08:13", shift: "B2", acuity: 3 },
  { date: "5/20/2019 21:30:22", shift: "A3", acuity: 3 },
  { date: "5/20/2019 21:30:40", shift: "A3", acuity: 2 },
  { date: "5/20/2019 21:30:52", shift: "C2", acuity: 3 },
  { date: "5/20/2019 21:48:56", shift: "D2", acuity: 4 },
  { date: "5/20/2019 21:49:11", shift: "B2", acuity: 2 },
  { date: "5/20/2019 22:01:29", shift: "B3", acuity: 3 },
  { date: "5/20/2019 22:04:36", shift: "A3", acuity: 4 },
  { date: "5/20/2019 22:11:48", shift: "C2", acuity: 7 },
  { date: "5/20/2019 22:27:52", shift: "D2", acuity: 2 },
  { date: "5/20/2019 22:33:49", shift: "B3", acuity: 7 },
  { date: "5/20/2019 22:38:28", shift: "B3", acuity: 7 },
  { date: "5/20/2019 22:39:33", shift: "A3", acuity: 3 },
  { date: "5/20/2019 22:39:48", shift: "C2", acuity: 3 },
  { date: "5/20/2019 22:40:00", shift: "D2", acuity: 3 },
  { date: "5/20/2019 22:47:11", shift: "B3", acuity: 4 },
  { date: "5/20/2019 22:58:24", shift: "A3", acuity: 2 },
  { date: "5/20/2019 22:59:54", shift: "C2", acuity: 7 },
  { date: "5/20/2019 23:05:55", shift: "D2", acuity: 3 },
  { date: "5/20/2019 23:09:45", shift: "B3", acuity: 3 },
  { date: "5/20/2019 23:20:32", shift: "A3", acuity: 2 },
  { date: "5/20/2019 23:31:51", shift: "C2", acuity: 2 },
  { date: "5/20/2019 23:32:07", shift: "D2", acuity: 2 },
  { date: "5/20/2019 23:36:15", shift: "B3", acuity: 3 },
  { date: "5/20/2019 23:36:32", shift: "A3", acuity: 3 },
  { date: "5/20/2019 23:58:22", shift: "C2", acuity: 3 },
  { date: "5/21/2019 00:02:31", shift: "D2", acuity: 2 },
  { date: "5/21/2019 00:02:55", shift: "B3", acuity: 4 },
  { date: "5/21/2019 00:10:32", shift: "A3", acuity: 3 },
  { date: "5/21/2019 00:26:50", shift: "D2", acuity: 3 },
  { date: "5/21/2019 00:46:36", shift: "B3", acuity: 2 },
  { date: "5/21/2019 00:46:48", shift: "A3", acuity: 3 },
  { date: "5/21/2019 00:47:05", shift: "D2", acuity: 4 },
  { date: "5/21/2019 01:11:37", shift: "B3", acuity: 2 },
  { date: "5/21/2019 01:33:27", shift: "A3", acuity: 2 },
  { date: "5/21/2019 01:44:13", shift: "B3", acuity: 3 },
  { date: "5/21/2019 02:04:16", shift: "A3", acuity: 4 },
  { date: "5/21/2019 02:28:50", shift: "B3", acuity: 2 },
  { date: "5/21/2019 02:29:03", shift: "A3", acuity: 3 },
  { date: "5/21/2019 03:14:21", shift: "B3", acuity: 4 },
  { date: "5/21/2019 03:14:33", shift: "A3", acuity: 3 },
  { date: "5/21/2019 04:09:21", shift: "B3", acuity: 2 },
  { date: "5/21/2019 04:33:58", shift: "A3", acuity: 2 },
  { date: "5/21/2019 05:49:50", shift: "A1", acuity: 5 },
  { date: "5/21/2019 05:57:30", shift: "A1", acuity: 3 },
  { date: "5/21/2019 06:32:09", shift: "A1", acuity: 3 },
  { date: "5/21/2019 06:50:16", shift: "B1", acuity: 2 },
  { date: "5/21/2019 06:51:35", shift: "B1", acuity: 3 },
  { date: "5/21/2019 06:57:53", shift: "B1", acuity: 3 },
  { date: "5/21/2019 07:18:29", shift: "A1", acuity: 3 },
  { date: "5/21/2019 07:18:40", shift: "B1", acuity: 2 },
  { date: "5/21/2019 07:32:11", shift: "A1", acuity: 3 },
  { date: "5/21/2019 07:46:05", shift: "B1", acuity: 3 },
  { date: "5/21/2019 08:05:58", shift: "A1", acuity: 3 },
  { date: "5/21/2019 08:11:02", shift: "B1", acuity: 3 },
  { date: "5/21/2019 08:47:18", shift: "C1", acuity: 3 },
  { date: "5/21/2019 09:18:44", shift: "C1", acuity: 2 },
  { date: "5/21/2019 09:19:00", shift: "C1", acuity: 2 },
  { date: "5/21/2019 09:37:28", shift: "A1", acuity: 3 },
  { date: "5/21/2019 09:49:01", shift: "B1", acuity: 3 },
  { date: "5/21/2019 10:11:54", shift: "D1", acuity: 4 },
  { date: "5/21/2019 10:12:47", shift: "C1", acuity: 4 },
  { date: "5/21/2019 10:13:17", shift: "A1", acuity: 3 },
  { date: "5/21/2019 10:35:45", shift: "D1", acuity: 2 },
  { date: "5/21/2019 10:37:40", shift: "D1", acuity: 3 },
  { date: "5/21/2019 10:45:06", shift: "B1", acuity: 2 },
  { date: "5/21/2019 11:03:56", shift: "C1", acuity: 3 },
  { date: "5/21/2019 11:08:09", shift: "A1", acuity: 3 },
  { date: "5/21/2019 11:09:12", shift: "D1", acuity: 2 },
  { date: "5/21/2019 11:17:51", shift: "B1", acuity: 3 },
  { date: "5/21/2019 11:22:55", shift: "C1", acuity: 2 },
  { date: "5/21/2019 11:26:52", shift: "A1", acuity: 3 },
  { date: "5/21/2019 11:34:51", shift: "D1", acuity: 3 },
  { date: "5/21/2019 11:52:22", shift: "B1", acuity: 2 },
  { date: "5/21/2019 11:52:33", shift: "C1", acuity: 3 },
  { date: "5/21/2019 12:15:06", shift: "A1", acuity: 3 },
  { date: "5/21/2019 12:15:15", shift: "D1", acuity: 3 },
  { date: "5/21/2019 12:28:51", shift: "B1", acuity: 3 },
  { date: "5/21/2019 12:54:24", shift: "C1", acuity: 3 },
  { date: "5/21/2019 13:00:51", shift: "A2", acuity: 2 },
  { date: "5/21/2019 13:03:45", shift: "D1", acuity: 2 },
  { date: "5/21/2019 13:20:04", shift: "B1", acuity: 2 },
  { date: "5/21/2019 13:23:21", shift: "C1", acuity: 3 },
  { date: "5/21/2019 13:28:11", shift: "A2", acuity: 3 },
  { date: "5/21/2019 13:39:36", shift: "A2", acuity: 3 },
  { date: "5/21/2019 13:51:01", shift: "A2", acuity: 2 },
  { date: "5/21/2019 14:05:09", shift: "B2", acuity: 2 },
  { date: "5/21/2019 14:22:44", shift: "D1", acuity: 2 },
  { date: "5/21/2019 14:28:55", shift: "C1", acuity: 3 },
  { date: "5/21/2019 14:46:57", shift: "B2", acuity: 2 },
  { date: "5/21/2019 14:56:10", shift: "B2", acuity: 2 },
  { date: "5/21/2019 14:57:23", shift: "A2", acuity: 3 },
  { date: "5/21/2019 15:08:48", shift: "D1", acuity: 3 },
  { date: "5/21/2019 15:17:10", shift: "C1", acuity: 2 },
  { date: "5/21/2019 15:42:58", shift: "B2", acuity: 2 },
  { date: "5/21/2019 15:49:24", shift: "A2", acuity: 2 },
  { date: "5/21/2019 15:52:17", shift: "D1", acuity: 3 },
  { date: "5/21/2019 15:52:44", shift: "C1", acuity: 3 },
  { date: "5/21/2019 16:02:27", shift: "B2", acuity: 3 },
  { date: "5/21/2019 16:14:35", shift: "A2", acuity: 3 },
  { date: "5/21/2019 16:25:56", shift: "D1", acuity: 3 },
  { date: "5/21/2019 16:33:33", shift: "C2", acuity: 3 },
  { date: "5/21/2019 16:39:07", shift: "B2", acuity: 3 },
  { date: "5/21/2019 16:54:27", shift: "A2", acuity: 3 },
  { date: "5/21/2019 17:07:41", shift: "C2", acuity: 4 },
  { date: "5/21/2019 17:08:03", shift: "C2", acuity: 2 },
  { date: "5/21/2019 17:13:39", shift: "D1", acuity: 3 },
  { date: "5/21/2019 17:19:56", shift: "B2", acuity: 2 },
  { date: "5/21/2019 17:20:24", shift: "A2", acuity: 3 },
  { date: "5/21/2019 17:28:47", shift: "C2", acuity: 2 },
  { date: "5/21/2019 17:40:09", shift: "D1", acuity: 2 },
  { date: "5/21/2019 17:55:17", shift: "B2", acuity: 3 },
  { date: "5/21/2019 18:02:40", shift: "D2", acuity: 2 },
  { date: "5/21/2019 18:18:03", shift: "A2", acuity: 2 },
  { date: "5/21/2019 18:26:21", shift: "C2", acuity: 2 },
  { date: "5/21/2019 18:33:00", shift: "D2", acuity: 3 },
  { date: "5/21/2019 18:45:35", shift: "D2", acuity: 2 },
  { date: "5/21/2019 19:00:00", shift: "B2", acuity: 3 },
  { date: "5/21/2019 19:16:40", shift: "A2", acuity: 2 },
  { date: "5/21/2019 19:35:55", shift: "C2", acuity: 2 },
  { date: "5/21/2019 19:36:11", shift: "D2", acuity: 2 },
  { date: "5/21/2019 19:36:56", shift: "B2", acuity: 3 },
  { date: "5/21/2019 19:45:00", shift: "A2", acuity: 2 },
  { date: "5/21/2019 19:55:08", shift: "C2", acuity: 3 },
  { date: "5/21/2019 20:05:36", shift: "D2", acuity: 3 },
  { date: "5/21/2019 20:13:11", shift: "B2", acuity: 2 },
  { date: "5/21/2019 20:13:25", shift: "A2", acuity: 3 },
  { date: "5/21/2019 20:22:38", shift: "C2", acuity: 3 },
  { date: "5/21/2019 20:33:59", shift: "D2", acuity: 2 },
  { date: "5/21/2019 20:43:49", shift: "B2", acuity: 2 },
  { date: "5/21/2019 20:44:04", shift: "A2", acuity: 2 },
  { date: "5/21/2019 20:44:36", shift: "C2", acuity: 2 },
  { date: "5/21/2019 20:52:13", shift: "D2", acuity: 3 },
  { date: "5/21/2019 21:00:51", shift: "A3", acuity: 2 },
  { date: "5/21/2019 21:16:12", shift: "B2", acuity: 3 },
  { date: "5/21/2019 21:44:57", shift: "A3", acuity: 3 },
  { date: "5/21/2019 21:51:44", shift: "A3", acuity: 2 },
  { date: "5/21/2019 22:05:33", shift: "B3", acuity: 3 },
  { date: "5/21/2019 22:05:59", shift: "C2", acuity: 3 },
  { date: "5/21/2019 22:09:27", shift: "D2", acuity: 3 },
  { date: "5/21/2019 22:20:22", shift: "A3", acuity: 2 },
  { date: "5/21/2019 22:46:44", shift: "B3", acuity: 4 },
  { date: "5/21/2019 23:14:25", shift: "B3", acuity: 2 },
  { date: "5/21/2019 23:14:39", shift: "C2", acuity: 2 },
  { date: "5/21/2019 23:25:16", shift: "D2", acuity: 3 },
  { date: "5/21/2019 23:38:57", shift: "A3", acuity: 2 },
  { date: "5/21/2019 23:48:25", shift: "B3", acuity: 2 },
  { date: "5/21/2019 23:52:36", shift: "C2", acuity: 3 },
  { date: "5/22/2019 00:02:14", shift: "D2", acuity: 2 },
  { date: "5/22/2019 00:02:31", shift: "A3", acuity: 2 },
  { date: "5/22/2019 00:03:44", shift: "B3", acuity: 3 },
  { date: "5/22/2019 00:17:03", shift: "D2", acuity: 3 },
  { date: "5/22/2019 00:17:23", shift: "A3", acuity: 4 },
  { date: "5/22/2019 00:37:08", shift: "B3", acuity: 2 },
  { date: "5/22/2019 00:42:20", shift: "D2", acuity: 3 },
  { date: "5/22/2019 00:56:11", shift: "A3", acuity: 2 },
  { date: "5/22/2019 01:04:55", shift: "B3", acuity: 3 },
  { date: "5/22/2019 01:20:31", shift: "D2", acuity: 4 },
  { date: "5/22/2019 01:33:46", shift: "A3", acuity: 4 },
  { date: "5/22/2019 01:34:17", shift: "B3", acuity: 4 },
  { date: "5/22/2019 01:34:34", shift: "A3", acuity: 3 },
  { date: "5/22/2019 01:57:37", shift: "B3", acuity: 3 },
  { date: "5/22/2019 01:57:55", shift: "A3", acuity: 3 },
  { date: "5/22/2019 02:10:55", shift: "B3", acuity: 3 },
  { date: "5/22/2019 02:19:06", shift: "A3", acuity: 4 },
  { date: "5/22/2019 02:31:19", shift: "B3", acuity: 4 },
  { date: "5/22/2019 02:31:39", shift: "A3", acuity: 4 },
  { date: "5/22/2019 02:42:06", shift: "B3", acuity: 3 },
  { date: "5/22/2019 03:05:23", shift: "A3", acuity: 3 },
  { date: "5/22/2019 03:35:44", shift: "B3", acuity: 3 },
  { date: "5/22/2019 03:55:16", shift: "A3", acuity: 3 },
  { date: "5/22/2019 04:09:26", shift: "B3", acuity: 2 },
  { date: "5/22/2019 04:14:00", shift: "A3", acuity: 2 },
  { date: "5/22/2019 04:21:45", shift: "B3", acuity: 3 },
  { date: "5/22/2019 05:01:16", shift: "A1", acuity: 4 },
  { date: "5/22/2019 05:01:22", shift: "B3", acuity: 5 },
  { date: "5/22/2019 05:40:05", shift: "A1", acuity: 3 },
  { date: "5/22/2019 05:50:34", shift: "A1", acuity: 3 },
  { date: "5/22/2019 06:32:38", shift: "B1", acuity: 2 },
  { date: "5/22/2019 06:36:12", shift: "B1", acuity: 3 },
  { date: "5/22/2019 07:05:55", shift: "B1", acuity: 2 },
  { date: "5/22/2019 07:55:40", shift: "A1", acuity: 2 },
  { date: "5/22/2019 07:55:50", shift: "B1", acuity: 4 },
  { date: "5/22/2019 09:27:41", shift: "C1", acuity: 4 },
  { date: "5/22/2019 09:27:52", shift: "C1", acuity: 2 },
  { date: "5/22/2019 09:28:04", shift: "C1", acuity: 2 },
  { date: "5/22/2019 09:39:48", shift: "A1", acuity: 4 },
  { date: "5/22/2019 09:52:24", shift: "B1", acuity: 2 },
  { date: "5/22/2019 10:02:44", shift: "D1", acuity: 2 },
  { date: "5/22/2019 10:03:49", shift: "C1", acuity: 3 },
  { date: "5/22/2019 10:08:00", shift: "A1", acuity: 3 },
  { date: "5/22/2019 10:19:33", shift: "B1", acuity: 2 },
  { date: "5/22/2019 10:19:53", shift: "D1", acuity: 3 },
  { date: "5/22/2019 10:25:15", shift: "C1", acuity: 3 },
  { date: "5/22/2019 10:29:09", shift: "A1", acuity: 3 },
  { date: "5/22/2019 10:43:48", shift: "D1", acuity: 3 },
  { date: "5/22/2019 10:55:39", shift: "D1", acuity: 3 },
  { date: "5/22/2019 11:37:01", shift: "B1", acuity: 2 },
  { date: "5/22/2019 11:44:55", shift: "C1", acuity: 2 },
  { date: "5/22/2019 11:56:05", shift: "A1", acuity: 3 },
  { date: "5/22/2019 12:22:13", shift: "D1", acuity: 3 },
  { date: "5/22/2019 12:55:15", shift: "B1", acuity: 2 },
  { date: "5/22/2019 13:10:55", shift: "A2", acuity: 3 },
  { date: "5/22/2019 13:16:25", shift: "C1", acuity: 3 },
  { date: "5/22/2019 13:17:00", shift: "D1", acuity: 3 },
  { date: "5/22/2019 13:37:26", shift: "A2", acuity: 3 },
  { date: "5/22/2019 13:43:13", shift: "A2", acuity: 3 },
  { date: "5/22/2019 13:58:29", shift: "B1", acuity: 2 },
  { date: "5/22/2019 14:22:53", shift: "B2", acuity: 3 },
  { date: "5/22/2019 14:26:35", shift: "C1", acuity: 2 },
  { date: "5/22/2019 14:34:53", shift: "B2", acuity: 2 },
  { date: "5/22/2019 14:51:23", shift: "B2", acuity: 3 },
  { date: "5/22/2019 14:51:32", shift: "D1", acuity: 3 },
  { date: "5/22/2019 14:51:49", shift: "A2", acuity: 3 },
  { date: "5/22/2019 15:04:58", shift: "C1", acuity: 3 },
  { date: "5/22/2019 15:15:28", shift: "B2", acuity: 2 },
  { date: "5/22/2019 15:29:43", shift: "D1", acuity: 5 },
  { date: "5/22/2019 15:31:07", shift: "A2", acuity: 3 },
  { date: "5/22/2019 15:38:51", shift: "C1", acuity: 4 },
  { date: "5/22/2019 16:16:52", shift: "B2", acuity: 3 },
  { date: "5/22/2019 16:17:17", shift: "D1", acuity: 3 },
  { date: "5/22/2019 16:20:51", shift: "A2", acuity: 3 },
  { date: "5/22/2019 16:27:45", shift: "C1", acuity: 2 },
  { date: "5/22/2019 16:27:57", shift: "B2", acuity: 3 },
  { date: "5/22/2019 16:29:55", shift: "D1", acuity: 3 },
  { date: "5/22/2019 16:41:35", shift: "C2", acuity: 4 },
  { date: "5/22/2019 16:41:54", shift: "A2", acuity: 3 },
  { date: "5/22/2019 16:56:52", shift: "B2", acuity: 3 },
  { date: "5/22/2019 16:58:15", shift: "D1", acuity: 3 },
  { date: "5/22/2019 17:04:05", shift: "C2", acuity: 3 },
  { date: "5/22/2019 17:04:14", shift: "C2", acuity: 3 },
  { date: "5/22/2019 17:21:52", shift: "A2", acuity: 2 },
  { date: "5/22/2019 17:26:37", shift: "B2", acuity: 2 },
  { date: "5/22/2019 17:28:09", shift: "D1", acuity: 2 },
  { date: "5/22/2019 17:33:15", shift: "C2", acuity: 2 },
  { date: "5/22/2019 18:08:01", shift: "D2", acuity: 4 },
  { date: "5/22/2019 18:08:13", shift: "A2", acuity: 3 },
  { date: "5/22/2019 18:08:23", shift: "B2", acuity: 2 },
  { date: "5/22/2019 18:30:20", shift: "D2", acuity: 2 },
  { date: "5/22/2019 18:31:01", shift: "D2", acuity: 2 },
  { date: "5/22/2019 18:35:52", shift: "C2", acuity: 2 },
  { date: "5/22/2019 18:59:18", shift: "A2", acuity: 4 },
  { date: "5/22/2019 19:08:43", shift: "B2", acuity: 2 },
  { date: "5/22/2019 19:17:40", shift: "D2", acuity: 2 },
  { date: "5/22/2019 19:22:23", shift: "C2", acuity: 2 },
  { date: "5/22/2019 19:39:36", shift: "A2", acuity: 3 },
  { date: "5/22/2019 19:39:56", shift: "B2", acuity: 3 },
  { date: "5/22/2019 19:44:04", shift: "D2", acuity: 3 },
  { date: "5/22/2019 19:44:18", shift: "C2", acuity: 4 },
  { date: "5/22/2019 19:51:38", shift: "A2", acuity: 2 },
  { date: "5/22/2019 20:02:34", shift: "B2", acuity: 2 },
  { date: "5/22/2019 20:05:27", shift: "D2", acuity: 2 },
  { date: "5/22/2019 20:22:05", shift: "C2", acuity: 2 },
  { date: "5/22/2019 20:23:24", shift: "A2", acuity: 3 },
  { date: "5/22/2019 20:25:34", shift: "B2", acuity: 3 },
  { date: "5/22/2019 20:39:29", shift: "D2", acuity: 2 },
  { date: "5/22/2019 21:01:30", shift: "A3", acuity: 3 },
  { date: "5/22/2019 21:08:00", shift: "C2", acuity: 3 },
  { date: "5/22/2019 21:15:24", shift: "B2", acuity: 2 },
  { date: "5/22/2019 21:16:38", shift: "D2", acuity: 3 },
  { date: "5/22/2019 21:23:49", shift: "A3", acuity: 3 },
  { date: "5/22/2019 21:30:07", shift: "A3", acuity: 2 },
  { date: "5/22/2019 21:55:27", shift: "A3", acuity: 2 },
  { date: "5/22/2019 21:58:35", shift: "C2", acuity: 3 },
  { date: "5/22/2019 22:07:02", shift: "B3", acuity: 3 },
  { date: "5/22/2019 22:07:15", shift: "D2", acuity: 4 },
  { date: "5/22/2019 22:15:36", shift: "A3", acuity: 4 },
  { date: "5/22/2019 22:35:46", shift: "B3", acuity: 4 },
  { date: "5/22/2019 22:35:56", shift: "B3", acuity: 3 },
  { date: "5/22/2019 22:38:08", shift: "C2", acuity: 3 },
  { date: "5/22/2019 22:46:27", shift: "D2", acuity: 2 },
  { date: "5/22/2019 22:49:20", shift: "A3", acuity: 4 },
  { date: "5/22/2019 23:04:19", shift: "B3", acuity: 2 },
  { date: "5/22/2019 23:12:35", shift: "C2", acuity: 3 },
  { date: "5/22/2019 23:19:02", shift: "D2", acuity: 3 },
  { date: "5/22/2019 23:26:04", shift: "A3", acuity: 3 },
  { date: "5/22/2019 23:27:50", shift: "B3", acuity: 2 },
  { date: "5/22/2019 23:46:46", shift: "C2", acuity: 2 },
  { date: "5/22/2019 23:47:03", shift: "D2", acuity: 3 },
  { date: "5/23/2019 00:04:00", shift: "A3", acuity: 3 },
  { date: "5/23/2019 00:25:00", shift: "B3", acuity: 4 },
  { date: "5/23/2019 00:26:50", shift: "D2", acuity: 3 },
  { date: "5/23/2019 00:29:23", shift: "A3", acuity: 4 },
  { date: "5/23/2019 00:49:16", shift: "B3", acuity: 2 },
  { date: "5/23/2019 00:54:41", shift: "D2", acuity: 3 },
  { date: "5/23/2019 01:30:16", shift: "A3", acuity: 4 },
  { date: "5/23/2019 01:51:15", shift: "B3", acuity: 3 },
  { date: "5/23/2019 02:00:45", shift: "A3", acuity: 3 },
  { date: "5/23/2019 02:00:59", shift: "B3", acuity: 3 },
  { date: "5/23/2019 02:07:37", shift: "A3", acuity: 3 },
  { date: "5/23/2019 02:55:50", shift: "B3", acuity: 2 },
  { date: "5/23/2019 03:08:24", shift: "A3", acuity: 3 },
  { date: "5/23/2019 03:30:02", shift: "B3", acuity: 3 },
  { date: "5/23/2019 03:41:37", shift: "A3", acuity: 4 },
  { date: "5/23/2019 03:44:27", shift: "B3", acuity: 3 },
  { date: "5/23/2019 03:50:42", shift: "A3", acuity: 3 },
  { date: "5/23/2019 04:12:38", shift: "B3", acuity: 2 },
  { date: "5/23/2019 05:02:25", shift: "A1", acuity: 2 },
  { date: "5/23/2019 05:14:01", shift: "B3", acuity: 2 },
  { date: "5/23/2019 05:41:03", shift: "A1", acuity: 3 },
  { date: "5/23/2019 05:58:11", shift: "A1", acuity: 4 },
  { date: "5/23/2019 06:10:00", shift: "B1", acuity: 4 },
  { date: "5/23/2019 06:47:00", shift: "B1", acuity: 2 },
  { date: "5/23/2019 06:48:21", shift: "B1", acuity: 2 },
  { date: "5/23/2019 07:17:09", shift: "A1", acuity: 2 },
  { date: "5/23/2019 07:31:09", shift: "B1", acuity: 3 },
  { date: "5/23/2019 07:55:21", shift: "A1", acuity: 3 },
  { date: "5/23/2019 08:15:31", shift: "B1", acuity: 2 },
  { date: "5/23/2019 08:15:51", shift: "A1", acuity: 4 },
  { date: "5/23/2019 08:49:43", shift: "C1", acuity: 4 },
  { date: "5/23/2019 08:50:54", shift: "B1", acuity: 2 },
  { date: "5/23/2019 09:13:32", shift: "C1", acuity: 3 },
  { date: "5/23/2019 09:17:56", shift: "C1", acuity: 4 },
  { date: "5/23/2019 09:24:15", shift: "A1", acuity: 4 },
  { date: "5/23/2019 09:24:58", shift: "B1", acuity: 4 },
  { date: "5/23/2019 09:25:43", shift: "C1", acuity: 3 },
  { date: "5/23/2019 09:26:21", shift: "A1", acuity: 3 },
  { date: "5/23/2019 09:38:25", shift: "B1", acuity: 2 },
  { date: "5/23/2019 09:39:04", shift: "C1", acuity: 2 },
  { date: "5/23/2019 09:46:00", shift: "A1", acuity: 2 },
  { date: "5/23/2019 09:54:06", shift: "B1", acuity: 2 },
  { date: "5/23/2019 09:54:36", shift: "C1", acuity: 3 },
  { date: "5/23/2019 10:11:34", shift: "D1", acuity: 3 },
  { date: "5/23/2019 10:12:18", shift: "A1", acuity: 2 },
  { date: "5/23/2019 10:27:01", shift: "B1", acuity: 2 },
  { date: "5/23/2019 10:30:47", shift: "D1", acuity: 2 },
  { date: "5/23/2019 10:55:03", shift: "D1", acuity: 3 },
  { date: "5/23/2019 11:13:19", shift: "C1", acuity: 3 },
  { date: "5/23/2019 11:17:13", shift: "A1", acuity: 2 },
  { date: "5/23/2019 11:48:12", shift: "B1", acuity: 3 },
  { date: "5/23/2019 11:50:01", shift: "D1", acuity: 2 },
  { date: "5/23/2019 11:52:14", shift: "C1", acuity: 2 },
  { date: "5/23/2019 12:10:25", shift: "A1", acuity: 3 },
  { date: "5/23/2019 12:16:03", shift: "B1", acuity: 2 },
  { date: "5/23/2019 12:16:46", shift: "D1", acuity: 4 },
  { date: "5/23/2019 12:41:38", shift: "C1", acuity: 2 },
  { date: "5/23/2019 12:50:44", shift: "A1", acuity: 2 },
  { date: "5/23/2019 12:52:34", shift: "B1", acuity: 2 },
  { date: "5/23/2019 13:13:43", shift: "A2", acuity: 4 },
  { date: "5/23/2019 13:14:12", shift: "D1", acuity: 7 },
  { date: "5/23/2019 13:34:46", shift: "A2", acuity: 2 },
  { date: "5/23/2019 13:38:50", shift: "A2", acuity: 2 },
  { date: "5/23/2019 13:39:33", shift: "C1", acuity: 2 },
  { date: "5/23/2019 13:50:35", shift: "B1", acuity: 2 },
  { date: "5/23/2019 14:12:59", shift: "B2", acuity: 4 },
  { date: "5/23/2019 14:34:58", shift: "B2", acuity: 2 },
  { date: "5/23/2019 14:36:20", shift: "B2", acuity: 2 },
  { date: "5/23/2019 14:41:13", shift: "D1", acuity: 4 },
  { date: "5/23/2019 14:41:40", shift: "A2", acuity: 3 },
  { date: "5/23/2019 14:44:27", shift: "C1", acuity: 2 },
  { date: "5/23/2019 15:04:35", shift: "B2", acuity: 3 },
  { date: "5/23/2019 15:16:23", shift: "D1", acuity: 3 },
  { date: "5/23/2019 15:16:38", shift: "A2", acuity: 2 },
  { date: "5/23/2019 15:20:42", shift: "C1", acuity: 3 },
  { date: "5/23/2019 15:28:36", shift: "B2", acuity: 3 },
  { date: "5/23/2019 15:35:22", shift: "D1", acuity: 3 },
  { date: "5/23/2019 15:36:48", shift: "A2", acuity: 3 },
  { date: "5/23/2019 15:37:48", shift: "C1", acuity: 3 },
  { date: "5/23/2019 15:46:18", shift: "B2", acuity: 3 },
  { date: "5/23/2019 15:47:35", shift: "D1", acuity: 2 },
  { date: "5/23/2019 16:14:11", shift: "A2", acuity: 3 },
  { date: "5/23/2019 16:17:30", shift: "C1", acuity: 2 },
  { date: "5/23/2019 16:29:29", shift: "B2", acuity: 3 },
  { date: "5/23/2019 16:37:52", shift: "C2", acuity: 2 },
  { date: "5/23/2019 16:40:02", shift: "D1", acuity: 2 },
  { date: "5/23/2019 16:52:23", shift: "A2", acuity: 3 },
  { date: "5/23/2019 16:55:29", shift: "B2", acuity: 2 },
  { date: "5/23/2019 17:07:18", shift: "C2", acuity: 2 },
  { date: "5/23/2019 17:07:46", shift: "C2", acuity: 2 },
  { date: "5/23/2019 17:08:28", shift: "D1", acuity: 2 },
  { date: "5/23/2019 17:26:20", shift: "A2", acuity: 3 },
  { date: "5/23/2019 17:31:04", shift: "B2", acuity: 2 },
  { date: "5/23/2019 17:50:51", shift: "C2", acuity: 3 },
  { date: "5/23/2019 18:02:43", shift: "D2", acuity: 3 },
  { date: "5/23/2019 18:03:18", shift: "A2", acuity: 2 },
  { date: "5/23/2019 18:03:48", shift: "B2", acuity: 3 },
  { date: "5/23/2019 18:21:47", shift: "C2", acuity: 3 },
  { date: "5/23/2019 18:22:14", shift: "D2", acuity: 2 },
  { date: "5/23/2019 18:49:02", shift: "D2", acuity: 2 },
  { date: "5/23/2019 18:50:45", shift: "D2", acuity: 3 },
  { date: "5/23/2019 18:51:20", shift: "A2", acuity: 2 },
  { date: "5/23/2019 19:17:16", shift: "B2", acuity: 3 },
  { date: "5/23/2019 19:17:49", shift: "C2", acuity: 3 },
  { date: "5/23/2019 19:23:40", shift: "D2", acuity: 3 },
  { date: "5/23/2019 19:27:04", shift: "A2", acuity: 3 },
  { date: "5/23/2019 19:34:04", shift: "B2", acuity: 3 },
  { date: "5/23/2019 19:45:07", shift: "C2", acuity: 3 },
  { date: "5/23/2019 19:45:21", shift: "D2", acuity: 2 },
  { date: "5/23/2019 20:04:24", shift: "A2", acuity: 3 },
  { date: "5/23/2019 20:13:55", shift: "B2", acuity: 3 },
  { date: "5/23/2019 20:17:40", shift: "C2", acuity: 3 },
  { date: "5/23/2019 20:38:10", shift: "D2", acuity: 2 },
  { date: "5/23/2019 20:38:22", shift: "A2", acuity: 3 },
  { date: "5/23/2019 21:04:07", shift: "A3", acuity: 3 },
  { date: "5/23/2019 21:04:24", shift: "B2", acuity: 2 },
  { date: "5/23/2019 21:09:24", shift: "C2", acuity: 3 },
  { date: "5/23/2019 21:09:40", shift: "D2", acuity: 2 },
  { date: "5/23/2019 21:30:16", shift: "A3", acuity: 2 },
  { date: "5/23/2019 21:44:24", shift: "A3", acuity: 3 },
  { date: "5/23/2019 21:44:35", shift: "B2", acuity: 2 },
  { date: "5/23/2019 22:04:36", shift: "B3", acuity: 3 },
  { date: "5/23/2019 22:07:15", shift: "C2", acuity: 3 },
  { date: "5/23/2019 22:11:33", shift: "D2", acuity: 4 },
  { date: "5/23/2019 22:26:36", shift: "A3", acuity: 3 },
  { date: "5/23/2019 22:30:18", shift: "B3", acuity: 3 },
  { date: "5/23/2019 22:30:31", shift: "B3", acuity: 2 },
  { date: "5/23/2019 22:30:57", shift: "C2", acuity: 2 },
  { date: "5/23/2019 22:49:27", shift: "D2", acuity: 4 },
  { date: "5/23/2019 22:49:40", shift: "A3", acuity: 3 },
  { date: "5/23/2019 23:07:15", shift: "B3", acuity: 3 },
  { date: "5/23/2019 23:08:33", shift: "C2", acuity: 4 },
  { date: "5/23/2019 23:08:42", shift: "D2", acuity: 3 },
  { date: "5/23/2019 23:13:27", shift: "A3", acuity: 2 },
  { date: "5/23/2019 23:26:13", shift: "B3", acuity: 4 },
  { date: "5/23/2019 23:34:15", shift: "C2", acuity: 2 },
  { date: "5/24/2019 00:15:06", shift: "D2", acuity: 3 },
  { date: "5/24/2019 00:26:19", shift: "A3", acuity: 3 },
  { date: "5/24/2019 00:40:37", shift: "B3", acuity: 3 },
  { date: "5/24/2019 00:40:49", shift: "D2", acuity: 3 },
  { date: "5/24/2019 01:00:28", shift: "A3", acuity: 3 },
  { date: "5/24/2019 01:03:26", shift: "B3", acuity: 4 },
  { date: "5/24/2019 01:08:21", shift: "D2", acuity: 4 },
  { date: "5/24/2019 01:16:49", shift: "A3", acuity: 3 },
  { date: "5/24/2019 01:41:23", shift: "B3", acuity: 3 },
  { date: "5/24/2019 01:41:34", shift: "A3", acuity: 2 },
  { date: "5/24/2019 01:42:10", shift: "B3", acuity: 4 },
  { date: "5/24/2019 01:42:30", shift: "A3", acuity: 4 },
  { date: "5/24/2019 01:42:55", shift: "B3", acuity: 3 },
  { date: "5/24/2019 01:46:00", shift: "A3", acuity: 2 },
  { date: "5/24/2019 03:35:39", shift: "B3", acuity: 2 },
  { date: "5/24/2019 03:44:44", shift: "A3", acuity: 3 },
  { date: "5/24/2019 04:18:27", shift: "B3", acuity: 3 },
  { date: "5/24/2019 04:28:59", shift: "A3", acuity: 2 },
  { date: "5/24/2019 05:02:08", shift: "A1", acuity: 3 },
  { date: "5/24/2019 05:02:18", shift: "B3", acuity: 3 },
  { date: "5/24/2019 06:00:57", shift: "A1", acuity: 5 },
  { date: "5/24/2019 06:12:31", shift: "A1", acuity: 4 },
  { date: "5/24/2019 06:35:32", shift: "B1", acuity: 3 },
  { date: "5/24/2019 07:12:17", shift: "B1", acuity: 3 },
  { date: "5/24/2019 07:14:04", shift: "B1", acuity: 2 },
  { date: "5/24/2019 07:21:04", shift: "A1", acuity: 2 },
  { date: "5/24/2019 07:31:12", shift: "B1", acuity: 2 },
  { date: "5/24/2019 07:32:55", shift: "A1", acuity: 3 },
  { date: "5/24/2019 07:39:36", shift: "B1", acuity: 3 },
  { date: "5/24/2019 07:58:50", shift: "A1", acuity: 2 },
  { date: "5/24/2019 08:00:21", shift: "B1", acuity: 3 },
  { date: "5/24/2019 08:47:25", shift: "C1", acuity: 4 },
  { date: "5/24/2019 08:47:37", shift: "A1", acuity: 2 },
  { date: "5/24/2019 09:00:26", shift: "C1", acuity: 3 },
  { date: "5/24/2019 09:14:45", shift: "C1", acuity: 2 },
  { date: "5/24/2019 09:15:15", shift: "B1", acuity: 3 },
  { date: "5/24/2019 09:17:58", shift: "A1", acuity: 3 },
  { date: "5/24/2019 09:26:02", shift: "C1", acuity: 3 },
  { date: "5/24/2019 09:26:14", shift: "B1", acuity: 4 },
  { date: "5/24/2019 09:54:37", shift: "A1", acuity: 2 },
  { date: "5/24/2019 09:54:44", shift: "C1", acuity: 3 },
  { date: "5/24/2019 10:19:35", shift: "D1", acuity: 2 },
  { date: "5/24/2019 10:22:38", shift: "B1", acuity: 3 },
  { date: "5/24/2019 10:25:14", shift: "A1", acuity: 2 },
  { date: "5/24/2019 10:56:28", shift: "D1", acuity: 2 },
  { date: "5/24/2019 11:10:32", shift: "D1", acuity: 2 },
  { date: "5/24/2019 11:12:05", shift: "C1", acuity: 2 },
  { date: "5/24/2019 11:12:44", shift: "B1", acuity: 2 },
  { date: "5/24/2019 11:21:33", shift: "A1", acuity: 3 },
  { date: "5/24/2019 11:29:33", shift: "D1", acuity: 2 },
  { date: "5/24/2019 11:30:57", shift: "C1", acuity: 2 },
  { date: "5/24/2019 11:36:14", shift: "B1", acuity: 3 },
  { date: "5/24/2019 11:55:41", shift: "A1", acuity: 3 },
  { date: "5/24/2019 12:01:35", shift: "D1", acuity: 3 },
  { date: "5/24/2019 12:05:25", shift: "C1", acuity: 2 },
  { date: "5/24/2019 12:12:12", shift: "B1", acuity: 3 },
  { date: "5/24/2019 12:20:02", shift: "A1", acuity: 3 },
  { date: "5/24/2019 12:53:24", shift: "D1", acuity: 3 },
  { date: "5/24/2019 12:58:32", shift: "C1", acuity: 3 },
  { date: "5/24/2019 12:58:41", shift: "B1", acuity: 2 },
  { date: "5/24/2019 13:18:48", shift: "A2", acuity: 4 },
  { date: "5/24/2019 13:19:28", shift: "D1", acuity: 2 },
  { date: "5/24/2019 13:30:52", shift: "A2", acuity: 2 },
  { date: "5/24/2019 13:50:21", shift: "A2", acuity: 2 },
  { date: "5/24/2019 13:51:48", shift: "C1", acuity: 2 },
  { date: "5/24/2019 13:56:25", shift: "B1", acuity: 2 },
  { date: "5/24/2019 14:16:05", shift: "B2", acuity: 2 },
  { date: "5/24/2019 14:16:18", shift: "D1", acuity: 3 },
  { date: "5/24/2019 14:16:30", shift: "A2", acuity: 3 },
  { date: "5/24/2019 14:35:31", shift: "B2", acuity: 2 },
  { date: "5/24/2019 14:35:44", shift: "B2", acuity: 3 },
  { date: "5/24/2019 14:36:07", shift: "C1", acuity: 2 },
  { date: "5/24/2019 14:55:46", shift: "D1", acuity: 3 },
  { date: "5/24/2019 14:56:04", shift: "A2", acuity: 3 },
  { date: "5/24/2019 14:56:24", shift: "B2", acuity: 2 },
  { date: "5/24/2019 15:09:03", shift: "C1", acuity: 3 },
  { date: "5/24/2019 15:12:41", shift: "D1", acuity: 2 },
  { date: "5/24/2019 15:13:01", shift: "A2", acuity: 2 },
  { date: "5/24/2019 15:22:31", shift: "B2", acuity: 3 },
  { date: "5/24/2019 15:28:27", shift: "C1", acuity: 2 },
  { date: "5/24/2019 15:55:36", shift: "D1", acuity: 2 },
  { date: "5/24/2019 15:55:51", shift: "A2", acuity: 3 },
  { date: "5/24/2019 15:55:59", shift: "B2", acuity: 3 },
  { date: "5/24/2019 16:10:16", shift: "C1", acuity: 3 },
  { date: "5/24/2019 16:18:06", shift: "D1", acuity: 2 },
  { date: "5/24/2019 16:29:19", shift: "A2", acuity: 3 },
  { date: "5/24/2019 16:29:36", shift: "B2", acuity: 2 },
  { date: "5/24/2019 16:39:00", shift: "C2", acuity: 3 },
  { date: "5/24/2019 16:50:29", shift: "D1", acuity: 2 },
  { date: "5/24/2019 16:55:38", shift: "A2", acuity: 2 },
  { date: "5/24/2019 16:56:12", shift: "B2", acuity: 2 },
  { date: "5/24/2019 17:01:20", shift: "C2", acuity: 3 },
  { date: "5/24/2019 17:21:41", shift: "C2", acuity: 2 },
  { date: "5/24/2019 17:24:26", shift: "D1", acuity: 3 },
  { date: "5/24/2019 17:24:44", shift: "A2", acuity: 2 },
  { date: "5/24/2019 17:45:14", shift: "B2", acuity: 2 },
  { date: "5/24/2019 17:47:46", shift: "C2", acuity: 3 },
  { date: "5/24/2019 17:54:30", shift: "D1", acuity: 2 },
  { date: "5/24/2019 18:00:48", shift: "D2", acuity: 2 },
  { date: "5/24/2019 18:06:56", shift: "A2", acuity: 3 },
  { date: "5/24/2019 18:16:29", shift: "B2", acuity: 2 },
  { date: "5/24/2019 18:33:26", shift: "D2", acuity: 2 },
  { date: "5/24/2019 18:33:39", shift: "D2", acuity: 3 },
  { date: "5/24/2019 18:37:16", shift: "C2", acuity: 4 },
  { date: "5/24/2019 18:56:39", shift: "A2", acuity: 3 },
  { date: "5/24/2019 18:56:50", shift: "B2", acuity: 3 },
  { date: "5/24/2019 18:57:05", shift: "D2", acuity: 2 },
  { date: "5/24/2019 18:59:07", shift: "C2", acuity: 2 },
  { date: "5/24/2019 19:01:09", shift: "A2", acuity: 2 },
  { date: "5/24/2019 19:14:50", shift: "B2", acuity: 3 },
  { date: "5/24/2019 19:19:32", shift: "D2", acuity: 4 },
  { date: "5/24/2019 19:33:08", shift: "C2", acuity: 3 },
  { date: "5/24/2019 19:47:48", shift: "A2", acuity: 2 },
  { date: "5/24/2019 19:49:08", shift: "B2", acuity: 3 },
  { date: "5/24/2019 19:49:29", shift: "D2", acuity: 3 },
  { date: "5/24/2019 20:28:54", shift: "C2", acuity: 2 },
  { date: "5/24/2019 20:29:52", shift: "A2", acuity: 2 },
  { date: "5/24/2019 20:30:26", shift: "B2", acuity: 4 },
  { date: "5/24/2019 20:31:36", shift: "D2", acuity: 3 },
  { date: "5/24/2019 20:35:59", shift: "C2", acuity: 2 },
  { date: "5/24/2019 20:39:45", shift: "A2", acuity: 2 },
  { date: "5/24/2019 20:43:51", shift: "B2", acuity: 2 },
  { date: "5/24/2019 20:56:05", shift: "D2", acuity: 3 },
  { date: "5/24/2019 20:56:55", shift: "C2", acuity: 2 },
  { date: "5/24/2019 21:16:00", shift: "A3", acuity: 2 },
  { date: "5/24/2019 21:34:59", shift: "A3", acuity: 3 },
  { date: "5/24/2019 21:35:28", shift: "A3", acuity: 3 },
  { date: "5/24/2019 21:58:58", shift: "B2", acuity: 3 },
  { date: "5/24/2019 22:02:38", shift: "B3", acuity: 3 },
  { date: "5/24/2019 22:16:09", shift: "D2", acuity: 2 },
  { date: "5/24/2019 22:26:39", shift: "C2", acuity: 2 },
  { date: "5/24/2019 22:27:02", shift: "A3", acuity: 3 },
  { date: "5/24/2019 22:39:39", shift: "B3", acuity: 3 },
  { date: "5/24/2019 22:46:44", shift: "B3", acuity: 3 },
  { date: "5/24/2019 22:51:29", shift: "D2", acuity: 3 },
  { date: "5/24/2019 22:52:03", shift: "C2", acuity: 4 },
  { date: "5/24/2019 23:07:48", shift: "A3", acuity: 3 },
  { date: "5/24/2019 23:08:08", shift: "B3", acuity: 3 },
  { date: "5/24/2019 23:08:41", shift: "D2", acuity: 3 },
  { date: "5/24/2019 23:13:50", shift: "C2", acuity: 5 },
  { date: "5/24/2019 23:14:23", shift: "A3", acuity: 3 },
  { date: "5/24/2019 23:17:32", shift: "B3", acuity: 2 },
  { date: "5/24/2019 23:49:56", shift: "D2", acuity: 5 },
  { date: "5/24/2019 23:50:20", shift: "C2", acuity: 3 },
  { date: "5/25/2019 00:01:30", shift: "A3", acuity: 3 },
  { date: "5/25/2019 00:39:54", shift: "B3", acuity: 3 },
  { date: "5/25/2019 00:40:06", shift: "D2", acuity: 2 },
  { date: "5/25/2019 00:50:13", shift: "A3", acuity: 2 },
  { date: "5/25/2019 01:05:11", shift: "B3", acuity: 3 },
  { date: "5/25/2019 01:41:54", shift: "A3", acuity: 2 },
  { date: "5/25/2019 01:51:43", shift: "B3", acuity: 2 },
  { date: "5/25/2019 01:56:14", shift: "A3", acuity: 2 },
  { date: "5/25/2019 02:06:36", shift: "B3", acuity: 3 },
  { date: "5/25/2019 02:24:57", shift: "A3", acuity: 4 },
  { date: "5/25/2019 02:50:02", shift: "B3", acuity: 3 },
  { date: "5/25/2019 03:02:33", shift: "A3", acuity: 4 },
  { date: "5/25/2019 03:24:21", shift: "B3", acuity: 4 },
  { date: "5/25/2019 03:26:54", shift: "A3", acuity: 3 },
  { date: "5/25/2019 03:34:05", shift: "B3", acuity: 5 },
  { date: "5/25/2019 03:34:32", shift: "A3", acuity: 4 },
  { date: "5/25/2019 03:38:09", shift: "B3", acuity: 2 },
  { date: "5/25/2019 03:39:26", shift: "A3", acuity: 3 },
  { date: "5/25/2019 04:18:18", shift: "B3", acuity: 3 },
  { date: "5/25/2019 04:25:10", shift: "A3", acuity: 3 },
  { date: "5/25/2019 04:25:47", shift: "B3", acuity: 3 },
  { date: "5/25/2019 05:13:50", shift: "A1", acuity: 5 },
  { date: "5/25/2019 05:22:16", shift: "B3", acuity: 3 },
  { date: "5/25/2019 05:57:33", shift: "A1", acuity: 3 },
  { date: "5/25/2019 06:19:21", shift: "A1", acuity: 4 },
  { date: "5/25/2019 06:20:11", shift: "B1", acuity: 2 },
  { date: "5/25/2019 06:51:17", shift: "B1", acuity: 3 },
  { date: "5/25/2019 07:14:59", shift: "B1", acuity: 3 },
  { date: "5/25/2019 07:17:53", shift: "A1", acuity: 4 },
  { date: "5/25/2019 07:26:53", shift: "B1", acuity: 3 },
  { date: "5/25/2019 07:52:33", shift: "A1", acuity: 3 },
  { date: "5/25/2019 07:52:48", shift: "B1", acuity: 3 },
  { date: "5/25/2019 08:35:21", shift: "C1", acuity: 3 },
  { date: "5/25/2019 08:35:45", shift: "A1", acuity: 2 },
  { date: "5/25/2019 08:36:04", shift: "B1", acuity: 2 },
  { date: "5/25/2019 08:36:23", shift: "C1", acuity: 3 },
  { date: "5/25/2019 09:05:41", shift: "C1", acuity: 4 },
  { date: "5/25/2019 09:10:37", shift: "C1", acuity: 4 },
  { date: "5/25/2019 09:11:21", shift: "A1", acuity: 2 },
  { date: "5/25/2019 09:17:31", shift: "B1", acuity: 2 },
  { date: "5/25/2019 09:20:50", shift: "C1", acuity: 3 },
  { date: "5/25/2019 09:36:49", shift: "A1", acuity: 4 },
  { date: "5/25/2019 09:37:07", shift: "B1", acuity: 3 },
  { date: "5/25/2019 09:42:54", shift: "C1", acuity: 3 },
  { date: "5/25/2019 09:48:13", shift: "A1", acuity: 3 },
  { date: "5/25/2019 10:20:31", shift: "D1", acuity: 2 },
  { date: "5/25/2019 10:20:49", shift: "B1", acuity: 2 },
  { date: "5/25/2019 10:32:37", shift: "D1", acuity: 2 },
  { date: "5/25/2019 10:45:15", shift: "D1", acuity: 3 },
  { date: "5/25/2019 10:48:42", shift: "C1", acuity: 3 },
  { date: "5/25/2019 10:57:14", shift: "A1", acuity: 4 },
  { date: "5/25/2019 10:57:33", shift: "B1", acuity: 2 },
  { date: "5/25/2019 11:12:43", shift: "D1", acuity: 2 },
  { date: "5/25/2019 11:13:02", shift: "C1", acuity: 2 },
  { date: "5/25/2019 11:20:09", shift: "A1", acuity: 3 },
  { date: "5/25/2019 11:41:00", shift: "B1", acuity: 3 },
  { date: "5/25/2019 11:56:55", shift: "D1", acuity: 3 },
  { date: "5/25/2019 12:04:11", shift: "C1", acuity: 3 },
  { date: "5/25/2019 12:10:27", shift: "A1", acuity: 3 },
  { date: "5/25/2019 12:26:40", shift: "B1", acuity: 2 },
  { date: "5/25/2019 12:33:51", shift: "D1", acuity: 2 },
  { date: "5/25/2019 13:02:49", shift: "A2", acuity: 2 },
  { date: "5/25/2019 13:03:22", shift: "C1", acuity: 3 },
  { date: "5/25/2019 13:03:54", shift: "B1", acuity: 3 },
  { date: "5/25/2019 13:28:58", shift: "D1", acuity: 2 },
  { date: "5/25/2019 13:52:59", shift: "A2", acuity: 2 },
  { date: "5/25/2019 14:12:57", shift: "A2", acuity: 3 },
  { date: "5/25/2019 14:27:58", shift: "B2", acuity: 3 },
  { date: "5/25/2019 14:28:21", shift: "C1", acuity: 3 },
  { date: "5/25/2019 14:28:39", shift: "D1", acuity: 3 },
  { date: "5/25/2019 14:51:46", shift: "B2", acuity: 3 },
  { date: "5/25/2019 14:56:39", shift: "B2", acuity: 2 },
  { date: "5/25/2019 15:15:39", shift: "A2", acuity: 2 },
  { date: "5/25/2019 15:35:54", shift: "C1", acuity: 3 },
  { date: "5/25/2019 15:39:52", shift: "D1", acuity: 2 },
  { date: "5/25/2019 15:43:29", shift: "B2", acuity: 3 },
  { date: "5/25/2019 15:52:12", shift: "A2", acuity: 3 },
  { date: "5/25/2019 15:58:15", shift: "C1", acuity: 2 },
  { date: "5/25/2019 16:05:40", shift: "D1", acuity: 2 },
  { date: "5/25/2019 16:11:44", shift: "B2", acuity: 3 },
  { date: "5/25/2019 16:11:59", shift: "A2", acuity: 3 },
  { date: "5/25/2019 16:14:00", shift: "C1", acuity: 2 },
  { date: "5/25/2019 16:25:23", shift: "D1", acuity: 3 },
  { date: "5/25/2019 17:00:19", shift: "C2", acuity: 2 },
  { date: "5/25/2019 17:30:06", shift: "C2", acuity: 3 },
  { date: "5/25/2019 17:30:54", shift: "C2", acuity: 3 },
  { date: "5/25/2019 17:38:22", shift: "B2", acuity: 3 },
  { date: "5/25/2019 17:46:46", shift: "A2", acuity: 3 },
  { date: "5/25/2019 17:54:19", shift: "D1", acuity: 2 },
  { date: "5/25/2019 17:54:41", shift: "C2", acuity: 2 },
  { date: "5/25/2019 17:59:51", shift: "B2", acuity: 3 },
  { date: "5/25/2019 18:07:13", shift: "D2", acuity: 2 },
  { date: "5/25/2019 18:14:42", shift: "A2", acuity: 3 },
  { date: "5/25/2019 18:32:33", shift: "D2", acuity: 3 },
  { date: "5/25/2019 18:32:48", shift: "D2", acuity: 3 },
  { date: "5/25/2019 18:33:17", shift: "C2", acuity: 3 },
  { date: "5/25/2019 18:57:27", shift: "B2", acuity: 3 },
  { date: "5/25/2019 18:57:53", shift: "A2", acuity: 3 },
  { date: "5/25/2019 19:05:26", shift: "D2", acuity: 2 },
  { date: "5/25/2019 19:13:32", shift: "C2", acuity: 3 },
  { date: "5/25/2019 19:18:25", shift: "B2", acuity: 2 },
  { date: "5/25/2019 19:28:28", shift: "A2", acuity: 3 },
  { date: "5/25/2019 19:28:40", shift: "D2", acuity: 3 },
  { date: "5/25/2019 19:34:06", shift: "C2", acuity: 3 },
  { date: "5/25/2019 19:37:02", shift: "B2", acuity: 3 },
  { date: "5/25/2019 19:39:34", shift: "A2", acuity: 2 },
  { date: "5/25/2019 19:56:58", shift: "D2", acuity: 2 },
  { date: "5/25/2019 20:07:45", shift: "C2", acuity: 2 },
  { date: "5/25/2019 20:08:04", shift: "B2", acuity: 3 },
  { date: "5/25/2019 20:14:06", shift: "A2", acuity: 2 },
  { date: "5/25/2019 20:16:36", shift: "D2", acuity: 3 },
  { date: "5/25/2019 20:21:47", shift: "C2", acuity: 3 },
  { date: "5/25/2019 20:35:39", shift: "B2", acuity: 2 },
  { date: "5/25/2019 20:35:55", shift: "A2", acuity: 2 },
  { date: "5/25/2019 20:43:09", shift: "D2", acuity: 4 },
  { date: "5/25/2019 20:53:25", shift: "C2", acuity: 3 },
  { date: "5/25/2019 20:57:38", shift: "B2", acuity: 3 },
  { date: "5/25/2019 21:03:31", shift: "A3", acuity: 2 },
  { date: "5/25/2019 21:28:00", shift: "D2", acuity: 3 },
  { date: "5/25/2019 21:32:32", shift: "A3", acuity: 5 },
  { date: "5/25/2019 21:32:54", shift: "A3", acuity: 4 },
  { date: "5/25/2019 21:33:08", shift: "C2", acuity: 4 },
  { date: "5/25/2019 22:00:24", shift: "B3", acuity: 3 },
  { date: "5/25/2019 22:11:23", shift: "D2", acuity: 4 },
  { date: "5/25/2019 22:11:33", shift: "A3", acuity: 3 },
  { date: "5/25/2019 22:17:32", shift: "C2", acuity: 4 },
  { date: "5/25/2019 22:21:02", shift: "B3", acuity: 3 },
  { date: "5/25/2019 22:29:39", shift: "D2", acuity: 4 },
  { date: "5/25/2019 22:36:13", shift: "B3", acuity: 4 },
  { date: "5/25/2019 22:37:50", shift: "B3", acuity: 3 },
  { date: "5/25/2019 22:51:25", shift: "A3", acuity: 3 },
  { date: "5/25/2019 23:05:23", shift: "C2", acuity: 4 },
  { date: "5/25/2019 23:10:30", shift: "D2", acuity: 4 },
  { date: "5/25/2019 23:12:38", shift: "B3", acuity: 4 },
  { date: "5/26/2019 00:04:17", shift: "A3", acuity: 2 },
  { date: "5/26/2019 00:12:38", shift: "D2", acuity: 3 },
  { date: "5/26/2019 00:32:45", shift: "B3", acuity: 2 },
  { date: "5/26/2019 00:33:06", shift: "A3", acuity: 2 },
  { date: "5/26/2019 00:59:34", shift: "D2", acuity: 4 },
  { date: "5/26/2019 01:14:41", shift: "B3", acuity: 2 },
  { date: "5/26/2019 01:25:02", shift: "A3", acuity: 3 },
  { date: "5/26/2019 01:31:43", shift: "B3", acuity: 3 },
  { date: "5/26/2019 01:42:11", shift: "A3", acuity: 2 },
  { date: "5/26/2019 01:53:56", shift: "B3", acuity: 3 },
  { date: "5/26/2019 01:54:08", shift: "A3", acuity: 3 },
  { date: "5/26/2019 02:10:20", shift: "B3", acuity: 3 },
  { date: "5/26/2019 02:10:39", shift: "A3", acuity: 4 },
  { date: "5/26/2019 02:11:19", shift: "B3", acuity: 3 },
  { date: "5/26/2019 02:19:30", shift: "A3", acuity: 3 },
  { date: "5/26/2019 02:19:42", shift: "B3", acuity: 4 },
  { date: "5/26/2019 02:57:56", shift: "A3", acuity: 4 },
  { date: "5/26/2019 03:03:02", shift: "B3", acuity: 4 },
  { date: "5/26/2019 04:10:00", shift: "A3", acuity: 4 },
  { date: "5/26/2019 04:10:12", shift: "B3", acuity: 3 },
  { date: "5/26/2019 04:23:48", shift: "A3", acuity: 2 },
  { date: "5/26/2019 05:05:28", shift: "A1", acuity: 4 },
  { date: "5/26/2019 05:05:41", shift: "B3", acuity: 4 },
  { date: "5/26/2019 05:15:10", shift: "A1", acuity: 4 },
  { date: "5/26/2019 05:43:02", shift: "A1", acuity: 3 },
  { date: "5/26/2019 05:43:15", shift: "A1", acuity: 4 },
  { date: "5/26/2019 06:13:18", shift: "B1", acuity: 3 },
  { date: "5/26/2019 06:17:26", shift: "A1", acuity: 4 },
  { date: "5/26/2019 07:09:22", shift: "B1", acuity: 2 },
  { date: "5/26/2019 07:14:40", shift: "B1", acuity: 4 },
  { date: "5/26/2019 07:53:35", shift: "B1", acuity: 4 },
  { date: "5/26/2019 07:53:58", shift: "A1", acuity: 3 },
  { date: "5/26/2019 07:54:18", shift: "B1", acuity: 3 },
  { date: "5/26/2019 07:54:36", shift: "A1", acuity: 3 },
  { date: "5/26/2019 08:21:52", shift: "B1", acuity: 3 },
  { date: "5/26/2019 08:30:10", shift: "C1", acuity: 3 },
  { date: "5/26/2019 08:33:12", shift: "A1", acuity: 3 },
  { date: "5/26/2019 08:36:45", shift: "B1", acuity: 2 },
  { date: "5/26/2019 08:47:01", shift: "C1", acuity: 2 },
  { date: "5/26/2019 09:17:57", shift: "C1", acuity: 3 },
  { date: "5/26/2019 09:18:17", shift: "C1", acuity: 3 },
  { date: "5/26/2019 09:20:26", shift: "A1", acuity: 4 },
  { date: "5/26/2019 09:20:32", shift: "B1", acuity: 3 },
  { date: "5/26/2019 09:31:34", shift: "C1", acuity: 2 },
  { date: "5/26/2019 09:41:46", shift: "A1", acuity: 3 },
  { date: "5/26/2019 09:50:19", shift: "B1", acuity: 3 },
  { date: "5/26/2019 10:12:53", shift: "D1", acuity: 2 },
  { date: "5/26/2019 10:13:15", shift: "C1", acuity: 3 },
  { date: "5/26/2019 10:13:38", shift: "A1", acuity: 3 },
  { date: "5/26/2019 10:16:55", shift: "B1", acuity: 4 },
  { date: "5/26/2019 10:52:36", shift: "D1", acuity: 3 },
  { date: "5/26/2019 10:55:50", shift: "D1", acuity: 3 },
  { date: "5/26/2019 10:56:16", shift: "C1", acuity: 3 },
  { date: "5/26/2019 11:23:46", shift: "A1", acuity: 3 },
  { date: "5/26/2019 11:24:50", shift: "B1", acuity: 2 },
  { date: "5/26/2019 11:27:42", shift: "D1", acuity: 3 },
  { date: "5/26/2019 11:27:57", shift: "C1", acuity: 3 },
  { date: "5/26/2019 11:50:27", shift: "A1", acuity: 2 },
  { date: "5/26/2019 12:19:32", shift: "B1", acuity: 3 },
  { date: "5/26/2019 12:19:49", shift: "D1", acuity: 3 },
  { date: "5/26/2019 12:25:40", shift: "C1", acuity: 2 },
  { date: "5/26/2019 12:25:47", shift: "A1", acuity: 2 },
  { date: "5/26/2019 12:26:18", shift: "B1", acuity: 2 },
  { date: "5/26/2019 12:39:06", shift: "D1", acuity: 3 },
  { date: "5/26/2019 12:54:22", shift: "C1", acuity: 3 },
  { date: "5/26/2019 13:30:10", shift: "A2", acuity: 2 },
  { date: "5/26/2019 13:30:36", shift: "A2", acuity: 3 },
  { date: "5/26/2019 13:50:25", shift: "A2", acuity: 3 },
  { date: "5/26/2019 13:54:07", shift: "B1", acuity: 3 },
  { date: "5/26/2019 13:54:25", shift: "D1", acuity: 2 },
  { date: "5/26/2019 13:56:35", shift: "C1", acuity: 3 },
  { date: "5/26/2019 14:08:30", shift: "B2", acuity: 3 },
  { date: "5/26/2019 14:11:42", shift: "A2", acuity: 3 },
  { date: "5/26/2019 14:23:53", shift: "D1", acuity: 3 },
  { date: "5/26/2019 14:24:05", shift: "C1", acuity: 3 },
  { date: "5/26/2019 14:24:22", shift: "B2", acuity: 3 },
  { date: "5/26/2019 14:25:57", shift: "A2", acuity: 3 },
  { date: "5/26/2019 14:26:16", shift: "D1", acuity: 3 },
  { date: "5/26/2019 14:32:49", shift: "B2", acuity: 3 },
  { date: "5/26/2019 14:33:06", shift: "B2", acuity: 3 },
  { date: "5/26/2019 15:28:34", shift: "C1", acuity: 2 },
  { date: "5/26/2019 15:28:48", shift: "A2", acuity: 3 },
  { date: "5/26/2019 15:29:44", shift: "D1", acuity: 3 },
  { date: "5/26/2019 15:48:27", shift: "B2", acuity: 2 },
  { date: "5/26/2019 16:01:34", shift: "C1", acuity: 3 },
  { date: "5/26/2019 16:05:03", shift: "A2", acuity: 4 },
  { date: "5/26/2019 16:06:11", shift: "D1", acuity: 4 },
  { date: "5/26/2019 16:06:29", shift: "B2", acuity: 3 },
  { date: "5/26/2019 16:11:29", shift: "C1", acuity: 2 },
  { date: "5/26/2019 16:16:46", shift: "A2", acuity: 3 },
  { date: "5/26/2019 16:30:17", shift: "C2", acuity: 3 },
  { date: "5/26/2019 16:43:33", shift: "D1", acuity: 2 },
  { date: "5/26/2019 16:53:53", shift: "B2", acuity: 3 },
  { date: "5/26/2019 16:54:13", shift: "A2", acuity: 3 },
  { date: "5/26/2019 16:54:33", shift: "C2", acuity: 2 },
  { date: "5/26/2019 16:57:50", shift: "D1", acuity: 2 },
  { date: "5/26/2019 17:06:06", shift: "C2", acuity: 3 },
  { date: "5/26/2019 17:08:07", shift: "C2", acuity: 2 },
  { date: "5/26/2019 17:12:24", shift: "B2", acuity: 3 },
  { date: "5/26/2019 17:12:39", shift: "A2", acuity: 3 },
  { date: "5/26/2019 17:23:25", shift: "D1", acuity: 2 },
  { date: "5/26/2019 17:26:21", shift: "C2", acuity: 2 },
  { date: "5/26/2019 17:26:43", shift: "B2", acuity: 3 },
  { date: "5/26/2019 17:36:30", shift: "A2", acuity: 3 },
  { date: "5/26/2019 17:45:20", shift: "D1", acuity: 3 },
  { date: "5/26/2019 17:45:36", shift: "C2", acuity: 3 },
  { date: "5/26/2019 18:02:03", shift: "D2", acuity: 2 },
  { date: "5/26/2019 18:12:21", shift: "B2", acuity: 4 },
  { date: "5/26/2019 18:30:09", shift: "D2", acuity: 2 },
  { date: "5/26/2019 18:30:17", shift: "D2", acuity: 4 },
  { date: "5/26/2019 18:32:34", shift: "A2", acuity: 3 },
  { date: "5/26/2019 18:45:43", shift: "C2", acuity: 2 },
  { date: "5/26/2019 18:54:14", shift: "B2", acuity: 3 },
  { date: "5/26/2019 18:57:37", shift: "D2", acuity: 3 },
  { date: "5/26/2019 18:57:52", shift: "A2", acuity: 3 },
  { date: "5/26/2019 19:27:07", shift: "C2", acuity: 3 },
  { date: "5/26/2019 19:29:28", shift: "B2", acuity: 2 },
  { date: "5/26/2019 19:31:26", shift: "D2", acuity: 3 },
  { date: "5/26/2019 19:31:43", shift: "A2", acuity: 3 },
  { date: "5/26/2019 19:33:47", shift: "C2", acuity: 3 },
  { date: "5/26/2019 19:39:47", shift: "B2", acuity: 2 },
  { date: "5/26/2019 19:47:28", shift: "D2", acuity: 4 },
  { date: "5/26/2019 19:47:53", shift: "A2", acuity: 2 },
  { date: "5/26/2019 20:05:57", shift: "C2", acuity: 4 },
  { date: "5/26/2019 20:07:05", shift: "B2", acuity: 3 },
  { date: "5/26/2019 20:12:40", shift: "D2", acuity: 3 },
  { date: "5/26/2019 20:18:13", shift: "A2", acuity: 3 },
  { date: "5/26/2019 20:31:02", shift: "C2", acuity: 3 },
  { date: "5/26/2019 20:40:14", shift: "B2", acuity: 2 },
  { date: "5/26/2019 20:45:04", shift: "D2", acuity: 2 },
  { date: "5/26/2019 21:00:44", shift: "A3", acuity: 3 },
  { date: "5/26/2019 21:01:05", shift: "C2", acuity: 3 },
  { date: "5/26/2019 21:01:19", shift: "B2", acuity: 3 },
  { date: "5/26/2019 21:31:45", shift: "A3", acuity: 3 },
  { date: "5/26/2019 21:53:04", shift: "A3", acuity: 3 },
  { date: "5/26/2019 21:53:59", shift: "D2", acuity: 3 },
  { date: "5/26/2019 21:54:05", shift: "C2", acuity: 3 },
  { date: "5/26/2019 22:11:10", shift: "B3", acuity: 4 },
  { date: "5/26/2019 22:19:05", shift: "A3", acuity: 3 },
  { date: "5/26/2019 22:23:42", shift: "D2", acuity: 3 },
  { date: "5/26/2019 22:37:28", shift: "B3", acuity: 2 },
  { date: "5/26/2019 22:45:03", shift: "B3", acuity: 2 },
  { date: "5/26/2019 22:50:06", shift: "C2", acuity: 2 },
  { date: "5/26/2019 23:02:21", shift: "A3", acuity: 2 },
  { date: "5/26/2019 23:18:46", shift: "D2", acuity: 3 },
  { date: "5/26/2019 23:19:17", shift: "B3", acuity: 2 },
  { date: "5/26/2019 23:33:20", shift: "C2", acuity: 3 },
  { date: "5/26/2019 23:36:07", shift: "A3", acuity: 2 },
  { date: "5/26/2019 23:39:22", shift: "D2", acuity: 3 },
  { date: "5/26/2019 23:46:53", shift: "B3", acuity: 3 },
  { date: "5/26/2019 23:56:08", shift: "C2", acuity: 3 },
  { date: "5/26/2019 23:56:19", shift: "A3", acuity: 3 },
  { date: "5/27/2019 00:13:48", shift: "D2", acuity: 3 },
  { date: "5/27/2019 00:14:04", shift: "B3", acuity: 4 },
  { date: "5/27/2019 00:14:17", shift: "A3", acuity: 4 },
  { date: "5/27/2019 00:37:16", shift: "D2", acuity: 3 },
  { date: "5/27/2019 00:37:34", shift: "B3", acuity: 3 },
  { date: "5/27/2019 00:54:22", shift: "A3", acuity: 3 },
  { date: "5/27/2019 01:03:11", shift: "D2", acuity: 5 },
  { date: "5/27/2019 01:10:04", shift: "B3", acuity: 3 },
  { date: "5/27/2019 01:58:11", shift: "A3", acuity: 4 },
  { date: "5/27/2019 02:12:37", shift: "B3", acuity: 4 },
  { date: "5/27/2019 02:30:09", shift: "A3", acuity: 3 },
  { date: "5/27/2019 02:58:40", shift: "B3", acuity: 3 },
  { date: "5/27/2019 03:05:20", shift: "A3", acuity: 3 },
  { date: "5/27/2019 03:06:15", shift: "B3", acuity: 3 },
  { date: "5/27/2019 03:07:34", shift: "A3", acuity: 4 },
  { date: "5/27/2019 03:21:53", shift: "B3", acuity: 2 },
  { date: "5/27/2019 03:26:29", shift: "A3", acuity: 4 },
  { date: "5/27/2019 03:35:14", shift: "B3", acuity: 3 },
  { date: "5/27/2019 03:54:31", shift: "A3", acuity: 4 },
  { date: "5/27/2019 04:25:46", shift: "B3", acuity: 2 },
  { date: "5/27/2019 05:14:30", shift: "A1", acuity: 2 },
  { date: "5/27/2019 05:28:45", shift: "B3", acuity: 3 },
  { date: "5/27/2019 05:31:00", shift: "A1", acuity: 2 },
  { date: "5/27/2019 05:37:45", shift: "A1", acuity: 3 },
  { date: "5/27/2019 06:00:33", shift: "B1", acuity: 3 },
  { date: "5/27/2019 06:19:58", shift: "A1", acuity: 2 },
  { date: "5/27/2019 06:27:33", shift: "B1", acuity: 2 },
  { date: "5/27/2019 06:29:36", shift: "A1", acuity: 2 },
  { date: "5/27/2019 07:56:36", shift: "B1", acuity: 3 },
  { date: "5/27/2019 08:16:15", shift: "A1", acuity: 3 },
  { date: "5/27/2019 08:18:17", shift: "B1", acuity: 4 },
  { date: "5/27/2019 08:32:59", shift: "C1", acuity: 3 },
  { date: "5/27/2019 08:42:14", shift: "A1", acuity: 3 },
  { date: "5/27/2019 09:01:17", shift: "C1", acuity: 2 },
  { date: "5/27/2019 09:16:03", shift: "C1", acuity: 2 },
  { date: "5/27/2019 09:16:21", shift: "B1", acuity: 3 },
  { date: "5/27/2019 09:24:16", shift: "A1", acuity: 3 },
  { date: "5/27/2019 09:24:36", shift: "C1", acuity: 3 },
  { date: "5/27/2019 09:43:25", shift: "B1", acuity: 4 },
  { date: "5/27/2019 10:03:59", shift: "D1", acuity: 3 },
  { date: "5/27/2019 10:09:23", shift: "A1", acuity: 3 },
  { date: "5/27/2019 10:09:48", shift: "C1", acuity: 3 },
  { date: "5/27/2019 10:13:36", shift: "B1", acuity: 4 },
  { date: "5/27/2019 10:50:14", shift: "D1", acuity: 2 },
  { date: "5/27/2019 11:06:25", shift: "D1", acuity: 3 },
  { date: "5/27/2019 11:06:32", shift: "A1", acuity: 3 },
  { date: "5/27/2019 11:13:15", shift: "C1", acuity: 3 },
  { date: "5/27/2019 11:23:49", shift: "B1", acuity: 3 },
  { date: "5/27/2019 11:31:51", shift: "D1", acuity: 2 },
  { date: "5/27/2019 11:38:30", shift: "A1", acuity: 3 },
  { date: "5/27/2019 11:48:52", shift: "C1", acuity: 3 },
  { date: "5/27/2019 12:05:13", shift: "B1", acuity: 2 },
  { date: "5/27/2019 12:05:42", shift: "D1", acuity: 3 },
  { date: "5/27/2019 12:21:27", shift: "A1", acuity: 3 },
  { date: "5/27/2019 12:30:22", shift: "C1", acuity: 2 },
  { date: "5/27/2019 12:46:21", shift: "B1", acuity: 2 },
  { date: "5/27/2019 12:46:32", shift: "D1", acuity: 3 },
  { date: "5/27/2019 13:08:34", shift: "A2", acuity: 2 },
  { date: "5/27/2019 13:36:26", shift: "A2", acuity: 3 },
  { date: "5/27/2019 13:36:44", shift: "A2", acuity: 3 },
  { date: "5/27/2019 13:58:22", shift: "C1", acuity: 3 },
  { date: "5/27/2019 14:11:14", shift: "B2", acuity: 3 },
  { date: "5/27/2019 14:18:58", shift: "D1", acuity: 3 },
  { date: "5/27/2019 14:20:20", shift: "A2", acuity: 3 },
  { date: "5/27/2019 14:27:21", shift: "C1", acuity: 3 },
  { date: "5/27/2019 14:38:21", shift: "B2", acuity: 2 },
  { date: "5/27/2019 14:50:54", shift: "B2", acuity: 2 },
  { date: "5/27/2019 15:15:21", shift: "D1", acuity: 3 },
  { date: "5/27/2019 15:16:36", shift: "A2", acuity: 3 },
  { date: "5/27/2019 15:21:21", shift: "C1", acuity: 2 },
  { date: "5/27/2019 15:21:36", shift: "B2", acuity: 3 },
  { date: "5/27/2019 15:35:22", shift: "D1", acuity: 3 },
  { date: "5/27/2019 15:35:42", shift: "A2", acuity: 3 },
  { date: "5/27/2019 15:38:40", shift: "C1", acuity: 2 },
  { date: "5/27/2019 15:47:37", shift: "B2", acuity: 2 },
  { date: "5/27/2019 15:50:52", shift: "D1", acuity: 2 },
  { date: "5/27/2019 15:56:15", shift: "A2", acuity: 3 },
  { date: "5/27/2019 15:57:03", shift: "C1", acuity: 3 },
  { date: "5/27/2019 15:59:45", shift: "B2", acuity: 3 },
  { date: "5/27/2019 16:07:51", shift: "D1", acuity: 4 },
  { date: "5/27/2019 16:28:29", shift: "A2", acuity: 2 },
  { date: "5/27/2019 16:28:42", shift: "C1", acuity: 2 },
  { date: "5/27/2019 16:28:52", shift: "B2", acuity: 3 },
  { date: "5/27/2019 16:38:41", shift: "C2", acuity: 2 },
  { date: "5/27/2019 16:38:58", shift: "D1", acuity: 2 },
  { date: "5/27/2019 16:48:14", shift: "A2", acuity: 3 },
  { date: "5/27/2019 17:15:15", shift: "C2", acuity: 2 },
  { date: "5/27/2019 17:19:54", shift: "C2", acuity: 3 },
  { date: "5/27/2019 17:28:09", shift: "B2", acuity: 3 },
  { date: "5/27/2019 17:45:39", shift: "D1", acuity: 3 },
  { date: "5/27/2019 18:00:25", shift: "D2", acuity: 3 },
  { date: "5/27/2019 18:07:25", shift: "A2", acuity: 2 },
  { date: "5/27/2019 18:23:12", shift: "C2", acuity: 3 },
  { date: "5/27/2019 18:29:42", shift: "B2", acuity: 3 },
  { date: "5/27/2019 18:35:20", shift: "D2", acuity: 2 },
  { date: "5/27/2019 18:43:51", shift: "D2", acuity: 3 },
  { date: "5/27/2019 18:44:04", shift: "A2", acuity: 3 },
  { date: "5/27/2019 18:54:02", shift: "C2", acuity: 7 },
  { date: "5/27/2019 19:00:16", shift: "B2", acuity: 2 },
  { date: "5/27/2019 19:00:25", shift: "D2", acuity: 2 },
  { date: "5/27/2019 19:15:40", shift: "A2", acuity: 2 },
  { date: "5/27/2019 19:24:55", shift: "C2", acuity: 3 },
  { date: "5/27/2019 19:31:59", shift: "B2", acuity: 4 },
  { date: "5/27/2019 19:32:14", shift: "D2", acuity: 3 },
  { date: "5/27/2019 19:48:37", shift: "A2", acuity: 2 },
  { date: "5/27/2019 19:48:53", shift: "C2", acuity: 4 },
  { date: "5/27/2019 19:49:55", shift: "B2", acuity: 3 },
  { date: "5/27/2019 19:59:22", shift: "D2", acuity: 2 },
  { date: "5/27/2019 20:04:52", shift: "A2", acuity: 3 },
  { date: "5/27/2019 20:15:23", shift: "C2", acuity: 2 },
  { date: "5/27/2019 20:16:11", shift: "B2", acuity: 2 },
  { date: "5/27/2019 20:26:54", shift: "D2", acuity: 2 },
  { date: "5/27/2019 20:34:08", shift: "A2", acuity: 2 },
  { date: "5/27/2019 20:38:31", shift: "C2", acuity: 4 },
  { date: "5/27/2019 20:46:54", shift: "B2", acuity: 2 },
  { date: "5/27/2019 20:47:08", shift: "D2", acuity: 2 },
  { date: "5/27/2019 20:55:22", shift: "A2", acuity: 2 },
  { date: "5/27/2019 21:03:02", shift: "A3", acuity: 2 },
  { date: "5/27/2019 21:12:51", shift: "C2", acuity: 3 },
  { date: "5/27/2019 21:16:12", shift: "B2", acuity: 2 },
  { date: "5/27/2019 21:29:28", shift: "D2", acuity: 2 },
  { date: "5/27/2019 21:48:43", shift: "A3", acuity: 3 },
  { date: "5/27/2019 21:49:29", shift: "A3", acuity: 3 },
  { date: "5/27/2019 21:53:51", shift: "C2", acuity: 7 },
  { date: "5/27/2019 21:54:06", shift: "B2", acuity: 7 },
  { date: "5/27/2019 22:00:43", shift: "B3", acuity: 2 },
  { date: "5/27/2019 22:14:28", shift: "D2", acuity: 3 },
  { date: "5/27/2019 22:15:20", shift: "A3", acuity: 2 },
  { date: "5/27/2019 22:28:54", shift: "C2", acuity: 2 },
  { date: "5/27/2019 22:46:08", shift: "B3", acuity: 3 },
  { date: "5/27/2019 23:09:30", shift: "B3", acuity: 2 },
  { date: "5/27/2019 23:16:09", shift: "D2", acuity: 2 },
  { date: "5/27/2019 23:17:23", shift: "A3", acuity: 3 },
  { date: "5/28/2019 00:00:22", shift: "B3", acuity: 3 },
  { date: "5/28/2019 00:00:47", shift: "D2", acuity: 3 },
  { date: "5/28/2019 00:01:01", shift: "A3", acuity: 2 },
  { date: "5/28/2019 00:15:02", shift: "B3", acuity: 2 },
  { date: "5/28/2019 00:16:22", shift: "D2", acuity: 2 },
  { date: "5/28/2019 00:30:22", shift: "A3", acuity: 2 },
  { date: "5/28/2019 00:47:33", shift: "B3", acuity: 3 },
  { date: "5/28/2019 00:49:39", shift: "D2", acuity: 2 },
  { date: "5/28/2019 02:00:31", shift: "A3", acuity: 4 },
  { date: "5/28/2019 02:00:43", shift: "B3", acuity: 4 },
  { date: "5/28/2019 02:01:03", shift: "A3", acuity: 3 },
  { date: "5/28/2019 02:11:18", shift: "B3", acuity: 3 },
  { date: "5/28/2019 02:34:57", shift: "A3", acuity: 3 },
  { date: "5/28/2019 02:43:52", shift: "B3", acuity: 3 },
  { date: "5/28/2019 03:45:15", shift: "A3", acuity: 4 },
  { date: "5/28/2019 03:46:00", shift: "B3", acuity: 3 },
  { date: "5/28/2019 03:59:55", shift: "A3", acuity: 3 },
  { date: "5/28/2019 04:00:18", shift: "B3", acuity: 3 },
  { date: "5/28/2019 04:09:45", shift: "A3", acuity: 2 },
  { date: "5/28/2019 04:10:53", shift: "B3", acuity: 5 },
  { date: "5/28/2019 04:23:38", shift: "A3", acuity: 7 },
  { date: "5/28/2019 04:32:33", shift: "B3", acuity: 3 },
  { date: "5/28/2019 05:08:30", shift: "A1", acuity: 3 },
  { date: "5/28/2019 05:08:50", shift: "B3", acuity: 2 },
  { date: "5/28/2019 05:09:26", shift: "A1", acuity: 3 },
  { date: "5/28/2019 06:28:09", shift: "A1", acuity: 4 },
  { date: "5/28/2019 06:30:00", shift: "A1", acuity: 7 },
  { date: "5/28/2019 06:41:38", shift: "B1", acuity: 4 },
  { date: "5/28/2019 06:43:37", shift: "B1", acuity: 2 },
  { date: "5/28/2019 07:33:28", shift: "B1", acuity: 3 },
  { date: "5/28/2019 07:33:49", shift: "A1", acuity: 4 },
  { date: "5/28/2019 08:17:54", shift: "B1", acuity: 2 },
  { date: "5/28/2019 08:44:45", shift: "C1", acuity: 3 },
  { date: "5/28/2019 08:46:20", shift: "A1", acuity: 4 },
  { date: "5/28/2019 08:58:39", shift: "B1", acuity: 4 },
  { date: "5/28/2019 09:11:23", shift: "C1", acuity: 2 },
  { date: "5/28/2019 09:11:44", shift: "C1", acuity: 7 },
  { date: "5/28/2019 09:16:59", shift: "A1", acuity: 2 },
  { date: "5/28/2019 09:35:25", shift: "B1", acuity: 2 },
  { date: "5/28/2019 09:44:22", shift: "C1", acuity: 2 },
  { date: "5/28/2019 09:44:37", shift: "A1", acuity: 2 },
  { date: "5/28/2019 09:45:01", shift: "B1", acuity: 3 },
  { date: "5/28/2019 10:10:22", shift: "D1", acuity: 2 },
  { date: "5/28/2019 10:15:50", shift: "C1", acuity: 2 },
  { date: "5/28/2019 10:17:37", shift: "A1", acuity: 7 },
  { date: "5/28/2019 10:25:51", shift: "B1", acuity: 7 },
  { date: "5/28/2019 10:49:57", shift: "D1", acuity: 2 },
  { date: "5/28/2019 11:05:23", shift: "D1", acuity: 7 },
  { date: "5/28/2019 11:24:28", shift: "C1", acuity: 4 },
  { date: "5/28/2019 11:24:46", shift: "A1", acuity: 3 },
  { date: "5/28/2019 11:25:40", shift: "B1", acuity: 3 },
  { date: "5/28/2019 11:52:42", shift: "D1", acuity: 2 },
  { date: "5/28/2019 11:53:05", shift: "C1", acuity: 2 },
  { date: "5/28/2019 11:53:27", shift: "A1", acuity: 3 },
  { date: "5/28/2019 12:00:15", shift: "B1", acuity: 3 },
  { date: "5/28/2019 12:27:13", shift: "D1", acuity: 7 },
  { date: "5/28/2019 12:28:09", shift: "C1", acuity: 2 },
  { date: "5/28/2019 12:28:59", shift: "A1", acuity: 7 },
  { date: "5/28/2019 13:05:57", shift: "A2", acuity: 2 },
  { date: "5/28/2019 13:23:36", shift: "B1", acuity: 3 },
  { date: "5/28/2019 13:32:38", shift: "A2", acuity: 2 },
  { date: "5/28/2019 13:43:28", shift: "A2", acuity: 3 },
  { date: "5/28/2019 13:49:21", shift: "D1", acuity: 7 },
  { date: "5/28/2019 13:59:06", shift: "C1", acuity: 2 },
  { date: "5/28/2019 14:15:53", shift: "B2", acuity: 3 },
  { date: "5/28/2019 14:33:19", shift: "B2", acuity: 2 },
  { date: "5/28/2019 14:59:35", shift: "B2", acuity: 3 },
  { date: "5/28/2019 15:03:42", shift: "A2", acuity: 2 },
  { date: "5/28/2019 15:07:28", shift: "D1", acuity: 2 },
  { date: "5/28/2019 15:18:12", shift: "C1", acuity: 3 },
  { date: "5/28/2019 15:30:47", shift: "B2", acuity: 4 },
  { date: "5/28/2019 15:31:12", shift: "A2", acuity: 2 },
  { date: "5/28/2019 15:33:22", shift: "D1", acuity: 3 },
  { date: "5/28/2019 15:33:53", shift: "C1", acuity: 3 },
  { date: "5/28/2019 15:34:13", shift: "B2", acuity: 2 },
  { date: "5/28/2019 15:50:02", shift: "A2", acuity: 3 },
  { date: "5/28/2019 15:59:59", shift: "D1", acuity: 3 },
  { date: "5/28/2019 16:00:25", shift: "C1", acuity: 2 },
  { date: "5/28/2019 16:00:51", shift: "B2", acuity: 7 },
  { date: "5/28/2019 16:16:34", shift: "A2", acuity: 2 },
  { date: "5/28/2019 16:27:37", shift: "D1", acuity: 3 },
  { date: "5/28/2019 16:52:17", shift: "C2", acuity: 2 },
  { date: "5/28/2019 16:55:48", shift: "B2", acuity: 3 },
  { date: "5/28/2019 16:56:07", shift: "A2", acuity: 3 },
  { date: "5/28/2019 17:03:26", shift: "C2", acuity: 3 },
  { date: "5/28/2019 17:03:50", shift: "C2", acuity: 2 },
  { date: "5/28/2019 17:04:11", shift: "D1", acuity: 3 },
  { date: "5/28/2019 17:13:06", shift: "B2", acuity: 2 },
  { date: "5/28/2019 17:13:43", shift: "A2", acuity: 2 },
  { date: "5/28/2019 17:23:35", shift: "C2", acuity: 3 },
  { date: "5/28/2019 17:24:01", shift: "D1", acuity: 3 },
  { date: "5/28/2019 17:24:30", shift: "B2", acuity: 2 },
  { date: "5/28/2019 17:38:53", shift: "A2", acuity: 3 },
  { date: "5/28/2019 17:53:30", shift: "C2", acuity: 2 },
  { date: "5/28/2019 18:01:22", shift: "D2", acuity: 2 },
  { date: "5/28/2019 18:12:10", shift: "B2", acuity: 3 },
  { date: "5/28/2019 18:27:55", shift: "A2", acuity: 2 },
  { date: "5/28/2019 18:42:23", shift: "D2", acuity: 2 },
  { date: "5/28/2019 19:04:17", shift: "D2", acuity: 2 },
  { date: "5/28/2019 19:13:44", shift: "C2", acuity: 3 },
  { date: "5/28/2019 19:34:07", shift: "B2", acuity: 2 },
  { date: "5/28/2019 19:35:31", shift: "A2", acuity: 3 },
  { date: "5/28/2019 19:39:18", shift: "D2", acuity: 3 },
  { date: "5/28/2019 19:40:00", shift: "C2", acuity: 3 },
  { date: "5/28/2019 19:42:31", shift: "B2", acuity: 3 },
  { date: "5/28/2019 19:43:50", shift: "A2", acuity: 3 },
  { date: "5/28/2019 19:44:10", shift: "D2", acuity: 3 },
  { date: "5/28/2019 20:04:45", shift: "C2", acuity: 3 },
  { date: "5/28/2019 20:04:58", shift: "B2", acuity: 3 },
  { date: "5/28/2019 20:19:12", shift: "A2", acuity: 3 },
  { date: "5/28/2019 20:28:06", shift: "D2", acuity: 3 },
  { date: "5/28/2019 20:29:01", shift: "C2", acuity: 3 },
  { date: "5/28/2019 20:38:57", shift: "B2", acuity: 2 },
  { date: "5/28/2019 20:43:39", shift: "A2", acuity: 3 },
  { date: "5/28/2019 20:57:37", shift: "D2", acuity: 2 },
  { date: "5/28/2019 21:05:21", shift: "A3", acuity: 3 },
  { date: "5/28/2019 21:05:40", shift: "C2", acuity: 3 },
  { date: "5/28/2019 21:22:10", shift: "B2", acuity: 2 },
  { date: "5/28/2019 21:23:39", shift: "D2", acuity: 3 },
  { date: "5/28/2019 21:30:45", shift: "A3", acuity: 3 },
  { date: "5/28/2019 21:31:05", shift: "A3", acuity: 3 },
  { date: "5/28/2019 21:46:37", shift: "C2", acuity: 3 },
  { date: "5/28/2019 21:59:40", shift: "B2", acuity: 3 },
  { date: "5/28/2019 22:00:10", shift: "B3", acuity: 3 },
  { date: "5/28/2019 22:10:13", shift: "D2", acuity: 2 },
  { date: "5/28/2019 22:11:01", shift: "A3", acuity: 4 },
  { date: "5/28/2019 22:20:31", shift: "C2", acuity: 2 },
  { date: "5/28/2019 22:24:14", shift: "B3", acuity: 3 },
  { date: "5/28/2019 22:46:50", shift: "B3", acuity: 3 },
  { date: "5/28/2019 22:51:50", shift: "B3", acuity: 2 },
  { date: "5/28/2019 23:01:06", shift: "D2", acuity: 4 },
  { date: "5/28/2019 23:07:13", shift: "A3", acuity: 3 },
  { date: "5/28/2019 23:07:27", shift: "C2", acuity: 3 },
  { date: "5/28/2019 23:17:24", shift: "B3", acuity: 4 },
  { date: "5/28/2019 23:17:39", shift: "D2", acuity: 3 },
  { date: "5/28/2019 23:28:47", shift: "A3", acuity: 3 },
  { date: "5/28/2019 23:33:54", shift: "C2", acuity: 3 },
  { date: "5/28/2019 23:36:14", shift: "B3", acuity: 4 },
  { date: "5/28/2019 23:54:44", shift: "D2", acuity: 4 },
  { date: "5/29/2019 00:12:42", shift: "A3", acuity: 4 },
  { date: "5/29/2019 00:26:01", shift: "B3", acuity: 3 },
  { date: "5/29/2019 00:26:22", shift: "D2", acuity: 4 },
  { date: "5/29/2019 00:35:11", shift: "A3", acuity: 3 },
  { date: "5/29/2019 00:45:39", shift: "B3", acuity: 3 },
  { date: "5/29/2019 00:56:06", shift: "D2", acuity: 3 },
  { date: "5/29/2019 01:02:54", shift: "A3", acuity: 4 },
  { date: "5/29/2019 01:04:25", shift: "B3", acuity: 3 },
  { date: "5/29/2019 02:04:54", shift: "A3", acuity: 3 },
  { date: "5/29/2019 02:05:08", shift: "B3", acuity: 4 },
  { date: "5/29/2019 02:41:25", shift: "A3", acuity: 3 },
  { date: "5/29/2019 02:57:37", shift: "B3", acuity: 2 },
  { date: "5/29/2019 03:03:02", shift: "A3", acuity: 3 },
  { date: "5/29/2019 03:06:04", shift: "B3", acuity: 4 },
  { date: "5/29/2019 03:28:03", shift: "A3", acuity: 4 },
  { date: "5/29/2019 04:13:49", shift: "B3", acuity: 5 },
  { date: "5/29/2019 04:38:39", shift: "A3", acuity: 4 },
  { date: "5/29/2019 05:24:30", shift: "A1", acuity: 4 },
  { date: "5/29/2019 05:26:06", shift: "B3", acuity: 4 },
  { date: "5/29/2019 05:53:31", shift: "A1", acuity: 4 },
  { date: "5/29/2019 06:08:25", shift: "A1", acuity: 3 },
  { date: "5/29/2019 06:31:59", shift: "B1", acuity: 3 },
  { date: "5/29/2019 06:56:30", shift: "B1", acuity: 3 },
  { date: "5/29/2019 07:40:50", shift: "B1", acuity: 3 },
  { date: "5/29/2019 08:04:27", shift: "A1", acuity: 3 },
  { date: "5/29/2019 08:19:04", shift: "B1", acuity: 3 },
  { date: "5/29/2019 08:39:50", shift: "C1", acuity: 3 },
  { date: "5/29/2019 08:40:11", shift: "A1", acuity: 3 },
  { date: "5/29/2019 08:43:14", shift: "B1", acuity: 2 },
  { date: "5/29/2019 08:57:38", shift: "C1", acuity: 3 },
  { date: "5/29/2019 08:58:01", shift: "A1", acuity: 3 },
  { date: "5/29/2019 08:58:51", shift: "B1", acuity: 3 },
  { date: "5/29/2019 09:17:25", shift: "C1", acuity: 3 },
  { date: "5/29/2019 09:24:34", shift: "C1", acuity: 3 },
  { date: "5/29/2019 09:29:23", shift: "A1", acuity: 3 },
  { date: "5/29/2019 09:34:59", shift: "B1", acuity: 3 },
  { date: "5/29/2019 09:35:19", shift: "C1", acuity: 3 },
  { date: "5/29/2019 09:42:07", shift: "A1", acuity: 3 },
  { date: "5/29/2019 09:44:49", shift: "B1", acuity: 2 },
  { date: "5/29/2019 09:58:09", shift: "C1", acuity: 3 },
  { date: "5/29/2019 10:06:00", shift: "D1", acuity: 3 },
  { date: "5/29/2019 10:28:10", shift: "A1", acuity: 3 },
  { date: "5/29/2019 10:39:19", shift: "D1", acuity: 3 },
  { date: "5/29/2019 10:49:03", shift: "D1", acuity: 2 },
  { date: "5/29/2019 11:11:51", shift: "B1", acuity: 2 },
  { date: "5/29/2019 11:25:12", shift: "C1", acuity: 3 },
  { date: "5/29/2019 11:30:22", shift: "A1", acuity: 3 },
  { date: "5/29/2019 11:34:18", shift: "D1", acuity: 2 },
  { date: "5/29/2019 11:41:08", shift: "B1", acuity: 3 },
  { date: "5/29/2019 11:41:25", shift: "C1", acuity: 3 },
  { date: "5/29/2019 11:56:26", shift: "A1", acuity: 2 },
  { date: "5/29/2019 11:56:47", shift: "D1", acuity: 3 },
  { date: "5/29/2019 12:08:04", shift: "B1", acuity: 3 },
  { date: "5/29/2019 12:08:15", shift: "C1", acuity: 3 },
  { date: "5/29/2019 12:22:55", shift: "A1", acuity: 3 },
  { date: "5/29/2019 12:39:21", shift: "D1", acuity: 3 },
  { date: "5/29/2019 12:41:43", shift: "B1", acuity: 3 },
  { date: "5/29/2019 12:58:51", shift: "C1", acuity: 3 },
  { date: "5/29/2019 13:06:23", shift: "A2", acuity: 3 },
  { date: "5/29/2019 13:31:48", shift: "A2", acuity: 3 },
  { date: "5/29/2019 13:32:04", shift: "A2", acuity: 3 },
  { date: "5/29/2019 13:54:16", shift: "D1", acuity: 4 },
  { date: "5/29/2019 13:57:39", shift: "B1", acuity: 2 },
  { date: "5/29/2019 14:02:29", shift: "B2", acuity: 2 },
  { date: "5/29/2019 14:19:00", shift: "C1", acuity: 3 },
  { date: "5/29/2019 14:19:12", shift: "A2", acuity: 2 },
  { date: "5/29/2019 14:48:00", shift: "B2", acuity: 3 },
  { date: "5/29/2019 14:48:24", shift: "B2", acuity: 3 },
  { date: "5/29/2019 14:48:35", shift: "D1", acuity: 3 },
  { date: "5/29/2019 14:48:48", shift: "C1", acuity: 3 },
  { date: "5/29/2019 15:12:03", shift: "A2", acuity: 2 },
  { date: "5/29/2019 15:12:16", shift: "B2", acuity: 3 },
  { date: "5/29/2019 15:18:37", shift: "D1", acuity: 2 },
  { date: "5/29/2019 15:28:41", shift: "C1", acuity: 2 },
  { date: "5/29/2019 15:42:36", shift: "A2", acuity: 3 },
  { date: "5/29/2019 15:45:50", shift: "B2", acuity: 3 },
  { date: "5/29/2019 15:46:49", shift: "D1", acuity: 3 },
  { date: "5/29/2019 16:17:56", shift: "C1", acuity: 2 },
  { date: "5/29/2019 16:18:12", shift: "A2", acuity: 3 },
  { date: "5/29/2019 16:25:16", shift: "B2", acuity: 3 },
  { date: "5/29/2019 16:30:29", shift: "C2", acuity: 3 },
  { date: "5/29/2019 16:31:00", shift: "D1", acuity: 2 },
  { date: "5/29/2019 16:44:42", shift: "A2", acuity: 2 },
  { date: "5/29/2019 16:58:43", shift: "B2", acuity: 3 },
  { date: "5/29/2019 17:15:53", shift: "C2", acuity: 3 },
  { date: "5/29/2019 17:23:11", shift: "C2", acuity: 3 },
  { date: "5/29/2019 17:29:26", shift: "D1", acuity: 2 },
  { date: "5/29/2019 17:29:38", shift: "A2", acuity: 2 },
  { date: "5/29/2019 17:29:54", shift: "B2", acuity: 2 },
  { date: "5/29/2019 17:35:53", shift: "C2", acuity: 2 },
  { date: "5/29/2019 17:49:00", shift: "D1", acuity: 3 },
  { date: "5/29/2019 17:51:09", shift: "A2", acuity: 3 },
  { date: "5/29/2019 17:59:11", shift: "B2", acuity: 2 },
  { date: "5/29/2019 18:14:13", shift: "D2", acuity: 2 },
  { date: "5/29/2019 18:14:35", shift: "C2", acuity: 3 },
  { date: "5/29/2019 18:14:44", shift: "A2", acuity: 3 },
  { date: "5/29/2019 18:14:53", shift: "B2", acuity: 3 },
  { date: "5/29/2019 18:45:27", shift: "D2", acuity: 3 },
  { date: "5/29/2019 18:45:39", shift: "D2", acuity: 3 },
  { date: "5/29/2019 18:53:23", shift: "C2", acuity: 3 },
  { date: "5/29/2019 18:57:13", shift: "A2", acuity: 2 },
  { date: "5/29/2019 19:00:41", shift: "B2", acuity: 3 },
  { date: "5/29/2019 19:08:08", shift: "D2", acuity: 2 },
  { date: "5/29/2019 19:58:41", shift: "C2", acuity: 2 },
  { date: "5/29/2019 19:58:53", shift: "A2", acuity: 3 },
  { date: "5/29/2019 19:59:38", shift: "B2", acuity: 2 },
  { date: "5/29/2019 20:00:30", shift: "D2", acuity: 2 },
  { date: "5/29/2019 20:02:38", shift: "C2", acuity: 2 },
  { date: "5/29/2019 20:22:11", shift: "A2", acuity: 2 },
  { date: "5/29/2019 20:25:13", shift: "B2", acuity: 2 },
  { date: "5/29/2019 20:40:59", shift: "D2", acuity: 3 },
  { date: "5/29/2019 20:42:53", shift: "C2", acuity: 3 },
  { date: "5/29/2019 20:55:48", shift: "A2", acuity: 2 },
  { date: "5/29/2019 21:02:48", shift: "A3", acuity: 2 },
  { date: "5/29/2019 21:22:11", shift: "B2", acuity: 2 },
  { date: "5/29/2019 21:22:38", shift: "D2", acuity: 3 },
  { date: "5/29/2019 21:37:49", shift: "A3", acuity: 2 },
  { date: "5/29/2019 22:00:08", shift: "A3", acuity: 4 },
  { date: "5/29/2019 22:10:20", shift: "B3", acuity: 4 },
  { date: "5/29/2019 22:10:33", shift: "C2", acuity: 2 },
  { date: "5/29/2019 22:21:25", shift: "D2", acuity: 3 },
  { date: "5/29/2019 22:32:20", shift: "B3", acuity: 3 },
  { date: "5/29/2019 22:45:29", shift: "B3", acuity: 3 },
  { date: "5/29/2019 22:45:45", shift: "A3", acuity: 2 },
  { date: "5/29/2019 22:58:39", shift: "C2", acuity: 3 },
  { date: "5/29/2019 22:58:52", shift: "D2", acuity: 2 },
  { date: "5/29/2019 23:02:56", shift: "B3", acuity: 3 },
  { date: "5/29/2019 23:22:09", shift: "A3", acuity: 2 },
  { date: "5/29/2019 23:22:30", shift: "C2", acuity: 3 },
  { date: "5/29/2019 23:28:05", shift: "D2", acuity: 3 },
  { date: "5/29/2019 23:40:43", shift: "B3", acuity: 2 },
  { date: "5/29/2019 23:43:58", shift: "A3", acuity: 4 },
  { date: "5/29/2019 23:54:42", shift: "C2", acuity: 2 },
  { date: "5/30/2019 00:06:40", shift: "D2", acuity: 2 },
  { date: "5/30/2019 00:17:44", shift: "B3", acuity: 3 },
  { date: "5/30/2019 00:25:44", shift: "A3", acuity: 3 },
  { date: "5/30/2019 00:25:59", shift: "D2", acuity: 3 },
  { date: "5/30/2019 00:39:55", shift: "B3", acuity: 3 },
  { date: "5/30/2019 00:56:12", shift: "A3", acuity: 3 },
  { date: "5/30/2019 01:01:10", shift: "D2", acuity: 3 },
  { date: "5/30/2019 01:12:58", shift: "B3", acuity: 3 },
  { date: "5/30/2019 01:37:21", shift: "A3", acuity: 3 },
  { date: "5/30/2019 01:38:07", shift: "B3", acuity: 3 },
  { date: "5/30/2019 02:08:51", shift: "A3", acuity: 3 },
  { date: "5/30/2019 02:42:54", shift: "B3", acuity: 4 },
  { date: "5/30/2019 02:52:35", shift: "A3", acuity: 2 },
  { date: "5/30/2019 03:03:05", shift: "B3", acuity: 3 },
  { date: "5/30/2019 03:18:50", shift: "A3", acuity: 3 },
  { date: "5/30/2019 03:19:07", shift: "B3", acuity: 2 },
  { date: "5/30/2019 03:19:20", shift: "A3", acuity: 3 },
  { date: "5/30/2019 03:38:21", shift: "B3", acuity: 3 },
  { date: "5/30/2019 03:46:40", shift: "A3", acuity: 5 },
  { date: "5/30/2019 04:06:22", shift: "B3", acuity: 4 },
  { date: "5/30/2019 04:21:00", shift: "A3", acuity: 3 },
  { date: "5/30/2019 04:32:52", shift: "B3", acuity: 4 },
  { date: "5/30/2019 05:00:36", shift: "A1", acuity: 3 },
  { date: "5/30/2019 05:30:18", shift: "A1", acuity: 2 },
  { date: "5/30/2019 05:48:31", shift: "A1", acuity: 2 },
  { date: "5/30/2019 06:22:23", shift: "B1", acuity: 3 },
  { date: "5/30/2019 06:44:49", shift: "B1", acuity: 3 },
  { date: "5/30/2019 07:04:34", shift: "B1", acuity: 3 },
  { date: "5/30/2019 08:06:38", shift: "A1", acuity: 3 },
  { date: "5/30/2019 08:28:02", shift: "B1", acuity: 3 },
  { date: "5/30/2019 08:28:16", shift: "A1", acuity: 4 },
  { date: "5/30/2019 08:38:52", shift: "C1", acuity: 3 },
  { date: "5/30/2019 08:39:43", shift: "B1", acuity: 3 },
  { date: "5/30/2019 08:43:00", shift: "A1", acuity: 2 },
  { date: "5/30/2019 08:48:43", shift: "C1", acuity: 3 },
  { date: "5/30/2019 09:13:13", shift: "C1", acuity: 3 },
  { date: "5/30/2019 09:42:42", shift: "C1", acuity: 3 },
  { date: "5/30/2019 09:58:25", shift: "B1", acuity: 2 },
  { date: "5/30/2019 10:08:11", shift: "D1", acuity: 3 },
  { date: "5/30/2019 10:37:41", shift: "D1", acuity: 3 },
  { date: "5/30/2019 10:44:50", shift: "D1", acuity: 3 },
  { date: "5/30/2019 10:50:31", shift: "A1", acuity: 3 },
  { date: "5/30/2019 10:51:32", shift: "C1", acuity: 2 },
  { date: "5/30/2019 11:14:34", shift: "B1", acuity: 3 },
  { date: "5/30/2019 11:48:28", shift: "D1", acuity: 3 },
  { date: "5/30/2019 11:54:10", shift: "A1", acuity: 2 },
  { date: "5/30/2019 12:07:18", shift: "C1", acuity: 3 },
  { date: "5/30/2019 12:16:13", shift: "B1", acuity: 3 },
  { date: "5/30/2019 12:26:00", shift: "D1", acuity: 3 },
  { date: "5/30/2019 12:28:49", shift: "A1", acuity: 3 },
  { date: "5/30/2019 12:31:40", shift: "C1", acuity: 3 },
  { date: "5/30/2019 12:39:37", shift: "B1", acuity: 2 },
  { date: "5/30/2019 12:50:43", shift: "D1", acuity: 3 },
  { date: "5/30/2019 12:51:27", shift: "A1", acuity: 2 },
  { date: "5/30/2019 12:52:26", shift: "C1", acuity: 3 },
  { date: "5/30/2019 12:58:41", shift: "B1", acuity: 2 },
  { date: "5/30/2019 13:00:58", shift: "A2", acuity: 2 },
  { date: "5/30/2019 13:01:07", shift: "D1", acuity: 4 },
  { date: "5/30/2019 13:33:42", shift: "A2", acuity: 2 },
  { date: "5/30/2019 13:33:53", shift: "A2", acuity: 2 },
  { date: "5/30/2019 13:50:51", shift: "C1", acuity: 3 },
  { date: "5/30/2019 14:01:28", shift: "B2", acuity: 2 },
  { date: "5/30/2019 14:02:32", shift: "D1", acuity: 2 },
  { date: "5/30/2019 14:12:35", shift: "A2", acuity: 3 },
  { date: "5/30/2019 14:18:47", shift: "C1", acuity: 3 },
  { date: "5/30/2019 14:18:57", shift: "B2", acuity: 3 },
  { date: "5/30/2019 14:36:59", shift: "B2", acuity: 2 },
  { date: "5/30/2019 14:49:01", shift: "B2", acuity: 3 },
  { date: "5/30/2019 15:09:53", shift: "D1", acuity: 3 },
  { date: "5/30/2019 15:17:25", shift: "A2", acuity: 3 },
  { date: "5/30/2019 15:22:57", shift: "C1", acuity: 3 },
  { date: "5/30/2019 15:23:31", shift: "B2", acuity: 3 },
  { date: "5/30/2019 15:27:40", shift: "D1", acuity: 2 },
  { date: "5/30/2019 16:00:13", shift: "A2", acuity: 3 },
  { date: "5/30/2019 16:25:19", shift: "C1", acuity: 3 },
  { date: "5/30/2019 16:38:29", shift: "C2", acuity: 2 },
  { date: "5/30/2019 16:38:40", shift: "B2", acuity: 2 },
  { date: "5/30/2019 16:49:48", shift: "D1", acuity: 2 },
  { date: "5/30/2019 17:06:31", shift: "C2", acuity: 3 },
  { date: "5/30/2019 17:12:54", shift: "C2", acuity: 3 },
  { date: "5/30/2019 17:14:55", shift: "A2", acuity: 3 },
  { date: "5/30/2019 17:24:51", shift: "B2", acuity: 3 },
  { date: "5/30/2019 17:29:27", shift: "D1", acuity: 3 },
  { date: "5/30/2019 17:39:57", shift: "C2", acuity: 3 },
  { date: "5/30/2019 18:07:36", shift: "D2", acuity: 3 },
  { date: "5/30/2019 18:08:47", shift: "A2", acuity: 3 },
  { date: "5/30/2019 18:09:02", shift: "B2", acuity: 2 },
  { date: "5/30/2019 18:21:10", shift: "C2", acuity: 2 },
  { date: "5/30/2019 18:25:10", shift: "D2", acuity: 2 },
  { date: "5/30/2019 18:33:11", shift: "D2", acuity: 4 },
  { date: "5/30/2019 18:33:20", shift: "D2", acuity: 3 },
  { date: "5/30/2019 18:38:01", shift: "A2", acuity: 2 },
  { date: "5/30/2019 18:51:25", shift: "B2", acuity: 3 },
  { date: "5/30/2019 18:57:58", shift: "C2", acuity: 2 },
  { date: "5/30/2019 19:03:56", shift: "D2", acuity: 7 },
  { date: "5/30/2019 19:05:39", shift: "A2", acuity: 2 },
  { date: "5/30/2019 19:11:33", shift: "B2", acuity: 2 },
  { date: "5/30/2019 19:11:49", shift: "C2", acuity: 7 },
  { date: "5/30/2019 19:18:03", shift: "D2", acuity: 3 },
  { date: "5/30/2019 19:25:03", shift: "A2", acuity: 2 },
  { date: "5/30/2019 19:29:47", shift: "B2", acuity: 2 },
  { date: "5/30/2019 19:51:22", shift: "C2", acuity: 3 },
  { date: "5/30/2019 20:05:13", shift: "D2", acuity: 7 },
  { date: "5/30/2019 20:08:50", shift: "A2", acuity: 2 },
  { date: "5/30/2019 20:16:04", shift: "B2", acuity: 3 },
  { date: "5/30/2019 20:30:05", shift: "C2", acuity: 2 },
  { date: "5/30/2019 20:32:09", shift: "D2", acuity: 2 },
  { date: "5/30/2019 20:58:55", shift: "A2", acuity: 7 },
  { date: "5/30/2019 20:59:33", shift: "B2", acuity: 3 },
  { date: "5/30/2019 21:14:10", shift: "A3", acuity: 3 },
  { date: "5/30/2019 21:24:22", shift: "C2", acuity: 2 },
  { date: "5/30/2019 21:33:02", shift: "A3", acuity: 3 },
  { date: "5/30/2019 21:45:34", shift: "A3", acuity: 3 },
  { date: "5/30/2019 21:53:49", shift: "D2", acuity: 3 },
  { date: "5/30/2019 22:02:35", shift: "B3", acuity: 3 },
  { date: "5/30/2019 22:05:14", shift: "C2", acuity: 7 },
  { date: "5/30/2019 22:18:24", shift: "A3", acuity: 2 },
  { date: "5/30/2019 22:32:19", shift: "B3", acuity: 3 },
  { date: "5/30/2019 22:52:00", shift: "B3", acuity: 2 },
  { date: "5/30/2019 22:53:45", shift: "D2", acuity: 3 },
  { date: "5/30/2019 22:56:30", shift: "C2", acuity: 2 },
  { date: "5/30/2019 22:56:44", shift: "A3", acuity: 3 },
  { date: "5/30/2019 23:06:13", shift: "B3", acuity: 2 },
  { date: "5/30/2019 23:14:36", shift: "D2", acuity: 2 },
  { date: "5/30/2019 23:15:06", shift: "C2", acuity: 4 },
  { date: "5/30/2019 23:22:33", shift: "A3", acuity: 3 },
  { date: "5/30/2019 23:25:04", shift: "B3", acuity: 4 },
  { date: "5/30/2019 23:26:33", shift: "D2", acuity: 4 },
  { date: "5/30/2019 23:36:17", shift: "C2", acuity: 4 },
  { date: "5/30/2019 23:37:10", shift: "A3", acuity: 3 },
  { date: "5/30/2019 23:50:39", shift: "B3", acuity: 3 },
  { date: "5/30/2019 23:59:24", shift: "D2", acuity: 2 },
  { date: "5/31/2019 00:34:44", shift: "A3", acuity: 4 },
  { date: "5/31/2019 00:47:46", shift: "B3", acuity: 3 },
  { date: "5/31/2019 00:57:44", shift: "D2", acuity: 3 },
  { date: "5/31/2019 01:26:08", shift: "A3", acuity: 3 },
  { date: "5/31/2019 01:59:58", shift: "B3", acuity: 7 },
  { date: "5/31/2019 02:04:59", shift: "A3", acuity: 4 },
  { date: "5/31/2019 02:16:27", shift: "B3", acuity: 3 },
  { date: "5/31/2019 02:27:03", shift: "A3", acuity: 7 },
  { date: "5/31/2019 02:46:28", shift: "B3", acuity: 7 },
  { date: "5/31/2019 03:08:40", shift: "A3", acuity: 2 },
  { date: "5/31/2019 03:21:58", shift: "B3", acuity: 3 },
  { date: "5/31/2019 03:50:18", shift: "A3", acuity: 3 },
  { date: "5/31/2019 04:05:17", shift: "B3", acuity: 4 },
  { date: "5/31/2019 04:10:58", shift: "A3", acuity: 4 },
  { date: "5/31/2019 04:11:19", shift: "B3", acuity: 4 },
  { date: "5/31/2019 04:38:07", shift: "A3", acuity: 2 },
  { date: "5/31/2019 05:00:08", shift: "A1", acuity: 3 },
  { date: "5/31/2019 05:08:55", shift: "B3", acuity: 2 },
  { date: "5/31/2019 05:40:08", shift: "A1", acuity: 2 },
  { date: "5/31/2019 06:04:29", shift: "A1", acuity: 2 },
  { date: "5/31/2019 06:36:00", shift: "B1", acuity: 2 },
  { date: "5/31/2019 06:39:40", shift: "B1", acuity: 3 },
  { date: "5/31/2019 07:04:30", shift: "B1", acuity: 3 },
  { date: "5/31/2019 07:16:51", shift: "A1", acuity: 4 },
  { date: "5/31/2019 07:31:23", shift: "B1", acuity: 4 },
  { date: "5/31/2019 07:34:33", shift: "A1", acuity: 3 },
  { date: "5/31/2019 07:57:44", shift: "B1", acuity: 2 },
  { date: "5/31/2019 08:05:20", shift: "A1", acuity: 3 },
  { date: "5/31/2019 08:07:13", shift: "B1", acuity: 3 },
  { date: "5/31/2019 08:32:13", shift: "C1", acuity: 4 },
  { date: "5/31/2019 08:32:33", shift: "A1", acuity: 3 },
  { date: "5/31/2019 09:12:34", shift: "C1", acuity: 7 },
  { date: "5/31/2019 09:12:50", shift: "C1", acuity: 3 },
  { date: "5/31/2019 09:15:43", shift: "B1", acuity: 3 },
  { date: "5/31/2019 09:18:33", shift: "A1", acuity: 2 },
  { date: "5/31/2019 09:19:55", shift: "C1", acuity: 4 },
  { date: "5/31/2019 09:28:14", shift: "B1", acuity: 2 },
  { date: "5/31/2019 09:57:28", shift: "A1", acuity: 2 },
  { date: "5/31/2019 10:11:52", shift: "D1", acuity: 4 },
  { date: "5/31/2019 10:12:17", shift: "C1", acuity: 2 },
  { date: "5/31/2019 10:22:49", shift: "B1", acuity: 3 },
  { date: "5/31/2019 10:23:09", shift: "A1", acuity: 3 },
  { date: "5/31/2019 10:35:11", shift: "D1", acuity: 2 },
  { date: "5/31/2019 10:49:28", shift: "D1", acuity: 3 },
  { date: "5/31/2019 10:57:10", shift: "C1", acuity: 2 },
  { date: "5/31/2019 11:11:24", shift: "B1", acuity: 2 },
  { date: "5/31/2019 11:25:38", shift: "A1", acuity: 7 },
  { date: "5/31/2019 11:31:53", shift: "D1", acuity: 7 },
  { date: "5/31/2019 11:33:38", shift: "C1", acuity: 3 },
  { date: "5/31/2019 11:34:11", shift: "B1", acuity: 3 },
  { date: "5/31/2019 11:40:14", shift: "A1", acuity: 3 },
  { date: "5/31/2019 11:46:48", shift: "D1", acuity: 3 },
  { date: "5/31/2019 11:52:36", shift: "C1", acuity: 2 },
  { date: "5/31/2019 11:55:44", shift: "B1", acuity: 2 },
  { date: "5/31/2019 12:20:00", shift: "A1", acuity: 7 },
  { date: "5/31/2019 12:27:54", shift: "D1", acuity: 2 },
  { date: "5/31/2019 12:28:32", shift: "C1", acuity: 2 },
  { date: "5/31/2019 12:41:27", shift: "B1", acuity: 3 },
  { date: "5/31/2019 12:42:22", shift: "A1", acuity: 2 },
  { date: "5/31/2019 12:59:44", shift: "D1", acuity: 2 },
  { date: "5/31/2019 13:00:34", shift: "A2", acuity: 4 },
  { date: "5/31/2019 13:11:31", shift: "C1", acuity: 2 },
  { date: "5/31/2019 13:21:22", shift: "B1", acuity: 2 },
  { date: "5/31/2019 13:26:54", shift: "D1", acuity: 3 },
  { date: "5/31/2019 13:47:09", shift: "A2", acuity: 2 },
  { date: "5/31/2019 13:49:37", shift: "A2", acuity: 3 },
  { date: "5/31/2019 13:58:55", shift: "C1", acuity: 3 },
  { date: "5/31/2019 14:07:05", shift: "B2", acuity: 3 },
  { date: "5/31/2019 14:07:34", shift: "D1", acuity: 2 },
  { date: "5/31/2019 14:39:51", shift: "B2", acuity: 3 },
  { date: "5/31/2019 14:40:27", shift: "B2", acuity: 2 },
  { date: "5/31/2019 14:59:41", shift: "A2", acuity: 7 },
  { date: "5/31/2019 15:01:44", shift: "C1", acuity: 2 },
  { date: "5/31/2019 15:12:48", shift: "D1", acuity: 2 },
  { date: "5/31/2019 15:27:49", shift: "B2", acuity: 7 },
  { date: "5/31/2019 15:30:36", shift: "A2", acuity: 7 },
  { date: "5/31/2019 15:35:53", shift: "C1", acuity: 2 },
  { date: "5/31/2019 15:40:25", shift: "D1", acuity: 3 },
  { date: "5/31/2019 15:41:07", shift: "B2", acuity: 2 },
  { date: "5/31/2019 15:41:42", shift: "A2", acuity: 3 },
  { date: "5/31/2019 16:01:29", shift: "C1", acuity: 3 },
  { date: "5/31/2019 16:09:54", shift: "D1", acuity: 3 },
  { date: "5/31/2019 16:10:29", shift: "B2", acuity: 2 },
  { date: "5/31/2019 16:21:37", shift: "A2", acuity: 7 },
  { date: "5/31/2019 16:22:10", shift: "C1", acuity: 2 },
  { date: "5/31/2019 16:30:38", shift: "C2", acuity: 2 },
  { date: "5/31/2019 16:32:47", shift: "D1", acuity: 2 },
  { date: "5/31/2019 16:33:17", shift: "B2", acuity: 3 },
  { date: "5/31/2019 17:00:28", shift: "C2", acuity: 3 },
  { date: "5/31/2019 17:17:56", shift: "C2", acuity: 7 },
  { date: "5/31/2019 17:18:16", shift: "A2", acuity: 3 },
  { date: "5/31/2019 17:30:20", shift: "D1", acuity: 3 },
  { date: "5/31/2019 17:51:19", shift: "B2", acuity: 3 },
  { date: "5/31/2019 17:57:11", shift: "C2", acuity: 7 },
  { date: "5/31/2019 18:03:56", shift: "D2", acuity: 2 },
  { date: "5/31/2019 18:06:26", shift: "A2", acuity: 3 },
  { date: "5/31/2019 18:12:48", shift: "B2", acuity: 3 },
  { date: "5/31/2019 18:13:27", shift: "C2", acuity: 2 },
  { date: "5/31/2019 18:22:11", shift: "D2", acuity: 7 },
  { date: "5/31/2019 18:26:59", shift: "A2", acuity: 2 },
  { date: "5/31/2019 18:30:24", shift: "D2", acuity: 7 },
  { date: "5/31/2019 18:36:14", shift: "D2", acuity: 3 },
  { date: "5/31/2019 18:36:42", shift: "B2", acuity: 2 },
  { date: "5/31/2019 18:42:36", shift: "C2", acuity: 2 },
  { date: "5/31/2019 18:45:00", shift: "A2", acuity: 3 },
  { date: "5/31/2019 19:10:04", shift: "D2", acuity: 7 },
  { date: "5/31/2019 19:33:38", shift: "B2", acuity: 3 },
  { date: "5/31/2019 19:33:48", shift: "C2", acuity: 3 },
  { date: "5/31/2019 19:34:08", shift: "A2", acuity: 2 },
  { date: "5/31/2019 19:39:33", shift: "D2", acuity: 3 },
  { date: "5/31/2019 19:39:44", shift: "B2", acuity: 3 },
  { date: "5/31/2019 19:59:28", shift: "C2", acuity: 3 },
  { date: "5/31/2019 20:14:21", shift: "A2", acuity: 3 },
  { date: "5/31/2019 20:14:31", shift: "D2", acuity: 3 },
  { date: "5/31/2019 20:28:08", shift: "B2", acuity: 3 },
  { date: "5/31/2019 20:41:09", shift: "C2", acuity: 2 },
  { date: "5/31/2019 20:48:00", shift: "A2", acuity: 3 },
  { date: "5/31/2019 20:56:19", shift: "D2", acuity: 2 },
  { date: "5/31/2019 21:14:41", shift: "A3", acuity: 3 },
  { date: "5/31/2019 21:14:51", shift: "B2", acuity: 3 },
  { date: "5/31/2019 21:15:05", shift: "C2", acuity: 7 },
  { date: "5/31/2019 21:15:16", shift: "D2", acuity: 2 },
  { date: "5/31/2019 21:22:26", shift: "A3", acuity: 2 },
  { date: "5/31/2019 21:32:04", shift: "A3", acuity: 7 },
  { date: "5/31/2019 21:51:19", shift: "A3", acuity: 7 },
  { date: "5/31/2019 21:52:57", shift: "B2", acuity: 2 },
  { date: "5/31/2019 21:59:32", shift: "C2", acuity: 2 },
  { date: "5/31/2019 22:08:19", shift: "B3", acuity: 3 },
  { date: "5/31/2019 22:19:10", shift: "D2", acuity: 7 },
  { date: "5/31/2019 22:29:06", shift: "A3", acuity: 7 },
  { date: "5/31/2019 22:38:05", shift: "B3", acuity: 3 },
  { date: "5/31/2019 22:59:54", shift: "B3", acuity: 3 },
  { date: "5/31/2019 23:09:38", shift: "C2", acuity: 2 },
  { date: "5/31/2019 23:24:01", shift: "D2", acuity: 2 },
  { date: "5/31/2019 23:24:18", shift: "A3", acuity: 7 },
  { date: "5/31/2019 23:30:03", shift: "B3", acuity: 3 },
  { date: "5/31/2019 23:48:53", shift: "C2", acuity: 2 },
  { date: "6/1/2019 00:03:39", shift: "D2", acuity: 2 },
  { date: "6/1/2019 00:09:59", shift: "A3", acuity: 3 },
  { date: "6/1/2019 00:16:41", shift: "B3", acuity: 3 },
  { date: "6/1/2019 00:17:00", shift: "D2", acuity: 3 },
  { date: "6/1/2019 00:25:15", shift: "A3", acuity: 7 },
  { date: "6/1/2019 00:38:15", shift: "B3", acuity: 3 },
  { date: "6/1/2019 00:46:58", shift: "D2", acuity: 4 },
  { date: "6/1/2019 01:06:32", shift: "A3", acuity: 4 },
  { date: "6/1/2019 01:06:42", shift: "B3", acuity: 3 },
  { date: "6/1/2019 01:08:51", shift: "D2", acuity: 3 },
  { date: "6/1/2019 01:08:59", shift: "A3", acuity: 3 },
  { date: "6/1/2019 01:12:25", shift: "B3", acuity: 4 },
  { date: "6/1/2019 01:12:50", shift: "D2", acuity: 3 },
  { date: "6/1/2019 01:36:22", shift: "A3", acuity: 4 },
  { date: "6/1/2019 01:58:31", shift: "B3", acuity: 4 },
  { date: "6/1/2019 02:17:43", shift: "A3", acuity: 3 },
  { date: "6/1/2019 02:18:01", shift: "B3", acuity: 5 },
  { date: "6/1/2019 02:28:55", shift: "A3", acuity: 2 },
  { date: "6/1/2019 02:30:55", shift: "B3", acuity: 4 },
  { date: "6/1/2019 03:02:31", shift: "A3", acuity: 4 },
  { date: "6/1/2019 03:14:57", shift: "B3", acuity: 3 },
  { date: "6/1/2019 03:25:01", shift: "A3", acuity: 3 },
  { date: "6/1/2019 03:52:33", shift: "B3", acuity: 3 },
  { date: "6/1/2019 04:40:30", shift: "A3", acuity: 2 },
  { date: "6/1/2019 04:53:34", shift: "B3", acuity: 3 },
  { date: "6/1/2019 05:03:32", shift: "A1", acuity: 4 },
  { date: "6/1/2019 05:25:20", shift: "B3", acuity: 3 },
  { date: "6/1/2019 05:26:03", shift: "A1", acuity: 3 },
  { date: "6/1/2019 05:44:23", shift: "A1", acuity: 4 },
  { date: "6/1/2019 05:53:27", shift: "A1", acuity: 3 },
  { date: "6/1/2019 06:18:43", shift: "B1", acuity: 2 },
  { date: "6/1/2019 06:35:46", shift: "B1", acuity: 3 },
  { date: "6/1/2019 06:36:31", shift: "B1", acuity: 4 },
  { date: "6/1/2019 07:05:12", shift: "A1", acuity: 4 },
  { date: "6/1/2019 07:11:56", shift: "B1", acuity: 4 },
  { date: "6/1/2019 07:25:01", shift: "A1", acuity: 3 },
  { date: "6/1/2019 07:47:14", shift: "B1", acuity: 3 },
  { date: "6/1/2019 08:15:34", shift: "A1", acuity: 3 },
  { date: "6/1/2019 08:15:49", shift: "B1", acuity: 3 },
  { date: "6/1/2019 08:41:47", shift: "C1", acuity: 3 },
  { date: "6/1/2019 08:46:50", shift: "A1", acuity: 2 },
  { date: "6/1/2019 09:05:45", shift: "C1", acuity: 2 },
  { date: "6/1/2019 09:17:30", shift: "C1", acuity: 2 },
  { date: "6/1/2019 09:17:47", shift: "B1", acuity: 3 },
  { date: "6/1/2019 09:18:18", shift: "A1", acuity: 3 },
  { date: "6/1/2019 09:18:43", shift: "C1", acuity: 2 },
  { date: "6/1/2019 09:18:59", shift: "B1", acuity: 3 },
  { date: "6/1/2019 09:33:31", shift: "A1", acuity: 5 },
  { date: "6/1/2019 09:34:24", shift: "C1", acuity: 3 },
  { date: "6/1/2019 09:42:24", shift: "B1", acuity: 2 },
  { date: "6/1/2019 09:57:11", shift: "A1", acuity: 2 },
  { date: "6/1/2019 10:06:16", shift: "D1", acuity: 3 },
  { date: "6/1/2019 10:12:58", shift: "C1", acuity: 3 },
  { date: "6/1/2019 10:31:27", shift: "D1", acuity: 3 },
  { date: "6/1/2019 10:44:14", shift: "D1", acuity: 4 },
  { date: "6/1/2019 11:20:36", shift: "B1", acuity: 2 },
  { date: "6/1/2019 11:42:54", shift: "A1", acuity: 3 },
  { date: "6/1/2019 11:46:11", shift: "C1", acuity: 3 },
  { date: "6/1/2019 11:59:49", shift: "D1", acuity: 3 },
  { date: "6/1/2019 12:03:32", shift: "B1", acuity: 3 },
  { date: "6/1/2019 12:24:39", shift: "A1", acuity: 3 },
  { date: "6/1/2019 12:24:59", shift: "C1", acuity: 3 },
  { date: "6/1/2019 12:25:15", shift: "D1", acuity: 3 },
  { date: "6/1/2019 12:43:42", shift: "B1", acuity: 3 },
  { date: "6/1/2019 12:44:12", shift: "A1", acuity: 3 },
  { date: "6/1/2019 12:44:53", shift: "C1", acuity: 2 },
  { date: "6/1/2019 12:54:36", shift: "D1", acuity: 3 },
  { date: "6/1/2019 12:59:44", shift: "B1", acuity: 2 },
  { date: "6/1/2019 13:00:22", shift: "A2", acuity: 3 },
  { date: "6/1/2019 13:02:48", shift: "C1", acuity: 2 },
  { date: "6/1/2019 13:24:59", shift: "D1", acuity: 4 },
  { date: "6/1/2019 13:48:29", shift: "A2", acuity: 3 },
  { date: "6/1/2019 14:09:27", shift: "A2", acuity: 2 },
  { date: "6/1/2019 14:09:47", shift: "B2", acuity: 3 },
  { date: "6/1/2019 14:17:25", shift: "C1", acuity: 2 },
  { date: "6/1/2019 14:20:14", shift: "D1", acuity: 2 },
  { date: "6/1/2019 14:44:12", shift: "B2", acuity: 3 },
  { date: "6/1/2019 14:48:33", shift: "B2", acuity: 3 },
  { date: "6/1/2019 14:48:50", shift: "A2", acuity: 3 },
  { date: "6/1/2019 14:59:47", shift: "C1", acuity: 3 },
  { date: "6/1/2019 15:10:27", shift: "D1", acuity: 2 },
  { date: "6/1/2019 15:24:40", shift: "B2", acuity: 3 },
  { date: "6/1/2019 15:24:56", shift: "A2", acuity: 2 },
  { date: "6/1/2019 15:39:25", shift: "C1", acuity: 3 },
  { date: "6/1/2019 15:44:59", shift: "D1", acuity: 2 },
  { date: "6/1/2019 15:51:41", shift: "B2", acuity: 4 },
  { date: "6/1/2019 15:58:26", shift: "A2", acuity: 3 },
  { date: "6/1/2019 16:21:45", shift: "C1", acuity: 3 },
  { date: "6/1/2019 16:24:54", shift: "D1", acuity: 2 },
  { date: "6/1/2019 16:25:07", shift: "B2", acuity: 3 },
  { date: "6/1/2019 16:34:36", shift: "C2", acuity: 2 },
  { date: "6/1/2019 16:49:08", shift: "A2", acuity: 3 },
  { date: "6/1/2019 16:49:22", shift: "D1", acuity: 2 },
  { date: "6/1/2019 17:07:32", shift: "C2", acuity: 3 },
  { date: "6/1/2019 17:22:42", shift: "C2", acuity: 3 },
  { date: "6/1/2019 17:36:40", shift: "B2", acuity: 3 },
  { date: "6/1/2019 17:42:16", shift: "A2", acuity: 3 },
  { date: "6/1/2019 18:02:16", shift: "D2", acuity: 3 },
  { date: "6/1/2019 18:07:02", shift: "C2", acuity: 4 },
  { date: "6/1/2019 18:11:37", shift: "B2", acuity: 2 },
  { date: "6/1/2019 18:11:58", shift: "A2", acuity: 3 },
  { date: "6/1/2019 18:30:39", shift: "D2", acuity: 3 },
  { date: "6/1/2019 18:50:02", shift: "D2", acuity: 3 },
  { date: "6/1/2019 18:50:24", shift: "C2", acuity: 2 },
  { date: "6/1/2019 18:59:45", shift: "B2", acuity: 3 },
  { date: "6/1/2019 19:04:32", shift: "A2", acuity: 3 },
  { date: "6/1/2019 19:10:26", shift: "D2", acuity: 2 },
  { date: "6/1/2019 19:16:20", shift: "C2", acuity: 3 },
  { date: "6/1/2019 19:20:52", shift: "B2", acuity: 2 },
  { date: "6/1/2019 19:25:14", shift: "A2", acuity: 3 },
  { date: "6/1/2019 19:40:40", shift: "D2", acuity: 3 },
  { date: "6/1/2019 19:45:28", shift: "C2", acuity: 3 },
  { date: "6/1/2019 19:47:56", shift: "B2", acuity: 3 },
  { date: "6/1/2019 20:06:03", shift: "A2", acuity: 3 },
  { date: "6/1/2019 20:10:29", shift: "D2", acuity: 4 },
  { date: "6/1/2019 20:12:40", shift: "C2", acuity: 2 },
  { date: "6/1/2019 20:20:08", shift: "B2", acuity: 2 },
  { date: "6/1/2019 20:20:27", shift: "A2", acuity: 3 },
  { date: "6/1/2019 20:26:22", shift: "D2", acuity: 2 },
  { date: "6/1/2019 20:26:33", shift: "C2", acuity: 3 },
  { date: "6/1/2019 20:29:12", shift: "B2", acuity: 2 },
  { date: "6/1/2019 20:35:04", shift: "A2", acuity: 2 },
  { date: "6/1/2019 20:35:19", shift: "D2", acuity: 2 },
  { date: "6/1/2019 20:46:49", shift: "C2", acuity: 3 },
  { date: "6/1/2019 20:55:35", shift: "B2", acuity: 2 },
  { date: "6/1/2019 21:09:43", shift: "A3", acuity: 3 },
  { date: "6/1/2019 21:14:27", shift: "D2", acuity: 3 },
  { date: "6/1/2019 21:24:48", shift: "C2", acuity: 4 },
  { date: "6/1/2019 21:25:03", shift: "B2", acuity: 3 },
  { date: "6/1/2019 21:30:22", shift: "A3", acuity: 3 },
  { date: "6/1/2019 21:44:46", shift: "A3", acuity: 3 },
  { date: "6/1/2019 22:01:53", shift: "B3", acuity: 2 },
  { date: "6/1/2019 22:14:13", shift: "D2", acuity: 2 },
  { date: "6/1/2019 22:18:51", shift: "C2", acuity: 2 },
  { date: "6/1/2019 22:22:00", shift: "A3", acuity: 3 },
  { date: "6/1/2019 22:22:10", shift: "B3", acuity: 3 },
  { date: "6/1/2019 22:33:08", shift: "B3", acuity: 2 },
  { date: "6/1/2019 22:44:42", shift: "B3", acuity: 3 },
  { date: "6/1/2019 22:58:30", shift: "D2", acuity: 3 },
  { date: "6/1/2019 23:05:58", shift: "C2", acuity: 4 },
  { date: "6/1/2019 23:16:13", shift: "A3", acuity: 4 },
  { date: "6/1/2019 23:19:39", shift: "B3", acuity: 3 },
  { date: "6/1/2019 23:58:11", shift: "D2", acuity: 3 },
  { date: "6/2/2019 00:04:21", shift: "A3", acuity: 2 },
  { date: "6/2/2019 00:05:50", shift: "B3", acuity: 4 },
  { date: "6/2/2019 00:11:03", shift: "D2", acuity: 4 },
  { date: "6/2/2019 00:33:49", shift: "A3", acuity: 3 },
  { date: "6/2/2019 00:42:30", shift: "B3", acuity: 4 },
  { date: "6/2/2019 00:45:04", shift: "D2", acuity: 3 },
  { date: "6/2/2019 00:49:18", shift: "A3", acuity: 4 },
  { date: "6/2/2019 01:03:07", shift: "B3", acuity: 3 },
  { date: "6/2/2019 01:18:39", shift: "D2", acuity: 2 },
  { date: "6/2/2019 01:57:00", shift: "A3", acuity: 2 },
  { date: "6/2/2019 02:17:41", shift: "B3", acuity: 3 },
  { date: "6/2/2019 02:37:08", shift: "A3", acuity: 3 },
  { date: "6/2/2019 02:40:12", shift: "B3", acuity: 2 },
  { date: "6/2/2019 02:51:03", shift: "A3", acuity: 3 },
  { date: "6/2/2019 03:08:41", shift: "B3", acuity: 2 },
  { date: "6/2/2019 04:56:04", shift: "A3", acuity: 2 },
  { date: "6/2/2019 05:01:42", shift: "A1", acuity: 3 },
  { date: "6/2/2019 06:13:48", shift: "A1", acuity: 3 },
  { date: "6/2/2019 06:14:01", shift: "A1", acuity: 3 },
  { date: "6/2/2019 06:15:28", shift: "B1", acuity: 3 },
  { date: "6/2/2019 06:37:18", shift: "B1", acuity: 2 },
  { date: "6/2/2019 06:42:01", shift: "B1", acuity: 3 },
  { date: "6/2/2019 06:50:13", shift: "A1", acuity: 2 },
  { date: "6/2/2019 07:00:40", shift: "B1", acuity: 4 },
  { date: "6/2/2019 07:11:17", shift: "A1", acuity: 3 },
  { date: "6/2/2019 07:41:31", shift: "B1", acuity: 2 },
  { date: "6/2/2019 07:50:36", shift: "A1", acuity: 3 },
  { date: "6/2/2019 08:05:39", shift: "B1", acuity: 3 },
  { date: "6/2/2019 08:13:38", shift: "A1", acuity: 2 },
  { date: "6/2/2019 08:14:00", shift: "B1", acuity: 5 },
  { date: "6/2/2019 08:24:40", shift: "A1", acuity: 3 },
  { date: "6/2/2019 08:24:58", shift: "B1", acuity: 3 },
  { date: "6/2/2019 08:51:49", shift: "C1", acuity: 3 },
  { date: "6/2/2019 09:06:18", shift: "C1", acuity: 3 },
  { date: "6/2/2019 09:14:25", shift: "C1", acuity: 3 },
  { date: "6/2/2019 09:16:51", shift: "A1", acuity: 3 },
  { date: "6/2/2019 09:18:21", shift: "B1", acuity: 3 },
  { date: "6/2/2019 09:22:04", shift: "C1", acuity: 3 },
  { date: "6/2/2019 09:40:46", shift: "A1", acuity: 2 },
  { date: "6/2/2019 09:50:20", shift: "B1", acuity: 4 },
  { date: "6/2/2019 09:55:32", shift: "C1", acuity: 3 },
  { date: "6/2/2019 09:56:19", shift: "A1", acuity: 3 },
  { date: "6/2/2019 10:10:25", shift: "D1", acuity: 2 },
  { date: "6/2/2019 10:23:38", shift: "B1", acuity: 3 },
  { date: "6/2/2019 10:37:11", shift: "D1", acuity: 3 },
  { date: "6/2/2019 10:37:28", shift: "D1", acuity: 3 },
  { date: "6/2/2019 11:02:00", shift: "C1", acuity: 2 },
  { date: "6/2/2019 11:03:51", shift: "A1", acuity: 2 },
  { date: "6/2/2019 11:04:25", shift: "B1", acuity: 4 },
  { date: "6/2/2019 11:04:41", shift: "D1", acuity: 3 },
  { date: "6/2/2019 11:32:21", shift: "C1", acuity: 3 },
  { date: "6/2/2019 11:32:51", shift: "A1", acuity: 3 },
  { date: "6/2/2019 11:33:10", shift: "B1", acuity: 3 },
  { date: "6/2/2019 11:54:16", shift: "D1", acuity: 2 },
  { date: "6/2/2019 11:56:01", shift: "C1", acuity: 3 },
  { date: "6/2/2019 12:14:03", shift: "A1", acuity: 3 },
  { date: "6/2/2019 12:20:44", shift: "B1", acuity: 2 },
  { date: "6/2/2019 12:29:40", shift: "D1", acuity: 3 },
  { date: "6/2/2019 12:40:13", shift: "C1", acuity: 3 },
  { date: "6/2/2019 12:42:49", shift: "A1", acuity: 3 },
  { date: "6/2/2019 12:43:21", shift: "B1", acuity: 3 },
  { date: "6/2/2019 12:53:42", shift: "D1", acuity: 2 },
  { date: "6/2/2019 13:00:00", shift: "A2", acuity: 4 },
  { date: "6/2/2019 13:01:50", shift: "C1", acuity: 3 },
  { date: "6/2/2019 13:32:35", shift: "A2", acuity: 2 },
  { date: "6/2/2019 14:04:48", shift: "A2", acuity: 2 },
  { date: "6/2/2019 14:09:42", shift: "B2", acuity: 3 },
  { date: "6/2/2019 14:10:52", shift: "D1", acuity: 3 },
  { date: "6/2/2019 14:15:31", shift: "C1", acuity: 2 },
  { date: "6/2/2019 14:27:39", shift: "A2", acuity: 3 },
  { date: "6/2/2019 14:41:33", shift: "B2", acuity: 3 },
  { date: "6/2/2019 14:46:24", shift: "B2", acuity: 3 },
  { date: "6/2/2019 14:58:31", shift: "D1", acuity: 3 },
  { date: "6/2/2019 15:01:05", shift: "C1", acuity: 3 },
  { date: "6/2/2019 15:15:33", shift: "A2", acuity: 3 },
  { date: "6/2/2019 15:30:57", shift: "B2", acuity: 4 },
  { date: "6/2/2019 15:44:03", shift: "D1", acuity: 3 },
  { date: "6/2/2019 15:51:20", shift: "C1", acuity: 3 },
  { date: "6/2/2019 16:00:09", shift: "A2", acuity: 4 },
  { date: "6/2/2019 16:00:49", shift: "B2", acuity: 3 },
  { date: "6/2/2019 16:03:18", shift: "D1", acuity: 3 },
  { date: "6/2/2019 16:23:55", shift: "C1", acuity: 2 },
  { date: "6/2/2019 16:35:39", shift: "C2", acuity: 3 },
  { date: "6/2/2019 16:45:59", shift: "A2", acuity: 4 },
  { date: "6/2/2019 16:46:14", shift: "B2", acuity: 3 },
  { date: "6/2/2019 16:54:19", shift: "D1", acuity: 3 },
  { date: "6/2/2019 17:02:10", shift: "C2", acuity: 3 },
  { date: "6/2/2019 17:10:46", shift: "C2", acuity: 3 },
  { date: "6/2/2019 17:15:40", shift: "A2", acuity: 2 },
  { date: "6/2/2019 17:16:26", shift: "B2", acuity: 3 },
  { date: "6/2/2019 17:21:23", shift: "D1", acuity: 3 },
  { date: "6/2/2019 17:32:55", shift: "C2", acuity: 2 },
  { date: "6/2/2019 17:33:29", shift: "A2", acuity: 3 },
  { date: "6/2/2019 17:53:56", shift: "B2", acuity: 3 },
  { date: "6/2/2019 17:59:38", shift: "D1", acuity: 2 },
  { date: "6/2/2019 18:03:12", shift: "D2", acuity: 3 },
  { date: "6/2/2019 18:07:54", shift: "C2", acuity: 3 },
  { date: "6/2/2019 18:14:00", shift: "A2", acuity: 3 },
  { date: "6/2/2019 18:30:29", shift: "D2", acuity: 7 },
  { date: "6/2/2019 18:38:53", shift: "D2", acuity: 3 },
  { date: "6/2/2019 18:41:38", shift: "B2", acuity: 3 },
  { date: "6/2/2019 18:47:06", shift: "C2", acuity: 2 },
  { date: "6/2/2019 18:50:43", shift: "A2", acuity: 7 },
  { date: "6/2/2019 18:58:39", shift: "D2", acuity: 3 },
  { date: "6/2/2019 19:01:31", shift: "B2", acuity: 3 },
  { date: "6/2/2019 19:07:50", shift: "C2", acuity: 2 },
  { date: "6/2/2019 19:17:16", shift: "A2", acuity: 3 },
  { date: "6/2/2019 19:25:51", shift: "D2", acuity: 7 },
  { date: "6/2/2019 19:34:01", shift: "B2", acuity: 4 },
  { date: "6/2/2019 19:49:10", shift: "C2", acuity: 3 },
  { date: "6/2/2019 20:07:40", shift: "A2", acuity: 7 },
  { date: "6/2/2019 20:07:57", shift: "D2", acuity: 3 },
  { date: "6/2/2019 20:27:47", shift: "B2", acuity: 3 },
  { date: "6/2/2019 20:28:03", shift: "C2", acuity: 4 },
  { date: "6/2/2019 20:54:03", shift: "A2", acuity: 3 },
  { date: "6/2/2019 20:57:32", shift: "D2", acuity: 3 },
  { date: "6/2/2019 20:58:19", shift: "B2", acuity: 4 },
  { date: "6/2/2019 21:08:32", shift: "A3", acuity: 2 },
  { date: "6/2/2019 21:09:29", shift: "C2", acuity: 3 },
  { date: "6/2/2019 21:16:14", shift: "D2", acuity: 3 },
  { date: "6/2/2019 21:23:39", shift: "B2", acuity: 5 },
  { date: "6/2/2019 21:27:01", shift: "A3", acuity: 4 },
  { date: "6/2/2019 21:36:20", shift: "A3", acuity: 4 },
  { date: "6/2/2019 21:38:34", shift: "A3", acuity: 4 },
  { date: "6/2/2019 21:52:44", shift: "C2", acuity: 4 },
  { date: "6/2/2019 21:53:00", shift: "D2", acuity: 3 },
  { date: "6/2/2019 21:53:14", shift: "B2", acuity: 4 },
  { date: "6/2/2019 22:04:26", shift: "B3", acuity: 3 },
  { date: "6/2/2019 22:04:44", shift: "A3", acuity: 4 },
  { date: "6/2/2019 22:09:26", shift: "C2", acuity: 3 },
  { date: "6/2/2019 22:16:07", shift: "D2", acuity: 2 },
  { date: "6/2/2019 22:16:27", shift: "B3", acuity: 4 },
  { date: "6/2/2019 22:23:39", shift: "A3", acuity: 4 },
  { date: "6/2/2019 22:26:25", shift: "C2", acuity: 2 },
  { date: "6/2/2019 22:54:22", shift: "B3", acuity: 4 },
  { date: "6/2/2019 22:58:08", shift: "B3", acuity: 4 },
  { date: "6/2/2019 23:07:19", shift: "D2", acuity: 3 },
  { date: "6/2/2019 23:10:15", shift: "A3", acuity: 3 },
  { date: "6/2/2019 23:19:18", shift: "C2", acuity: 5 },
  { date: "6/2/2019 23:24:00", shift: "B3", acuity: 5 },
  { date: "6/2/2019 23:36:06", shift: "D2", acuity: 4 },
  { date: "6/2/2019 23:42:39", shift: "A3", acuity: 2 },
  { date: "6/2/2019 23:44:10", shift: "C2", acuity: 2 },
  { date: "6/2/2019 23:50:17", shift: "B3", acuity: 2 },
  { date: "6/2/2019 23:57:13", shift: "D2", acuity: 3 },
  { date: "6/2/2019 23:57:40", shift: "A3", acuity: 4 },
  { date: "6/3/2019 00:06:40", shift: "B3", acuity: 3 },
  { date: "6/3/2019 00:32:50", shift: "D2", acuity: 3 },
  { date: "6/3/2019 00:33:08", shift: "A3", acuity: 3 },
  { date: "6/3/2019 00:42:41", shift: "B3", acuity: 4 },
  { date: "6/3/2019 00:49:04", shift: "D2", acuity: 3 },
  { date: "6/3/2019 00:49:22", shift: "A3", acuity: 4 },
  { date: "6/3/2019 00:58:17", shift: "B3", acuity: 4 },
  { date: "6/3/2019 01:05:33", shift: "D2", acuity: 2 },
  { date: "6/3/2019 01:05:50", shift: "A3", acuity: 4 },
  { date: "6/3/2019 01:24:34", shift: "B3", acuity: 4 },
  { date: "6/3/2019 01:24:46", shift: "D2", acuity: 4 },
  { date: "6/3/2019 01:30:17", shift: "A3", acuity: 2 },
  { date: "6/3/2019 01:40:05", shift: "B3", acuity: 2 },
  { date: "6/3/2019 01:40:28", shift: "A3", acuity: 3 },
  { date: "6/3/2019 02:07:13", shift: "B3", acuity: 3 },
  { date: "6/3/2019 02:23:39", shift: "A3", acuity: 4 },
  { date: "6/3/2019 02:51:46", shift: "B3", acuity: 3 },
  { date: "6/3/2019 02:52:02", shift: "A3", acuity: 4 },
  { date: "6/3/2019 03:06:12", shift: "B3", acuity: 5 },
  { date: "6/3/2019 03:34:58", shift: "A3", acuity: 3 },
  { date: "6/3/2019 03:40:20", shift: "B3", acuity: 4 },
  { date: "6/3/2019 03:48:45", shift: "A3", acuity: 3 },
  { date: "6/3/2019 03:49:01", shift: "B3", acuity: 3 },
  { date: "6/3/2019 04:03:24", shift: "A3", acuity: 3 },
  { date: "6/3/2019 04:36:45", shift: "B3", acuity: 3 },
  { date: "6/3/2019 05:15:10", shift: "A1", acuity: 2 },
  { date: "6/3/2019 05:45:23", shift: "A1", acuity: 3 },
  { date: "6/3/2019 05:52:30", shift: "A1", acuity: 3 },
  { date: "6/3/2019 06:21:00", shift: "B1", acuity: 2 },
  { date: "6/3/2019 06:21:48", shift: "A1", acuity: 3 },
  { date: "6/3/2019 07:04:59", shift: "B1", acuity: 3 },
  { date: "6/3/2019 07:28:15", shift: "B1", acuity: 2 },
  { date: "6/3/2019 07:36:34", shift: "A1", acuity: 3 },
  { date: "6/3/2019 07:38:07", shift: "B1", acuity: 3 },
  { date: "6/3/2019 08:08:42", shift: "A1", acuity: 4 },
  { date: "6/3/2019 08:30:40", shift: "C1", acuity: 3 },
  { date: "6/3/2019 08:56:37", shift: "B1", acuity: 2 },
  { date: "6/3/2019 09:12:53", shift: "C1", acuity: 2 },
  { date: "6/3/2019 09:17:14", shift: "C1", acuity: 3 },
  { date: "6/3/2019 09:18:40", shift: "A1", acuity: 4 },
  { date: "6/3/2019 09:28:39", shift: "B1", acuity: 2 },
  { date: "6/3/2019 09:48:42", shift: "C1", acuity: 2 },
  { date: "6/3/2019 09:48:52", shift: "A1", acuity: 3 },
  { date: "6/3/2019 09:51:30", shift: "B1", acuity: 2 },
  { date: "6/3/2019 09:52:42", shift: "C1", acuity: 2 },
  { date: "6/3/2019 09:53:10", shift: "A1", acuity: 4 },
  { date: "6/3/2019 10:02:05", shift: "D1", acuity: 2 },
  { date: "6/3/2019 10:03:27", shift: "B1", acuity: 3 },
  { date: "6/3/2019 10:07:13", shift: "C1", acuity: 3 },
  { date: "6/3/2019 10:20:46", shift: "A1", acuity: 2 },
  { date: "6/3/2019 10:21:24", shift: "D1", acuity: 2 },
  { date: "6/3/2019 10:39:00", shift: "D1", acuity: 2 },
  { date: "6/3/2019 10:39:03", shift: "D1", acuity: 2 },
  { date: "6/3/2019 10:51:17", shift: "B1", acuity: 2 },
  { date: "6/3/2019 11:28:00", shift: "C1", acuity: 3 },
  { date: "6/3/2019 11:49:21", shift: "A1", acuity: 2 },
  { date: "6/3/2019 11:49:37", shift: "D1", acuity: 2 },
  { date: "6/3/2019 12:08:43", shift: "B1", acuity: 3 },
  { date: "6/3/2019 12:09:21", shift: "C1", acuity: 3 },
  { date: "6/3/2019 12:16:42", shift: "A1", acuity: 3 },
  { date: "6/3/2019 12:31:51", shift: "D1", acuity: 2 },
  { date: "6/3/2019 12:33:46", shift: "B1", acuity: 3 },
  { date: "6/3/2019 12:33:58", shift: "C1", acuity: 2 },
  { date: "6/3/2019 12:42:04", shift: "A1", acuity: 3 },
  { date: "6/3/2019 12:48:12", shift: "D1", acuity: 3 },
  { date: "6/3/2019 12:55:29", shift: "B1", acuity: 2 },
  { date: "6/3/2019 13:04:45", shift: "A2", acuity: 2 },
  { date: "6/3/2019 13:22:32", shift: "C1", acuity: 2 },
  { date: "6/3/2019 13:23:20", shift: "D1", acuity: 2 },
  { date: "6/3/2019 13:29:06", shift: "B1", acuity: 3 },
  { date: "6/3/2019 13:30:34", shift: "A2", acuity: 2 },
  { date: "6/3/2019 13:32:28", shift: "A2", acuity: 2 },
  { date: "6/3/2019 13:47:18", shift: "C1", acuity: 2 },
  { date: "6/3/2019 14:08:38", shift: "B2", acuity: 3 },
  { date: "6/3/2019 14:17:18", shift: "D1", acuity: 3 },
  { date: "6/3/2019 14:19:55", shift: "A2", acuity: 4 },
  { date: "6/3/2019 14:41:47", shift: "B2", acuity: 3 },
  { date: "6/3/2019 14:55:52", shift: "B2", acuity: 2 },
  { date: "6/3/2019 15:04:10", shift: "C1", acuity: 2 },
  { date: "6/3/2019 15:09:39", shift: "D1", acuity: 3 },
  { date: "6/3/2019 15:14:23", shift: "A2", acuity: 2 },
  { date: "6/3/2019 15:31:11", shift: "B2", acuity: 3 },
  { date: "6/3/2019 15:31:24", shift: "C1", acuity: 3 },
  { date: "6/3/2019 15:35:44", shift: "D1", acuity: 3 },
  { date: "6/3/2019 15:35:57", shift: "A2", acuity: 3 },
  { date: "6/3/2019 15:56:20", shift: "B2", acuity: 4 },
  { date: "6/3/2019 16:02:16", shift: "C1", acuity: 3 },
  { date: "6/3/2019 16:24:42", shift: "D1", acuity: 2 },
  { date: "6/3/2019 16:24:54", shift: "A2", acuity: 3 },
  { date: "6/3/2019 16:39:05", shift: "C2", acuity: 3 },
  { date: "6/3/2019 16:47:58", shift: "B2", acuity: 3 },
  { date: "6/3/2019 16:56:33", shift: "D1", acuity: 3 },
  { date: "6/3/2019 17:00:59", shift: "C2", acuity: 2 },
  { date: "6/3/2019 17:24:53", shift: "C2", acuity: 2 },
  { date: "6/3/2019 17:27:46", shift: "A2", acuity: 2 },
  { date: "6/3/2019 17:50:06", shift: "B2", acuity: 2 },
  { date: "6/3/2019 18:08:23", shift: "D2", acuity: 2 },
  { date: "6/3/2019 18:15:23", shift: "C2", acuity: 2 },
  { date: "6/3/2019 18:16:41", shift: "A2", acuity: 2 },
  { date: "6/3/2019 18:32:15", shift: "D2", acuity: 2 },
  { date: "6/3/2019 18:38:05", shift: "D2", acuity: 3 },
  { date: "6/3/2019 18:38:19", shift: "B2", acuity: 2 },
  { date: "6/3/2019 18:44:09", shift: "C2", acuity: 3 },
  { date: "6/3/2019 19:01:05", shift: "A2", acuity: 2 },
  { date: "6/3/2019 19:03:38", shift: "D2", acuity: 3 },
  { date: "6/3/2019 19:11:36", shift: "B2", acuity: 2 },
  { date: "6/3/2019 19:12:16", shift: "C2", acuity: 4 },
  { date: "6/3/2019 19:17:15", shift: "A2", acuity: 3 },
  { date: "6/3/2019 19:20:47", shift: "D2", acuity: 2 },
  { date: "6/3/2019 19:30:54", shift: "B2", acuity: 3 },
  { date: "6/3/2019 19:45:39", shift: "C2", acuity: 3 },
  { date: "6/3/2019 19:45:55", shift: "A2", acuity: 2 },
  { date: "6/3/2019 20:05:31", shift: "D2", acuity: 3 },
  { date: "6/3/2019 20:12:52", shift: "B2", acuity: 3 },
  { date: "6/3/2019 20:29:49", shift: "C2", acuity: 2 },
  { date: "6/3/2019 20:30:02", shift: "A2", acuity: 3 },
  { date: "6/3/2019 20:33:04", shift: "D2", acuity: 2 },
  { date: "6/3/2019 20:47:44", shift: "B2", acuity: 2 },
  { date: "6/3/2019 21:14:36", shift: "A3", acuity: 4 },
  { date: "6/3/2019 21:16:05", shift: "C2", acuity: 5 },
  { date: "6/3/2019 21:24:45", shift: "D2", acuity: 2 },
  { date: "6/3/2019 21:37:09", shift: "A3", acuity: 2 },
  { date: "6/3/2019 21:40:34", shift: "A3", acuity: 3 },
  { date: "6/3/2019 21:54:46", shift: "B2", acuity: 4 },
  { date: "6/3/2019 21:57:12", shift: "C2", acuity: 3 },
  { date: "6/3/2019 22:06:48", shift: "B3", acuity: 3 },
  { date: "6/3/2019 22:07:17", shift: "D2", acuity: 2 },
  { date: "6/3/2019 22:07:55", shift: "A3", acuity: 2 },
  { date: "6/3/2019 22:10:02", shift: "C2", acuity: 3 },
  { date: "6/3/2019 22:21:51", shift: "B3", acuity: 2 },
  { date: "6/3/2019 22:30:48", shift: "B3", acuity: 3 },
  { date: "6/3/2019 22:38:23", shift: "B3", acuity: 3 },
  { date: "6/3/2019 22:52:34", shift: "D2", acuity: 3 },
  { date: "6/3/2019 22:59:03", shift: "A3", acuity: 3 },
  { date: "6/3/2019 22:59:25", shift: "C2", acuity: 3 },
  { date: "6/3/2019 23:00:56", shift: "B3", acuity: 3 },
  { date: "6/3/2019 23:07:24", shift: "D2", acuity: 2 },
  { date: "6/3/2019 23:11:17", shift: "A3", acuity: 4 },
  { date: "6/3/2019 23:11:33", shift: "C2", acuity: 2 },
  { date: "6/3/2019 23:17:29", shift: "B3", acuity: 2 },
  { date: "6/3/2019 23:18:19", shift: "D2", acuity: 2 },
  { date: "6/3/2019 23:41:51", shift: "A3", acuity: 2 },
  { date: "6/3/2019 23:43:12", shift: "C2", acuity: 3 },
  { date: "6/3/2019 23:44:13", shift: "B3", acuity: 3 },
  { date: "6/4/2019 00:08:20", shift: "D2", acuity: 3 },
  { date: "6/4/2019 00:45:00", shift: "A3", acuity: 2 },
  { date: "6/4/2019 00:48:18", shift: "B3", acuity: 2 },
  { date: "6/4/2019 00:49:02", shift: "D2", acuity: 3 },
  { date: "6/4/2019 00:50:49", shift: "A3", acuity: 3 },
  { date: "6/4/2019 01:03:13", shift: "B3", acuity: 3 },
  { date: "6/4/2019 01:09:48", shift: "D2", acuity: 2 },
  { date: "6/4/2019 01:36:32", shift: "A3", acuity: 3 },
  { date: "6/4/2019 01:38:22", shift: "B3", acuity: 3 },
  { date: "6/4/2019 02:02:43", shift: "A3", acuity: 3 },
  { date: "6/4/2019 02:03:07", shift: "B3", acuity: 3 },
  { date: "6/4/2019 02:12:18", shift: "A3", acuity: 4 },
  { date: "6/4/2019 02:26:05", shift: "B3", acuity: 3 },
  { date: "6/4/2019 02:37:18", shift: "A3", acuity: 3 },
  { date: "6/4/2019 02:45:55", shift: "B3", acuity: 3 },
  { date: "6/4/2019 02:47:27", shift: "A3", acuity: 2 },
  { date: "6/4/2019 03:06:53", shift: "B3", acuity: 2 },
  { date: "6/4/2019 03:20:16", shift: "A3", acuity: 4 },
  { date: "6/4/2019 03:33:14", shift: "B3", acuity: 4 },
  { date: "6/4/2019 03:34:19", shift: "A3", acuity: 5 },
  { date: "6/4/2019 04:16:22", shift: "B3", acuity: 2 },
  { date: "6/4/2019 04:16:36", shift: "A3", acuity: 2 },
  { date: "6/4/2019 04:29:51", shift: "B3", acuity: 2 },
  { date: "6/4/2019 05:46:10", shift: "A1", acuity: 4 },
  { date: "6/4/2019 06:13:32", shift: "A1", acuity: 4 },
  { date: "6/4/2019 06:52:24", shift: "A1", acuity: 3 },
  { date: "6/4/2019 07:16:44", shift: "B1", acuity: 4 },
  { date: "6/4/2019 07:36:11", shift: "B1", acuity: 3 },
  { date: "6/4/2019 08:05:13", shift: "A1", acuity: 3 },
  { date: "6/4/2019 08:05:27", shift: "B1", acuity: 3 },
  { date: "6/4/2019 08:42:27", shift: "C1", acuity: 3 },
  { date: "6/4/2019 09:02:53", shift: "C1", acuity: 3 },
  { date: "6/4/2019 09:07:45", shift: "C1", acuity: 3 },
  { date: "6/4/2019 09:14:57", shift: "A1", acuity: 5 },
  { date: "6/4/2019 09:27:41", shift: "B1", acuity: 3 },
  { date: "6/4/2019 09:29:07", shift: "C1", acuity: 3 },
  { date: "6/4/2019 09:34:01", shift: "A1", acuity: 3 },
  { date: "6/4/2019 09:36:33", shift: "B1", acuity: 2 },
  { date: "6/4/2019 09:42:05", shift: "C1", acuity: 4 },
  { date: "6/4/2019 09:47:40", shift: "A1", acuity: 3 },
  { date: "6/4/2019 09:47:51", shift: "B1", acuity: 3 },
  { date: "6/4/2019 09:48:47", shift: "C1", acuity: 3 },
  { date: "6/4/2019 10:26:00", shift: "D1", acuity: 5 },
  { date: "6/4/2019 10:26:13", shift: "A1", acuity: 3 },
  { date: "6/4/2019 10:33:00", shift: "D1", acuity: 3 },
  { date: "6/4/2019 10:37:52", shift: "D1", acuity: 3 },
  { date: "6/4/2019 10:38:00", shift: "B1", acuity: 3 },
  { date: "6/4/2019 10:53:11", shift: "C1", acuity: 3 },
  { date: "6/4/2019 10:53:21", shift: "A1", acuity: 4 },
  { date: "6/4/2019 11:01:19", shift: "D1", acuity: 3 },
  { date: "6/4/2019 11:04:53", shift: "B1", acuity: 7 },
  { date: "6/4/2019 11:11:53", shift: "C1", acuity: 3 },
  { date: "6/4/2019 11:14:26", shift: "A1", acuity: 3 },
  { date: "6/4/2019 11:18:55", shift: "D1", acuity: 3 },
  { date: "6/4/2019 11:33:30", shift: "B1", acuity: 2 },
  { date: "6/4/2019 11:33:46", shift: "C1", acuity: 2 },
  { date: "6/4/2019 11:35:46", shift: "A1", acuity: 2 },
  { date: "6/4/2019 11:42:17", shift: "D1", acuity: 2 },
  { date: "6/4/2019 11:53:30", shift: "B1", acuity: 3 },
  { date: "6/4/2019 11:59:00", shift: "C1", acuity: 2 },
  { date: "6/4/2019 12:06:24", shift: "A1", acuity: 3 },
  { date: "6/4/2019 12:26:44", shift: "D1", acuity: 3 },
  { date: "6/4/2019 12:27:11", shift: "B1", acuity: 2 },
  { date: "6/4/2019 12:35:25", shift: "C1", acuity: 2 },
  { date: "6/4/2019 12:47:36", shift: "A1", acuity: 2 },
  { date: "6/4/2019 13:03:21", shift: "A2", acuity: 4 },
  { date: "6/4/2019 13:14:14", shift: "D1", acuity: 2 },
  { date: "6/4/2019 13:15:30", shift: "B1", acuity: 3 },
  { date: "6/4/2019 13:15:38", shift: "C1", acuity: 3 },
  { date: "6/4/2019 13:33:04", shift: "A2", acuity: 2 },
  { date: "6/4/2019 13:47:06", shift: "A2", acuity: 3 },
  { date: "6/4/2019 14:11:31", shift: "B2", acuity: 3 },
  { date: "6/4/2019 14:12:03", shift: "D1", acuity: 2 },
  { date: "6/4/2019 14:12:40", shift: "C1", acuity: 2 },
  { date: "6/4/2019 14:19:42", shift: "A2", acuity: 2 },
  { date: "6/4/2019 14:46:19", shift: "B2", acuity: 3 },
  { date: "6/4/2019 14:51:00", shift: "B2", acuity: 2 },
  { date: "6/4/2019 14:51:10", shift: "D1", acuity: 3 },
  { date: "6/4/2019 14:57:39", shift: "C1", acuity: 3 },
  { date: "6/4/2019 15:18:13", shift: "A2", acuity: 4 },
  { date: "6/4/2019 15:39:07", shift: "B2", acuity: 2 },
  { date: "6/4/2019 15:45:59", shift: "D1", acuity: 2 },
  { date: "6/4/2019 15:55:43", shift: "C1", acuity: 3 },
  { date: "6/4/2019 15:57:05", shift: "A2", acuity: 2 },
  { date: "6/4/2019 16:02:48", shift: "B2", acuity: 2 },
  { date: "6/4/2019 16:04:33", shift: "D1", acuity: 2 },
  { date: "6/4/2019 16:04:52", shift: "C1", acuity: 2 },
  { date: "6/4/2019 16:05:02", shift: "A2", acuity: 3 },
  { date: "6/4/2019 16:34:55", shift: "C2", acuity: 3 },
  { date: "6/4/2019 16:45:54", shift: "B2", acuity: 2 },
  { date: "6/4/2019 16:46:34", shift: "D1", acuity: 3 },
  { date: "6/4/2019 16:46:44", shift: "A2", acuity: 2 },
  { date: "6/4/2019 16:47:02", shift: "C2", acuity: 3 },
  { date: "6/4/2019 16:48:34", shift: "B2", acuity: 2 },
  { date: "6/4/2019 16:54:48", shift: "D1", acuity: 3 },
  { date: "6/4/2019 16:54:57", shift: "A2", acuity: 3 },
  { date: "6/4/2019 16:55:08", shift: "B2", acuity: 3 },
  { date: "6/4/2019 17:07:10", shift: "C2", acuity: 3 },
  { date: "6/4/2019 17:24:46", shift: "C2", acuity: 3 },
  { date: "6/4/2019 17:30:45", shift: "D1", acuity: 2 },
  { date: "6/4/2019 17:30:55", shift: "A2", acuity: 2 },
  { date: "6/4/2019 17:41:03", shift: "B2", acuity: 3 },
  { date: "6/4/2019 17:50:07", shift: "C2", acuity: 2 },
  { date: "6/4/2019 17:52:38", shift: "D1", acuity: 2 },
  { date: "6/4/2019 18:21:47", shift: "D2", acuity: 2 },
  { date: "6/4/2019 18:33:41", shift: "D2", acuity: 3 },
  { date: "6/4/2019 18:37:47", shift: "D2", acuity: 2 },
  { date: "6/4/2019 18:47:30", shift: "A2", acuity: 3 },
  { date: "6/4/2019 19:21:50", shift: "B2", acuity: 2 },
  { date: "6/4/2019 19:28:47", shift: "C2", acuity: 2 },
  { date: "6/4/2019 19:36:01", shift: "D2", acuity: 2 },
  { date: "6/4/2019 19:39:16", shift: "A2", acuity: 2 },
  { date: "6/4/2019 19:48:04", shift: "B2", acuity: 3 },
  { date: "6/4/2019 19:48:15", shift: "C2", acuity: 3 },
  { date: "6/4/2019 19:48:36", shift: "D2", acuity: 2 },
  { date: "6/4/2019 19:48:49", shift: "A2", acuity: 3 },
  { date: "6/4/2019 19:53:05", shift: "B2", acuity: 2 },
  { date: "6/4/2019 19:57:43", shift: "C2", acuity: 2 },
  { date: "6/4/2019 20:16:20", shift: "D2", acuity: 3 },
  { date: "6/4/2019 20:17:52", shift: "A2", acuity: 3 },
  { date: "6/4/2019 20:22:31", shift: "B2", acuity: 2 },
  { date: "6/4/2019 20:22:48", shift: "C2", acuity: 2 },
  { date: "6/4/2019 20:40:47", shift: "D2", acuity: 3 },
  { date: "6/4/2019 20:41:00", shift: "A2", acuity: 2 },
  { date: "6/4/2019 20:43:06", shift: "B2", acuity: 2 },
  { date: "6/4/2019 21:02:08", shift: "A3", acuity: 3 },
  { date: "6/4/2019 21:02:22", shift: "C2", acuity: 2 },
  { date: "6/4/2019 21:13:26", shift: "D2", acuity: 3 },
  { date: "6/4/2019 21:19:56", shift: "B2", acuity: 2 },
  { date: "6/4/2019 21:33:04", shift: "A3", acuity: 2 },
  { date: "6/4/2019 21:33:28", shift: "A3", acuity: 3 },
  { date: "6/4/2019 21:33:55", shift: "C2", acuity: 2 },
  { date: "6/4/2019 21:36:12", shift: "D2", acuity: 2 },
  { date: "6/4/2019 21:53:01", shift: "B2", acuity: 3 },
  { date: "6/4/2019 21:57:22", shift: "A3", acuity: 2 },
  { date: "6/4/2019 21:57:39", shift: "C2", acuity: 5 },
  { date: "6/4/2019 21:57:53", shift: "D2", acuity: 4 },
  { date: "6/4/2019 22:57:38", shift: "B3", acuity: 4 },
  { date: "6/4/2019 22:57:44", shift: "B3", acuity: 4 },
  { date: "6/4/2019 23:02:19", shift: "B3", acuity: 3 },
  { date: "6/4/2019 23:17:26", shift: "A3", acuity: 3 },
  { date: "6/4/2019 23:48:35", shift: "C2", acuity: 3 },
  { date: "6/4/2019 23:51:12", shift: "D2", acuity: 3 },
  { date: "6/4/2019 23:56:35", shift: "B3", acuity: 4 },
  { date: "6/5/2019 00:06:23", shift: "A3", acuity: 4 },
  { date: "6/5/2019 00:07:27", shift: "D2", acuity: 4 },
  { date: "6/5/2019 00:07:39", shift: "B3", acuity: 3 },
  { date: "6/5/2019 00:28:16", shift: "A3", acuity: 4 },
  { date: "6/5/2019 00:28:36", shift: "D2", acuity: 3 },
  { date: "6/5/2019 00:28:52", shift: "B3", acuity: 4 },
  { date: "6/5/2019 00:29:06", shift: "A3", acuity: 4 },
  { date: "6/5/2019 00:43:49", shift: "D2", acuity: 4 },
  { date: "6/5/2019 00:49:53", shift: "B3", acuity: 3 },
  { date: "6/5/2019 00:52:49", shift: "A3", acuity: 4 },
  { date: "6/5/2019 01:06:34", shift: "D2", acuity: 2 },
  { date: "6/5/2019 01:26:49", shift: "B3", acuity: 3 },
  { date: "6/5/2019 01:41:03", shift: "A3", acuity: 3 },
  { date: "6/5/2019 01:59:57", shift: "B3", acuity: 3 },
  { date: "6/5/2019 02:14:24", shift: "A3", acuity: 2 },
  { date: "6/5/2019 03:49:08", shift: "B3", acuity: 3 },
  { date: "6/5/2019 03:58:49", shift: "A3", acuity: 3 },
  { date: "6/5/2019 04:01:21", shift: "B3", acuity: 3 },
  { date: "6/5/2019 05:28:05", shift: "A1", acuity: 3 },
  { date: "6/5/2019 05:30:28", shift: "A1", acuity: 2 },
  { date: "6/5/2019 05:35:59", shift: "A1", acuity: 2 },
  { date: "6/5/2019 05:38:57", shift: "B3", acuity: 4 },
  { date: "6/5/2019 06:03:11", shift: "B1", acuity: 5 },
  { date: "6/5/2019 06:03:25", shift: "A1", acuity: 4 },
  { date: "6/5/2019 06:36:30", shift: "B1", acuity: 3 },
  { date: "6/5/2019 07:11:00", shift: "B1", acuity: 2 },
  { date: "6/5/2019 07:17:28", shift: "A1", acuity: 3 },
  { date: "6/5/2019 07:39:45", shift: "B1", acuity: 3 },
  { date: "6/5/2019 08:13:15", shift: "A1", acuity: 2 },
  { date: "6/5/2019 08:28:26", shift: "B1", acuity: 3 },
  { date: "6/5/2019 08:38:39", shift: "C1", acuity: 2 },
  { date: "6/5/2019 08:50:51", shift: "A1", acuity: 4 },
  { date: "6/5/2019 08:51:18", shift: "B1", acuity: 7 },
  { date: "6/5/2019 08:56:35", shift: "C1", acuity: 4 },
  { date: "6/5/2019 09:07:52", shift: "C1", acuity: 3 },
  { date: "6/5/2019 09:08:08", shift: "C1", acuity: 3 },
  { date: "6/5/2019 09:24:28", shift: "A1", acuity: 2 },
  { date: "6/5/2019 09:24:38", shift: "B1", acuity: 2 },
  { date: "6/5/2019 09:41:34", shift: "C1", acuity: 3 },
  { date: "6/5/2019 09:41:49", shift: "A1", acuity: 3 },
  { date: "6/5/2019 09:46:51", shift: "B1", acuity: 2 },
  { date: "6/5/2019 10:01:55", shift: "D1", acuity: 3 },
  { date: "6/5/2019 10:04:55", shift: "C1", acuity: 3 },
  { date: "6/5/2019 10:09:01", shift: "A1", acuity: 3 },
  { date: "6/5/2019 10:20:03", shift: "B1", acuity: 3 },
  { date: "6/5/2019 10:20:17", shift: "D1", acuity: 3 },
  { date: "6/5/2019 10:34:53", shift: "D1", acuity: 3 },
  { date: "6/5/2019 10:58:26", shift: "D1", acuity: 3 },
  { date: "6/5/2019 11:00:30", shift: "C1", acuity: 2 },
  { date: "6/5/2019 11:10:08", shift: "A1", acuity: 3 },
  { date: "6/5/2019 11:31:57", shift: "B1", acuity: 2 },
  { date: "6/5/2019 11:32:26", shift: "D1", acuity: 4 },
  { date: "6/5/2019 11:44:24", shift: "C1", acuity: 4 },
  { date: "6/5/2019 11:57:46", shift: "A1", acuity: 3 },
  { date: "6/5/2019 12:05:26", shift: "B1", acuity: 3 },
  { date: "6/5/2019 12:10:13", shift: "D1", acuity: 2 },
  { date: "6/5/2019 12:14:15", shift: "C1", acuity: 2 },
  { date: "6/5/2019 12:22:30", shift: "A1", acuity: 3 },
  { date: "6/5/2019 12:36:53", shift: "B1", acuity: 2 },
  { date: "6/5/2019 12:53:16", shift: "D1", acuity: 2 },
  { date: "6/5/2019 13:06:09", shift: "A2", acuity: 3 },
  { date: "6/5/2019 13:12:57", shift: "C1", acuity: 2 },
  { date: "6/5/2019 13:13:26", shift: "B1", acuity: 3 },
  { date: "6/5/2019 13:16:19", shift: "D1", acuity: 3 },
  { date: "6/5/2019 13:16:55", shift: "A2", acuity: 2 },
  { date: "6/5/2019 13:27:10", shift: "C1", acuity: 3 },
  { date: "6/5/2019 13:38:56", shift: "A2", acuity: 2 },
  { date: "6/5/2019 13:44:18", shift: "A2", acuity: 2 },
  { date: "6/5/2019 13:55:14", shift: "B1", acuity: 2 },
  { date: "6/5/2019 14:17:32", shift: "B2", acuity: 2 },
  { date: "6/5/2019 14:18:11", shift: "D1", acuity: 2 },
  { date: "6/5/2019 14:33:44", shift: "B2", acuity: 3 },
  { date: "6/5/2019 14:43:46", shift: "B2", acuity: 3 },
  { date: "6/5/2019 14:52:48", shift: "C1", acuity: 2 },
  { date: "6/5/2019 14:53:34", shift: "A2", acuity: 2 },
  { date: "6/5/2019 14:58:20", shift: "D1", acuity: 3 },
  { date: "6/5/2019 15:07:59", shift: "B2", acuity: 2 },
  { date: "6/5/2019 15:21:14", shift: "C1", acuity: 3 },
  { date: "6/5/2019 15:21:51", shift: "A2", acuity: 2 },
  { date: "6/5/2019 15:22:11", shift: "D1", acuity: 2 },
  { date: "6/5/2019 15:37:22", shift: "B2", acuity: 2 },
  { date: "6/5/2019 15:50:48", shift: "C1", acuity: 3 },
  { date: "6/5/2019 16:19:37", shift: "A2", acuity: 3 },
  { date: "6/5/2019 16:25:18", shift: "D1", acuity: 3 },
  { date: "6/5/2019 16:30:48", shift: "C2", acuity: 3 },
  { date: "6/5/2019 16:33:49", shift: "B2", acuity: 3 },
  { date: "6/5/2019 16:44:45", shift: "A2", acuity: 3 },
  { date: "6/5/2019 16:44:59", shift: "D1", acuity: 2 },
  { date: "6/5/2019 16:53:54", shift: "C2", acuity: 3 },
  { date: "6/5/2019 16:54:19", shift: "B2", acuity: 2 },
  { date: "6/5/2019 17:05:06", shift: "C2", acuity: 3 },
  { date: "6/5/2019 17:18:28", shift: "C2", acuity: 2 },
  { date: "6/5/2019 17:30:02", shift: "A2", acuity: 2 },
  { date: "6/5/2019 17:55:40", shift: "D1", acuity: 2 },
  { date: "6/5/2019 17:58:55", shift: "B2", acuity: 2 },
  { date: "6/5/2019 17:59:23", shift: "C2", acuity: 3 },
  { date: "6/5/2019 18:05:20", shift: "D2", acuity: 4 },
  { date: "6/5/2019 18:14:53", shift: "A2", acuity: 2 },
  { date: "6/5/2019 18:23:54", shift: "B2", acuity: 3 },
  { date: "6/5/2019 18:24:18", shift: "C2", acuity: 2 },
  { date: "6/5/2019 18:37:09", shift: "D2", acuity: 3 },
  { date: "6/5/2019 18:54:40", shift: "D2", acuity: 3 },
  { date: "6/5/2019 18:59:41", shift: "A2", acuity: 3 },
  { date: "6/5/2019 19:00:31", shift: "B2", acuity: 3 },
  { date: "6/5/2019 19:20:58", shift: "C2", acuity: 3 },
  { date: "6/5/2019 19:21:37", shift: "D2", acuity: 2 },
  { date: "6/5/2019 19:21:50", shift: "A2", acuity: 3 },
  { date: "6/5/2019 19:37:05", shift: "B2", acuity: 3 },
  { date: "6/5/2019 19:37:16", shift: "C2", acuity: 3 },
  { date: "6/5/2019 19:49:40", shift: "D2", acuity: 3 },
  { date: "6/5/2019 19:49:56", shift: "A2", acuity: 2 },
  { date: "6/5/2019 19:55:17", shift: "B2", acuity: 3 },
  { date: "6/5/2019 20:01:33", shift: "C2", acuity: 3 },
  { date: "6/5/2019 20:02:34", shift: "D2", acuity: 2 },
  { date: "6/5/2019 20:03:00", shift: "A2", acuity: 4 },
  { date: "6/5/2019 20:15:14", shift: "B2", acuity: 3 },
  { date: "6/5/2019 20:21:13", shift: "C2", acuity: 4 },
  { date: "6/5/2019 20:23:41", shift: "D2", acuity: 3 },
  { date: "6/5/2019 20:28:38", shift: "A2", acuity: 2 },
  { date: "6/5/2019 20:34:12", shift: "B2", acuity: 3 },
  { date: "6/5/2019 20:47:18", shift: "C2", acuity: 3 },
  { date: "6/5/2019 20:55:50", shift: "D2", acuity: 3 },
  { date: "6/5/2019 21:10:46", shift: "A3", acuity: 3 },
  { date: "6/5/2019 21:21:28", shift: "B2", acuity: 3 },
  { date: "6/5/2019 21:27:17", shift: "C2", acuity: 3 },
  { date: "6/5/2019 21:40:34", shift: "A3", acuity: 3 },
  { date: "6/5/2019 21:42:30", shift: "A3", acuity: 3 },
  { date: "6/5/2019 21:50:56", shift: "D2", acuity: 2 },
  { date: "6/5/2019 21:53:37", shift: "B2", acuity: 3 },
  { date: "6/5/2019 22:12:41", shift: "B3", acuity: 3 },
  { date: "6/5/2019 22:12:49", shift: "C2", acuity: 3 },
  { date: "6/5/2019 22:20:25", shift: "A3", acuity: 3 },
  { date: "6/5/2019 22:23:48", shift: "D2", acuity: 3 },
  { date: "6/5/2019 22:32:44", shift: "B3", acuity: 2 },
  { date: "6/5/2019 22:35:27", shift: "B3", acuity: 2 },
  { date: "6/5/2019 22:49:18", shift: "C2", acuity: 3 },
  { date: "6/5/2019 23:02:04", shift: "A3", acuity: 4 },
  { date: "6/5/2019 23:10:16", shift: "D2", acuity: 3 },
  { date: "6/5/2019 23:10:47", shift: "B3", acuity: 3 },
  { date: "6/5/2019 23:19:58", shift: "C2", acuity: 3 },
  { date: "6/5/2019 23:34:03", shift: "A3", acuity: 4 },
  { date: "6/5/2019 23:40:33", shift: "D2", acuity: 2 },
  { date: "6/6/2019 00:28:17", shift: "B3", acuity: 4 },
  { date: "6/6/2019 00:28:46", shift: "A3", acuity: 4 },
  { date: "6/6/2019 00:29:18", shift: "D2", acuity: 3 },
  { date: "6/6/2019 00:39:41", shift: "B3", acuity: 5 },
  { date: "6/6/2019 00:59:10", shift: "A3", acuity: 4 },
  { date: "6/6/2019 01:11:52", shift: "D2", acuity: 3 },
  { date: "6/6/2019 01:52:35", shift: "B3", acuity: 3 },
  { date: "6/6/2019 01:56:58", shift: "A3", acuity: 3 },
  { date: "6/6/2019 03:22:19", shift: "B3", acuity: 4 },
  { date: "6/6/2019 03:22:58", shift: "A3", acuity: 2 },
  { date: "6/6/2019 04:10:02", shift: "B3", acuity: 3 },
  { date: "6/6/2019 04:13:33", shift: "A3", acuity: 3 },
  { date: "6/6/2019 04:20:10", shift: "B3", acuity: 4 },
  { date: "6/6/2019 04:27:43", shift: "A3", acuity: 3 },
  { date: "6/6/2019 04:30:16", shift: "B3", acuity: 2 },
  { date: "6/6/2019 05:00:27", shift: "A1", acuity: 3 },
  { date: "6/6/2019 05:00:40", shift: "B3", acuity: 4 },
  { date: "6/6/2019 05:49:33", shift: "A1", acuity: 3 },
  { date: "6/6/2019 06:43:46", shift: "A1", acuity: 3 },
  { date: "6/6/2019 06:53:19", shift: "B1", acuity: 4 },
  { date: "6/6/2019 06:58:25", shift: "B1", acuity: 3 },
  { date: "6/6/2019 07:06:39", shift: "B1", acuity: 3 },
  { date: "6/6/2019 07:20:09", shift: "A1", acuity: 2 },
  { date: "6/6/2019 07:25:00", shift: "B1", acuity: 2 },
  { date: "6/6/2019 07:56:46", shift: "A1", acuity: 3 },
  { date: "6/6/2019 08:33:06", shift: "C1", acuity: 3 },
  { date: "6/6/2019 08:37:41", shift: "B1", acuity: 3 },
  { date: "6/6/2019 08:45:08", shift: "A1", acuity: 4 },
  { date: "6/6/2019 08:56:39", shift: "C1", acuity: 2 },
  { date: "6/6/2019 09:02:04", shift: "C1", acuity: 2 },
  { date: "6/6/2019 09:15:32", shift: "C1", acuity: 3 },
  { date: "6/6/2019 09:16:40", shift: "B1", acuity: 2 },
  { date: "6/6/2019 09:23:28", shift: "A1", acuity: 2 },
  { date: "6/6/2019 09:24:43", shift: "C1", acuity: 3 },
  { date: "6/6/2019 09:30:15", shift: "B1", acuity: 3 },
  { date: "6/6/2019 09:35:30", shift: "A1", acuity: 2 },
  { date: "6/6/2019 09:46:21", shift: "C1", acuity: 3 },
  { date: "6/6/2019 09:50:58", shift: "B1", acuity: 2 },
  { date: "6/6/2019 09:52:59", shift: "A1", acuity: 2 },
  { date: "6/6/2019 10:10:21", shift: "D1", acuity: 2 },
  { date: "6/6/2019 10:10:53", shift: "C1", acuity: 2 },
  { date: "6/6/2019 10:30:50", shift: "D1", acuity: 3 },
  { date: "6/6/2019 10:42:28", shift: "D1", acuity: 3 },
  { date: "6/6/2019 10:42:46", shift: "B1", acuity: 2 },
  { date: "6/6/2019 11:01:52", shift: "A1", acuity: 2 },
  { date: "6/6/2019 11:38:56", shift: "C1", acuity: 3 },
  { date: "6/6/2019 11:39:58", shift: "D1", acuity: 3 },
  { date: "6/6/2019 11:44:40", shift: "B1", acuity: 3 },
  { date: "6/6/2019 11:48:41", shift: "A1", acuity: 2 },
  { date: "6/6/2019 11:49:03", shift: "C1", acuity: 3 },
  { date: "6/6/2019 12:01:49", shift: "D1", acuity: 2 },
  { date: "6/6/2019 12:14:38", shift: "B1", acuity: 3 },
  { date: "6/6/2019 12:15:26", shift: "A1", acuity: 2 },
  { date: "6/6/2019 12:36:39", shift: "C1", acuity: 2 },
  { date: "6/6/2019 12:37:09", shift: "D1", acuity: 3 },
  { date: "6/6/2019 12:37:29", shift: "B1", acuity: 3 },
  { date: "6/6/2019 13:01:52", shift: "A2", acuity: 3 },
  { date: "6/6/2019 13:06:32", shift: "C1", acuity: 2 },
  { date: "6/6/2019 13:21:20", shift: "D1", acuity: 3 },
  { date: "6/6/2019 13:42:34", shift: "A2", acuity: 2 },
  { date: "6/6/2019 13:42:49", shift: "A2", acuity: 2 },
  { date: "6/6/2019 14:09:58", shift: "B2", acuity: 3 },
  { date: "6/6/2019 14:20:52", shift: "C1", acuity: 2 },
  { date: "6/6/2019 14:25:42", shift: "D1", acuity: 3 },
  { date: "6/6/2019 14:57:51", shift: "B2", acuity: 2 },
  { date: "6/6/2019 15:06:01", shift: "B2", acuity: 2 },
  { date: "6/6/2019 15:12:38", shift: "A2", acuity: 3 },
  { date: "6/6/2019 15:21:46", shift: "C1", acuity: 3 },
  { date: "6/6/2019 15:22:09", shift: "D1", acuity: 3 },
  { date: "6/6/2019 15:27:57", shift: "B2", acuity: 3 },
  { date: "6/6/2019 15:37:16", shift: "A2", acuity: 3 },
  { date: "6/6/2019 15:37:45", shift: "C1", acuity: 3 },
  { date: "6/6/2019 16:01:14", shift: "D1", acuity: 3 },
  { date: "6/6/2019 16:01:36", shift: "B2", acuity: 2 },
  { date: "6/6/2019 16:21:17", shift: "A2", acuity: 3 },
  { date: "6/6/2019 16:43:36", shift: "C2", acuity: 2 },
  { date: "6/6/2019 16:44:10", shift: "D1", acuity: 3 },
  { date: "6/6/2019 16:45:15", shift: "B2", acuity: 3 },
  { date: "6/6/2019 16:58:50", shift: "A2", acuity: 2 },
  { date: "6/6/2019 17:00:41", shift: "C2", acuity: 3 },
  { date: "6/6/2019 17:01:00", shift: "C2", acuity: 2 },
  { date: "6/6/2019 17:22:56", shift: "D1", acuity: 3 },
  { date: "6/6/2019 17:32:00", shift: "B2", acuity: 3 },
  { date: "6/6/2019 17:49:28", shift: "A2", acuity: 2 },
  { date: "6/6/2019 17:57:06", shift: "C2", acuity: 3 },
  { date: "6/6/2019 18:05:07", shift: "D2", acuity: 3 },
  { date: "6/6/2019 18:05:25", shift: "B2", acuity: 2 },
  { date: "6/6/2019 18:17:23", shift: "A2", acuity: 3 },
  { date: "6/6/2019 18:20:32", shift: "C2", acuity: 7 },
  { date: "6/6/2019 18:25:27", shift: "D2", acuity: 3 },
  { date: "6/6/2019 18:34:18", shift: "D2", acuity: 3 },
  { date: "6/6/2019 18:37:29", shift: "D2", acuity: 2 },
  { date: "6/6/2019 18:39:18", shift: "B2", acuity: 4 },
  { date: "6/6/2019 18:41:42", shift: "A2", acuity: 3 },
  { date: "6/6/2019 18:59:40", shift: "C2", acuity: 7 },
  { date: "6/6/2019 19:03:31", shift: "D2", acuity: 3 },
  { date: "6/6/2019 19:11:00", shift: "B2", acuity: 3 },
  { date: "6/6/2019 19:19:19", shift: "A2", acuity: 3 },
  { date: "6/6/2019 19:19:36", shift: "C2", acuity: 3 },
  { date: "6/6/2019 19:32:08", shift: "D2", acuity: 3 },
  { date: "6/6/2019 19:46:03", shift: "B2", acuity: 3 },
  { date: "6/6/2019 19:46:30", shift: "A2", acuity: 4 },
  { date: "6/6/2019 19:57:55", shift: "C2", acuity: 3 },
  { date: "6/6/2019 20:07:56", shift: "D2", acuity: 3 },
  { date: "6/6/2019 20:09:08", shift: "B2", acuity: 3 },
  { date: "6/6/2019 20:18:25", shift: "A2", acuity: 3 },
  { date: "6/6/2019 20:38:37", shift: "C2", acuity: 3 },
  { date: "6/6/2019 20:47:35", shift: "D2", acuity: 3 },
  { date: "6/6/2019 20:52:06", shift: "B2", acuity: 3 },
  { date: "6/6/2019 20:52:40", shift: "A2", acuity: 2 },
  { date: "6/6/2019 20:59:25", shift: "C2", acuity: 2 },
  { date: "6/6/2019 21:10:56", shift: "A3", acuity: 2 },
  { date: "6/6/2019 21:18:42", shift: "D2", acuity: 4 },
  { date: "6/6/2019 21:19:36", shift: "B2", acuity: 2 },
  { date: "6/6/2019 21:26:13", shift: "C2", acuity: 2 },
  { date: "6/6/2019 21:43:18", shift: "A3", acuity: 3 },
  { date: "6/6/2019 21:43:38", shift: "A3", acuity: 3 },
  { date: "6/6/2019 21:50:18", shift: "D2", acuity: 3 },
  { date: "6/6/2019 22:06:58", shift: "B3", acuity: 3 },
  { date: "6/6/2019 22:08:16", shift: "C2", acuity: 3 },
  { date: "6/6/2019 22:15:55", shift: "A3", acuity: 3 },
  { date: "6/6/2019 22:21:47", shift: "D2", acuity: 3 },
  { date: "6/6/2019 22:22:02", shift: "B3", acuity: 3 },
  { date: "6/6/2019 22:38:13", shift: "B3", acuity: 4 },
  { date: "6/6/2019 22:43:59", shift: "B3", acuity: 7 },
  { date: "6/6/2019 22:56:27", shift: "C2", acuity: 3 },
  { date: "6/6/2019 22:56:41", shift: "A3", acuity: 4 },
  { date: "6/6/2019 22:58:01", shift: "D2", acuity: 2 },
  { date: "6/6/2019 23:09:01", shift: "B3", acuity: 3 },
  { date: "6/6/2019 23:17:36", shift: "C2", acuity: 2 },
  { date: "6/6/2019 23:18:05", shift: "A3", acuity: 3 },
  { date: "6/6/2019 23:20:23", shift: "D2", acuity: 4 },
  { date: "6/6/2019 23:23:01", shift: "B3", acuity: 4 },
  { date: "6/6/2019 23:30:36", shift: "C2", acuity: 2 },
  { date: "6/6/2019 23:30:55", shift: "A3", acuity: 3 },
  { date: "6/7/2019 00:06:07", shift: "D2", acuity: 4 },
  { date: "6/7/2019 00:06:37", shift: "B3", acuity: 4 },
  { date: "6/7/2019 00:55:25", shift: "A3", acuity: 4 },
  { date: "6/7/2019 00:55:49", shift: "D2", acuity: 5 },
  { date: "6/7/2019 00:58:44", shift: "B3", acuity: 2 },
  { date: "6/7/2019 01:08:48", shift: "A3", acuity: 3 },
  { date: "6/7/2019 01:28:44", shift: "D2", acuity: 3 },
  { date: "6/7/2019 01:42:29", shift: "B3", acuity: 4 },
  { date: "6/7/2019 01:42:43", shift: "A3", acuity: 3 },
  { date: "6/7/2019 01:43:11", shift: "B3", acuity: 4 },
  { date: "6/7/2019 02:24:01", shift: "A3", acuity: 2 },
  { date: "6/7/2019 02:42:15", shift: "B3", acuity: 7 },
  { date: "6/7/2019 02:55:47", shift: "A3", acuity: 2 },
  { date: "6/7/2019 02:55:59", shift: "B3", acuity: 4 },
  { date: "6/7/2019 03:15:24", shift: "A3", acuity: 3 },
  { date: "6/7/2019 03:22:35", shift: "B3", acuity: 4 },
  { date: "6/7/2019 03:23:03", shift: "A3", acuity: 4 },
  { date: "6/7/2019 03:31:01", shift: "B3", acuity: 4 },
  { date: "6/7/2019 03:46:18", shift: "A3", acuity: 3 },
  { date: "6/7/2019 04:08:49", shift: "B3", acuity: 2 },
  { date: "6/7/2019 04:09:02", shift: "A3", acuity: 3 },
  { date: "6/7/2019 04:13:31", shift: "B3", acuity: 3 },
  { date: "6/7/2019 04:41:16", shift: "A3", acuity: 3 },
  { date: "6/7/2019 05:05:39", shift: "A1", acuity: 3 },
  { date: "6/7/2019 05:05:56", shift: "B3", acuity: 5 },
  { date: "6/7/2019 05:33:26", shift: "A1", acuity: 5 },
  { date: "6/7/2019 05:38:45", shift: "A1", acuity: 2 },
  { date: "6/7/2019 05:57:23", shift: "B3", acuity: 2 },
  { date: "6/7/2019 06:18:16", shift: "B1", acuity: 2 },
  { date: "6/7/2019 06:22:19", shift: "A1", acuity: 2 },
  { date: "6/7/2019 06:26:40", shift: "B1", acuity: 3 },
  { date: "6/7/2019 06:27:14", shift: "A1", acuity: 2 },
  { date: "6/7/2019 07:27:55", shift: "B1", acuity: 2 },
  { date: "6/7/2019 07:39:36", shift: "B1", acuity: 3 },
  { date: "6/7/2019 07:55:37", shift: "A1", acuity: 3 },
  { date: "6/7/2019 07:57:25", shift: "B1", acuity: 3 },
  { date: "6/7/2019 07:59:02", shift: "A1", acuity: 4 },
  { date: "6/7/2019 08:47:37", shift: "C1", acuity: 3 },
  { date: "6/7/2019 08:48:00", shift: "B1", acuity: 2 },
  { date: "6/7/2019 09:01:57", shift: "C1", acuity: 4 },
  { date: "6/7/2019 09:12:12", shift: "C1", acuity: 3 },
  { date: "6/7/2019 09:18:43", shift: "A1", acuity: 4 },
  { date: "6/7/2019 09:19:29", shift: "B1", acuity: 4 },
  { date: "6/7/2019 09:19:53", shift: "C1", acuity: 4 },
  { date: "6/7/2019 09:58:24", shift: "A1", acuity: 4 },
  { date: "6/7/2019 09:58:45", shift: "B1", acuity: 3 },
  { date: "6/7/2019 10:19:09", shift: "D1", acuity: 3 },
  { date: "6/7/2019 10:21:29", shift: "C1", acuity: 2 },
  { date: "6/7/2019 10:32:57", shift: "D1", acuity: 2 },
  { date: "6/7/2019 10:36:26", shift: "D1", acuity: 4 },
  { date: "6/7/2019 10:39:31", shift: "A1", acuity: 4 },
  { date: "6/7/2019 10:56:04", shift: "B1", acuity: 4 },
  { date: "6/7/2019 11:09:26", shift: "C1", acuity: 2 },
  { date: "6/7/2019 11:10:26", shift: "D1", acuity: 3 },
  { date: "6/7/2019 11:10:44", shift: "A1", acuity: 3 },
  { date: "6/7/2019 11:36:07", shift: "B1", acuity: 3 },
  { date: "6/7/2019 11:36:50", shift: "C1", acuity: 3 },
  { date: "6/7/2019 11:52:43", shift: "D1", acuity: 3 },
  { date: "6/7/2019 11:53:07", shift: "A1", acuity: 3 },
  { date: "6/7/2019 11:56:13", shift: "B1", acuity: 3 },
  { date: "6/7/2019 12:25:26", shift: "C1", acuity: 3 },
  { date: "6/7/2019 12:33:17", shift: "D1", acuity: 2 },
  { date: "6/7/2019 12:43:19", shift: "A1", acuity: 3 },
  { date: "6/7/2019 12:46:03", shift: "B1", acuity: 3 },
  { date: "6/7/2019 13:12:23", shift: "A2", acuity: 3 },
  { date: "6/7/2019 13:35:07", shift: "A2", acuity: 3 },
  { date: "6/7/2019 13:35:24", shift: "A2", acuity: 3 },
  { date: "6/7/2019 13:43:01", shift: "C1", acuity: 2 },
  { date: "6/7/2019 13:58:50", shift: "D1", acuity: 2 },
  { date: "6/7/2019 14:06:00", shift: "B2", acuity: 4 },
  { date: "6/7/2019 14:22:09", shift: "A2", acuity: 3 },
  { date: "6/7/2019 14:31:28", shift: "B2", acuity: 3 },
  { date: "6/7/2019 14:46:21", shift: "B2", acuity: 2 },
  { date: "6/7/2019 14:46:39", shift: "C1", acuity: 3 },
  { date: "6/7/2019 14:53:08", shift: "D1", acuity: 3 },
  { date: "6/7/2019 14:53:52", shift: "A2", acuity: 3 },
  { date: "6/7/2019 14:57:06", shift: "B2", acuity: 7 },
  { date: "6/7/2019 15:19:27", shift: "C1", acuity: 2 },
  { date: "6/7/2019 15:19:43", shift: "D1", acuity: 3 },
  { date: "6/7/2019 15:32:59", shift: "A2", acuity: 7 },
  { date: "6/7/2019 15:42:30", shift: "B2", acuity: 7 },
  { date: "6/7/2019 15:46:02", shift: "C1", acuity: 2 },
  { date: "6/7/2019 15:48:13", shift: "D1", acuity: 2 },
  { date: "6/7/2019 16:05:48", shift: "A2", acuity: 7 },
  { date: "6/7/2019 16:12:17", shift: "B2", acuity: 2 },
  { date: "6/7/2019 16:38:41", shift: "C2", acuity: 3 },
  { date: "6/7/2019 16:39:14", shift: "D1", acuity: 3 },
  { date: "6/7/2019 16:50:07", shift: "A2", acuity: 2 },
  { date: "6/7/2019 16:55:38", shift: "B2", acuity: 3 },
  { date: "6/7/2019 17:00:35", shift: "C2", acuity: 3 },
  { date: "6/7/2019 17:18:32", shift: "C2", acuity: 2 },
  { date: "6/7/2019 17:27:07", shift: "D1", acuity: 2 },
  { date: "6/7/2019 17:39:21", shift: "A2", acuity: 3 },
  { date: "6/7/2019 17:39:43", shift: "B2", acuity: 4 },
  { date: "6/7/2019 17:48:27", shift: "C2", acuity: 2 },
  { date: "6/7/2019 17:56:23", shift: "D1", acuity: 3 },
  { date: "6/7/2019 18:04:30", shift: "D2", acuity: 3 },
  { date: "6/7/2019 18:10:59", shift: "A2", acuity: 2 },
  { date: "6/7/2019 18:24:17", shift: "B2", acuity: 2 },
  { date: "6/7/2019 18:57:30", shift: "D2", acuity: 3 },
  { date: "6/7/2019 19:08:00", shift: "D2", acuity: 2 },
  { date: "6/7/2019 19:21:16", shift: "C2", acuity: 2 },
  { date: "6/7/2019 19:29:01", shift: "A2", acuity: 2 },
  { date: "6/7/2019 19:33:24", shift: "B2", acuity: 3 },
  { date: "6/7/2019 19:36:27", shift: "D2", acuity: 3 },
  { date: "6/7/2019 19:36:49", shift: "C2", acuity: 3 },
  { date: "6/7/2019 19:46:05", shift: "A2", acuity: 3 },
  { date: "6/7/2019 19:54:42", shift: "B2", acuity: 3 },
  { date: "6/7/2019 19:58:27", shift: "D2", acuity: 3 },
  { date: "6/7/2019 20:08:49", shift: "C2", acuity: 2 },
  { date: "6/7/2019 20:09:31", shift: "A2", acuity: 2 },
  { date: "6/7/2019 20:17:18", shift: "B2", acuity: 2 },
  { date: "6/7/2019 20:17:46", shift: "D2", acuity: 3 },
  { date: "6/7/2019 20:18:50", shift: "C2", acuity: 3 },
  { date: "6/7/2019 20:21:20", shift: "A2", acuity: 3 },
  { date: "6/7/2019 20:37:20", shift: "B2", acuity: 3 },
  { date: "6/7/2019 20:41:24", shift: "D2", acuity: 2 },
  { date: "6/7/2019 20:45:56", shift: "C2", acuity: 3 },
  { date: "6/7/2019 20:50:01", shift: "A2", acuity: 2 },
  { date: "6/7/2019 21:03:43", shift: "A3", acuity: 3 },
  { date: "6/7/2019 21:16:30", shift: "B2", acuity: 2 },
  { date: "6/7/2019 21:16:53", shift: "D2", acuity: 3 },
  { date: "6/7/2019 21:18:52", shift: "C2", acuity: 4 },
  { date: "6/7/2019 21:33:47", shift: "A3", acuity: 2 },
  { date: "6/7/2019 21:34:15", shift: "A3", acuity: 2 },
  { date: "6/7/2019 21:51:58", shift: "B2", acuity: 2 },
  { date: "6/7/2019 22:06:58", shift: "B3", acuity: 3 },
  { date: "6/7/2019 22:19:34", shift: "D2", acuity: 2 },
  { date: "6/7/2019 22:20:04", shift: "C2", acuity: 2 },
  { date: "6/7/2019 22:30:16", shift: "B3", acuity: 3 },
  { date: "6/7/2019 22:32:18", shift: "B3", acuity: 2 },
  { date: "6/7/2019 22:47:46", shift: "A3", acuity: 2 },
  { date: "6/7/2019 23:11:48", shift: "D2", acuity: 3 },
  { date: "6/7/2019 23:13:41", shift: "C2", acuity: 3 },
  { date: "6/7/2019 23:14:29", shift: "B3", acuity: 2 },
  { date: "6/7/2019 23:18:12", shift: "A3", acuity: 4 },
  { date: "6/7/2019 23:42:07", shift: "D2", acuity: 3 },
  { date: "6/7/2019 23:44:24", shift: "C2", acuity: 3 },
  { date: "6/8/2019 00:05:49", shift: "B3", acuity: 4 },
  { date: "6/8/2019 00:09:44", shift: "A3", acuity: 2 },
  { date: "6/8/2019 00:10:16", shift: "D2", acuity: 3 },
  { date: "6/8/2019 00:21:57", shift: "B3", acuity: 3 },
  { date: "6/8/2019 00:31:19", shift: "A3", acuity: 3 },
  { date: "6/8/2019 00:31:43", shift: "D2", acuity: 3 },
  { date: "6/8/2019 01:00:33", shift: "B3", acuity: 3 },
  { date: "6/8/2019 01:00:56", shift: "A3", acuity: 3 },
  { date: "6/8/2019 01:03:50", shift: "D2", acuity: 3 },
  { date: "6/8/2019 01:10:14", shift: "B3", acuity: 3 },
  { date: "6/8/2019 01:10:50", shift: "A3", acuity: 3 },
  { date: "6/8/2019 01:18:43", shift: "D2", acuity: 4 },
  { date: "6/8/2019 01:20:29", shift: "B3", acuity: 3 },
  { date: "6/8/2019 01:40:25", shift: "A3", acuity: 2 },
  { date: "6/8/2019 01:41:12", shift: "B3", acuity: 3 },
  { date: "6/8/2019 01:41:37", shift: "A3", acuity: 3 },
  { date: "6/8/2019 01:50:36", shift: "B3", acuity: 3 },
  { date: "6/8/2019 01:57:37", shift: "A3", acuity: 3 },
  { date: "6/8/2019 01:59:21", shift: "B3", acuity: 4 },
  { date: "6/8/2019 02:33:48", shift: "A3", acuity: 4 },
  { date: "6/8/2019 02:43:54", shift: "B3", acuity: 3 },
  { date: "6/8/2019 02:57:05", shift: "A3", acuity: 4 },
  { date: "6/8/2019 03:08:55", shift: "B3", acuity: 3 },
  { date: "6/8/2019 03:12:22", shift: "A3", acuity: 4 },
  { date: "6/8/2019 03:12:37", shift: "B3", acuity: 3 },
  { date: "6/8/2019 03:43:12", shift: "A3", acuity: 4 },
  { date: "6/8/2019 04:06:39", shift: "B3", acuity: 3 },
  { date: "6/8/2019 04:08:03", shift: "A3", acuity: 4 },
  { date: "6/8/2019 04:28:25", shift: "B3", acuity: 5 },
  { date: "6/8/2019 05:35:12", shift: "A1", acuity: 4 },
  { date: "6/8/2019 06:23:34", shift: "A1", acuity: 2 },
  { date: "6/8/2019 07:22:26", shift: "B1", acuity: 3 },
  { date: "6/8/2019 07:42:51", shift: "B1", acuity: 5 },
  { date: "6/8/2019 07:51:02", shift: "B1", acuity: 4 },
  { date: "6/8/2019 08:01:28", shift: "A1", acuity: 2 },
  { date: "6/8/2019 08:14:26", shift: "B1", acuity: 3 },
  { date: "6/8/2019 08:20:29", shift: "A1", acuity: 4 },
  { date: "6/8/2019 08:58:48", shift: "C1", acuity: 3 },
  { date: "6/8/2019 09:05:25", shift: "C1", acuity: 3 },
  { date: "6/8/2019 09:21:30", shift: "C1", acuity: 2 },
  { date: "6/8/2019 09:51:05", shift: "B1", acuity: 3 },
  { date: "6/8/2019 09:54:03", shift: "A1", acuity: 3 },
  { date: "6/8/2019 09:54:30", shift: "C1", acuity: 2 },
  { date: "6/8/2019 10:26:58", shift: "D1", acuity: 3 },
  { date: "6/8/2019 10:46:40", shift: "D1", acuity: 5 },
  { date: "6/8/2019 11:03:43", shift: "D1", acuity: 3 },
  { date: "6/8/2019 11:07:32", shift: "B1", acuity: 2 },
  { date: "6/8/2019 11:09:27", shift: "A1", acuity: 2 },
  { date: "6/8/2019 11:13:42", shift: "C1", acuity: 2 },
  { date: "6/8/2019 11:18:39", shift: "D1", acuity: 3 },
  { date: "6/8/2019 11:21:50", shift: "B1", acuity: 2 },
  { date: "6/8/2019 11:33:04", shift: "A1", acuity: 2 },
  { date: "6/8/2019 11:38:07", shift: "C1", acuity: 3 },
  { date: "6/8/2019 11:47:42", shift: "D1", acuity: 3 },
  { date: "6/8/2019 11:59:42", shift: "B1", acuity: 2 },
  { date: "6/8/2019 12:05:22", shift: "A1", acuity: 2 },
  { date: "6/8/2019 12:05:43", shift: "C1", acuity: 3 },
  { date: "6/8/2019 12:32:54", shift: "D1", acuity: 3 },
  { date: "6/8/2019 12:46:54", shift: "B1", acuity: 2 },
  { date: "6/8/2019 13:02:48", shift: "A2", acuity: 2 },
  { date: "6/8/2019 13:16:02", shift: "C1", acuity: 2 },
  { date: "6/8/2019 13:20:09", shift: "D1", acuity: 2 },
  { date: "6/8/2019 13:48:25", shift: "A2", acuity: 2 },
  { date: "6/8/2019 14:03:35", shift: "A2", acuity: 2 },
  { date: "6/8/2019 14:04:04", shift: "B2", acuity: 2 },
  { date: "6/8/2019 14:31:38", shift: "B2", acuity: 2 },
  { date: "6/8/2019 14:32:29", shift: "B2", acuity: 3 },
  { date: "6/8/2019 14:53:10", shift: "C1", acuity: 2 },
  { date: "6/8/2019 14:56:35", shift: "D1", acuity: 2 },
  { date: "6/8/2019 14:59:49", shift: "A2", acuity: 3 },
  { date: "6/8/2019 15:15:59", shift: "B2", acuity: 2 },
  { date: "6/8/2019 15:22:50", shift: "C1", acuity: 3 },
  { date: "6/8/2019 15:38:16", shift: "D1", acuity: 3 },
  { date: "6/8/2019 15:40:17", shift: "A2", acuity: 2 },
  { date: "6/8/2019 15:57:32", shift: "B2", acuity: 3 },
  { date: "6/8/2019 16:12:08", shift: "C1", acuity: 3 },
  { date: "6/8/2019 16:12:44", shift: "D1", acuity: 4 },
  { date: "6/8/2019 16:12:59", shift: "A2", acuity: 3 },
  { date: "6/8/2019 16:28:29", shift: "B2", acuity: 3 },
  { date: "6/8/2019 16:43:01", shift: "C2", acuity: 3 },
  { date: "6/8/2019 16:45:15", shift: "D1", acuity: 2 },
  { date: "6/8/2019 16:58:55", shift: "A2", acuity: 3 },
  { date: "6/8/2019 17:12:40", shift: "C2", acuity: 2 },
  { date: "6/8/2019 17:12:56", shift: "C2", acuity: 3 },
  { date: "6/8/2019 17:26:49", shift: "B2", acuity: 2 },
  { date: "6/8/2019 17:31:25", shift: "D1", acuity: 3 },
  { date: "6/8/2019 18:01:40", shift: "D2", acuity: 3 },
  { date: "6/8/2019 18:02:18", shift: "A2", acuity: 2 },
  { date: "6/8/2019 18:02:26", shift: "C2", acuity: 3 },
  { date: "6/8/2019 18:04:45", shift: "B2", acuity: 3 },
  { date: "6/8/2019 18:30:16", shift: "D2", acuity: 2 },
  { date: "6/8/2019 18:30:28", shift: "D2", acuity: 2 },
  { date: "6/8/2019 18:30:45", shift: "A2", acuity: 4 },
  { date: "6/8/2019 18:45:05", shift: "C2", acuity: 3 },
  { date: "6/8/2019 18:46:49", shift: "B2", acuity: 2 },
  { date: "6/8/2019 18:51:05", shift: "D2", acuity: 2 },
  { date: "6/8/2019 19:17:26", shift: "A2", acuity: 2 },
  { date: "6/8/2019 19:19:08", shift: "C2", acuity: 2 },
  { date: "6/8/2019 19:19:29", shift: "B2", acuity: 3 },
  { date: "6/8/2019 19:20:40", shift: "D2", acuity: 2 },
  { date: "6/8/2019 19:36:51", shift: "A2", acuity: 2 },
  { date: "6/8/2019 19:46:30", shift: "C2", acuity: 3 },
  { date: "6/8/2019 19:53:02", shift: "B2", acuity: 3 },
  { date: "6/8/2019 20:00:16", shift: "D2", acuity: 3 },
  { date: "6/8/2019 20:07:27", shift: "A2", acuity: 3 },
  { date: "6/8/2019 20:07:45", shift: "C2", acuity: 2 },
  { date: "6/8/2019 20:08:37", shift: "B2", acuity: 2 },
  { date: "6/8/2019 20:39:46", shift: "D2", acuity: 4 },
  { date: "6/8/2019 20:58:26", shift: "A2", acuity: 3 },
  { date: "6/8/2019 21:10:42", shift: "A3", acuity: 4 },
  { date: "6/8/2019 21:30:41", shift: "A3", acuity: 3 },
  { date: "6/8/2019 21:41:38", shift: "A3", acuity: 3 },
  { date: "6/8/2019 21:46:02", shift: "C2", acuity: 2 },
  { date: "6/8/2019 21:56:50", shift: "B2", acuity: 3 },
  { date: "6/8/2019 21:57:24", shift: "D2", acuity: 3 },
  { date: "6/8/2019 22:02:00", shift: "B3", acuity: 4 },
  { date: "6/8/2019 22:09:05", shift: "A3", acuity: 2 },
  { date: "6/8/2019 22:19:44", shift: "C2", acuity: 3 },
  { date: "6/8/2019 22:20:12", shift: "D2", acuity: 3 },
  { date: "6/8/2019 22:34:33", shift: "B3", acuity: 3 },
  { date: "6/8/2019 22:34:44", shift: "B3", acuity: 3 },
  { date: "6/8/2019 22:53:49", shift: "A3", acuity: 3 },
  { date: "6/8/2019 22:54:00", shift: "C2", acuity: 3 },
  { date: "6/8/2019 23:08:23", shift: "D2", acuity: 2 },
  { date: "6/8/2019 23:19:32", shift: "B3", acuity: 3 },
  { date: "6/8/2019 23:37:41", shift: "A3", acuity: 3 },
  { date: "6/9/2019 00:00:17", shift: "D2", acuity: 4 },
  { date: "6/9/2019 00:07:30", shift: "B3", acuity: 3 },
  { date: "6/9/2019 00:07:51", shift: "A3", acuity: 4 },
  { date: "6/9/2019 00:19:11", shift: "D2", acuity: 2 },
  { date: "6/9/2019 00:19:32", shift: "B3", acuity: 3 },
  { date: "6/9/2019 00:24:05", shift: "A3", acuity: 3 },
  { date: "6/9/2019 00:24:21", shift: "D2", acuity: 3 },
  { date: "6/9/2019 00:49:50", shift: "B3", acuity: 3 },
  { date: "6/9/2019 01:13:50", shift: "A3", acuity: 4 },
  { date: "6/9/2019 01:14:12", shift: "D2", acuity: 4 },
  { date: "6/9/2019 01:23:05", shift: "B3", acuity: 4 },
  { date: "6/9/2019 01:27:28", shift: "A3", acuity: 3 },
  { date: "6/9/2019 01:30:23", shift: "B3", acuity: 4 },
  { date: "6/9/2019 01:30:34", shift: "A3", acuity: 3 },
  { date: "6/9/2019 01:44:43", shift: "B3", acuity: 5 },
  { date: "6/9/2019 01:56:27", shift: "A3", acuity: 3 },
  { date: "6/9/2019 01:56:43", shift: "B3", acuity: 4 },
  { date: "6/9/2019 01:57:04", shift: "A3", acuity: 4 },
  { date: "6/9/2019 02:38:12", shift: "B3", acuity: 3 },
  { date: "6/9/2019 02:43:11", shift: "A3", acuity: 4 },
  { date: "6/9/2019 02:52:37", shift: "B3", acuity: 5 },
  { date: "6/9/2019 02:57:02", shift: "A3", acuity: 3 },
  { date: "6/9/2019 02:57:36", shift: "B3", acuity: 4 },
  { date: "6/9/2019 03:26:40", shift: "A3", acuity: 2 },
  { date: "6/9/2019 03:39:30", shift: "B3", acuity: 3 },
  { date: "6/9/2019 05:02:14", shift: "A1", acuity: 4 },
  { date: "6/9/2019 05:02:37", shift: "B3", acuity: 2 },
  { date: "6/9/2019 05:24:24", shift: "A1", acuity: 3 },
  { date: "6/9/2019 05:33:57", shift: "A1", acuity: 2 },
  { date: "6/9/2019 05:40:03", shift: "A1", acuity: 2 },
  { date: "6/9/2019 06:01:21", shift: "B1", acuity: 2 },
  { date: "6/9/2019 06:04:58", shift: "A1", acuity: 3 },
  { date: "6/9/2019 06:13:24", shift: "B1", acuity: 2 },
  { date: "6/9/2019 06:41:20", shift: "B1", acuity: 4 },
  { date: "6/9/2019 07:09:22", shift: "B1", acuity: 4 },
  { date: "6/9/2019 07:09:36", shift: "A1", acuity: 3 },
  { date: "6/9/2019 07:28:26", shift: "B1", acuity: 3 },
  { date: "6/9/2019 08:03:09", shift: "A1", acuity: 4 },
  { date: "6/9/2019 08:03:28", shift: "B1", acuity: 3 },
  { date: "6/9/2019 08:03:38", shift: "A1", acuity: 3 },
  { date: "6/9/2019 08:30:16", shift: "C1", acuity: 3 },
  { date: "6/9/2019 08:50:47", shift: "B1", acuity: 5 },
  { date: "6/9/2019 09:00:26", shift: "C1", acuity: 3 },
  { date: "6/9/2019 09:16:03", shift: "C1", acuity: 4 },
  { date: "6/9/2019 09:21:37", shift: "A1", acuity: 3 },
  { date: "6/9/2019 09:21:56", shift: "B1", acuity: 3 },
  { date: "6/9/2019 09:22:21", shift: "C1", acuity: 5 },
  { date: "6/9/2019 09:22:36", shift: "A1", acuity: 3 },
  { date: "6/9/2019 09:48:55", shift: "B1", acuity: 4 },
  { date: "6/9/2019 09:50:48", shift: "C1", acuity: 3 },
  { date: "6/9/2019 09:51:20", shift: "A1", acuity: 3 },
  { date: "6/9/2019 09:51:33", shift: "B1", acuity: 2 },
  { date: "6/9/2019 10:19:12", shift: "D1", acuity: 2 },
  { date: "6/9/2019 10:19:30", shift: "C1", acuity: 3 },
  { date: "6/9/2019 10:19:44", shift: "A1", acuity: 3 },
  { date: "6/9/2019 10:25:23", shift: "B1", acuity: 2 },
  { date: "6/9/2019 10:39:16", shift: "D1", acuity: 4 },
  { date: "6/9/2019 11:00:56", shift: "D1", acuity: 3 },
  { date: "6/9/2019 11:36:38", shift: "C1", acuity: 3 },
  { date: "6/9/2019 11:36:55", shift: "A1", acuity: 3 },
  { date: "6/9/2019 11:37:21", shift: "B1", acuity: 3 },
  { date: "6/9/2019 11:44:08", shift: "D1", acuity: 2 },
  { date: "6/9/2019 12:02:02", shift: "C1", acuity: 2 },
  { date: "6/9/2019 12:02:22", shift: "A1", acuity: 3 },
  { date: "6/9/2019 12:15:13", shift: "B1", acuity: 2 },
  { date: "6/9/2019 12:15:35", shift: "D1", acuity: 3 },
  { date: "6/9/2019 12:31:33", shift: "C1", acuity: 3 },
  { date: "6/9/2019 12:45:05", shift: "A1", acuity: 3 },
  { date: "6/9/2019 12:47:54", shift: "B1", acuity: 2 },
  { date: "6/9/2019 12:59:26", shift: "D1", acuity: 3 },
  { date: "6/9/2019 12:59:44", shift: "C1", acuity: 3 },
  { date: "6/9/2019 13:00:44", shift: "A2", acuity: 3 },
  { date: "6/9/2019 13:01:06", shift: "B1", acuity: 2 },
  { date: "6/9/2019 13:12:58", shift: "D1", acuity: 3 },
  { date: "6/9/2019 13:15:50", shift: "C1", acuity: 2 },
  { date: "6/9/2019 13:34:25", shift: "A2", acuity: 3 },
  { date: "6/9/2019 13:34:49", shift: "A2", acuity: 2 },
  { date: "6/9/2019 13:48:35", shift: "B1", acuity: 2 },
  { date: "6/9/2019 13:55:18", shift: "D1", acuity: 3 },
  { date: "6/9/2019 13:59:32", shift: "C1", acuity: 2 },
  { date: "6/9/2019 14:17:52", shift: "B2", acuity: 2 },
  { date: "6/9/2019 14:20:23", shift: "A2", acuity: 3 },
  { date: "6/9/2019 14:34:35", shift: "B2", acuity: 2 },
  { date: "6/9/2019 14:35:17", shift: "B2", acuity: 2 },
  { date: "6/9/2019 14:35:34", shift: "D1", acuity: 2 },
  { date: "6/9/2019 15:20:21", shift: "C1", acuity: 2 },
  { date: "6/9/2019 15:20:44", shift: "A2", acuity: 2 },
  { date: "6/9/2019 15:27:55", shift: "B2", acuity: 2 },
  { date: "6/9/2019 15:37:03", shift: "D1", acuity: 2 },
  { date: "6/9/2019 15:37:40", shift: "C1", acuity: 3 },
  { date: "6/9/2019 15:47:47", shift: "A2", acuity: 2 },
  { date: "6/9/2019 15:55:18", shift: "B2", acuity: 5 },
  { date: "6/9/2019 15:55:36", shift: "D1", acuity: 3 },
  { date: "6/9/2019 16:17:30", shift: "C1", acuity: 2 },
  { date: "6/9/2019 16:31:44", shift: "C2", acuity: 2 },
  { date: "6/9/2019 16:32:21", shift: "A2", acuity: 3 },
  { date: "6/9/2019 16:32:39", shift: "B2", acuity: 2 },
  { date: "6/9/2019 16:33:39", shift: "D1", acuity: 3 },
  { date: "6/9/2019 17:00:15", shift: "C2", acuity: 2 },
  { date: "6/9/2019 17:28:34", shift: "C2", acuity: 2 },
  { date: "6/9/2019 17:46:50", shift: "A2", acuity: 3 },
  { date: "6/9/2019 18:05:24", shift: "D2", acuity: 3 },
  { date: "6/9/2019 18:11:23", shift: "B2", acuity: 2 },
  { date: "6/9/2019 18:31:20", shift: "D2", acuity: 3 },
  { date: "6/9/2019 18:39:30", shift: "D2", acuity: 2 },
  { date: "6/9/2019 18:39:57", shift: "C2", acuity: 2 },
  { date: "6/9/2019 18:41:49", shift: "A2", acuity: 3 },
  { date: "6/9/2019 18:42:03", shift: "B2", acuity: 3 },
  { date: "6/9/2019 18:47:50", shift: "D2", acuity: 3 },
  { date: "6/9/2019 19:31:20", shift: "C2", acuity: 3 },
  { date: "6/9/2019 19:35:43", shift: "A2", acuity: 2 },
  { date: "6/9/2019 19:40:36", shift: "B2", acuity: 2 },
  { date: "6/9/2019 19:41:46", shift: "D2", acuity: 2 },
  { date: "6/9/2019 19:45:38", shift: "C2", acuity: 3 },
  { date: "6/9/2019 19:53:57", shift: "A2", acuity: 3 },
  { date: "6/9/2019 19:59:34", shift: "B2", acuity: 2 },
  { date: "6/9/2019 19:59:58", shift: "D2", acuity: 4 },
  { date: "6/9/2019 20:01:01", shift: "C2", acuity: 3 },
  { date: "6/9/2019 20:05:55", shift: "A2", acuity: 3 },
  { date: "6/9/2019 20:29:39", shift: "B2", acuity: 3 },
  { date: "6/9/2019 20:34:06", shift: "D2", acuity: 2 },
  { date: "6/9/2019 20:49:39", shift: "C2", acuity: 3 },
  { date: "6/9/2019 20:49:54", shift: "A2", acuity: 3 },
  { date: "6/9/2019 20:52:01", shift: "B2", acuity: 3 },
  { date: "6/9/2019 21:00:50", shift: "A3", acuity: 3 },
  { date: "6/9/2019 21:16:20", shift: "D2", acuity: 3 },
  { date: "6/9/2019 21:26:46", shift: "C2", acuity: 3 },
  { date: "6/9/2019 21:36:56", shift: "A3", acuity: 3 },
  { date: "6/9/2019 21:40:46", shift: "A3", acuity: 3 },
  { date: "6/9/2019 21:47:03", shift: "B2", acuity: 4 },
  { date: "6/9/2019 21:48:10", shift: "D2", acuity: 3 },
  { date: "6/9/2019 21:48:19", shift: "C2", acuity: 3 },
  { date: "6/9/2019 21:52:01", shift: "A3", acuity: 3 },
  { date: "6/9/2019 22:00:51", shift: "B3", acuity: 2 },
  { date: "6/9/2019 22:01:06", shift: "D2", acuity: 3 },
  { date: "6/9/2019 22:22:53", shift: "C2", acuity: 2 },
  { date: "6/9/2019 22:45:11", shift: "B3", acuity: 3 },
  { date: "6/9/2019 22:54:16", shift: "B3", acuity: 3 },
  { date: "6/9/2019 22:56:55", shift: "A3", acuity: 4 },
  { date: "6/9/2019 22:57:08", shift: "D2", acuity: 2 },
  { date: "6/9/2019 23:20:19", shift: "C2", acuity: 4 },
  { date: "6/9/2019 23:20:44", shift: "B3", acuity: 3 },
  { date: "6/9/2019 23:27:01", shift: "A3", acuity: 4 },
  { date: "6/9/2019 23:41:18", shift: "D2", acuity: 2 },
  { date: "6/10/2019 00:02:52", shift: "B3", acuity: 3 },
  { date: "6/10/2019 00:03:16", shift: "A3", acuity: 3 },
  { date: "6/10/2019 00:07:16", shift: "D2", acuity: 3 },
  { date: "6/10/2019 00:18:33", shift: "B3", acuity: 4 },
  { date: "6/10/2019 00:18:46", shift: "A3", acuity: 4 },
  { date: "6/10/2019 00:21:08", shift: "D2", acuity: 3 },
  { date: "6/10/2019 00:58:35", shift: "B3", acuity: 3 },
  { date: "6/10/2019 01:01:51", shift: "A3", acuity: 3 },
  { date: "6/10/2019 01:02:05", shift: "D2", acuity: 4 },
  { date: "6/10/2019 01:10:50", shift: "B3", acuity: 4 },
  { date: "6/10/2019 01:11:05", shift: "A3", acuity: 3 },
  { date: "6/10/2019 01:25:59", shift: "D2", acuity: 2 },
  { date: "6/10/2019 01:28:48", shift: "B3", acuity: 3 },
  { date: "6/10/2019 01:47:03", shift: "A3", acuity: 4 },
  { date: "6/10/2019 02:04:16", shift: "B3", acuity: 3 },
  { date: "6/10/2019 02:04:27", shift: "A3", acuity: 3 },
  { date: "6/10/2019 02:04:40", shift: "B3", acuity: 4 },
  { date: "6/10/2019 02:27:58", shift: "A3", acuity: 2 },
  { date: "6/10/2019 03:19:22", shift: "B3", acuity: 3 },
  { date: "6/10/2019 03:34:25", shift: "A3", acuity: 3 },
  { date: "6/10/2019 03:34:51", shift: "B3", acuity: 5 },
  { date: "6/10/2019 03:44:05", shift: "A3", acuity: 4 },
  { date: "6/10/2019 03:46:38", shift: "B3", acuity: 2 },
  { date: "6/10/2019 03:47:51", shift: "A3", acuity: 5 },
  { date: "6/10/2019 04:17:20", shift: "B3", acuity: 2 },
  { date: "6/10/2019 04:23:51", shift: "A3", acuity: 3 },
  { date: "6/10/2019 04:32:57", shift: "B3", acuity: 2 },
  { date: "6/10/2019 05:11:01", shift: "A1", acuity: 5 },
  { date: "6/10/2019 05:36:53", shift: "A1", acuity: 2 },
  { date: "6/10/2019 05:46:41", shift: "A1", acuity: 3 },
  { date: "6/10/2019 06:20:45", shift: "B1", acuity: 4 },
  { date: "6/10/2019 06:54:42", shift: "B1", acuity: 2 },
  { date: "6/10/2019 06:57:59", shift: "B1", acuity: 5 },
  { date: "6/10/2019 07:31:36", shift: "A1", acuity: 4 },
  { date: "6/10/2019 07:31:52", shift: "B1", acuity: 4 },
  { date: "6/10/2019 07:32:08", shift: "A1", acuity: 3 },
  { date: "6/10/2019 07:49:23", shift: "B1", acuity: 3 },
  { date: "6/10/2019 08:16:12", shift: "A1", acuity: 4 },
  { date: "6/10/2019 08:24:23", shift: "B1", acuity: 3 },
  { date: "6/10/2019 08:27:30", shift: "A1", acuity: 2 },
  { date: "6/10/2019 08:27:46", shift: "B1", acuity: 4 },
  { date: "6/10/2019 08:40:19", shift: "C1", acuity: 4 },
  { date: "6/10/2019 08:47:36", shift: "A1", acuity: 4 },
  { date: "6/10/2019 08:47:55", shift: "B1", acuity: 4 },
  { date: "6/10/2019 09:05:21", shift: "C1", acuity: 2 },
  { date: "6/10/2019 09:06:18", shift: "C1", acuity: 3 },
  { date: "6/10/2019 09:44:46", shift: "A1", acuity: 2 },
  { date: "6/10/2019 09:53:23", shift: "B1", acuity: 2 },
  { date: "6/10/2019 10:04:01", shift: "D1", acuity: 2 },
  { date: "6/10/2019 10:07:27", shift: "C1", acuity: 4 },
  { date: "6/10/2019 10:12:31", shift: "A1", acuity: 3 },
  { date: "6/10/2019 10:17:03", shift: "B1", acuity: 2 },
  { date: "6/10/2019 10:38:35", shift: "D1", acuity: 2 },
  { date: "6/10/2019 10:46:09", shift: "D1", acuity: 3 },
  { date: "6/10/2019 10:46:22", shift: "C1", acuity: 3 },
  { date: "6/10/2019 10:47:22", shift: "A1", acuity: 2 },
  { date: "6/10/2019 10:54:11", shift: "B1", acuity: 2 },
  { date: "6/10/2019 11:12:16", shift: "D1", acuity: 2 },
  { date: "6/10/2019 11:22:13", shift: "C1", acuity: 3 },
  { date: "6/10/2019 11:24:36", shift: "A1", acuity: 2 },
  { date: "6/10/2019 11:28:45", shift: "B1", acuity: 2 },
  { date: "6/10/2019 11:32:38", shift: "D1", acuity: 3 },
  { date: "6/10/2019 11:45:20", shift: "C1", acuity: 3 },
  { date: "6/10/2019 11:47:52", shift: "A1", acuity: 2 },
  { date: "6/10/2019 12:12:03", shift: "B1", acuity: 3 },
  { date: "6/10/2019 12:22:07", shift: "D1", acuity: 2 },
  { date: "6/10/2019 12:22:33", shift: "C1", acuity: 2 },
  { date: "6/10/2019 12:35:47", shift: "A1", acuity: 2 },
  { date: "6/10/2019 12:59:28", shift: "B1", acuity: 3 },
  { date: "6/10/2019 12:59:54", shift: "D1", acuity: 3 },
  { date: "6/10/2019 13:19:19", shift: "A2", acuity: 3 },
  { date: "6/10/2019 13:19:55", shift: "C1", acuity: 3 },
  { date: "6/10/2019 13:34:42", shift: "A2", acuity: 2 },
  { date: "6/10/2019 13:41:45", shift: "A2", acuity: 3 },
  { date: "6/10/2019 14:17:48", shift: "B2", acuity: 3 },
  { date: "6/10/2019 14:18:12", shift: "D1", acuity: 3 },
  { date: "6/10/2019 14:33:06", shift: "B2", acuity: 2 },
  { date: "6/10/2019 14:33:19", shift: "B2", acuity: 3 },
  { date: "6/10/2019 14:46:13", shift: "C1", acuity: 7 },
  { date: "6/10/2019 15:28:20", shift: "A2", acuity: 2 },
  { date: "6/10/2019 15:28:36", shift: "D1", acuity: 2 },
  { date: "6/10/2019 15:28:49", shift: "B2", acuity: 3 },
  { date: "6/10/2019 15:29:35", shift: "C1", acuity: 3 },
  { date: "6/10/2019 15:29:55", shift: "A2", acuity: 3 },
  { date: "6/10/2019 15:48:16", shift: "D1", acuity: 2 },
  { date: "6/10/2019 15:48:33", shift: "B2", acuity: 3 },
  { date: "6/10/2019 16:33:54", shift: "C2", acuity: 3 },
  { date: "6/10/2019 16:34:08", shift: "A2", acuity: 3 },
  { date: "6/10/2019 16:49:17", shift: "D1", acuity: 2 },
  { date: "6/10/2019 16:59:39", shift: "B2", acuity: 3 },
  { date: "6/10/2019 17:10:52", shift: "C2", acuity: 3 },
  { date: "6/10/2019 17:11:08", shift: "C2", acuity: 3 },
  { date: "6/10/2019 17:31:28", shift: "A2", acuity: 3 },
  { date: "6/10/2019 17:35:07", shift: "D1", acuity: 2 },
  { date: "6/10/2019 17:35:56", shift: "B2", acuity: 3 },
  { date: "6/10/2019 17:43:11", shift: "C2", acuity: 4 },
  { date: "6/10/2019 17:56:39", shift: "A2", acuity: 2 },
  { date: "6/10/2019 18:08:36", shift: "D2", acuity: 3 },
  { date: "6/10/2019 18:11:07", shift: "B2", acuity: 2 },
  { date: "6/10/2019 18:24:31", shift: "C2", acuity: 2 },
  { date: "6/10/2019 18:34:32", shift: "D2", acuity: 4 },
  { date: "6/10/2019 18:45:43", shift: "D2", acuity: 3 },
  { date: "6/10/2019 18:50:30", shift: "A2", acuity: 3 },
  { date: "6/10/2019 19:07:57", shift: "B2", acuity: 3 },
  { date: "6/10/2019 19:08:08", shift: "C2", acuity: 3 },
  { date: "6/10/2019 19:19:01", shift: "D2", acuity: 3 },
  { date: "6/10/2019 19:19:54", shift: "A2", acuity: 2 },
  { date: "6/10/2019 19:30:39", shift: "B2", acuity: 2 },
  { date: "6/10/2019 19:46:13", shift: "C2", acuity: 3 },
  { date: "6/10/2019 19:52:28", shift: "D2", acuity: 2 },
  { date: "6/10/2019 19:58:55", shift: "A2", acuity: 3 },
  { date: "6/10/2019 19:59:52", shift: "B2", acuity: 3 },
  { date: "6/10/2019 20:02:28", shift: "C2", acuity: 2 },
  { date: "6/10/2019 20:34:25", shift: "D2", acuity: 3 },
  { date: "6/10/2019 20:39:51", shift: "A2", acuity: 2 },
  { date: "6/10/2019 20:41:55", shift: "B2", acuity: 2 },
  { date: "6/10/2019 21:05:13", shift: "A3", acuity: 2 },
  { date: "6/10/2019 21:05:39", shift: "C2", acuity: 2 },
  { date: "6/10/2019 21:22:01", shift: "D2", acuity: 3 },
  { date: "6/10/2019 21:34:47", shift: "A3", acuity: 2 },
  { date: "6/10/2019 21:35:12", shift: "A3", acuity: 3 },
  { date: "6/10/2019 21:35:57", shift: "B2", acuity: 3 },
  { date: "6/10/2019 21:36:17", shift: "C2", acuity: 2 },
  { date: "6/10/2019 21:59:32", shift: "D2", acuity: 2 },
  { date: "6/10/2019 22:22:50", shift: "B3", acuity: 4 },
  { date: "6/10/2019 22:24:08", shift: "A3", acuity: 3 },
  { date: "6/10/2019 22:33:45", shift: "B3", acuity: 2 },
  { date: "6/10/2019 22:35:58", shift: "B3", acuity: 3 },
  { date: "6/10/2019 22:45:36", shift: "C2", acuity: 4 },
  { date: "6/10/2019 23:00:39", shift: "D2", acuity: 2 },
  { date: "6/10/2019 23:03:52", shift: "A3", acuity: 3 },
  { date: "6/10/2019 23:05:39", shift: "B3", acuity: 2 },
  { date: "6/10/2019 23:08:49", shift: "C2", acuity: 4 },
  { date: "6/10/2019 23:23:12", shift: "D2", acuity: 3 },
  { date: "6/10/2019 23:27:20", shift: "A3", acuity: 3 },
  { date: "6/10/2019 23:28:19", shift: "B3", acuity: 2 },
  { date: "6/10/2019 23:32:56", shift: "C2", acuity: 3 },
  { date: "6/10/2019 23:36:40", shift: "D2", acuity: 2 },
  { date: "6/10/2019 23:49:05", shift: "A3", acuity: 4 },
  { date: "6/10/2019 23:50:23", shift: "B3", acuity: 3 },
  { date: "6/11/2019 00:12:18", shift: "D2", acuity: 2 },
  { date: "6/11/2019 00:12:43", shift: "A3", acuity: 2 },
  { date: "6/11/2019 00:23:43", shift: "B3", acuity: 4 },
  { date: "6/11/2019 00:37:49", shift: "D2", acuity: 2 },
  { date: "6/11/2019 00:38:09", shift: "A3", acuity: 4 },
  { date: "6/11/2019 00:46:21", shift: "B3", acuity: 4 },
  { date: "6/11/2019 00:58:32", shift: "D2", acuity: 3 },
  { date: "6/11/2019 01:09:33", shift: "A3", acuity: 3 },
  { date: "6/11/2019 01:10:11", shift: "B3", acuity: 3 },
  { date: "6/11/2019 01:10:36", shift: "D2", acuity: 3 },
  { date: "6/11/2019 01:11:01", shift: "A3", acuity: 4 },
  { date: "6/11/2019 01:52:27", shift: "B3", acuity: 2 },
  { date: "6/11/2019 01:53:39", shift: "A3", acuity: 2 },
  { date: "6/11/2019 01:54:07", shift: "B3", acuity: 4 },
  { date: "6/11/2019 02:07:19", shift: "A3", acuity: 3 },
  { date: "6/11/2019 02:08:01", shift: "B3", acuity: 3 },
  { date: "6/11/2019 02:15:50", shift: "A3", acuity: 2 },
  { date: "6/11/2019 02:29:42", shift: "B3", acuity: 4 },
  { date: "6/11/2019 03:02:25", shift: "A3", acuity: 3 },
  { date: "6/11/2019 03:02:57", shift: "B3", acuity: 3 },
  { date: "6/11/2019 03:24:40", shift: "A3", acuity: 3 },
  { date: "6/11/2019 03:46:21", shift: "B3", acuity: 3 },
  { date: "6/11/2019 04:03:27", shift: "A3", acuity: 3 },
  { date: "6/11/2019 04:14:24", shift: "B3", acuity: 2 },
  { date: "6/11/2019 04:38:35", shift: "A3", acuity: 4 },
  { date: "6/11/2019 06:01:02", shift: "A1", acuity: 3 },
  { date: "6/11/2019 06:30:18", shift: "A1", acuity: 2 },
  { date: "6/11/2019 06:54:21", shift: "A1", acuity: 3 },
  { date: "6/11/2019 06:58:22", shift: "A1", acuity: 3 },
  { date: "6/11/2019 07:27:54", shift: "B1", acuity: 2 },
  { date: "6/11/2019 07:46:16", shift: "B1", acuity: 2 },
  { date: "6/11/2019 08:37:31", shift: "C1", acuity: 3 },
  { date: "6/11/2019 08:54:09", shift: "A1", acuity: 3 },
  { date: "6/11/2019 08:54:26", shift: "B1", acuity: 2 },
  { date: "6/11/2019 08:55:25", shift: "C1", acuity: 3 },
  { date: "6/11/2019 09:04:04", shift: "C1", acuity: 2 },
  { date: "6/11/2019 09:30:47", shift: "C1", acuity: 3 },
  { date: "6/11/2019 09:39:04", shift: "A1", acuity: 3 },
  { date: "6/11/2019 09:39:23", shift: "B1", acuity: 3 },
  { date: "6/11/2019 09:57:50", shift: "C1", acuity: 3 },
  { date: "6/11/2019 09:58:14", shift: "A1", acuity: 7 },
  { date: "6/11/2019 10:10:47", shift: "D1", acuity: 3 },
  { date: "6/11/2019 10:11:13", shift: "B1", acuity: 7 },
  { date: "6/11/2019 10:14:55", shift: "C1", acuity: 7 },
  { date: "6/11/2019 10:29:15", shift: "A1", acuity: 2 },
  { date: "6/11/2019 10:36:27", shift: "D1", acuity: 3 },
  { date: "6/11/2019 10:45:43", shift: "D1", acuity: 2 },
  { date: "6/11/2019 11:00:20", shift: "B1", acuity: 3 },
  { date: "6/11/2019 11:31:06", shift: "C1", acuity: 2 },
  { date: "6/11/2019 11:46:21", shift: "A1", acuity: 2 },
  { date: "6/11/2019 12:22:58", shift: "D1", acuity: 2 },
  { date: "6/11/2019 12:43:55", shift: "B1", acuity: 2 },
  { date: "6/11/2019 12:47:08", shift: "C1", acuity: 2 },
  { date: "6/11/2019 13:07:41", shift: "A2", acuity: 3 },
  { date: "6/11/2019 13:16:28", shift: "D1", acuity: 3 },
  { date: "6/11/2019 13:22:47", shift: "B1", acuity: 4 },
  { date: "6/11/2019 13:36:10", shift: "A2", acuity: 2 },
  { date: "6/11/2019 14:01:44", shift: "A2", acuity: 3 },
  { date: "6/11/2019 14:02:02", shift: "B2", acuity: 3 },
  { date: "6/11/2019 14:06:49", shift: "C1", acuity: 2 },
  { date: "6/11/2019 14:49:51", shift: "B2", acuity: 2 },
  { date: "6/11/2019 14:58:49", shift: "B2", acuity: 2 },
  { date: "6/11/2019 14:59:34", shift: "D1", acuity: 2 },
  { date: "6/11/2019 15:06:07", shift: "A2", acuity: 2 },
  { date: "6/11/2019 15:27:33", shift: "C1", acuity: 3 },
  { date: "6/11/2019 15:41:50", shift: "B2", acuity: 2 },
  { date: "6/11/2019 16:15:30", shift: "D1", acuity: 2 },
  { date: "6/11/2019 16:20:00", shift: "A2", acuity: 3 },
  { date: "6/11/2019 16:45:08", shift: "C2", acuity: 2 },
  { date: "6/11/2019 17:04:35", shift: "C2", acuity: 3 },
  { date: "6/11/2019 17:14:48", shift: "C2", acuity: 2 },
  { date: "6/11/2019 17:25:51", shift: "B2", acuity: 2 },
  { date: "6/11/2019 17:26:41", shift: "D1", acuity: 2 },
  { date: "6/11/2019 17:32:50", shift: "A2", acuity: 3 },
  { date: "6/11/2019 17:33:29", shift: "C2", acuity: 3 },
  { date: "6/11/2019 17:47:13", shift: "B2", acuity: 4 },
  { date: "6/11/2019 18:01:18", shift: "D2", acuity: 2 },
  { date: "6/11/2019 18:18:49", shift: "A2", acuity: 2 },
  { date: "6/11/2019 18:19:17", shift: "C2", acuity: 2 },
  { date: "6/11/2019 18:19:39", shift: "B2", acuity: 3 },
  { date: "6/11/2019 18:39:06", shift: "D2", acuity: 2 },
  { date: "6/11/2019 19:13:22", shift: "D2", acuity: 4 },
  { date: "6/11/2019 19:13:42", shift: "A2", acuity: 5 },
  { date: "6/11/2019 19:25:53", shift: "C2", acuity: 2 },
  { date: "6/11/2019 19:26:08", shift: "B2", acuity: 2 },
  { date: "6/11/2019 19:26:21", shift: "D2", acuity: 3 },
  { date: "6/11/2019 19:34:55", shift: "A2", acuity: 2 },
  { date: "6/11/2019 19:41:31", shift: "C2", acuity: 3 },
  { date: "6/11/2019 20:08:18", shift: "B2", acuity: 2 },
  { date: "6/11/2019 20:10:22", shift: "D2", acuity: 3 },
  { date: "6/11/2019 20:10:59", shift: "A2", acuity: 3 },
  { date: "6/11/2019 20:19:02", shift: "C2", acuity: 2 },
  { date: "6/11/2019 20:29:37", shift: "B2", acuity: 3 },
  { date: "6/11/2019 20:34:17", shift: "D2", acuity: 3 },
  { date: "6/11/2019 20:51:42", shift: "A2", acuity: 2 },
  { date: "6/11/2019 20:52:00", shift: "C2", acuity: 2 },
  { date: "6/11/2019 20:52:13", shift: "B2", acuity: 3 },
  { date: "6/11/2019 21:18:28", shift: "A3", acuity: 3 },
  { date: "6/11/2019 21:20:14", shift: "D2", acuity: 3 },
  { date: "6/11/2019 21:24:35", shift: "C2", acuity: 2 },
  { date: "6/11/2019 21:34:57", shift: "A3", acuity: 3 },
  { date: "6/11/2019 21:37:34", shift: "A3", acuity: 3 },
  { date: "6/11/2019 21:47:42", shift: "B2", acuity: 2 },
  { date: "6/11/2019 22:09:22", shift: "B3", acuity: 3 },
  { date: "6/11/2019 22:10:08", shift: "D2", acuity: 3 },
  { date: "6/11/2019 22:10:19", shift: "C2", acuity: 3 },
  { date: "6/11/2019 22:19:13", shift: "A3", acuity: 3 },
  { date: "6/11/2019 22:30:19", shift: "B3", acuity: 3 },
  { date: "6/11/2019 22:38:04", shift: "B3", acuity: 2 },
  { date: "6/11/2019 22:39:31", shift: "D2", acuity: 3 },
  { date: "6/11/2019 22:45:41", shift: "C2", acuity: 3 },
  { date: "6/11/2019 22:53:36", shift: "A3", acuity: 2 },
  { date: "6/11/2019 22:59:34", shift: "B3", acuity: 2 },
  { date: "6/11/2019 23:18:15", shift: "D2", acuity: 3 },
  { date: "6/11/2019 23:18:32", shift: "C2", acuity: 3 },
  { date: "6/11/2019 23:19:10", shift: "A3", acuity: 2 },
  { date: "6/11/2019 23:27:08", shift: "B3", acuity: 4 },
  { date: "6/11/2019 23:27:28", shift: "D2", acuity: 2 },
  { date: "6/11/2019 23:48:14", shift: "C2", acuity: 3 },
  { date: "6/11/2019 23:55:02", shift: "A3", acuity: 2 },
  { date: "6/11/2019 23:55:45", shift: "B3", acuity: 3 },
  { date: "6/12/2019 00:13:28", shift: "D2", acuity: 2 },
  { date: "6/12/2019 00:13:43", shift: "A3", acuity: 3 },
  { date: "6/12/2019 00:26:50", shift: "B3", acuity: 3 },
  { date: "6/12/2019 00:31:29", shift: "D2", acuity: 4 },
  { date: "6/12/2019 00:36:14", shift: "A3", acuity: 4 },
  { date: "6/12/2019 00:50:13", shift: "B3", acuity: 3 },
  { date: "6/12/2019 00:50:29", shift: "D2", acuity: 4 },
  { date: "6/12/2019 01:12:35", shift: "A3", acuity: 4 },
  { date: "6/12/2019 01:12:57", shift: "B3", acuity: 3 },
  { date: "6/12/2019 01:25:56", shift: "D2", acuity: 3 },
  { date: "6/12/2019 01:48:51", shift: "A3", acuity: 3 },
  { date: "6/12/2019 01:52:09", shift: "B3", acuity: 2 },
  { date: "6/12/2019 01:52:37", shift: "A3", acuity: 4 },
  { date: "6/12/2019 02:14:04", shift: "B3", acuity: 3 },
  { date: "6/12/2019 02:19:59", shift: "A3", acuity: 2 },
  { date: "6/12/2019 02:27:21", shift: "B3", acuity: 3 },
  { date: "6/12/2019 02:35:53", shift: "A3", acuity: 3 },
  { date: "6/12/2019 03:20:13", shift: "B3", acuity: 4 },
  { date: "6/12/2019 03:21:09", shift: "A3", acuity: 2 },
  { date: "6/12/2019 03:56:07", shift: "B3", acuity: 2 },
  { date: "6/12/2019 04:57:04", shift: "A3", acuity: 3 },
  { date: "6/12/2019 05:16:43", shift: "A1", acuity: 3 },
  { date: "6/12/2019 05:30:27", shift: "A1", acuity: 3 },
  { date: "6/12/2019 05:30:42", shift: "A1", acuity: 4 },
  { date: "6/12/2019 06:05:15", shift: "B1", acuity: 3 },
  { date: "6/12/2019 06:06:08", shift: "A1", acuity: 3 },
  { date: "6/12/2019 06:20:55", shift: "B1", acuity: 3 },
  { date: "6/12/2019 07:11:42", shift: "B1", acuity: 3 },
  { date: "6/12/2019 07:11:54", shift: "B1", acuity: 5 },
  { date: "6/12/2019 07:17:26", shift: "A1", acuity: 2 },
  { date: "6/12/2019 07:57:27", shift: "B1", acuity: 3 },
  { date: "6/12/2019 08:34:56", shift: "C1", acuity: 3 },
  { date: "6/12/2019 08:46:27", shift: "A1", acuity: 3 },
  { date: "6/12/2019 09:02:41", shift: "C1", acuity: 2 },
  { date: "6/12/2019 09:11:28", shift: "C1", acuity: 2 },
  { date: "6/12/2019 09:12:42", shift: "B1", acuity: 2 },
  { date: "6/12/2019 09:12:57", shift: "A1", acuity: 3 },
  { date: "6/12/2019 09:33:11", shift: "C1", acuity: 3 },
  { date: "6/12/2019 09:34:13", shift: "B1", acuity: 3 },
  { date: "6/12/2019 09:34:29", shift: "A1", acuity: 3 },
  { date: "6/12/2019 09:43:39", shift: "C1", acuity: 3 },
  { date: "6/12/2019 09:51:48", shift: "B1", acuity: 2 },
  { date: "6/12/2019 10:13:00", shift: "D1", acuity: 2 },
  { date: "6/12/2019 10:26:56", shift: "A1", acuity: 3 },
  { date: "6/12/2019 10:27:18", shift: "C1", acuity: 2 },
  { date: "6/12/2019 10:50:58", shift: "D1", acuity: 2 },
  { date: "6/12/2019 10:51:20", shift: "D1", acuity: 3 },
  { date: "6/12/2019 11:14:30", shift: "B1", acuity: 2 },
  { date: "6/12/2019 11:20:48", shift: "A1", acuity: 3 },
  { date: "6/12/2019 11:39:50", shift: "C1", acuity: 2 },
  { date: "6/12/2019 11:45:34", shift: "D1", acuity: 3 },
  { date: "6/12/2019 12:05:37", shift: "B1", acuity: 3 },
  { date: "6/12/2019 12:12:43", shift: "A1", acuity: 2 },
  { date: "6/12/2019 12:41:54", shift: "C1", acuity: 3 },
  { date: "6/12/2019 12:46:16", shift: "D1", acuity: 2 },
  { date: "6/12/2019 12:50:44", shift: "B1", acuity: 3 },
  { date: "6/12/2019 13:01:26", shift: "A2", acuity: 2 },
  { date: "6/12/2019 13:15:43", shift: "C1", acuity: 2 },
  { date: "6/12/2019 13:41:38", shift: "A2", acuity: 2 },
  { date: "6/12/2019 13:44:21", shift: "A2", acuity: 2 },
  { date: "6/12/2019 14:06:01", shift: "B2", acuity: 2 },
  { date: "6/12/2019 14:10:55", shift: "D1", acuity: 3 },
  { date: "6/12/2019 14:14:04", shift: "C1", acuity: 3 },
  { date: "6/12/2019 14:30:48", shift: "B2", acuity: 3 },
  { date: "6/12/2019 14:45:54", shift: "B2", acuity: 3 },
  { date: "6/12/2019 14:52:16", shift: "A2", acuity: 2 },
  { date: "6/12/2019 14:52:47", shift: "D1", acuity: 3 },
  { date: "6/12/2019 15:10:46", shift: "C1", acuity: 3 },
  { date: "6/12/2019 15:11:07", shift: "B2", acuity: 3 },
  { date: "6/12/2019 15:38:03", shift: "A2", acuity: 2 },
  { date: "6/12/2019 15:50:04", shift: "D1", acuity: 3 },
  { date: "6/12/2019 15:50:16", shift: "C1", acuity: 3 },
  { date: "6/12/2019 15:59:33", shift: "B2", acuity: 2 },
  { date: "6/12/2019 15:59:51", shift: "A2", acuity: 3 },
  { date: "6/12/2019 16:00:18", shift: "D1", acuity: 3 },
  { date: "6/12/2019 16:00:52", shift: "C1", acuity: 3 },
  { date: "6/12/2019 16:10:48", shift: "B2", acuity: 3 },
  { date: "6/12/2019 16:21:35", shift: "A2", acuity: 3 },
  { date: "6/12/2019 16:34:58", shift: "C2", acuity: 3 },
  { date: "6/12/2019 16:51:21", shift: "D1", acuity: 3 },
  { date: "6/12/2019 16:51:40", shift: "B2", acuity: 4 },
  { date: "6/12/2019 17:06:26", shift: "C2", acuity: 3 },
  { date: "6/12/2019 17:16:42", shift: "C2", acuity: 2 },
  { date: "6/12/2019 17:17:02", shift: "A2", acuity: 3 },
  { date: "6/12/2019 17:33:20", shift: "D1", acuity: 2 },
  { date: "6/12/2019 17:33:36", shift: "B2", acuity: 3 },
  { date: "6/12/2019 17:35:47", shift: "C2", acuity: 2 },
  { date: "6/12/2019 17:55:20", shift: "A2", acuity: 3 },
  { date: "6/12/2019 18:01:43", shift: "D2", acuity: 3 },
  { date: "6/12/2019 18:21:30", shift: "B2", acuity: 3 },
  { date: "6/12/2019 18:31:04", shift: "D2", acuity: 3 },
  { date: "6/12/2019 18:48:28", shift: "D2", acuity: 3 },
  { date: "6/12/2019 19:00:17", shift: "C2", acuity: 7 },
  { date: "6/12/2019 19:05:40", shift: "A2", acuity: 3 },
  { date: "6/12/2019 19:07:35", shift: "B2", acuity: 2 },
  { date: "6/12/2019 19:15:03", shift: "D2", acuity: 2 },
  { date: "6/12/2019 19:15:24", shift: "C2", acuity: 2 },
  { date: "6/12/2019 19:23:31", shift: "A2", acuity: 2 },
  { date: "6/12/2019 19:32:21", shift: "B2", acuity: 3 },
  { date: "6/12/2019 19:32:44", shift: "D2", acuity: 2 },
  { date: "6/12/2019 19:36:41", shift: "C2", acuity: 3 },
  { date: "6/12/2019 19:55:21", shift: "A2", acuity: 2 },
  { date: "6/12/2019 19:55:36", shift: "B2", acuity: 2 },
  { date: "6/12/2019 20:08:43", shift: "D2", acuity: 3 },
  { date: "6/12/2019 20:09:11", shift: "C2", acuity: 3 },
  { date: "6/12/2019 20:33:52", shift: "A2", acuity: 3 },
  { date: "6/12/2019 20:34:21", shift: "B2", acuity: 3 },
  { date: "6/12/2019 20:40:05", shift: "D2", acuity: 2 },
  { date: "6/12/2019 20:49:26", shift: "C2", acuity: 2 },
  { date: "6/12/2019 21:00:26", shift: "A3", acuity: 3 },
  { date: "6/12/2019 21:05:23", shift: "B2", acuity: 2 },
  { date: "6/12/2019 21:05:38", shift: "D2", acuity: 4 },
  { date: "6/12/2019 21:20:31", shift: "C2", acuity: 3 },
  { date: "6/12/2019 22:26:49", shift: "A3", acuity: 2 },
  { date: "6/12/2019 22:32:46", shift: "A3", acuity: 3 },
  { date: "6/12/2019 22:33:15", shift: "B3", acuity: 3 },
  { date: "6/12/2019 22:48:56", shift: "B3", acuity: 4 },
  { date: "6/12/2019 22:58:20", shift: "B3", acuity: 2 },
  { date: "6/12/2019 22:58:50", shift: "D2", acuity: 2 },
  { date: "6/12/2019 23:05:40", shift: "C2", acuity: 3 },
  { date: "6/12/2019 23:12:46", shift: "A3", acuity: 3 },
  { date: "6/12/2019 23:34:51", shift: "B3", acuity: 3 },
  { date: "6/13/2019 00:07:44", shift: "D2", acuity: 3 },
  { date: "6/13/2019 00:08:21", shift: "A3", acuity: 4 },
  { date: "6/13/2019 00:14:36", shift: "B3", acuity: 2 },
  { date: "6/13/2019 00:16:28", shift: "D2", acuity: 4 },
  { date: "6/13/2019 00:18:36", shift: "A3", acuity: 3 },
  { date: "6/13/2019 00:19:08", shift: "B3", acuity: 2 },
  { date: "6/13/2019 00:41:15", shift: "D2", acuity: 3 },
  { date: "6/13/2019 00:52:41", shift: "A3", acuity: 3 },
  { date: "6/13/2019 01:08:45", shift: "B3", acuity: 3 },
  { date: "6/13/2019 01:10:01", shift: "D2", acuity: 4 },
  { date: "6/13/2019 01:16:27", shift: "A3", acuity: 2 },
  { date: "6/13/2019 01:33:34", shift: "B3", acuity: 2 },
  { date: "6/13/2019 01:54:23", shift: "A3", acuity: 3 },
  { date: "6/13/2019 01:54:32", shift: "B3", acuity: 3 },
  { date: "6/13/2019 02:33:48", shift: "A3", acuity: 2 },
  { date: "6/13/2019 02:34:04", shift: "B3", acuity: 3 },
  { date: "6/13/2019 02:34:31", shift: "A3", acuity: 4 },
  { date: "6/13/2019 02:50:09", shift: "B3", acuity: 4 },
  { date: "6/13/2019 03:23:51", shift: "A3", acuity: 3 },
  { date: "6/13/2019 03:25:38", shift: "B3", acuity: 3 },
  { date: "6/13/2019 03:38:27", shift: "A3", acuity: 3 },
  { date: "6/13/2019 03:38:45", shift: "B3", acuity: 3 },
  { date: "6/13/2019 04:20:24", shift: "A3", acuity: 3 },
  { date: "6/13/2019 05:01:20", shift: "A1", acuity: 3 },
  { date: "6/13/2019 05:03:16", shift: "B3", acuity: 2 },
  { date: "6/13/2019 05:56:16", shift: "A1", acuity: 3 },
  { date: "6/13/2019 06:02:56", shift: "A1", acuity: 4 },
  { date: "6/13/2019 06:44:36", shift: "B1", acuity: 3 },
  { date: "6/13/2019 07:15:00", shift: "B1", acuity: 2 },
  { date: "6/13/2019 07:44:46", shift: "B1", acuity: 4 },
  { date: "6/13/2019 07:57:19", shift: "A1", acuity: 3 },
  { date: "6/13/2019 07:57:39", shift: "B1", acuity: 2 },
  { date: "6/13/2019 08:22:37", shift: "A1", acuity: 4 },
  { date: "6/13/2019 08:42:09", shift: "C1", acuity: 4 },
  { date: "6/13/2019 08:42:24", shift: "B1", acuity: 3 },
  { date: "6/13/2019 08:48:19", shift: "A1", acuity: 2 },
  { date: "6/13/2019 08:59:59", shift: "C1", acuity: 3 },
  { date: "6/13/2019 09:13:45", shift: "C1", acuity: 2 },
  { date: "6/13/2019 09:27:12", shift: "C1", acuity: 4 },
  { date: "6/13/2019 09:27:16", shift: "B1", acuity: 3 },
  { date: "6/13/2019 09:28:41", shift: "A1", acuity: 3 },
  { date: "6/13/2019 09:32:14", shift: "C1", acuity: 3 },
  { date: "6/13/2019 09:42:01", shift: "B1", acuity: 2 },
  { date: "6/13/2019 09:49:40", shift: "A1", acuity: 3 },
  { date: "6/13/2019 10:05:27", shift: "D1", acuity: 3 },
  { date: "6/13/2019 10:05:50", shift: "C1", acuity: 3 },
  { date: "6/13/2019 10:33:34", shift: "D1", acuity: 3 },
  { date: "6/13/2019 10:33:54", shift: "D1", acuity: 3 },
  { date: "6/13/2019 10:38:18", shift: "B1", acuity: 3 },
  { date: "6/13/2019 10:59:39", shift: "A1", acuity: 3 },
  { date: "6/13/2019 11:07:56", shift: "C1", acuity: 2 },
  { date: "6/13/2019 11:32:52", shift: "D1", acuity: 2 },
  { date: "6/13/2019 11:33:09", shift: "B1", acuity: 3 },
  { date: "6/13/2019 11:34:07", shift: "A1", acuity: 3 },
  { date: "6/13/2019 11:34:44", shift: "C1", acuity: 4 },
  { date: "6/13/2019 11:44:06", shift: "D1", acuity: 2 },
  { date: "6/13/2019 12:00:51", shift: "B1", acuity: 2 },
  { date: "6/13/2019 12:06:52", shift: "A1", acuity: 2 },
  { date: "6/13/2019 12:12:55", shift: "C1", acuity: 2 },
  { date: "6/13/2019 12:20:39", shift: "D1", acuity: 3 },
  { date: "6/13/2019 12:37:38", shift: "B1", acuity: 2 },
  { date: "6/13/2019 13:26:23", shift: "A2", acuity: 2 },
  { date: "6/13/2019 13:49:51", shift: "A2", acuity: 2 },
  { date: "6/13/2019 13:50:15", shift: "A2", acuity: 3 },
  { date: "6/13/2019 14:52:22", shift: "B2", acuity: 3 },
  { date: "6/13/2019 14:52:45", shift: "B2", acuity: 3 },
  { date: "6/13/2019 14:53:00", shift: "B2", acuity: 2 },
  { date: "6/13/2019 14:58:13", shift: "C1", acuity: 2 },
  { date: "6/13/2019 15:23:14", shift: "D1", acuity: 3 },
  { date: "6/13/2019 15:29:55", shift: "A2", acuity: 2 },
  { date: "6/13/2019 15:34:03", shift: "B2", acuity: 3 },
  { date: "6/13/2019 15:46:30", shift: "C1", acuity: 2 },
  { date: "6/13/2019 15:46:40", shift: "D1", acuity: 2 },
  { date: "6/13/2019 15:47:03", shift: "A2", acuity: 3 },
  { date: "6/13/2019 15:59:05", shift: "B2", acuity: 2 },
  { date: "6/13/2019 16:06:01", shift: "C1", acuity: 2 },
  { date: "6/13/2019 16:25:55", shift: "D1", acuity: 3 },
  { date: "6/13/2019 16:26:13", shift: "A2", acuity: 3 },
  { date: "6/13/2019 16:29:53", shift: "B2", acuity: 2 },
  { date: "6/13/2019 16:37:45", shift: "C2", acuity: 3 },
  { date: "6/13/2019 16:38:23", shift: "D1", acuity: 2 },
  { date: "6/13/2019 16:46:45", shift: "A2", acuity: 2 },
  { date: "6/13/2019 16:48:54", shift: "B2", acuity: 3 },
  { date: "6/13/2019 16:51:42", shift: "C2", acuity: 2 },
  { date: "6/13/2019 17:03:38", shift: "C2", acuity: 2 },
  { date: "6/13/2019 17:06:10", shift: "C2", acuity: 3 },
  { date: "6/13/2019 17:06:47", shift: "D1", acuity: 4 },
  { date: "6/13/2019 17:13:44", shift: "A2", acuity: 2 },
  { date: "6/13/2019 17:39:45", shift: "B2", acuity: 3 },
  { date: "6/13/2019 17:59:47", shift: "C2", acuity: 3 },
  { date: "6/13/2019 18:00:14", shift: "D2", acuity: 3 },
  { date: "6/13/2019 18:26:41", shift: "A2", acuity: 2 },
  { date: "6/13/2019 18:57:15", shift: "D2", acuity: 3 },
  { date: "6/13/2019 19:01:59", shift: "D2", acuity: 7 },
  { date: "6/13/2019 19:13:15", shift: "B2", acuity: 3 },
  { date: "6/13/2019 19:28:43", shift: "C2", acuity: 7 },
  { date: "6/13/2019 19:37:18", shift: "A2", acuity: 2 },
  { date: "6/13/2019 19:38:29", shift: "D2", acuity: 7 },
  { date: "6/13/2019 19:50:48", shift: "B2", acuity: 2 },
  { date: "6/13/2019 20:02:52", shift: "C2", acuity: 2 },
  { date: "6/13/2019 20:03:48", shift: "A2", acuity: 3 },
  { date: "6/13/2019 20:09:49", shift: "D2", acuity: 3 },
  { date: "6/13/2019 20:16:04", shift: "B2", acuity: 2 },
  { date: "6/13/2019 20:33:32", shift: "C2", acuity: 3 },
  { date: "6/13/2019 21:00:13", shift: "A3", acuity: 2 },
  { date: "6/13/2019 21:02:08", shift: "D2", acuity: 2 },
  { date: "6/13/2019 21:02:22", shift: "B2", acuity: 7 },
  { date: "6/13/2019 21:15:31", shift: "C2", acuity: 2 },
  { date: "6/13/2019 21:17:37", shift: "A3", acuity: 3 },
  { date: "6/13/2019 21:32:04", shift: "A3", acuity: 2 },
  { date: "6/13/2019 21:32:13", shift: "A3", acuity: 3 },
  { date: "6/13/2019 21:50:11", shift: "D2", acuity: 2 },
  { date: "6/13/2019 21:58:45", shift: "B2", acuity: 3 },
  { date: "6/13/2019 22:08:50", shift: "B3", acuity: 3 },
  { date: "6/13/2019 22:09:06", shift: "C2", acuity: 5 },
  { date: "6/13/2019 22:12:50", shift: "A3", acuity: 2 },
  { date: "6/13/2019 22:13:31", shift: "D2", acuity: 7 },
  { date: "6/13/2019 22:14:40", shift: "B3", acuity: 2 },
  { date: "6/13/2019 22:19:10", shift: "C2", acuity: 4 },
  { date: "6/13/2019 22:48:36", shift: "B3", acuity: 3 },
  { date: "6/13/2019 22:48:54", shift: "B3", acuity: 3 },
  { date: "6/13/2019 22:56:47", shift: "A3", acuity: 3 },
  { date: "6/13/2019 23:15:42", shift: "D2", acuity: 3 },
  { date: "6/13/2019 23:22:08", shift: "C2", acuity: 3 },
  { date: "6/13/2019 23:41:04", shift: "B3", acuity: 2 },
  { date: "6/13/2019 23:47:28", shift: "A3", acuity: 3 },
  { date: "6/14/2019 00:02:48", shift: "D2", acuity: 4 },
  { date: "6/14/2019 00:11:16", shift: "B3", acuity: 4 },
  { date: "6/14/2019 00:11:28", shift: "A3", acuity: 3 },
  { date: "6/14/2019 00:11:40", shift: "D2", acuity: 4 },
  { date: "6/14/2019 00:28:07", shift: "B3", acuity: 3 },
  { date: "6/14/2019 00:32:01", shift: "A3", acuity: 3 },
  { date: "6/14/2019 00:48:01", shift: "D2", acuity: 4 },
  { date: "6/14/2019 00:54:01", shift: "B3", acuity: 3 },
  { date: "6/14/2019 00:54:12", shift: "A3", acuity: 3 },
  { date: "6/14/2019 01:03:47", shift: "D2", acuity: 3 },
  { date: "6/14/2019 01:04:21", shift: "B3", acuity: 4 },
  { date: "6/14/2019 01:09:54", shift: "A3", acuity: 4 },
  { date: "6/14/2019 01:44:44", shift: "B3", acuity: 3 },
  { date: "6/14/2019 01:44:53", shift: "A3", acuity: 3 },
  { date: "6/14/2019 01:58:26", shift: "B3", acuity: 4 },
  { date: "6/14/2019 02:22:15", shift: "A3", acuity: 4 },
  { date: "6/14/2019 02:37:02", shift: "B3", acuity: 3 },
  { date: "6/14/2019 02:37:26", shift: "A3", acuity: 3 },
  { date: "6/14/2019 02:41:45", shift: "B3", acuity: 4 },
  { date: "6/14/2019 03:02:37", shift: "A3", acuity: 5 },
  { date: "6/14/2019 03:02:47", shift: "B3", acuity: 3 },
  { date: "6/14/2019 03:22:00", shift: "A3", acuity: 3 },
  { date: "6/14/2019 03:26:04", shift: "B3", acuity: 3 },
  { date: "6/14/2019 03:35:16", shift: "A3", acuity: 2 },
  { date: "6/14/2019 03:48:39", shift: "B3", acuity: 5 },
  { date: "6/14/2019 03:48:45", shift: "A3", acuity: 3 },
  { date: "6/14/2019 05:02:10", shift: "A1", acuity: 3 },
  { date: "6/14/2019 05:09:55", shift: "B3", acuity: 4 },
  { date: "6/14/2019 05:10:09", shift: "A1", acuity: 3 },
  { date: "6/14/2019 06:15:19", shift: "A1", acuity: 3 },
  { date: "6/14/2019 06:16:26", shift: "A1", acuity: 3 },
  { date: "6/14/2019 06:28:59", shift: "B1", acuity: 4 },
  { date: "6/14/2019 07:31:46", shift: "B1", acuity: 4 },
  { date: "6/14/2019 07:42:44", shift: "B1", acuity: 3 },
  { date: "6/14/2019 08:35:29", shift: "C1", acuity: 3 },
  { date: "6/14/2019 08:35:37", shift: "A1", acuity: 4 },
  { date: "6/14/2019 09:00:22", shift: "C1", acuity: 3 },
  { date: "6/14/2019 09:03:56", shift: "C1", acuity: 4 },
  { date: "6/14/2019 09:12:57", shift: "B1", acuity: 3 },
  { date: "6/14/2019 09:20:32", shift: "A1", acuity: 3 },
  { date: "6/14/2019 09:24:46", shift: "C1", acuity: 3 },
  { date: "6/14/2019 09:36:09", shift: "B1", acuity: 3 },
  { date: "6/14/2019 09:37:32", shift: "A1", acuity: 2 },
  { date: "6/14/2019 09:41:01", shift: "C1", acuity: 3 },
  { date: "6/14/2019 10:00:17", shift: "D1", acuity: 3 },
  { date: "6/14/2019 10:00:39", shift: "B1", acuity: 3 },
  { date: "6/14/2019 10:15:33", shift: "A1", acuity: 3 },
  { date: "6/14/2019 10:15:48", shift: "C1", acuity: 3 },
  { date: "6/14/2019 10:25:08", shift: "D1", acuity: 3 },
  { date: "6/14/2019 11:00:25", shift: "D1", acuity: 3 },
  { date: "6/14/2019 11:02:29", shift: "D1", acuity: 2 },
  { date: "6/14/2019 11:04:50", shift: "B1", acuity: 2 },
  { date: "6/14/2019 11:25:24", shift: "A1", acuity: 3 },
  { date: "6/14/2019 11:25:47", shift: "C1", acuity: 3 },
  { date: "6/14/2019 11:25:59", shift: "D1", acuity: 2 },
  { date: "6/14/2019 11:27:39", shift: "B1", acuity: 3 },
  { date: "6/14/2019 11:32:05", shift: "A1", acuity: 4 },
  { date: "6/14/2019 11:46:27", shift: "C1", acuity: 2 },
  { date: "6/14/2019 11:49:29", shift: "D1", acuity: 3 },
  { date: "6/14/2019 11:55:11", shift: "B1", acuity: 2 },
  { date: "6/14/2019 11:55:20", shift: "A1", acuity: 3 },
  { date: "6/14/2019 11:57:46", shift: "C1", acuity: 3 },
  { date: "6/14/2019 12:08:23", shift: "D1", acuity: 3 },
  { date: "6/14/2019 12:09:44", shift: "B1", acuity: 3 },
  { date: "6/14/2019 12:22:43", shift: "A1", acuity: 2 },
  { date: "6/14/2019 12:26:41", shift: "C1", acuity: 2 },
  { date: "6/14/2019 12:52:32", shift: "D1", acuity: 3 },
  { date: "6/14/2019 12:58:08", shift: "B1", acuity: 2 },
  { date: "6/14/2019 13:13:04", shift: "A2", acuity: 2 },
  { date: "6/14/2019 13:25:12", shift: "C1", acuity: 2 },
  { date: "6/14/2019 13:26:39", shift: "D1", acuity: 2 },
  { date: "6/14/2019 13:26:44", shift: "B1", acuity: 3 },
  { date: "6/14/2019 13:46:55", shift: "A2", acuity: 2 },
  { date: "6/14/2019 13:55:29", shift: "A2", acuity: 3 },
  { date: "6/14/2019 14:24:56", shift: "B2", acuity: 3 },
  { date: "6/14/2019 14:25:38", shift: "C1", acuity: 2 },
  { date: "6/14/2019 14:46:51", shift: "B2", acuity: 2 },
  { date: "6/14/2019 15:06:33", shift: "B2", acuity: 2 },
  { date: "6/14/2019 15:21:45", shift: "D1", acuity: 3 },
  { date: "6/14/2019 15:27:54", shift: "A2", acuity: 4 },
  { date: "6/14/2019 15:48:06", shift: "C1", acuity: 3 },
  { date: "6/14/2019 15:50:28", shift: "B2", acuity: 2 },
  { date: "6/14/2019 15:52:55", shift: "D1", acuity: 3 },
  { date: "6/14/2019 15:54:31", shift: "A2", acuity: 2 },
  { date: "6/14/2019 16:02:36", shift: "C1", acuity: 2 },
  { date: "6/14/2019 16:07:22", shift: "B2", acuity: 2 },
  { date: "6/14/2019 16:14:20", shift: "D1", acuity: 2 },
  { date: "6/14/2019 16:31:36", shift: "C2", acuity: 3 },
  { date: "6/14/2019 16:55:29", shift: "A2", acuity: 2 },
  { date: "6/14/2019 17:01:48", shift: "C2", acuity: 2 },
  { date: "6/14/2019 17:09:01", shift: "C2", acuity: 2 },
  { date: "6/14/2019 17:20:33", shift: "B2", acuity: 2 },
  { date: "6/14/2019 17:20:55", shift: "D1", acuity: 2 },
  { date: "6/14/2019 17:33:03", shift: "A2", acuity: 4 },
  { date: "6/14/2019 17:33:29", shift: "C2", acuity: 2 },
  { date: "6/14/2019 17:42:28", shift: "B2", acuity: 2 },
  { date: "6/14/2019 17:44:06", shift: "D1", acuity: 3 },
  { date: "6/14/2019 18:08:26", shift: "D2", acuity: 2 },
  { date: "6/14/2019 18:19:48", shift: "A2", acuity: 3 },
  { date: "6/14/2019 18:23:01", shift: "C2", acuity: 2 },
  { date: "6/14/2019 18:31:51", shift: "D2", acuity: 3 },
  { date: "6/14/2019 18:33:13", shift: "D2", acuity: 3 },
  { date: "6/14/2019 18:56:48", shift: "B2", acuity: 2 },
  { date: "6/14/2019 19:22:35", shift: "A2", acuity: 2 },
  { date: "6/14/2019 19:35:17", shift: "C2", acuity: 4 },
  { date: "6/14/2019 19:36:01", shift: "D2", acuity: 2 },
  { date: "6/14/2019 19:52:27", shift: "B2", acuity: 2 },
  { date: "6/14/2019 19:52:44", shift: "A2", acuity: 3 },
  { date: "6/14/2019 19:58:35", shift: "C2", acuity: 3 },
  { date: "6/14/2019 20:04:51", shift: "D2", acuity: 2 },
  { date: "6/14/2019 20:09:31", shift: "B2", acuity: 3 },
  { date: "6/14/2019 20:17:36", shift: "A2", acuity: 3 },
  { date: "6/14/2019 20:17:54", shift: "C2", acuity: 3 },
  { date: "6/14/2019 20:18:23", shift: "D2", acuity: 2 },
  { date: "6/14/2019 20:29:53", shift: "B2", acuity: 3 },
  { date: "6/14/2019 20:49:39", shift: "A2", acuity: 2 },
  { date: "6/14/2019 21:03:09", shift: "A3", acuity: 3 },
  { date: "6/14/2019 21:05:47", shift: "C2", acuity: 3 },
  { date: "6/14/2019 21:06:03", shift: "D2", acuity: 3 },
  { date: "6/14/2019 21:12:22", shift: "B2", acuity: 2 },
  { date: "6/14/2019 21:25:32", shift: "A3", acuity: 2 },
  { date: "6/14/2019 21:50:58", shift: "A3", acuity: 3 },
  { date: "6/14/2019 21:53:42", shift: "A3", acuity: 4 },
  { date: "6/14/2019 21:53:58", shift: "C2", acuity: 3 },
  { date: "6/14/2019 22:04:02", shift: "B3", acuity: 2 },
  { date: "6/14/2019 22:18:45", shift: "D2", acuity: 3 },
  { date: "6/14/2019 22:19:40", shift: "A3", acuity: 3 },
  { date: "6/14/2019 22:21:35", shift: "C2", acuity: 4 },
  { date: "6/14/2019 22:28:32", shift: "B3", acuity: 2 },
  { date: "6/14/2019 22:31:11", shift: "B3", acuity: 3 },
  { date: "6/14/2019 22:41:18", shift: "B3", acuity: 3 },
  { date: "6/14/2019 22:41:41", shift: "D2", acuity: 3 },
  { date: "6/14/2019 22:42:27", shift: "A3", acuity: 2 },
  { date: "6/14/2019 23:01:34", shift: "C2", acuity: 5 },
  { date: "6/14/2019 23:09:52", shift: "B3", acuity: 3 },
  { date: "6/14/2019 23:10:30", shift: "D2", acuity: 3 },
  { date: "6/14/2019 23:24:50", shift: "A3", acuity: 4 },
  { date: "6/14/2019 23:26:53", shift: "C2", acuity: 5 },
  { date: "6/14/2019 23:27:12", shift: "B3", acuity: 4 },
  { date: "6/14/2019 23:31:18", shift: "D2", acuity: 3 },
  { date: "6/14/2019 23:35:31", shift: "A3", acuity: 3 },
  { date: "6/14/2019 23:35:44", shift: "C2", acuity: 4 },
  { date: "6/14/2019 23:35:57", shift: "B3", acuity: 4 },
  { date: "6/14/2019 23:36:20", shift: "D2", acuity: 3 },
  { date: "6/14/2019 23:36:40", shift: "A3", acuity: 4 },
  { date: "6/14/2019 23:52:52", shift: "C2", acuity: 4 },
  { date: "6/15/2019 00:00:57", shift: "B3", acuity: 3 },
  { date: "6/15/2019 00:05:35", shift: "D2", acuity: 3 },
  { date: "6/15/2019 00:27:12", shift: "A3", acuity: 7 },
  { date: "6/15/2019 00:28:19", shift: "B3", acuity: 7 },
  { date: "6/15/2019 00:28:41", shift: "D2", acuity: 7 },
  { date: "6/15/2019 00:34:52", shift: "A3", acuity: 3 },
  { date: "6/15/2019 00:37:32", shift: "B3", acuity: 2 },
  { date: "6/15/2019 00:40:36", shift: "D2", acuity: 7 },
  { date: "6/15/2019 00:55:49", shift: "A3", acuity: 7 },
  { date: "6/15/2019 01:13:48", shift: "B3", acuity: 2 },
  { date: "6/15/2019 01:37:30", shift: "A3", acuity: 4 },
  { date: "6/15/2019 01:37:51", shift: "B3", acuity: 2 },
  { date: "6/15/2019 01:39:04", shift: "A3", acuity: 4 },
  { date: "6/15/2019 01:53:09", shift: "B3", acuity: 3 },
  { date: "6/15/2019 02:06:36", shift: "A3", acuity: 4 },
  { date: "6/15/2019 02:16:03", shift: "B3", acuity: 3 },
  { date: "6/15/2019 02:47:58", shift: "A3", acuity: 3 },
  { date: "6/15/2019 03:34:05", shift: "B3", acuity: 3 },
  { date: "6/15/2019 03:35:59", shift: "A3", acuity: 7 },
  { date: "6/15/2019 04:18:44", shift: "B3", acuity: 3 },
  { date: "6/15/2019 04:36:48", shift: "A3", acuity: 3 },
  { date: "6/15/2019 05:02:05", shift: "A1", acuity: 3 },
  { date: "6/15/2019 05:06:25", shift: "B3", acuity: 7 },
  { date: "6/15/2019 05:17:36", shift: "A1", acuity: 4 },
  { date: "6/15/2019 05:45:36", shift: "A1", acuity: 3 },
  { date: "6/15/2019 06:21:16", shift: "A1", acuity: 4 },
  { date: "6/15/2019 06:31:57", shift: "B1", acuity: 3 },
  { date: "6/15/2019 06:32:07", shift: "B1", acuity: 3 },
  { date: "6/15/2019 06:47:45", shift: "B1", acuity: 3 },
  { date: "6/15/2019 06:58:49", shift: "A1", acuity: 3 },
  { date: "6/15/2019 07:07:28", shift: "B1", acuity: 3 },
  { date: "6/15/2019 07:18:14", shift: "A1", acuity: 3 },
  { date: "6/15/2019 07:51:03", shift: "B1", acuity: 3 },
  { date: "6/15/2019 07:55:13", shift: "A1", acuity: 4 },
  { date: "6/15/2019 07:55:51", shift: "B1", acuity: 2 },
  { date: "6/15/2019 08:17:03", shift: "A1", acuity: 3 },
  { date: "6/15/2019 08:31:25", shift: "C1", acuity: 3 },
  { date: "6/15/2019 08:37:19", shift: "B1", acuity: 3 },
  { date: "6/15/2019 08:47:23", shift: "A1", acuity: 3 },
  { date: "6/15/2019 09:03:10", shift: "C1", acuity: 3 },
  { date: "6/15/2019 09:03:24", shift: "C1", acuity: 2 },
  { date: "6/15/2019 09:03:55", shift: "B1", acuity: 3 },
  { date: "6/15/2019 09:09:58", shift: "A1", acuity: 3 },
  { date: "6/15/2019 09:21:53", shift: "C1", acuity: 3 },
  { date: "6/15/2019 09:33:22", shift: "B1", acuity: 3 },
  { date: "6/15/2019 09:33:43", shift: "A1", acuity: 4 },
  { date: "6/15/2019 09:38:01", shift: "C1", acuity: 3 },
  { date: "6/15/2019 09:48:23", shift: "B1", acuity: 2 },
  { date: "6/15/2019 10:01:18", shift: "D1", acuity: 3 },
  { date: "6/15/2019 10:27:28", shift: "A1", acuity: 2 },
  { date: "6/15/2019 10:27:54", shift: "C1", acuity: 3 },
  { date: "6/15/2019 10:33:07", shift: "D1", acuity: 3 },
  { date: "6/15/2019 10:42:10", shift: "D1", acuity: 3 },
  { date: "6/15/2019 10:53:46", shift: "B1", acuity: 2 },
  { date: "6/15/2019 11:01:37", shift: "A1", acuity: 3 },
  { date: "6/15/2019 11:23:46", shift: "C1", acuity: 2 },
  { date: "6/15/2019 11:24:46", shift: "D1", acuity: 3 },
  { date: "6/15/2019 11:34:22", shift: "B1", acuity: 3 },
  { date: "6/15/2019 11:55:23", shift: "A1", acuity: 3 },
  { date: "6/15/2019 11:55:54", shift: "C1", acuity: 2 },
  { date: "6/15/2019 12:09:23", shift: "D1", acuity: 3 },
  { date: "6/15/2019 12:30:26", shift: "B1", acuity: 2 },
  { date: "6/15/2019 12:30:45", shift: "A1", acuity: 2 },
  { date: "6/15/2019 12:35:25", shift: "C1", acuity: 2 },
  { date: "6/15/2019 12:51:49", shift: "D1", acuity: 2 },
  { date: "6/15/2019 12:53:58", shift: "B1", acuity: 4 },
  { date: "6/15/2019 13:02:16", shift: "A2", acuity: 4 },
  { date: "6/15/2019 13:28:11", shift: "C1", acuity: 2 },
  { date: "6/15/2019 13:28:40", shift: "D1", acuity: 3 },
  { date: "6/15/2019 13:29:57", shift: "B1", acuity: 3 },
  { date: "6/15/2019 13:53:14", shift: "A2", acuity: 3 },
  { date: "6/15/2019 13:54:04", shift: "A2", acuity: 2 },
  { date: "6/15/2019 14:05:49", shift: "B2", acuity: 3 },
  { date: "6/15/2019 14:08:16", shift: "C1", acuity: 2 },
  { date: "6/15/2019 14:31:24", shift: "B2", acuity: 2 },
  { date: "6/15/2019 14:35:27", shift: "B2", acuity: 3 },
  { date: "6/15/2019 14:39:18", shift: "D1", acuity: 3 },
  { date: "6/15/2019 14:54:36", shift: "A2", acuity: 3 },
  { date: "6/15/2019 14:59:21", shift: "C1", acuity: 2 },
  { date: "6/15/2019 15:02:29", shift: "B2", acuity: 2 },
  { date: "6/15/2019 15:14:55", shift: "D1", acuity: 4 },
  { date: "6/15/2019 15:15:11", shift: "A2", acuity: 2 },
  { date: "6/15/2019 15:29:44", shift: "C1", acuity: 3 },
  { date: "6/15/2019 15:51:39", shift: "B2", acuity: 3 },
  { date: "6/15/2019 15:51:51", shift: "D1", acuity: 3 },
  { date: "6/15/2019 16:05:12", shift: "A2", acuity: 3 },
  { date: "6/15/2019 16:23:10", shift: "C1", acuity: 3 },
  { date: "6/15/2019 16:35:03", shift: "C2", acuity: 3 },
  { date: "6/15/2019 16:35:23", shift: "B2", acuity: 3 },
  { date: "6/15/2019 16:49:18", shift: "D1", acuity: 2 },
  { date: "6/15/2019 16:52:50", shift: "A2", acuity: 3 },
  { date: "6/15/2019 17:00:53", shift: "C2", acuity: 2 },
  { date: "6/15/2019 17:01:55", shift: "C2", acuity: 2 },
  { date: "6/15/2019 17:04:30", shift: "B2", acuity: 3 },
  { date: "6/15/2019 17:06:30", shift: "D1", acuity: 3 },
  { date: "6/15/2019 17:11:14", shift: "A2", acuity: 2 },
  { date: "6/15/2019 17:19:39", shift: "C2", acuity: 3 },
  { date: "6/15/2019 17:39:35", shift: "B2", acuity: 4 },
  { date: "6/15/2019 17:39:47", shift: "D1", acuity: 3 },
  { date: "6/15/2019 17:52:47", shift: "A2", acuity: 3 },
  { date: "6/15/2019 17:59:50", shift: "C2", acuity: 4 },
  { date: "6/15/2019 18:24:35", shift: "D2", acuity: 3 },
  { date: "6/15/2019 18:25:06", shift: "B2", acuity: 2 },
  { date: "6/15/2019 18:36:06", shift: "D2", acuity: 3 },
  { date: "6/15/2019 18:44:56", shift: "D2", acuity: 2 },
  { date: "6/15/2019 18:46:57", shift: "A2", acuity: 2 },
  { date: "6/15/2019 18:47:35", shift: "C2", acuity: 4 },
  { date: "6/15/2019 18:50:28", shift: "B2", acuity: 3 },
  { date: "6/15/2019 19:00:21", shift: "D2", acuity: 2 },
  { date: "6/15/2019 19:10:16", shift: "A2", acuity: 2 },
  { date: "6/15/2019 19:27:20", shift: "C2", acuity: 2 },
  { date: "6/15/2019 19:47:48", shift: "B2", acuity: 3 },
  { date: "6/15/2019 19:51:11", shift: "D2", acuity: 2 },
  { date: "6/15/2019 19:51:29", shift: "A2", acuity: 2 },
  { date: "6/15/2019 19:52:18", shift: "C2", acuity: 2 },
  { date: "6/15/2019 19:56:11", shift: "B2", acuity: 3 },
  { date: "6/15/2019 20:05:43", shift: "D2", acuity: 3 },
  { date: "6/15/2019 20:12:24", shift: "A2", acuity: 4 },
  { date: "6/15/2019 20:12:35", shift: "C2", acuity: 3 },
  { date: "6/15/2019 20:19:19", shift: "B2", acuity: 3 },
  { date: "6/15/2019 20:20:09", shift: "D2", acuity: 3 },
  { date: "6/15/2019 20:41:38", shift: "A2", acuity: 3 },
  { date: "6/15/2019 20:50:47", shift: "C2", acuity: 3 },
  { date: "6/15/2019 20:54:05", shift: "B2", acuity: 3 },
  { date: "6/15/2019 21:04:28", shift: "A3", acuity: 4 },
  { date: "6/15/2019 21:04:40", shift: "D2", acuity: 3 },
  { date: "6/15/2019 21:09:26", shift: "C2", acuity: 3 },
  { date: "6/15/2019 21:24:50", shift: "B2", acuity: 3 },
  { date: "6/15/2019 21:25:37", shift: "A3", acuity: 4 },
  { date: "6/15/2019 21:26:06", shift: "D2", acuity: 2 },
  { date: "6/15/2019 21:28:48", shift: "C2", acuity: 3 },
  { date: "6/15/2019 21:41:32", shift: "A3", acuity: 2 },
  { date: "6/15/2019 21:42:22", shift: "A3", acuity: 3 },
  { date: "6/15/2019 21:47:29", shift: "B2", acuity: 3 },
  { date: "6/15/2019 21:51:21", shift: "D2", acuity: 3 },
  { date: "6/15/2019 22:16:41", shift: "B3", acuity: 3 },
  { date: "6/15/2019 22:16:56", shift: "C2", acuity: 3 },
  { date: "6/15/2019 22:26:46", shift: "A3", acuity: 3 },
  { date: "6/15/2019 22:26:58", shift: "D2", acuity: 3 },
  { date: "6/15/2019 22:40:01", shift: "B3", acuity: 3 },
  { date: "6/15/2019 22:40:13", shift: "B3", acuity: 3 },
  { date: "6/15/2019 22:49:10", shift: "C2", acuity: 3 },
  { date: "6/15/2019 22:49:23", shift: "A3", acuity: 3 },
  { date: "6/15/2019 22:49:37", shift: "D2", acuity: 4 },
  { date: "6/15/2019 23:01:02", shift: "B3", acuity: 3 },
  { date: "6/15/2019 23:08:42", shift: "C2", acuity: 3 },
  { date: "6/15/2019 23:15:26", shift: "A3", acuity: 2 },
  { date: "6/15/2019 23:16:25", shift: "D2", acuity: 2 },
  { date: "6/15/2019 23:27:21", shift: "B3", acuity: 3 },
  { date: "6/15/2019 23:31:35", shift: "C2", acuity: 3 },
  { date: "6/15/2019 23:39:25", shift: "A3", acuity: 2 },
  { date: "6/15/2019 23:40:10", shift: "D2", acuity: 3 },
  { date: "6/15/2019 23:43:18", shift: "B3", acuity: 4 },
  { date: "6/15/2019 23:49:22", shift: "C2", acuity: 4 },
  { date: "6/15/2019 23:57:12", shift: "A3", acuity: 3 },
  { date: "6/16/2019 00:00:19", shift: "D2", acuity: 4 },
  { date: "6/16/2019 00:15:42", shift: "B3", acuity: 3 },
  { date: "6/16/2019 00:21:01", shift: "A3", acuity: 2 },
  { date: "6/16/2019 00:25:59", shift: "D2", acuity: 3 },
  { date: "6/16/2019 00:31:23", shift: "B3", acuity: 2 },
  { date: "6/16/2019 00:48:40", shift: "A3", acuity: 4 },
  { date: "6/16/2019 01:10:58", shift: "D2", acuity: 4 },
  { date: "6/16/2019 01:11:16", shift: "B3", acuity: 5 },
  { date: "6/16/2019 01:11:41", shift: "A3", acuity: 3 },
  { date: "6/16/2019 01:24:14", shift: "D2", acuity: 2 },
  { date: "6/16/2019 01:46:03", shift: "B3", acuity: 3 },
  { date: "6/16/2019 02:00:14", shift: "A3", acuity: 3 },
  { date: "6/16/2019 02:06:16", shift: "B3", acuity: 3 },
  { date: "6/16/2019 02:09:43", shift: "A3", acuity: 2 },
  { date: "6/16/2019 02:19:31", shift: "B3", acuity: 3 },
  { date: "6/16/2019 02:27:12", shift: "A3", acuity: 4 },
  { date: "6/16/2019 02:36:32", shift: "B3", acuity: 3 },
  { date: "6/16/2019 02:52:35", shift: "A3", acuity: 3 },
  { date: "6/16/2019 02:56:24", shift: "B3", acuity: 3 },
  { date: "6/16/2019 03:15:07", shift: "A3", acuity: 3 },
  { date: "6/16/2019 03:30:42", shift: "B3", acuity: 2 },
  { date: "6/16/2019 03:34:49", shift: "A3", acuity: 2 },
  { date: "6/16/2019 04:16:07", shift: "B3", acuity: 2 },
  { date: "6/16/2019 04:16:15", shift: "A3", acuity: 3 },
  { date: "6/16/2019 04:24:28", shift: "B3", acuity: 3 },
  { date: "6/16/2019 04:53:39", shift: "A3", acuity: 3 },
  { date: "6/16/2019 05:12:13", shift: "A1", acuity: 3 },
  { date: "6/16/2019 05:17:57", shift: "B3", acuity: 4 },
  { date: "6/16/2019 05:40:33", shift: "A1", acuity: 4 },
  { date: "6/16/2019 06:17:23", shift: "A1", acuity: 4 },
  { date: "6/16/2019 06:36:22", shift: "B1", acuity: 3 },
  { date: "6/16/2019 06:47:48", shift: "B1", acuity: 3 },
  { date: "6/16/2019 07:10:11", shift: "B1", acuity: 3 },
  { date: "6/16/2019 07:33:56", shift: "A1", acuity: 3 },
  { date: "6/16/2019 07:53:12", shift: "B1", acuity: 3 },
  { date: "6/16/2019 07:58:24", shift: "A1", acuity: 3 },
  { date: "6/16/2019 08:11:56", shift: "B1", acuity: 4 },
  { date: "6/16/2019 08:25:54", shift: "A1", acuity: 2 },
  { date: "6/16/2019 08:31:27", shift: "C1", acuity: 4 },
  { date: "6/16/2019 08:52:27", shift: "B1", acuity: 3 },
  { date: "6/16/2019 08:57:53", shift: "A1", acuity: 3 },
  { date: "6/16/2019 09:06:57", shift: "C1", acuity: 4 },
  { date: "6/16/2019 09:20:45", shift: "C1", acuity: 2 },
  { date: "6/16/2019 09:21:08", shift: "B1", acuity: 3 },
  { date: "6/16/2019 09:35:17", shift: "A1", acuity: 3 },
  { date: "6/16/2019 09:40:02", shift: "C1", acuity: 3 },
  { date: "6/16/2019 10:08:22", shift: "D1", acuity: 4 },
  { date: "6/16/2019 10:23:02", shift: "B1", acuity: 4 },
  { date: "6/16/2019 10:30:22", shift: "D1", acuity: 2 },
  { date: "6/16/2019 10:35:53", shift: "D1", acuity: 2 },
  { date: "6/16/2019 10:55:10", shift: "A1", acuity: 3 },
  { date: "6/16/2019 11:14:53", shift: "C1", acuity: 3 },
  { date: "6/16/2019 11:26:14", shift: "B1", acuity: 3 },
  { date: "6/16/2019 11:26:43", shift: "D1", acuity: 3 },
  { date: "6/16/2019 11:42:54", shift: "A1", acuity: 3 },
  { date: "6/16/2019 11:56:14", shift: "C1", acuity: 3 },
  { date: "6/16/2019 12:11:54", shift: "B1", acuity: 3 },
  { date: "6/16/2019 12:30:29", shift: "D1", acuity: 2 },
  { date: "6/16/2019 12:47:40", shift: "A1", acuity: 3 },
  { date: "6/16/2019 12:48:10", shift: "C1", acuity: 3 },
  { date: "6/16/2019 12:57:15", shift: "B1", acuity: 2 },
  { date: "6/16/2019 12:57:33", shift: "D1", acuity: 2 },
  { date: "6/16/2019 12:59:23", shift: "A1", acuity: 2 },
  { date: "6/16/2019 12:59:43", shift: "C1", acuity: 3 },
  { date: "6/16/2019 13:09:00", shift: "A2", acuity: 3 },
  { date: "6/16/2019 13:33:39", shift: "A2", acuity: 2 },
  { date: "6/16/2019 13:37:29", shift: "A2", acuity: 2 },
  { date: "6/16/2019 13:45:42", shift: "B1", acuity: 2 },
  { date: "6/16/2019 13:53:52", shift: "D1", acuity: 3 },
  { date: "6/16/2019 13:58:52", shift: "C1", acuity: 3 },
  { date: "6/16/2019 14:12:53", shift: "B2", acuity: 2 },
  { date: "6/16/2019 14:18:26", shift: "A2", acuity: 3 },
  { date: "6/16/2019 14:43:34", shift: "B2", acuity: 2 },
  { date: "6/16/2019 14:44:11", shift: "B2", acuity: 3 },
  { date: "6/16/2019 14:57:51", shift: "D1", acuity: 3 },
  { date: "6/16/2019 15:16:58", shift: "C1", acuity: 2 },
  { date: "6/16/2019 15:20:42", shift: "A2", acuity: 2 },
  { date: "6/16/2019 15:28:50", shift: "B2", acuity: 3 },
  { date: "6/16/2019 15:34:23", shift: "D1", acuity: 3 },
  { date: "6/16/2019 15:34:42", shift: "C1", acuity: 3 },
  { date: "6/16/2019 15:43:39", shift: "A2", acuity: 3 },
  { date: "6/16/2019 15:55:14", shift: "B2", acuity: 2 },
  { date: "6/16/2019 15:55:36", shift: "D1", acuity: 3 },
  { date: "6/16/2019 16:00:15", shift: "C1", acuity: 3 },
  { date: "6/16/2019 16:15:36", shift: "A2", acuity: 2 },
  { date: "6/16/2019 16:15:51", shift: "B2", acuity: 3 },
  { date: "6/16/2019 16:23:05", shift: "D1", acuity: 2 },
  { date: "6/16/2019 16:42:46", shift: "C2", acuity: 3 },
  { date: "6/16/2019 16:43:08", shift: "A2", acuity: 3 },
  { date: "6/16/2019 16:44:13", shift: "B2", acuity: 3 },
  { date: "6/16/2019 16:45:34", shift: "D1", acuity: 3 },
  { date: "6/16/2019 17:13:31", shift: "C2", acuity: 2 },
  { date: "6/16/2019 17:19:56", shift: "C2", acuity: 2 },
  { date: "6/16/2019 17:35:55", shift: "A2", acuity: 3 },
  { date: "6/16/2019 17:42:32", shift: "B2", acuity: 3 },
  { date: "6/16/2019 17:58:57", shift: "D1", acuity: 4 },
  { date: "6/16/2019 18:11:52", shift: "D2", acuity: 3 },
  { date: "6/16/2019 18:13:43", shift: "C2", acuity: 2 },
  { date: "6/16/2019 18:15:12", shift: "A2", acuity: 3 },
  { date: "6/16/2019 18:35:50", shift: "D2", acuity: 3 },
  { date: "6/16/2019 18:36:17", shift: "D2", acuity: 3 },
  { date: "6/16/2019 18:43:19", shift: "B2", acuity: 3 },
  { date: "6/16/2019 19:00:21", shift: "C2", acuity: 3 },
  { date: "6/16/2019 19:20:02", shift: "A2", acuity: 3 },
  { date: "6/16/2019 19:30:08", shift: "D2", acuity: 3 },
  { date: "6/16/2019 19:33:48", shift: "B2", acuity: 3 },
  { date: "6/16/2019 19:38:40", shift: "C2", acuity: 3 },
  { date: "6/16/2019 19:45:50", shift: "A2", acuity: 4 },
  { date: "6/16/2019 19:46:02", shift: "D2", acuity: 4 },
  { date: "6/16/2019 19:51:04", shift: "B2", acuity: 2 },
  { date: "6/16/2019 20:09:01", shift: "C2", acuity: 3 },
  { date: "6/16/2019 20:09:13", shift: "A2", acuity: 7 },
  { date: "6/16/2019 20:18:16", shift: "D2", acuity: 2 },
  { date: "6/16/2019 20:29:10", shift: "B2", acuity: 4 },
  { date: "6/16/2019 20:29:20", shift: "C2", acuity: 3 },
  { date: "6/16/2019 20:39:05", shift: "A2", acuity: 3 },
  { date: "6/16/2019 20:53:42", shift: "D2", acuity: 3 },
  { date: "6/16/2019 20:53:57", shift: "B2", acuity: 3 },
  { date: "6/16/2019 21:04:42", shift: "A3", acuity: 2 },
  { date: "6/16/2019 21:05:07", shift: "C2", acuity: 2 },
  { date: "6/16/2019 21:18:48", shift: "D2", acuity: 3 },
  { date: "6/16/2019 21:21:13", shift: "B2", acuity: 5 },
  { date: "6/16/2019 21:26:40", shift: "A3", acuity: 3 },
  { date: "6/16/2019 21:36:59", shift: "A3", acuity: 7 },
  { date: "6/16/2019 21:50:07", shift: "A3", acuity: 3 },
  { date: "6/16/2019 22:05:00", shift: "B3", acuity: 3 },
  { date: "6/16/2019 22:06:55", shift: "C2", acuity: 3 },
  { date: "6/16/2019 22:15:23", shift: "D2", acuity: 2 },
  { date: "6/16/2019 22:20:25", shift: "A3", acuity: 3 },
  { date: "6/16/2019 22:43:47", shift: "B3", acuity: 3 },
  { date: "6/16/2019 22:44:08", shift: "B3", acuity: 5 },
  { date: "6/16/2019 22:44:56", shift: "C2", acuity: 4 },
  { date: "6/16/2019 22:57:38", shift: "D2", acuity: 3 },
  { date: "6/16/2019 23:04:14", shift: "A3", acuity: 4 },
  { date: "6/16/2019 23:12:54", shift: "B3", acuity: 3 },
  { date: "6/16/2019 23:34:19", shift: "C2", acuity: 2 },
  { date: "6/16/2019 23:43:00", shift: "D2", acuity: 3 },
  { date: "6/16/2019 23:43:36", shift: "A3", acuity: 4 },
  { date: "6/16/2019 23:47:38", shift: "B3", acuity: 3 },
  { date: "6/16/2019 23:57:40", shift: "C2", acuity: 3 },
  { date: "6/17/2019 00:06:09", shift: "D2", acuity: 7 },
  { date: "6/17/2019 00:18:59", shift: "A3", acuity: 3 },
  { date: "6/17/2019 00:19:17", shift: "B3", acuity: 3 },
  { date: "6/17/2019 00:21:32", shift: "D2", acuity: 2 },
  { date: "6/17/2019 00:40:11", shift: "A3", acuity: 4 },
  { date: "6/17/2019 00:45:14", shift: "B3", acuity: 3 },
  { date: "6/17/2019 00:53:10", shift: "D2", acuity: 2 },
  { date: "6/17/2019 01:06:46", shift: "A3", acuity: 3 },
  { date: "6/17/2019 01:06:59", shift: "B3", acuity: 4 },
  { date: "6/17/2019 01:34:23", shift: "A3", acuity: 3 },
  { date: "6/17/2019 01:51:21", shift: "B3", acuity: 7 },
  { date: "6/17/2019 01:55:20", shift: "A3", acuity: 7 },
  { date: "6/17/2019 01:59:59", shift: "B3", acuity: 4 },
  { date: "6/17/2019 02:16:54", shift: "A3", acuity: 2 },
  { date: "6/17/2019 02:27:04", shift: "B3", acuity: 3 },
  { date: "6/17/2019 02:39:21", shift: "A3", acuity: 5 },
  { date: "6/17/2019 03:09:23", shift: "B3", acuity: 4 },
  { date: "6/17/2019 03:22:13", shift: "A3", acuity: 4 },
  { date: "6/17/2019 03:28:25", shift: "B3", acuity: 3 },
  { date: "6/17/2019 03:56:45", shift: "A3", acuity: 4 },
  { date: "6/17/2019 04:15:45", shift: "B3", acuity: 3 },
  { date: "6/17/2019 04:24:10", shift: "A3", acuity: 3 },
  { date: "6/17/2019 06:06:14", shift: "A1", acuity: 3 },
  { date: "6/17/2019 06:06:26", shift: "A1", acuity: 3 },
  { date: "6/17/2019 06:55:16", shift: "A1", acuity: 3 },
  { date: "6/17/2019 07:30:14", shift: "B1", acuity: 3 },
  { date: "6/17/2019 07:30:32", shift: "B1", acuity: 2 },
  { date: "6/17/2019 07:35:23", shift: "A1", acuity: 3 },
  { date: "6/17/2019 07:43:44", shift: "B1", acuity: 3 },
  { date: "6/17/2019 07:43:56", shift: "A1", acuity: 4 },
  { date: "6/17/2019 07:49:55", shift: "B1", acuity: 3 },
  { date: "6/17/2019 08:23:37", shift: "A1", acuity: 2 },
  { date: "6/17/2019 09:00:58", shift: "C1", acuity: 3 },
  { date: "6/17/2019 09:08:25", shift: "C1", acuity: 3 },
  { date: "6/17/2019 09:15:47", shift: "C1", acuity: 3 },
  { date: "6/17/2019 09:18:53", shift: "B1", acuity: 3 },
  { date: "6/17/2019 09:27:42", shift: "A1", acuity: 4 },
  { date: "6/17/2019 09:28:27", shift: "C1", acuity: 2 },
  { date: "6/17/2019 09:39:01", shift: "B1", acuity: 4 },
  { date: "6/17/2019 09:43:22", shift: "A1", acuity: 3 },
  { date: "6/17/2019 09:58:11", shift: "C1", acuity: 3 },
  { date: "6/17/2019 10:21:40", shift: "D1", acuity: 2 },
  { date: "6/17/2019 10:21:54", shift: "B1", acuity: 2 },
  { date: "6/17/2019 10:42:35", shift: "D1", acuity: 2 },
  { date: "6/17/2019 10:46:27", shift: "D1", acuity: 3 },
  { date: "6/17/2019 10:56:52", shift: "A1", acuity: 2 },
  { date: "6/17/2019 10:57:18", shift: "C1", acuity: 3 },
  { date: "6/17/2019 11:17:10", shift: "B1", acuity: 2 },
  { date: "6/17/2019 11:17:27", shift: "D1", acuity: 2 },
  { date: "6/17/2019 11:17:41", shift: "A1", acuity: 2 },
  { date: "6/17/2019 11:17:58", shift: "C1", acuity: 3 },
  { date: "6/17/2019 11:18:09", shift: "B1", acuity: 3 },
  { date: "6/17/2019 11:27:54", shift: "D1", acuity: 3 },
  { date: "6/17/2019 11:54:17", shift: "A1", acuity: 3 },
  { date: "6/17/2019 11:54:29", shift: "C1", acuity: 3 },
  { date: "6/17/2019 12:01:41", shift: "B1", acuity: 2 },
  { date: "6/17/2019 12:12:12", shift: "D1", acuity: 3 },
  { date: "6/17/2019 12:18:33", shift: "A1", acuity: 3 },
  { date: "6/17/2019 12:31:14", shift: "C1", acuity: 2 },
  { date: "6/17/2019 12:35:41", shift: "B1", acuity: 3 },
  { date: "6/17/2019 12:36:57", shift: "D1", acuity: 3 },
  { date: "6/17/2019 12:52:47", shift: "A1", acuity: 3 },
  { date: "6/17/2019 12:54:20", shift: "C1", acuity: 3 },
  { date: "6/17/2019 13:28:45", shift: "A2", acuity: 2 },
  { date: "6/17/2019 13:29:03", shift: "B1", acuity: 2 },
  { date: "6/17/2019 13:31:35", shift: "A2", acuity: 2 },
  { date: "6/17/2019 14:08:09", shift: "A2", acuity: 3 },
  { date: "6/17/2019 14:20:28", shift: "B2", acuity: 3 },
  { date: "6/17/2019 14:20:37", shift: "D1", acuity: 3 },
  { date: "6/17/2019 14:20:48", shift: "C1", acuity: 2 },
  { date: "6/17/2019 14:20:58", shift: "A2", acuity: 3 },
  { date: "6/17/2019 14:41:20", shift: "B2", acuity: 2 },
  { date: "6/17/2019 14:55:57", shift: "B2", acuity: 3 },
  { date: "6/17/2019 14:59:32", shift: "D1", acuity: 3 },
  { date: "6/17/2019 15:01:18", shift: "C1", acuity: 3 },
  { date: "6/17/2019 15:31:18", shift: "A2", acuity: 3 },
  { date: "6/17/2019 15:32:01", shift: "B2", acuity: 3 },
  { date: "6/17/2019 15:32:16", shift: "D1", acuity: 3 },
  { date: "6/17/2019 15:43:54", shift: "C1", acuity: 3 },
  { date: "6/17/2019 16:08:36", shift: "A2", acuity: 3 },
  { date: "6/17/2019 16:23:35", shift: "B2", acuity: 3 },
  { date: "6/17/2019 16:34:23", shift: "C2", acuity: 3 },
  { date: "6/17/2019 17:01:49", shift: "C2", acuity: 2 },
  { date: "6/17/2019 17:07:29", shift: "C2", acuity: 2 },
  { date: "6/17/2019 17:15:21", shift: "D1", acuity: 2 },
  { date: "6/17/2019 17:17:57", shift: "A2", acuity: 3 },
  { date: "6/17/2019 17:18:22", shift: "B2", acuity: 3 },
  { date: "6/17/2019 17:22:37", shift: "C2", acuity: 2 },
  { date: "6/17/2019 17:31:24", shift: "D1", acuity: 2 },
  { date: "6/17/2019 17:52:32", shift: "A2", acuity: 7 },
  { date: "6/17/2019 17:52:47", shift: "B2", acuity: 2 },
  { date: "6/17/2019 17:59:11", shift: "C2", acuity: 3 },
  { date: "6/17/2019 18:12:47", shift: "D2", acuity: 2 },
  { date: "6/17/2019 18:13:03", shift: "A2", acuity: 3 },
  { date: "6/17/2019 18:20:53", shift: "B2", acuity: 7 },
  { date: "6/17/2019 18:39:54", shift: "D2", acuity: 7 },
  { date: "6/17/2019 18:43:59", shift: "D2", acuity: 3 },
  { date: "6/17/2019 19:07:57", shift: "C2", acuity: 7 },
  { date: "6/17/2019 19:12:43", shift: "A2", acuity: 2 },
  { date: "6/17/2019 19:15:50", shift: "B2", acuity: 7 },
  { date: "6/17/2019 19:30:43", shift: "D2", acuity: 3 },
  { date: "6/17/2019 19:43:09", shift: "C2", acuity: 2 },
  { date: "6/17/2019 19:45:05", shift: "A2", acuity: 3 },
  { date: "6/17/2019 20:06:59", shift: "B2", acuity: 7 },
  { date: "6/17/2019 20:09:12", shift: "D2", acuity: 7 },
  { date: "6/17/2019 20:20:47", shift: "C2", acuity: 3 },
  { date: "6/17/2019 20:23:31", shift: "A2", acuity: 2 },
  { date: "6/17/2019 20:42:30", shift: "B2", acuity: 2 },
  { date: "6/17/2019 20:46:48", shift: "D2", acuity: 3 },
  { date: "6/17/2019 20:49:28", shift: "C2", acuity: 2 },
  { date: "6/17/2019 21:11:48", shift: "A3", acuity: 3 },
  { date: "6/17/2019 21:19:18", shift: "B2", acuity: 3 },
  { date: "6/17/2019 21:19:46", shift: "D2", acuity: 2 },
  { date: "6/17/2019 21:51:41", shift: "A3", acuity: 2 },
  { date: "6/17/2019 21:59:16", shift: "A3", acuity: 3 },
  { date: "6/17/2019 21:59:49", shift: "C2", acuity: 2 },
  { date: "6/17/2019 22:04:04", shift: "B3", acuity: 3 },
  { date: "6/17/2019 22:11:53", shift: "D2", acuity: 3 },
  { date: "6/17/2019 22:38:31", shift: "B3", acuity: 3 },
  { date: "6/17/2019 22:50:05", shift: "B3", acuity: 2 },
  { date: "6/17/2019 22:55:35", shift: "A3", acuity: 2 },
  { date: "6/17/2019 23:08:55", shift: "C2", acuity: 2 },
  { date: "6/17/2019 23:23:50", shift: "D2", acuity: 2 },
  { date: "6/17/2019 23:28:44", shift: "B3", acuity: 2 },
  { date: "6/17/2019 23:35:50", shift: "A3", acuity: 3 },
  { date: "6/17/2019 23:40:40", shift: "C2", acuity: 3 },
  { date: "6/17/2019 23:46:57", shift: "D2", acuity: 3 },
  { date: "6/17/2019 23:49:29", shift: "B3", acuity: 4 },
  { date: "6/18/2019 00:03:46", shift: "A3", acuity: 3 },
  { date: "6/18/2019 00:22:44", shift: "D2", acuity: 2 },
  { date: "6/18/2019 00:47:00", shift: "B3", acuity: 5 },
  { date: "6/18/2019 00:50:50", shift: "A3", acuity: 2 },
  { date: "6/18/2019 00:59:15", shift: "D2", acuity: 3 },
  { date: "6/18/2019 01:13:28", shift: "B3", acuity: 4 },
  { date: "6/18/2019 01:14:53", shift: "A3", acuity: 3 },
  { date: "6/18/2019 01:27:35", shift: "D2", acuity: 3 },
  { date: "6/18/2019 01:46:08", shift: "B3", acuity: 4 },
  { date: "6/18/2019 01:52:19", shift: "A3", acuity: 4 },
  { date: "6/18/2019 02:00:48", shift: "B3", acuity: 3 },
  { date: "6/18/2019 02:01:02", shift: "A3", acuity: 4 },
  { date: "6/18/2019 02:17:13", shift: "B3", acuity: 4 },
  { date: "6/18/2019 02:22:00", shift: "A3", acuity: 3 },
  { date: "6/18/2019 02:31:40", shift: "B3", acuity: 4 },
  { date: "6/18/2019 02:46:07", shift: "A3", acuity: 2 },
  { date: "6/18/2019 02:52:27", shift: "B3", acuity: 2 },
  { date: "6/18/2019 03:23:42", shift: "A3", acuity: 2 },
  { date: "6/18/2019 03:27:01", shift: "B3", acuity: 4 },
  { date: "6/18/2019 03:48:29", shift: "A3", acuity: 4 },
  { date: "6/18/2019 03:50:53", shift: "B3", acuity: 2 },
  { date: "6/18/2019 04:51:54", shift: "A3", acuity: 3 },
  { date: "6/18/2019 05:02:46", shift: "A1", acuity: 3 },
  { date: "6/18/2019 05:03:12", shift: "B3", acuity: 3 },
  { date: "6/18/2019 05:03:27", shift: "A1", acuity: 4 },
  { date: "6/18/2019 05:10:01", shift: "B3", acuity: 3 },
  { date: "6/18/2019 05:33:47", shift: "A1", acuity: 3 },
  { date: "6/18/2019 05:56:39", shift: "A1", acuity: 4 },
  { date: "6/18/2019 06:28:42", shift: "B1", acuity: 7 },
  { date: "6/18/2019 07:10:58", shift: "B1", acuity: 2 },
  { date: "6/18/2019 07:56:11", shift: "B1", acuity: 7 },
  { date: "6/18/2019 08:10:42", shift: "A1", acuity: 7 },
  { date: "6/18/2019 08:34:15", shift: "C1", acuity: 4 },
  { date: "6/18/2019 08:41:24", shift: "B1", acuity: 2 },
  { date: "6/18/2019 08:41:35", shift: "A1", acuity: 2 },
  { date: "6/18/2019 08:48:06", shift: "C1", acuity: 2 },
  { date: "6/18/2019 09:02:08", shift: "C1", acuity: 7 },
  { date: "6/18/2019 09:25:49", shift: "C1", acuity: 3 },
  { date: "6/18/2019 09:26:05", shift: "B1", acuity: 3 },
  { date: "6/18/2019 09:26:26", shift: "A1", acuity: 5 },
  { date: "6/18/2019 09:41:49", shift: "C1", acuity: 2 },
  { date: "6/18/2019 09:42:11", shift: "B1", acuity: 4 },
  { date: "6/18/2019 10:01:41", shift: "D1", acuity: 3 },
  { date: "6/18/2019 10:01:58", shift: "A1", acuity: 3 },
  { date: "6/18/2019 10:25:52", shift: "C1", acuity: 3 },
  { date: "6/18/2019 10:53:02", shift: "D1", acuity: 3 },
  { date: "6/18/2019 11:06:51", shift: "D1", acuity: 2 },
  { date: "6/18/2019 11:07:15", shift: "B1", acuity: 2 },
  { date: "6/18/2019 11:12:20", shift: "A1", acuity: 2 },
  { date: "6/18/2019 11:31:48", shift: "C1", acuity: 3 },
  { date: "6/18/2019 11:46:20", shift: "D1", acuity: 2 },
  { date: "6/18/2019 11:46:57", shift: "B1", acuity: 3 },
  { date: "6/18/2019 11:48:21", shift: "A1", acuity: 2 },
  { date: "6/18/2019 11:55:46", shift: "C1", acuity: 3 },
  { date: "6/18/2019 12:00:17", shift: "D1", acuity: 2 },
  { date: "6/18/2019 12:29:36", shift: "B1", acuity: 2 },
  { date: "6/18/2019 12:53:19", shift: "A1", acuity: 2 },
  { date: "6/18/2019 13:00:19", shift: "A2", acuity: 3 },
  { date: "6/18/2019 13:00:57", shift: "C1", acuity: 2 },
  { date: "6/18/2019 13:19:11", shift: "D1", acuity: 3 },
  { date: "6/18/2019 13:19:31", shift: "B1", acuity: 2 },
  { date: "6/18/2019 13:19:52", shift: "A2", acuity: 4 },
  { date: "6/18/2019 13:31:16", shift: "A2", acuity: 2 },
  { date: "6/18/2019 13:41:04", shift: "A2", acuity: 2 },
  { date: "6/18/2019 13:44:46", shift: "C1", acuity: 3 },
  { date: "6/18/2019 13:56:56", shift: "D1", acuity: 2 },
  { date: "6/18/2019 14:16:08", shift: "B2", acuity: 2 },
  { date: "6/18/2019 14:37:02", shift: "B2", acuity: 2 },
  { date: "6/18/2019 14:38:38", shift: "B2", acuity: 2 },
  { date: "6/18/2019 14:39:05", shift: "A2", acuity: 3 },
  { date: "6/18/2019 14:44:03", shift: "C1", acuity: 2 },
  { date: "6/18/2019 14:58:02", shift: "D1", acuity: 2 },
  { date: "6/18/2019 15:12:08", shift: "B2", acuity: 3 },
  { date: "6/18/2019 15:12:22", shift: "A2", acuity: 3 },
  { date: "6/18/2019 15:13:06", shift: "C1", acuity: 2 },
  { date: "6/18/2019 15:40:04", shift: "D1", acuity: 2 },
  { date: "6/18/2019 15:40:16", shift: "B2", acuity: 3 },
  { date: "6/18/2019 15:40:31", shift: "A2", acuity: 3 },
  { date: "6/18/2019 15:49:42", shift: "C1", acuity: 3 },
  { date: "6/18/2019 16:05:54", shift: "D1", acuity: 3 },
  { date: "6/18/2019 16:06:29", shift: "B2", acuity: 3 },
  { date: "6/18/2019 16:17:08", shift: "A2", acuity: 3 },
  { date: "6/18/2019 16:17:41", shift: "C1", acuity: 3 },
  { date: "6/18/2019 16:20:39", shift: "D1", acuity: 2 },
  { date: "6/18/2019 16:29:58", shift: "B2", acuity: 3 },
  { date: "6/18/2019 16:39:14", shift: "C2", acuity: 2 },
  { date: "6/18/2019 17:08:58", shift: "C2", acuity: 2 },
  { date: "6/18/2019 17:23:51", shift: "C2", acuity: 2 },
  { date: "6/18/2019 17:31:41", shift: "A2", acuity: 3 },
  { date: "6/18/2019 17:32:17", shift: "D1", acuity: 3 },
  { date: "6/18/2019 17:36:15", shift: "B2", acuity: 2 },
  { date: "6/18/2019 17:51:54", shift: "C2", acuity: 2 },
  { date: "6/18/2019 17:56:49", shift: "A2", acuity: 2 },
  { date: "6/18/2019 18:02:30", shift: "D2", acuity: 3 },
  { date: "6/18/2019 18:10:03", shift: "B2", acuity: 3 },
  { date: "6/18/2019 18:20:02", shift: "C2", acuity: 3 },
  { date: "6/18/2019 18:25:09", shift: "A2", acuity: 2 },
  { date: "6/18/2019 18:25:28", shift: "D2", acuity: 3 },
  { date: "6/18/2019 18:40:21", shift: "D2", acuity: 3 },
  { date: "6/18/2019 18:40:38", shift: "D2", acuity: 3 },
  { date: "6/18/2019 18:47:37", shift: "B2", acuity: 2 },
  { date: "6/18/2019 18:49:27", shift: "C2", acuity: 3 },
  { date: "6/18/2019 19:01:13", shift: "A2", acuity: 3 },
  { date: "6/18/2019 19:03:48", shift: "D2", acuity: 2 },
  { date: "6/18/2019 19:06:26", shift: "B2", acuity: 3 },
  { date: "6/18/2019 19:36:44", shift: "C2", acuity: 2 },
  { date: "6/18/2019 19:37:12", shift: "A2", acuity: 3 },
  { date: "6/18/2019 19:37:28", shift: "D2", acuity: 2 },
  { date: "6/18/2019 19:37:45", shift: "B2", acuity: 2 },
  { date: "6/18/2019 19:47:16", shift: "C2", acuity: 3 },
  { date: "6/18/2019 19:51:29", shift: "A2", acuity: 2 },
  { date: "6/18/2019 19:52:24", shift: "D2", acuity: 2 },
  { date: "6/18/2019 19:56:03", shift: "B2", acuity: 2 },
  { date: "6/18/2019 20:10:47", shift: "C2", acuity: 3 },
  { date: "6/18/2019 20:15:51", shift: "A2", acuity: 4 },
  { date: "6/18/2019 20:20:40", shift: "D2", acuity: 2 },
  { date: "6/18/2019 20:24:29", shift: "B2", acuity: 2 },
  { date: "6/18/2019 20:33:26", shift: "C2", acuity: 3 },
  { date: "6/18/2019 20:33:38", shift: "A2", acuity: 3 },
  { date: "6/18/2019 20:51:54", shift: "D2", acuity: 2 },
  { date: "6/18/2019 20:53:14", shift: "B2", acuity: 3 },
  { date: "6/18/2019 21:03:00", shift: "A3", acuity: 2 },
  { date: "6/18/2019 21:17:49", shift: "C2", acuity: 3 },
  { date: "6/18/2019 21:22:56", shift: "D2", acuity: 3 },
  { date: "6/18/2019 21:37:08", shift: "A3", acuity: 3 },
  { date: "6/18/2019 21:53:45", shift: "A3", acuity: 2 },
  { date: "6/18/2019 22:01:33", shift: "B3", acuity: 3 },
  { date: "6/18/2019 22:01:48", shift: "C2", acuity: 3 },
  { date: "6/18/2019 22:04:05", shift: "D2", acuity: 3 },
  { date: "6/18/2019 22:17:37", shift: "A3", acuity: 2 },
  { date: "6/18/2019 22:31:12", shift: "B3", acuity: 3 },
  { date: "6/18/2019 22:35:52", shift: "B3", acuity: 2 },
  { date: "6/18/2019 22:36:08", shift: "C2", acuity: 2 },
  { date: "6/18/2019 22:56:59", shift: "D2", acuity: 3 },
  { date: "6/18/2019 23:03:24", shift: "A3", acuity: 3 },
  { date: "6/18/2019 23:03:43", shift: "B3", acuity: 3 },
  { date: "6/18/2019 23:04:03", shift: "C2", acuity: 2 },
  { date: "6/18/2019 23:17:16", shift: "D2", acuity: 3 },
  { date: "6/18/2019 23:24:26", shift: "A3", acuity: 4 },
  { date: "6/18/2019 23:27:53", shift: "B3", acuity: 3 },
  { date: "6/18/2019 23:28:15", shift: "C2", acuity: 5 },
  { date: "6/18/2019 23:51:33", shift: "D2", acuity: 3 },
  { date: "6/18/2019 23:57:31", shift: "A3", acuity: 5 },
  { date: "6/19/2019 00:06:24", shift: "B3", acuity: 3 },
  { date: "6/19/2019 00:23:27", shift: "D2", acuity: 3 },
  { date: "6/19/2019 00:29:14", shift: "A3", acuity: 4 },
  { date: "6/19/2019 00:37:20", shift: "B3", acuity: 4 },
  { date: "6/19/2019 00:48:44", shift: "D2", acuity: 2 },
  { date: "6/19/2019 00:50:05", shift: "A3", acuity: 3 },
  { date: "6/19/2019 01:07:06", shift: "B3", acuity: 4 },
  { date: "6/19/2019 01:24:47", shift: "D2", acuity: 3 },
  { date: "6/19/2019 01:32:34", shift: "A3", acuity: 3 },
  { date: "6/19/2019 01:32:51", shift: "B3", acuity: 4 },
  { date: "6/19/2019 01:45:11", shift: "A3", acuity: 4 },
  { date: "6/19/2019 01:52:13", shift: "B3", acuity: 3 },
  { date: "6/19/2019 01:52:28", shift: "A3", acuity: 2 },
  { date: "6/19/2019 02:08:42", shift: "B3", acuity: 2 },
  { date: "6/19/2019 02:18:31", shift: "A3", acuity: 3 },
  { date: "6/19/2019 02:18:43", shift: "B3", acuity: 3 },
  { date: "6/19/2019 02:27:37", shift: "A3", acuity: 3 },
  { date: "6/19/2019 02:35:10", shift: "B3", acuity: 2 },
  { date: "6/19/2019 02:36:22", shift: "A3", acuity: 3 },
  { date: "6/19/2019 02:47:57", shift: "B3", acuity: 3 },
  { date: "6/19/2019 03:09:19", shift: "A3", acuity: 4 },
  { date: "6/19/2019 03:09:34", shift: "B3", acuity: 3 },
  { date: "6/19/2019 03:16:19", shift: "A3", acuity: 3 },
  { date: "6/19/2019 03:24:39", shift: "B3", acuity: 2 },
  { date: "6/19/2019 03:51:49", shift: "A3", acuity: 3 },
  { date: "6/19/2019 04:19:37", shift: "B3", acuity: 3 },
  { date: "6/19/2019 04:21:50", shift: "A3", acuity: 3 },
  { date: "6/19/2019 05:01:21", shift: "A1", acuity: 2 },
  { date: "6/19/2019 05:07:13", shift: "B3", acuity: 2 },
  { date: "6/19/2019 05:38:51", shift: "A1", acuity: 2 },
  { date: "6/19/2019 05:39:06", shift: "A1", acuity: 2 },
  { date: "6/19/2019 06:12:13", shift: "B1", acuity: 2 },
  { date: "6/19/2019 06:17:02", shift: "A1", acuity: 3 },
  { date: "6/19/2019 06:36:54", shift: "B1", acuity: 4 },
  { date: "6/19/2019 07:21:13", shift: "B1", acuity: 3 },
  { date: "6/19/2019 07:37:06", shift: "A1", acuity: 2 },
  { date: "6/19/2019 07:55:44", shift: "B1", acuity: 4 },
  { date: "6/19/2019 07:56:26", shift: "A1", acuity: 3 },
  { date: "6/19/2019 07:58:49", shift: "B1", acuity: 3 },
  { date: "6/19/2019 08:14:04", shift: "A1", acuity: 3 },
  { date: "6/19/2019 08:34:34", shift: "C1", acuity: 2 },
  { date: "6/19/2019 08:46:36", shift: "B1", acuity: 3 },
  { date: "6/19/2019 08:49:11", shift: "A1", acuity: 4 },
  { date: "6/19/2019 09:00:57", shift: "C1", acuity: 3 },
  { date: "6/19/2019 09:01:18", shift: "C1", acuity: 4 },
  { date: "6/19/2019 09:17:35", shift: "B1", acuity: 2 },
  { date: "6/19/2019 09:17:51", shift: "A1", acuity: 3 },
  { date: "6/19/2019 09:27:07", shift: "C1", acuity: 3 },
  { date: "6/19/2019 09:37:20", shift: "B1", acuity: 3 },
  { date: "6/19/2019 09:38:28", shift: "A1", acuity: 3 },
  { date: "6/19/2019 09:54:32", shift: "C1", acuity: 4 },
  { date: "6/19/2019 09:54:52", shift: "B1", acuity: 3 },
  { date: "6/19/2019 09:55:09", shift: "A1", acuity: 2 },
  { date: "6/19/2019 09:59:56", shift: "C1", acuity: 2 },
  { date: "6/19/2019 10:07:13", shift: "D1", acuity: 3 },
  { date: "6/19/2019 10:29:21", shift: "B1", acuity: 3 },
  { date: "6/19/2019 10:32:23", shift: "D1", acuity: 2 },
  { date: "6/19/2019 10:47:27", shift: "D1", acuity: 3 },
  { date: "6/19/2019 10:51:28", shift: "A1", acuity: 3 },
  { date: "6/19/2019 10:51:38", shift: "C1", acuity: 2 },
  { date: "6/19/2019 10:55:07", shift: "B1", acuity: 2 },
  { date: "6/19/2019 11:03:24", shift: "D1", acuity: 3 },
  { date: "6/19/2019 11:36:52", shift: "A1", acuity: 2 },
  { date: "6/19/2019 11:39:42", shift: "C1", acuity: 3 },
  { date: "6/19/2019 11:41:40", shift: "B1", acuity: 3 },
  { date: "6/19/2019 11:47:00", shift: "D1", acuity: 2 },
  { date: "6/19/2019 11:47:10", shift: "A1", acuity: 3 },
  { date: "6/19/2019 12:02:26", shift: "C1", acuity: 2 },
  { date: "6/19/2019 12:10:13", shift: "B1", acuity: 2 },
  { date: "6/19/2019 12:16:13", shift: "D1", acuity: 2 },
  { date: "6/19/2019 12:34:26", shift: "A1", acuity: 3 },
  { date: "6/19/2019 12:48:34", shift: "C1", acuity: 3 },
  { date: "6/19/2019 12:59:34", shift: "B1", acuity: 2 },
  { date: "6/19/2019 13:10:22", shift: "A2", acuity: 3 },
  { date: "6/19/2019 13:20:40", shift: "D1", acuity: 2 },
  { date: "6/19/2019 13:29:00", shift: "C1", acuity: 2 },
  { date: "6/19/2019 13:31:04", shift: "A2", acuity: 3 },
  { date: "6/19/2019 13:36:17", shift: "A2", acuity: 2 },
  { date: "6/19/2019 13:37:33", shift: "B1", acuity: 2 },
  { date: "6/19/2019 14:01:01", shift: "B2", acuity: 3 },
  { date: "6/19/2019 14:11:48", shift: "D1", acuity: 3 },
  { date: "6/19/2019 14:12:08", shift: "C1", acuity: 3 },
  { date: "6/19/2019 14:12:23", shift: "A2", acuity: 2 },
  { date: "6/19/2019 14:33:07", shift: "B2", acuity: 2 },
  { date: "6/19/2019 14:56:00", shift: "B2", acuity: 2 },
  { date: "6/19/2019 14:56:25", shift: "D1", acuity: 2 },
  { date: "6/19/2019 14:59:43", shift: "C1", acuity: 3 },
  { date: "6/19/2019 15:10:34", shift: "A2", acuity: 2 },
  { date: "6/19/2019 15:20:29", shift: "B2", acuity: 3 },
  { date: "6/19/2019 15:20:59", shift: "D1", acuity: 2 },
  { date: "6/19/2019 15:22:32", shift: "C1", acuity: 2 },
  { date: "6/19/2019 15:34:43", shift: "A2", acuity: 3 },
  { date: "6/19/2019 15:43:45", shift: "B2", acuity: 3 },
  { date: "6/19/2019 15:52:46", shift: "D1", acuity: 3 },
  { date: "6/19/2019 15:56:26", shift: "C1", acuity: 3 },
  { date: "6/19/2019 16:09:01", shift: "A2", acuity: 3 },
  { date: "6/19/2019 16:09:18", shift: "B2", acuity: 3 },
  { date: "6/19/2019 16:17:06", shift: "D1", acuity: 4 },
  { date: "6/19/2019 16:23:42", shift: "C1", acuity: 3 },
  { date: "6/19/2019 16:46:13", shift: "C2", acuity: 2 },
  { date: "6/19/2019 16:48:32", shift: "A2", acuity: 3 },
  { date: "6/19/2019 16:54:51", shift: "B2", acuity: 2 },
  { date: "6/19/2019 17:00:59", shift: "C2", acuity: 2 },
  { date: "6/19/2019 17:06:35", shift: "C2", acuity: 3 },
  { date: "6/19/2019 17:08:41", shift: "D1", acuity: 3 },
  { date: "6/19/2019 17:17:51", shift: "A2", acuity: 3 },
  { date: "6/19/2019 17:20:54", shift: "B2", acuity: 3 },
  { date: "6/19/2019 17:40:23", shift: "C2", acuity: 3 },
  { date: "6/19/2019 17:45:07", shift: "D1", acuity: 3 },
  { date: "6/19/2019 18:14:46", shift: "D2", acuity: 2 },
  { date: "6/19/2019 18:18:41", shift: "A2", acuity: 3 },
  { date: "6/19/2019 18:18:59", shift: "B2", acuity: 3 },
  { date: "6/19/2019 18:24:25", shift: "C2", acuity: 2 },
  { date: "6/19/2019 18:35:05", shift: "D2", acuity: 2 },
  { date: "6/19/2019 18:39:49", shift: "D2", acuity: 3 },
  { date: "6/19/2019 18:57:50", shift: "A2", acuity: 2 },
  { date: "6/19/2019 19:24:29", shift: "B2", acuity: 3 },
  { date: "6/19/2019 19:24:45", shift: "C2", acuity: 3 },
  { date: "6/19/2019 19:34:54", shift: "D2", acuity: 3 },
  { date: "6/19/2019 19:35:15", shift: "A2", acuity: 3 },
  { date: "6/19/2019 19:59:52", shift: "B2", acuity: 2 },
  { date: "6/19/2019 20:00:10", shift: "C2", acuity: 2 },
  { date: "6/19/2019 20:12:13", shift: "D2", acuity: 2 },
  { date: "6/19/2019 20:21:32", shift: "A2", acuity: 2 },
  { date: "6/19/2019 20:29:19", shift: "B2", acuity: 2 },
  { date: "6/19/2019 20:37:07", shift: "C2", acuity: 3 },
  { date: "6/19/2019 20:37:23", shift: "D2", acuity: 3 },
  { date: "6/19/2019 20:41:18", shift: "A2", acuity: 3 },
  { date: "6/19/2019 20:53:54", shift: "B2", acuity: 3 },
  { date: "6/19/2019 20:57:52", shift: "C2", acuity: 3 },
  { date: "6/19/2019 21:16:51", shift: "A3", acuity: 2 },
  { date: "6/19/2019 21:27:08", shift: "D2", acuity: 4 },
  { date: "6/19/2019 21:38:22", shift: "A3", acuity: 3 },
  { date: "6/19/2019 21:44:23", shift: "A3", acuity: 4 },
  { date: "6/19/2019 21:44:36", shift: "B2", acuity: 4 },
  { date: "6/19/2019 21:50:49", shift: "C2", acuity: 3 },
  { date: "6/19/2019 21:51:12", shift: "D2", acuity: 3 },
  { date: "6/19/2019 21:59:24", shift: "A3", acuity: 3 },
  { date: "6/19/2019 22:12:38", shift: "B3", acuity: 3 },
  { date: "6/19/2019 22:18:37", shift: "C2", acuity: 2 },
  { date: "6/19/2019 22:18:49", shift: "D2", acuity: 3 },
  { date: "6/19/2019 22:59:04", shift: "B3", acuity: 2 },
  { date: "6/19/2019 23:06:35", shift: "B3", acuity: 4 },
  { date: "6/19/2019 23:06:45", shift: "A3", acuity: 3 },
  { date: "6/19/2019 23:11:05", shift: "C2", acuity: 3 },
  { date: "6/19/2019 23:11:33", shift: "D2", acuity: 3 },
  { date: "6/19/2019 23:22:31", shift: "B3", acuity: 3 },
  { date: "6/19/2019 23:41:08", shift: "A3", acuity: 3 },
  { date: "6/19/2019 23:44:30", shift: "C2", acuity: 4 },
  { date: "6/19/2019 23:57:41", shift: "D2", acuity: 4 },
  { date: "6/20/2019 00:22:59", shift: "B3", acuity: 2 },
  { date: "6/20/2019 00:34:25", shift: "A3", acuity: 3 },
  { date: "6/20/2019 00:57:46", shift: "D2", acuity: 3 },
  { date: "6/20/2019 00:57:58", shift: "B3", acuity: 5 },
  { date: "6/20/2019 01:02:22", shift: "A3", acuity: 3 },
  { date: "6/20/2019 01:10:11", shift: "D2", acuity: 4 },
  { date: "6/20/2019 01:25:44", shift: "B3", acuity: 4 },
  { date: "6/20/2019 01:26:06", shift: "A3", acuity: 4 },
  { date: "6/20/2019 01:35:42", shift: "B3", acuity: 4 },
  { date: "6/20/2019 01:39:37", shift: "A3", acuity: 2 },
  { date: "6/20/2019 01:51:41", shift: "B3", acuity: 2 },
  { date: "6/20/2019 01:51:55", shift: "A3", acuity: 3 },
  { date: "6/20/2019 02:10:15", shift: "B3", acuity: 3 },
  { date: "6/20/2019 02:37:29", shift: "A3", acuity: 3 },
  { date: "6/20/2019 02:37:43", shift: "B3", acuity: 4 },
  { date: "6/20/2019 03:04:02", shift: "A3", acuity: 3 },
  { date: "6/20/2019 03:15:27", shift: "B3", acuity: 3 },
  { date: "6/20/2019 03:37:51", shift: "A3", acuity: 3 },
  { date: "6/20/2019 04:03:19", shift: "B3", acuity: 3 },
  { date: "6/20/2019 04:06:02", shift: "A3", acuity: 3 },
  { date: "6/20/2019 04:26:58", shift: "B3", acuity: 2 },
  { date: "6/20/2019 05:22:33", shift: "A1", acuity: 4 },
  { date: "6/20/2019 05:36:50", shift: "A1", acuity: 3 },
  { date: "6/20/2019 05:40:17", shift: "A1", acuity: 3 },
  { date: "6/20/2019 06:47:22", shift: "B1", acuity: 4 },
  { date: "6/20/2019 07:10:15", shift: "B1", acuity: 3 },
  { date: "6/20/2019 07:15:31", shift: "B1", acuity: 3 },
  { date: "6/20/2019 07:39:31", shift: "A1", acuity: 3 },
  { date: "6/20/2019 07:57:38", shift: "B1", acuity: 4 },
  { date: "6/20/2019 07:58:44", shift: "A1", acuity: 4 },
  { date: "6/20/2019 08:06:35", shift: "B1", acuity: 3 },
  { date: "6/20/2019 08:20:15", shift: "A1", acuity: 3 },
  { date: "6/20/2019 08:35:25", shift: "C1", acuity: 2 },
  { date: "6/20/2019 08:37:05", shift: "B1", acuity: 4 },
  { date: "6/20/2019 09:24:28", shift: "C1", acuity: 2 },
  { date: "6/20/2019 09:25:49", shift: "C1", acuity: 3 },
  { date: "6/20/2019 09:36:18", shift: "A1", acuity: 3 },
  { date: "6/20/2019 09:42:29", shift: "B1", acuity: 4 },
  { date: "6/20/2019 09:57:57", shift: "C1", acuity: 4 },
  { date: "6/20/2019 09:58:03", shift: "A1", acuity: 3 },
  { date: "6/20/2019 09:58:09", shift: "B1", acuity: 3 },
  { date: "6/20/2019 09:59:14", shift: "C1", acuity: 3 },
  { date: "6/20/2019 10:15:14", shift: "D1", acuity: 4 },
  { date: "6/20/2019 10:15:44", shift: "A1", acuity: 2 },
  { date: "6/20/2019 10:20:22", shift: "B1", acuity: 4 },
  { date: "6/20/2019 10:54:42", shift: "D1", acuity: 2 },
  { date: "6/20/2019 10:55:02", shift: "D1", acuity: 2 },
  { date: "6/20/2019 11:15:13", shift: "C1", acuity: 2 },
  { date: "6/20/2019 11:15:32", shift: "A1", acuity: 3 },
  { date: "6/20/2019 11:22:27", shift: "B1", acuity: 3 },
  { date: "6/20/2019 11:28:43", shift: "D1", acuity: 3 },
  { date: "6/20/2019 11:38:17", shift: "C1", acuity: 3 },
  { date: "6/20/2019 11:38:36", shift: "A1", acuity: 2 },
  { date: "6/20/2019 11:57:40", shift: "B1", acuity: 3 },
  { date: "6/20/2019 12:00:27", shift: "D1", acuity: 3 },
  { date: "6/20/2019 12:01:02", shift: "C1", acuity: 2 },
  { date: "6/20/2019 12:01:59", shift: "A1", acuity: 3 },
  { date: "6/20/2019 12:11:54", shift: "B1", acuity: 3 },
  { date: "6/20/2019 12:14:32", shift: "D1", acuity: 2 },
  { date: "6/20/2019 12:17:34", shift: "C1", acuity: 3 },
  { date: "6/20/2019 12:43:28", shift: "A1", acuity: 2 },
  { date: "6/20/2019 12:55:41", shift: "B1", acuity: 3 },
  { date: "6/20/2019 12:55:58", shift: "D1", acuity: 3 },
  { date: "6/20/2019 13:02:20", shift: "A2", acuity: 3 },
  { date: "6/20/2019 13:09:11", shift: "C1", acuity: 3 },
  { date: "6/20/2019 13:57:00", shift: "A2", acuity: 2 },
  { date: "6/20/2019 14:06:56", shift: "A2", acuity: 3 },
  { date: "6/20/2019 14:13:38", shift: "B2", acuity: 2 },
  { date: "6/20/2019 14:20:37", shift: "D1", acuity: 3 },
  { date: "6/20/2019 14:31:53", shift: "B2", acuity: 3 },
  { date: "6/20/2019 14:38:52", shift: "B2", acuity: 2 },
  { date: "6/20/2019 14:55:24", shift: "C1", acuity: 3 },
  { date: "6/20/2019 15:00:04", shift: "A2", acuity: 3 },
  { date: "6/20/2019 15:16:54", shift: "D1", acuity: 3 },
  { date: "6/20/2019 15:18:00", shift: "B2", acuity: 2 },
  { date: "6/20/2019 15:20:21", shift: "C1", acuity: 3 },
  { date: "6/20/2019 15:35:30", shift: "A2", acuity: 2 },
  { date: "6/20/2019 15:38:16", shift: "D1", acuity: 3 },
  { date: "6/20/2019 15:48:52", shift: "B2", acuity: 3 },
  { date: "6/20/2019 15:49:01", shift: "C1", acuity: 2 },
  { date: "6/20/2019 15:55:43", shift: "A2", acuity: 3 },
  { date: "6/20/2019 16:06:03", shift: "D1", acuity: 3 },
  { date: "6/20/2019 16:17:06", shift: "B2", acuity: 3 },
  { date: "6/20/2019 16:17:36", shift: "C1", acuity: 2 },
  { date: "6/20/2019 16:33:11", shift: "C2", acuity: 3 },
  { date: "6/20/2019 16:41:56", shift: "A2", acuity: 2 },
  { date: "6/20/2019 16:42:32", shift: "D1", acuity: 3 },
  { date: "6/20/2019 16:57:02", shift: "B2", acuity: 2 },
  { date: "6/20/2019 17:46:24", shift: "C2", acuity: 3 },
  { date: "6/20/2019 17:46:36", shift: "C2", acuity: 3 },
  { date: "6/20/2019 17:47:23", shift: "A2", acuity: 3 },
  { date: "6/20/2019 18:00:38", shift: "D2", acuity: 3 },
  { date: "6/20/2019 18:02:11", shift: "B2", acuity: 2 },
  { date: "6/20/2019 18:15:55", shift: "C2", acuity: 2 },
  { date: "6/20/2019 18:42:45", shift: "D2", acuity: 2 },
  { date: "6/20/2019 18:43:23", shift: "D2", acuity: 2 },
  { date: "6/20/2019 19:00:21", shift: "A2", acuity: 3 },
  { date: "6/20/2019 19:16:16", shift: "B2", acuity: 3 },
  { date: "6/20/2019 19:21:59", shift: "C2", acuity: 3 },
  { date: "6/20/2019 19:24:20", shift: "D2", acuity: 3 },
  { date: "6/20/2019 19:44:17", shift: "A2", acuity: 3 },
  { date: "6/20/2019 19:48:27", shift: "B2", acuity: 3 },
  { date: "6/20/2019 20:09:05", shift: "C2", acuity: 2 },
  { date: "6/20/2019 20:09:53", shift: "D2", acuity: 2 },
  { date: "6/20/2019 20:20:13", shift: "A2", acuity: 2 },
  { date: "6/20/2019 20:22:48", shift: "B2", acuity: 3 },
  { date: "6/20/2019 20:33:29", shift: "C2", acuity: 2 },
  { date: "6/20/2019 20:38:40", shift: "D2", acuity: 2 },
  { date: "6/20/2019 20:46:20", shift: "A2", acuity: 3 },
  { date: "6/20/2019 20:46:33", shift: "B2", acuity: 3 },
  { date: "6/20/2019 20:54:21", shift: "C2", acuity: 2 },
  { date: "6/20/2019 21:06:05", shift: "A3", acuity: 3 },
  { date: "6/20/2019 21:20:53", shift: "D2", acuity: 2 },
  { date: "6/20/2019 21:32:42", shift: "A3", acuity: 2 },
  { date: "6/20/2019 21:48:17", shift: "A3", acuity: 3 },
  { date: "6/20/2019 21:48:36", shift: "B2", acuity: 3 },
  { date: "6/20/2019 21:52:17", shift: "C2", acuity: 3 },
  { date: "6/20/2019 21:53:43", shift: "D2", acuity: 2 },
  { date: "6/20/2019 22:03:51", shift: "B3", acuity: 3 },
  { date: "6/20/2019 22:04:31", shift: "A3", acuity: 2 },
  { date: "6/20/2019 22:12:18", shift: "C2", acuity: 3 },
  { date: "6/20/2019 22:13:44", shift: "D2", acuity: 3 },
  { date: "6/20/2019 22:21:32", shift: "B3", acuity: 3 },
  { date: "6/20/2019 22:30:26", shift: "B3", acuity: 3 },
  { date: "6/20/2019 22:30:37", shift: "B3", acuity: 3 },
  { date: "6/20/2019 22:30:49", shift: "A3", acuity: 4 },
  { date: "6/20/2019 22:35:28", shift: "C2", acuity: 4 },
  { date: "6/20/2019 22:48:17", shift: "D2", acuity: 4 },
  { date: "6/20/2019 22:48:33", shift: "B3", acuity: 2 },
  { date: "6/20/2019 23:15:38", shift: "A3", acuity: 2 },
  { date: "6/20/2019 23:16:04", shift: "C2", acuity: 2 },
  { date: "6/20/2019 23:36:12", shift: "D2", acuity: 3 },
  { date: "6/20/2019 23:46:22", shift: "B3", acuity: 3 },
  { date: "6/20/2019 23:47:01", shift: "A3", acuity: 3 },
  { date: "6/21/2019 00:09:05", shift: "D2", acuity: 4 },
  { date: "6/21/2019 00:10:56", shift: "B3", acuity: 3 },
  { date: "6/21/2019 00:16:53", shift: "A3", acuity: 2 },
  { date: "6/21/2019 00:17:08", shift: "D2", acuity: 2 },
  { date: "6/21/2019 00:49:32", shift: "B3", acuity: 2 },
  { date: "6/21/2019 00:49:43", shift: "A3", acuity: 3 },
  { date: "6/21/2019 01:00:45", shift: "D2", acuity: 3 },
  { date: "6/21/2019 01:22:23", shift: "B3", acuity: 4 },
  { date: "6/21/2019 01:31:45", shift: "A3", acuity: 2 },
  { date: "6/21/2019 01:31:57", shift: "B3", acuity: 2 },
  { date: "6/21/2019 01:37:14", shift: "A3", acuity: 3 },
  { date: "6/21/2019 01:48:56", shift: "B3", acuity: 3 },
  { date: "6/21/2019 02:06:48", shift: "A3", acuity: 4 },
  { date: "6/21/2019 02:07:06", shift: "B3", acuity: 4 },
  { date: "6/21/2019 02:29:55", shift: "A3", acuity: 3 },
  { date: "6/21/2019 02:35:52", shift: "B3", acuity: 3 },
  { date: "6/21/2019 02:47:55", shift: "A3", acuity: 3 },
  { date: "6/21/2019 03:24:59", shift: "B3", acuity: 2 },
  { date: "6/21/2019 03:34:25", shift: "A3", acuity: 3 },
  { date: "6/21/2019 03:48:46", shift: "B3", acuity: 4 },
  { date: "6/21/2019 03:55:15", shift: "A3", acuity: 4 },
  { date: "6/21/2019 03:58:21", shift: "B3", acuity: 3 },
  { date: "6/21/2019 04:14:50", shift: "A3", acuity: 4 },
  { date: "6/21/2019 04:37:08", shift: "B3", acuity: 3 },
  { date: "6/21/2019 04:37:22", shift: "A3", acuity: 4 },
  { date: "6/21/2019 04:53:42", shift: "B3", acuity: 4 },
  { date: "6/21/2019 05:09:52", shift: "A1", acuity: 4 },
  { date: "6/21/2019 05:49:51", shift: "A1", acuity: 4 },
  { date: "6/21/2019 06:04:19", shift: "A1", acuity: 3 },
  { date: "6/21/2019 06:16:04", shift: "B1", acuity: 3 },
  { date: "6/21/2019 06:24:48", shift: "A1", acuity: 4 },
  { date: "6/21/2019 06:32:05", shift: "B1", acuity: 3 },
  { date: "6/21/2019 06:44:57", shift: "B1", acuity: 3 },
  { date: "6/21/2019 06:54:19", shift: "A1", acuity: 4 },
  { date: "6/21/2019 06:58:35", shift: "B1", acuity: 5 },
  { date: "6/21/2019 07:27:55", shift: "A1", acuity: 3 },
  { date: "6/21/2019 07:43:51", shift: "B1", acuity: 2 },
  { date: "6/21/2019 08:19:03", shift: "A1", acuity: 2 },
  { date: "6/21/2019 08:32:00", shift: "C1", acuity: 2 },
  { date: "6/21/2019 08:40:16", shift: "B1", acuity: 5 },
  { date: "6/21/2019 09:00:13", shift: "C1", acuity: 4 },
  { date: "6/21/2019 09:01:58", shift: "C1", acuity: 2 },
  { date: "6/21/2019 09:02:08", shift: "A1", acuity: 2 },
  { date: "6/21/2019 09:11:04", shift: "B1", acuity: 2 },
  { date: "6/21/2019 09:20:39", shift: "C1", acuity: 3 },
  { date: "6/21/2019 09:42:18", shift: "A1", acuity: 2 },
  { date: "6/21/2019 09:45:07", shift: "B1", acuity: 3 },
  { date: "6/21/2019 09:45:51", shift: "C1", acuity: 2 },
  { date: "6/21/2019 09:53:26", shift: "A1", acuity: 3 },
  { date: "6/21/2019 10:01:24", shift: "D1", acuity: 3 },
  { date: "6/21/2019 10:01:38", shift: "B1", acuity: 5 },
  { date: "6/21/2019 10:07:45", shift: "C1", acuity: 3 },
  { date: "6/21/2019 10:40:56", shift: "D1", acuity: 2 },
  { date: "6/21/2019 11:13:28", shift: "D1", acuity: 3 },
  { date: "6/21/2019 11:26:07", shift: "A1", acuity: 2 },
  { date: "6/21/2019 11:31:19", shift: "B1", acuity: 2 },
  { date: "6/21/2019 11:38:25", shift: "C1", acuity: 3 },
  { date: "6/21/2019 12:05:19", shift: "D1", acuity: 2 },
  { date: "6/21/2019 12:23:44", shift: "A1", acuity: 3 },
  { date: "6/21/2019 12:24:16", shift: "B1", acuity: 3 },
  { date: "6/21/2019 12:30:08", shift: "C1", acuity: 3 },
  { date: "6/21/2019 12:56:07", shift: "D1", acuity: 2 },
  { date: "6/21/2019 13:02:23", shift: "A2", acuity: 3 },
  { date: "6/21/2019 13:31:41", shift: "A2", acuity: 2 },
  { date: "6/21/2019 13:32:09", shift: "A2", acuity: 3 },
  { date: "6/21/2019 13:40:34", shift: "B1", acuity: 2 },
  { date: "6/21/2019 13:47:07", shift: "C1", acuity: 3 },
  { date: "6/21/2019 13:49:01", shift: "D1", acuity: 2 },
  { date: "6/21/2019 14:04:52", shift: "B2", acuity: 2 },
  { date: "6/21/2019 14:19:31", shift: "A2", acuity: 3 },
  { date: "6/21/2019 14:21:54", shift: "C1", acuity: 2 },
  { date: "6/21/2019 14:22:47", shift: "D1", acuity: 3 },
  { date: "6/21/2019 14:41:43", shift: "B2", acuity: 4 },
  { date: "6/21/2019 14:46:18", shift: "B2", acuity: 2 },
  { date: "6/21/2019 14:47:11", shift: "A2", acuity: 2 },
  { date: "6/21/2019 14:51:55", shift: "C1", acuity: 3 },
  { date: "6/21/2019 15:18:12", shift: "D1", acuity: 3 },
  { date: "6/21/2019 15:41:32", shift: "B2", acuity: 2 },
  { date: "6/21/2019 15:57:05", shift: "A2", acuity: 3 },
  { date: "6/21/2019 15:57:13", shift: "C1", acuity: 3 },
  { date: "6/21/2019 16:00:18", shift: "D1", acuity: 3 },
  { date: "6/21/2019 16:13:40", shift: "B2", acuity: 2 },
  { date: "6/21/2019 16:27:13", shift: "A2", acuity: 2 },
  { date: "6/21/2019 16:38:23", shift: "C2", acuity: 3 },
  { date: "6/21/2019 16:40:03", shift: "D1", acuity: 3 },
  { date: "6/21/2019 16:59:46", shift: "B2", acuity: 2 },
  { date: "6/21/2019 17:08:07", shift: "C2", acuity: 3 },
  { date: "6/21/2019 17:18:16", shift: "C2", acuity: 3 },
  { date: "6/21/2019 17:23:15", shift: "A2", acuity: 3 },
  { date: "6/21/2019 17:24:43", shift: "D1", acuity: 3 },
  { date: "6/21/2019 17:33:25", shift: "B2", acuity: 3 },
  { date: "6/21/2019 17:42:05", shift: "C2", acuity: 3 },
  { date: "6/21/2019 17:51:24", shift: "A2", acuity: 2 },
  { date: "6/21/2019 17:51:36", shift: "D1", acuity: 3 },
  { date: "6/21/2019 17:53:53", shift: "B2", acuity: 2 },
  { date: "6/21/2019 17:59:48", shift: "C2", acuity: 3 },
  { date: "6/21/2019 18:09:44", shift: "D2", acuity: 2 },
  { date: "6/21/2019 18:10:22", shift: "A2", acuity: 3 },
  { date: "6/21/2019 18:31:20", shift: "D2", acuity: 2 },
  { date: "6/21/2019 18:43:00", shift: "D2", acuity: 3 },
  { date: "6/21/2019 18:52:33", shift: "B2", acuity: 3 },
  { date: "6/21/2019 19:00:50", shift: "C2", acuity: 2 },
  { date: "6/21/2019 19:09:27", shift: "A2", acuity: 3 },
  { date: "6/21/2019 19:14:19", shift: "D2", acuity: 3 },
  { date: "6/21/2019 19:16:03", shift: "B2", acuity: 3 },
  { date: "6/21/2019 19:38:24", shift: "C2", acuity: 2 },
  { date: "6/21/2019 19:38:38", shift: "A2", acuity: 2 },
  { date: "6/21/2019 19:39:27", shift: "D2", acuity: 3 },
  { date: "6/21/2019 19:43:15", shift: "B2", acuity: 3 },
  { date: "6/21/2019 19:45:57", shift: "C2", acuity: 4 },
  { date: "6/21/2019 20:10:49", shift: "A2", acuity: 3 },
  { date: "6/21/2019 20:16:40", shift: "D2", acuity: 3 },
  { date: "6/21/2019 20:31:41", shift: "B2", acuity: 3 },
  { date: "6/21/2019 20:43:55", shift: "C2", acuity: 3 },
  { date: "6/21/2019 20:50:31", shift: "A2", acuity: 3 },
  { date: "6/21/2019 20:53:59", shift: "D2", acuity: 3 },
  { date: "6/21/2019 20:57:58", shift: "B2", acuity: 3 },
  { date: "6/21/2019 21:02:18", shift: "A3", acuity: 3 },
  { date: "6/21/2019 21:16:57", shift: "C2", acuity: 2 },
  { date: "6/21/2019 21:22:40", shift: "D2", acuity: 3 },
  { date: "6/21/2019 21:27:12", shift: "B2", acuity: 4 },
  { date: "6/21/2019 21:35:27", shift: "A3", acuity: 2 },
  { date: "6/21/2019 21:38:57", shift: "A3", acuity: 3 },
  { date: "6/21/2019 21:49:14", shift: "C2", acuity: 2 },
  { date: "6/21/2019 21:51:14", shift: "D2", acuity: 4 },
  { date: "6/21/2019 21:57:45", shift: "B2", acuity: 3 },
  { date: "6/21/2019 22:04:56", shift: "B3", acuity: 3 },
  { date: "6/21/2019 22:05:10", shift: "A3", acuity: 4 },
  { date: "6/21/2019 22:20:16", shift: "C2", acuity: 4 },
  { date: "6/21/2019 22:21:54", shift: "D2", acuity: 2 },
  { date: "6/21/2019 22:31:04", shift: "B3", acuity: 3 },
  { date: "6/21/2019 22:34:37", shift: "B3", acuity: 2 },
  { date: "6/21/2019 22:43:05", shift: "A3", acuity: 4 },
  { date: "6/21/2019 22:46:41", shift: "C2", acuity: 3 },
  { date: "6/21/2019 22:49:34", shift: "D2", acuity: 2 },
  { date: "6/21/2019 22:57:56", shift: "B3", acuity: 4 },
  { date: "6/21/2019 23:18:20", shift: "A3", acuity: 2 },
  { date: "6/21/2019 23:22:55", shift: "C2", acuity: 3 },
  { date: "6/21/2019 23:23:19", shift: "D2", acuity: 4 },
  { date: "6/21/2019 23:30:14", shift: "B3", acuity: 3 },
  { date: "6/21/2019 23:30:41", shift: "A3", acuity: 4 },
  { date: "6/21/2019 23:34:34", shift: "C2", acuity: 4 },
  { date: "6/21/2019 23:34:50", shift: "D2", acuity: 3 },
  { date: "6/21/2019 23:48:24", shift: "B3", acuity: 5 },
  { date: "6/22/2019 00:07:26", shift: "A3", acuity: 3 },
  { date: "6/22/2019 00:10:32", shift: "D2", acuity: 3 },
  { date: "6/22/2019 00:10:49", shift: "B3", acuity: 3 },
  { date: "6/22/2019 00:18:15", shift: "A3", acuity: 2 },
  { date: "6/22/2019 00:22:09", shift: "D2", acuity: 2 },
  { date: "6/22/2019 00:42:43", shift: "B3", acuity: 4 },
  { date: "6/22/2019 00:42:55", shift: "A3", acuity: 3 },
  { date: "6/22/2019 00:57:31", shift: "D2", acuity: 5 },
  { date: "6/22/2019 01:25:03", shift: "B3", acuity: 4 },
  { date: "6/22/2019 01:30:10", shift: "A3", acuity: 3 },
  { date: "6/22/2019 01:30:22", shift: "B3", acuity: 4 },
  { date: "6/22/2019 01:32:29", shift: "A3", acuity: 4 },
  { date: "6/22/2019 01:52:36", shift: "B3", acuity: 3 },
  { date: "6/22/2019 01:59:18", shift: "A3", acuity: 4 },
  { date: "6/22/2019 02:14:12", shift: "B3", acuity: 4 },
  { date: "6/22/2019 02:30:21", shift: "A3", acuity: 4 },
  { date: "6/22/2019 02:42:02", shift: "B3", acuity: 3 },
  { date: "6/22/2019 02:54:18", shift: "A3", acuity: 2 },
  { date: "6/22/2019 02:59:38", shift: "B3", acuity: 2 },
  { date: "6/22/2019 03:10:47", shift: "A3", acuity: 4 },
  { date: "6/22/2019 03:30:36", shift: "B3", acuity: 4 },
  { date: "6/22/2019 03:30:56", shift: "A3", acuity: 2 },
  { date: "6/22/2019 04:47:32", shift: "B3", acuity: 3 },
  { date: "6/22/2019 05:25:43", shift: "A1", acuity: 4 },
  { date: "6/22/2019 05:40:48", shift: "A1", acuity: 2 },
  { date: "6/22/2019 06:41:18", shift: "A1", acuity: 4 },
  { date: "6/22/2019 06:41:31", shift: "A1", acuity: 5 },
  { date: "6/22/2019 06:46:52", shift: "B1", acuity: 3 },
  { date: "6/22/2019 06:50:11", shift: "B1", acuity: 2 },
  { date: "6/22/2019 07:20:03", shift: "B1", acuity: 3 },
  { date: "6/22/2019 07:20:25", shift: "A1", acuity: 4 },
  { date: "6/22/2019 07:40:06", shift: "B1", acuity: 2 },
  { date: "6/22/2019 08:02:03", shift: "A1", acuity: 4 },
  { date: "6/22/2019 08:03:02", shift: "B1", acuity: 3 },
  { date: "6/22/2019 08:10:59", shift: "A1", acuity: 3 },
  { date: "6/22/2019 08:16:48", shift: "B1", acuity: 2 },
  { date: "6/22/2019 08:57:12", shift: "C1", acuity: 3 },
  { date: "6/22/2019 09:04:01", shift: "C1", acuity: 3 },
  { date: "6/22/2019 09:08:31", shift: "C1", acuity: 7 },
  { date: "6/22/2019 09:13:32", shift: "A1", acuity: 3 },
  { date: "6/22/2019 09:16:12", shift: "B1", acuity: 3 },
  { date: "6/22/2019 09:16:22", shift: "C1", acuity: 3 },
  { date: "6/22/2019 09:23:19", shift: "A1", acuity: 3 },
  { date: "6/22/2019 09:37:45", shift: "B1", acuity: 3 },
  { date: "6/22/2019 09:37:59", shift: "C1", acuity: 3 },
  { date: "6/22/2019 09:45:26", shift: "A1", acuity: 7 },
  { date: "6/22/2019 09:52:26", shift: "B1", acuity: 2 },
  { date: "6/22/2019 09:53:03", shift: "C1", acuity: 7 },
  { date: "6/22/2019 10:10:03", shift: "D1", acuity: 2 },
  { date: "6/22/2019 11:00:51", shift: "D1", acuity: 3 },
  { date: "6/22/2019 11:03:12", shift: "D1", acuity: 2 },
  { date: "6/22/2019 11:05:57", shift: "A1", acuity: 7 },
  { date: "6/22/2019 11:26:21", shift: "B1", acuity: 3 },
  { date: "6/22/2019 11:27:05", shift: "C1", acuity: 3 },
  { date: "6/22/2019 11:44:07", shift: "D1", acuity: 3 },
  { date: "6/22/2019 11:51:54", shift: "A1", acuity: 7 },
  { date: "6/22/2019 11:54:33", shift: "B1", acuity: 3 },
  { date: "6/22/2019 12:12:00", shift: "C1", acuity: 3 },
  { date: "6/22/2019 12:53:17", shift: "D1", acuity: 3 },
  { date: "6/22/2019 13:01:29", shift: "A2", acuity: 3 },
  { date: "6/22/2019 13:01:44", shift: "B1", acuity: 3 },
  { date: "6/22/2019 13:02:11", shift: "C1", acuity: 3 },
  { date: "6/22/2019 13:25:01", shift: "D1", acuity: 7 },
  { date: "6/22/2019 13:32:00", shift: "A2", acuity: 2 },
  { date: "6/22/2019 13:38:48", shift: "A2", acuity: 3 },
  { date: "6/22/2019 13:50:51", shift: "B1", acuity: 4 },
  { date: "6/22/2019 13:57:09", shift: "C1", acuity: 2 },
  { date: "6/22/2019 14:02:44", shift: "B2", acuity: 2 },
  { date: "6/22/2019 14:04:16", shift: "D1", acuity: 2 },
  { date: "6/22/2019 14:04:26", shift: "A2", acuity: 3 },
  { date: "6/22/2019 14:20:34", shift: "C1", acuity: 3 },
  { date: "6/22/2019 14:21:03", shift: "B2", acuity: 2 },
  { date: "6/22/2019 14:35:14", shift: "B2", acuity: 2 },
  { date: "6/22/2019 14:35:38", shift: "B2", acuity: 3 },
  { date: "6/22/2019 14:43:01", shift: "D1", acuity: 2 },
  { date: "6/22/2019 14:57:45", shift: "A2", acuity: 3 },
  { date: "6/22/2019 15:53:10", shift: "C1", acuity: 3 },
  { date: "6/22/2019 15:56:25", shift: "B2", acuity: 2 },
  { date: "6/22/2019 16:00:02", shift: "D1", acuity: 3 },
  { date: "6/22/2019 16:08:57", shift: "A2", acuity: 3 },
  { date: "6/22/2019 16:16:48", shift: "C1", acuity: 2 },
  { date: "6/22/2019 16:29:16", shift: "B2", acuity: 3 },
  { date: "6/22/2019 16:47:56", shift: "C2", acuity: 3 },
  { date: "6/22/2019 16:49:19", shift: "D1", acuity: 3 },
  { date: "6/22/2019 16:49:38", shift: "A2", acuity: 2 },
  { date: "6/22/2019 17:07:26", shift: "C2", acuity: 3 },
  { date: "6/22/2019 17:08:11", shift: "C2", acuity: 3 },
  { date: "6/22/2019 17:16:52", shift: "B2", acuity: 3 },
  { date: "6/22/2019 17:37:27", shift: "D1", acuity: 2 },
  { date: "6/22/2019 17:37:48", shift: "A2", acuity: 2 },
  { date: "6/22/2019 17:43:09", shift: "C2", acuity: 4 },
  { date: "6/22/2019 17:50:45", shift: "B2", acuity: 3 },
  { date: "6/22/2019 17:53:31", shift: "D1", acuity: 3 },
  { date: "6/22/2019 17:55:49", shift: "A2", acuity: 2 },
  { date: "6/22/2019 18:07:07", shift: "D2", acuity: 3 },
  { date: "6/22/2019 18:12:54", shift: "C2", acuity: 2 },
  { date: "6/22/2019 18:22:14", shift: "B2", acuity: 2 },
  { date: "6/22/2019 18:39:34", shift: "D2", acuity: 2 },
  { date: "6/22/2019 18:39:49", shift: "D2", acuity: 2 },
  { date: "6/22/2019 19:00:20", shift: "A2", acuity: 4 },
  { date: "6/22/2019 19:01:55", shift: "C2", acuity: 2 },
  { date: "6/22/2019 19:02:08", shift: "B2", acuity: 3 },
  { date: "6/22/2019 19:21:33", shift: "D2", acuity: 2 },
  { date: "6/22/2019 19:28:29", shift: "A2", acuity: 2 },
  { date: "6/22/2019 19:39:34", shift: "C2", acuity: 3 },
  { date: "6/22/2019 19:39:52", shift: "B2", acuity: 3 },
  { date: "6/22/2019 20:04:14", shift: "D2", acuity: 2 },
  { date: "6/22/2019 20:11:20", shift: "A2", acuity: 3 },
  { date: "6/22/2019 20:19:25", shift: "C2", acuity: 2 },
  { date: "6/22/2019 20:19:48", shift: "B2", acuity: 3 },
  { date: "6/22/2019 20:27:04", shift: "D2", acuity: 2 },
  { date: "6/22/2019 20:40:23", shift: "A2", acuity: 3 },
  { date: "6/22/2019 20:45:38", shift: "C2", acuity: 2 },
  { date: "6/22/2019 20:56:40", shift: "B2", acuity: 2 },
  { date: "6/22/2019 21:07:59", shift: "A3", acuity: 2 },
  { date: "6/22/2019 21:09:43", shift: "D2", acuity: 3 },
  { date: "6/22/2019 21:09:58", shift: "C2", acuity: 3 },
  { date: "6/22/2019 21:10:17", shift: "B2", acuity: 3 },
  { date: "6/22/2019 21:24:17", shift: "A3", acuity: 4 },
  { date: "6/22/2019 21:25:00", shift: "D2", acuity: 2 },
  { date: "6/22/2019 21:37:10", shift: "A3", acuity: 3 },
  { date: "6/22/2019 21:43:56", shift: "A3", acuity: 3 },
  { date: "6/22/2019 22:13:09", shift: "B3", acuity: 3 },
  { date: "6/22/2019 22:36:48", shift: "B3", acuity: 3 },
  { date: "6/22/2019 22:49:26", shift: "B3", acuity: 2 },
  { date: "6/22/2019 22:51:02", shift: "C2", acuity: 3 },
  { date: "6/22/2019 22:51:16", shift: "D2", acuity: 3 },
  { date: "6/22/2019 23:03:26", shift: "A3", acuity: 4 },
  { date: "6/22/2019 23:16:02", shift: "B3", acuity: 2 },
  { date: "6/22/2019 23:16:18", shift: "C2", acuity: 3 },
  { date: "6/22/2019 23:20:10", shift: "D2", acuity: 4 },
  { date: "6/22/2019 23:34:14", shift: "A3", acuity: 2 },
  { date: "6/22/2019 23:34:39", shift: "B3", acuity: 2 },
  { date: "6/22/2019 23:59:18", shift: "C2", acuity: 4 },
  { date: "6/23/2019 00:16:50", shift: "D2", acuity: 4 },
  { date: "6/23/2019 00:17:03", shift: "A3", acuity: 4 },
  { date: "6/23/2019 00:28:26", shift: "B3", acuity: 3 },
  { date: "6/23/2019 00:55:51", shift: "D2", acuity: 5 },
  { date: "6/23/2019 01:13:05", shift: "A3", acuity: 2 },
  { date: "6/23/2019 01:25:22", shift: "B3", acuity: 4 },
  { date: "6/23/2019 01:25:54", shift: "D2", acuity: 5 },
  { date: "6/23/2019 01:55:41", shift: "A3", acuity: 4 },
  { date: "6/23/2019 02:24:45", shift: "B3", acuity: 2 },
  { date: "6/23/2019 02:25:06", shift: "A3", acuity: 3 },
  { date: "6/23/2019 03:17:47", shift: "B3", acuity: 3 },
  { date: "6/23/2019 03:29:08", shift: "A3", acuity: 3 },
  { date: "6/23/2019 03:34:04", shift: "B3", acuity: 3 },
  { date: "6/23/2019 05:02:17", shift: "A1", acuity: 3 },
  { date: "6/23/2019 05:10:01", shift: "B3", acuity: 2 },
  { date: "6/23/2019 06:04:12", shift: "A1", acuity: 3 },
  { date: "6/23/2019 06:04:26", shift: "A1", acuity: 3 },
  { date: "6/23/2019 06:11:06", shift: "B1", acuity: 4 },
  { date: "6/23/2019 06:54:06", shift: "B1", acuity: 3 },
  { date: "6/23/2019 07:39:26", shift: "B1", acuity: 4 },
  { date: "6/23/2019 08:31:41", shift: "C1", acuity: 3 },
  { date: "6/23/2019 08:32:15", shift: "A1", acuity: 3 },
  { date: "6/23/2019 08:42:08", shift: "B1", acuity: 2 },
  { date: "6/23/2019 08:44:04", shift: "C1", acuity: 3 },
  { date: "6/23/2019 08:44:20", shift: "A1", acuity: 3 },
  { date: "6/23/2019 08:51:11", shift: "B1", acuity: 7 },
  { date: "6/23/2019 09:02:40", shift: "C1", acuity: 2 },
  { date: "6/23/2019 09:21:09", shift: "C1", acuity: 2 },
  { date: "6/23/2019 09:38:01", shift: "A1", acuity: 4 },
  { date: "6/23/2019 09:39:36", shift: "B1", acuity: 4 },
  { date: "6/23/2019 09:54:05", shift: "C1", acuity: 4 },
  { date: "6/23/2019 09:54:22", shift: "A1", acuity: 3 },
  { date: "6/23/2019 09:54:36", shift: "B1", acuity: 3 },
  { date: "6/23/2019 09:59:06", shift: "C1", acuity: 3 },
  { date: "6/23/2019 10:29:12", shift: "D1", acuity: 3 },
  { date: "6/23/2019 10:29:28", shift: "A1", acuity: 2 },
  { date: "6/23/2019 10:29:42", shift: "B1", acuity: 3 },
  { date: "6/23/2019 10:55:58", shift: "D1", acuity: 2 },
  { date: "6/23/2019 10:56:33", shift: "D1", acuity: 2 },
  { date: "6/23/2019 11:21:13", shift: "C1", acuity: 3 },
  { date: "6/23/2019 11:25:33", shift: "A1", acuity: 2 },
  { date: "6/23/2019 11:31:41", shift: "B1", acuity: 2 },
  { date: "6/23/2019 11:40:14", shift: "D1", acuity: 2 },
  { date: "6/23/2019 11:50:13", shift: "C1", acuity: 2 },
  { date: "6/23/2019 11:55:52", shift: "A1", acuity: 2 },
  { date: "6/23/2019 12:01:08", shift: "B1", acuity: 2 },
  { date: "6/23/2019 12:01:19", shift: "D1", acuity: 2 },
  { date: "6/23/2019 12:29:57", shift: "C1", acuity: 3 },
  { date: "6/23/2019 12:30:39", shift: "A1", acuity: 2 },
  { date: "6/23/2019 12:40:35", shift: "B1", acuity: 3 },
  { date: "6/23/2019 12:54:26", shift: "D1", acuity: 2 },
  { date: "6/23/2019 13:12:52", shift: "A2", acuity: 2 },
  { date: "6/23/2019 13:17:18", shift: "C1", acuity: 3 },
  { date: "6/23/2019 13:18:24", shift: "B1", acuity: 3 },
  { date: "6/23/2019 13:24:02", shift: "D1", acuity: 3 },
  { date: "6/23/2019 13:32:37", shift: "A2", acuity: 3 },
  { date: "6/23/2019 13:33:37", shift: "A2", acuity: 3 },
  { date: "6/23/2019 14:05:56", shift: "B2", acuity: 3 },
  { date: "6/23/2019 14:25:08", shift: "C1", acuity: 3 },
  { date: "6/23/2019 14:49:25", shift: "B2", acuity: 2 },
  { date: "6/23/2019 14:55:45", shift: "B2", acuity: 3 },
  { date: "6/23/2019 14:56:44", shift: "D1", acuity: 3 },
  { date: "6/23/2019 14:58:20", shift: "A2", acuity: 2 },
  { date: "6/23/2019 15:05:38", shift: "C1", acuity: 3 },
  { date: "6/23/2019 15:05:55", shift: "B2", acuity: 3 },
  { date: "6/23/2019 15:17:08", shift: "D1", acuity: 2 },
  { date: "6/23/2019 15:20:44", shift: "A2", acuity: 2 },
  { date: "6/23/2019 15:28:55", shift: "C1", acuity: 3 },
  { date: "6/23/2019 16:02:38", shift: "B2", acuity: 3 },
  { date: "6/23/2019 16:20:25", shift: "D1", acuity: 2 },
  { date: "6/23/2019 16:22:05", shift: "A2", acuity: 3 },
  { date: "6/23/2019 16:34:42", shift: "C2", acuity: 3 },
  { date: "6/23/2019 16:34:52", shift: "B2", acuity: 3 },
  { date: "6/23/2019 16:35:07", shift: "D1", acuity: 3 },
  { date: "6/23/2019 16:38:06", shift: "A2", acuity: 3 },
  { date: "6/23/2019 16:44:32", shift: "C2", acuity: 2 },
  { date: "6/23/2019 16:54:37", shift: "B2", acuity: 2 },
  { date: "6/23/2019 17:02:12", shift: "C2", acuity: 2 },
  { date: "6/23/2019 17:02:31", shift: "C2", acuity: 3 },
  { date: "6/23/2019 17:27:17", shift: "D1", acuity: 3 },
  { date: "6/23/2019 17:48:42", shift: "A2", acuity: 4 },
  { date: "6/23/2019 17:53:48", shift: "B2", acuity: 3 },
  { date: "6/23/2019 18:21:45", shift: "D2", acuity: 3 },
  { date: "6/23/2019 18:22:04", shift: "C2", acuity: 2 },
  { date: "6/23/2019 18:22:17", shift: "A2", acuity: 3 },
  { date: "6/23/2019 18:41:54", shift: "D2", acuity: 3 },
  { date: "6/23/2019 19:13:12", shift: "D2", acuity: 3 },
  { date: "6/23/2019 19:16:18", shift: "B2", acuity: 2 },
  { date: "6/23/2019 19:25:47", shift: "C2", acuity: 3 },
  { date: "6/23/2019 19:39:15", shift: "A2", acuity: 2 },
  { date: "6/23/2019 19:39:43", shift: "D2", acuity: 2 },
  { date: "6/23/2019 19:52:23", shift: "B2", acuity: 2 },
  { date: "6/23/2019 20:01:13", shift: "C2", acuity: 2 },
  { date: "6/23/2019 20:20:15", shift: "A2", acuity: 3 },
  { date: "6/23/2019 20:22:04", shift: "D2", acuity: 3 },
  { date: "6/23/2019 20:27:35", shift: "B2", acuity: 2 },
  { date: "6/23/2019 20:45:39", shift: "C2", acuity: 3 },
  { date: "6/23/2019 21:24:01", shift: "A3", acuity: 2 },
  { date: "6/23/2019 21:24:11", shift: "D2", acuity: 2 },
  { date: "6/23/2019 21:32:46", shift: "A3", acuity: 3 },
  { date: "6/23/2019 21:35:05", shift: "A3", acuity: 3 },
  { date: "6/23/2019 21:37:18", shift: "B2", acuity: 3 },
  { date: "6/23/2019 21:44:21", shift: "C2", acuity: 3 },
  { date: "6/23/2019 22:01:47", shift: "B3", acuity: 3 },
  { date: "6/23/2019 22:01:59", shift: "D2", acuity: 3 },
  { date: "6/23/2019 22:11:06", shift: "A3", acuity: 3 },
  { date: "6/23/2019 22:12:40", shift: "C2", acuity: 3 },
  { date: "6/23/2019 22:40:43", shift: "B3", acuity: 3 },
  { date: "6/23/2019 22:41:05", shift: "B3", acuity: 4 },
  { date: "6/23/2019 22:51:33", shift: "D2", acuity: 4 },
  { date: "6/23/2019 22:52:54", shift: "A3", acuity: 5 },
  { date: "6/23/2019 23:00:36", shift: "C2", acuity: 3 },
  { date: "6/23/2019 23:22:49", shift: "B3", acuity: 4 },
  { date: "6/23/2019 23:24:30", shift: "D2", acuity: 6 },
  { date: "6/23/2019 23:44:47", shift: "A3", acuity: 4 },
  { date: "6/23/2019 23:51:15", shift: "C2", acuity: 3 },
  { date: "6/24/2019 00:09:42", shift: "B3", acuity: 3 },
  { date: "6/24/2019 00:10:53", shift: "D2", acuity: 4 },
  { date: "6/24/2019 00:11:08", shift: "A3", acuity: 4 },
  { date: "6/24/2019 00:30:48", shift: "B3", acuity: 3 },
  { date: "6/24/2019 00:54:29", shift: "D2", acuity: 4 },
  { date: "6/24/2019 00:54:46", shift: "A3", acuity: 4 },
  { date: "6/24/2019 01:24:34", shift: "B3", acuity: 3 },
  { date: "6/24/2019 01:24:41", shift: "D2", acuity: 4 },
  { date: "6/24/2019 01:32:32", shift: "A3", acuity: 6 },
  { date: "6/24/2019 02:15:15", shift: "B3", acuity: 3 },
  { date: "6/24/2019 02:19:11", shift: "A3", acuity: 3 },
  { date: "6/24/2019 02:45:56", shift: "B3", acuity: 3 },
  { date: "6/24/2019 02:55:26", shift: "A3", acuity: 6 },
  { date: "6/24/2019 03:10:25", shift: "B3", acuity: 3 },
  { date: "6/24/2019 03:24:14", shift: "A3", acuity: 4 },
  { date: "6/24/2019 03:37:18", shift: "B3", acuity: 6 },
  { date: "6/24/2019 04:49:10", shift: "A3", acuity: 6 },
  { date: "6/24/2019 04:50:00", shift: "B3", acuity: 3 },
  { date: "6/24/2019 05:15:51", shift: "A1", acuity: 3 },
  { date: "6/24/2019 05:30:18", shift: "A1", acuity: 4 },
  { date: "6/24/2019 05:41:58", shift: "A1", acuity: 2 },
  { date: "6/24/2019 06:00:37", shift: "B1", acuity: 3 },
  { date: "6/24/2019 06:00:50", shift: "A1", acuity: 3 },
  { date: "6/24/2019 06:30:50", shift: "B1", acuity: 3 },
  { date: "6/24/2019 07:08:31", shift: "B1", acuity: 4 },
  { date: "6/24/2019 07:11:51", shift: "A1", acuity: 3 },
  { date: "6/24/2019 07:37:44", shift: "B1", acuity: 4 },
  { date: "6/24/2019 07:49:18", shift: "A1", acuity: 4 },
  { date: "6/24/2019 07:55:14", shift: "B1", acuity: 2 },
  { date: "6/24/2019 08:00:22", shift: "A1", acuity: 4 },
  { date: "6/24/2019 08:20:26", shift: "B1", acuity: 3 },
  { date: "6/24/2019 08:20:38", shift: "A1", acuity: 3 },
  { date: "6/24/2019 08:43:23", shift: "C1", acuity: 3 },
  { date: "6/24/2019 09:15:10", shift: "C1", acuity: 3 },
  { date: "6/24/2019 09:20:22", shift: "C1", acuity: 3 },
  { date: "6/24/2019 09:43:19", shift: "B1", acuity: 3 },
  { date: "6/24/2019 09:44:25", shift: "A1", acuity: 3 },
  { date: "6/24/2019 10:14:46", shift: "D1", acuity: 3 },
  { date: "6/24/2019 10:22:42", shift: "C1", acuity: 3 },
  { date: "6/24/2019 10:22:59", shift: "B1", acuity: 6 },
  { date: "6/24/2019 10:27:10", shift: "A1", acuity: 6 },
  { date: "6/24/2019 10:38:39", shift: "D1", acuity: 6 },
  { date: "6/24/2019 10:59:59", shift: "D1", acuity: 2 },
  { date: "6/24/2019 11:22:16", shift: "C1", acuity: 6 },
  { date: "6/24/2019 11:43:08", shift: "B1", acuity: 2 },
  { date: "6/24/2019 11:43:34", shift: "A1", acuity: 6 },
  { date: "6/24/2019 11:55:38", shift: "D1", acuity: 3 },
  { date: "6/24/2019 12:38:43", shift: "C1", acuity: 6 },
  { date: "6/24/2019 12:43:56", shift: "B1", acuity: 6 },
  { date: "6/24/2019 12:49:18", shift: "A1", acuity: 6 },
  { date: "6/24/2019 13:16:46", shift: "A2", acuity: 6 },
  { date: "6/24/2019 13:31:37", shift: "A2", acuity: 3 },
  { date: "6/24/2019 13:45:58", shift: "A2", acuity: 3 },
  { date: "6/24/2019 13:55:55", shift: "D1", acuity: 6 },
  { date: "6/24/2019 14:02:51", shift: "B2", acuity: 6 },
  { date: "6/24/2019 14:37:28", shift: "B2", acuity: 6 },
  { date: "6/24/2019 14:38:26", shift: "B2", acuity: 6 },
  { date: "6/24/2019 14:39:09", shift: "C1", acuity: 6 },
  { date: "6/24/2019 14:51:45", shift: "A2", acuity: 3 },
  { date: "6/24/2019 15:02:35", shift: "D1", acuity: 6 },
  { date: "6/24/2019 15:32:04", shift: "B2", acuity: 3 },
  { date: "6/24/2019 15:44:35", shift: "C1", acuity: 3 },
  { date: "6/24/2019 15:45:03", shift: "A2", acuity: 6 },
  { date: "6/24/2019 15:48:38", shift: "D1", acuity: 6 },
  { date: "6/24/2019 15:58:56", shift: "B2", acuity: 3 },
  { date: "6/24/2019 16:07:05", shift: "C1", acuity: 3 },
  { date: "6/24/2019 16:30:16", shift: "C2", acuity: 4 },
  { date: "6/24/2019 16:32:27", shift: "A2", acuity: 3 },
  { date: "6/24/2019 16:55:27", shift: "D1", acuity: 6 },
  { date: "6/24/2019 17:04:44", shift: "C2", acuity: 3 },
  { date: "6/24/2019 17:04:58", shift: "C2", acuity: 3 },
  { date: "6/24/2019 17:15:35", shift: "B2", acuity: 6 },
  { date: "6/24/2019 17:34:18", shift: "A2", acuity: 3 },
  { date: "6/24/2019 17:48:31", shift: "D1", acuity: 3 },
  { date: "6/24/2019 17:50:45", shift: "C2", acuity: 3 },
  { date: "6/24/2019 17:59:25", shift: "B2", acuity: 3 },
  { date: "6/24/2019 18:06:50", shift: "D2", acuity: 6 },
  { date: "6/24/2019 18:18:46", shift: "A2", acuity: 3 },
  { date: "6/24/2019 18:25:08", shift: "C2", acuity: 3 },
  { date: "6/24/2019 18:30:14", shift: "D2", acuity: 2 },
  { date: "6/24/2019 18:39:10", shift: "D2", acuity: 3 },
  { date: "6/24/2019 18:48:19", shift: "B2", acuity: 3 },
  { date: "6/24/2019 18:59:59", shift: "A2", acuity: 3 },
  { date: "6/24/2019 19:00:12", shift: "C2", acuity: 3 },
  { date: "6/24/2019 19:07:50", shift: "D2", acuity: 6 },
  { date: "6/24/2019 19:18:13", shift: "B2", acuity: 3 },
  { date: "6/24/2019 19:31:49", shift: "A2", acuity: 6 },
  { date: "6/24/2019 19:32:16", shift: "C2", acuity: 6 },
  { date: "6/24/2019 19:37:14", shift: "D2", acuity: 3 },
  { date: "6/24/2019 19:39:17", shift: "B2", acuity: 6 },
  { date: "6/24/2019 19:39:27", shift: "A2", acuity: 3 },
  { date: "6/24/2019 19:47:32", shift: "C2", acuity: 6 },
  { date: "6/24/2019 19:47:44", shift: "D2", acuity: 6 },
  { date: "6/24/2019 20:04:10", shift: "B2", acuity: 3 },
  { date: "6/24/2019 20:10:38", shift: "A2", acuity: 3 },
  { date: "6/24/2019 20:11:02", shift: "C2", acuity: 4 },
  { date: "6/24/2019 20:24:11", shift: "D2", acuity: 3 },
  { date: "6/24/2019 20:29:17", shift: "B2", acuity: 6 },
  { date: "6/24/2019 20:30:57", shift: "A2", acuity: 3 },
  { date: "6/24/2019 20:48:18", shift: "C2", acuity: 3 },
  { date: "6/24/2019 20:53:45", shift: "D2", acuity: 3 },
  { date: "6/24/2019 21:07:11", shift: "A3", acuity: 3 },
  { date: "6/24/2019 21:13:44", shift: "B2", acuity: 4 },
  { date: "6/24/2019 21:14:00", shift: "C2", acuity: 3 },
  { date: "6/24/2019 21:14:11", shift: "D2", acuity: 3 },
  { date: "6/24/2019 21:21:31", shift: "A3", acuity: 3 },
  { date: "6/24/2019 21:31:25", shift: "A3", acuity: 6 },
  { date: "6/24/2019 21:43:06", shift: "A3", acuity: 6 },
  { date: "6/24/2019 21:55:17", shift: "B2", acuity: 3 },
  { date: "6/24/2019 22:05:56", shift: "B3", acuity: 4 },
  { date: "6/24/2019 22:06:12", shift: "C2", acuity: 6 },
  { date: "6/24/2019 22:19:16", shift: "D2", acuity: 6 },
  { date: "6/24/2019 22:38:10", shift: "B3", acuity: 6 },
  { date: "6/24/2019 22:56:21", shift: "B3", acuity: 4 },
  { date: "6/24/2019 22:56:37", shift: "A3", acuity: 4 },
  { date: "6/24/2019 22:56:52", shift: "C2", acuity: 3 },
  { date: "6/24/2019 22:58:37", shift: "D2", acuity: 6 },
  { date: "6/24/2019 23:27:14", shift: "B3", acuity: 6 },
  { date: "6/24/2019 23:44:55", shift: "A3", acuity: 3 },
  { date: "6/24/2019 23:45:57", shift: "C2", acuity: 3 },
  { date: "6/25/2019 00:02:32", shift: "D2", acuity: 4 },
  { date: "6/25/2019 00:07:33", shift: "B3", acuity: 6 },
  { date: "6/25/2019 00:23:55", shift: "A3", acuity: 4 },
  { date: "6/25/2019 00:52:19", shift: "D2", acuity: 3 },
  { date: "6/25/2019 01:17:44", shift: "B3", acuity: 3 },
  { date: "6/25/2019 01:18:10", shift: "A3", acuity: 6 },
  { date: "6/25/2019 01:19:01", shift: "D2", acuity: 4 },
  { date: "6/25/2019 01:33:57", shift: "B3", acuity: 3 },
  { date: "6/25/2019 01:53:07", shift: "A3", acuity: 3 },
  { date: "6/25/2019 02:16:10", shift: "B3", acuity: 2 },
  { date: "6/25/2019 02:29:58", shift: "A3", acuity: 4 },
  { date: "6/25/2019 02:43:42", shift: "B3", acuity: 4 },
  { date: "6/25/2019 03:10:21", shift: "A3", acuity: 3 },
  { date: "6/25/2019 03:22:21", shift: "B3", acuity: 4 },
  { date: "6/25/2019 03:52:14", shift: "A3", acuity: 6 },
  { date: "6/25/2019 04:05:12", shift: "B3", acuity: 4 },
  { date: "6/25/2019 04:19:03", shift: "A3", acuity: 4 },
  { date: "6/25/2019 05:08:11", shift: "A1", acuity: 3 },
  { date: "6/25/2019 05:08:38", shift: "B3", acuity: 3 },
  { date: "6/25/2019 05:34:03", shift: "A1", acuity: 4 },
  { date: "6/25/2019 06:05:22", shift: "A1", acuity: 3 },
  { date: "6/25/2019 06:08:50", shift: "B1", acuity: 3 },
  { date: "6/25/2019 06:46:15", shift: "B1", acuity: 3 },
  { date: "6/25/2019 06:59:05", shift: "B1", acuity: 6 },
  { date: "6/25/2019 07:26:40", shift: "A1", acuity: 6 },
  { date: "6/25/2019 07:30:19", shift: "B1", acuity: 4 },
  { date: "6/25/2019 07:42:26", shift: "A1", acuity: 6 },
  { date: "6/25/2019 08:23:08", shift: "B1", acuity: 6 },
  { date: "6/25/2019 08:43:17", shift: "C1", acuity: 3 },
  { date: "6/25/2019 09:00:34", shift: "C1", acuity: 7 },
  { date: "6/25/2019 09:29:32", shift: "C1", acuity: 3 },
  { date: "6/25/2019 09:40:33", shift: "A1", acuity: 3 },
  { date: "6/25/2019 09:41:30", shift: "B1", acuity: 4 },
  { date: "6/25/2019 09:53:38", shift: "C1", acuity: 6 },
  { date: "6/25/2019 09:55:28", shift: "A1", acuity: 6 },
  { date: "6/25/2019 09:55:51", shift: "B1", acuity: 3 },
  { date: "6/25/2019 09:56:17", shift: "C1", acuity: 4 },
  { date: "6/25/2019 10:04:42", shift: "D1", acuity: 3 },
  { date: "6/25/2019 10:29:14", shift: "A1", acuity: 3 },
  { date: "6/25/2019 10:47:00", shift: "D1", acuity: 3 },
  { date: "6/25/2019 10:51:29", shift: "D1", acuity: 6 },
  { date: "6/25/2019 11:03:07", shift: "B1", acuity: 6 },
  { date: "6/25/2019 11:12:06", shift: "C1", acuity: 3 },
  { date: "6/25/2019 11:45:28", shift: "A1", acuity: 3 },
  { date: "6/25/2019 12:13:33", shift: "D1", acuity: 6 },
  { date: "6/25/2019 12:21:00", shift: "B1", acuity: 3 },
  { date: "6/25/2019 12:29:57", shift: "C1", acuity: 6 },
  { date: "6/25/2019 12:39:17", shift: "A1", acuity: 3 },
  { date: "6/25/2019 12:39:33", shift: "D1", acuity: 3 },
  { date: "6/25/2019 12:44:08", shift: "B1", acuity: 3 },
  { date: "6/25/2019 13:26:20", shift: "A2", acuity: 3 },
  { date: "6/25/2019 13:27:08", shift: "C1", acuity: 3 },
  { date: "6/25/2019 14:00:47", shift: "A2", acuity: 3 },
  { date: "6/25/2019 14:10:24", shift: "A2", acuity: 2 },
  { date: "6/25/2019 14:23:05", shift: "B2", acuity: 3 },
  { date: "6/25/2019 14:56:42", shift: "B2", acuity: 6 },
  { date: "6/25/2019 15:12:17", shift: "B2", acuity: 6 },
  { date: "6/25/2019 15:12:38", shift: "D1", acuity: 2 },
  { date: "6/25/2019 15:33:17", shift: "C1", acuity: 6 },
  { date: "6/25/2019 15:33:47", shift: "A2", acuity: 6 },
  { date: "6/25/2019 15:34:12", shift: "B2", acuity: 3 },
  { date: "6/25/2019 15:36:51", shift: "D1", acuity: 3 },
  { date: "6/25/2019 15:53:44", shift: "C1", acuity: 3 },
  { date: "6/25/2019 15:54:06", shift: "A2", acuity: 3 },
  { date: "6/25/2019 16:20:02", shift: "B2", acuity: 6 },
  { date: "6/25/2019 16:23:37", shift: "D1", acuity: 6 },
  { date: "6/25/2019 16:24:05", shift: "C1", acuity: 6 },
  { date: "6/25/2019 16:41:17", shift: "C2", acuity: 6 },
  { date: "6/25/2019 16:42:21", shift: "A2", acuity: 3 },
  { date: "6/25/2019 17:12:31", shift: "C2", acuity: 6 },
  { date: "6/25/2019 17:24:09", shift: "C2", acuity: 6 },
  { date: "6/25/2019 17:24:34", shift: "B2", acuity: 6 },
  { date: "6/25/2019 18:00:33", shift: "D2", acuity: 3 },
  { date: "6/25/2019 18:05:18", shift: "A2", acuity: 3 },
  { date: "6/25/2019 18:24:23", shift: "C2", acuity: 6 },
  { date: "6/25/2019 18:25:56", shift: "B2", acuity: 6 },
  { date: "6/25/2019 18:30:16", shift: "D2", acuity: 6 },
  { date: "6/25/2019 18:30:36", shift: "D2", acuity: 3 },
  { date: "6/25/2019 18:58:21", shift: "A2", acuity: 3 },
  { date: "6/25/2019 18:58:40", shift: "C2", acuity: 3 },
  { date: "6/25/2019 18:59:01", shift: "B2", acuity: 3 },
  { date: "6/25/2019 19:14:18", shift: "D2", acuity: 3 },
  { date: "6/25/2019 19:14:34", shift: "A2", acuity: 6 },
  { date: "6/25/2019 19:14:49", shift: "C2", acuity: 3 },
  { date: "6/25/2019 19:15:03", shift: "B2", acuity: 3 },
  { date: "6/25/2019 19:31:26", shift: "D2", acuity: 6 },
  { date: "6/25/2019 19:33:48", shift: "A2", acuity: 3 },
  { date: "6/25/2019 19:52:16", shift: "C2", acuity: 3 },
  { date: "6/25/2019 19:58:10", shift: "B2", acuity: 3 },
  { date: "6/25/2019 19:58:23", shift: "D2", acuity: 3 },
  { date: "6/25/2019 20:00:44", shift: "A2", acuity: 6 },
  { date: "6/25/2019 20:25:46", shift: "C2", acuity: 3 },
  { date: "6/25/2019 20:43:49", shift: "B2", acuity: 3 },
  { date: "6/25/2019 20:44:02", shift: "D2", acuity: 3 },
  { date: "6/25/2019 21:00:54", shift: "A3", acuity: 3 },
  { date: "6/25/2019 21:02:17", shift: "C2", acuity: 3 },
  { date: "6/25/2019 21:47:00", shift: "A3", acuity: 3 },
  { date: "6/25/2019 21:48:59", shift: "A3", acuity: 4 },
  { date: "6/25/2019 22:05:18", shift: "B3", acuity: 3 },
  { date: "6/25/2019 22:05:36", shift: "D2", acuity: 3 },
  { date: "6/25/2019 22:18:20", shift: "C2", acuity: 6 },
  { date: "6/25/2019 22:22:21", shift: "A3", acuity: 3 },
  { date: "6/25/2019 22:31:32", shift: "B3", acuity: 3 },
  { date: "6/25/2019 22:31:40", shift: "B3", acuity: 3 },
  { date: "6/25/2019 22:49:51", shift: "D2", acuity: 4 },
  { date: "6/25/2019 22:50:23", shift: "C2", acuity: 4 },
  { date: "6/25/2019 22:59:55", shift: "A3", acuity: 4 },
  { date: "6/25/2019 23:06:08", shift: "B3", acuity: 3 },
  { date: "6/25/2019 23:17:48", shift: "D2", acuity: 3 },
  { date: "6/25/2019 23:18:07", shift: "C2", acuity: 3 },
  { date: "6/25/2019 23:29:19", shift: "A3", acuity: 6 },
  { date: "6/25/2019 23:48:38", shift: "B3", acuity: 4 },
  { date: "6/25/2019 23:49:02", shift: "D2", acuity: 4 },
  { date: "6/25/2019 23:55:10", shift: "C2", acuity: 3 },
  { date: "6/26/2019 00:06:52", shift: "A3", acuity: 4 },
  { date: "6/26/2019 00:15:11", shift: "B3", acuity: 4 },
  { date: "6/26/2019 00:18:49", shift: "D2", acuity: 4 },
  { date: "6/26/2019 00:19:02", shift: "A3", acuity: 4 },
  { date: "6/26/2019 00:54:55", shift: "B3", acuity: 3 },
  { date: "6/26/2019 01:07:05", shift: "D2", acuity: 3 },
  { date: "6/26/2019 01:21:03", shift: "A3", acuity: 3 },
  { date: "6/26/2019 02:33:11", shift: "B3", acuity: 6 },
  { date: "6/26/2019 02:42:47", shift: "A3", acuity: 4 },
  { date: "6/26/2019 02:57:00", shift: "B3", acuity: 3 },
  { date: "6/26/2019 03:10:05", shift: "A3", acuity: 3 },
  { date: "6/26/2019 03:11:47", shift: "B3", acuity: 6 },
  { date: "6/26/2019 03:27:07", shift: "A3", acuity: 3 },
  { date: "6/26/2019 03:46:21", shift: "B3", acuity: 6 },
  { date: "6/26/2019 03:52:47", shift: "A3", acuity: 3 },
  { date: "6/26/2019 03:56:26", shift: "B3", acuity: 3 },
  { date: "6/26/2019 04:01:09", shift: "A3", acuity: 6 },
  { date: "6/26/2019 04:02:16", shift: "B3", acuity: 3 },
  { date: "6/26/2019 04:44:26", shift: "A3", acuity: 6 },
  { date: "6/26/2019 05:03:38", shift: "A1", acuity: 4 },
  { date: "6/26/2019 05:03:55", shift: "B3", acuity: 6 },
  { date: "6/26/2019 05:33:24", shift: "A1", acuity: 6 },
  { date: "6/26/2019 06:15:19", shift: "A1", acuity: 3 },
  { date: "6/26/2019 06:30:31", shift: "B1", acuity: 6 },
  { date: "6/26/2019 07:01:40", shift: "B1", acuity: 3 },
  { date: "6/26/2019 07:23:35", shift: "B1", acuity: 3 },
  { date: "6/26/2019 07:28:22", shift: "A1", acuity: 3 },
  { date: "6/26/2019 07:41:20", shift: "B1", acuity: 4 },
  { date: "6/26/2019 08:28:39", shift: "A1", acuity: 4 },
  { date: "6/26/2019 08:38:37", shift: "C1", acuity: 3 },
  { date: "6/26/2019 09:00:19", shift: "C1", acuity: 3 },
  { date: "6/26/2019 09:08:48", shift: "C1", acuity: 3 },
  { date: "6/26/2019 09:24:12", shift: "B1", acuity: 3 },
  { date: "6/26/2019 09:27:40", shift: "A1", acuity: 3 },
  { date: "6/26/2019 09:37:39", shift: "C1", acuity: 6 },
  { date: "6/26/2019 09:37:57", shift: "B1", acuity: 3 },
  { date: "6/26/2019 09:48:03", shift: "A1", acuity: 3 },
  { date: "6/26/2019 09:50:53", shift: "C1", acuity: 6 },
  { date: "6/26/2019 09:51:19", shift: "B1", acuity: 3 },
  { date: "6/26/2019 10:55:32", shift: "D1", acuity: 6 },
  { date: "6/26/2019 10:56:03", shift: "D1", acuity: 4 },
  { date: "6/26/2019 11:24:50", shift: "D1", acuity: 6 },
  { date: "6/26/2019 11:29:20", shift: "A1", acuity: 3 },
  { date: "6/26/2019 11:30:20", shift: "C1", acuity: 3 },
  { date: "6/26/2019 12:21:47", shift: "B1", acuity: 6 },
  { date: "6/26/2019 12:59:27", shift: "D1", acuity: 6 },
  { date: "6/26/2019 13:31:30", shift: "A2", acuity: 3 },
  { date: "6/26/2019 13:31:43", shift: "A2", acuity: 3 },
  { date: "6/26/2019 13:45:56", shift: "A2", acuity: 6 },
  { date: "6/26/2019 13:46:26", shift: "C1", acuity: 6 },
  { date: "6/26/2019 14:01:10", shift: "B2", acuity: 3 },
  { date: "6/26/2019 14:09:42", shift: "D1", acuity: 3 },
  { date: "6/26/2019 14:31:31", shift: "B2", acuity: 4 },
  { date: "6/26/2019 14:49:58", shift: "B2", acuity: 6 },
  { date: "6/26/2019 14:50:15", shift: "A2", acuity: 6 },
  { date: "6/26/2019 14:55:45", shift: "C1", acuity: 3 },
  { date: "6/26/2019 15:00:18", shift: "D1", acuity: 3 },
  { date: "6/26/2019 15:05:25", shift: "B2", acuity: 3 },
  { date: "6/26/2019 15:21:19", shift: "A2", acuity: 3 },
  { date: "6/26/2019 15:25:44", shift: "C1", acuity: 3 },
  { date: "6/26/2019 15:25:57", shift: "D1", acuity: 3 },
  { date: "6/26/2019 15:41:18", shift: "B2", acuity: 3 },
  { date: "6/26/2019 15:45:59", shift: "A2", acuity: 3 },
  { date: "6/26/2019 15:52:37", shift: "C1", acuity: 3 },
  { date: "6/26/2019 16:14:24", shift: "D1", acuity: 3 },
  { date: "6/26/2019 16:22:03", shift: "B2", acuity: 3 },
  { date: "6/26/2019 16:29:42", shift: "A2", acuity: 3 },
  { date: "6/26/2019 16:29:59", shift: "C1", acuity: 6 },
  { date: "6/26/2019 16:30:19", shift: "C2", acuity: 3 },
  { date: "6/26/2019 16:42:26", shift: "D1", acuity: 3 },
  { date: "6/26/2019 16:51:44", shift: "B2", acuity: 6 },
  { date: "6/26/2019 16:54:48", shift: "A2", acuity: 3 },
  { date: "6/26/2019 17:09:40", shift: "C2", acuity: 3 },
  { date: "6/26/2019 17:36:37", shift: "C2", acuity: 3 },
  { date: "6/26/2019 17:45:14", shift: "D1", acuity: 3 },
  { date: "6/26/2019 17:45:43", shift: "B2", acuity: 6 },
  { date: "6/26/2019 17:46:09", shift: "A2", acuity: 3 },
  { date: "6/26/2019 18:07:56", shift: "D2", acuity: 3 },
  { date: "6/26/2019 18:09:03", shift: "C2", acuity: 3 },
  { date: "6/26/2019 18:29:10", shift: "B2", acuity: 6 },
  { date: "6/26/2019 18:35:20", shift: "D2", acuity: 3 },
  { date: "6/26/2019 18:35:56", shift: "D2", acuity: 6 },
  { date: "6/26/2019 18:44:59", shift: "A2", acuity: 6 },
  { date: "6/26/2019 18:45:15", shift: "C2", acuity: 3 },
  { date: "6/26/2019 18:56:00", shift: "B2", acuity: 6 },
  { date: "6/26/2019 19:00:43", shift: "D2", acuity: 6 },
  { date: "6/26/2019 19:19:15", shift: "A2", acuity: 6 },
  { date: "6/26/2019 19:19:29", shift: "C2", acuity: 6 },
  { date: "6/26/2019 19:19:49", shift: "B2", acuity: 6 },
  { date: "6/26/2019 19:28:15", shift: "D2", acuity: 3 },
  { date: "6/26/2019 19:50:46", shift: "A2", acuity: 6 },
  { date: "6/26/2019 19:51:02", shift: "C2", acuity: 3 },
  { date: "6/26/2019 20:05:58", shift: "B2", acuity: 3 },
  { date: "6/26/2019 20:14:22", shift: "D2", acuity: 3 },
  { date: "6/26/2019 20:14:38", shift: "A2", acuity: 3 },
  { date: "6/26/2019 20:38:02", shift: "C2", acuity: 3 },
  { date: "6/26/2019 20:38:16", shift: "B2", acuity: 3 },
  { date: "6/26/2019 20:42:02", shift: "D2", acuity: 6 },
  { date: "6/26/2019 20:42:27", shift: "A2", acuity: 3 },
  { date: "6/26/2019 20:50:09", shift: "C2", acuity: 3 },
  { date: "6/26/2019 21:16:13", shift: "A3", acuity: 3 },
  { date: "6/26/2019 21:21:35", shift: "B2", acuity: 3 },
  { date: "6/26/2019 21:39:59", shift: "A3", acuity: 6 },
  { date: "6/26/2019 21:40:19", shift: "A3", acuity: 3 },
  { date: "6/26/2019 21:40:40", shift: "D2", acuity: 3 },
  { date: "6/26/2019 22:07:56", shift: "B3", acuity: 3 },
  { date: "6/26/2019 22:08:37", shift: "C2", acuity: 2 },
  { date: "6/26/2019 22:10:39", shift: "A3", acuity: 6 },
  { date: "6/26/2019 22:18:06", shift: "D2", acuity: 6 },
  { date: "6/26/2019 22:27:25", shift: "B3", acuity: 3 },
  { date: "6/26/2019 22:31:51", shift: "B3", acuity: 3 },
  { date: "6/26/2019 22:44:05", shift: "B3", acuity: 3 },
  { date: "6/26/2019 22:52:34", shift: "C2", acuity: 3 },
  { date: "6/26/2019 22:52:43", shift: "A3", acuity: 2 },
  { date: "6/26/2019 23:07:59", shift: "D2", acuity: 4 },
  { date: "6/26/2019 23:10:09", shift: "B3", acuity: 4 },
  { date: "6/26/2019 23:12:27", shift: "C2", acuity: 4 },
  { date: "6/26/2019 23:30:08", shift: "A3", acuity: 4 },
  { date: "6/26/2019 23:53:16", shift: "D2", acuity: 6 },
  { date: "6/26/2019 23:54:17", shift: "B3", acuity: 4 },
  { date: "6/27/2019 00:01:24", shift: "A3", acuity: 6 },
  { date: "6/27/2019 00:04:17", shift: "D2", acuity: 6 },
  { date: "6/27/2019 00:05:27", shift: "B3", acuity: 4 },
  { date: "6/27/2019 00:11:04", shift: "A3", acuity: 3 },
  { date: "6/27/2019 00:17:08", shift: "D2", acuity: 3 },
  { date: "6/27/2019 00:17:28", shift: "B3", acuity: 4 },
  { date: "6/27/2019 00:17:40", shift: "A3", acuity: 4 },
  { date: "6/27/2019 00:19:06", shift: "D2", acuity: 4 },
  { date: "6/27/2019 00:24:50", shift: "B3", acuity: 3 },
  { date: "6/27/2019 00:33:40", shift: "A3", acuity: 3 },
  { date: "6/27/2019 00:34:37", shift: "D2", acuity: 5 },
  { date: "6/27/2019 00:46:06", shift: "B3", acuity: 4 },
  { date: "6/27/2019 01:07:53", shift: "A3", acuity: 3 },
  { date: "6/27/2019 01:08:00", shift: "D2", acuity: 4 },
  { date: "6/27/2019 01:31:50", shift: "B3", acuity: 4 },
  { date: "6/27/2019 01:35:16", shift: "A3", acuity: 3 },
  { date: "6/27/2019 01:58:06", shift: "B3", acuity: 3 },
  { date: "6/27/2019 02:04:54", shift: "A3", acuity: 3 },
  { date: "6/27/2019 02:05:13", shift: "B3", acuity: 6 },
  { date: "6/27/2019 02:27:08", shift: "A3", acuity: 3 },
  { date: "6/27/2019 02:33:05", shift: "B3", acuity: 3 },
  { date: "6/27/2019 02:48:05", shift: "A3", acuity: 3 },
  { date: "6/27/2019 02:56:24", shift: "B3", acuity: 6 },
  { date: "6/27/2019 03:37:18", shift: "A3", acuity: 4 },
  { date: "6/27/2019 03:43:54", shift: "B3", acuity: 5 },
  { date: "6/27/2019 05:03:24", shift: "A1", acuity: 3 },
  { date: "6/27/2019 05:11:49", shift: "B3", acuity: 6 },
  { date: "6/27/2019 05:22:51", shift: "A1", acuity: 4 },
  { date: "6/27/2019 05:39:05", shift: "A1", acuity: 4 },
  { date: "6/27/2019 05:39:28", shift: "A1", acuity: 4 },
  { date: "6/27/2019 06:00:46", shift: "B1", acuity: 3 },
  { date: "6/27/2019 06:56:10", shift: "B1", acuity: 4 },
  { date: "6/27/2019 07:12:40", shift: "B1", acuity: 6 },
  { date: "6/27/2019 07:16:37", shift: "A1", acuity: 3 },
  { date: "6/27/2019 07:54:48", shift: "B1", acuity: 6 },
  { date: "6/27/2019 08:04:25", shift: "A1", acuity: 3 },
  { date: "6/27/2019 08:08:36", shift: "B1", acuity: 3 },
  { date: "6/27/2019 08:21:51", shift: "A1", acuity: 4 },
  { date: "6/27/2019 09:01:37", shift: "C1", acuity: 3 },
  { date: "6/27/2019 09:01:45", shift: "C1", acuity: 3 },
  { date: "6/27/2019 09:25:30", shift: "C1", acuity: 3 },
  { date: "6/27/2019 09:35:25", shift: "B1", acuity: 3 },
  { date: "6/27/2019 09:35:35", shift: "A1", acuity: 3 },
  { date: "6/27/2019 09:38:19", shift: "C1", acuity: 3 },
  { date: "6/27/2019 10:18:56", shift: "D1", acuity: 3 },
  { date: "6/27/2019 10:24:51", shift: "B1", acuity: 6 },
  { date: "6/27/2019 10:25:11", shift: "A1", acuity: 4 },
  { date: "6/27/2019 10:25:26", shift: "C1", acuity: 3 },
  { date: "6/27/2019 10:36:14", shift: "D1", acuity: 6 },
  { date: "6/27/2019 10:36:30", shift: "D1", acuity: 3 },
  { date: "6/27/2019 10:55:56", shift: "B1", acuity: 3 },
  { date: "6/27/2019 11:16:15", shift: "A1", acuity: 4 },
  { date: "6/27/2019 11:16:33", shift: "C1", acuity: 6 },
  { date: "6/27/2019 11:24:44", shift: "D1", acuity: 3 },
  { date: "6/27/2019 11:30:18", shift: "B1", acuity: 3 },
  { date: "6/27/2019 11:35:22", shift: "A1", acuity: 6 },
  { date: "6/27/2019 12:00:39", shift: "C1", acuity: 3 },
  { date: "6/27/2019 12:02:57", shift: "D1", acuity: 6 },
  { date: "6/27/2019 12:27:34", shift: "B1", acuity: 3 },
  { date: "6/27/2019 12:34:31", shift: "A1", acuity: 6 },
  { date: "6/27/2019 12:35:13", shift: "C1", acuity: 2 },
  { date: "6/27/2019 12:47:27", shift: "D1", acuity: 6 },
  { date: "6/27/2019 13:05:25", shift: "A2", acuity: 6 },
  { date: "6/27/2019 13:16:50", shift: "B1", acuity: 4 },
  { date: "6/27/2019 13:28:37", shift: "C1", acuity: 3 },
  { date: "6/27/2019 13:28:56", shift: "D1", acuity: 3 },
  { date: "6/27/2019 13:29:09", shift: "A2", acuity: 6 },
  { date: "6/27/2019 13:41:39", shift: "A2", acuity: 3 },
  { date: "6/27/2019 13:51:31", shift: "A2", acuity: 6 },
  { date: "6/27/2019 13:59:45", shift: "B1", acuity: 6 },
  { date: "6/27/2019 14:04:19", shift: "B2", acuity: 6 },
  { date: "6/27/2019 14:09:29", shift: "C1", acuity: 6 },
  { date: "6/27/2019 14:38:25", shift: "B2", acuity: 3 },
  { date: "6/27/2019 14:43:21", shift: "B2", acuity: 3 },
  { date: "6/27/2019 14:52:06", shift: "D1", acuity: 3 },
  { date: "6/27/2019 15:17:06", shift: "A2", acuity: 3 },
  { date: "6/27/2019 15:17:32", shift: "C1", acuity: 6 },
  { date: "6/27/2019 15:17:50", shift: "B2", acuity: 6 },
  { date: "6/27/2019 15:19:40", shift: "D1", acuity: 6 },
  { date: "6/27/2019 15:34:03", shift: "A2", acuity: 3 },
  { date: "6/27/2019 15:39:43", shift: "C1", acuity: 6 },
  { date: "6/27/2019 15:45:46", shift: "B2", acuity: 2 },
  { date: "6/27/2019 15:57:24", shift: "D1", acuity: 6 },
  { date: "6/27/2019 16:10:06", shift: "A2", acuity: 6 },
  { date: "6/27/2019 16:18:00", shift: "C1", acuity: 6 },
  { date: "6/27/2019 16:39:21", shift: "C2", acuity: 3 },
  { date: "6/27/2019 16:48:49", shift: "B2", acuity: 3 },
  { date: "6/27/2019 17:03:33", shift: "C2", acuity: 3 },
  { date: "6/27/2019 17:04:21", shift: "C2", acuity: 3 },
  { date: "6/27/2019 17:19:51", shift: "D1", acuity: 6 },
  { date: "6/27/2019 17:20:25", shift: "A2", acuity: 3 },
  { date: "6/27/2019 17:28:37", shift: "B2", acuity: 3 },
  { date: "6/27/2019 17:29:07", shift: "C2", acuity: 6 },
  { date: "6/27/2019 17:38:46", shift: "D1", acuity: 3 },
  { date: "6/27/2019 17:54:19", shift: "A2", acuity: 6 },
  { date: "6/27/2019 18:01:35", shift: "D2", acuity: 3 },
  { date: "6/27/2019 18:16:38", shift: "B2", acuity: 4 },
  { date: "6/27/2019 18:17:40", shift: "C2", acuity: 6 },
  { date: "6/27/2019 18:30:38", shift: "D2", acuity: 6 },
  { date: "6/27/2019 18:36:30", shift: "D2", acuity: 3 },
  { date: "6/27/2019 18:36:47", shift: "A2", acuity: 3 },
  { date: "6/27/2019 18:39:32", shift: "B2", acuity: 3 },
  { date: "6/27/2019 18:56:31", shift: "C2", acuity: 3 },
  { date: "6/27/2019 19:13:10", shift: "D2", acuity: 3 },
  { date: "6/27/2019 19:13:28", shift: "A2", acuity: 6 },
  { date: "6/27/2019 19:19:55", shift: "B2", acuity: 3 },
  { date: "6/27/2019 19:20:11", shift: "C2", acuity: 3 },
  { date: "6/27/2019 19:33:47", shift: "D2", acuity: 4 },
  { date: "6/27/2019 19:36:47", shift: "A2", acuity: 3 },
  { date: "6/27/2019 19:42:35", shift: "B2", acuity: 3 },
  { date: "6/27/2019 19:42:49", shift: "C2", acuity: 3 },
  { date: "6/27/2019 20:00:16", shift: "D2", acuity: 6 },
  { date: "6/27/2019 20:08:43", shift: "A2", acuity: 3 },
  { date: "6/27/2019 20:14:42", shift: "B2", acuity: 6 },
  { date: "6/27/2019 20:15:06", shift: "C2", acuity: 6 },
  { date: "6/27/2019 20:25:13", shift: "D2", acuity: 3 },
  { date: "6/27/2019 20:25:32", shift: "A2", acuity: 3 },
  { date: "6/27/2019 20:38:41", shift: "B2", acuity: 2 },
  { date: "6/27/2019 20:44:38", shift: "C2", acuity: 3 },
  { date: "6/27/2019 20:47:33", shift: "D2", acuity: 3 },
  { date: "6/27/2019 21:05:18", shift: "A3", acuity: 3 },
  { date: "6/27/2019 21:05:52", shift: "B2", acuity: 3 },
  { date: "6/27/2019 21:16:23", shift: "C2", acuity: 3 },
  { date: "6/27/2019 21:27:45", shift: "D2", acuity: 6 },
  { date: "6/27/2019 21:37:01", shift: "A3", acuity: 6 },
  { date: "6/27/2019 21:48:06", shift: "A3", acuity: 3 },
  { date: "6/27/2019 21:54:29", shift: "B2", acuity: 6 },
  { date: "6/27/2019 21:54:43", shift: "C2", acuity: 3 },
  { date: "6/27/2019 21:59:19", shift: "D2", acuity: 6 },
  { date: "6/27/2019 22:15:46", shift: "B3", acuity: 6 },
  { date: "6/27/2019 22:18:48", shift: "A3", acuity: 4 },
  { date: "6/27/2019 22:19:04", shift: "C2", acuity: 3 },
  { date: "6/27/2019 22:22:56", shift: "D2", acuity: 3 },
  { date: "6/27/2019 22:23:13", shift: "B3", acuity: 4 },
  { date: "6/27/2019 22:31:25", shift: "B3", acuity: 4 },
  { date: "6/27/2019 22:46:57", shift: "B3", acuity: 3 },
  { date: "6/27/2019 22:47:23", shift: "A3", acuity: 4 },
  { date: "6/27/2019 22:47:38", shift: "C2", acuity: 6 },
  { date: "6/27/2019 22:52:26", shift: "D2", acuity: 4 },
  { date: "6/27/2019 22:55:11", shift: "B3", acuity: 3 },
  { date: "6/27/2019 23:05:26", shift: "A3", acuity: 6 },
  { date: "6/27/2019 23:13:53", shift: "C2", acuity: 3 },
  { date: "6/27/2019 23:18:11", shift: "D2", acuity: 3 },
  { date: "6/27/2019 23:18:23", shift: "B3", acuity: 3 },
  { date: "6/27/2019 23:36:57", shift: "A3", acuity: 3 },
  { date: "6/27/2019 23:37:10", shift: "C2", acuity: 4 },
  { date: "6/27/2019 23:49:25", shift: "D2", acuity: 6 },
  { date: "6/27/2019 23:51:57", shift: "B3", acuity: 3 },
  { date: "6/27/2019 23:57:37", shift: "A3", acuity: 3 },
  { date: "6/28/2019 00:07:22", shift: "D2", acuity: 3 },
  { date: "6/28/2019 00:07:47", shift: "B3", acuity: 4 },
  { date: "6/28/2019 00:12:13", shift: "A3", acuity: 3 },
  { date: "6/28/2019 00:20:29", shift: "D2", acuity: 3 },
  { date: "6/28/2019 00:26:08", shift: "B3", acuity: 2 },
  { date: "6/28/2019 00:47:58", shift: "A3", acuity: 4 },
  { date: "6/28/2019 00:54:41", shift: "D2", acuity: 3 },
  { date: "6/28/2019 01:05:08", shift: "B3", acuity: 4 },
  { date: "6/28/2019 01:25:49", shift: "A3", acuity: 3 },
  { date: "6/28/2019 01:42:28", shift: "B3", acuity: 4 },
  { date: "6/28/2019 01:50:18", shift: "A3", acuity: 6 },
  { date: "6/28/2019 02:08:12", shift: "B3", acuity: 3 },
  { date: "6/28/2019 02:19:56", shift: "A3", acuity: 3 },
  { date: "6/28/2019 02:20:08", shift: "B3", acuity: 5 },
  { date: "6/28/2019 02:26:32", shift: "A3", acuity: 3 },
  { date: "6/28/2019 02:39:27", shift: "B3", acuity: 3 },
  { date: "6/28/2019 02:39:46", shift: "A3", acuity: 6 },
  { date: "6/28/2019 03:45:48", shift: "B3", acuity: 6 },
  { date: "6/28/2019 04:02:10", shift: "A3", acuity: 3 },
  { date: "6/28/2019 04:26:51", shift: "B3", acuity: 6 },
  { date: "6/28/2019 04:27:21", shift: "A3", acuity: 4 },
  { date: "6/28/2019 05:07:16", shift: "A1", acuity: 3 },
  { date: "6/28/2019 05:45:11", shift: "A1", acuity: 3 },
  { date: "6/28/2019 05:45:25", shift: "A1", acuity: 6 },
  { date: "6/28/2019 06:00:22", shift: "B1", acuity: 3 },
  { date: "6/28/2019 06:16:34", shift: "A1", acuity: 6 },
  { date: "6/28/2019 06:25:40", shift: "B1", acuity: 3 },
  { date: "6/28/2019 06:45:40", shift: "B1", acuity: 6 },
  { date: "6/28/2019 07:15:15", shift: "B1", acuity: 6 },
  { date: "6/28/2019 07:18:01", shift: "A1", acuity: 3 },
  { date: "6/28/2019 07:29:43", shift: "B1", acuity: 4 },
  { date: "6/28/2019 07:38:31", shift: "A1", acuity: 3 },
  { date: "6/28/2019 08:05:06", shift: "B1", acuity: 4 },
  { date: "6/28/2019 08:05:25", shift: "A1", acuity: 6 },
  { date: "6/28/2019 08:58:46", shift: "C1", acuity: 6 },
  { date: "6/28/2019 08:59:00", shift: "B1", acuity: 3 },
  { date: "6/28/2019 09:19:30", shift: "C1", acuity: 3 },
  { date: "6/28/2019 09:19:48", shift: "C1", acuity: 4 },
  { date: "6/28/2019 09:20:12", shift: "A1", acuity: 3 },
  { date: "6/28/2019 09:33:55", shift: "B1", acuity: 6 },
  { date: "6/28/2019 09:46:51", shift: "C1", acuity: 6 },
  { date: "6/28/2019 09:53:37", shift: "A1", acuity: 6 },
  { date: "6/28/2019 09:54:16", shift: "B1", acuity: 6 },
  { date: "6/28/2019 10:10:11", shift: "D1", acuity: 6 },
  { date: "6/28/2019 10:17:08", shift: "C1", acuity: 6 },
  { date: "6/28/2019 10:21:35", shift: "A1", acuity: 6 },
  { date: "6/28/2019 10:32:45", shift: "D1", acuity: 3 },
  { date: "6/28/2019 11:10:08", shift: "D1", acuity: 3 },
  { date: "6/28/2019 11:10:56", shift: "B1", acuity: 6 },
  { date: "6/28/2019 11:37:13", shift: "C1", acuity: 6 },
  { date: "6/28/2019 11:37:21", shift: "A1", acuity: 3 },
  { date: "6/28/2019 11:54:24", shift: "D1", acuity: 3 },
  { date: "6/28/2019 12:01:53", shift: "B1", acuity: 6 },
  { date: "6/28/2019 12:08:22", shift: "C1", acuity: 6 },
  { date: "6/28/2019 12:08:36", shift: "A1", acuity: 3 },
  { date: "6/28/2019 12:31:13", shift: "D1", acuity: 3 },
  { date: "6/28/2019 12:31:29", shift: "B1", acuity: 3 },
  { date: "6/28/2019 12:45:33", shift: "C1", acuity: 3 },
  { date: "6/28/2019 12:54:52", shift: "A1", acuity: 6 },
  { date: "6/28/2019 12:55:32", shift: "D1", acuity: 3 },
  { date: "6/28/2019 12:55:53", shift: "B1", acuity: 6 },
  { date: "6/28/2019 13:23:18", shift: "A2", acuity: 3 },
  { date: "6/28/2019 13:29:52", shift: "C1", acuity: 7 },
  { date: "6/28/2019 13:32:30", shift: "A2", acuity: 3 },
  { date: "6/28/2019 13:42:14", shift: "A2", acuity: 3 },
  { date: "6/28/2019 13:42:57", shift: "D1", acuity: 6 },
  { date: "6/28/2019 13:49:24", shift: "B1", acuity: 3 },
  { date: "6/28/2019 13:52:45", shift: "C1", acuity: 6 },
  { date: "6/28/2019 14:08:05", shift: "B2", acuity: 6 },
  { date: "6/28/2019 14:29:42", shift: "A2", acuity: 3 },
  { date: "6/28/2019 14:32:19", shift: "B2", acuity: 3 },
  { date: "6/28/2019 14:36:39", shift: "B2", acuity: 3 },
  { date: "6/28/2019 14:37:00", shift: "D1", acuity: 3 },
  { date: "6/28/2019 14:41:08", shift: "C1", acuity: 6 },
  { date: "6/28/2019 15:05:32", shift: "A2", acuity: 3 },
  { date: "6/28/2019 15:18:54", shift: "B2", acuity: 6 },
  { date: "6/28/2019 15:26:11", shift: "D1", acuity: 3 },
  { date: "6/28/2019 15:26:41", shift: "C1", acuity: 6 },
  { date: "6/28/2019 15:38:35", shift: "A2", acuity: 6 },
  { date: "6/28/2019 15:47:24", shift: "B2", acuity: 6 },
  { date: "6/28/2019 15:58:24", shift: "D1", acuity: 6 },
  { date: "6/28/2019 15:58:55", shift: "C1", acuity: 3 },
  { date: "6/28/2019 15:59:58", shift: "A2", acuity: 3 },
  { date: "6/28/2019 16:02:13", shift: "B2", acuity: 6 },
  { date: "6/28/2019 16:23:02", shift: "D1", acuity: 6 },
  { date: "6/28/2019 16:37:39", shift: "C2", acuity: 6 },
  { date: "6/28/2019 16:37:57", shift: "A2", acuity: 3 },
  { date: "6/28/2019 16:42:24", shift: "B2", acuity: 3 },
  { date: "6/28/2019 17:02:23", shift: "C2", acuity: 3 },
  { date: "6/28/2019 17:09:25", shift: "C2", acuity: 3 },
  { date: "6/28/2019 17:30:00", shift: "D1", acuity: 3 },
  { date: "6/28/2019 17:44:38", shift: "A2", acuity: 3 },
  { date: "6/28/2019 17:56:03", shift: "B2", acuity: 2 },
  { date: "6/28/2019 17:56:59", shift: "C2", acuity: 3 },
  { date: "6/28/2019 18:00:54", shift: "D2", acuity: 6 },
  { date: "6/28/2019 18:08:42", shift: "A2", acuity: 3 },
  { date: "6/28/2019 18:20:12", shift: "B2", acuity: 3 },
  { date: "6/28/2019 18:29:53", shift: "C2", acuity: 4 },
  { date: "6/28/2019 18:46:44", shift: "D2", acuity: 4 },
  { date: "6/28/2019 18:48:39", shift: "D2", acuity: 3 },
  { date: "6/28/2019 18:49:06", shift: "A2", acuity: 3 },
  { date: "6/28/2019 18:50:56", shift: "B2", acuity: 6 },
  { date: "6/28/2019 18:51:33", shift: "C2", acuity: 3 },
  { date: "6/28/2019 19:04:25", shift: "D2", acuity: 6 },
  { date: "6/28/2019 19:08:26", shift: "A2", acuity: 6 },
  { date: "6/28/2019 19:14:19", shift: "B2", acuity: 3 },
  { date: "6/28/2019 19:23:37", shift: "C2", acuity: 6 },
  { date: "6/28/2019 19:23:49", shift: "D2", acuity: 3 },
  { date: "6/28/2019 19:43:02", shift: "A2", acuity: 3 },
  { date: "6/28/2019 19:46:32", shift: "B2", acuity: 3 },
  { date: "6/28/2019 20:13:28", shift: "C2", acuity: 3 },
  { date: "6/28/2019 20:13:45", shift: "D2", acuity: 3 },
  { date: "6/28/2019 20:32:21", shift: "A2", acuity: 4 },
  { date: "6/28/2019 20:40:52", shift: "B2", acuity: 6 },
  { date: "6/28/2019 20:48:52", shift: "C2", acuity: 4 },
  { date: "6/28/2019 20:54:13", shift: "D2", acuity: 3 },
  { date: "6/28/2019 21:07:36", shift: "A3", acuity: 3 },
  { date: "6/28/2019 21:13:15", shift: "B2", acuity: 3 },
  { date: "6/28/2019 21:18:16", shift: "C2", acuity: 6 },
  { date: "6/28/2019 21:21:03", shift: "D2", acuity: 6 },
  { date: "6/28/2019 21:27:03", shift: "A3", acuity: 6 },
  { date: "6/28/2019 21:36:13", shift: "A3", acuity: 6 },
  { date: "6/28/2019 22:02:39", shift: "A3", acuity: 6 },
  { date: "6/28/2019 22:13:26", shift: "B3", acuity: 6 },
  { date: "6/28/2019 22:18:15", shift: "C2", acuity: 6 },
  { date: "6/28/2019 22:34:50", shift: "B3", acuity: 6 },
  { date: "6/28/2019 22:35:39", shift: "B3", acuity: 6 },
  { date: "6/28/2019 22:36:55", shift: "D2", acuity: 3 },
  { date: "6/28/2019 22:42:01", shift: "A3", acuity: 6 },
  { date: "6/28/2019 23:00:44", shift: "C2", acuity: 3 },
  { date: "6/28/2019 23:01:01", shift: "B3", acuity: 6 },
  { date: "6/28/2019 23:01:28", shift: "D2", acuity: 3 },
  { date: "6/28/2019 23:02:45", shift: "A3", acuity: 3 },
  { date: "6/28/2019 23:04:58", shift: "C2", acuity: 6 },
  { date: "6/28/2019 23:30:53", shift: "B3", acuity: 3 },
  { date: "6/28/2019 23:37:06", shift: "D2", acuity: 4 },
  { date: "6/28/2019 23:39:41", shift: "A3", acuity: 3 },
  { date: "6/28/2019 23:49:46", shift: "C2", acuity: 3 },
  { date: "6/28/2019 23:50:01", shift: "B3", acuity: 3 },
  { date: "6/29/2019 00:00:41", shift: "D2", acuity: 3 },
  { date: "6/29/2019 00:21:36", shift: "A3", acuity: 3 },
  { date: "6/29/2019 00:22:33", shift: "B3", acuity: 4 },
  { date: "6/29/2019 00:23:43", shift: "D2", acuity: 3 },
  { date: "6/29/2019 00:39:21", shift: "A3", acuity: 3 },
  { date: "6/29/2019 00:41:10", shift: "B3", acuity: 4 },
  { date: "6/29/2019 00:52:04", shift: "D2", acuity: 3 },
  { date: "6/29/2019 00:53:11", shift: "A3", acuity: 3 },
  { date: "6/29/2019 00:59:18", shift: "B3", acuity: 4 },
  { date: "6/29/2019 01:06:53", shift: "D2", acuity: 4 },
  { date: "6/29/2019 01:27:40", shift: "A3", acuity: 4 },
  { date: "6/29/2019 01:38:53", shift: "B3", acuity: 3 },
  { date: "6/29/2019 01:39:07", shift: "A3", acuity: 4 },
  { date: "6/29/2019 02:03:43", shift: "B3", acuity: 3 },
  { date: "6/29/2019 02:19:39", shift: "A3", acuity: 4 },
  { date: "6/29/2019 02:54:34", shift: "B3", acuity: 4 },
  { date: "6/29/2019 03:22:21", shift: "A3", acuity: 3 },
  { date: "6/29/2019 03:32:43", shift: "B3", acuity: 4 },
  { date: "6/29/2019 03:33:33", shift: "A3", acuity: 3 },
  { date: "6/29/2019 03:50:50", shift: "B3", acuity: 3 },
  { date: "6/29/2019 04:07:55", shift: "A3", acuity: 3 },
  { date: "6/29/2019 04:22:16", shift: "B3", acuity: 3 },
  { date: "6/29/2019 04:42:47", shift: "A3", acuity: 3 },
  { date: "6/29/2019 04:55:03", shift: "B3", acuity: 4 },
  { date: "6/29/2019 05:08:10", shift: "A1", acuity: 4 },
  { date: "6/29/2019 05:30:29", shift: "A1", acuity: 3 },
  { date: "6/29/2019 05:36:34", shift: "A1", acuity: 6 },
  { date: "6/29/2019 05:46:47", shift: "B3", acuity: 3 },
  { date: "6/29/2019 06:00:31", shift: "B1", acuity: 3 },
  { date: "6/29/2019 06:00:59", shift: "A1", acuity: 3 },
  { date: "6/29/2019 06:07:15", shift: "B1", acuity: 6 },
  { date: "6/29/2019 06:30:42", shift: "B1", acuity: 3 },
  { date: "6/29/2019 06:36:39", shift: "B1", acuity: 4 },
  { date: "6/29/2019 06:38:36", shift: "A1", acuity: 6 },
  { date: "6/29/2019 07:19:11", shift: "B1", acuity: 3 },
  { date: "6/29/2019 07:24:28", shift: "A1", acuity: 3 },
  { date: "6/29/2019 07:32:27", shift: "B1", acuity: 3 },
  { date: "6/29/2019 07:46:37", shift: "A1", acuity: 3 },
  { date: "6/29/2019 07:52:26", shift: "B1", acuity: 6 },
  { date: "6/29/2019 07:56:12", shift: "A1", acuity: 3 },
  { date: "6/29/2019 08:10:32", shift: "B1", acuity: 3 },
  { date: "6/29/2019 08:17:12", shift: "A1", acuity: 3 },
  { date: "6/29/2019 08:41:51", shift: "C1", acuity: 6 },
  { date: "6/29/2019 08:42:05", shift: "B1", acuity: 3 },
  { date: "6/29/2019 09:05:28", shift: "C1", acuity: 6 },
  { date: "6/29/2019 09:16:40", shift: "C1", acuity: 4 },
  { date: "6/29/2019 09:16:52", shift: "A1", acuity: 3 },
  { date: "6/29/2019 09:23:56", shift: "B1", acuity: 4 },
  { date: "6/29/2019 09:38:06", shift: "C1", acuity: 3 },
  { date: "6/29/2019 09:46:21", shift: "A1", acuity: 6 },
  { date: "6/29/2019 10:06:06", shift: "D1", acuity: 3 },
  { date: "6/29/2019 10:06:24", shift: "B1", acuity: 3 },
  { date: "6/29/2019 10:06:38", shift: "C1", acuity: 4 },
  { date: "6/29/2019 10:11:01", shift: "A1", acuity: 3 },
  { date: "6/29/2019 10:21:12", shift: "D1", acuity: 4 },
  { date: "6/29/2019 10:22:52", shift: "B1", acuity: 3 },
  { date: "6/29/2019 10:32:09", shift: "D1", acuity: 6 },
  { date: "6/29/2019 10:35:05", shift: "D1", acuity: 6 },
  { date: "6/29/2019 10:41:20", shift: "C1", acuity: 3 },
  { date: "6/29/2019 10:48:26", shift: "A1", acuity: 4 },
  { date: "6/29/2019 11:01:05", shift: "B1", acuity: 6 },
  { date: "6/29/2019 11:05:00", shift: "D1", acuity: 6 },
  { date: "6/29/2019 11:22:34", shift: "C1", acuity: 3 },
  { date: "6/29/2019 11:34:27", shift: "A1", acuity: 3 },
  { date: "6/29/2019 11:34:43", shift: "B1", acuity: 6 },
  { date: "6/29/2019 11:47:32", shift: "D1", acuity: 6 },
  { date: "6/29/2019 11:50:27", shift: "C1", acuity: 3 },
  { date: "6/29/2019 12:05:59", shift: "A1", acuity: 3 },
  { date: "6/29/2019 12:06:23", shift: "B1", acuity: 3 },
  { date: "6/29/2019 12:20:19", shift: "D1", acuity: 3 },
  { date: "6/29/2019 12:32:53", shift: "C1", acuity: 6 },
  { date: "6/29/2019 12:33:14", shift: "A1", acuity: 3 },
  { date: "6/29/2019 12:35:46", shift: "B1", acuity: 3 },
  { date: "6/29/2019 12:36:04", shift: "D1", acuity: 3 },
  { date: "6/29/2019 12:36:28", shift: "C1", acuity: 6 },
  { date: "6/29/2019 12:51:38", shift: "A1", acuity: 3 },
  { date: "6/29/2019 12:54:52", shift: "B1", acuity: 6 },
  { date: "6/29/2019 13:00:20", shift: "A2", acuity: 3 },
  { date: "6/29/2019 13:04:35", shift: "D1", acuity: 3 },
  { date: "6/29/2019 13:10:36", shift: "C1", acuity: 3 },
  { date: "6/29/2019 13:14:54", shift: "B1", acuity: 6 },
  { date: "6/29/2019 13:20:26", shift: "A2", acuity: 3 },
  { date: "6/29/2019 13:30:15", shift: "A2", acuity: 3 },
  { date: "6/29/2019 13:30:22", shift: "A2", acuity: 4 },
  { date: "6/29/2019 13:33:36", shift: "D1", acuity: 6 },
  { date: "6/29/2019 13:37:42", shift: "C1", acuity: 6 },
  { date: "6/29/2019 14:06:05", shift: "B2", acuity: 3 },
  { date: "6/29/2019 14:06:16", shift: "A2", acuity: 3 },
  { date: "6/29/2019 14:35:06", shift: "B2", acuity: 3 },
  { date: "6/29/2019 14:43:52", shift: "B2", acuity: 4 },
  { date: "6/29/2019 15:17:31", shift: "D1", acuity: 3 },
  { date: "6/29/2019 15:31:02", shift: "C1", acuity: 6 },
  { date: "6/29/2019 15:43:31", shift: "A2", acuity: 3 },
  { date: "6/29/2019 15:43:45", shift: "B2", acuity: 3 },
  { date: "6/29/2019 15:44:00", shift: "D1", acuity: 6 },
  { date: "6/29/2019 16:18:58", shift: "C1", acuity: 2 },
  { date: "6/29/2019 16:20:41", shift: "A2", acuity: 3 },
  { date: "6/29/2019 16:22:21", shift: "B2", acuity: 3 },
  { date: "6/29/2019 16:28:58", shift: "D1", acuity: 6 },
  { date: "6/29/2019 16:44:25", shift: "C2", acuity: 3 },
  { date: "6/29/2019 16:44:40", shift: "A2", acuity: 3 },
  { date: "6/29/2019 16:47:46", shift: "B2", acuity: 4 },
  { date: "6/29/2019 16:53:42", shift: "D1", acuity: 3 },
  { date: "6/29/2019 17:00:42", shift: "C2", acuity: 3 },
  { date: "6/29/2019 17:03:08", shift: "C2", acuity: 3 },
  { date: "6/29/2019 17:13:27", shift: "A2", acuity: 6 },
  { date: "6/29/2019 17:16:18", shift: "B2", acuity: 3 },
  { date: "6/29/2019 17:19:55", shift: "D1", acuity: 4 },
  { date: "6/29/2019 17:22:11", shift: "C2", acuity: 3 },
  { date: "6/29/2019 17:30:14", shift: "A2", acuity: 6 },
  { date: "6/29/2019 17:56:02", shift: "B2", acuity: 3 },
  { date: "6/29/2019 18:10:07", shift: "D2", acuity: 3 },
  { date: "6/29/2019 18:10:21", shift: "C2", acuity: 3 },
  { date: "6/29/2019 18:13:22", shift: "A2", acuity: 3 },
  { date: "6/29/2019 18:30:29", shift: "D2", acuity: 3 },
  { date: "6/29/2019 18:30:52", shift: "D2", acuity: 4 },
  { date: "6/29/2019 18:40:17", shift: "B2", acuity: 3 },
  { date: "6/29/2019 18:50:12", shift: "C2", acuity: 6 },
  { date: "6/29/2019 18:50:26", shift: "A2", acuity: 6 },
  { date: "6/29/2019 19:02:45", shift: "D2", acuity: 3 },
  { date: "6/29/2019 19:14:09", shift: "B2", acuity: 3 },
  { date: "6/29/2019 19:24:03", shift: "C2", acuity: 4 },
  { date: "6/29/2019 19:38:33", shift: "A2", acuity: 6 },
  { date: "6/29/2019 19:39:55", shift: "D2", acuity: 4 },
  { date: "6/29/2019 20:03:52", shift: "B2", acuity: 3 },
  { date: "6/29/2019 20:10:25", shift: "C2", acuity: 3 },
  { date: "6/29/2019 20:10:32", shift: "A2", acuity: 3 },
  { date: "6/29/2019 20:10:45", shift: "D2", acuity: 3 },
  { date: "6/29/2019 20:28:08", shift: "B2", acuity: 3 },
  { date: "6/29/2019 20:30:14", shift: "C2", acuity: 3 },
  { date: "6/29/2019 20:46:24", shift: "A2", acuity: 3 },
  { date: "6/29/2019 20:59:09", shift: "D2", acuity: 6 },
  { date: "6/29/2019 21:07:46", shift: "A3", acuity: 3 },
  { date: "6/29/2019 21:08:07", shift: "B2", acuity: 3 },
  { date: "6/29/2019 21:08:23", shift: "C2", acuity: 3 },
  { date: "6/29/2019 21:27:43", shift: "D2", acuity: 6 },
  { date: "6/29/2019 21:38:43", shift: "A3", acuity: 3 },
  { date: "6/29/2019 21:59:14", shift: "A3", acuity: 3 },
  { date: "6/29/2019 22:09:18", shift: "B3", acuity: 4 },
  { date: "6/29/2019 22:20:19", shift: "C2", acuity: 6 },
  { date: "6/29/2019 22:26:04", shift: "D2", acuity: 6 },
  { date: "6/29/2019 22:26:13", shift: "A3", acuity: 6 },
  { date: "6/29/2019 22:48:02", shift: "B3", acuity: 3 },
  { date: "6/29/2019 23:05:04", shift: "B3", acuity: 3 },
  { date: "6/29/2019 23:05:13", shift: "C2", acuity: 3 },
  { date: "6/29/2019 23:06:14", shift: "D2", acuity: 6 },
  { date: "6/29/2019 23:06:32", shift: "A3", acuity: 3 },
  { date: "6/29/2019 23:12:55", shift: "B3", acuity: 3 },
  { date: "6/29/2019 23:13:36", shift: "C2", acuity: 3 },
  { date: "6/29/2019 23:33:11", shift: "D2", acuity: 3 },
  { date: "6/29/2019 23:41:03", shift: "A3", acuity: 3 },
  { date: "6/29/2019 23:48:53", shift: "B3", acuity: 4 },
  { date: "6/30/2019 00:06:59", shift: "D2", acuity: 4 },
  { date: "6/30/2019 00:11:34", shift: "A3", acuity: 3 },
  { date: "6/30/2019 00:18:55", shift: "B3", acuity: 3 },
  { date: "6/30/2019 00:21:15", shift: "D2", acuity: 4 },
  { date: "6/30/2019 00:22:22", shift: "A3", acuity: 3 },
  { date: "6/30/2019 00:28:34", shift: "B3", acuity: 5 },
  { date: "6/30/2019 00:35:22", shift: "D2", acuity: 6 },
  { date: "6/30/2019 00:46:11", shift: "A3", acuity: 4 },
  { date: "6/30/2019 00:58:48", shift: "B3", acuity: 6 },
  { date: "6/30/2019 01:01:56", shift: "D2", acuity: 3 },
  { date: "6/30/2019 01:34:18", shift: "A3", acuity: 4 },
  { date: "6/30/2019 01:40:06", shift: "B3", acuity: 3 },
  { date: "6/30/2019 01:40:23", shift: "A3", acuity: 4 },
  { date: "6/30/2019 01:40:34", shift: "B3", acuity: 3 },
  { date: "6/30/2019 01:48:49", shift: "A3", acuity: 6 },
  { date: "6/30/2019 01:58:51", shift: "B3", acuity: 3 },
  { date: "6/30/2019 01:59:06", shift: "A3", acuity: 3 },
  { date: "6/30/2019 01:59:21", shift: "B3", acuity: 3 },
  { date: "6/30/2019 02:16:09", shift: "A3", acuity: 3 },
  { date: "6/30/2019 02:25:55", shift: "B3", acuity: 4 },
  { date: "6/30/2019 02:26:09", shift: "A3", acuity: 6 },
  { date: "6/30/2019 02:46:43", shift: "B3", acuity: 4 },
  { date: "6/30/2019 03:08:55", shift: "A3", acuity: 4 },
  { date: "6/30/2019 03:21:55", shift: "B3", acuity: 3 },
  { date: "6/30/2019 03:22:07", shift: "A3", acuity: 3 },
  { date: "6/30/2019 03:44:27", shift: "B3", acuity: 4 },
  { date: "6/30/2019 03:44:49", shift: "A3", acuity: 4 },
  { date: "6/30/2019 04:15:27", shift: "B3", acuity: 3 },
  { date: "6/30/2019 04:18:25", shift: "A3", acuity: 6 },
  { date: "6/30/2019 04:26:22", shift: "B3", acuity: 6 },
  { date: "6/30/2019 04:35:55", shift: "A3", acuity: 4 },
  { date: "6/30/2019 04:40:26", shift: "B3", acuity: 6 },
  { date: "6/30/2019 04:47:29", shift: "A3", acuity: 4 },
  { date: "6/30/2019 05:00:15", shift: "A1", acuity: 3 },
  { date: "6/30/2019 05:00:30", shift: "B3", acuity: 4 },
  { date: "6/30/2019 05:18:17", shift: "A1", acuity: 3 },
  { date: "6/30/2019 05:27:01", shift: "B3", acuity: 4 },
  { date: "6/30/2019 05:32:45", shift: "A1", acuity: 3 },
  { date: "6/30/2019 05:45:59", shift: "A1", acuity: 3 },
  { date: "6/30/2019 05:48:20", shift: "B3", acuity: 6 },
  { date: "6/30/2019 05:54:51", shift: "A1", acuity: 6 },
  { date: "6/30/2019 06:20:15", shift: "B1", acuity: 4 },
  { date: "6/30/2019 06:20:35", shift: "A1", acuity: 4 },
  { date: "6/30/2019 06:35:59", shift: "B1", acuity: 3 },
  { date: "6/30/2019 06:55:52", shift: "B1", acuity: 3 },
  { date: "6/30/2019 07:27:20", shift: "A1", acuity: 3 },
  { date: "6/30/2019 07:31:05", shift: "B1", acuity: 6 },
  { date: "6/30/2019 07:31:22", shift: "A1", acuity: 3 },
  { date: "6/30/2019 07:56:52", shift: "B1", acuity: 3 },
  { date: "6/30/2019 08:10:43", shift: "A1", acuity: 3 },
  { date: "6/30/2019 08:36:06", shift: "C1", acuity: 4 },
  { date: "6/30/2019 08:39:32", shift: "B1", acuity: 3 },
  { date: "6/30/2019 08:39:54", shift: "A1", acuity: 3 },
  { date: "6/30/2019 08:48:24", shift: "C1", acuity: 6 },
  { date: "6/30/2019 08:48:40", shift: "B1", acuity: 3 },
  { date: "6/30/2019 09:12:43", shift: "C1", acuity: 6 },
  { date: "6/30/2019 09:13:03", shift: "C1", acuity: 3 },
  { date: "6/30/2019 09:21:27", shift: "A1", acuity: 4 },
  { date: "6/30/2019 09:30:59", shift: "B1", acuity: 4 },
  { date: "6/30/2019 09:31:16", shift: "C1", acuity: 3 },
  { date: "6/30/2019 09:39:09", shift: "A1", acuity: 3 },
  { date: "6/30/2019 09:46:14", shift: "B1", acuity: 3 },
  { date: "6/30/2019 10:01:39", shift: "D1", acuity: 6 },
  { date: "6/30/2019 10:13:15", shift: "C1", acuity: 3 },
  { date: "6/30/2019 10:15:02", shift: "A1", acuity: 3 },
  { date: "6/30/2019 10:31:27", shift: "D1", acuity: 6 },
  { date: "6/30/2019 10:31:45", shift: "D1", acuity: 3 },
  { date: "6/30/2019 10:37:23", shift: "B1", acuity: 3 },
  { date: "6/30/2019 10:41:22", shift: "C1", acuity: 3 },
  { date: "6/30/2019 11:18:44", shift: "A1", acuity: 4 },
  { date: "6/30/2019 11:25:44", shift: "D1", acuity: 3 },
  { date: "6/30/2019 11:31:03", shift: "B1", acuity: 3 },
  { date: "6/30/2019 11:39:18", shift: "C1", acuity: 3 },
  { date: "6/30/2019 11:49:49", shift: "A1", acuity: 6 },
  { date: "6/30/2019 12:00:38", shift: "D1", acuity: 3 },
  { date: "6/30/2019 12:01:11", shift: "B1", acuity: 6 },
  { date: "6/30/2019 12:20:59", shift: "C1", acuity: 3 },
  { date: "6/30/2019 12:26:31", shift: "A1", acuity: 6 },
  { date: "6/30/2019 12:33:30", shift: "D1", acuity: 3 },
  { date: "6/30/2019 12:42:50", shift: "B1", acuity: 6 },
  { date: "6/30/2019 12:43:10", shift: "C1", acuity: 3 },
  { date: "6/30/2019 12:53:25", shift: "A1", acuity: 3 },
  { date: "6/30/2019 13:02:21", shift: "A2", acuity: 3 },
  { date: "6/30/2019 13:09:13", shift: "D1", acuity: 3 },
  { date: "6/30/2019 13:27:05", shift: "B1", acuity: 6 },
  { date: "6/30/2019 13:38:20", shift: "A2", acuity: 6 },
  { date: "6/30/2019 14:08:40", shift: "A2", acuity: 3 },
  { date: "6/30/2019 14:09:00", shift: "B2", acuity: 3 },
  { date: "6/30/2019 14:09:13", shift: "C1", acuity: 3 },
  { date: "6/30/2019 14:21:29", shift: "D1", acuity: 4 },
  { date: "6/30/2019 14:26:33", shift: "A2", acuity: 3 },
  { date: "6/30/2019 14:26:52", shift: "B2", acuity: 6 },
  { date: "6/30/2019 14:34:21", shift: "B2", acuity: 6 },
  { date: "6/30/2019 14:37:42", shift: "B2", acuity: 6 },
  { date: "6/30/2019 14:56:58", shift: "C1", acuity: 3 },
  { date: "6/30/2019 14:57:23", shift: "D1", acuity: 3 },
  { date: "6/30/2019 15:08:01", shift: "A2", acuity: 6 },
  { date: "6/30/2019 15:16:04", shift: "B2", acuity: 4 },
  { date: "6/30/2019 15:16:29", shift: "C1", acuity: 3 },
  { date: "6/30/2019 15:20:41", shift: "D1", acuity: 3 },
  { date: "6/30/2019 15:25:10", shift: "A2", acuity: 6 },
  { date: "6/30/2019 15:36:22", shift: "B2", acuity: 3 },
  { date: "6/30/2019 15:42:24", shift: "C1", acuity: 3 },
  { date: "6/30/2019 15:42:44", shift: "D1", acuity: 3 },
  { date: "6/30/2019 15:43:02", shift: "A2", acuity: 3 },
  { date: "6/30/2019 15:53:32", shift: "B2", acuity: 6 },
  { date: "6/30/2019 15:57:22", shift: "C1", acuity: 6 },
  { date: "6/30/2019 16:06:11", shift: "D1", acuity: 6 },
  { date: "6/30/2019 16:07:22", shift: "A2", acuity: 6 },
  { date: "6/30/2019 16:17:53", shift: "B2", acuity: 7 },
  { date: "6/30/2019 16:48:55", shift: "C2", acuity: 6 },
  { date: "6/30/2019 16:56:34", shift: "D1", acuity: 6 },
  { date: "6/30/2019 17:07:55", shift: "C2", acuity: 6 },
  { date: "6/30/2019 17:08:24", shift: "C2", acuity: 6 },
  { date: "6/30/2019 17:20:20", shift: "A2", acuity: 6 },
  { date: "6/30/2019 17:43:58", shift: "B2", acuity: 3 },
  { date: "6/30/2019 17:47:08", shift: "D1", acuity: 3 },
  { date: "6/30/2019 17:50:17", shift: "C2", acuity: 3 },
  { date: "6/30/2019 17:54:42", shift: "A2", acuity: 6 },
  { date: "6/30/2019 18:00:41", shift: "D2", acuity: 3 },
  { date: "6/30/2019 18:01:07", shift: "B2", acuity: 3 },
  { date: "6/30/2019 18:22:40", shift: "C2", acuity: 6 },
  { date: "6/30/2019 18:23:46", shift: "A2", acuity: 6 },
  { date: "6/30/2019 18:34:48", shift: "D2", acuity: 6 },
  { date: "6/30/2019 18:35:21", shift: "D2", acuity: 6 },
  { date: "6/30/2019 18:42:51", shift: "B2", acuity: 3 },
  { date: "6/30/2019 18:44:37", shift: "C2", acuity: 3 },
  { date: "6/30/2019 18:52:42", shift: "A2", acuity: 3 },
  { date: "6/30/2019 19:01:59", shift: "D2", acuity: 6 },
  { date: "6/30/2019 19:28:59", shift: "B2", acuity: 6 },
  { date: "6/30/2019 19:39:30", shift: "C2", acuity: 3 },
  { date: "6/30/2019 19:44:13", shift: "A2", acuity: 6 },
  { date: "6/30/2019 19:50:06", shift: "D2", acuity: 6 },
  { date: "6/30/2019 20:11:32", shift: "B2", acuity: 6 },
  { date: "6/30/2019 20:12:38", shift: "C2", acuity: 3 },
  { date: "6/30/2019 20:12:53", shift: "A2", acuity: 6 },
  { date: "6/30/2019 20:13:04", shift: "D2", acuity: 3 },
  { date: "6/30/2019 20:19:19", shift: "B2", acuity: 4 },
  { date: "6/30/2019 20:26:57", shift: "C2", acuity: 3 },
  { date: "6/30/2019 20:37:15", shift: "A2", acuity: 3 },
  { date: "6/30/2019 20:48:41", shift: "D2", acuity: 3 },
  { date: "6/30/2019 20:58:00", shift: "B2", acuity: 6 },
  { date: "6/30/2019 21:03:30", shift: "A3", acuity: 3 },
  { date: "6/30/2019 21:23:58", shift: "C2", acuity: 6 },
  { date: "6/30/2019 21:24:43", shift: "D2", acuity: 6 },
  { date: "6/30/2019 21:25:04", shift: "B2", acuity: 3 },
  { date: "6/30/2019 21:25:17", shift: "A3", acuity: 6 },
  { date: "6/30/2019 21:32:55", shift: "A3", acuity: 3 },
  { date: "6/30/2019 21:51:07", shift: "A3", acuity: 3 },
  { date: "6/30/2019 21:52:04", shift: "C2", acuity: 3 },
  { date: "6/30/2019 22:03:14", shift: "B3", acuity: 3 },
  { date: "6/30/2019 22:03:33", shift: "D2", acuity: 6 },
  { date: "6/30/2019 22:17:15", shift: "A3", acuity: 4 },
  { date: "6/30/2019 22:17:39", shift: "C2", acuity: 3 },
  { date: "6/30/2019 22:17:57", shift: "B3", acuity: 4 },
  { date: "6/30/2019 22:20:24", shift: "D2", acuity: 6 },
  { date: "6/30/2019 22:29:53", shift: "A3", acuity: 6 },
  { date: "6/30/2019 22:49:27", shift: "B3", acuity: 6 },
  { date: "6/30/2019 22:57:26", shift: "B3", acuity: 3 },
  { date: "6/30/2019 23:09:03", shift: "C2", acuity: 4 },
  { date: "6/30/2019 23:30:07", shift: "D2", acuity: 4 },
  { date: "6/30/2019 23:32:15", shift: "A3", acuity: 6 },
  { date: "6/30/2019 23:47:13", shift: "B3", acuity: 3 },
  { date: "7/1/2019 00:16:20", shift: "D2", acuity: 6 },
  { date: "7/1/2019 00:28:35", shift: "A3", acuity: 6 },
  { date: "7/1/2019 00:33:46", shift: "B3", acuity: 3 },
  { date: "7/1/2019 00:41:36", shift: "D2", acuity: 6 },
  { date: "7/1/2019 00:46:29", shift: "A3", acuity: 4 },
  { date: "7/1/2019 00:52:33", shift: "B3", acuity: 3 },
  { date: "7/1/2019 01:00:20", shift: "D2", acuity: 6 },
  { date: "7/1/2019 01:06:48", shift: "A3", acuity: 4 },
  { date: "7/1/2019 01:11:18", shift: "B3", acuity: 6 },
  { date: "7/1/2019 01:28:51", shift: "D2", acuity: 4 },
  { date: "7/1/2019 01:45:04", shift: "A3", acuity: 6 },
  { date: "7/1/2019 01:45:16", shift: "B3", acuity: 3 },
  { date: "7/1/2019 02:02:52", shift: "A3", acuity: 4 },
  { date: "7/1/2019 02:16:28", shift: "B3", acuity: 3 },
  { date: "7/1/2019 02:25:08", shift: "A3", acuity: 6 },
  { date: "7/1/2019 02:46:44", shift: "B3", acuity: 6 },
  { date: "7/1/2019 03:17:37", shift: "A3", acuity: 6 },
  { date: "7/1/2019 03:37:00", shift: "B3", acuity: 2 },
  { date: "7/1/2019 04:21:40", shift: "A3", acuity: 3 },
  { date: "7/1/2019 04:22:23", shift: "B3", acuity: 6 },
  { date: "7/1/2019 04:22:29", shift: "A3", acuity: 3 },
  { date: "7/1/2019 04:27:08", shift: "B3", acuity: 3 },
  { date: "7/1/2019 04:34:13", shift: "A3", acuity: 3 },
  { date: "7/1/2019 05:34:23", shift: "A1", acuity: 4 },
  { date: "7/1/2019 05:38:57", shift: "A1", acuity: 2 },
  { date: "7/1/2019 05:41:03", shift: "A1", acuity: 4 },
  { date: "7/1/2019 05:54:00", shift: "B3", acuity: 2 },
  { date: "7/1/2019 06:01:11", shift: "B1", acuity: 3 },
  { date: "7/1/2019 07:16:13", shift: "B1", acuity: 3 },
  { date: "7/1/2019 07:16:23", shift: "B1", acuity: 3 },
  { date: "7/1/2019 07:21:15", shift: "A1", acuity: 5 },
  { date: "7/1/2019 07:21:37", shift: "B1", acuity: 3 },
  { date: "7/1/2019 07:39:59", shift: "A1", acuity: 3 },
  { date: "7/1/2019 07:50:12", shift: "B1", acuity: 3 },
  { date: "7/1/2019 08:04:35", shift: "A1", acuity: 3 },
  { date: "7/1/2019 08:11:38", shift: "B1", acuity: 3 },
  { date: "7/1/2019 08:30:40", shift: "C1", acuity: 3 },
  { date: "7/1/2019 08:35:21", shift: "A1", acuity: 3 },
  { date: "7/1/2019 08:38:13", shift: "B1", acuity: 2 },
  { date: "7/1/2019 09:00:21", shift: "C1", acuity: 3 },
  { date: "7/1/2019 09:05:26", shift: "C1", acuity: 6 },
  { date: "7/1/2019 09:12:54", shift: "A1", acuity: 4 },
  { date: "7/1/2019 09:13:08", shift: "B1", acuity: 4 },
  { date: "7/1/2019 09:34:39", shift: "C1", acuity: 3 },
  { date: "7/1/2019 09:48:51", shift: "A1", acuity: 6 },
  { date: "7/1/2019 10:09:26", shift: "D1", acuity: 2 },
  { date: "7/1/2019 10:10:14", shift: "B1", acuity: 3 },
  { date: "7/1/2019 10:14:08", shift: "C1", acuity: 3 },
  { date: "7/1/2019 10:15:44", shift: "A1", acuity: 6 },
  { date: "7/1/2019 10:23:31", shift: "D1", acuity: 3 },
  { date: "7/1/2019 10:44:19", shift: "D1", acuity: 3 },
  { date: "7/1/2019 10:48:00", shift: "D1", acuity: 3 },
  { date: "7/1/2019 10:57:51", shift: "B1", acuity: 3 },
  { date: "7/1/2019 11:17:51", shift: "C1", acuity: 3 },
  { date: "7/1/2019 11:23:09", shift: "A1", acuity: 3 },
  { date: "7/1/2019 11:26:39", shift: "D1", acuity: 3 },
  { date: "7/1/2019 11:35:41", shift: "B1", acuity: 3 },
  { date: "7/1/2019 11:43:05", shift: "C1", acuity: 3 },
  { date: "7/1/2019 11:51:40", shift: "A1", acuity: 2 },
  { date: "7/1/2019 12:17:51", shift: "D1", acuity: 3 },
  { date: "7/1/2019 12:31:04", shift: "B1", acuity: 6 },
  { date: "7/1/2019 12:32:23", shift: "C1", acuity: 6 },
  { date: "7/1/2019 12:51:11", shift: "A1", acuity: 6 },
  { date: "7/1/2019 12:51:38", shift: "D1", acuity: 3 },
  { date: "7/1/2019 12:57:21", shift: "B1", acuity: 6 },
  { date: "7/1/2019 13:01:42", shift: "A2", acuity: 6 },
  { date: "7/1/2019 13:04:39", shift: "C1", acuity: 3 },
  { date: "7/1/2019 13:04:49", shift: "D1", acuity: 3 },
  { date: "7/1/2019 13:21:54", shift: "B1", acuity: 3 },
  { date: "7/1/2019 13:40:03", shift: "A2", acuity: 3 },
  { date: "7/1/2019 13:43:56", shift: "A2", acuity: 6 },
  { date: "7/1/2019 13:49:00", shift: "C1", acuity: 6 },
  { date: "7/1/2019 13:50:46", shift: "D1", acuity: 3 },
  { date: "7/1/2019 14:20:11", shift: "B2", acuity: 6 },
  { date: "7/1/2019 14:22:35", shift: "A2", acuity: 3 },
  { date: "7/1/2019 14:23:08", shift: "C1", acuity: 6 },
  { date: "7/1/2019 14:32:25", shift: "B2", acuity: 3 },
  { date: "7/1/2019 14:39:07", shift: "B2", acuity: 3 },
  { date: "7/1/2019 14:53:04", shift: "D1", acuity: 6 },
  { date: "7/1/2019 15:16:14", shift: "A2", acuity: 3 },
  { date: "7/1/2019 15:19:31", shift: "C1", acuity: 6 },
  { date: "7/1/2019 15:20:15", shift: "B2", acuity: 3 },
  { date: "7/1/2019 15:56:49", shift: "D1", acuity: 6 },
  { date: "7/1/2019 15:58:54", shift: "A2", acuity: 2 },
  { date: "7/1/2019 16:01:30", shift: "C1", acuity: 6 },
  { date: "7/1/2019 16:05:17", shift: "B2", acuity: 7 },
  { date: "7/1/2019 16:07:08", shift: "D1", acuity: 6 },
  { date: "7/1/2019 16:07:19", shift: "A2", acuity: 3 },
  { date: "7/1/2019 16:34:23", shift: "C2", acuity: 6 },
  { date: "7/1/2019 16:40:05", shift: "B2", acuity: 6 },
  { date: "7/1/2019 16:51:09", shift: "D1", acuity: 3 },
  { date: "7/1/2019 16:53:24", shift: "A2", acuity: 6 },
  { date: "7/1/2019 17:01:34", shift: "C2", acuity: 6 },
  { date: "7/1/2019 17:01:44", shift: "C2", acuity: 3 },
  { date: "7/1/2019 17:04:46", shift: "B2", acuity: 3 },
  { date: "7/1/2019 17:10:22", shift: "D1", acuity: 3 },
  { date: "7/1/2019 17:26:51", shift: "A2", acuity: 6 },
  { date: "7/1/2019 18:00:34", shift: "D2", acuity: 3 },
  { date: "7/1/2019 18:00:47", shift: "C2", acuity: 3 },
  { date: "7/1/2019 18:19:58", shift: "B2", acuity: 3 },
  { date: "7/1/2019 18:27:28", shift: "A2", acuity: 3 },
  { date: "7/1/2019 18:46:04", shift: "D2", acuity: 3 },
  { date: "7/1/2019 19:28:39", shift: "D2", acuity: 6 },
  { date: "7/1/2019 19:28:52", shift: "C2", acuity: 3 },
  { date: "7/1/2019 19:39:03", shift: "B2", acuity: 3 },
  { date: "7/1/2019 20:18:36", shift: "A2", acuity: 6 },
  { date: "7/1/2019 20:26:40", shift: "D2", acuity: 3 },
  { date: "7/1/2019 20:27:15", shift: "C2", acuity: 3 },
  { date: "7/1/2019 20:41:10", shift: "B2", acuity: 6 },
  { date: "7/1/2019 20:52:58", shift: "A2", acuity: 3 },
  { date: "7/1/2019 21:20:57", shift: "A3", acuity: 3 },
  { date: "7/1/2019 21:33:23", shift: "A3", acuity: 3 },
  { date: "7/1/2019 21:50:18", shift: "A3", acuity: 3 },
  { date: "7/1/2019 21:51:39", shift: "D2", acuity: 3 },
  { date: "7/1/2019 22:03:44", shift: "B3", acuity: 3 },
  { date: "7/1/2019 22:13:41", shift: "C2", acuity: 3 },
  { date: "7/1/2019 22:16:08", shift: "A3", acuity: 6 },
  { date: "7/1/2019 22:21:12", shift: "D2", acuity: 3 },
  { date: "7/1/2019 22:27:08", shift: "B3", acuity: 6 },
  { date: "7/1/2019 22:43:18", shift: "B3", acuity: 6 },
  { date: "7/1/2019 22:43:53", shift: "B3", acuity: 4 },
  { date: "7/1/2019 22:49:39", shift: "C2", acuity: 3 },
  { date: "7/1/2019 23:13:58", shift: "A3", acuity: 4 },
  { date: "7/1/2019 23:15:08", shift: "D2", acuity: 3 },
  { date: "7/1/2019 23:20:25", shift: "B3", acuity: 6 },
  { date: "7/1/2019 23:21:11", shift: "C2", acuity: 3 },
  { date: "7/1/2019 23:28:34", shift: "A3", acuity: 4 },
  { date: "7/1/2019 23:32:00", shift: "D2", acuity: 3 },
  { date: "7/1/2019 23:55:57", shift: "B3", acuity: 4 },
  { date: "7/1/2019 23:57:50", shift: "C2", acuity: 3 },
  { date: "7/2/2019 00:01:35", shift: "A3", acuity: 4 },
  { date: "7/2/2019 00:02:19", shift: "D2", acuity: 4 },
  { date: "7/2/2019 00:09:47", shift: "B3", acuity: 3 },
  { date: "7/2/2019 00:12:37", shift: "A3", acuity: 3 },
  { date: "7/2/2019 00:18:26", shift: "D2", acuity: 3 },
  { date: "7/2/2019 00:28:19", shift: "B3", acuity: 3 },
  { date: "7/2/2019 00:37:32", shift: "A3", acuity: 4 },
  { date: "7/2/2019 00:44:59", shift: "D2", acuity: 6 },
  { date: "7/2/2019 01:00:29", shift: "B3", acuity: 4 },
  { date: "7/2/2019 01:00:57", shift: "A3", acuity: 4 },
  { date: "7/2/2019 01:11:13", shift: "D2", acuity: 6 },
  { date: "7/2/2019 01:18:18", shift: "B3", acuity: 3 },
  { date: "7/2/2019 01:24:24", shift: "A3", acuity: 6 },
  { date: "7/2/2019 01:45:43", shift: "B3", acuity: 3 },
  { date: "7/2/2019 02:02:30", shift: "A3", acuity: 3 },
  { date: "7/2/2019 02:06:18", shift: "B3", acuity: 3 },
  { date: "7/2/2019 02:14:20", shift: "A3", acuity: 4 },
  { date: "7/2/2019 02:14:33", shift: "B3", acuity: 3 },
  { date: "7/2/2019 02:44:07", shift: "A3", acuity: 3 },
  { date: "7/2/2019 02:44:24", shift: "B3", acuity: 3 },
  { date: "7/2/2019 02:50:19", shift: "A3", acuity: 3 },
  { date: "7/2/2019 02:55:06", shift: "B3", acuity: 6 },
  { date: "7/2/2019 03:15:21", shift: "A3", acuity: 6 },
  { date: "7/2/2019 03:16:14", shift: "B3", acuity: 6 },
  { date: "7/2/2019 03:46:25", shift: "A3", acuity: 4 },
  { date: "7/2/2019 04:06:43", shift: "B3", acuity: 3 },
  { date: "7/2/2019 04:17:15", shift: "A3", acuity: 6 },
  { date: "7/2/2019 06:06:32", shift: "A1", acuity: 4 },
  { date: "7/2/2019 06:37:58", shift: "A1", acuity: 3 },
  { date: "7/2/2019 07:48:22", shift: "B1", acuity: 4 },
  { date: "7/2/2019 08:21:44", shift: "A1", acuity: 6 },
  { date: "7/2/2019 08:25:51", shift: "B1", acuity: 3 },
  { date: "7/2/2019 08:31:02", shift: "C1", acuity: 3 },
  { date: "7/2/2019 08:42:26", shift: "A1", acuity: 6 },
  { date: "7/2/2019 08:45:44", shift: "B1", acuity: 3 },
  { date: "7/2/2019 09:05:02", shift: "C1", acuity: 4 },
  { date: "7/2/2019 09:10:42", shift: "C1", acuity: 3 },
  { date: "7/2/2019 09:28:24", shift: "A1", acuity: 4 },
  { date: "7/2/2019 09:40:05", shift: "B1", acuity: 3 },
  { date: "7/2/2019 09:40:33", shift: "C1", acuity: 3 },
  { date: "7/2/2019 09:44:36", shift: "A1", acuity: 6 },
  { date: "7/2/2019 09:51:42", shift: "B1", acuity: 6 },
  { date: "7/2/2019 10:04:51", shift: "D1", acuity: 6 },
  { date: "7/2/2019 10:13:19", shift: "C1", acuity: 6 },
  { date: "7/2/2019 10:13:30", shift: "A1", acuity: 6 },
  { date: "7/2/2019 10:24:05", shift: "B1", acuity: 6 },
  { date: "7/2/2019 10:42:10", shift: "D1", acuity: 6 },
  { date: "7/2/2019 10:52:26", shift: "D1", acuity: 3 },
  { date: "7/2/2019 11:02:27", shift: "C1", acuity: 3 },
  { date: "7/2/2019 11:16:49", shift: "A1", acuity: 6 },
  { date: "7/2/2019 11:16:58", shift: "B1", acuity: 3 },
  { date: "7/2/2019 11:26:29", shift: "D1", acuity: 6 },
  { date: "7/2/2019 11:26:46", shift: "C1", acuity: 4 },
  { date: "7/2/2019 11:33:06", shift: "A1", acuity: 6 },
  { date: "7/2/2019 11:56:10", shift: "B1", acuity: 6 },
  { date: "7/2/2019 12:21:55", shift: "D1", acuity: 6 },
  { date: "7/2/2019 12:22:07", shift: "C1", acuity: 6 },
  { date: "7/2/2019 12:27:09", shift: "A1", acuity: 6 },
  { date: "7/2/2019 12:31:10", shift: "B1", acuity: 6 },
  { date: "7/2/2019 12:34:30", shift: "D1", acuity: 6 },
  { date: "7/2/2019 12:48:38", shift: "C1", acuity: 3 },
  { date: "7/2/2019 13:34:59", shift: "A2", acuity: 6 },
  { date: "7/2/2019 13:35:22", shift: "A2", acuity: 3 },
  { date: "7/2/2019 13:45:49", shift: "A2", acuity: 6 },
  { date: "7/2/2019 13:46:01", shift: "B1", acuity: 6 },
  { date: "7/2/2019 13:51:24", shift: "D1", acuity: 6 },
  { date: "7/2/2019 14:08:04", shift: "B2", acuity: 3 },
  { date: "7/2/2019 14:08:20", shift: "C1", acuity: 4 },
  { date: "7/2/2019 14:20:45", shift: "A2", acuity: 3 },
  { date: "7/2/2019 14:33:44", shift: "B2", acuity: 6 },
  { date: "7/2/2019 14:55:49", shift: "B2", acuity: 3 },
  { date: "7/2/2019 15:04:40", shift: "D1", acuity: 6 },
  { date: "7/2/2019 15:16:22", shift: "C1", acuity: 4 },
  { date: "7/2/2019 15:16:35", shift: "A2", acuity: 3 },
  { date: "7/2/2019 15:29:48", shift: "B2", acuity: 3 },
  { date: "7/2/2019 15:30:06", shift: "D1", acuity: 3 },
  { date: "7/2/2019 15:43:37", shift: "C1", acuity: 3 },
  { date: "7/2/2019 15:48:04", shift: "A2", acuity: 3 },
  { date: "7/2/2019 15:48:21", shift: "B2", acuity: 3 },
  { date: "7/2/2019 16:08:48", shift: "D1", acuity: 3 },
  { date: "7/2/2019 16:18:36", shift: "C1", acuity: 6 },
  { date: "7/2/2019 16:18:52", shift: "A2", acuity: 3 },
  { date: "7/2/2019 16:21:22", shift: "B2", acuity: 6 },
  { date: "7/2/2019 16:34:11", shift: "C2", acuity: 3 },
  { date: "7/2/2019 16:42:59", shift: "D1", acuity: 6 },
  { date: "7/2/2019 16:45:14", shift: "A2", acuity: 3 },
  { date: "7/2/2019 16:53:44", shift: "B2", acuity: 6 },
  { date: "7/2/2019 17:00:26", shift: "C2", acuity: 3 },
  { date: "7/2/2019 17:00:38", shift: "C2", acuity: 3 },
  { date: "7/2/2019 17:00:52", shift: "D1", acuity: 3 },
  { date: "7/2/2019 17:12:27", shift: "A2", acuity: 6 },
  { date: "7/2/2019 17:19:38", shift: "B2", acuity: 3 },
  { date: "7/2/2019 17:27:02", shift: "C2", acuity: 6 },
  { date: "7/2/2019 17:29:53", shift: "D1", acuity: 3 },
  { date: "7/2/2019 17:36:42", shift: "A2", acuity: 3 },
  { date: "7/2/2019 17:45:40", shift: "B2", acuity: 3 },
  { date: "7/2/2019 17:48:10", shift: "C2", acuity: 3 },
  { date: "7/2/2019 18:15:13", shift: "D2", acuity: 3 },
  { date: "7/2/2019 18:18:20", shift: "A2", acuity: 6 },
  { date: "7/2/2019 18:19:08", shift: "B2", acuity: 3 },
  { date: "7/2/2019 18:55:46", shift: "D2", acuity: 3 },
  { date: "7/2/2019 19:08:22", shift: "D2", acuity: 6 },
  { date: "7/2/2019 19:10:48", shift: "C2", acuity: 3 },
  { date: "7/2/2019 19:15:14", shift: "A2", acuity: 6 },
  { date: "7/2/2019 19:20:58", shift: "B2", acuity: 6 },
  { date: "7/2/2019 19:31:28", shift: "D2", acuity: 4 },
  { date: "7/2/2019 19:36:30", shift: "C2", acuity: 6 },
  { date: "7/2/2019 19:47:04", shift: "A2", acuity: 3 },
  { date: "7/2/2019 19:58:29", shift: "B2", acuity: 3 },
  { date: "7/2/2019 20:01:28", shift: "D2", acuity: 4 },
  { date: "7/2/2019 20:07:31", shift: "C2", acuity: 3 },
  { date: "7/2/2019 20:28:54", shift: "A2", acuity: 6 },
  { date: "7/2/2019 20:32:43", shift: "B2", acuity: 3 },
  { date: "7/2/2019 20:41:47", shift: "D2", acuity: 3 },
  { date: "7/2/2019 20:45:27", shift: "C2", acuity: 6 },
  { date: "7/2/2019 20:53:52", shift: "A2", acuity: 4 },
  { date: "7/2/2019 20:58:19", shift: "B2", acuity: 3 },
  { date: "7/2/2019 21:09:08", shift: "A3", acuity: 3 },
  { date: "7/2/2019 21:35:53", shift: "A3", acuity: 6 },
  { date: "7/2/2019 21:44:33", shift: "A3", acuity: 3 },
  { date: "7/2/2019 21:53:18", shift: "D2", acuity: 3 },
  { date: "7/2/2019 21:53:30", shift: "C2", acuity: 3 },
  { date: "7/2/2019 22:09:00", shift: "B3", acuity: 6 },
  { date: "7/2/2019 22:15:05", shift: "A3", acuity: 3 },
  { date: "7/2/2019 22:21:02", shift: "D2", acuity: 3 },
  { date: "7/2/2019 22:37:43", shift: "B3", acuity: 3 },
  { date: "7/2/2019 22:38:53", shift: "B3", acuity: 3 },
  { date: "7/2/2019 22:40:25", shift: "C2", acuity: 4 },
  { date: "7/2/2019 23:05:52", shift: "A3", acuity: 4 },
  { date: "7/2/2019 23:15:18", shift: "D2", acuity: 6 },
  { date: "7/2/2019 23:43:22", shift: "B3", acuity: 3 },
  { date: "7/2/2019 23:44:28", shift: "C2", acuity: 3 },
  { date: "7/2/2019 23:54:54", shift: "A3", acuity: 4 },
  { date: "7/2/2019 23:56:21", shift: "D2", acuity: 6 },
  { date: "7/3/2019 00:09:17", shift: "B3", acuity: 4 },
  { date: "7/3/2019 00:16:51", shift: "A3", acuity: 6 },
  { date: "7/3/2019 00:28:42", shift: "D2", acuity: 3 },
  { date: "7/3/2019 00:31:44", shift: "B3", acuity: 4 },
  { date: "7/3/2019 00:40:56", shift: "A3", acuity: 6 },
  { date: "7/3/2019 00:47:20", shift: "D2", acuity: 3 },
  { date: "7/3/2019 01:00:51", shift: "B3", acuity: 4 },
  { date: "7/3/2019 01:11:04", shift: "A3", acuity: 3 },
  { date: "7/3/2019 01:19:26", shift: "D2", acuity: 3 },
  { date: "7/3/2019 01:25:39", shift: "B3", acuity: 3 },
  { date: "7/3/2019 01:25:49", shift: "A3", acuity: 3 },
  { date: "7/3/2019 01:31:40", shift: "B3", acuity: 6 },
  { date: "7/3/2019 01:52:37", shift: "A3", acuity: 6 },
  { date: "7/3/2019 02:05:29", shift: "B3", acuity: 6 },
  { date: "7/3/2019 02:16:36", shift: "A3", acuity: 3 },
  { date: "7/3/2019 02:22:51", shift: "B3", acuity: 4 },
  { date: "7/3/2019 02:23:08", shift: "A3", acuity: 3 },
  { date: "7/3/2019 02:49:21", shift: "B3", acuity: 5 },
  { date: "7/3/2019 02:52:56", shift: "A3", acuity: 6 },
  { date: "7/3/2019 03:15:11", shift: "B3", acuity: 6 },
  { date: "7/3/2019 03:41:17", shift: "A3", acuity: 4 },
  { date: "7/3/2019 04:01:40", shift: "B3", acuity: 3 },
  { date: "7/3/2019 04:12:48", shift: "A3", acuity: 3 },
  { date: "7/3/2019 04:32:07", shift: "B3", acuity: 6 },
  { date: "7/3/2019 04:44:06", shift: "A3", acuity: 4 },
  { date: "7/3/2019 05:04:44", shift: "A1", acuity: 3 },
  { date: "7/3/2019 05:43:55", shift: "A1", acuity: 3 },
  { date: "7/3/2019 05:53:53", shift: "A1", acuity: 6 },
  { date: "7/3/2019 06:19:25", shift: "B1", acuity: 4 },
  { date: "7/3/2019 06:29:21", shift: "A1", acuity: 4 },
  { date: "7/3/2019 06:38:49", shift: "B1", acuity: 3 },
  { date: "7/3/2019 06:46:51", shift: "B1", acuity: 3 },
  { date: "7/3/2019 08:00:53", shift: "A1", acuity: 3 },
  { date: "7/3/2019 08:12:25", shift: "B1", acuity: 4 },
  { date: "7/3/2019 08:12:51", shift: "A1", acuity: 4 },
  { date: "7/3/2019 08:23:59", shift: "B1", acuity: 3 },
  { date: "7/3/2019 08:29:52", shift: "A1", acuity: 3 },
  { date: "7/3/2019 08:50:35", shift: "C1", acuity: 3 },
  { date: "7/3/2019 09:08:19", shift: "C1", acuity: 3 },
  { date: "7/3/2019 09:08:49", shift: "C1", acuity: 3 },
  { date: "7/3/2019 09:42:58", shift: "B1", acuity: 6 },
  { date: "7/3/2019 09:45:05", shift: "A1", acuity: 4 },
  { date: "7/3/2019 10:00:47", shift: "D1", acuity: 6 },
  { date: "7/3/2019 10:06:26", shift: "C1", acuity: 3 },
  { date: "7/3/2019 10:14:20", shift: "B1", acuity: 4 },
  { date: "7/3/2019 10:14:39", shift: "A1", acuity: 6 },
  { date: "7/3/2019 10:23:26", shift: "D1", acuity: 4 },
  { date: "7/3/2019 10:30:28", shift: "D1", acuity: 3 },
  { date: "7/3/2019 10:50:44", shift: "D1", acuity: 3 },
  { date: "7/3/2019 10:59:09", shift: "C1", acuity: 3 },
  { date: "7/3/2019 10:59:41", shift: "B1", acuity: 3 },
  { date: "7/3/2019 11:06:28", shift: "A1", acuity: 6 },
  { date: "7/3/2019 11:39:37", shift: "D1", acuity: 3 },
  { date: "7/3/2019 12:06:14", shift: "C1", acuity: 3 },
  { date: "7/3/2019 12:06:40", shift: "B1", acuity: 6 },
  { date: "7/3/2019 12:19:38", shift: "A1", acuity: 6 },
  { date: "7/3/2019 12:43:50", shift: "D1", acuity: 3 },
  { date: "7/3/2019 12:48:38", shift: "C1", acuity: 6 },
  { date: "7/3/2019 12:50:03", shift: "B1", acuity: 4 },
  { date: "7/3/2019 12:50:30", shift: "A1", acuity: 3 },
  { date: "7/3/2019 12:56:25", shift: "D1", acuity: 6 },
  { date: "7/3/2019 13:07:28", shift: "A2", acuity: 6 },
  { date: "7/3/2019 13:12:43", shift: "C1", acuity: 6 },
  { date: "7/3/2019 13:33:45", shift: "A2", acuity: 6 },
  { date: "7/3/2019 13:37:56", shift: "A2", acuity: 3 },
  { date: "7/3/2019 13:43:44", shift: "B1", acuity: 3 },
  { date: "7/3/2019 13:54:12", shift: "D1", acuity: 3 },
  { date: "7/3/2019 13:59:55", shift: "C1", acuity: 6 },
  { date: "7/3/2019 14:17:50", shift: "B2", acuity: 3 },
  { date: "7/3/2019 14:18:13", shift: "A2", acuity: 6 },
  { date: "7/3/2019 14:18:33", shift: "D1", acuity: 6 },
  { date: "7/3/2019 14:18:54", shift: "C1", acuity: 6 },
  { date: "7/3/2019 14:29:17", shift: "B2", acuity: 3 },
  { date: "7/3/2019 14:36:58", shift: "B2", acuity: 3 },
  { date: "7/3/2019 14:44:44", shift: "B2", acuity: 6 },
  { date: "7/3/2019 14:54:55", shift: "A2", acuity: 6 },
  { date: "7/3/2019 14:57:03", shift: "D1", acuity: 3 },
  { date: "7/3/2019 15:13:28", shift: "C1", acuity: 6 },
  { date: "7/3/2019 15:45:53", shift: "B2", acuity: 3 },
  { date: "7/3/2019 15:48:49", shift: "A2", acuity: 6 },
  { date: "7/3/2019 15:50:12", shift: "D1", acuity: 3 },
  { date: "7/3/2019 15:50:45", shift: "C1", acuity: 3 },
  { date: "7/3/2019 16:12:51", shift: "B2", acuity: 6 },
  { date: "7/3/2019 16:35:17", shift: "C2", acuity: 6 },
  { date: "7/3/2019 16:51:50", shift: "A2", acuity: 2 },
  { date: "7/3/2019 16:58:13", shift: "D1", acuity: 3 },
  { date: "7/3/2019 16:59:21", shift: "B2", acuity: 3 },
  { date: "7/3/2019 17:05:54", shift: "C2", acuity: 6 },
  { date: "7/3/2019 17:13:51", shift: "C2", acuity: 3 },
  { date: "7/3/2019 17:49:43", shift: "A2", acuity: 6 },
  { date: "7/3/2019 17:50:38", shift: "D1", acuity: 3 },
  { date: "7/3/2019 17:51:18", shift: "B2", acuity: 6 },
  { date: "7/3/2019 17:59:41", shift: "C2", acuity: 3 },
  { date: "7/3/2019 18:00:27", shift: "D2", acuity: 3 },
  { date: "7/3/2019 18:11:20", shift: "A2", acuity: 6 },
  { date: "7/3/2019 18:18:08", shift: "B2", acuity: 6 },
  { date: "7/3/2019 18:28:33", shift: "C2", acuity: 3 },
  { date: "7/3/2019 19:01:51", shift: "D2", acuity: 3 },
  { date: "7/3/2019 19:03:14", shift: "D2", acuity: 3 },
  { date: "7/3/2019 19:22:43", shift: "A2", acuity: 6 },
  { date: "7/3/2019 19:23:42", shift: "B2", acuity: 3 },
  { date: "7/3/2019 19:24:10", shift: "C2", acuity: 3 },
  { date: "7/3/2019 19:34:06", shift: "D2", acuity: 3 },
  { date: "7/3/2019 19:34:39", shift: "A2", acuity: 3 },
  { date: "7/3/2019 19:46:56", shift: "B2", acuity: 3 },
  { date: "7/3/2019 19:53:04", shift: "C2", acuity: 6 },
  { date: "7/3/2019 20:01:29", shift: "D2", acuity: 6 },
  { date: "7/3/2019 20:06:52", shift: "A2", acuity: 3 },
  { date: "7/3/2019 20:07:19", shift: "B2", acuity: 6 },
  { date: "7/3/2019 20:19:07", shift: "C2", acuity: 6 },
  { date: "7/3/2019 20:19:52", shift: "D2", acuity: 3 },
  { date: "7/3/2019 20:20:59", shift: "A2", acuity: 3 },
  { date: "7/3/2019 20:22:16", shift: "B2", acuity: 3 },
  { date: "7/3/2019 20:42:37", shift: "C2", acuity: 3 },
  { date: "7/3/2019 20:43:36", shift: "D2", acuity: 3 },
  { date: "7/3/2019 20:48:32", shift: "A2", acuity: 6 },
  { date: "7/3/2019 21:00:26", shift: "A3", acuity: 6 },
  { date: "7/3/2019 21:01:15", shift: "B2", acuity: 3 },
  { date: "7/3/2019 21:18:49", shift: "C2", acuity: 3 },
  { date: "7/3/2019 21:21:00", shift: "D2", acuity: 6 },
  { date: "7/3/2019 21:35:07", shift: "A3", acuity: 6 },
  { date: "7/3/2019 21:40:55", shift: "A3", acuity: 3 },
  { date: "7/3/2019 22:05:08", shift: "B3", acuity: 6 },
  { date: "7/3/2019 22:07:21", shift: "C2", acuity: 3 },
  { date: "7/3/2019 22:07:57", shift: "D2", acuity: 3 },
  { date: "7/3/2019 22:14:40", shift: "A3", acuity: 3 },
  { date: "7/3/2019 22:21:15", shift: "B3", acuity: 6 },
  { date: "7/3/2019 22:30:25", shift: "B3", acuity: 3 },
  { date: "7/3/2019 22:42:23", shift: "B3", acuity: 3 },
  { date: "7/3/2019 22:43:48", shift: "C2", acuity: 6 },
  { date: "7/3/2019 22:44:22", shift: "D2", acuity: 6 },
  { date: "7/3/2019 22:46:24", shift: "A3", acuity: 3 },
  { date: "7/3/2019 22:52:59", shift: "B3", acuity: 3 },
  { date: "7/3/2019 22:55:19", shift: "C2", acuity: 3 },
  { date: "7/3/2019 22:57:07", shift: "D2", acuity: 3 },
  { date: "7/3/2019 23:03:27", shift: "A3", acuity: 3 },
  { date: "7/3/2019 23:10:41", shift: "B3", acuity: 6 },
  { date: "7/3/2019 23:20:38", shift: "C2", acuity: 3 },
  { date: "7/3/2019 23:41:19", shift: "D2", acuity: 3 },
  { date: "7/4/2019 00:04:46", shift: "A3", acuity: 6 },
  { date: "7/4/2019 00:46:46", shift: "B3", acuity: 4 },
  { date: "7/4/2019 00:47:01", shift: "D2", acuity: 6 },
  { date: "7/4/2019 00:55:27", shift: "A3", acuity: 6 },
  { date: "7/4/2019 00:55:52", shift: "B3", acuity: 3 },
  { date: "7/4/2019 00:56:25", shift: "D2", acuity: 3 },
  { date: "7/4/2019 00:57:04", shift: "A3", acuity: 5 },
  { date: "7/4/2019 00:57:11", shift: "B3", acuity: 4 },
  { date: "7/4/2019 01:07:21", shift: "D2", acuity: 3 },
  { date: "7/4/2019 01:08:23", shift: "A3", acuity: 3 },
  { date: "7/4/2019 01:11:27", shift: "B3", acuity: 3 },
  { date: "7/4/2019 01:13:43", shift: "D2", acuity: 4 },
  { date: "7/4/2019 01:32:12", shift: "A3", acuity: 3 },
  { date: "7/4/2019 01:48:27", shift: "B3", acuity: 4 },
  { date: "7/4/2019 02:04:46", shift: "A3", acuity: 3 },
  { date: "7/4/2019 02:05:06", shift: "B3", acuity: 3 },
  { date: "7/4/2019 02:24:20", shift: "A3", acuity: 3 },
  { date: "7/4/2019 02:34:38", shift: "B3", acuity: 4 },
  { date: "7/4/2019 02:44:44", shift: "A3", acuity: 4 },
  { date: "7/4/2019 03:02:43", shift: "B3", acuity: 6 },
  { date: "7/4/2019 03:21:43", shift: "A3", acuity: 3 },
  { date: "7/4/2019 03:22:00", shift: "B3", acuity: 3 },
  { date: "7/4/2019 05:13:23", shift: "A1", acuity: 4 },
  { date: "7/4/2019 05:25:21", shift: "B3", acuity: 3 },
  { date: "7/4/2019 06:24:25", shift: "A1", acuity: 6 },
  { date: "7/4/2019 06:52:00", shift: "A1", acuity: 3 },
  { date: "7/4/2019 07:22:12", shift: "B1", acuity: 4 },
  { date: "7/4/2019 07:34:53", shift: "B1", acuity: 4 },
  { date: "7/4/2019 07:48:53", shift: "B1", acuity: 3 },
  { date: "7/4/2019 07:54:13", shift: "A1", acuity: 4 },
  { date: "7/4/2019 07:56:44", shift: "B1", acuity: 3 },
  { date: "7/4/2019 08:11:36", shift: "A1", acuity: 3 },
  { date: "7/4/2019 08:11:52", shift: "B1", acuity: 6 },
  { date: "7/4/2019 08:33:56", shift: "C1", acuity: 3 },
  { date: "7/4/2019 08:39:27", shift: "A1", acuity: 6 },
  { date: "7/4/2019 08:39:48", shift: "B1", acuity: 6 },
  { date: "7/4/2019 09:01:39", shift: "C1", acuity: 6 },
  { date: "7/4/2019 09:01:58", shift: "C1", acuity: 7 },
  { date: "7/4/2019 09:02:26", shift: "A1", acuity: 6 },
  { date: "7/4/2019 09:07:25", shift: "B1", acuity: 3 },
  { date: "7/4/2019 09:13:44", shift: "C1", acuity: 6 },
  { date: "7/4/2019 09:27:28", shift: "A1", acuity: 6 },
  { date: "7/4/2019 09:47:51", shift: "B1", acuity: 3 },
  { date: "7/4/2019 09:49:32", shift: "C1", acuity: 2 },
  { date: "7/4/2019 10:00:22", shift: "D1", acuity: 3 },
  { date: "7/4/2019 10:12:18", shift: "A1", acuity: 3 },
  { date: "7/4/2019 10:12:51", shift: "B1", acuity: 3 },
  { date: "7/4/2019 10:21:45", shift: "C1", acuity: 3 },
  { date: "7/4/2019 10:39:14", shift: "D1", acuity: 3 },
  { date: "7/4/2019 10:54:02", shift: "D1", acuity: 6 },
  { date: "7/4/2019 11:13:03", shift: "A1", acuity: 6 },
  { date: "7/4/2019 11:15:57", shift: "B1", acuity: 2 },
  { date: "7/4/2019 11:29:43", shift: "C1", acuity: 3 },
  { date: "7/4/2019 11:30:02", shift: "D1", acuity: 3 },
  { date: "7/4/2019 12:30:28", shift: "A1", acuity: 3 },
  { date: "7/4/2019 12:30:45", shift: "B1", acuity: 3 },
  { date: "7/4/2019 12:32:58", shift: "C1", acuity: 3 },
  { date: "7/4/2019 12:33:18", shift: "D1", acuity: 3 },
  { date: "7/4/2019 12:39:36", shift: "A1", acuity: 3 },
  { date: "7/4/2019 12:52:01", shift: "B1", acuity: 4 },
  { date: "7/4/2019 13:03:41", shift: "A2", acuity: 3 },
  { date: "7/4/2019 13:06:06", shift: "C1", acuity: 3 },
  { date: "7/4/2019 13:09:24", shift: "D1", acuity: 6 },
  { date: "7/4/2019 13:18:36", shift: "B1", acuity: 6 },
  { date: "7/4/2019 13:55:19", shift: "A2", acuity: 6 },
  { date: "7/4/2019 13:55:34", shift: "A2", acuity: 3 },
  { date: "7/4/2019 13:56:47", shift: "C1", acuity: 6 },
  { date: "7/4/2019 14:05:14", shift: "B2", acuity: 3 },
  { date: "7/4/2019 14:35:36", shift: "B2", acuity: 4 },
  { date: "7/4/2019 14:41:02", shift: "B2", acuity: 3 },
  { date: "7/4/2019 15:05:49", shift: "D1", acuity: 7 },
  { date: "7/4/2019 15:13:34", shift: "A2", acuity: 7 },
  { date: "7/4/2019 15:14:02", shift: "C1", acuity: 7 },
  { date: "7/4/2019 15:16:49", shift: "B2", acuity: 3 },
  { date: "7/4/2019 15:18:17", shift: "D1", acuity: 3 },
  { date: "7/4/2019 15:31:47", shift: "A2", acuity: 3 },
  { date: "7/4/2019 15:39:56", shift: "C1", acuity: 6 },
  { date: "7/4/2019 15:52:52", shift: "B2", acuity: 3 },
  { date: "7/4/2019 16:01:41", shift: "D1", acuity: 3 },
  { date: "7/4/2019 16:09:51", shift: "A2", acuity: 3 },
  { date: "7/4/2019 16:24:26", shift: "C1", acuity: 4 },
  { date: "7/4/2019 16:24:53", shift: "B2", acuity: 4 },
  { date: "7/4/2019 16:40:30", shift: "C2", acuity: 3 },
  { date: "7/4/2019 16:41:31", shift: "D1", acuity: 3 },
  { date: "7/4/2019 16:42:39", shift: "A2", acuity: 3 },
  { date: "7/4/2019 16:57:02", shift: "B2", acuity: 3 },
  { date: "7/4/2019 17:01:07", shift: "C2", acuity: 6 },
  { date: "7/4/2019 17:09:23", shift: "C2", acuity: 3 },
  { date: "7/4/2019 17:21:21", shift: "D1", acuity: 6 },
  { date: "7/4/2019 17:21:41", shift: "A2", acuity: 6 },
  { date: "7/4/2019 17:39:24", shift: "B2", acuity: 3 },
  { date: "7/4/2019 17:44:51", shift: "C2", acuity: 3 },
  { date: "7/4/2019 18:04:56", shift: "D2", acuity: 6 },
  { date: "7/4/2019 18:13:20", shift: "A2", acuity: 3 },
  { date: "7/4/2019 18:20:32", shift: "B2", acuity: 6 },
  { date: "7/4/2019 18:26:25", shift: "C2", acuity: 6 },
  { date: "7/4/2019 18:37:43", shift: "D2", acuity: 3 },
  { date: "7/4/2019 18:46:04", shift: "D2", acuity: 3 },
  { date: "7/4/2019 19:01:33", shift: "A2", acuity: 6 },
  { date: "7/4/2019 19:02:07", shift: "B2", acuity: 3 },
  { date: "7/4/2019 19:34:10", shift: "C2", acuity: 3 },
  { date: "7/4/2019 19:34:35", shift: "D2", acuity: 7 },
  { date: "7/4/2019 19:34:54", shift: "A2", acuity: 3 },
  { date: "7/4/2019 19:58:25", shift: "B2", acuity: 6 },
  { date: "7/4/2019 19:59:09", shift: "C2", acuity: 4 },
  { date: "7/4/2019 20:09:50", shift: "D2", acuity: 3 },
  { date: "7/4/2019 20:11:06", shift: "A2", acuity: 3 },
  { date: "7/4/2019 20:14:17", shift: "B2", acuity: 6 },
  { date: "7/4/2019 20:23:05", shift: "C2", acuity: 3 },
  { date: "7/4/2019 20:30:17", shift: "D2", acuity: 4 },
  { date: "7/4/2019 20:34:24", shift: "A2", acuity: 6 },
  { date: "7/4/2019 20:36:56", shift: "B2", acuity: 3 },
  { date: "7/4/2019 20:44:38", shift: "C2", acuity: 5 },
  { date: "7/4/2019 20:59:32", shift: "D2", acuity: 4 },
  { date: "7/4/2019 20:59:57", shift: "A2", acuity: 4 },
  { date: "7/4/2019 21:00:15", shift: "A3", acuity: 4 },
  { date: "7/4/2019 21:13:45", shift: "B2", acuity: 6 },
  { date: "7/4/2019 21:15:41", shift: "C2", acuity: 6 },
  { date: "7/4/2019 21:36:30", shift: "A3", acuity: 3 },
  { date: "7/4/2019 21:36:45", shift: "A3", acuity: 6 },
  { date: "7/4/2019 21:37:27", shift: "D2", acuity: 6 },
  { date: "7/4/2019 21:45:37", shift: "B2", acuity: 2 },
  { date: "7/4/2019 21:50:06", shift: "C2", acuity: 3 },
  { date: "7/4/2019 22:04:28", shift: "B3", acuity: 6 },
  { date: "7/4/2019 22:13:02", shift: "A3", acuity: 3 },
  { date: "7/4/2019 22:14:07", shift: "D2", acuity: 4 },
  { date: "7/4/2019 22:30:14", shift: "B3", acuity: 3 },
  { date: "7/4/2019 22:53:27", shift: "B3", acuity: 6 },
  { date: "7/4/2019 22:53:50", shift: "C2", acuity: 4 },
  { date: "7/4/2019 23:04:26", shift: "A3", acuity: 3 },
  { date: "7/4/2019 23:11:20", shift: "D2", acuity: 5 },
  { date: "7/4/2019 23:12:48", shift: "B3", acuity: 4 },
  { date: "7/4/2019 23:14:34", shift: "C2", acuity: 3 },
  { date: "7/4/2019 23:49:19", shift: "A3", acuity: 3 },
  { date: "7/4/2019 23:49:33", shift: "D2", acuity: 4 },
  { date: "7/4/2019 23:52:06", shift: "B3", acuity: 3 },
  { date: "7/4/2019 23:52:59", shift: "C2", acuity: 4 },
  { date: "7/5/2019 00:03:15", shift: "A3", acuity: 4 },
  { date: "7/5/2019 00:18:01", shift: "D2", acuity: 5 },
  { date: "7/5/2019 00:29:47", shift: "B3", acuity: 6 },
  { date: "7/5/2019 00:30:37", shift: "A3", acuity: 3 },
  { date: "7/5/2019 00:40:41", shift: "D2", acuity: 3 },
  { date: "7/5/2019 00:41:24", shift: "B3", acuity: 4 },
  { date: "7/5/2019 00:48:25", shift: "A3", acuity: 3 },
  { date: "7/5/2019 00:54:02", shift: "D2", acuity: 3 },
  { date: "7/5/2019 01:12:54", shift: "B3", acuity: 4 },
  { date: "7/5/2019 01:13:12", shift: "A3", acuity: 6 },
  { date: "7/5/2019 01:34:57", shift: "B3", acuity: 3 },
  { date: "7/5/2019 01:35:28", shift: "A3", acuity: 6 },
  { date: "7/5/2019 01:35:39", shift: "B3", acuity: 3 },
  { date: "7/5/2019 01:45:47", shift: "A3", acuity: 3 },
  { date: "7/5/2019 02:13:39", shift: "B3", acuity: 4 },
  { date: "7/5/2019 02:28:14", shift: "A3", acuity: 4 },
  { date: "7/5/2019 02:48:14", shift: "B3", acuity: 3 },
  { date: "7/5/2019 02:48:48", shift: "A3", acuity: 3 },
  { date: "7/5/2019 03:31:48", shift: "B3", acuity: 3 },
  { date: "7/5/2019 04:22:59", shift: "A3", acuity: 4 },
  { date: "7/5/2019 04:30:56", shift: "B3", acuity: 3 },
  { date: "7/5/2019 04:55:00", shift: "A3", acuity: 6 },
  { date: "7/5/2019 04:55:12", shift: "B3", acuity: 6 },
  { date: "7/5/2019 05:01:24", shift: "A1", acuity: 3 },
  { date: "7/5/2019 05:45:46", shift: "A1", acuity: 3 },
  { date: "7/5/2019 05:54:24", shift: "A1", acuity: 3 },
  { date: "7/5/2019 06:01:58", shift: "B1", acuity: 3 },
  { date: "7/5/2019 06:16:54", shift: "A1", acuity: 2 },
  { date: "7/5/2019 06:32:03", shift: "B1", acuity: 3 },
  { date: "7/5/2019 06:35:46", shift: "B1", acuity: 6 },
  { date: "7/5/2019 06:55:00", shift: "A1", acuity: 3 },
  { date: "7/5/2019 07:20:00", shift: "B1", acuity: 3 },
  { date: "7/5/2019 07:25:45", shift: "A1", acuity: 3 },
  { date: "7/5/2019 07:26:07", shift: "B1", acuity: 3 },
  { date: "7/5/2019 07:45:43", shift: "A1", acuity: 3 },
  { date: "7/5/2019 08:35:37", shift: "C1", acuity: 3 },
  { date: "7/5/2019 08:40:16", shift: "B1", acuity: 2 },
  { date: "7/5/2019 09:25:36", shift: "C1", acuity: 2 },
  { date: "7/5/2019 09:26:19", shift: "C1", acuity: 3 },
  { date: "7/5/2019 09:26:50", shift: "A1", acuity: 7 },
  { date: "7/5/2019 09:46:57", shift: "B1", acuity: 4 },
  { date: "7/5/2019 09:47:24", shift: "C1", acuity: 4 },
  { date: "7/5/2019 09:59:00", shift: "A1", acuity: 6 },
  { date: "7/5/2019 10:12:16", shift: "D1", acuity: 3 },
  { date: "7/5/2019 10:15:33", shift: "B1", acuity: 3 },
  { date: "7/5/2019 10:27:56", shift: "C1", acuity: 6 },
  { date: "7/5/2019 10:28:17", shift: "A1", acuity: 3 },
  { date: "7/5/2019 10:33:34", shift: "D1", acuity: 6 },
  { date: "7/5/2019 10:33:59", shift: "D1", acuity: 6 },
  { date: "7/5/2019 10:35:26", shift: "B1", acuity: 7 },
  { date: "7/5/2019 10:43:03", shift: "C1", acuity: 6 },
  { date: "7/5/2019 11:03:44", shift: "A1", acuity: 6 },
  { date: "7/5/2019 11:16:58", shift: "D1", acuity: 6 },
  { date: "7/5/2019 11:23:00", shift: "B1", acuity: 3 },
  { date: "7/5/2019 11:25:36", shift: "C1", acuity: 7 },
  { date: "7/5/2019 11:35:07", shift: "A1", acuity: 3 },
  { date: "7/5/2019 11:45:13", shift: "D1", acuity: 3 },
  { date: "7/5/2019 11:53:00", shift: "B1", acuity: 6 },
  { date: "7/5/2019 12:00:27", shift: "C1", acuity: 6 },
  { date: "7/5/2019 12:20:09", shift: "A1", acuity: 6 },
  { date: "7/5/2019 12:21:58", shift: "D1", acuity: 6 },
  { date: "7/5/2019 12:40:51", shift: "B1", acuity: 6 },
  { date: "7/5/2019 12:45:29", shift: "C1", acuity: 6 },
  { date: "7/5/2019 12:46:35", shift: "A1", acuity: 6 },
  { date: "7/5/2019 12:56:10", shift: "D1", acuity: 6 },
  { date: "7/5/2019 12:56:59", shift: "B1", acuity: 3 },
  { date: "7/5/2019 13:23:04", shift: "A2", acuity: 3 },
  { date: "7/5/2019 13:42:05", shift: "A2", acuity: 6 },
  { date: "7/5/2019 13:59:10", shift: "A2", acuity: 3 },
  { date: "7/5/2019 14:11:13", shift: "B2", acuity: 6 },
  { date: "7/5/2019 14:11:49", shift: "C1", acuity: 3 },
  { date: "7/5/2019 14:12:36", shift: "D1", acuity: 3 },
  { date: "7/5/2019 14:14:50", shift: "A2", acuity: 7 },
  { date: "7/5/2019 14:16:25", shift: "B2", acuity: 6 },
  { date: "7/5/2019 14:30:17", shift: "B2", acuity: 3 },
  { date: "7/5/2019 14:32:59", shift: "B2", acuity: 6 },
  { date: "7/5/2019 14:42:51", shift: "C1", acuity: 6 },
  { date: "7/5/2019 14:53:03", shift: "D1", acuity: 6 },
  { date: "7/5/2019 14:53:45", shift: "A2", acuity: 6 },
  { date: "7/5/2019 14:54:17", shift: "B2", acuity: 6 },
  { date: "7/5/2019 14:54:44", shift: "C1", acuity: 3 },
  { date: "7/5/2019 15:34:14", shift: "D1", acuity: 6 },
  { date: "7/5/2019 15:37:19", shift: "A2", acuity: 6 },
  { date: "7/5/2019 16:04:37", shift: "B2", acuity: 6 },
  { date: "7/5/2019 16:29:38", shift: "C1", acuity: 6 },
  { date: "7/5/2019 16:31:52", shift: "C2", acuity: 3 },
  { date: "7/5/2019 16:38:08", shift: "D1", acuity: 4 },
  { date: "7/5/2019 16:38:42", shift: "A2", acuity: 6 },
  { date: "7/5/2019 16:46:31", shift: "B2", acuity: 3 },
  { date: "7/5/2019 16:46:59", shift: "C2", acuity: 6 },
  { date: "7/5/2019 16:50:32", shift: "D1", acuity: 6 },
  { date: "7/5/2019 16:59:54", shift: "A2", acuity: 6 },
  { date: "7/5/2019 17:07:22", shift: "C2", acuity: 3 },
  { date: "7/5/2019 17:08:10", shift: "C2", acuity: 6 },
  { date: "7/5/2019 17:13:05", shift: "B2", acuity: 3 },
  { date: "7/5/2019 17:20:17", shift: "D1", acuity: 6 },
  { date: "7/5/2019 17:20:38", shift: "A2", acuity: 6 },
  { date: "7/5/2019 17:21:02", shift: "C2", acuity: 6 },
  { date: "7/5/2019 17:24:00", shift: "B2", acuity: 6 },
  { date: "7/5/2019 17:59:06", shift: "D1", acuity: 6 },
  { date: "7/5/2019 18:00:31", shift: "D2", acuity: 6 },
  { date: "7/5/2019 18:16:15", shift: "A2", acuity: 3 },
  { date: "7/5/2019 18:22:45", shift: "C2", acuity: 6 },
  { date: "7/5/2019 18:26:29", shift: "B2", acuity: 6 },
  { date: "7/5/2019 18:31:31", shift: "D2", acuity: 6 },
  { date: "7/5/2019 18:37:16", shift: "D2", acuity: 6 },
  { date: "7/5/2019 18:52:33", shift: "A2", acuity: 3 },
  { date: "7/5/2019 19:14:45", shift: "C2", acuity: 6 },
  { date: "7/5/2019 19:24:39", shift: "B2", acuity: 6 },
  { date: "7/5/2019 19:28:53", shift: "D2", acuity: 3 },
  { date: "7/5/2019 19:29:55", shift: "A2", acuity: 6 },
  { date: "7/5/2019 19:31:55", shift: "C2", acuity: 3 },
  { date: "7/5/2019 19:32:25", shift: "B2", acuity: 3 },
  { date: "7/5/2019 19:43:37", shift: "D2", acuity: 6 },
  { date: "7/5/2019 19:44:20", shift: "A2", acuity: 6 },
  { date: "7/5/2019 19:58:05", shift: "C2", acuity: 3 },
  { date: "7/5/2019 20:00:40", shift: "B2", acuity: 3 },
  { date: "7/5/2019 20:01:10", shift: "D2", acuity: 3 },
  { date: "7/5/2019 20:08:08", shift: "A2", acuity: 3 },
  { date: "7/5/2019 20:11:16", shift: "C2", acuity: 3 },
  { date: "7/5/2019 20:12:13", shift: "B2", acuity: 3 },
  { date: "7/5/2019 20:22:41", shift: "D2", acuity: 4 },
  { date: "7/5/2019 20:27:45", shift: "A2", acuity: 4 },
  { date: "7/5/2019 20:32:50", shift: "C2", acuity: 3 },
  { date: "7/5/2019 20:34:47", shift: "B2", acuity: 4 },
  { date: "7/5/2019 20:40:29", shift: "D2", acuity: 3 },
  { date: "7/5/2019 20:51:40", shift: "A2", acuity: 3 },
  { date: "7/5/2019 20:58:15", shift: "C2", acuity: 3 },
  { date: "7/5/2019 21:13:25", shift: "A3", acuity: 6 },
  { date: "7/5/2019 21:32:13", shift: "A3", acuity: 6 },
  { date: "7/5/2019 21:44:46", shift: "A3", acuity: 3 },
  { date: "7/5/2019 21:55:20", shift: "B2", acuity: 6 },
  { date: "7/5/2019 22:02:13", shift: "B3", acuity: 3 },
  { date: "7/5/2019 22:02:39", shift: "D2", acuity: 3 },
  { date: "7/5/2019 22:13:54", shift: "C2", acuity: 6 },
  { date: "7/5/2019 22:33:14", shift: "B3", acuity: 6 },
  { date: "7/5/2019 22:49:15", shift: "B3", acuity: 3 },
  { date: "7/5/2019 22:49:24", shift: "A3", acuity: 4 },
  { date: "7/5/2019 22:57:36", shift: "D2", acuity: 3 },
  { date: "7/5/2019 23:01:49", shift: "C2", acuity: 3 },
  { date: "7/5/2019 23:03:20", shift: "B3", acuity: 4 },
  { date: "7/5/2019 23:10:56", shift: "A3", acuity: 6 },
  { date: "7/5/2019 23:35:58", shift: "D2", acuity: 6 },
  { date: "7/5/2019 23:52:32", shift: "C2", acuity: 6 },
  { date: "7/6/2019 00:00:18", shift: "B3", acuity: 3 },
  { date: "7/6/2019 00:00:41", shift: "A3", acuity: 3 },
  { date: "7/6/2019 00:11:07", shift: "D2", acuity: 6 },
  { date: "7/6/2019 00:12:56", shift: "B3", acuity: 6 },
  { date: "7/6/2019 00:13:19", shift: "A3", acuity: 6 },
  { date: "7/6/2019 00:24:26", shift: "D2", acuity: 6 },
  { date: "7/6/2019 00:36:53", shift: "B3", acuity: 4 },
  { date: "7/6/2019 00:37:24", shift: "A3", acuity: 4 },
  { date: "7/6/2019 00:37:56", shift: "D2", acuity: 4 },
  { date: "7/6/2019 00:44:15", shift: "B3", acuity: 3 },
  { date: "7/6/2019 01:07:39", shift: "A3", acuity: 3 },
  { date: "7/6/2019 01:10:59", shift: "D2", acuity: 6 },
  { date: "7/6/2019 02:00:52", shift: "B3", acuity: 3 },
  { date: "7/6/2019 02:16:42", shift: "A3", acuity: 3 },
  { date: "7/6/2019 02:17:16", shift: "B3", acuity: 4 },
  { date: "7/6/2019 02:49:13", shift: "A3", acuity: 3 },
  { date: "7/6/2019 02:49:17", shift: "B3", acuity: 3 },
  { date: "7/6/2019 02:49:54", shift: "A3", acuity: 6 },
  { date: "7/6/2019 03:00:56", shift: "B3", acuity: 6 },
  { date: "7/6/2019 03:06:21", shift: "A3", acuity: 4 },
  { date: "7/6/2019 03:13:23", shift: "B3", acuity: 4 },
  { date: "7/6/2019 03:23:44", shift: "A3", acuity: 3 },
  { date: "7/6/2019 03:24:18", shift: "B3", acuity: 4 },
  { date: "7/6/2019 03:31:52", shift: "A3", acuity: 3 },
  { date: "7/6/2019 03:48:04", shift: "B3", acuity: 4 },
  { date: "7/6/2019 04:13:58", shift: "A3", acuity: 4 },
  { date: "7/6/2019 04:23:00", shift: "B3", acuity: 6 },
  { date: "7/6/2019 05:00:42", shift: "A1", acuity: 4 },
  { date: "7/6/2019 05:10:36", shift: "B3", acuity: 4 },
  { date: "7/6/2019 05:33:33", shift: "A1", acuity: 6 },
  { date: "7/6/2019 05:42:53", shift: "A1", acuity: 3 },
  { date: "7/6/2019 06:00:24", shift: "B1", acuity: 3 },
  { date: "7/6/2019 06:00:33", shift: "A1", acuity: 6 },
  { date: "7/6/2019 06:06:46", shift: "B1", acuity: 3 },
  { date: "7/6/2019 06:16:15", shift: "A1", acuity: 5 },
  { date: "7/6/2019 06:30:38", shift: "B1", acuity: 4 },
  { date: "7/6/2019 06:41:16", shift: "B1", acuity: 6 },
  { date: "7/6/2019 06:52:10", shift: "A1", acuity: 6 },
  { date: "7/6/2019 07:32:32", shift: "B1", acuity: 3 },
  { date: "7/6/2019 07:47:21", shift: "A1", acuity: 3 },
  { date: "7/6/2019 08:09:22", shift: "B1", acuity: 3 },
  { date: "7/6/2019 08:31:31", shift: "C1", acuity: 3 },
  { date: "7/6/2019 08:41:51", shift: "A1", acuity: 6 },
  { date: "7/6/2019 08:52:21", shift: "B1", acuity: 6 },
  { date: "7/6/2019 09:02:33", shift: "C1", acuity: 3 },
  { date: "7/6/2019 09:06:26", shift: "C1", acuity: 3 },
  { date: "7/6/2019 09:19:08", shift: "A1", acuity: 3 },
  { date: "7/6/2019 09:23:16", shift: "B1", acuity: 6 },
  { date: "7/6/2019 09:43:49", shift: "C1", acuity: 6 },
  { date: "7/6/2019 09:43:59", shift: "A1", acuity: 3 },
  { date: "7/6/2019 10:01:10", shift: "D1", acuity: 4 },
  { date: "7/6/2019 10:01:22", shift: "B1", acuity: 3 },
  { date: "7/6/2019 10:04:48", shift: "C1", acuity: 3 },
  { date: "7/6/2019 10:20:24", shift: "A1", acuity: 6 },
  { date: "7/6/2019 10:24:39", shift: "D1", acuity: 3 },
  { date: "7/6/2019 10:34:54", shift: "D1", acuity: 2 },
  { date: "7/6/2019 10:40:12", shift: "D1", acuity: 3 },
  { date: "7/6/2019 10:46:15", shift: "B1", acuity: 6 },
  { date: "7/6/2019 10:53:50", shift: "C1", acuity: 4 },
  { date: "7/6/2019 11:02:50", shift: "A1", acuity: 6 },
  { date: "7/6/2019 11:11:38", shift: "D1", acuity: 3 },
  { date: "7/6/2019 11:35:00", shift: "B1", acuity: 3 },
  { date: "7/6/2019 12:04:07", shift: "C1", acuity: 6 },
  { date: "7/6/2019 12:17:32", shift: "A1", acuity: 6 },
  { date: "7/6/2019 12:17:51", shift: "D1", acuity: 4 },
  { date: "7/6/2019 12:32:17", shift: "B1", acuity: 4 },
  { date: "7/6/2019 12:32:32", shift: "C1", acuity: 3 },
  { date: "7/6/2019 12:32:46", shift: "A1", acuity: 3 },
  { date: "7/6/2019 12:41:43", shift: "D1", acuity: 3 },
  { date: "7/6/2019 12:57:23", shift: "B1", acuity: 3 },
  { date: "7/6/2019 13:05:10", shift: "A2", acuity: 3 },
  { date: "7/6/2019 13:05:42", shift: "C1", acuity: 6 },
  { date: "7/6/2019 13:57:54", shift: "A2", acuity: 6 },
  { date: "7/6/2019 14:28:09", shift: "A2", acuity: 3 },
  { date: "7/6/2019 14:40:30", shift: "B2", acuity: 3 },
  { date: "7/6/2019 14:54:44", shift: "B2", acuity: 3 },
  { date: "7/6/2019 15:03:54", shift: "B2", acuity: 6 },
  { date: "7/6/2019 15:16:49", shift: "D1", acuity: 3 },
  { date: "7/6/2019 15:21:10", shift: "C1", acuity: 3 },
  { date: "7/6/2019 15:21:23", shift: "A2", acuity: 3 },
  { date: "7/6/2019 15:21:37", shift: "B2", acuity: 3 },
  { date: "7/6/2019 15:36:29", shift: "D1", acuity: 3 },
  { date: "7/6/2019 15:39:54", shift: "C1", acuity: 3 },
  { date: "7/6/2019 15:48:31", shift: "A2", acuity: 6 },
  { date: "7/6/2019 15:50:09", shift: "B2", acuity: 6 },
  { date: "7/6/2019 15:58:03", shift: "D1", acuity: 6 },
  { date: "7/6/2019 16:08:00", shift: "C1", acuity: 6 },
  { date: "7/6/2019 16:22:12", shift: "A2", acuity: 3 },
  { date: "7/6/2019 16:22:29", shift: "B2", acuity: 3 },
  { date: "7/6/2019 16:23:05", shift: "D1", acuity: 6 },
  { date: "7/6/2019 16:30:38", shift: "C2", acuity: 6 },
  { date: "7/6/2019 16:53:39", shift: "A2", acuity: 6 },
  { date: "7/6/2019 16:56:44", shift: "B2", acuity: 3 },
  { date: "7/6/2019 17:13:23", shift: "C2", acuity: 3 },
  { date: "7/6/2019 17:38:43", shift: "C2", acuity: 3 },
  { date: "7/6/2019 17:56:51", shift: "D1", acuity: 6 },
  { date: "7/6/2019 17:57:11", shift: "A2", acuity: 4 },
  { date: "7/6/2019 18:29:41", shift: "D2", acuity: 3 },
  { date: "7/6/2019 18:30:04", shift: "D2", acuity: 6 },
  { date: "7/6/2019 18:30:39", shift: "D2", acuity: 6 },
  { date: "7/6/2019 18:50:12", shift: "B2", acuity: 3 },
  { date: "7/6/2019 18:54:05", shift: "C2", acuity: 6 },
  { date: "7/6/2019 18:58:33", shift: "A2", acuity: 3 },
  { date: "7/6/2019 19:13:09", shift: "D2", acuity: 3 },
  { date: "7/6/2019 19:22:34", shift: "B2", acuity: 3 },
  { date: "7/6/2019 19:42:44", shift: "C2", acuity: 3 },
  { date: "7/6/2019 19:43:21", shift: "A2", acuity: 3 },
  { date: "7/6/2019 19:43:36", shift: "D2", acuity: 3 },
  { date: "7/6/2019 19:47:00", shift: "B2", acuity: 3 },
  { date: "7/6/2019 20:04:44", shift: "C2", acuity: 3 },
  { date: "7/6/2019 20:05:01", shift: "A2", acuity: 3 },
  { date: "7/6/2019 20:05:17", shift: "D2", acuity: 3 },
  { date: "7/6/2019 20:09:31", shift: "B2", acuity: 6 },
  { date: "7/6/2019 20:22:27", shift: "C2", acuity: 6 },
  { date: "7/6/2019 20:23:08", shift: "A2", acuity: 3 },
  { date: "7/6/2019 20:34:37", shift: "D2", acuity: 6 },
  { date: "7/6/2019 20:56:00", shift: "B2", acuity: 3 },
  { date: "7/6/2019 21:04:58", shift: "A3", acuity: 4 },
  { date: "7/6/2019 21:16:22", shift: "C2", acuity: 3 },
  { date: "7/6/2019 21:24:05", shift: "D2", acuity: 6 },
  { date: "7/6/2019 21:33:28", shift: "A3", acuity: 3 },
  { date: "7/6/2019 21:35:20", shift: "A3", acuity: 3 },
  { date: "7/6/2019 21:35:33", shift: "B2", acuity: 6 },
  { date: "7/6/2019 21:46:12", shift: "C2", acuity: 3 },
  { date: "7/6/2019 21:56:11", shift: "D2", acuity: 6 },
  { date: "7/6/2019 22:19:59", shift: "B3", acuity: 6 },
  { date: "7/6/2019 22:23:56", shift: "A3", acuity: 3 },
  { date: "7/6/2019 22:24:13", shift: "C2", acuity: 6 },
  { date: "7/6/2019 22:35:50", shift: "B3", acuity: 3 },
  { date: "7/6/2019 22:50:33", shift: "B3", acuity: 3 },
  { date: "7/6/2019 23:00:05", shift: "D2", acuity: 6 },
  { date: "7/6/2019 23:04:03", shift: "A3", acuity: 6 },
  { date: "7/6/2019 23:16:03", shift: "C2", acuity: 4 },
  { date: "7/6/2019 23:24:37", shift: "B3", acuity: 3 },
  { date: "7/6/2019 23:26:07", shift: "D2", acuity: 5 },
  { date: "7/6/2019 23:41:51", shift: "A3", acuity: 6 },
  { date: "7/6/2019 23:59:59", shift: "C2", acuity: 3 },
  { date: "7/7/2019 00:02:37", shift: "B3", acuity: 3 },
  { date: "7/7/2019 00:05:51", shift: "D2", acuity: 4 },
  { date: "7/7/2019 00:10:39", shift: "A3", acuity: 3 },
  { date: "7/7/2019 00:11:17", shift: "B3", acuity: 4 },
  { date: "7/7/2019 00:18:52", shift: "D2", acuity: 3 },
  { date: "7/7/2019 00:38:31", shift: "A3", acuity: 3 },
  { date: "7/7/2019 00:46:26", shift: "B3", acuity: 3 },
  { date: "7/7/2019 00:57:54", shift: "D2", acuity: 3 },
  { date: "7/7/2019 01:03:18", shift: "A3", acuity: 4 },
  { date: "7/7/2019 01:11:19", shift: "B3", acuity: 4 },
  { date: "7/7/2019 01:11:30", shift: "D2", acuity: 3 },
  { date: "7/7/2019 01:14:30", shift: "A3", acuity: 4 },
  { date: "7/7/2019 01:23:27", shift: "B3", acuity: 4 },
  { date: "7/7/2019 01:33:19", shift: "A3", acuity: 3 },
  { date: "7/7/2019 01:38:38", shift: "B3", acuity: 4 },
  { date: "7/7/2019 01:40:14", shift: "A3", acuity: 3 },
  { date: "7/7/2019 02:10:53", shift: "B3", acuity: 4 },
  { date: "7/7/2019 02:26:36", shift: "A3", acuity: 3 },
  { date: "7/7/2019 02:27:06", shift: "B3", acuity: 3 },
  { date: "7/7/2019 02:50:47", shift: "A3", acuity: 4 },
  { date: "7/7/2019 03:09:06", shift: "B3", acuity: 3 },
  { date: "7/7/2019 03:21:33", shift: "A3", acuity: 6 },
  { date: "7/7/2019 03:28:15", shift: "B3", acuity: 5 },
  { date: "7/7/2019 03:37:53", shift: "A3", acuity: 6 },
  { date: "7/7/2019 04:03:43", shift: "B3", acuity: 6 },
  { date: "7/7/2019 04:45:35", shift: "A3", acuity: 6 },
  { date: "7/7/2019 05:09:33", shift: "A1", acuity: 4 },
  { date: "7/7/2019 05:10:00", shift: "B3", acuity: 3 },
  { date: "7/7/2019 05:49:48", shift: "A1", acuity: 3 },
  { date: "7/7/2019 06:19:17", shift: "A1", acuity: 2 },
  { date: "7/7/2019 06:29:48", shift: "B1", acuity: 6 },
  { date: "7/7/2019 06:34:59", shift: "B1", acuity: 3 },
  { date: "7/7/2019 06:43:31", shift: "B1", acuity: 3 },
  { date: "7/7/2019 07:09:00", shift: "A1", acuity: 3 },
  { date: "7/7/2019 07:11:54", shift: "B1", acuity: 6 },
  { date: "7/7/2019 07:26:03", shift: "A1", acuity: 3 },
  { date: "7/7/2019 07:37:50", shift: "B1", acuity: 4 },
  { date: "7/7/2019 07:53:06", shift: "A1", acuity: 3 },
  { date: "7/7/2019 08:14:29", shift: "B1", acuity: 3 },
  { date: "7/7/2019 08:19:36", shift: "A1", acuity: 3 },
  { date: "7/7/2019 08:19:52", shift: "B1", acuity: 6 },
  { date: "7/7/2019 08:25:50", shift: "A1", acuity: 4 },
  { date: "7/7/2019 08:47:18", shift: "C1", acuity: 4 },
  { date: "7/7/2019 08:58:17", shift: "B1", acuity: 3 },
  { date: "7/7/2019 09:14:08", shift: "C1", acuity: 3 },
  { date: "7/7/2019 09:30:19", shift: "C1", acuity: 3 },
  { date: "7/7/2019 09:37:35", shift: "A1", acuity: 3 },
  { date: "7/7/2019 09:37:51", shift: "B1", acuity: 6 },
  { date: "7/7/2019 09:45:52", shift: "C1", acuity: 3 },
  { date: "7/7/2019 10:05:50", shift: "D1", acuity: 3 },
  { date: "7/7/2019 10:06:17", shift: "A1", acuity: 6 },
  { date: "7/7/2019 10:17:46", shift: "B1", acuity: 6 },
  { date: "7/7/2019 10:18:03", shift: "C1", acuity: 4 },
  { date: "7/7/2019 10:35:15", shift: "D1", acuity: 4 },
  { date: "7/7/2019 10:46:16", shift: "D1", acuity: 3 },
  { date: "7/7/2019 11:21:35", shift: "A1", acuity: 6 },
  { date: "7/7/2019 11:21:56", shift: "B1", acuity: 6 },
  { date: "7/7/2019 11:35:41", shift: "C1", acuity: 6 },
  { date: "7/7/2019 11:36:27", shift: "D1", acuity: 3 },
  { date: "7/7/2019 12:01:12", shift: "A1", acuity: 3 },
  { date: "7/7/2019 12:02:07", shift: "B1", acuity: 3 },
  { date: "7/7/2019 12:02:33", shift: "C1", acuity: 4 },
  { date: "7/7/2019 12:17:44", shift: "D1", acuity: 3 },
  { date: "7/7/2019 12:27:37", shift: "A1", acuity: 3 },
  { date: "7/7/2019 12:35:17", shift: "B1", acuity: 6 },
  { date: "7/7/2019 12:49:27", shift: "C1", acuity: 6 },
  { date: "7/7/2019 12:54:32", shift: "D1", acuity: 3 },
  { date: "7/7/2019 13:14:12", shift: "A2", acuity: 6 },
  { date: "7/7/2019 13:14:35", shift: "B1", acuity: 3 },
  { date: "7/7/2019 13:20:40", shift: "C1", acuity: 3 },
  { date: "7/7/2019 14:03:11", shift: "A2", acuity: 3 },
  { date: "7/7/2019 14:03:18", shift: "A2", acuity: 6 },
  { date: "7/7/2019 14:26:04", shift: "B2", acuity: 6 },
  { date: "7/7/2019 14:38:49", shift: "B2", acuity: 6 },
  { date: "7/7/2019 14:41:01", shift: "B2", acuity: 6 },
  { date: "7/7/2019 14:47:57", shift: "D1", acuity: 3 },
  { date: "7/7/2019 14:56:13", shift: "C1", acuity: 6 },
  { date: "7/7/2019 14:56:46", shift: "A2", acuity: 6 },
  { date: "7/7/2019 15:14:04", shift: "B2", acuity: 6 },
  { date: "7/7/2019 15:41:46", shift: "D1", acuity: 6 },
  { date: "7/7/2019 15:42:31", shift: "C1", acuity: 6 },
  { date: "7/7/2019 15:44:24", shift: "A2", acuity: 6 },
  { date: "7/7/2019 15:44:36", shift: "B2", acuity: 6 },
  { date: "7/7/2019 15:44:53", shift: "D1", acuity: 3 },
  { date: "7/7/2019 16:15:16", shift: "C1", acuity: 3 },
  { date: "7/7/2019 16:33:45", shift: "C2", acuity: 3 },
  { date: "7/7/2019 16:34:00", shift: "A2", acuity: 3 },
  { date: "7/7/2019 16:37:31", shift: "B2", acuity: 6 },
  { date: "7/7/2019 17:05:15", shift: "C2", acuity: 6 },
  { date: "7/7/2019 17:07:40", shift: "C2", acuity: 4 },
  { date: "7/7/2019 17:09:04", shift: "D1", acuity: 3 },
  { date: "7/7/2019 17:17:23", shift: "A2", acuity: 6 },
  { date: "7/7/2019 17:32:20", shift: "B2", acuity: 3 },
  { date: "7/7/2019 17:39:53", shift: "C2", acuity: 6 },
  { date: "7/7/2019 17:57:54", shift: "D1", acuity: 6 },
  { date: "7/7/2019 18:07:55", shift: "D2", acuity: 6 },
  { date: "7/7/2019 18:34:09", shift: "D2", acuity: 2 },
  { date: "7/7/2019 18:37:44", shift: "D2", acuity: 6 },
  { date: "7/7/2019 18:37:57", shift: "A2", acuity: 3 },
  { date: "7/7/2019 18:38:10", shift: "B2", acuity: 3 },
  { date: "7/7/2019 18:38:24", shift: "C2", acuity: 6 },
  { date: "7/7/2019 18:58:49", shift: "D2", acuity: 6 },
  { date: "7/7/2019 18:59:13", shift: "A2", acuity: 4 },
  { date: "7/7/2019 19:06:54", shift: "B2", acuity: 3 },
  { date: "7/7/2019 19:07:11", shift: "C2", acuity: 3 },
  { date: "7/7/2019 19:07:23", shift: "D2", acuity: 3 },
  { date: "7/7/2019 19:35:11", shift: "A2", acuity: 3 },
  { date: "7/7/2019 19:35:57", shift: "B2", acuity: 6 },
  { date: "7/7/2019 19:44:05", shift: "C2", acuity: 3 },
  { date: "7/7/2019 19:44:20", shift: "D2", acuity: 6 },
  { date: "7/7/2019 19:56:07", shift: "A2", acuity: 4 },
  { date: "7/7/2019 20:00:21", shift: "B2", acuity: 3 },
  { date: "7/7/2019 20:00:38", shift: "C2", acuity: 3 },
  { date: "7/7/2019 20:16:46", shift: "D2", acuity: 6 },
  { date: "7/7/2019 20:26:25", shift: "A2", acuity: 3 },
  { date: "7/7/2019 20:37:53", shift: "B2", acuity: 3 },
  { date: "7/7/2019 20:48:03", shift: "C2", acuity: 6 },
  { date: "7/7/2019 20:58:53", shift: "D2", acuity: 6 },
  { date: "7/7/2019 20:59:21", shift: "A2", acuity: 3 },
  { date: "7/7/2019 21:09:50", shift: "A3", acuity: 6 },
  { date: "7/7/2019 21:11:52", shift: "B2", acuity: 2 },
  { date: "7/7/2019 21:36:04", shift: "A3", acuity: 6 },
  { date: "7/7/2019 21:40:09", shift: "A3", acuity: 3 },
  { date: "7/7/2019 21:45:31", shift: "C2", acuity: 3 },
  { date: "7/7/2019 21:47:52", shift: "D2", acuity: 3 },
  { date: "7/7/2019 22:00:27", shift: "B3", acuity: 3 },
  { date: "7/7/2019 22:01:25", shift: "A3", acuity: 3 },
  { date: "7/7/2019 22:09:15", shift: "C2", acuity: 2 },
  { date: "7/7/2019 22:36:06", shift: "B3", acuity: 3 },
  { date: "7/7/2019 22:42:34", shift: "B3", acuity: 6 },
  { date: "7/7/2019 22:42:57", shift: "D2", acuity: 6 },
  { date: "7/7/2019 23:02:18", shift: "A3", acuity: 6 },
  { date: "7/7/2019 23:17:50", shift: "C2", acuity: 6 },
  { date: "7/7/2019 23:42:24", shift: "B3", acuity: 3 },
  { date: "7/7/2019 23:42:46", shift: "D2", acuity: 3 },
  { date: "7/7/2019 23:43:05", shift: "A3", acuity: 3 },
  { date: "7/7/2019 23:43:37", shift: "C2", acuity: 4 },
  { date: "7/7/2019 23:53:17", shift: "B3", acuity: 4 },
  { date: "7/8/2019 00:05:41", shift: "D2", acuity: 3 },
  { date: "7/8/2019 00:34:52", shift: "A3", acuity: 4 },
  { date: "7/8/2019 00:40:16", shift: "B3", acuity: 3 },
  { date: "7/8/2019 00:50:12", shift: "D2", acuity: 6 },
  { date: "7/8/2019 01:15:21", shift: "A3", acuity: 6 },
  { date: "7/8/2019 01:26:00", shift: "B3", acuity: 3 },
  { date: "7/8/2019 01:26:27", shift: "D2", acuity: 6 },
  { date: "7/8/2019 01:41:16", shift: "A3", acuity: 6 },
  { date: "7/8/2019 01:41:42", shift: "B3", acuity: 4 },
  { date: "7/8/2019 01:44:12", shift: "A3", acuity: 4 },
  { date: "7/8/2019 01:53:02", shift: "B3", acuity: 6 },
  { date: "7/8/2019 02:22:43", shift: "A3", acuity: 4 },
  { date: "7/8/2019 02:35:26", shift: "B3", acuity: 4 },
  { date: "7/8/2019 02:35:43", shift: "A3", acuity: 4 },
  { date: "7/8/2019 02:43:31", shift: "B3", acuity: 3 },
  { date: "7/8/2019 03:06:34", shift: "A3", acuity: 3 },
  { date: "7/8/2019 03:13:29", shift: "B3", acuity: 4 },
  { date: "7/8/2019 03:26:02", shift: "A3", acuity: 4 },
  { date: "7/8/2019 03:56:43", shift: "B3", acuity: 3 },
  { date: "7/8/2019 04:01:35", shift: "A3", acuity: 3 },
  { date: "7/8/2019 04:07:40", shift: "B3", acuity: 6 },
  { date: "7/8/2019 04:30:45", shift: "A3", acuity: 3 },
  { date: "7/8/2019 04:31:00", shift: "B3", acuity: 7 },
  { date: "7/8/2019 04:48:04", shift: "A3", acuity: 3 },
  { date: "7/8/2019 04:48:18", shift: "B3", acuity: 3 },
  { date: "7/8/2019 05:20:19", shift: "A1", acuity: 3 },
  { date: "7/8/2019 06:21:01", shift: "A1", acuity: 4 },
  { date: "7/8/2019 06:37:52", shift: "A1", acuity: 4 },
  { date: "7/8/2019 07:40:37", shift: "B1", acuity: 6 },
  { date: "7/8/2019 07:45:35", shift: "B1", acuity: 6 },
  { date: "7/8/2019 07:51:09", shift: "A1", acuity: 3 },
  { date: "7/8/2019 07:56:15", shift: "B1", acuity: 6 },
  { date: "7/8/2019 08:05:07", shift: "A1", acuity: 6 },
  { date: "7/8/2019 08:11:27", shift: "B1", acuity: 3 },
  { date: "7/8/2019 09:01:49", shift: "C1", acuity: 6 },
  { date: "7/8/2019 09:20:28", shift: "C1", acuity: 6 },
  { date: "7/8/2019 09:29:06", shift: "C1", acuity: 6 },
  { date: "7/8/2019 09:30:36", shift: "A1", acuity: 6 },
  { date: "7/8/2019 09:33:05", shift: "B1", acuity: 3 },
  { date: "7/8/2019 09:33:23", shift: "C1", acuity: 3 },
  { date: "7/8/2019 09:57:12", shift: "A1", acuity: 6 },
  { date: "7/8/2019 10:03:40", shift: "D1", acuity: 3 },
  { date: "7/8/2019 10:17:02", shift: "B1", acuity: 3 },
  { date: "7/8/2019 10:38:09", shift: "D1", acuity: 6 },
  { date: "7/8/2019 10:54:12", shift: "D1", acuity: 3 },
  { date: "7/8/2019 11:12:25", shift: "C1", acuity: 3 },
  { date: "7/8/2019 11:23:14", shift: "A1", acuity: 6 },
  { date: "7/8/2019 11:39:35", shift: "B1", acuity: 3 },
  { date: "7/8/2019 11:59:13", shift: "D1", acuity: 3 },
  { date: "7/8/2019 12:13:57", shift: "C1", acuity: 3 },
  { date: "7/8/2019 12:37:30", shift: "A1", acuity: 3 },
  { date: "7/8/2019 12:43:49", shift: "B1", acuity: 4 },
  { date: "7/8/2019 12:58:49", shift: "D1", acuity: 3 },
  { date: "7/8/2019 13:12:48", shift: "A2", acuity: 3 },
  { date: "7/8/2019 13:13:15", shift: "C1", acuity: 6 },
  { date: "7/8/2019 13:21:26", shift: "B1", acuity: 3 },
  { date: "7/8/2019 13:26:44", shift: "D1", acuity: 6 },
  { date: "7/8/2019 13:31:32", shift: "A2", acuity: 3 },
  { date: "7/8/2019 13:56:56", shift: "A2", acuity: 6 },
  { date: "7/8/2019 14:19:43", shift: "B2", acuity: 6 },
  { date: "7/8/2019 15:15:04", shift: "B2", acuity: 3 },
  { date: "7/8/2019 15:17:39", shift: "B2", acuity: 3 },
  { date: "7/8/2019 15:18:36", shift: "C1", acuity: 6 },
  { date: "7/8/2019 15:20:00", shift: "D1", acuity: 3 },
  { date: "7/8/2019 15:22:44", shift: "A2", acuity: 3 },
  { date: "7/8/2019 15:28:02", shift: "B2", acuity: 3 },
  { date: "7/8/2019 15:28:17", shift: "C1", acuity: 3 },
  { date: "7/8/2019 15:39:00", shift: "D1", acuity: 6 },
  { date: "7/8/2019 15:39:16", shift: "A2", acuity: 6 },
  { date: "7/8/2019 15:47:19", shift: "B2", acuity: 6 },
  { date: "7/8/2019 16:09:37", shift: "C1", acuity: 3 },
  { date: "7/8/2019 16:09:50", shift: "D1", acuity: 3 },
  { date: "7/8/2019 16:18:58", shift: "A2", acuity: 6 },
  { date: "7/8/2019 16:27:41", shift: "B2", acuity: 3 },
  { date: "7/8/2019 16:28:17", shift: "C1", acuity: 6 },
  { date: "7/8/2019 16:34:19", shift: "C2", acuity: 3 },
  { date: "7/8/2019 16:37:42", shift: "D1", acuity: 3 },
  { date: "7/8/2019 16:56:53", shift: "A2", acuity: 6 },
  { date: "7/8/2019 16:57:21", shift: "B2", acuity: 3 },
  { date: "7/8/2019 17:09:54", shift: "C2", acuity: 3 },
  { date: "7/8/2019 17:17:53", shift: "C2", acuity: 6 },
  { date: "7/8/2019 17:18:05", shift: "D1", acuity: 3 },
  { date: "7/8/2019 17:18:29", shift: "A2", acuity: 3 },
  { date: "7/8/2019 17:25:13", shift: "B2", acuity: 3 },
  { date: "7/8/2019 17:47:00", shift: "C2", acuity: 3 },
  { date: "7/8/2019 17:53:15", shift: "D1", acuity: 3 },
  { date: "7/8/2019 18:00:59", shift: "D2", acuity: 6 },
  { date: "7/8/2019 18:19:50", shift: "A2", acuity: 6 },
  { date: "7/8/2019 18:31:39", shift: "D2", acuity: 6 },
  { date: "7/8/2019 18:47:27", shift: "D2", acuity: 4 },
  { date: "7/8/2019 19:12:20", shift: "B2", acuity: 6 },
  { date: "7/8/2019 19:15:10", shift: "C2", acuity: 6 },
  { date: "7/8/2019 19:15:44", shift: "A2", acuity: 6 },
  { date: "7/8/2019 19:20:45", shift: "D2", acuity: 6 },
  { date: "7/8/2019 19:29:08", shift: "B2", acuity: 3 },
  { date: "7/8/2019 19:30:51", shift: "C2", acuity: 3 },
  { date: "7/8/2019 19:31:22", shift: "A2", acuity: 6 },
  { date: "7/8/2019 19:31:58", shift: "D2", acuity: 6 },
  { date: "7/8/2019 19:38:23", shift: "B2", acuity: 3 },
  { date: "7/8/2019 19:38:50", shift: "C2", acuity: 3 },
  { date: "7/8/2019 19:41:10", shift: "A2", acuity: 6 },
  { date: "7/8/2019 19:50:25", shift: "D2", acuity: 6 },
  { date: "7/8/2019 19:55:49", shift: "B2", acuity: 6 },
  { date: "7/8/2019 20:06:33", shift: "C2", acuity: 7 },
  { date: "7/8/2019 20:24:28", shift: "A2", acuity: 6 },
  { date: "7/8/2019 20:28:25", shift: "D2", acuity: 7 },
  { date: "7/8/2019 20:42:30", shift: "B2", acuity: 3 },
  { date: "7/8/2019 20:48:42", shift: "C2", acuity: 3 },
  { date: "7/8/2019 20:49:15", shift: "A2", acuity: 3 },
  { date: "7/8/2019 21:10:36", shift: "A3", acuity: 3 },
  { date: "7/8/2019 21:16:49", shift: "D2", acuity: 6 },
  { date: "7/8/2019 21:20:02", shift: "B2", acuity: 3 },
  { date: "7/8/2019 21:23:55", shift: "C2", acuity: 6 },
  { date: "7/8/2019 21:24:25", shift: "A3", acuity: 6 },
  { date: "7/8/2019 21:36:06", shift: "A3", acuity: 3 },
  { date: "7/8/2019 21:54:31", shift: "A3", acuity: 3 },
  { date: "7/8/2019 21:55:18", shift: "D2", acuity: 3 },
  { date: "7/8/2019 21:55:46", shift: "B2", acuity: 4 },
  { date: "7/8/2019 22:06:18", shift: "B3", acuity: 3 },
  { date: "7/8/2019 22:06:44", shift: "C2", acuity: 3 },
  { date: "7/8/2019 22:13:29", shift: "A3", acuity: 6 },
  { date: "7/8/2019 22:23:37", shift: "D2", acuity: 4 },
  { date: "7/8/2019 22:24:13", shift: "B3", acuity: 4 },
  { date: "7/8/2019 22:30:54", shift: "B3", acuity: 3 },
  { date: "7/8/2019 22:38:11", shift: "B3", acuity: 6 },
  { date: "7/8/2019 22:45:15", shift: "C2", acuity: 3 },
  { date: "7/8/2019 22:52:42", shift: "A3", acuity: 4 },
  { date: "7/8/2019 23:02:57", shift: "D2", acuity: 3 },
  { date: "7/8/2019 23:03:29", shift: "B3", acuity: 4 },
  { date: "7/8/2019 23:03:46", shift: "C2", acuity: 3 },
  { date: "7/8/2019 23:21:15", shift: "A3", acuity: 6 },
  { date: "7/8/2019 23:29:32", shift: "D2", acuity: 6 },
  { date: "7/8/2019 23:40:00", shift: "B3", acuity: 3 },
  { date: "7/8/2019 23:45:41", shift: "C2", acuity: 2 },
  { date: "7/8/2019 23:47:12", shift: "A3", acuity: 6 },
  { date: "7/9/2019 00:06:44", shift: "D2", acuity: 3 },
  { date: "7/9/2019 00:07:02", shift: "B3", acuity: 3 },
  { date: "7/9/2019 00:09:49", shift: "A3", acuity: 7 },
  { date: "7/9/2019 00:17:26", shift: "D2", acuity: 7 },
  { date: "7/9/2019 00:25:08", shift: "B3", acuity: 4 },
  { date: "7/9/2019 00:25:27", shift: "A3", acuity: 3 },
  { date: "7/9/2019 00:25:40", shift: "D2", acuity: 4 },
  { date: "7/9/2019 00:34:48", shift: "B3", acuity: 3 },
  { date: "7/9/2019 00:38:51", shift: "A3", acuity: 3 },
  { date: "7/9/2019 00:53:07", shift: "D2", acuity: 3 },
  { date: "7/9/2019 00:54:00", shift: "B3", acuity: 3 },
  { date: "7/9/2019 01:11:49", shift: "A3", acuity: 3 },
  { date: "7/9/2019 01:39:35", shift: "B3", acuity: 6 },
  { date: "7/9/2019 01:39:57", shift: "A3", acuity: 3 },
  { date: "7/9/2019 01:51:48", shift: "B3", acuity: 6 },
  { date: "7/9/2019 02:12:34", shift: "A3", acuity: 4 },
  { date: "7/9/2019 02:12:56", shift: "B3", acuity: 6 },
  { date: "7/9/2019 02:20:37", shift: "A3", acuity: 6 },
  { date: "7/9/2019 02:35:52", shift: "B3", acuity: 4 },
  { date: "7/9/2019 02:37:51", shift: "A3", acuity: 6 },
  { date: "7/9/2019 02:44:41", shift: "B3", acuity: 6 },
  { date: "7/9/2019 02:50:45", shift: "A3", acuity: 3 },
  { date: "7/9/2019 03:16:47", shift: "B3", acuity: 6 },
  { date: "7/9/2019 03:18:28", shift: "A3", acuity: 4 },
  { date: "7/9/2019 03:31:57", shift: "B3", acuity: 6 },
  { date: "7/9/2019 03:40:29", shift: "A3", acuity: 6 },
  { date: "7/9/2019 03:45:45", shift: "B3", acuity: 4 },
  { date: "7/9/2019 04:08:21", shift: "A3", acuity: 4 },
  { date: "7/9/2019 04:25:46", shift: "B3", acuity: 4 },
  { date: "7/9/2019 05:22:42", shift: "A1", acuity: 6 },
  { date: "7/9/2019 05:49:21", shift: "A1", acuity: 6 },
  { date: "7/9/2019 06:01:55", shift: "A1", acuity: 4 },
  { date: "7/9/2019 06:57:48", shift: "B1", acuity: 3 },
  { date: "7/9/2019 07:24:03", shift: "B1", acuity: 6 },
  { date: "7/9/2019 07:24:34", shift: "B1", acuity: 3 },
  { date: "7/9/2019 07:39:10", shift: "A1", acuity: 3 },
  { date: "7/9/2019 08:30:07", shift: "C1", acuity: 3 },
  { date: "7/9/2019 08:43:29", shift: "B1", acuity: 3 },
  { date: "7/9/2019 08:53:18", shift: "A1", acuity: 6 },
  { date: "7/9/2019 09:02:15", shift: "C1", acuity: 3 },
  { date: "7/9/2019 09:11:59", shift: "C1", acuity: 6 },
  { date: "7/9/2019 09:12:13", shift: "B1", acuity: 3 },
  { date: "7/9/2019 09:24:39", shift: "A1", acuity: 3 },
  { date: "7/9/2019 09:40:05", shift: "C1", acuity: 4 },
  { date: "7/9/2019 09:40:22", shift: "B1", acuity: 4 },
  { date: "7/9/2019 10:14:10", shift: "D1", acuity: 3 },
  { date: "7/9/2019 10:26:06", shift: "A1", acuity: 6 },
  { date: "7/9/2019 10:32:56", shift: "D1", acuity: 3 },
  { date: "7/9/2019 10:34:02", shift: "D1", acuity: 3 },
  { date: "7/9/2019 10:41:27", shift: "C1", acuity: 3 },
  { date: "7/9/2019 10:41:51", shift: "B1", acuity: 3 },
  { date: "7/9/2019 10:43:40", shift: "A1", acuity: 4 },
  { date: "7/9/2019 10:46:45", shift: "D1", acuity: 3 },
  { date: "7/9/2019 10:50:15", shift: "C1", acuity: 6 },
  { date: "7/9/2019 10:50:52", shift: "B1", acuity: 3 },
  { date: "7/9/2019 11:24:19", shift: "A1", acuity: 3 },
  { date: "7/9/2019 11:24:35", shift: "D1", acuity: 3 },
  { date: "7/9/2019 11:26:25", shift: "C1", acuity: 3 },
  { date: "7/9/2019 11:59:14", shift: "B1", acuity: 3 },
  { date: "7/9/2019 12:01:36", shift: "A1", acuity: 6 },
  { date: "7/9/2019 12:22:55", shift: "D1", acuity: 3 },
  { date: "7/9/2019 12:29:20", shift: "C1", acuity: 4 },
  { date: "7/9/2019 12:31:29", shift: "B1", acuity: 2 },
  { date: "7/9/2019 12:39:53", shift: "A1", acuity: 3 },
  { date: "7/9/2019 12:54:40", shift: "D1", acuity: 6 },
  { date: "7/9/2019 13:00:52", shift: "A2", acuity: 3 },
  { date: "7/9/2019 13:01:38", shift: "C1", acuity: 6 },
  { date: "7/9/2019 13:26:58", shift: "B1", acuity: 6 },
  { date: "7/9/2019 13:34:54", shift: "A2", acuity: 6 },
  { date: "7/9/2019 13:37:49", shift: "A2", acuity: 2 },
  { date: "7/9/2019 13:42:21", shift: "D1", acuity: 6 },
  { date: "7/9/2019 13:44:40", shift: "C1", acuity: 6 },
  { date: "7/9/2019 13:45:09", shift: "B1", acuity: 3 },
  { date: "7/9/2019 13:55:05", shift: "A2", acuity: 6 },
  { date: "7/9/2019 14:02:48", shift: "B2", acuity: 3 },
  { date: "7/9/2019 14:26:36", shift: "D1", acuity: 6 },
  { date: "7/9/2019 14:33:44", shift: "B2", acuity: 3 },
  { date: "7/9/2019 14:57:05", shift: "B2", acuity: 6 },
  { date: "7/9/2019 15:07:01", shift: "C1", acuity: 6 },
  { date: "7/9/2019 15:12:03", shift: "A2", acuity: 6 },
  { date: "7/9/2019 15:27:08", shift: "D1", acuity: 4 },
  { date: "7/9/2019 15:37:22", shift: "B2", acuity: 3 },
  { date: "7/9/2019 15:43:02", shift: "C1", acuity: 3 },
  { date: "7/9/2019 15:57:13", shift: "A2", acuity: 3 },
  { date: "7/9/2019 16:11:35", shift: "D1", acuity: 2 },
  { date: "7/9/2019 16:16:21", shift: "B2", acuity: 3 },
  { date: "7/9/2019 16:38:57", shift: "C2", acuity: 3 },
  { date: "7/9/2019 16:47:47", shift: "A2", acuity: 3 },
  { date: "7/9/2019 16:48:54", shift: "D1", acuity: 6 },
  { date: "7/9/2019 16:49:09", shift: "B2", acuity: 3 },
  { date: "7/9/2019 17:23:22", shift: "C2", acuity: 6 },
  { date: "7/9/2019 17:23:25", shift: "C2", acuity: 3 },
  { date: "7/9/2019 17:23:50", shift: "A2", acuity: 6 },
  { date: "7/9/2019 17:38:51", shift: "D1", acuity: 6 },
  { date: "7/9/2019 17:39:09", shift: "B2", acuity: 3 },
  { date: "7/9/2019 18:09:36", shift: "D2", acuity: 3 },
  { date: "7/9/2019 18:12:35", shift: "C2", acuity: 6 },
  { date: "7/9/2019 18:17:41", shift: "A2", acuity: 3 },
  { date: "7/9/2019 18:18:50", shift: "B2", acuity: 6 },
  { date: "7/9/2019 18:24:34", shift: "D2", acuity: 3 },
  { date: "7/9/2019 18:25:29", shift: "C2", acuity: 6 },
  { date: "7/9/2019 18:26:06", shift: "A2", acuity: 3 },
  { date: "7/9/2019 18:42:37", shift: "D2", acuity: 3 },
  { date: "7/9/2019 19:18:46", shift: "D2", acuity: 6 },
  { date: "7/9/2019 19:19:27", shift: "B2", acuity: 6 },
  { date: "7/9/2019 19:19:54", shift: "C2", acuity: 3 },
  { date: "7/9/2019 19:20:10", shift: "A2", acuity: 3 },
  { date: "7/9/2019 19:35:38", shift: "D2", acuity: 2 },
  { date: "7/9/2019 19:35:52", shift: "B2", acuity: 6 },
  { date: "7/9/2019 19:36:15", shift: "C2", acuity: 3 },
  { date: "7/9/2019 19:36:32", shift: "A2", acuity: 6 },
  { date: "7/9/2019 19:36:48", shift: "D2", acuity: 3 },
  { date: "7/9/2019 19:46:21", shift: "B2", acuity: 6 },
  { date: "7/9/2019 20:09:39", shift: "C2", acuity: 3 },
  { date: "7/9/2019 20:29:57", shift: "A2", acuity: 6 },
  { date: "7/9/2019 20:38:18", shift: "D2", acuity: 3 },
  { date: "7/9/2019 20:43:33", shift: "B2", acuity: 6 },
  { date: "7/9/2019 21:03:18", shift: "A3", acuity: 6 },
  { date: "7/9/2019 21:03:52", shift: "C2", acuity: 3 },
  { date: "7/9/2019 21:06:47", shift: "D2", acuity: 4 },
  { date: "7/9/2019 21:12:47", shift: "B2", acuity: 6 },
  { date: "7/9/2019 21:20:55", shift: "A3", acuity: 6 },
  { date: "7/9/2019 21:40:18", shift: "A3", acuity: 3 },
  { date: "7/9/2019 21:42:17", shift: "A3", acuity: 3 },
  { date: "7/9/2019 22:04:58", shift: "B3", acuity: 3 },
  { date: "7/9/2019 22:06:31", shift: "C2", acuity: 3 },
  { date: "7/9/2019 22:07:10", shift: "D2", acuity: 3 },
  { date: "7/9/2019 22:17:05", shift: "A3", acuity: 3 },
  { date: "7/9/2019 22:27:07", shift: "B3", acuity: 4 },
  { date: "7/9/2019 22:27:23", shift: "C2", acuity: 3 },
  { date: "7/9/2019 22:41:27", shift: "B3", acuity: 6 },
  { date: "7/9/2019 22:47:41", shift: "B3", acuity: 3 },
  { date: "7/9/2019 23:03:43", shift: "D2", acuity: 6 },
  { date: "7/9/2019 23:03:59", shift: "A3", acuity: 4 },
  { date: "7/9/2019 23:04:26", shift: "C2", acuity: 3 },
  { date: "7/9/2019 23:14:51", shift: "B3", acuity: 3 },
  { date: "7/9/2019 23:25:32", shift: "D2", acuity: 6 },
  { date: "7/9/2019 23:28:34", shift: "A3", acuity: 3 },
  { date: "7/9/2019 23:33:13", shift: "C2", acuity: 3 },
  { date: "7/9/2019 23:39:01", shift: "B3", acuity: 3 },
  { date: "7/9/2019 23:47:54", shift: "D2", acuity: 3 },
  { date: "7/9/2019 23:48:10", shift: "A3", acuity: 4 },
  { date: "7/10/2019 00:06:43", shift: "B3", acuity: 3 },
  { date: "7/10/2019 00:06:58", shift: "D2", acuity: 3 },
  { date: "7/10/2019 00:13:20", shift: "A3", acuity: 3 },
  { date: "7/10/2019 00:35:56", shift: "B3", acuity: 3 },
  { date: "7/10/2019 00:46:46", shift: "D2", acuity: 4 },
  { date: "7/10/2019 01:13:14", shift: "A3", acuity: 6 },
  { date: "7/10/2019 01:23:10", shift: "B3", acuity: 3 },
  { date: "7/10/2019 01:50:10", shift: "A3", acuity: 4 },
  { date: "7/10/2019 01:51:31", shift: "B3", acuity: 6 },
  { date: "7/10/2019 02:08:55", shift: "A3", acuity: 3 },
  { date: "7/10/2019 02:32:44", shift: "B3", acuity: 3 },
  { date: "7/10/2019 02:34:01", shift: "A3", acuity: 4 },
  { date: "7/10/2019 02:45:24", shift: "B3", acuity: 3 },
  { date: "7/10/2019 02:51:33", shift: "A3", acuity: 4 },
  { date: "7/10/2019 03:25:41", shift: "B3", acuity: 4 },
  { date: "7/10/2019 03:37:28", shift: "A3", acuity: 6 },
  { date: "7/10/2019 03:53:29", shift: "B3", acuity: 3 },
  { date: "7/10/2019 04:35:43", shift: "A3", acuity: 3 },
  { date: "7/10/2019 04:45:26", shift: "B3", acuity: 4 },
  { date: "7/10/2019 05:17:24", shift: "A1", acuity: 4 },
  { date: "7/10/2019 05:36:16", shift: "A1", acuity: 3 },
  { date: "7/10/2019 05:50:52", shift: "A1", acuity: 3 },
  { date: "7/10/2019 06:03:20", shift: "B1", acuity: 3 },
  { date: "7/10/2019 06:04:54", shift: "A1", acuity: 3 },
  { date: "7/10/2019 06:16:18", shift: "B1", acuity: 3 },
  { date: "7/10/2019 06:30:52", shift: "B1", acuity: 3 },
  { date: "7/10/2019 06:52:19", shift: "B1", acuity: 3 },
  { date: "7/10/2019 07:10:05", shift: "A1", acuity: 6 },
  { date: "7/10/2019 07:23:27", shift: "B1", acuity: 4 },
  { date: "7/10/2019 07:24:12", shift: "A1", acuity: 3 },
  { date: "7/10/2019 07:27:17", shift: "B1", acuity: 3 },
  { date: "7/10/2019 07:55:46", shift: "A1", acuity: 3 },
  { date: "7/10/2019 08:22:25", shift: "B1", acuity: 4 },
  { date: "7/10/2019 08:40:11", shift: "C1", acuity: 2 },
  { date: "7/10/2019 09:14:18", shift: "C1", acuity: 3 },
  { date: "7/10/2019 09:19:33", shift: "C1", acuity: 3 },
  { date: "7/10/2019 09:27:57", shift: "A1", acuity: 3 },
  { date: "7/10/2019 09:28:51", shift: "B1", acuity: 6 },
  { date: "7/10/2019 09:31:48", shift: "C1", acuity: 3 },
  { date: "7/10/2019 09:32:12", shift: "A1", acuity: 6 },
  { date: "7/10/2019 09:45:35", shift: "B1", acuity: 6 },
  { date: "7/10/2019 09:51:01", shift: "C1", acuity: 3 },
  { date: "7/10/2019 10:12:29", shift: "D1", acuity: 3 },
  { date: "7/10/2019 10:14:01", shift: "A1", acuity: 4 },
  { date: "7/10/2019 10:24:50", shift: "B1", acuity: 3 },
  { date: "7/10/2019 10:40:27", shift: "D1", acuity: 6 },
  { date: "7/10/2019 10:46:45", shift: "D1", acuity: 3 },
  { date: "7/10/2019 10:51:41", shift: "C1", acuity: 3 },
  { date: "7/10/2019 10:52:29", shift: "A1", acuity: 6 },
  { date: "7/10/2019 10:53:25", shift: "B1", acuity: 6 },
  { date: "7/10/2019 11:07:41", shift: "D1", acuity: 6 },
  { date: "7/10/2019 11:10:29", shift: "C1", acuity: 6 },
  { date: "7/10/2019 11:34:22", shift: "A1", acuity: 3 },
  { date: "7/10/2019 11:34:37", shift: "B1", acuity: 3 },
  { date: "7/10/2019 11:54:01", shift: "D1", acuity: 6 },
  { date: "7/10/2019 12:00:58", shift: "C1", acuity: 3 },
  { date: "7/10/2019 12:01:48", shift: "A1", acuity: 3 },
  { date: "7/10/2019 12:12:24", shift: "B1", acuity: 6 },
  { date: "7/10/2019 12:29:13", shift: "D1", acuity: 3 },
  { date: "7/10/2019 12:37:24", shift: "C1", acuity: 3 },
  { date: "7/10/2019 12:39:07", shift: "A1", acuity: 3 },
  { date: "7/10/2019 12:47:40", shift: "B1", acuity: 3 },
  { date: "7/10/2019 13:14:58", shift: "A2", acuity: 6 },
  { date: "7/10/2019 13:26:35", shift: "D1", acuity: 6 },
  { date: "7/10/2019 13:34:37", shift: "A2", acuity: 6 },
  { date: "7/10/2019 13:35:12", shift: "A2", acuity: 3 },
  { date: "7/10/2019 13:43:55", shift: "C1", acuity: 6 },
  { date: "7/10/2019 13:58:43", shift: "B1", acuity: 6 },
  { date: "7/10/2019 14:21:49", shift: "B2", acuity: 3 },
  { date: "7/10/2019 14:48:36", shift: "B2", acuity: 6 },
  { date: "7/10/2019 15:06:32", shift: "B2", acuity: 3 },
  { date: "7/10/2019 15:06:59", shift: "D1", acuity: 4 },
  { date: "7/10/2019 15:17:21", shift: "A2", acuity: 6 },
  { date: "7/10/2019 15:17:46", shift: "C1", acuity: 3 },
  { date: "7/10/2019 15:33:41", shift: "B2", acuity: 3 },
  { date: "7/10/2019 15:52:42", shift: "D1", acuity: 3 },
  { date: "7/10/2019 15:53:08", shift: "A2", acuity: 3 },
  { date: "7/10/2019 16:03:24", shift: "C1", acuity: 6 },
  { date: "7/10/2019 16:24:34", shift: "B2", acuity: 6 },
  { date: "7/10/2019 16:28:11", shift: "D1", acuity: 3 },
  { date: "7/10/2019 16:33:51", shift: "C2", acuity: 3 },
  { date: "7/10/2019 16:34:32", shift: "A2", acuity: 6 },
  { date: "7/10/2019 16:45:20", shift: "B2", acuity: 3 },
  { date: "7/10/2019 16:48:34", shift: "D1", acuity: 3 },
  { date: "7/10/2019 17:10:32", shift: "C2", acuity: 6 },
  { date: "7/10/2019 17:23:49", shift: "C2", acuity: 3 },
  { date: "7/10/2019 17:53:41", shift: "A2", acuity: 4 },
  { date: "7/10/2019 17:56:35", shift: "B2", acuity: 3 },
  { date: "7/10/2019 17:56:59", shift: "D1", acuity: 3 },
  { date: "7/10/2019 18:12:51", shift: "D2", acuity: 6 },
  { date: "7/10/2019 18:13:16", shift: "C2", acuity: 3 },
  { date: "7/10/2019 18:21:07", shift: "A2", acuity: 3 },
  { date: "7/10/2019 18:47:04", shift: "D2", acuity: 6 },
  { date: "7/10/2019 18:49:59", shift: "D2", acuity: 6 },
  { date: "7/10/2019 19:07:39", shift: "B2", acuity: 3 },
  { date: "7/10/2019 19:08:00", shift: "C2", acuity: 3 },
  { date: "7/10/2019 19:09:04", shift: "A2", acuity: 6 },
  { date: "7/10/2019 19:09:22", shift: "D2", acuity: 6 },
  { date: "7/10/2019 19:30:33", shift: "B2", acuity: 3 },
  { date: "7/10/2019 19:31:02", shift: "C2", acuity: 6 },
  { date: "7/10/2019 19:31:24", shift: "A2", acuity: 3 },
  { date: "7/10/2019 19:44:18", shift: "D2", acuity: 3 },
  { date: "7/10/2019 19:53:22", shift: "B2", acuity: 6 },
  { date: "7/10/2019 19:54:06", shift: "C2", acuity: 3 },
  { date: "7/10/2019 19:55:32", shift: "A2", acuity: 6 },
  { date: "7/10/2019 20:07:13", shift: "D2", acuity: 2 },
  { date: "7/10/2019 20:29:31", shift: "B2", acuity: 3 },
  { date: "7/10/2019 20:40:05", shift: "C2", acuity: 3 },
  { date: "7/10/2019 20:41:37", shift: "A2", acuity: 3 },
  { date: "7/10/2019 20:43:30", shift: "D2", acuity: 3 },
  { date: "7/10/2019 20:44:35", shift: "B2", acuity: 6 },
  { date: "7/10/2019 20:45:40", shift: "C2", acuity: 6 },
  { date: "7/10/2019 21:13:08", shift: "A3", acuity: 6 },
  { date: "7/10/2019 21:14:37", shift: "D2", acuity: 6 },
  { date: "7/10/2019 21:16:18", shift: "B2", acuity: 6 },
  { date: "7/10/2019 21:22:06", shift: "C2", acuity: 3 },
  { date: "7/10/2019 21:23:31", shift: "A3", acuity: 6 },
  { date: "7/10/2019 21:31:29", shift: "A3", acuity: 3 },
  { date: "7/10/2019 21:36:14", shift: "A3", acuity: 3 },
  { date: "7/10/2019 21:36:34", shift: "D2", acuity: 3 },
  { date: "7/10/2019 21:46:21", shift: "B2", acuity: 4 },
  { date: "7/10/2019 21:52:43", shift: "C2", acuity: 3 },
  { date: "7/10/2019 21:56:05", shift: "A3", acuity: 6 },
  { date: "7/10/2019 22:11:33", shift: "B3", acuity: 3 },
  { date: "7/10/2019 22:23:33", shift: "D2", acuity: 4 },
  { date: "7/10/2019 22:33:52", shift: "B3", acuity: 4 },
  { date: "7/10/2019 22:43:58", shift: "B3", acuity: 3 },
  { date: "7/10/2019 22:51:34", shift: "C2", acuity: 4 },
  { date: "7/10/2019 22:56:34", shift: "A3", acuity: 4 },
  { date: "7/10/2019 23:02:59", shift: "D2", acuity: 3 },
  { date: "7/10/2019 23:04:39", shift: "B3", acuity: 6 },
  { date: "7/10/2019 23:07:25", shift: "C2", acuity: 3 },
  { date: "7/10/2019 23:13:48", shift: "A3", acuity: 6 },
  { date: "7/10/2019 23:21:42", shift: "D2", acuity: 3 },
  { date: "7/10/2019 23:30:14", shift: "B3", acuity: 3 },
  { date: "7/10/2019 23:30:52", shift: "C2", acuity: 3 },
  { date: "7/10/2019 23:46:07", shift: "A3", acuity: 3 },
  { date: "7/11/2019 00:09:42", shift: "D2", acuity: 6 },
  { date: "7/11/2019 00:13:35", shift: "B3", acuity: 3 },
  { date: "7/11/2019 00:38:46", shift: "A3", acuity: 3 },
  { date: "7/11/2019 00:56:43", shift: "D2", acuity: 3 },
  { date: "7/11/2019 01:03:24", shift: "B3", acuity: 3 },
  { date: "7/11/2019 01:04:50", shift: "A3", acuity: 3 },
  { date: "7/11/2019 01:35:49", shift: "B3", acuity: 3 },
  { date: "7/11/2019 01:40:07", shift: "A3", acuity: 3 },
  { date: "7/11/2019 01:55:14", shift: "B3", acuity: 3 },
  { date: "7/11/2019 02:01:41", shift: "A3", acuity: 4 },
  { date: "7/11/2019 02:02:07", shift: "B3", acuity: 3 },
  { date: "7/11/2019 02:53:22", shift: "A3", acuity: 4 },
  { date: "7/11/2019 03:25:11", shift: "B3", acuity: 4 },
  { date: "7/11/2019 03:44:24", shift: "A3", acuity: 4 },
  { date: "7/11/2019 03:58:06", shift: "B3", acuity: 6 },
  { date: "7/11/2019 04:29:50", shift: "A3", acuity: 3 },
  { date: "7/11/2019 04:33:28", shift: "B3", acuity: 3 },
  { date: "7/11/2019 04:34:49", shift: "A3", acuity: 6 },
  { date: "7/11/2019 04:36:56", shift: "B3", acuity: 4 },
  { date: "7/11/2019 05:25:42", shift: "A1", acuity: 3 },
  { date: "7/11/2019 06:04:05", shift: "A1", acuity: 3 },
  { date: "7/11/2019 06:11:03", shift: "A1", acuity: 3 },
  { date: "7/11/2019 06:35:32", shift: "B1", acuity: 3 },
  { date: "7/11/2019 06:46:37", shift: "B1", acuity: 4 },
  { date: "7/11/2019 07:04:33", shift: "B1", acuity: 3 },
  { date: "7/11/2019 07:25:58", shift: "A1", acuity: 3 },
  { date: "7/11/2019 07:51:58", shift: "B1", acuity: 6 },
  { date: "7/11/2019 07:54:03", shift: "A1", acuity: 6 },
  { date: "7/11/2019 08:39:02", shift: "C1", acuity: 3 },
  { date: "7/11/2019 08:46:35", shift: "B1", acuity: 3 },
  { date: "7/11/2019 08:46:49", shift: "A1", acuity: 6 },
  { date: "7/11/2019 08:49:20", shift: "C1", acuity: 3 },
  { date: "7/11/2019 08:49:38", shift: "B1", acuity: 3 },
  { date: "7/11/2019 08:50:31", shift: "A1", acuity: 4 },
  { date: "7/11/2019 09:14:53", shift: "C1", acuity: 6 },
  { date: "7/11/2019 09:15:01", shift: "C1", acuity: 6 },
  { date: "7/11/2019 09:19:33", shift: "B1", acuity: 6 },
  { date: "7/11/2019 09:32:26", shift: "A1", acuity: 6 },
  { date: "7/11/2019 09:32:47", shift: "C1", acuity: 3 },
  { date: "7/11/2019 09:43:19", shift: "B1", acuity: 3 },
  { date: "7/11/2019 09:51:49", shift: "A1", acuity: 6 },
  { date: "7/11/2019 10:01:27", shift: "D1", acuity: 6 },
  { date: "7/11/2019 10:12:51", shift: "C1", acuity: 6 },
  { date: "7/11/2019 10:24:11", shift: "B1", acuity: 3 },
  { date: "7/11/2019 10:24:32", shift: "A1", acuity: 6 },
  { date: "7/11/2019 10:31:49", shift: "D1", acuity: 6 },
  { date: "7/11/2019 10:54:18", shift: "D1", acuity: 6 },
  { date: "7/11/2019 11:12:19", shift: "C1", acuity: 3 },
  { date: "7/11/2019 11:20:19", shift: "B1", acuity: 3 },
  { date: "7/11/2019 11:21:36", shift: "A1", acuity: 3 },
  { date: "7/11/2019 11:32:25", shift: "D1", acuity: 6 },
  { date: "7/11/2019 11:33:00", shift: "C1", acuity: 6 },
  { date: "7/11/2019 11:33:14", shift: "B1", acuity: 3 },
  { date: "7/11/2019 11:35:22", shift: "A1", acuity: 3 },
  { date: "7/11/2019 11:47:13", shift: "D1", acuity: 3 },
  { date: "7/11/2019 11:47:31", shift: "C1", acuity: 3 },
  { date: "7/11/2019 11:55:06", shift: "B1", acuity: 2 },
  { date: "7/11/2019 11:59:57", shift: "A1", acuity: 3 },
  { date: "7/11/2019 12:00:32", shift: "D1", acuity: 3 },
  { date: "7/11/2019 12:18:01", shift: "C1", acuity: 6 },
  { date: "7/11/2019 12:27:19", shift: "B1", acuity: 3 },
  { date: "7/11/2019 12:43:25", shift: "A1", acuity: 6 },
  { date: "7/11/2019 13:23:26", shift: "A2", acuity: 3 },
  { date: "7/11/2019 13:23:59", shift: "D1", acuity: 6 },
  { date: "7/11/2019 13:25:17", shift: "C1", acuity: 6 },
  { date: "7/11/2019 13:31:41", shift: "A2", acuity: 6 },
  { date: "7/11/2019 13:55:05", shift: "A2", acuity: 6 },
  { date: "7/11/2019 13:55:26", shift: "B1", acuity: 3 },
  { date: "7/11/2019 13:55:53", shift: "D1", acuity: 3 },
  { date: "7/11/2019 14:06:09", shift: "B2", acuity: 3 },
  { date: "7/11/2019 14:09:59", shift: "C1", acuity: 3 },
  { date: "7/11/2019 14:56:51", shift: "B2", acuity: 3 },
  { date: "7/11/2019 14:57:14", shift: "B2", acuity: 6 },
  { date: "7/11/2019 14:59:30", shift: "A2", acuity: 3 },
  { date: "7/11/2019 14:59:43", shift: "D1", acuity: 3 },
  { date: "7/11/2019 15:03:48", shift: "C1", acuity: 3 },
  { date: "7/11/2019 15:07:47", shift: "B2", acuity: 3 },
  { date: "7/11/2019 15:16:58", shift: "A2", acuity: 3 },
  { date: "7/11/2019 15:17:39", shift: "D1", acuity: 3 },
  { date: "7/11/2019 15:38:15", shift: "C1", acuity: 3 },
  { date: "7/11/2019 15:43:11", shift: "B2", acuity: 6 },
  { date: "7/11/2019 15:55:55", shift: "A2", acuity: 3 },
  { date: "7/11/2019 15:58:06", shift: "D1", acuity: 6 },
  { date: "7/11/2019 16:06:24", shift: "C1", acuity: 3 },
  { date: "7/11/2019 16:30:36", shift: "C2", acuity: 3 },
  { date: "7/11/2019 16:37:04", shift: "B2", acuity: 3 },
  { date: "7/11/2019 16:37:30", shift: "A2", acuity: 3 },
  { date: "7/11/2019 16:50:16", shift: "D1", acuity: 3 },
  { date: "7/11/2019 16:50:30", shift: "C2", acuity: 3 },
  { date: "7/11/2019 16:50:53", shift: "B2", acuity: 6 },
  { date: "7/11/2019 17:02:25", shift: "C2", acuity: 3 },
  { date: "7/11/2019 17:18:13", shift: "C2", acuity: 3 },
  { date: "7/11/2019 17:18:36", shift: "A2", acuity: 4 },
  { date: "7/11/2019 17:29:26", shift: "D1", acuity: 3 },
  { date: "7/11/2019 17:35:34", shift: "B2", acuity: 3 },
  { date: "7/11/2019 17:35:55", shift: "C2", acuity: 3 },
  { date: "7/11/2019 17:56:46", shift: "A2", acuity: 6 },
  { date: "7/11/2019 18:00:28", shift: "D2", acuity: 3 },
  { date: "7/11/2019 18:00:49", shift: "B2", acuity: 6 },
  { date: "7/11/2019 18:14:39", shift: "C2", acuity: 3 },
  { date: "7/11/2019 18:15:22", shift: "A2", acuity: 3 },
  { date: "7/11/2019 18:22:20", shift: "D2", acuity: 3 },
  { date: "7/11/2019 18:23:37", shift: "B2", acuity: 3 },
  { date: "7/11/2019 18:28:35", shift: "C2", acuity: 6 },
  { date: "7/11/2019 19:02:52", shift: "D2", acuity: 3 },
  { date: "7/11/2019 19:03:05", shift: "D2", acuity: 4 },
  { date: "7/11/2019 19:20:34", shift: "A2", acuity: 3 },
  { date: "7/11/2019 19:21:28", shift: "B2", acuity: 3 },
  { date: "7/11/2019 19:22:02", shift: "C2", acuity: 6 },
  { date: "7/11/2019 19:33:38", shift: "D2", acuity: 6 },
  { date: "7/11/2019 19:37:00", shift: "A2", acuity: 6 },
  { date: "7/11/2019 19:50:47", shift: "B2", acuity: 3 },
  { date: "7/11/2019 19:51:18", shift: "C2", acuity: 4 },
  { date: "7/11/2019 19:59:04", shift: "D2", acuity: 3 },
  { date: "7/11/2019 20:12:33", shift: "A2", acuity: 3 },
  { date: "7/11/2019 20:13:27", shift: "B2", acuity: 6 },
  { date: "7/11/2019 20:28:27", shift: "C2", acuity: 6 },
  { date: "7/11/2019 20:40:13", shift: "D2", acuity: 3 },
  { date: "7/11/2019 20:46:14", shift: "A2", acuity: 4 },
  { date: "7/11/2019 20:52:07", shift: "B2", acuity: 6 },
  { date: "7/11/2019 21:03:48", shift: "A3", acuity: 3 },
  { date: "7/11/2019 21:06:45", shift: "C2", acuity: 3 },
  { date: "7/11/2019 21:15:05", shift: "D2", acuity: 6 },
  { date: "7/11/2019 21:15:40", shift: "B2", acuity: 3 },
  { date: "7/11/2019 21:37:52", shift: "A3", acuity: 6 },
  { date: "7/11/2019 21:39:34", shift: "A3", acuity: 3 },
  { date: "7/11/2019 22:04:55", shift: "B3", acuity: 3 },
  { date: "7/11/2019 22:15:09", shift: "C2", acuity: 3 },
  { date: "7/11/2019 22:16:55", shift: "D2", acuity: 3 },
  { date: "7/11/2019 22:24:31", shift: "A3", acuity: 3 },
  { date: "7/11/2019 22:25:20", shift: "B3", acuity: 3 },
  { date: "7/11/2019 22:25:46", shift: "C2", acuity: 3 },
  { date: "7/11/2019 22:28:26", shift: "D2", acuity: 3 },
  { date: "7/11/2019 22:37:38", shift: "B3", acuity: 4 },
  { date: "7/11/2019 22:38:47", shift: "B3", acuity: 3 },
  { date: "7/11/2019 22:47:43", shift: "A3", acuity: 3 },
  { date: "7/11/2019 22:49:37", shift: "C2", acuity: 3 },
  { date: "7/11/2019 22:59:31", shift: "D2", acuity: 3 },
  { date: "7/11/2019 23:15:44", shift: "B3", acuity: 3 },
  { date: "7/11/2019 23:24:44", shift: "A3", acuity: 6 },
  { date: "7/11/2019 23:29:00", shift: "C2", acuity: 6 },
  { date: "7/11/2019 23:40:00", shift: "D2", acuity: 2 },
  { date: "7/11/2019 23:46:59", shift: "B3", acuity: 3 },
  { date: "7/11/2019 23:47:11", shift: "A3", acuity: 3 },
  { date: "7/11/2019 23:47:21", shift: "C2", acuity: 3 },
  { date: "7/11/2019 23:57:00", shift: "D2", acuity: 4 },
  { date: "7/12/2019 00:06:27", shift: "B3", acuity: 3 },
  { date: "7/12/2019 00:24:42", shift: "A3", acuity: 6 },
  { date: "7/12/2019 00:31:02", shift: "D2", acuity: 3 },
  { date: "7/12/2019 00:48:51", shift: "B3", acuity: 3 },
  { date: "7/12/2019 01:04:06", shift: "A3", acuity: 3 },
  { date: "7/12/2019 01:04:18", shift: "D2", acuity: 3 },
  { date: "7/12/2019 01:29:36", shift: "B3", acuity: 3 },
  { date: "7/12/2019 01:46:43", shift: "A3", acuity: 3 },
  { date: "7/12/2019 02:22:39", shift: "B3", acuity: 4 },
  { date: "7/12/2019 03:03:01", shift: "A3", acuity: 5 },
  { date: "7/12/2019 03:05:02", shift: "B3", acuity: 3 },
  { date: "7/12/2019 03:26:37", shift: "A3", acuity: 6 },
  { date: "7/12/2019 03:27:07", shift: "B3", acuity: 3 },
  { date: "7/12/2019 03:33:06", shift: "A3", acuity: 6 },
  { date: "7/12/2019 03:46:54", shift: "B3", acuity: 6 },
  { date: "7/12/2019 03:50:06", shift: "A3", acuity: 6 },
  { date: "7/12/2019 04:04:42", shift: "B3", acuity: 3 },
  { date: "7/12/2019 04:07:44", shift: "A3", acuity: 6 },
  { date: "7/12/2019 04:14:37", shift: "B3", acuity: 3 },
  { date: "7/12/2019 04:51:21", shift: "A3", acuity: 3 },
  { date: "7/12/2019 05:20:30", shift: "A1", acuity: 4 },
  { date: "7/12/2019 05:37:37", shift: "A1", acuity: 6 },
  { date: "7/12/2019 06:16:21", shift: "A1", acuity: 3 },
  { date: "7/12/2019 06:43:05", shift: "B1", acuity: 3 },
  { date: "7/12/2019 07:18:26", shift: "B1", acuity: 3 },
  { date: "7/12/2019 07:18:46", shift: "B1", acuity: 3 },
  { date: "7/12/2019 07:19:08", shift: "A1", acuity: 3 },
  { date: "7/12/2019 07:22:09", shift: "B1", acuity: 4 },
  { date: "7/12/2019 07:30:54", shift: "A1", acuity: 6 },
  { date: "7/12/2019 08:03:54", shift: "B1", acuity: 3 },
  { date: "7/12/2019 08:19:55", shift: "A1", acuity: 2 },
  { date: "7/12/2019 08:23:16", shift: "B1", acuity: 3 },
  { date: "7/12/2019 08:23:54", shift: "A1", acuity: 3 },
  { date: "7/12/2019 09:02:12", shift: "C1", acuity: 3 },
  { date: "7/12/2019 09:19:49", shift: "C1", acuity: 3 },
  { date: "7/12/2019 09:22:04", shift: "C1", acuity: 3 },
  { date: "7/12/2019 09:23:41", shift: "B1", acuity: 6 },
  { date: "7/12/2019 09:25:22", shift: "A1", acuity: 6 },
  { date: "7/12/2019 09:29:59", shift: "C1", acuity: 6 },
  { date: "7/12/2019 09:30:23", shift: "B1", acuity: 4 },
  { date: "7/12/2019 09:38:09", shift: "A1", acuity: 6 },
  { date: "7/12/2019 09:43:07", shift: "C1", acuity: 3 },
  { date: "7/12/2019 10:02:15", shift: "D1", acuity: 3 },
  { date: "7/12/2019 10:16:00", shift: "B1", acuity: 6 },
  { date: "7/12/2019 10:22:35", shift: "A1", acuity: 3 },
  { date: "7/12/2019 10:29:11", shift: "C1", acuity: 3 },
  { date: "7/12/2019 10:41:24", shift: "D1", acuity: 3 },
  { date: "7/12/2019 10:44:34", shift: "D1", acuity: 3 },
  { date: "7/12/2019 10:44:57", shift: "B1", acuity: 3 },
  { date: "7/12/2019 10:51:41", shift: "A1", acuity: 3 },
  { date: "7/12/2019 11:12:21", shift: "C1", acuity: 6 },
  { date: "7/12/2019 11:19:49", shift: "D1", acuity: 3 },
  { date: "7/12/2019 11:21:22", shift: "B1", acuity: 6 },
  { date: "7/12/2019 11:29:37", shift: "A1", acuity: 3 },
  { date: "7/12/2019 11:30:23", shift: "C1", acuity: 3 },
  { date: "7/12/2019 11:32:48", shift: "D1", acuity: 3 },
  { date: "7/12/2019 11:44:36", shift: "B1", acuity: 6 },
  { date: "7/12/2019 11:53:41", shift: "A1", acuity: 6 },
  { date: "7/12/2019 11:59:53", shift: "C1", acuity: 6 },
  { date: "7/12/2019 12:02:37", shift: "D1", acuity: 3 },
  { date: "7/12/2019 12:06:42", shift: "B1", acuity: 6 },
  { date: "7/12/2019 12:31:25", shift: "A1", acuity: 6 },
  { date: "7/12/2019 12:34:12", shift: "C1", acuity: 6 },
  { date: "7/12/2019 12:40:08", shift: "D1", acuity: 3 },
  { date: "7/12/2019 12:43:21", shift: "B1", acuity: 6 },
  { date: "7/12/2019 12:57:09", shift: "A1", acuity: 6 },
  { date: "7/12/2019 13:17:40", shift: "A2", acuity: 3 },
  { date: "7/12/2019 13:29:59", shift: "C1", acuity: 3 },
  { date: "7/12/2019 13:50:40", shift: "A2", acuity: 3 },
  { date: "7/12/2019 14:03:25", shift: "A2", acuity: 3 },
  { date: "7/12/2019 14:17:29", shift: "B2", acuity: 6 },
  { date: "7/12/2019 14:19:44", shift: "D1", acuity: 3 },
  { date: "7/12/2019 14:40:05", shift: "B2", acuity: 6 },
  { date: "7/12/2019 14:48:28", shift: "B2", acuity: 7 },
  { date: "7/12/2019 14:48:54", shift: "C1", acuity: 6 },
  { date: "7/12/2019 15:12:07", shift: "A2", acuity: 6 },
  { date: "7/12/2019 15:25:36", shift: "D1", acuity: 3 },
  { date: "7/12/2019 15:25:41", shift: "B2", acuity: 6 },
  { date: "7/12/2019 15:28:23", shift: "C1", acuity: 3 },
  { date: "7/12/2019 15:34:34", shift: "A2", acuity: 6 },
  { date: "7/12/2019 15:47:01", shift: "D1", acuity: 3 },
  { date: "7/12/2019 15:56:05", shift: "B2", acuity: 3 },
  { date: "7/12/2019 15:56:16", shift: "C1", acuity: 6 },
  { date: "7/12/2019 16:10:59", shift: "A2", acuity: 6 },
  { date: "7/12/2019 16:11:15", shift: "D1", acuity: 3 },
  { date: "7/12/2019 16:28:17", shift: "B2", acuity: 6 },
  { date: "7/12/2019 16:29:44", shift: "C1", acuity: 3 },
  { date: "7/12/2019 16:39:05", shift: "C2", acuity: 6 },
  { date: "7/12/2019 16:47:37", shift: "A2", acuity: 3 },
  { date: "7/12/2019 17:00:37", shift: "C2", acuity: 6 },
  { date: "7/12/2019 17:20:38", shift: "C2", acuity: 3 },
  { date: "7/12/2019 17:34:04", shift: "D1", acuity: 3 },
  { date: "7/12/2019 17:50:56", shift: "B2", acuity: 3 },
  { date: "7/12/2019 17:57:01", shift: "A2", acuity: 3 },
  { date: "7/12/2019 18:05:44", shift: "D2", acuity: 3 },
  { date: "7/12/2019 18:15:59", shift: "C2", acuity: 6 },
  { date: "7/12/2019 18:16:17", shift: "B2", acuity: 6 },
  { date: "7/12/2019 18:16:36", shift: "A2", acuity: 3 },
  { date: "7/12/2019 18:30:44", shift: "D2", acuity: 7 },
  { date: "7/12/2019 18:44:16", shift: "D2", acuity: 2 },
  { date: "7/12/2019 18:50:36", shift: "C2", acuity: 6 },
  { date: "7/12/2019 19:08:24", shift: "B2", acuity: 3 },
  { date: "7/12/2019 19:14:14", shift: "A2", acuity: 3 },
  { date: "7/12/2019 19:18:47", shift: "D2", acuity: 3 },
  { date: "7/12/2019 19:19:12", shift: "C2", acuity: 3 },
  { date: "7/12/2019 19:19:27", shift: "B2", acuity: 4 },
  { date: "7/12/2019 19:37:14", shift: "A2", acuity: 6 },
  { date: "7/12/2019 19:45:10", shift: "D2", acuity: 3 },
  { date: "7/12/2019 19:46:04", shift: "C2", acuity: 3 },
  { date: "7/12/2019 19:46:42", shift: "B2", acuity: 3 },
  { date: "7/12/2019 19:51:39", shift: "A2", acuity: 3 },
  { date: "7/12/2019 19:57:15", shift: "D2", acuity: 4 },
  { date: "7/12/2019 20:25:56", shift: "C2", acuity: 3 },
  { date: "7/12/2019 20:39:46", shift: "B2", acuity: 3 },
  { date: "7/12/2019 20:40:02", shift: "A2", acuity: 6 },
  { date: "7/12/2019 20:44:04", shift: "D2", acuity: 3 },
  { date: "7/12/2019 20:53:09", shift: "C2", acuity: 6 },
  { date: "7/12/2019 21:00:31", shift: "A3", acuity: 3 },
  { date: "7/12/2019 21:07:24", shift: "B2", acuity: 3 },
  { date: "7/12/2019 21:13:54", shift: "D2", acuity: 3 },
  { date: "7/12/2019 21:17:03", shift: "C2", acuity: 6 },
  { date: "7/12/2019 21:30:32", shift: "A3", acuity: 3 },
  { date: "7/12/2019 21:33:40", shift: "A3", acuity: 4 },
  { date: "7/12/2019 21:34:27", shift: "B2", acuity: 7 },
  { date: "7/12/2019 21:50:16", shift: "D2", acuity: 3 },
  { date: "7/12/2019 21:50:53", shift: "C2", acuity: 6 },
  { date: "7/12/2019 21:58:32", shift: "A3", acuity: 4 },
  { date: "7/12/2019 21:58:46", shift: "B2", acuity: 3 },
  { date: "7/12/2019 22:15:24", shift: "B3", acuity: 3 },
  { date: "7/12/2019 22:21:10", shift: "D2", acuity: 4 },
  { date: "7/12/2019 22:35:50", shift: "B3", acuity: 3 },
  { date: "7/12/2019 22:50:58", shift: "B3", acuity: 6 },
  { date: "7/12/2019 22:57:05", shift: "C2", acuity: 6 },
  { date: "7/12/2019 22:59:18", shift: "A3", acuity: 4 },
  { date: "7/12/2019 23:07:45", shift: "D2", acuity: 5 },
  { date: "7/12/2019 23:18:34", shift: "B3", acuity: 3 },
  { date: "7/12/2019 23:32:49", shift: "C2", acuity: 4 },
  { date: "7/12/2019 23:37:08", shift: "A3", acuity: 6 },
  { date: "7/12/2019 23:38:13", shift: "D2", acuity: 4 },
  { date: "7/13/2019 00:02:16", shift: "B3", acuity: 4 },
  { date: "7/13/2019 00:06:46", shift: "A3", acuity: 3 },
  { date: "7/13/2019 00:12:58", shift: "D2", acuity: 4 },
  { date: "7/13/2019 00:17:36", shift: "B3", acuity: 3 },
  { date: "7/13/2019 00:41:30", shift: "A3", acuity: 3 },
  { date: "7/13/2019 00:42:08", shift: "D2", acuity: 4 },
  { date: "7/13/2019 00:58:46", shift: "B3", acuity: 4 },
  { date: "7/13/2019 01:22:53", shift: "A3", acuity: 3 },
  { date: "7/13/2019 01:29:21", shift: "D2", acuity: 3 },
  { date: "7/13/2019 01:32:04", shift: "B3", acuity: 3 },
  { date: "7/13/2019 01:45:47", shift: "A3", acuity: 3 },
  { date: "7/13/2019 02:04:39", shift: "B3", acuity: 6 },
  { date: "7/13/2019 02:12:20", shift: "A3", acuity: 4 },
  { date: "7/13/2019 02:42:51", shift: "B3", acuity: 4 },
  { date: "7/13/2019 03:22:07", shift: "A3", acuity: 3 },
  { date: "7/13/2019 03:25:14", shift: "B3", acuity: 5 },
  { date: "7/13/2019 03:30:47", shift: "A3", acuity: 4 },
  { date: "7/13/2019 03:35:06", shift: "B3", acuity: 6 },
  { date: "7/13/2019 03:50:55", shift: "A3", acuity: 6 },
  { date: "7/13/2019 04:49:58", shift: "B3", acuity: 6 },
  { date: "7/13/2019 05:35:24", shift: "A1", acuity: 3 },
  { date: "7/13/2019 05:38:23", shift: "A1", acuity: 4 },
  { date: "7/13/2019 05:54:29", shift: "A1", acuity: 4 },
  { date: "7/13/2019 06:00:26", shift: "B1", acuity: 3 },
  { date: "7/13/2019 06:16:27", shift: "A1", acuity: 4 },
  { date: "7/13/2019 06:30:18", shift: "B1", acuity: 6 },
  { date: "7/13/2019 06:36:17", shift: "B1", acuity: 3 },
  { date: "7/13/2019 07:28:57", shift: "A1", acuity: 3 },
  { date: "7/13/2019 07:38:48", shift: "B1", acuity: 3 },
  { date: "7/13/2019 07:59:29", shift: "A1", acuity: 6 },
  { date: "7/13/2019 09:23:30", shift: "C1", acuity: 3 },
  { date: "7/13/2019 09:23:45", shift: "C1", acuity: 6 },
  { date: "7/13/2019 09:36:10", shift: "C1", acuity: 4 },
  { date: "7/13/2019 10:08:18", shift: "D1", acuity: 3 },
  { date: "7/13/2019 10:08:38", shift: "B1", acuity: 3 },
  { date: "7/13/2019 10:26:24", shift: "A1", acuity: 3 },
  { date: "7/13/2019 10:37:49", shift: "D1", acuity: 6 },
  { date: "7/13/2019 10:38:28", shift: "D1", acuity: 3 },
  { date: "7/13/2019 10:56:52", shift: "C1", acuity: 3 },
  { date: "7/13/2019 11:10:00", shift: "B1", acuity: 6 },
  { date: "7/13/2019 11:18:48", shift: "A1", acuity: 2 },
  { date: "7/13/2019 11:39:59", shift: "D1", acuity: 3 },
  { date: "7/13/2019 11:44:30", shift: "C1", acuity: 3 },
  { date: "7/13/2019 11:52:59", shift: "B1", acuity: 6 },
  { date: "7/13/2019 12:26:28", shift: "A1", acuity: 6 },
  { date: "7/13/2019 12:29:45", shift: "D1", acuity: 6 },
  { date: "7/13/2019 12:31:53", shift: "C1", acuity: 6 },
  { date: "7/13/2019 12:33:56", shift: "B1", acuity: 3 },
  { date: "7/13/2019 12:54:29", shift: "A1", acuity: 6 },
  { date: "7/13/2019 12:56:38", shift: "D1", acuity: 3 },
  { date: "7/13/2019 13:22:24", shift: "A2", acuity: 3 },
  { date: "7/13/2019 13:31:31", shift: "A2", acuity: 6 },
  { date: "7/13/2019 13:47:38", shift: "A2", acuity: 6 },
  { date: "7/13/2019 13:48:12", shift: "C1", acuity: 6 },
  { date: "7/13/2019 13:48:56", shift: "B1", acuity: 6 },
  { date: "7/13/2019 14:08:43", shift: "B2", acuity: 3 },
  { date: "7/13/2019 14:11:45", shift: "D1", acuity: 3 },
  { date: "7/13/2019 14:46:18", shift: "B2", acuity: 6 },
  { date: "7/13/2019 14:52:25", shift: "B2", acuity: 6 },
  { date: "7/13/2019 14:52:44", shift: "A2", acuity: 3 },
  { date: "7/13/2019 14:55:24", shift: "C1", acuity: 3 },
  { date: "7/13/2019 14:56:44", shift: "D1", acuity: 3 },
  { date: "7/13/2019 15:13:57", shift: "B2", acuity: 6 },
  { date: "7/13/2019 15:30:53", shift: "A2", acuity: 3 },
  { date: "7/13/2019 15:38:35", shift: "C1", acuity: 3 },
  { date: "7/13/2019 15:38:49", shift: "D1", acuity: 3 },
  { date: "7/13/2019 15:52:36", shift: "B2", acuity: 3 },
  { date: "7/13/2019 16:06:00", shift: "A2", acuity: 4 },
  { date: "7/13/2019 16:17:11", shift: "C1", acuity: 7 },
  { date: "7/13/2019 16:36:14", shift: "C2", acuity: 3 },
  { date: "7/13/2019 16:43:02", shift: "D1", acuity: 3 },
  { date: "7/13/2019 16:55:10", shift: "B2", acuity: 3 },
  { date: "7/13/2019 17:03:19", shift: "C2", acuity: 4 },
  { date: "7/13/2019 17:13:37", shift: "C2", acuity: 3 },
  { date: "7/13/2019 17:32:11", shift: "A2", acuity: 4 },
  { date: "7/13/2019 17:32:25", shift: "D1", acuity: 4 },
  { date: "7/13/2019 17:49:21", shift: "B2", acuity: 3 },
  { date: "7/13/2019 18:21:27", shift: "D2", acuity: 3 },
  { date: "7/13/2019 18:21:50", shift: "C2", acuity: 7 },
  { date: "7/13/2019 18:22:07", shift: "A2", acuity: 6 },
  { date: "7/13/2019 18:43:54", shift: "D2", acuity: 6 },
  { date: "7/13/2019 18:54:11", shift: "D2", acuity: 3 },
  { date: "7/13/2019 19:08:40", shift: "B2", acuity: 3 },
  { date: "7/13/2019 19:12:32", shift: "C2", acuity: 3 },
  { date: "7/13/2019 19:27:36", shift: "A2", acuity: 3 },
  { date: "7/13/2019 19:27:57", shift: "D2", acuity: 3 },
  { date: "7/13/2019 19:28:03", shift: "B2", acuity: 3 },
  { date: "7/13/2019 21:18:27", shift: "A3", acuity: 6 },
  { date: "7/13/2019 21:20:03", shift: "C2", acuity: 4 },
  { date: "7/13/2019 21:20:19", shift: "D2", acuity: 6 },
  { date: "7/13/2019 21:20:32", shift: "B2", acuity: 3 },
  { date: "7/13/2019 21:34:58", shift: "A3", acuity: 6 },
  { date: "7/13/2019 21:36:27", shift: "A3", acuity: 3 },
  { date: "7/13/2019 21:49:12", shift: "C2", acuity: 4 },
  { date: "7/13/2019 21:49:32", shift: "D2", acuity: 3 },
  { date: "7/13/2019 21:55:56", shift: "B2", acuity: 4 },
  { date: "7/13/2019 22:05:17", shift: "B3", acuity: 5 },
  { date: "7/13/2019 22:05:47", shift: "A3", acuity: 4 },
  { date: "7/13/2019 22:17:40", shift: "C2", acuity: 3 },
  { date: "7/13/2019 22:18:51", shift: "D2", acuity: 4 },
  { date: "7/13/2019 22:30:25", shift: "B3", acuity: 3 },
  { date: "7/13/2019 22:39:41", shift: "B3", acuity: 3 },
  { date: "7/13/2019 22:43:25", shift: "A3", acuity: 3 },
  { date: "7/13/2019 22:58:42", shift: "C2", acuity: 2 },
  { date: "7/13/2019 23:08:00", shift: "D2", acuity: 6 },
  { date: "7/13/2019 23:22:45", shift: "B3", acuity: 6 },
  { date: "7/13/2019 23:23:06", shift: "A3", acuity: 5 },
  { date: "7/13/2019 23:40:11", shift: "C2", acuity: 3 },
  { date: "7/13/2019 23:51:09", shift: "D2", acuity: 6 },
  { date: "7/14/2019 00:21:26", shift: "B3", acuity: 6 },
  { date: "7/14/2019 00:23:07", shift: "A3", acuity: 3 },
  { date: "7/14/2019 00:49:00", shift: "D2", acuity: 6 },
  { date: "7/14/2019 00:54:53", shift: "B3", acuity: 3 },
  { date: "7/14/2019 00:56:40", shift: "A3", acuity: 3 },
  { date: "7/14/2019 01:00:59", shift: "D2", acuity: 3 },
  { date: "7/14/2019 01:03:39", shift: "B3", acuity: 5 },
  { date: "7/14/2019 01:09:56", shift: "A3", acuity: 6 },
  { date: "7/14/2019 01:10:08", shift: "D2", acuity: 4 },
  { date: "7/14/2019 01:17:49", shift: "B3", acuity: 3 },
  { date: "7/14/2019 01:37:56", shift: "A3", acuity: 3 },
  { date: "7/14/2019 01:38:14", shift: "B3", acuity: 3 },
  { date: "7/14/2019 01:44:48", shift: "A3", acuity: 3 },
  { date: "7/14/2019 01:55:18", shift: "B3", acuity: 3 },
  { date: "7/14/2019 02:00:32", shift: "A3", acuity: 3 },
  { date: "7/14/2019 02:06:21", shift: "B3", acuity: 4 },
  { date: "7/14/2019 02:26:08", shift: "A3", acuity: 4 },
  { date: "7/14/2019 02:45:53", shift: "B3", acuity: 3 },
  { date: "7/14/2019 02:55:49", shift: "A3", acuity: 3 },
  { date: "7/14/2019 03:07:35", shift: "B3", acuity: 3 },
  { date: "7/14/2019 03:39:11", shift: "A3", acuity: 3 },
  { date: "7/14/2019 04:05:00", shift: "B3", acuity: 6 },
  { date: "7/14/2019 05:00:30", shift: "A1", acuity: 4 },
  { date: "7/14/2019 05:00:43", shift: "B3", acuity: 6 },
  { date: "7/14/2019 05:42:10", shift: "A1", acuity: 3 },
  { date: "7/14/2019 06:09:02", shift: "A1", acuity: 3 },
  { date: "7/14/2019 06:34:43", shift: "B1", acuity: 3 },
  { date: "7/14/2019 06:43:01", shift: "B1", acuity: 6 },
  { date: "7/14/2019 07:07:41", shift: "B1", acuity: 6 },
  { date: "7/14/2019 07:37:12", shift: "A1", acuity: 3 },
  { date: "7/14/2019 07:53:54", shift: "B1", acuity: 4 },
  { date: "7/14/2019 07:58:17", shift: "A1", acuity: 4 },
  { date: "7/14/2019 08:03:33", shift: "B1", acuity: 6 },
  { date: "7/14/2019 08:09:54", shift: "A1", acuity: 3 },
  { date: "7/14/2019 08:16:21", shift: "B1", acuity: 6 },
  { date: "7/14/2019 08:27:01", shift: "A1", acuity: 3 },
  { date: "7/14/2019 08:34:32", shift: "C1", acuity: 3 },
  { date: "7/14/2019 08:39:46", shift: "B1", acuity: 6 },
  { date: "7/14/2019 08:39:58", shift: "A1", acuity: 3 },
  { date: "7/14/2019 08:49:17", shift: "C1", acuity: 6 },
  { date: "7/14/2019 08:59:58", shift: "B1", acuity: 3 },
  { date: "7/14/2019 09:22:09", shift: "C1", acuity: 6 },
  { date: "7/14/2019 09:22:24", shift: "C1", acuity: 3 },
  { date: "7/14/2019 09:22:39", shift: "A1", acuity: 3 },
  { date: "7/14/2019 09:32:12", shift: "B1", acuity: 4 },
  { date: "7/14/2019 09:32:33", shift: "C1", acuity: 3 },
  { date: "7/14/2019 09:36:33", shift: "A1", acuity: 6 },
  { date: "7/14/2019 09:37:17", shift: "B1", acuity: 3 },
  { date: "7/14/2019 09:53:16", shift: "C1", acuity: 6 },
  { date: "7/14/2019 10:20:50", shift: "D1", acuity: 3 },
  { date: "7/14/2019 10:24:58", shift: "A1", acuity: 3 },
  { date: "7/14/2019 10:37:27", shift: "D1", acuity: 6 },
  { date: "7/14/2019 10:42:50", shift: "D1", acuity: 4 },
  { date: "7/14/2019 10:46:04", shift: "B1", acuity: 5 },
  { date: "7/14/2019 10:56:39", shift: "C1", acuity: 3 },
  { date: "7/14/2019 10:56:54", shift: "A1", acuity: 3 },
  { date: "7/14/2019 10:58:16", shift: "D1", acuity: 6 },
  { date: "7/14/2019 11:04:57", shift: "B1", acuity: 6 },
  { date: "7/14/2019 11:14:28", shift: "C1", acuity: 3 },
  { date: "7/14/2019 11:30:28", shift: "A1", acuity: 3 },
  { date: "7/14/2019 11:52:01", shift: "D1", acuity: 3 },
  { date: "7/14/2019 11:52:18", shift: "B1", acuity: 3 },
  { date: "7/14/2019 12:09:22", shift: "C1", acuity: 6 },
  { date: "7/14/2019 12:11:00", shift: "A1", acuity: 3 },
  { date: "7/14/2019 12:20:25", shift: "D1", acuity: 3 },
  { date: "7/14/2019 12:42:20", shift: "B1", acuity: 2 },
  { date: "7/14/2019 12:44:07", shift: "C1", acuity: 7 },
  { date: "7/14/2019 13:09:34", shift: "A2", acuity: 3 },
  { date: "7/14/2019 13:54:02", shift: "A2", acuity: 3 },
  { date: "7/14/2019 14:02:38", shift: "A2", acuity: 3 },
  { date: "7/14/2019 14:06:21", shift: "B2", acuity: 3 },
  { date: "7/14/2019 14:08:12", shift: "D1", acuity: 3 },
  { date: "7/14/2019 14:27:16", shift: "C1", acuity: 3 },
  { date: "7/14/2019 14:31:43", shift: "B2", acuity: 6 },
  { date: "7/14/2019 14:32:25", shift: "B2", acuity: 4 },
  { date: "7/14/2019 14:57:43", shift: "A2", acuity: 4 },
  { date: "7/14/2019 15:12:02", shift: "D1", acuity: 5 },
  { date: "7/14/2019 15:12:27", shift: "C1", acuity: 3 },
  { date: "7/14/2019 15:16:40", shift: "B2", acuity: 3 },
  { date: "7/14/2019 15:47:11", shift: "A2", acuity: 3 },
  { date: "7/14/2019 15:47:52", shift: "D1", acuity: 3 },
  { date: "7/14/2019 15:48:37", shift: "C1", acuity: 3 },
  { date: "7/14/2019 15:49:26", shift: "B2", acuity: 6 },
  { date: "7/14/2019 16:01:02", shift: "A2", acuity: 3 },
  { date: "7/14/2019 16:08:54", shift: "D1", acuity: 6 },
  { date: "7/14/2019 16:24:00", shift: "C1", acuity: 3 },
  { date: "7/14/2019 16:28:39", shift: "B2", acuity: 3 },
  { date: "7/14/2019 16:44:23", shift: "C2", acuity: 3 },
  { date: "7/14/2019 16:44:58", shift: "A2", acuity: 3 },
  { date: "7/14/2019 16:45:22", shift: "D1", acuity: 3 },
  { date: "7/14/2019 17:00:49", shift: "C2", acuity: 3 },
  { date: "7/14/2019 17:04:20", shift: "C2", acuity: 3 },
  { date: "7/14/2019 17:21:41", shift: "B2", acuity: 3 },
  { date: "7/14/2019 17:26:09", shift: "A2", acuity: 4 },
  { date: "7/14/2019 17:26:50", shift: "D1", acuity: 4 },
  { date: "7/14/2019 17:35:10", shift: "C2", acuity: 6 },
  { date: "7/14/2019 17:37:21", shift: "B2", acuity: 6 },
  { date: "7/14/2019 17:42:05", shift: "A2", acuity: 6 },
  { date: "7/14/2019 17:49:56", shift: "D1", acuity: 6 },
  { date: "7/14/2019 17:53:33", shift: "C2", acuity: 4 },
  { date: "7/14/2019 18:14:36", shift: "D2", acuity: 6 },
  { date: "7/14/2019 18:15:09", shift: "B2", acuity: 6 },
  { date: "7/14/2019 18:15:43", shift: "A2", acuity: 3 },
  { date: "7/14/2019 18:16:10", shift: "C2", acuity: 6 },
  { date: "7/14/2019 18:47:41", shift: "D2", acuity: 6 },
  { date: "7/14/2019 18:48:08", shift: "D2", acuity: 3 },
  { date: "7/14/2019 18:51:19", shift: "B2", acuity: 6 },
  { date: "7/14/2019 18:58:12", shift: "A2", acuity: 6 },
  { date: "7/14/2019 19:14:59", shift: "C2", acuity: 3 },
  { date: "7/14/2019 19:15:48", shift: "D2", acuity: 6 },
  { date: "7/14/2019 19:16:10", shift: "B2", acuity: 6 },
  { date: "7/14/2019 19:29:28", shift: "A2", acuity: 3 },
  { date: "7/14/2019 19:30:34", shift: "C2", acuity: 2 },
  { date: "7/14/2019 19:35:02", shift: "D2", acuity: 6 },
  { date: "7/14/2019 19:48:55", shift: "B2", acuity: 6 },
  { date: "7/14/2019 19:49:05", shift: "A2", acuity: 3 },
  { date: "7/14/2019 19:49:21", shift: "C2", acuity: 3 },
  { date: "7/14/2019 20:14:43", shift: "D2", acuity: 6 },
  { date: "7/14/2019 20:14:58", shift: "B2", acuity: 3 },
  { date: "7/14/2019 20:25:45", shift: "A2", acuity: 3 },
  { date: "7/14/2019 20:35:57", shift: "C2", acuity: 6 },
  { date: "7/14/2019 20:38:25", shift: "D2", acuity: 3 },
  { date: "7/14/2019 20:41:48", shift: "B2", acuity: 6 },
  { date: "7/14/2019 20:53:06", shift: "A2", acuity: 6 },
  { date: "7/14/2019 21:00:57", shift: "A3", acuity: 3 },
  { date: "7/14/2019 21:07:25", shift: "C2", acuity: 3 },
  { date: "7/14/2019 21:09:46", shift: "D2", acuity: 6 },
  { date: "7/14/2019 21:15:27", shift: "B2", acuity: 6 },
  { date: "7/14/2019 21:18:24", shift: "A3", acuity: 3 },
  { date: "7/14/2019 21:26:32", shift: "C2", acuity: 4 },
  { date: "7/14/2019 21:39:49", shift: "A3", acuity: 6 },
  { date: "7/14/2019 21:39:58", shift: "A3", acuity: 3 },
  { date: "7/14/2019 21:45:47", shift: "D2", acuity: 6 },
  { date: "7/14/2019 21:51:46", shift: "B2", acuity: 3 },
  { date: "7/14/2019 22:21:15", shift: "B3", acuity: 6 },
  { date: "7/14/2019 22:22:36", shift: "C2", acuity: 4 },
  { date: "7/14/2019 22:32:29", shift: "B3", acuity: 4 },
  { date: "7/14/2019 22:32:44", shift: "B3", acuity: 6 },
  { date: "7/14/2019 22:52:17", shift: "A3", acuity: 3 },
  { date: "7/14/2019 22:52:29", shift: "D2", acuity: 3 },
  { date: "7/14/2019 23:03:21", shift: "C2", acuity: 3 },
  { date: "7/14/2019 23:14:36", shift: "B3", acuity: 6 },
  { date: "7/14/2019 23:14:48", shift: "A3", acuity: 3 },
  { date: "7/14/2019 23:17:22", shift: "D2", acuity: 3 },
  { date: "7/14/2019 23:21:11", shift: "C2", acuity: 6 },
  { date: "7/14/2019 23:23:54", shift: "B3", acuity: 3 },
  { date: "7/14/2019 23:24:10", shift: "A3", acuity: 4 },
  { date: "7/14/2019 23:42:15", shift: "D2", acuity: 3 },
  { date: "7/14/2019 23:44:16", shift: "C2", acuity: 3 },
  { date: "7/14/2019 23:53:48", shift: "B3", acuity: 5 },
  { date: "7/14/2019 23:54:01", shift: "A3", acuity: 3 },
  { date: "7/14/2019 23:57:24", shift: "D2", acuity: 3 },
  { date: "7/14/2019 23:59:39", shift: "C2", acuity: 4 },
  { date: "7/15/2019 00:08:53", shift: "B3", acuity: 3 },
  { date: "7/15/2019 00:09:06", shift: "A3", acuity: 4 },
  { date: "7/15/2019 00:29:32", shift: "D2", acuity: 5 },
  { date: "7/15/2019 00:29:46", shift: "B3", acuity: 5 },
  { date: "7/15/2019 00:45:28", shift: "A3", acuity: 4 },
  { date: "7/15/2019 00:46:53", shift: "D2", acuity: 3 },
  { date: "7/15/2019 01:18:19", shift: "B3", acuity: 6 },
  { date: "7/15/2019 01:31:58", shift: "A3", acuity: 3 },
  { date: "7/15/2019 01:41:41", shift: "B3", acuity: 4 },
  { date: "7/15/2019 01:55:07", shift: "A3", acuity: 4 },
  { date: "7/15/2019 01:55:22", shift: "B3", acuity: 3 },
  { date: "7/15/2019 02:13:03", shift: "A3", acuity: 3 },
  { date: "7/15/2019 02:18:59", shift: "B3", acuity: 6 },
  { date: "7/15/2019 03:03:50", shift: "A3", acuity: 4 },
  { date: "7/15/2019 03:46:34", shift: "B3", acuity: 3 },
  { date: "7/15/2019 04:29:03", shift: "A3", acuity: 3 },
  { date: "7/15/2019 04:29:21", shift: "B3", acuity: 3 },
  { date: "7/15/2019 04:34:28", shift: "A3", acuity: 3 },
  { date: "7/15/2019 04:55:21", shift: "B3", acuity: 6 },
  { date: "7/15/2019 05:22:23", shift: "A1", acuity: 4 },
  { date: "7/15/2019 05:36:00", shift: "A1", acuity: 3 },
  { date: "7/15/2019 05:38:41", shift: "A1", acuity: 6 },
  { date: "7/15/2019 05:38:58", shift: "B3", acuity: 3 },
  { date: "7/15/2019 05:43:28", shift: "A1", acuity: 3 },
  { date: "7/15/2019 06:17:48", shift: "B1", acuity: 6 },
  { date: "7/15/2019 06:31:55", shift: "B1", acuity: 3 },
  { date: "7/15/2019 06:32:13", shift: "B1", acuity: 3 },
  { date: "7/15/2019 06:43:38", shift: "A1", acuity: 3 },
  { date: "7/15/2019 06:47:24", shift: "B1", acuity: 3 },
  { date: "7/15/2019 06:55:26", shift: "A1", acuity: 6 },
  { date: "7/15/2019 07:20:39", shift: "B1", acuity: 3 },
  { date: "7/15/2019 07:34:57", shift: "A1", acuity: 3 },
  { date: "7/15/2019 07:56:29", shift: "B1", acuity: 6 },
  { date: "7/15/2019 08:16:25", shift: "A1", acuity: 6 },
  { date: "7/15/2019 08:30:16", shift: "C1", acuity: 3 },
  { date: "7/15/2019 08:51:15", shift: "B1", acuity: 6 },
  { date: "7/15/2019 08:58:49", shift: "A1", acuity: 3 },
  { date: "7/15/2019 09:01:34", shift: "C1", acuity: 3 },
  { date: "7/15/2019 09:16:12", shift: "C1", acuity: 6 },
  { date: "7/15/2019 09:17:46", shift: "B1", acuity: 3 },
  { date: "7/15/2019 09:42:38", shift: "A1", acuity: 3 },
  { date: "7/15/2019 10:11:29", shift: "D1", acuity: 6 },
  { date: "7/15/2019 10:11:52", shift: "C1", acuity: 3 },
  { date: "7/15/2019 10:12:27", shift: "B1", acuity: 3 },
  { date: "7/15/2019 10:14:00", shift: "A1", acuity: 4 },
  { date: "7/15/2019 10:27:34", shift: "D1", acuity: 6 },
  { date: "7/15/2019 10:28:39", shift: "C1", acuity: 3 },
  { date: "7/15/2019 10:29:45", shift: "B1", acuity: 2 },
  { date: "7/15/2019 10:43:39", shift: "D1", acuity: 3 },
  { date: "7/15/2019 11:27:30", shift: "D1", acuity: 6 },
  { date: "7/15/2019 11:28:49", shift: "A1", acuity: 3 },
  { date: "7/15/2019 11:40:03", shift: "C1", acuity: 6 },
  { date: "7/15/2019 11:45:45", shift: "B1", acuity: 6 },
  { date: "7/15/2019 11:47:31", shift: "D1", acuity: 6 },
  { date: "7/15/2019 11:52:08", shift: "A1", acuity: 6 },
  { date: "7/15/2019 12:01:05", shift: "C1", acuity: 2 },
  { date: "7/15/2019 12:04:16", shift: "B1", acuity: 7 },
  { date: "7/15/2019 12:37:33", shift: "D1", acuity: 6 },
  { date: "7/15/2019 12:37:54", shift: "A1", acuity: 6 },
  { date: "7/15/2019 12:55:59", shift: "C1", acuity: 3 },
  { date: "7/15/2019 13:18:03", shift: "A2", acuity: 6 },
  { date: "7/15/2019 13:18:25", shift: "B1", acuity: 6 },
  { date: "7/15/2019 13:33:46", shift: "A2", acuity: 6 },
  { date: "7/15/2019 13:37:36", shift: "A2", acuity: 3 },
  { date: "7/15/2019 13:48:26", shift: "D1", acuity: 6 },
  { date: "7/15/2019 14:20:32", shift: "B2", acuity: 6 },
  { date: "7/15/2019 14:29:13", shift: "C1", acuity: 6 },
  { date: "7/15/2019 14:42:00", shift: "B2", acuity: 6 },
  { date: "7/15/2019 14:43:54", shift: "B2", acuity: 3 },
  { date: "7/15/2019 15:14:56", shift: "A2", acuity: 6 },
  { date: "7/15/2019 15:15:29", shift: "D1", acuity: 3 },
  { date: "7/15/2019 15:19:01", shift: "C1", acuity: 2 },
  { date: "7/15/2019 15:46:50", shift: "B2", acuity: 7 },
  { date: "7/15/2019 15:48:43", shift: "A2", acuity: 6 },
  { date: "7/15/2019 16:14:20", shift: "D1", acuity: 3 },
  { date: "7/15/2019 16:14:41", shift: "C1", acuity: 3 },
  { date: "7/15/2019 16:27:48", shift: "B2", acuity: 3 },
  { date: "7/15/2019 16:30:31", shift: "C2", acuity: 6 },
  { date: "7/15/2019 16:50:04", shift: "A2", acuity: 3 },
  { date: "7/15/2019 16:52:12", shift: "D1", acuity: 6 },
  { date: "7/15/2019 17:17:23", shift: "C2", acuity: 6 },
  { date: "7/15/2019 17:17:42", shift: "C2", acuity: 3 },
  { date: "7/15/2019 17:23:54", shift: "B2", acuity: 6 },
  { date: "7/15/2019 17:24:16", shift: "A2", acuity: 3 },
  { date: "7/15/2019 17:24:37", shift: "D1", acuity: 3 },
  { date: "7/15/2019 17:45:05", shift: "C2", acuity: 3 },
  { date: "7/15/2019 17:45:46", shift: "B2", acuity: 3 },
  { date: "7/15/2019 17:51:48", shift: "A2", acuity: 6 },
  { date: "7/15/2019 17:52:10", shift: "D1", acuity: 6 },
  { date: "7/15/2019 17:58:25", shift: "C2", acuity: 3 },
  { date: "7/15/2019 18:01:47", shift: "D2", acuity: 6 },
  { date: "7/15/2019 18:02:07", shift: "B2", acuity: 3 },
  { date: "7/15/2019 18:11:46", shift: "A2", acuity: 3 },
  { date: "7/15/2019 18:28:25", shift: "C2", acuity: 3 },
  { date: "7/15/2019 18:28:42", shift: "D2", acuity: 3 },
  { date: "7/15/2019 18:38:11", shift: "D2", acuity: 6 },
  { date: "7/15/2019 18:59:04", shift: "D2", acuity: 3 },
  { date: "7/15/2019 19:06:27", shift: "B2", acuity: 2 },
  { date: "7/15/2019 19:17:42", shift: "A2", acuity: 3 },
  { date: "7/15/2019 19:18:41", shift: "C2", acuity: 3 },
  { date: "7/15/2019 19:21:24", shift: "D2", acuity: 6 },
  { date: "7/15/2019 19:22:29", shift: "B2", acuity: 3 },
  { date: "7/15/2019 19:31:08", shift: "A2", acuity: 3 },
  { date: "7/15/2019 19:45:59", shift: "C2", acuity: 3 },
  { date: "7/15/2019 19:50:16", shift: "D2", acuity: 3 },
  { date: "7/15/2019 20:06:26", shift: "B2", acuity: 3 },
  { date: "7/15/2019 20:07:36", shift: "A2", acuity: 7 },
  { date: "7/15/2019 20:11:20", shift: "C2", acuity: 3 },
  { date: "7/15/2019 20:11:50", shift: "D2", acuity: 3 },
  { date: "7/15/2019 20:19:31", shift: "B2", acuity: 4 },
  { date: "7/15/2019 20:22:30", shift: "A2", acuity: 6 },
  { date: "7/15/2019 20:31:41", shift: "C2", acuity: 3 },
  { date: "7/15/2019 20:40:33", shift: "D2", acuity: 6 },
  { date: "7/15/2019 20:40:47", shift: "B2", acuity: 3 },
  { date: "7/15/2019 20:50:45", shift: "A2", acuity: 6 },
  { date: "7/15/2019 20:57:00", shift: "C2", acuity: 6 },
  { date: "7/15/2019 21:03:49", shift: "A3", acuity: 2 },
  { date: "7/15/2019 21:04:45", shift: "D2", acuity: 3 },
  { date: "7/15/2019 21:32:37", shift: "A3", acuity: 3 },
  { date: "7/15/2019 21:39:33", shift: "A3", acuity: 6 },
  { date: "7/15/2019 21:47:31", shift: "B2", acuity: 3 },
  { date: "7/15/2019 22:06:12", shift: "B3", acuity: 3 },
  { date: "7/15/2019 22:06:41", shift: "C2", acuity: 6 },
  { date: "7/15/2019 22:10:11", shift: "D2", acuity: 3 },
  { date: "7/15/2019 22:22:19", shift: "A3", acuity: 6 },
  { date: "7/15/2019 22:33:36", shift: "B3", acuity: 4 },
  { date: "7/15/2019 22:43:24", shift: "B3", acuity: 4 },
  { date: "7/15/2019 22:58:56", shift: "C2", acuity: 4 },
  { date: "7/15/2019 23:00:18", shift: "D2", acuity: 3 },
  { date: "7/15/2019 23:07:37", shift: "A3", acuity: 4 },
  { date: "7/15/2019 23:20:27", shift: "B3", acuity: 6 },
  { date: "7/15/2019 23:25:48", shift: "C2", acuity: 3 },
  { date: "7/15/2019 23:39:09", shift: "D2", acuity: 4 },
  { date: "7/15/2019 23:40:27", shift: "A3", acuity: 4 },
  { date: "7/15/2019 23:50:13", shift: "B3", acuity: 3 },
  { date: "7/15/2019 23:51:53", shift: "C2", acuity: 3 },
  { date: "7/16/2019 00:04:59", shift: "D2", acuity: 5 },
  { date: "7/16/2019 00:09:10", shift: "A3", acuity: 3 },
  { date: "7/16/2019 00:16:18", shift: "B3", acuity: 4 },
  { date: "7/16/2019 00:16:48", shift: "D2", acuity: 3 },
  { date: "7/16/2019 00:17:19", shift: "A3", acuity: 3 },
  { date: "7/16/2019 00:19:36", shift: "B3", acuity: 3 },
  { date: "7/16/2019 00:34:19", shift: "D2", acuity: 4 },
  { date: "7/16/2019 00:51:26", shift: "A3", acuity: 3 },
  { date: "7/16/2019 01:11:08", shift: "B3", acuity: 6 },
  { date: "7/16/2019 01:23:46", shift: "D2", acuity: 3 },
  { date: "7/16/2019 01:40:27", shift: "A3", acuity: 2 },
  { date: "7/16/2019 01:43:48", shift: "B3", acuity: 4 },
  { date: "7/16/2019 01:55:57", shift: "A3", acuity: 4 },
  { date: "7/16/2019 02:30:23", shift: "B3", acuity: 7 },
  { date: "7/16/2019 02:38:19", shift: "A3", acuity: 3 },
  { date: "7/16/2019 02:38:44", shift: "B3", acuity: 3 },
  { date: "7/16/2019 02:51:09", shift: "A3", acuity: 3 },
  { date: "7/16/2019 03:11:08", shift: "B3", acuity: 4 },
  { date: "7/16/2019 04:27:32", shift: "A3", acuity: 3 },
  { date: "7/16/2019 05:00:36", shift: "A1", acuity: 4 },
  { date: "7/16/2019 05:16:32", shift: "B3", acuity: 4 },
  { date: "7/16/2019 05:36:29", shift: "A1", acuity: 5 },
  { date: "7/16/2019 05:58:05", shift: "A1", acuity: 6 },
  { date: "7/16/2019 06:05:52", shift: "B1", acuity: 6 },
  { date: "7/16/2019 06:42:38", shift: "B1", acuity: 3 },
  { date: "7/16/2019 06:55:31", shift: "B1", acuity: 4 },
  { date: "7/16/2019 06:59:55", shift: "A1", acuity: 6 },
  { date: "7/16/2019 07:14:23", shift: "B1", acuity: 3 },
  { date: "7/16/2019 07:29:11", shift: "A1", acuity: 4 },
  { date: "7/16/2019 07:29:50", shift: "B1", acuity: 3 },
  { date: "7/16/2019 07:44:34", shift: "A1", acuity: 3 },
  { date: "7/16/2019 07:58:23", shift: "B1", acuity: 4 },
  { date: "7/16/2019 07:58:44", shift: "A1", acuity: 3 },
  { date: "7/16/2019 08:34:08", shift: "C1", acuity: 3 },
  { date: "7/16/2019 09:07:35", shift: "C1", acuity: 3 },
  { date: "7/16/2019 09:10:20", shift: "C1", acuity: 3 },
  { date: "7/16/2019 09:10:52", shift: "B1", acuity: 3 },
  { date: "7/16/2019 09:18:04", shift: "A1", acuity: 6 },
  { date: "7/16/2019 09:19:43", shift: "C1", acuity: 3 },
  { date: "7/16/2019 09:40:35", shift: "B1", acuity: 3 },
  { date: "7/16/2019 09:42:23", shift: "A1", acuity: 3 },
  { date: "7/16/2019 10:17:48", shift: "D1", acuity: 3 },
  { date: "7/16/2019 10:21:44", shift: "C1", acuity: 3 },
  { date: "7/16/2019 10:24:22", shift: "B1", acuity: 6 },
  { date: "7/16/2019 10:36:13", shift: "D1", acuity: 6 },
  { date: "7/16/2019 11:08:12", shift: "D1", acuity: 6 },
  { date: "7/16/2019 11:13:34", shift: "A1", acuity: 3 },
  { date: "7/16/2019 11:17:34", shift: "C1", acuity: 3 },
  { date: "7/16/2019 11:24:14", shift: "B1", acuity: 3 },
  { date: "7/16/2019 11:24:29", shift: "D1", acuity: 6 },
  { date: "7/16/2019 11:43:25", shift: "A1", acuity: 3 },
  { date: "7/16/2019 11:58:58", shift: "C1", acuity: 3 },
  { date: "7/16/2019 12:17:25", shift: "B1", acuity: 6 },
  { date: "7/16/2019 12:18:44", shift: "D1", acuity: 6 },
  { date: "7/16/2019 12:34:23", shift: "A1", acuity: 3 },
  { date: "7/16/2019 12:39:19", shift: "C1", acuity: 3 },
  { date: "7/16/2019 13:06:15", shift: "A2", acuity: 6 },
  { date: "7/16/2019 13:40:36", shift: "A2", acuity: 6 },
  { date: "7/16/2019 13:44:32", shift: "A2", acuity: 3 },
  { date: "7/16/2019 13:45:08", shift: "B1", acuity: 6 },
  { date: "7/16/2019 13:51:26", shift: "D1", acuity: 6 },
  { date: "7/16/2019 13:52:05", shift: "C1", acuity: 6 },
  { date: "7/16/2019 14:07:59", shift: "B2", acuity: 6 },
  { date: "7/16/2019 14:08:28", shift: "A2", acuity: 6 },
  { date: "7/16/2019 14:09:02", shift: "D1", acuity: 3 },
  { date: "7/16/2019 14:11:29", shift: "C1", acuity: 3 },
  { date: "7/16/2019 14:12:39", shift: "B2", acuity: 3 },
  { date: "7/16/2019 14:29:50", shift: "A2", acuity: 6 },
  { date: "7/16/2019 14:35:59", shift: "B2", acuity: 6 },
  { date: "7/16/2019 14:41:29", shift: "B2", acuity: 3 },
  { date: "7/16/2019 15:02:58", shift: "D1", acuity: 3 },
  { date: "7/16/2019 15:03:20", shift: "C1", acuity: 3 },
  { date: "7/16/2019 15:03:49", shift: "A2", acuity: 3 },
  { date: "7/16/2019 15:15:40", shift: "B2", acuity: 6 },
  { date: "7/16/2019 15:31:01", shift: "D1", acuity: 6 },
  { date: "7/16/2019 15:40:54", shift: "C1", acuity: 6 },
  { date: "7/16/2019 15:51:14", shift: "A2", acuity: 3 },
  { date: "7/16/2019 16:08:45", shift: "B2", acuity: 6 },
  { date: "7/16/2019 16:16:49", shift: "D1", acuity: 6 },
  { date: "7/16/2019 16:36:06", shift: "C2", acuity: 6 },
  { date: "7/16/2019 16:40:12", shift: "A2", acuity: 3 },
  { date: "7/16/2019 16:49:22", shift: "B2", acuity: 6 },
  { date: "7/16/2019 16:51:26", shift: "D1", acuity: 6 },
  { date: "7/16/2019 17:04:46", shift: "C2", acuity: 3 },
  { date: "7/16/2019 17:11:15", shift: "C2", acuity: 6 },
  { date: "7/16/2019 17:23:48", shift: "A2", acuity: 6 },
  { date: "7/16/2019 17:25:32", shift: "B2", acuity: 6 },
  { date: "7/16/2019 17:32:54", shift: "D1", acuity: 6 },
  { date: "7/16/2019 17:33:26", shift: "C2", acuity: 3 },
  { date: "7/16/2019 17:37:53", shift: "A2", acuity: 6 },
  { date: "7/16/2019 17:38:29", shift: "B2", acuity: 3 },
  { date: "7/16/2019 17:44:10", shift: "D1", acuity: 3 },
  { date: "7/16/2019 17:48:24", shift: "C2", acuity: 6 },
  { date: "7/16/2019 18:06:21", shift: "D2", acuity: 6 },
  { date: "7/16/2019 18:07:07", shift: "A2", acuity: 3 },
  { date: "7/16/2019 18:15:50", shift: "B2", acuity: 6 },
  { date: "7/16/2019 18:20:34", shift: "C2", acuity: 3 },
  { date: "7/16/2019 18:26:18", shift: "D2", acuity: 6 },
  { date: "7/16/2019 18:30:20", shift: "D2", acuity: 3 },
  { date: "7/16/2019 18:31:18", shift: "D2", acuity: 3 },
  { date: "7/16/2019 18:37:19", shift: "A2", acuity: 3 },
  { date: "7/16/2019 18:50:18", shift: "B2", acuity: 6 },
  { date: "7/16/2019 18:50:50", shift: "C2", acuity: 3 },
  { date: "7/16/2019 19:05:07", shift: "D2", acuity: 6 },
  { date: "7/16/2019 19:24:54", shift: "A2", acuity: 3 },
  { date: "7/16/2019 19:35:47", shift: "B2", acuity: 3 },
  { date: "7/16/2019 19:36:20", shift: "C2", acuity: 3 },
  { date: "7/16/2019 19:47:18", shift: "D2", acuity: 3 },
  { date: "7/16/2019 19:56:08", shift: "A2", acuity: 3 },
  { date: "7/16/2019 20:00:32", shift: "B2", acuity: 3 },
  { date: "7/16/2019 20:16:32", shift: "C2", acuity: 3 },
  { date: "7/16/2019 20:16:53", shift: "D2", acuity: 3 },
  { date: "7/16/2019 20:32:13", shift: "A2", acuity: 3 },
  { date: "7/16/2019 20:42:09", shift: "B2", acuity: 3 },
  { date: "7/16/2019 20:43:42", shift: "C2", acuity: 3 },
  { date: "7/16/2019 20:44:11", shift: "D2", acuity: 3 },
  { date: "7/16/2019 21:15:37", shift: "A3", acuity: 3 },
  { date: "7/16/2019 21:24:45", shift: "B2", acuity: 3 },
  { date: "7/16/2019 21:51:41", shift: "A3", acuity: 3 },
  { date: "7/16/2019 21:52:41", shift: "A3", acuity: 3 },
  { date: "7/16/2019 21:53:21", shift: "C2", acuity: 3 },
  { date: "7/16/2019 21:58:31", shift: "D2", acuity: 3 },
  { date: "7/16/2019 22:04:09", shift: "B3", acuity: 3 },
  { date: "7/16/2019 22:05:33", shift: "A3", acuity: 3 },
  { date: "7/16/2019 22:07:48", shift: "C2", acuity: 3 },
  { date: "7/16/2019 22:16:21", shift: "D2", acuity: 3 },
  { date: "7/16/2019 22:27:39", shift: "B3", acuity: 3 },
  { date: "7/16/2019 22:38:53", shift: "B3", acuity: 3 },
  { date: "7/16/2019 22:39:14", shift: "B3", acuity: 4 },
  { date: "7/16/2019 22:40:22", shift: "A3", acuity: 3 },
  { date: "7/16/2019 22:58:11", shift: "C2", acuity: 3 },
  { date: "7/16/2019 23:08:22", shift: "D2", acuity: 3 },
  { date: "7/16/2019 23:13:03", shift: "B3", acuity: 4 },
  { date: "7/16/2019 23:19:40", shift: "A3", acuity: 4 },
  { date: "7/16/2019 23:49:49", shift: "C2", acuity: 4 },
  { date: "7/16/2019 23:50:12", shift: "D2", acuity: 3 },
  { date: "7/16/2019 23:57:06", shift: "B3", acuity: 3 },
  { date: "7/17/2019 00:02:08", shift: "A3", acuity: 3 },
  { date: "7/17/2019 00:15:49", shift: "D2", acuity: 3 },
  { date: "7/17/2019 00:17:53", shift: "B3", acuity: 3 },
  { date: "7/17/2019 00:24:44", shift: "A3", acuity: 3 },
  { date: "7/17/2019 00:32:57", shift: "D2", acuity: 3 },
  { date: "7/17/2019 00:34:37", shift: "B3", acuity: 5 },
  { date: "7/17/2019 00:47:10", shift: "A3", acuity: 3 },
  { date: "7/17/2019 00:58:35", shift: "D2", acuity: 3 },
  { date: "7/17/2019 01:15:06", shift: "B3", acuity: 3 },
  { date: "7/17/2019 01:18:11", shift: "A3", acuity: 6 },
  { date: "7/17/2019 01:50:22", shift: "B3", acuity: 4 },
  { date: "7/17/2019 02:04:07", shift: "A3", acuity: 3 },
  { date: "7/17/2019 02:11:26", shift: "B3", acuity: 3 },
  { date: "7/17/2019 02:42:47", shift: "A3", acuity: 4 },
  { date: "7/17/2019 03:18:45", shift: "B3", acuity: 3 },
  { date: "7/17/2019 03:29:00", shift: "A3", acuity: 3 },
  { date: "7/17/2019 03:35:55", shift: "B3", acuity: 3 },
  { date: "7/17/2019 04:05:39", shift: "A3", acuity: 3 },
  { date: "7/17/2019 04:36:44", shift: "B3", acuity: 4 },
  { date: "7/17/2019 05:28:07", shift: "A1", acuity: 3 },
  { date: "7/17/2019 05:28:18", shift: "B3", acuity: 3 },
  { date: "7/17/2019 05:45:35", shift: "A1", acuity: 3 },
  { date: "7/17/2019 05:55:14", shift: "A1", acuity: 3 },
  { date: "7/17/2019 07:02:48", shift: "B1", acuity: 6 },
  { date: "7/17/2019 07:27:33", shift: "B1", acuity: 3 },
  { date: "7/17/2019 07:39:29", shift: "B1", acuity: 4 },
  { date: "7/17/2019 07:50:13", shift: "A1", acuity: 3 },
  { date: "7/17/2019 07:52:52", shift: "B1", acuity: 3 },
  { date: "7/17/2019 08:03:19", shift: "A1", acuity: 6 },
  { date: "7/17/2019 08:22:00", shift: "B1", acuity: 6 },
  { date: "7/17/2019 08:33:06", shift: "C1", acuity: 4 },
  { date: "7/17/2019 08:33:34", shift: "A1", acuity: 6 },
  { date: "7/17/2019 08:35:13", shift: "B1", acuity: 6 },
  { date: "7/17/2019 09:02:12", shift: "C1", acuity: 3 },
  { date: "7/17/2019 09:10:17", shift: "C1", acuity: 3 },
  { date: "7/17/2019 09:14:56", shift: "A1", acuity: 6 },
  { date: "7/17/2019 09:18:17", shift: "B1", acuity: 3 },
  { date: "7/17/2019 09:28:20", shift: "C1", acuity: 6 },
  { date: "7/17/2019 09:29:07", shift: "A1", acuity: 3 },
  { date: "7/17/2019 09:41:21", shift: "B1", acuity: 6 },
  { date: "7/17/2019 09:56:55", shift: "C1", acuity: 4 },
  { date: "7/17/2019 10:11:23", shift: "D1", acuity: 3 },
  { date: "7/17/2019 10:11:47", shift: "A1", acuity: 3 },
  { date: "7/17/2019 10:16:07", shift: "B1", acuity: 6 },
  { date: "7/17/2019 10:16:40", shift: "C1", acuity: 3 },
  { date: "7/17/2019 10:44:38", shift: "D1", acuity: 3 },
  { date: "7/17/2019 10:54:45", shift: "D1", acuity: 6 },
  { date: "7/17/2019 10:55:19", shift: "A1", acuity: 6 },
  { date: "7/17/2019 10:59:44", shift: "B1", acuity: 6 },
  { date: "7/17/2019 11:21:40", shift: "C1", acuity: 2 },
  { date: "7/17/2019 11:37:47", shift: "D1", acuity: 6 },
  { date: "7/17/2019 11:39:06", shift: "A1", acuity: 3 },
  { date: "7/17/2019 11:51:57", shift: "B1", acuity: 3 },
  { date: "7/17/2019 11:55:54", shift: "C1", acuity: 3 },
  { date: "7/17/2019 12:03:00", shift: "D1", acuity: 6 },
  { date: "7/17/2019 12:22:46", shift: "A1", acuity: 3 },
  { date: "7/17/2019 12:46:15", shift: "B1", acuity: 3 },
  { date: "7/17/2019 12:46:40", shift: "C1", acuity: 6 },
  { date: "7/17/2019 12:59:48", shift: "D1", acuity: 6 },
  { date: "7/17/2019 13:06:42", shift: "A2", acuity: 3 },
  { date: "7/17/2019 13:08:08", shift: "B1", acuity: 3 },
  { date: "7/17/2019 13:20:59", shift: "C1", acuity: 6 },
  { date: "7/17/2019 13:33:35", shift: "A2", acuity: 3 },
  { date: "7/17/2019 13:50:26", shift: "A2", acuity: 3 },
  { date: "7/17/2019 14:04:19", shift: "B2", acuity: 3 },
  { date: "7/17/2019 14:04:46", shift: "D1", acuity: 3 },
  { date: "7/17/2019 14:06:24", shift: "C1", acuity: 3 },
  { date: "7/17/2019 14:13:41", shift: "A2", acuity: 6 },
  { date: "7/17/2019 14:30:14", shift: "B2", acuity: 3 },
  { date: "7/17/2019 14:30:40", shift: "B2", acuity: 3 },
  { date: "7/17/2019 14:45:28", shift: "D1", acuity: 3 },
  { date: "7/17/2019 14:50:28", shift: "C1", acuity: 6 },
  { date: "7/17/2019 14:55:52", shift: "A2", acuity: 6 },
  { date: "7/17/2019 15:03:51", shift: "B2", acuity: 3 },
  { date: "7/17/2019 15:21:10", shift: "D1", acuity: 6 },
  { date: "7/17/2019 15:21:35", shift: "C1", acuity: 6 },
  { date: "7/17/2019 15:55:33", shift: "A2", acuity: 6 },
  { date: "7/17/2019 15:56:38", shift: "B2", acuity: 3 },
  { date: "7/17/2019 16:04:52", shift: "D1", acuity: 6 },
  { date: "7/17/2019 16:13:52", shift: "C1", acuity: 3 },
  { date: "7/17/2019 16:25:04", shift: "A2", acuity: 3 },
  { date: "7/17/2019 16:35:04", shift: "C2", acuity: 3 },
  { date: "7/17/2019 16:41:00", shift: "B2", acuity: 3 },
  { date: "7/17/2019 16:47:29", shift: "D1", acuity: 3 },
  { date: "7/17/2019 17:06:38", shift: "C2", acuity: 6 },
  { date: "7/17/2019 17:16:50", shift: "C2", acuity: 6 },
  { date: "7/17/2019 17:17:13", shift: "A2", acuity: 6 },
  { date: "7/17/2019 17:19:08", shift: "B2", acuity: 6 },
  { date: "7/17/2019 17:47:06", shift: "D1", acuity: 6 },
  { date: "7/17/2019 17:47:22", shift: "C2", acuity: 3 },
  { date: "7/17/2019 17:48:47", shift: "A2", acuity: 6 },
  { date: "7/17/2019 17:53:51", shift: "B2", acuity: 4 },
  { date: "7/17/2019 18:05:35", shift: "D2", acuity: 6 },
  { date: "7/17/2019 18:12:32", shift: "C2", acuity: 3 },
  { date: "7/17/2019 18:34:25", shift: "D2", acuity: 3 },
  { date: "7/17/2019 18:36:17", shift: "D2", acuity: 3 },
  { date: "7/17/2019 18:41:01", shift: "A2", acuity: 6 },
  { date: "7/17/2019 18:53:58", shift: "B2", acuity: 2 },
  { date: "7/17/2019 18:56:37", shift: "C2", acuity: 3 },
  { date: "7/17/2019 18:59:44", shift: "D2", acuity: 6 },
  { date: "7/17/2019 19:12:51", shift: "A2", acuity: 6 },
  { date: "7/17/2019 19:13:29", shift: "B2", acuity: 6 },
  { date: "7/17/2019 19:21:12", shift: "C2", acuity: 3 },
  { date: "7/17/2019 19:22:24", shift: "D2", acuity: 3 },
  { date: "7/17/2019 19:22:41", shift: "A2", acuity: 6 },
  { date: "7/17/2019 19:25:25", shift: "B2", acuity: 6 },
  { date: "7/17/2019 19:25:37", shift: "C2", acuity: 3 },
  { date: "7/17/2019 19:43:46", shift: "D2", acuity: 6 },
  { date: "7/17/2019 19:44:04", shift: "A2", acuity: 6 },
  { date: "7/17/2019 20:17:07", shift: "B2", acuity: 6 },
  { date: "7/17/2019 20:17:21", shift: "C2", acuity: 3 },
  { date: "7/17/2019 20:17:35", shift: "D2", acuity: 4 },
  { date: "7/17/2019 20:33:36", shift: "A2", acuity: 6 },
  { date: "7/17/2019 20:33:46", shift: "B2", acuity: 3 },
  { date: "7/17/2019 20:43:11", shift: "C2", acuity: 2 },
  { date: "7/17/2019 21:01:59", shift: "A3", acuity: 3 },
  { date: "7/17/2019 21:02:19", shift: "D2", acuity: 6 },
  { date: "7/17/2019 21:11:39", shift: "B2", acuity: 6 },
  { date: "7/17/2019 21:11:53", shift: "C2", acuity: 6 },
  { date: "7/17/2019 21:23:18", shift: "A3", acuity: 3 },
  { date: "7/17/2019 21:23:31", shift: "D2", acuity: 6 },
  { date: "7/17/2019 21:42:18", shift: "A3", acuity: 6 },
  { date: "7/17/2019 21:59:05", shift: "A3", acuity: 4 },
  { date: "7/17/2019 22:01:18", shift: "B3", acuity: 6 },
  { date: "7/17/2019 22:04:11", shift: "C2", acuity: 6 },
  { date: "7/17/2019 22:17:34", shift: "D2", acuity: 3 },
  { date: "7/17/2019 22:19:31", shift: "A3", acuity: 3 },
  { date: "7/17/2019 22:19:46", shift: "B3", acuity: 3 },
  { date: "7/17/2019 22:45:28", shift: "B3", acuity: 4 },
  { date: "7/17/2019 22:45:44", shift: "B3", acuity: 6 },
  { date: "7/17/2019 23:05:07", shift: "C2", acuity: 6 },
  { date: "7/17/2019 23:16:39", shift: "D2", acuity: 3 },
  { date: "7/17/2019 23:17:01", shift: "A3", acuity: 6 },
  { date: "7/17/2019 23:17:52", shift: "B3", acuity: 6 },
  { date: "7/17/2019 23:21:43", shift: "C2", acuity: 3 },
  { date: "7/17/2019 23:22:08", shift: "D2", acuity: 4 },
  { date: "7/17/2019 23:29:15", shift: "A3", acuity: 4 },
  { date: "7/17/2019 23:47:05", shift: "B3", acuity: 3 },
  { date: "7/17/2019 23:47:23", shift: "C2", acuity: 4 },
  { date: "7/17/2019 23:49:02", shift: "D2", acuity: 6 },
  { date: "7/18/2019 00:13:41", shift: "A3", acuity: 4 },
  { date: "7/18/2019 00:14:21", shift: "B3", acuity: 6 },
  { date: "7/18/2019 00:14:39", shift: "D2", acuity: 3 },
  { date: "7/18/2019 00:37:04", shift: "A3", acuity: 3 },
  { date: "7/18/2019 00:37:10", shift: "B3", acuity: 4 },
  { date: "7/18/2019 00:47:49", shift: "D2", acuity: 4 },
  { date: "7/18/2019 00:49:47", shift: "A3", acuity: 6 },
  { date: "7/18/2019 00:49:59", shift: "B3", acuity: 3 },
  { date: "7/18/2019 00:50:15", shift: "D2", acuity: 6 },
  { date: "7/18/2019 00:53:48", shift: "A3", acuity: 4 },
  { date: "7/18/2019 01:03:47", shift: "B3", acuity: 5 },
  { date: "7/18/2019 01:04:11", shift: "D2", acuity: 3 },
  { date: "7/18/2019 01:18:45", shift: "A3", acuity: 3 },
  { date: "7/18/2019 01:32:16", shift: "B3", acuity: 4 },
  { date: "7/18/2019 01:44:57", shift: "A3", acuity: 4 },
  { date: "7/18/2019 01:47:16", shift: "B3", acuity: 3 },
  { date: "7/18/2019 02:24:48", shift: "A3", acuity: 3 },
  { date: "7/18/2019 02:28:25", shift: "B3", acuity: 4 },
  { date: "7/18/2019 02:48:17", shift: "A3", acuity: 3 },
  { date: "7/18/2019 03:06:33", shift: "B3", acuity: 6 },
  { date: "7/18/2019 03:07:46", shift: "A3", acuity: 4 },
  { date: "7/18/2019 03:50:58", shift: "B3", acuity: 3 },
  { date: "7/18/2019 03:55:25", shift: "A3", acuity: 4 },
  { date: "7/18/2019 05:04:46", shift: "A1", acuity: 3 },
  { date: "7/18/2019 05:05:04", shift: "B3", acuity: 4 },
  { date: "7/18/2019 05:16:41", shift: "A1", acuity: 4 },
  { date: "7/18/2019 05:35:04", shift: "A1", acuity: 4 },
  { date: "7/18/2019 05:55:56", shift: "A1", acuity: 6 },
  { date: "7/18/2019 06:18:58", shift: "B1", acuity: 6 },
  { date: "7/18/2019 06:43:42", shift: "B1", acuity: 4 },
  { date: "7/18/2019 07:03:29", shift: "B1", acuity: 3 },
  { date: "7/18/2019 07:03:46", shift: "A1", acuity: 5 },
  { date: "7/18/2019 08:13:21", shift: "B1", acuity: 3 },
  { date: "7/18/2019 08:26:40", shift: "A1", acuity: 6 },
  { date: "7/18/2019 08:34:37", shift: "C1", acuity: 3 },
  { date: "7/18/2019 09:18:19", shift: "C1", acuity: 3 },
  { date: "7/18/2019 09:18:47", shift: "C1", acuity: 6 },
  { date: "7/18/2019 09:32:29", shift: "B1", acuity: 3 },
  { date: "7/18/2019 09:34:56", shift: "A1", acuity: 4 },
  { date: "7/18/2019 09:39:50", shift: "C1", acuity: 3 },
  { date: "7/18/2019 09:42:26", shift: "B1", acuity: 6 },
  { date: "7/18/2019 10:09:07", shift: "D1", acuity: 3 },
  { date: "7/18/2019 10:24:10", shift: "A1", acuity: 4 },
  { date: "7/18/2019 10:32:25", shift: "D1", acuity: 6 },
  { date: "7/18/2019 10:32:43", shift: "D1", acuity: 3 },
  { date: "7/18/2019 12:05:42", shift: "C1", acuity: 6 },
  { date: "7/18/2019 12:24:06", shift: "B1", acuity: 6 },
  { date: "7/18/2019 12:24:18", shift: "A1", acuity: 6 },
  { date: "7/18/2019 12:24:37", shift: "D1", acuity: 3 },
  { date: "7/18/2019 12:26:43", shift: "C1", acuity: 6 },
  { date: "7/18/2019 12:27:01", shift: "B1", acuity: 6 },
  { date: "7/18/2019 12:33:10", shift: "A1", acuity: 3 },
  { date: "7/18/2019 12:48:27", shift: "D1", acuity: 6 },
  { date: "7/18/2019 13:35:57", shift: "A2", acuity: 6 },
  { date: "7/18/2019 13:36:59", shift: "A2", acuity: 6 },
  { date: "7/18/2019 13:53:46", shift: "A2", acuity: 6 },
  { date: "7/18/2019 14:04:06", shift: "B2", acuity: 2 },
  { date: "7/18/2019 14:15:21", shift: "C1", acuity: 6 },
  { date: "7/18/2019 14:22:19", shift: "D1", acuity: 6 },
  { date: "7/18/2019 14:22:36", shift: "A2", acuity: 3 },
  { date: "7/18/2019 14:49:56", shift: "B2", acuity: 6 },
  { date: "7/18/2019 14:56:52", shift: "B2", acuity: 3 },
  { date: "7/18/2019 14:57:17", shift: "C1", acuity: 3 },
  { date: "7/18/2019 14:59:18", shift: "D1", acuity: 4 },
  { date: "7/18/2019 15:02:27", shift: "A2", acuity: 3 },
  { date: "7/18/2019 15:21:00", shift: "B2", acuity: 3 },
  { date: "7/18/2019 15:37:00", shift: "C1", acuity: 6 },
  { date: "7/18/2019 15:38:00", shift: "D1", acuity: 3 },
  { date: "7/18/2019 15:45:00", shift: "A2", acuity: 3 },
  { date: "7/18/2019 15:46:00", shift: "B2", acuity: 6 },
  { date: "7/18/2019 15:48:00", shift: "C1", acuity: 6 },
  { date: "7/18/2019 15:49:00", shift: "D1", acuity: 3 },
  { date: "7/18/2019 15:53:00", shift: "A2", acuity: 6 },
  { date: "7/18/2019 16:12:06", shift: "B2", acuity: 3 },
  { date: "7/18/2019 16:18:29", shift: "C1", acuity: 3 },
  { date: "7/18/2019 16:31:40", shift: "C2", acuity: 3 },
  { date: "7/18/2019 16:32:04", shift: "D1", acuity: 6 },
  { date: "7/18/2019 16:32:25", shift: "A2", acuity: 6 },
  { date: "7/18/2019 16:32:39", shift: "B2", acuity: 6 },
  { date: "7/18/2019 16:59:34", shift: "C2", acuity: 6 },
  { date: "7/18/2019 17:10:43", shift: "C2", acuity: 6 },
  { date: "7/18/2019 17:22:27", shift: "C2", acuity: 6 },
  { date: "7/18/2019 17:36:59", shift: "D1", acuity: 3 },
  { date: "7/18/2019 17:57:04", shift: "A2", acuity: 2 },
  { date: "7/18/2019 18:28:18", shift: "D2", acuity: 3 },
  { date: "7/18/2019 18:38:55", shift: "D2", acuity: 3 },
  { date: "7/18/2019 18:55:11", shift: "D2", acuity: 3 },
  { date: "7/18/2019 18:58:15", shift: "B2", acuity: 6 },
  { date: "7/18/2019 19:07:36", shift: "C2", acuity: 3 },
  { date: "7/18/2019 19:14:51", shift: "A2", acuity: 6 },
  { date: "7/18/2019 19:30:40", shift: "D2", acuity: 3 },
  { date: "7/18/2019 19:38:27", shift: "B2", acuity: 3 },
  { date: "7/18/2019 19:47:10", shift: "C2", acuity: 6 },
  { date: "7/18/2019 19:47:56", shift: "A2", acuity: 6 },
  { date: "7/18/2019 19:50:03", shift: "D2", acuity: 3 },
  { date: "7/18/2019 19:55:08", shift: "B2", acuity: 3 },
  { date: "7/18/2019 20:09:50", shift: "C2", acuity: 6 },
  { date: "7/18/2019 20:41:14", shift: "A2", acuity: 6 },
  { date: "7/18/2019 20:41:45", shift: "D2", acuity: 3 },
  { date: "7/18/2019 20:45:06", shift: "B2", acuity: 3 },
  { date: "7/18/2019 20:51:41", shift: "C2", acuity: 3 },
  { date: "7/18/2019 20:52:15", shift: "A2", acuity: 6 },
  { date: "7/18/2019 20:52:46", shift: "D2", acuity: 3 },
  { date: "7/18/2019 20:57:22", shift: "B2", acuity: 3 },
  { date: "7/18/2019 21:09:20", shift: "A3", acuity: 3 },
  { date: "7/18/2019 21:14:41", shift: "C2", acuity: 6 },
  { date: "7/18/2019 21:15:04", shift: "D2", acuity: 6 },
  { date: "7/18/2019 21:39:37", shift: "A3", acuity: 3 },
  { date: "7/18/2019 21:53:33", shift: "A3", acuity: 3 },
  { date: "7/18/2019 21:54:54", shift: "B2", acuity: 3 },
  { date: "7/18/2019 21:56:03", shift: "C2", acuity: 3 },
  { date: "7/18/2019 21:59:56", shift: "D2", acuity: 3 },
  { date: "7/18/2019 22:11:45", shift: "B3", acuity: 3 },
  { date: "7/18/2019 22:12:54", shift: "A3", acuity: 6 },
  { date: "7/18/2019 22:25:41", shift: "C2", acuity: 3 },
  { date: "7/18/2019 22:26:05", shift: "D2", acuity: 3 },
  { date: "7/18/2019 22:34:29", shift: "B3", acuity: 6 },
  { date: "7/18/2019 22:40:40", shift: "B3", acuity: 6 },
  { date: "7/18/2019 23:08:22", shift: "A3", acuity: 3 },
  { date: "7/18/2019 23:08:50", shift: "C2", acuity: 3 },
  { date: "7/18/2019 23:16:05", shift: "D2", acuity: 3 },
  { date: "7/18/2019 23:29:07", shift: "B3", acuity: 3 },
  { date: "7/18/2019 23:38:37", shift: "A3", acuity: 3 },
  { date: "7/19/2019 00:01:08", shift: "D2", acuity: 3 },
  { date: "7/19/2019 00:01:23", shift: "B3", acuity: 3 },
  { date: "7/19/2019 00:16:18", shift: "A3", acuity: 3 },
  { date: "7/19/2019 00:16:34", shift: "D2", acuity: 5 },
  { date: "7/19/2019 00:26:08", shift: "B3", acuity: 6 },
  { date: "7/19/2019 00:31:15", shift: "A3", acuity: 4 },
  { date: "7/19/2019 00:31:52", shift: "D2", acuity: 3 },
  { date: "7/19/2019 01:04:12", shift: "B3", acuity: 4 },
  { date: "7/19/2019 01:05:11", shift: "A3", acuity: 3 },
  { date: "7/19/2019 01:38:23", shift: "B3", acuity: 6 },
  { date: "7/19/2019 01:42:59", shift: "A3", acuity: 3 },
  { date: "7/19/2019 01:52:01", shift: "B3", acuity: 3 },
  { date: "7/19/2019 02:04:08", shift: "A3", acuity: 6 },
  { date: "7/19/2019 02:07:23", shift: "B3", acuity: 3 },
  { date: "7/19/2019 02:26:26", shift: "A3", acuity: 3 },
  { date: "7/19/2019 02:26:59", shift: "B3", acuity: 3 },
  { date: "7/19/2019 02:43:03", shift: "A3", acuity: 3 },
  { date: "7/19/2019 02:50:55", shift: "B3", acuity: 3 },
  { date: "7/19/2019 03:13:36", shift: "A3", acuity: 3 },
  { date: "7/19/2019 03:23:48", shift: "B3", acuity: 3 },
  { date: "7/19/2019 03:24:30", shift: "A3", acuity: 4 },
  { date: "7/19/2019 04:30:08", shift: "B3", acuity: 6 },
  { date: "7/19/2019 04:46:01", shift: "A3", acuity: 3 },
  { date: "7/19/2019 06:00:31", shift: "A1", acuity: 3 },
  { date: "7/19/2019 06:20:37", shift: "A1", acuity: 3 },
  { date: "7/19/2019 06:39:07", shift: "A1", acuity: 4 },
  { date: "7/19/2019 06:49:45", shift: "B1", acuity: 3 },
  { date: "7/19/2019 07:22:25", shift: "B1", acuity: 4 },
  { date: "7/19/2019 07:23:00", shift: "B1", acuity: 6 },
  { date: "7/19/2019 07:51:56", shift: "A1", acuity: 6 },
  { date: "7/19/2019 07:52:51", shift: "B1", acuity: 3 },
  { date: "7/19/2019 07:53:28", shift: "A1", acuity: 3 },
  { date: "7/19/2019 07:58:15", shift: "B1", acuity: 3 },
  { date: "7/19/2019 08:32:28", shift: "C1", acuity: 3 },
  { date: "7/19/2019 08:33:18", shift: "A1", acuity: 6 },
  { date: "7/19/2019 08:59:07", shift: "B1", acuity: 3 },
  { date: "7/19/2019 09:09:10", shift: "C1", acuity: 6 },
  { date: "7/19/2019 09:21:33", shift: "C1", acuity: 6 },
  { date: "7/19/2019 09:21:56", shift: "A1", acuity: 3 },
  { date: "7/19/2019 09:22:33", shift: "B1", acuity: 3 },
  { date: "7/19/2019 09:29:28", shift: "C1", acuity: 3 },
  { date: "7/19/2019 09:29:52", shift: "A1", acuity: 3 },
  { date: "7/19/2019 10:08:07", shift: "D1", acuity: 4 },
  { date: "7/19/2019 10:08:26", shift: "B1", acuity: 3 },
  { date: "7/19/2019 10:11:03", shift: "C1", acuity: 2 },
  { date: "7/19/2019 10:17:28", shift: "A1", acuity: 3 },
  { date: "7/19/2019 10:18:24", shift: "D1", acuity: 4 },
  { date: "7/19/2019 10:42:04", shift: "D1", acuity: 6 },
  { date: "7/19/2019 10:53:18", shift: "D1", acuity: 3 },
  { date: "7/19/2019 10:54:26", shift: "B1", acuity: 3 },
  { date: "7/19/2019 10:54:55", shift: "C1", acuity: 3 },
  { date: "7/19/2019 11:06:26", shift: "A1", acuity: 3 },
  { date: "7/19/2019 11:26:36", shift: "D1", acuity: 3 },
  { date: "7/19/2019 11:35:12", shift: "B1", acuity: 3 },
  { date: "7/19/2019 11:41:27", shift: "C1", acuity: 3 },
  { date: "7/19/2019 11:57:12", shift: "A1", acuity: 4 },
  { date: "7/19/2019 11:57:45", shift: "D1", acuity: 3 },
  { date: "7/19/2019 12:27:24", shift: "B1", acuity: 3 },
  { date: "7/19/2019 13:02:18", shift: "A2", acuity: 3 },
  { date: "7/19/2019 13:15:36", shift: "C1", acuity: 6 },
  { date: "7/19/2019 13:24:06", shift: "D1", acuity: 3 },
  { date: "7/19/2019 13:34:50", shift: "A2", acuity: 3 },
  { date: "7/19/2019 13:35:37", shift: "A2", acuity: 3 },
  { date: "7/19/2019 13:38:45", shift: "B1", acuity: 6 },
  { date: "7/19/2019 13:51:50", shift: "C1", acuity: 3 },
  { date: "7/19/2019 14:15:15", shift: "B2", acuity: 3 },
  { date: "7/19/2019 14:26:51", shift: "D1", acuity: 6 },
  { date: "7/19/2019 14:28:37", shift: "A2", acuity: 3 },
  { date: "7/19/2019 14:32:29", shift: "B2", acuity: 3 },
  { date: "7/19/2019 14:44:41", shift: "B2", acuity: 6 },
  { date: "7/19/2019 15:05:12", shift: "C1", acuity: 3 },
  { date: "7/19/2019 15:18:04", shift: "D1", acuity: 3 },
  { date: "7/19/2019 15:29:35", shift: "A2", acuity: 6 },
  { date: "7/19/2019 15:37:54", shift: "B2", acuity: 6 },
  { date: "7/19/2019 15:38:42", shift: "C1", acuity: 3 },
  { date: "7/19/2019 15:54:01", shift: "D1", acuity: 6 },
  { date: "7/19/2019 15:58:57", shift: "A2", acuity: 5 },
  { date: "7/19/2019 16:05:07", shift: "B2", acuity: 3 },
  { date: "7/19/2019 16:05:32", shift: "C1", acuity: 6 },
  { date: "7/19/2019 16:23:07", shift: "D1", acuity: 6 },
  { date: "7/19/2019 16:23:25", shift: "A2", acuity: 3 },
  { date: "7/19/2019 16:28:00", shift: "B2", acuity: 3 },
  { date: "7/19/2019 16:42:19", shift: "C2", acuity: 6 },
  { date: "7/19/2019 17:07:28", shift: "C2", acuity: 6 },
  { date: "7/19/2019 17:08:07", shift: "C2", acuity: 6 },
  { date: "7/19/2019 17:12:09", shift: "D1", acuity: 3 },
  { date: "7/19/2019 17:16:36", shift: "A2", acuity: 6 },
  { date: "7/19/2019 17:28:19", shift: "B2", acuity: 3 },
  { date: "7/19/2019 17:45:07", shift: "C2", acuity: 3 },
  { date: "7/19/2019 17:55:25", shift: "D1", acuity: 6 },
  { date: "7/19/2019 17:56:34", shift: "A2", acuity: 6 },
  { date: "7/19/2019 17:59:29", shift: "B2", acuity: 6 },
  { date: "7/19/2019 18:02:52", shift: "D2", acuity: 3 },
  { date: "7/19/2019 18:03:34", shift: "C2", acuity: 3 },
  { date: "7/19/2019 18:04:09", shift: "A2", acuity: 6 },
  { date: "7/19/2019 18:18:29", shift: "B2", acuity: 6 },
  { date: "7/19/2019 18:29:32", shift: "D2", acuity: 6 },
  { date: "7/19/2019 18:30:13", shift: "D2", acuity: 6 },
  { date: "7/19/2019 18:33:01", shift: "D2", acuity: 3 },
  { date: "7/19/2019 18:33:43", shift: "C2", acuity: 6 },
  { date: "7/19/2019 18:34:13", shift: "A2", acuity: 6 },
  { date: "7/19/2019 18:42:51", shift: "B2", acuity: 4 },
  { date: "7/19/2019 19:00:21", shift: "D2", acuity: 2 },
  { date: "7/19/2019 19:24:29", shift: "C2", acuity: 6 },
  { date: "7/19/2019 19:26:25", shift: "A2", acuity: 3 },
  { date: "7/19/2019 19:38:16", shift: "B2", acuity: 3 },
  { date: "7/19/2019 19:45:51", shift: "D2", acuity: 6 },
  { date: "7/19/2019 19:46:21", shift: "C2", acuity: 6 },
  { date: "7/19/2019 19:46:49", shift: "A2", acuity: 3 },
  { date: "7/19/2019 19:53:29", shift: "B2", acuity: 6 },
  { date: "7/19/2019 19:58:27", shift: "D2", acuity: 6 },
  { date: "7/19/2019 20:18:43", shift: "C2", acuity: 3 },
  { date: "7/19/2019 20:19:25", shift: "A2", acuity: 4 },
  { date: "7/19/2019 20:19:56", shift: "B2", acuity: 3 },
  { date: "7/19/2019 20:33:38", shift: "D2", acuity: 6 },
  { date: "7/19/2019 20:47:19", shift: "C2", acuity: 3 },
  { date: "7/19/2019 20:50:22", shift: "A2", acuity: 3 },
  { date: "7/19/2019 20:51:24", shift: "B2", acuity: 4 },
  { date: "7/19/2019 20:52:21", shift: "D2", acuity: 3 },
  { date: "7/19/2019 21:21:30", shift: "A3", acuity: 6 },
  { date: "7/19/2019 21:27:39", shift: "C2", acuity: 2 },
  { date: "7/19/2019 21:52:44", shift: "A3", acuity: 6 },
  { date: "7/19/2019 21:57:15", shift: "A3", acuity: 6 },
  { date: "7/19/2019 21:59:05", shift: "B2", acuity: 6 },
  { date: "7/19/2019 22:01:05", shift: "B3", acuity: 6 },
  { date: "7/19/2019 22:01:31", shift: "D2", acuity: 6 },
  { date: "7/19/2019 22:10:36", shift: "C2", acuity: 6 },
  { date: "7/19/2019 22:16:21", shift: "A3", acuity: 3 },
  { date: "7/19/2019 22:17:08", shift: "B3", acuity: 3 },
  { date: "7/19/2019 22:36:05", shift: "B3", acuity: 3 },
  { date: "7/19/2019 22:36:43", shift: "B3", acuity: 3 },
  { date: "7/19/2019 22:54:28", shift: "D2", acuity: 4 },
  { date: "7/19/2019 22:54:49", shift: "C2", acuity: 3 },
  { date: "7/19/2019 23:04:57", shift: "A3", acuity: 4 },
  { date: "7/19/2019 23:14:02", shift: "B3", acuity: 3 },
  { date: "7/19/2019 23:18:32", shift: "D2", acuity: 6 },
  { date: "7/19/2019 23:30:14", shift: "C2", acuity: 3 },
  { date: "7/19/2019 23:45:34", shift: "A3", acuity: 6 },
  { date: "7/19/2019 23:49:23", shift: "B3", acuity: 3 },
  { date: "7/19/2019 23:53:34", shift: "D2", acuity: 3 },
  { date: "7/19/2019 23:57:38", shift: "C2", acuity: 6 },
  { date: "7/20/2019 00:22:43", shift: "A3", acuity: 4 },
  { date: "7/20/2019 00:26:45", shift: "B3", acuity: 3 },
  { date: "7/20/2019 00:31:42", shift: "D2", acuity: 5 },
  { date: "7/20/2019 00:42:03", shift: "A3", acuity: 3 },
  { date: "7/20/2019 00:52:28", shift: "B3", acuity: 3 },
  { date: "7/20/2019 01:06:48", shift: "D2", acuity: 4 },
  { date: "7/20/2019 01:14:19", shift: "A3", acuity: 5 },
  { date: "7/20/2019 01:28:38", shift: "B3", acuity: 4 },
  { date: "7/20/2019 01:29:45", shift: "D2", acuity: 6 },
  { date: "7/20/2019 01:42:48", shift: "A3", acuity: 3 },
  { date: "7/20/2019 02:00:04", shift: "B3", acuity: 3 },
  { date: "7/20/2019 02:12:55", shift: "A3", acuity: 3 },
  { date: "7/20/2019 02:16:34", shift: "B3", acuity: 3 },
  { date: "7/20/2019 02:22:35", shift: "A3", acuity: 3 },
  { date: "7/20/2019 02:35:46", shift: "B3", acuity: 3 },
  { date: "7/20/2019 02:45:43", shift: "A3", acuity: 3 },
  { date: "7/20/2019 02:59:34", shift: "B3", acuity: 3 },
  { date: "7/20/2019 02:59:57", shift: "A3", acuity: 3 },
  { date: "7/20/2019 03:41:11", shift: "B3", acuity: 3 },
  { date: "7/20/2019 04:09:00", shift: "A3", acuity: 3 },
  { date: "7/20/2019 04:09:29", shift: "B3", acuity: 3 },
  { date: "7/20/2019 04:14:11", shift: "A3", acuity: 4 },
  { date: "7/20/2019 04:14:45", shift: "B3", acuity: 4 },
  { date: "7/20/2019 04:23:50", shift: "A3", acuity: 3 },
  { date: "7/20/2019 04:40:36", shift: "B3", acuity: 3 },
  { date: "7/20/2019 04:43:35", shift: "A3", acuity: 2 },
  { date: "7/20/2019 04:44:59", shift: "B3", acuity: 4 },
  { date: "7/20/2019 04:53:31", shift: "A3", acuity: 3 },
  { date: "7/20/2019 05:07:08", shift: "A1", acuity: 3 },
  { date: "7/20/2019 05:41:10", shift: "A1", acuity: 3 },
  { date: "7/20/2019 05:42:39", shift: "A1", acuity: 6 },
  { date: "7/20/2019 05:54:38", shift: "B3", acuity: 3 },
  { date: "7/20/2019 05:54:45", shift: "A1", acuity: 3 },
  { date: "7/20/2019 06:22:19", shift: "B1", acuity: 4 },
  { date: "7/20/2019 06:24:05", shift: "A1", acuity: 4 },
  { date: "7/20/2019 07:31:38", shift: "B1", acuity: 3 },
  { date: "7/20/2019 07:54:11", shift: "B1", acuity: 5 },
  { date: "7/20/2019 07:54:46", shift: "A1", acuity: 3 },
  { date: "7/20/2019 07:55:16", shift: "B1", acuity: 5 },
  { date: "7/20/2019 07:55:43", shift: "A1", acuity: 6 },
  { date: "7/20/2019 08:09:01", shift: "B1", acuity: 6 },
  { date: "7/20/2019 08:27:14", shift: "A1", acuity: 6 },
  { date: "7/20/2019 08:39:07", shift: "C1", acuity: 3 },
  { date: "7/20/2019 08:59:32", shift: "B1", acuity: 3 },
  { date: "7/20/2019 09:10:28", shift: "C1", acuity: 3 },
  { date: "7/20/2019 09:16:25", shift: "C1", acuity: 3 },
  { date: "7/20/2019 09:40:10", shift: "A1", acuity: 6 },
  { date: "7/20/2019 10:06:42", shift: "D1", acuity: 4 },
  { date: "7/20/2019 10:10:42", shift: "B1", acuity: 4 },
  { date: "7/20/2019 10:11:16", shift: "C1", acuity: 3 },
  { date: "7/20/2019 10:20:54", shift: "A1", acuity: 3 },
  { date: "7/20/2019 10:24:15", shift: "D1", acuity: 3 },
  { date: "7/20/2019 10:24:40", shift: "B1", acuity: 3 },
  { date: "7/20/2019 10:25:43", shift: "C1", acuity: 5 },
  { date: "7/20/2019 10:55:48", shift: "D1", acuity: 3 },
  { date: "7/20/2019 11:22:03", shift: "D1", acuity: 3 },
  { date: "7/20/2019 11:41:10", shift: "A1", acuity: 3 },
  { date: "7/20/2019 11:46:44", shift: "B1", acuity: 6 },
  { date: "7/20/2019 11:54:42", shift: "C1", acuity: 6 },
  { date: "7/20/2019 12:09:08", shift: "D1", acuity: 3 },
  { date: "7/20/2019 12:17:07", shift: "A1", acuity: 6 },
  { date: "7/20/2019 12:21:30", shift: "B1", acuity: 6 },
  { date: "7/20/2019 12:35:56", shift: "C1", acuity: 6 },
  { date: "7/20/2019 13:04:30", shift: "A2", acuity: 6 },
  { date: "7/20/2019 13:22:01", shift: "D1", acuity: 3 },
  { date: "7/20/2019 13:24:01", shift: "B1", acuity: 3 },
  { date: "7/20/2019 13:37:21", shift: "A2", acuity: 6 },
  { date: "7/20/2019 13:40:00", shift: "A2", acuity: 3 },
  { date: "7/20/2019 13:54:15", shift: "C1", acuity: 3 },
  { date: "7/20/2019 14:02:00", shift: "B2", acuity: 3 },
  { date: "7/20/2019 14:18:21", shift: "D1", acuity: 3 },
  { date: "7/20/2019 14:28:44", shift: "A2", acuity: 6 },
  { date: "7/20/2019 14:30:19", shift: "B2", acuity: 6 },
  { date: "7/20/2019 14:43:09", shift: "B2", acuity: 3 },
  { date: "7/20/2019 14:48:55", shift: "C1", acuity: 3 },
  { date: "7/20/2019 15:13:50", shift: "D1", acuity: 3 },
  { date: "7/20/2019 15:49:37", shift: "A2", acuity: 6 },
  { date: "7/20/2019 15:58:16", shift: "B2", acuity: 3 },
  { date: "7/20/2019 16:01:16", shift: "C1", acuity: 6 },
  { date: "7/20/2019 16:19:02", shift: "D1", acuity: 3 },
  { date: "7/20/2019 16:38:38", shift: "C2", acuity: 3 },
  { date: "7/20/2019 16:39:19", shift: "A2", acuity: 6 },
  { date: "7/20/2019 16:40:07", shift: "B2", acuity: 3 },
  { date: "7/20/2019 16:40:54", shift: "D1", acuity: 6 },
  { date: "7/20/2019 17:04:49", shift: "C2", acuity: 3 },
  { date: "7/20/2019 17:18:03", shift: "C2", acuity: 6 },
  { date: "7/20/2019 17:29:10", shift: "A2", acuity: 6 },
  { date: "7/20/2019 17:48:12", shift: "B2", acuity: 3 },
  { date: "7/20/2019 17:48:55", shift: "D1", acuity: 6 },
  { date: "7/20/2019 18:00:37", shift: "D2", acuity: 3 },
  { date: "7/20/2019 18:27:12", shift: "C2", acuity: 6 },
  { date: "7/20/2019 18:33:23", shift: "D2", acuity: 3 },
  { date: "7/20/2019 18:45:21", shift: "D2", acuity: 3 },
  { date: "7/20/2019 18:51:57", shift: "A2", acuity: 3 },
  { date: "7/20/2019 18:58:01", shift: "B2", acuity: 3 },
  { date: "7/20/2019 19:41:42", shift: "C2", acuity: 3 },
  { date: "7/20/2019 19:48:41", shift: "D2", acuity: 3 },
  { date: "7/20/2019 19:58:13", shift: "A2", acuity: 3 },
  { date: "7/20/2019 20:08:37", shift: "B2", acuity: 4 },
  { date: "7/20/2019 20:13:01", shift: "C2", acuity: 3 },
  { date: "7/20/2019 20:13:16", shift: "D2", acuity: 3 },
  { date: "7/20/2019 20:28:39", shift: "A2", acuity: 3 },
  { date: "7/20/2019 20:29:22", shift: "B2", acuity: 3 },
  { date: "7/20/2019 20:50:07", shift: "C2", acuity: 3 },
  { date: "7/20/2019 20:51:00", shift: "D2", acuity: 4 },
  { date: "7/20/2019 21:14:44", shift: "A3", acuity: 3 },
  { date: "7/20/2019 21:15:10", shift: "B2", acuity: 3 },
  { date: "7/20/2019 21:15:24", shift: "C2", acuity: 4 },
  { date: "7/20/2019 21:25:39", shift: "D2", acuity: 6 },
  { date: "7/20/2019 21:30:42", shift: "A3", acuity: 3 },
  { date: "7/20/2019 21:46:08", shift: "A3", acuity: 2 },
  { date: "7/20/2019 21:51:18", shift: "B2", acuity: 3 },
  { date: "7/20/2019 21:51:32", shift: "C2", acuity: 6 },
  { date: "7/20/2019 21:52:47", shift: "D2", acuity: 6 },
  { date: "7/20/2019 21:59:22", shift: "A3", acuity: 3 },
  { date: "7/20/2019 21:59:35", shift: "B2", acuity: 3 },
  { date: "7/20/2019 21:59:51", shift: "C2", acuity: 3 },
  { date: "7/20/2019 22:16:56", shift: "B3", acuity: 3 },
  { date: "7/20/2019 22:17:11", shift: "D2", acuity: 6 },
  { date: "7/20/2019 22:23:08", shift: "A3", acuity: 6 },
  { date: "7/20/2019 23:08:47", shift: "B3", acuity: 6 },
  { date: "7/20/2019 23:33:05", shift: "B3", acuity: 4 },
  { date: "7/20/2019 23:33:37", shift: "C2", acuity: 3 },
  { date: "7/20/2019 23:59:27", shift: "D2", acuity: 4 },
  { date: "7/21/2019 00:10:05", shift: "A3", acuity: 6 },
  { date: "7/21/2019 00:25:15", shift: "B3", acuity: 3 },
  { date: "7/21/2019 00:39:50", shift: "D2", acuity: 4 },
  { date: "7/21/2019 00:54:42", shift: "A3", acuity: 3 },
  { date: "7/21/2019 01:07:38", shift: "B3", acuity: 5 },
  { date: "7/21/2019 01:31:25", shift: "A3", acuity: 4 },
  { date: "7/21/2019 01:31:36", shift: "B3", acuity: 3 },
  { date: "7/21/2019 01:48:22", shift: "A3", acuity: 4 },
  { date: "7/21/2019 01:57:15", shift: "B3", acuity: 6 },
  { date: "7/21/2019 02:09:35", shift: "A3", acuity: 3 },
  { date: "7/21/2019 02:16:19", shift: "B3", acuity: 4 },
  { date: "7/21/2019 02:39:52", shift: "A3", acuity: 3 },
  { date: "7/21/2019 03:44:48", shift: "B3", acuity: 6 },
  { date: "7/21/2019 03:57:28", shift: "A3", acuity: 4 },
  { date: "7/21/2019 03:59:06", shift: "B3", acuity: 4 },
  { date: "7/21/2019 04:38:58", shift: "A3", acuity: 3 },
  { date: "7/21/2019 04:43:40", shift: "B3", acuity: 3 },
  { date: "7/21/2019 05:03:02", shift: "A1", acuity: 6 },
  { date: "7/21/2019 05:30:35", shift: "A1", acuity: 3 },
  { date: "7/21/2019 06:03:43", shift: "A1", acuity: 3 },
  { date: "7/21/2019 06:25:56", shift: "B1", acuity: 3 },
  { date: "7/21/2019 07:13:15", shift: "B1", acuity: 6 },
  { date: "7/21/2019 07:38:56", shift: "B1", acuity: 4 },
  { date: "7/21/2019 07:50:11", shift: "B1", acuity: 3 },
  { date: "7/21/2019 07:57:13", shift: "A1", acuity: 3 },
  { date: "7/21/2019 07:57:32", shift: "B1", acuity: 3 },
  { date: "7/21/2019 08:19:16", shift: "A1", acuity: 4 },
  { date: "7/21/2019 08:48:10", shift: "C1", acuity: 4 },
  { date: "7/21/2019 08:48:38", shift: "B1", acuity: 6 },
  { date: "7/21/2019 08:56:29", shift: "A1", acuity: 3 },
  { date: "7/21/2019 09:12:28", shift: "C1", acuity: 6 },
  { date: "7/21/2019 09:20:02", shift: "C1", acuity: 3 },
  { date: "7/21/2019 09:20:22", shift: "B1", acuity: 3 },
  { date: "7/21/2019 09:21:26", shift: "A1", acuity: 3 },
  { date: "7/21/2019 09:35:44", shift: "C1", acuity: 6 },
  { date: "7/21/2019 09:38:17", shift: "B1", acuity: 4 },
  { date: "7/21/2019 10:00:33", shift: "D1", acuity: 4 },
  { date: "7/21/2019 10:00:54", shift: "A1", acuity: 3 },
  { date: "7/21/2019 10:17:09", shift: "C1", acuity: 3 },
  { date: "7/21/2019 10:33:42", shift: "D1", acuity: 3 },
  { date: "7/21/2019 10:34:08", shift: "D1", acuity: 3 },
  { date: "7/21/2019 10:35:32", shift: "B1", acuity: 3 },
  { date: "7/21/2019 10:36:13", shift: "A1", acuity: 4 },
  { date: "7/21/2019 10:36:54", shift: "C1", acuity: 3 },
  { date: "7/21/2019 10:51:33", shift: "D1", acuity: 4 },
  { date: "7/21/2019 11:19:03", shift: "B1", acuity: 3 },
  { date: "7/21/2019 11:20:14", shift: "A1", acuity: 4 },
  { date: "7/21/2019 11:32:22", shift: "C1", acuity: 3 },
  { date: "7/21/2019 11:45:12", shift: "D1", acuity: 3 },
  { date: "7/21/2019 11:48:12", shift: "B1", acuity: 3 },
  { date: "7/21/2019 11:49:27", shift: "A1", acuity: 3 },
  { date: "7/21/2019 12:24:58", shift: "C1", acuity: 6 },
  { date: "7/21/2019 12:25:09", shift: "D1", acuity: 3 },
  { date: "7/21/2019 12:31:34", shift: "B1", acuity: 3 },
  { date: "7/21/2019 12:32:25", shift: "A1", acuity: 3 },
  { date: "7/21/2019 12:52:54", shift: "C1", acuity: 4 },
  { date: "7/21/2019 12:56:24", shift: "D1", acuity: 3 },
  { date: "7/21/2019 12:59:36", shift: "B1", acuity: 3 },
  { date: "7/21/2019 13:00:45", shift: "A2", acuity: 3 },
  { date: "7/21/2019 13:01:56", shift: "C1", acuity: 3 },
  { date: "7/21/2019 13:20:21", shift: "D1", acuity: 2 },
  { date: "7/21/2019 13:34:19", shift: "A2", acuity: 6 },
  { date: "7/21/2019 13:56:36", shift: "A2", acuity: 3 },
  { date: "7/21/2019 14:25:04", shift: "B2", acuity: 3 },
  { date: "7/21/2019 14:26:25", shift: "C1", acuity: 6 },
  { date: "7/21/2019 14:31:14", shift: "B2", acuity: 3 },
  { date: "7/21/2019 14:49:23", shift: "B2", acuity: 3 },
  { date: "7/21/2019 15:08:14", shift: "D1", acuity: 4 },
  { date: "7/21/2019 15:26:06", shift: "A2", acuity: 3 },
  { date: "7/21/2019 15:26:42", shift: "C1", acuity: 3 },
  { date: "7/21/2019 15:27:04", shift: "B2", acuity: 3 },
  { date: "7/21/2019 15:36:11", shift: "D1", acuity: 6 },
  { date: "7/21/2019 16:07:25", shift: "A2", acuity: 3 },
  { date: "7/21/2019 16:13:27", shift: "C1", acuity: 6 },
  { date: "7/21/2019 16:13:55", shift: "B2", acuity: 6 },
  { date: "7/21/2019 16:26:43", shift: "D1", acuity: 6 },
  { date: "7/21/2019 16:28:40", shift: "A2", acuity: 3 },
  { date: "7/21/2019 16:30:26", shift: "C2", acuity: 2 },
  { date: "7/21/2019 16:30:58", shift: "B2", acuity: 3 },
  { date: "7/21/2019 16:31:28", shift: "D1", acuity: 6 },
  { date: "7/21/2019 16:36:57", shift: "A2", acuity: 6 },
  { date: "7/21/2019 16:37:24", shift: "C2", acuity: 3 },
  { date: "7/21/2019 16:56:04", shift: "B2", acuity: 4 },
  { date: "7/21/2019 17:00:32", shift: "C2", acuity: 6 },
  { date: "7/21/2019 17:27:53", shift: "C2", acuity: 3 },
  { date: "7/21/2019 17:28:14", shift: "D1", acuity: 6 },
  { date: "7/21/2019 17:46:51", shift: "A2", acuity: 6 },
  { date: "7/21/2019 18:15:09", shift: "D2", acuity: 6 },
  { date: "7/21/2019 18:17:07", shift: "B2", acuity: 6 },
  { date: "7/21/2019 18:17:36", shift: "C2", acuity: 3 },
  { date: "7/21/2019 18:46:40", shift: "D2", acuity: 4 },
  { date: "7/21/2019 18:59:08", shift: "D2", acuity: 6 },
  { date: "7/21/2019 19:00:42", shift: "A2", acuity: 3 },
  { date: "7/21/2019 19:37:15", shift: "B2", acuity: 3 },
  { date: "7/21/2019 19:37:31", shift: "C2", acuity: 6 },
  { date: "7/21/2019 20:12:30", shift: "D2", acuity: 3 },
  { date: "7/21/2019 20:22:09", shift: "A2", acuity: 3 },
  { date: "7/21/2019 20:22:30", shift: "B2", acuity: 3 },
  { date: "7/21/2019 20:27:32", shift: "C2", acuity: 3 },
  { date: "7/21/2019 20:34:47", shift: "D2", acuity: 6 },
  { date: "7/21/2019 20:35:45", shift: "A2", acuity: 6 },
  { date: "7/21/2019 20:40:45", shift: "B2", acuity: 6 },
  { date: "7/21/2019 20:51:02", shift: "C2", acuity: 6 },
  { date: "7/21/2019 20:56:08", shift: "D2", acuity: 3 },
  { date: "7/21/2019 21:04:23", shift: "A3", acuity: 2 },
  { date: "7/21/2019 21:06:24", shift: "B2", acuity: 6 },
  { date: "7/21/2019 21:13:37", shift: "C2", acuity: 3 },
  { date: "7/21/2019 21:27:38", shift: "D2", acuity: 3 },
  { date: "7/21/2019 21:31:12", shift: "A3", acuity: 3 },
  { date: "7/21/2019 21:32:32", shift: "A3", acuity: 3 },
  { date: "7/21/2019 21:42:19", shift: "B2", acuity: 3 },
  { date: "7/21/2019 21:44:08", shift: "C2", acuity: 4 },
  { date: "7/21/2019 21:48:48", shift: "D2", acuity: 6 },
  { date: "7/21/2019 21:52:44", shift: "A3", acuity: 3 },
  { date: "7/21/2019 22:06:03", shift: "B3", acuity: 3 },
  { date: "7/21/2019 22:09:56", shift: "C2", acuity: 4 },
  { date: "7/21/2019 22:10:06", shift: "D2", acuity: 3 },
  { date: "7/21/2019 22:32:04", shift: "B3", acuity: 4 },
  { date: "7/21/2019 22:32:19", shift: "B3", acuity: 4 },
  { date: "7/21/2019 23:11:51", shift: "A3", acuity: 3 },
  { date: "7/21/2019 23:12:09", shift: "C2", acuity: 6 },
  { date: "7/21/2019 23:12:47", shift: "D2", acuity: 3 },
  { date: "7/21/2019 23:14:23", shift: "B3", acuity: 4 },
  { date: "7/21/2019 23:20:51", shift: "A3", acuity: 3 },
  { date: "7/21/2019 23:26:53", shift: "C2", acuity: 4 },
  { date: "7/21/2019 23:41:29", shift: "D2", acuity: 4 },
  { date: "7/21/2019 23:42:44", shift: "B3", acuity: 4 },
  { date: "7/21/2019 23:50:52", shift: "A3", acuity: 3 },
  { date: "7/22/2019 00:00:09", shift: "D2", acuity: 6 },
  { date: "7/22/2019 00:00:28", shift: "B3", acuity: 4 },
  { date: "7/22/2019 00:08:58", shift: "A3", acuity: 4 },
  { date: "7/22/2019 00:11:43", shift: "D2", acuity: 3 },
  { date: "7/22/2019 00:17:39", shift: "B3", acuity: 4 },
  { date: "7/22/2019 00:27:34", shift: "A3", acuity: 3 },
  { date: "7/22/2019 00:27:47", shift: "D2", acuity: 5 },
  { date: "7/22/2019 00:27:57", shift: "B3", acuity: 3 },
  { date: "7/22/2019 00:28:26", shift: "A3", acuity: 6 },
  { date: "7/22/2019 00:49:55", shift: "D2", acuity: 3 },
  { date: "7/22/2019 01:16:02", shift: "B3", acuity: 3 },
  { date: "7/22/2019 01:24:10", shift: "A3", acuity: 3 },
  { date: "7/22/2019 01:49:07", shift: "B3", acuity: 3 },
  { date: "7/22/2019 01:49:18", shift: "A3", acuity: 3 },
  { date: "7/22/2019 01:53:15", shift: "B3", acuity: 3 },
  { date: "7/22/2019 02:08:37", shift: "A3", acuity: 5 },
  { date: "7/22/2019 02:08:48", shift: "B3", acuity: 3 },
  { date: "7/22/2019 02:17:07", shift: "A3", acuity: 3 },
  { date: "7/22/2019 02:44:56", shift: "B3", acuity: 4 },
  { date: "7/22/2019 02:55:17", shift: "A3", acuity: 3 },
  { date: "7/22/2019 03:07:12", shift: "B3", acuity: 6 },
  { date: "7/22/2019 03:22:46", shift: "A3", acuity: 5 },
  { date: "7/22/2019 03:28:34", shift: "B3", acuity: 4 },
  { date: "7/22/2019 03:38:02", shift: "A3", acuity: 6 },
  { date: "7/22/2019 03:55:26", shift: "B3", acuity: 3 },
  { date: "7/22/2019 03:56:11", shift: "A3", acuity: 4 },
  { date: "7/22/2019 04:05:30", shift: "B3", acuity: 4 },
  { date: "7/22/2019 04:32:36", shift: "A3", acuity: 4 },
  { date: "7/22/2019 04:34:53", shift: "B3", acuity: 4 },
  { date: "7/22/2019 05:28:23", shift: "A1", acuity: 5 },
  { date: "7/22/2019 06:09:42", shift: "A1", acuity: 3 },
  { date: "7/22/2019 06:12:48", shift: "A1", acuity: 3 },
  { date: "7/22/2019 06:27:44", shift: "B1", acuity: 6 },
  { date: "7/22/2019 07:01:44", shift: "B1", acuity: 6 },
  { date: "7/22/2019 07:19:28", shift: "B1", acuity: 6 },
  { date: "7/22/2019 07:33:44", shift: "A1", acuity: 4 },
  { date: "7/22/2019 07:45:08", shift: "B1", acuity: 3 },
  { date: "7/22/2019 08:11:18", shift: "A1", acuity: 4 },
  { date: "7/22/2019 08:17:33", shift: "B1", acuity: 3 },
  { date: "7/22/2019 08:18:48", shift: "A1", acuity: 4 },
  { date: "7/22/2019 08:45:54", shift: "C1", acuity: 5 },
  { date: "7/22/2019 08:50:16", shift: "B1", acuity: 4 },
  { date: "7/22/2019 08:58:51", shift: "A1", acuity: 3 },
  { date: "7/22/2019 09:04:00", shift: "C1", acuity: 3 },
  { date: "7/22/2019 09:14:16", shift: "C1", acuity: 6 },
  { date: "7/22/2019 09:17:27", shift: "B1", acuity: 6 },
  { date: "7/22/2019 09:17:35", shift: "A1", acuity: 5 },
  { date: "7/22/2019 09:28:06", shift: "C1", acuity: 6 },
  { date: "7/22/2019 09:33:23", shift: "B1", acuity: 6 },
  { date: "7/22/2019 09:38:42", shift: "A1", acuity: 3 },
  { date: "7/22/2019 09:42:10", shift: "C1", acuity: 6 },
  { date: "7/22/2019 09:59:21", shift: "B1", acuity: 3 },
  { date: "7/22/2019 10:13:43", shift: "D1", acuity: 3 },
  { date: "7/22/2019 10:14:24", shift: "A1", acuity: 6 },
  { date: "7/22/2019 10:14:38", shift: "C1", acuity: 3 },
  { date: "7/22/2019 10:36:28", shift: "D1", acuity: 6 },
  { date: "7/22/2019 10:52:06", shift: "D1", acuity: 3 },
  { date: "7/22/2019 10:52:18", shift: "B1", acuity: 6 },
  { date: "7/22/2019 11:20:22", shift: "A1", acuity: 6 },
  { date: "7/22/2019 11:20:35", shift: "C1", acuity: 6 },
  { date: "7/22/2019 11:20:49", shift: "D1", acuity: 6 },
  { date: "7/22/2019 11:36:05", shift: "B1", acuity: 6 },
  { date: "7/22/2019 11:44:06", shift: "A1", acuity: 3 },
  { date: "7/22/2019 11:44:21", shift: "C1", acuity: 6 },
  { date: "7/22/2019 11:53:20", shift: "D1", acuity: 6 },
  { date: "7/22/2019 11:58:28", shift: "B1", acuity: 3 },
  { date: "7/22/2019 12:04:04", shift: "A1", acuity: 3 },
  { date: "7/22/2019 12:04:20", shift: "C1", acuity: 6 },
  { date: "7/22/2019 13:10:45", shift: "A2", acuity: 3 },
  { date: "7/22/2019 13:11:38", shift: "D1", acuity: 3 },
  { date: "7/22/2019 13:15:37", shift: "B1", acuity: 6 },
  { date: "7/22/2019 13:27:05", shift: "C1", acuity: 3 },
  { date: "7/22/2019 13:32:12", shift: "A2", acuity: 3 },
  { date: "7/22/2019 13:42:59", shift: "A2", acuity: 4 },
  { date: "7/22/2019 13:48:09", shift: "D1", acuity: 3 },
  { date: "7/22/2019 13:48:42", shift: "B1", acuity: 3 },
  { date: "7/22/2019 13:48:51", shift: "C1", acuity: 3 },
  { date: "7/22/2019 14:01:17", shift: "B2", acuity: 6 },
  { date: "7/22/2019 14:01:46", shift: "A2", acuity: 6 },
  { date: "7/22/2019 14:02:03", shift: "D1", acuity: 6 },
  { date: "7/22/2019 14:07:53", shift: "C1", acuity: 3 },
  { date: "7/22/2019 14:36:19", shift: "B2", acuity: 3 },
  { date: "7/22/2019 14:36:29", shift: "B2", acuity: 3 },
  { date: "7/22/2019 14:40:07", shift: "A2", acuity: 3 },
  { date: "7/22/2019 14:40:18", shift: "D1", acuity: 4 },
  { date: "7/22/2019 14:46:19", shift: "C1", acuity: 2 },
  { date: "7/22/2019 14:53:42", shift: "B2", acuity: 3 },
  { date: "7/22/2019 14:53:50", shift: "A2", acuity: 3 },
  { date: "7/22/2019 15:02:38", shift: "D1", acuity: 6 },
  { date: "7/22/2019 15:26:23", shift: "C1", acuity: 4 },
  { date: "7/22/2019 15:26:54", shift: "B2", acuity: 3 },
  { date: "7/22/2019 15:40:37", shift: "A2", acuity: 6 },
  { date: "7/22/2019 15:41:29", shift: "D1", acuity: 6 },
  { date: "7/22/2019 15:46:05", shift: "C1", acuity: 3 },
  { date: "7/22/2019 15:46:19", shift: "B2", acuity: 3 },
  { date: "7/22/2019 16:05:05", shift: "A2", acuity: 6 },
  { date: "7/22/2019 16:07:12", shift: "D1", acuity: 3 },
  { date: "7/22/2019 16:16:50", shift: "C1", acuity: 3 },
  { date: "7/22/2019 16:17:03", shift: "B2", acuity: 6 },
  { date: "7/22/2019 16:31:58", shift: "C2", acuity: 3 },
  { date: "7/22/2019 16:36:28", shift: "A2", acuity: 3 },
  { date: "7/22/2019 16:49:09", shift: "D1", acuity: 6 },
  { date: "7/22/2019 16:49:26", shift: "B2", acuity: 6 },
  { date: "7/22/2019 16:49:40", shift: "C2", acuity: 6 },
  { date: "7/22/2019 17:00:53", shift: "C2", acuity: 6 },
  { date: "7/22/2019 17:01:06", shift: "C2", acuity: 3 },
  { date: "7/22/2019 17:12:10", shift: "A2", acuity: 6 },
  { date: "7/22/2019 17:18:04", shift: "D1", acuity: 3 },
  { date: "7/22/2019 17:41:39", shift: "B2", acuity: 3 },
  { date: "7/22/2019 17:43:16", shift: "C2", acuity: 6 },
  { date: "7/22/2019 17:52:18", shift: "A2", acuity: 6 },
  { date: "7/22/2019 18:02:11", shift: "D2", acuity: 6 },
  { date: "7/22/2019 18:14:37", shift: "B2", acuity: 3 },
  { date: "7/22/2019 18:15:02", shift: "C2", acuity: 3 },
  { date: "7/22/2019 18:15:23", shift: "A2", acuity: 3 },
  { date: "7/22/2019 18:31:11", shift: "D2", acuity: 6 },
  { date: "7/22/2019 18:55:30", shift: "D2", acuity: 6 },
  { date: "7/22/2019 18:55:41", shift: "B2", acuity: 6 },
  { date: "7/22/2019 18:55:52", shift: "C2", acuity: 6 },
  { date: "7/22/2019 18:56:09", shift: "A2", acuity: 6 },
  { date: "7/22/2019 19:25:07", shift: "D2", acuity: 6 },
  { date: "7/22/2019 19:36:08", shift: "B2", acuity: 3 },
  { date: "7/22/2019 19:42:29", shift: "C2", acuity: 3 },
  { date: "7/22/2019 20:07:21", shift: "A2", acuity: 3 },
  { date: "7/22/2019 20:11:49", shift: "D2", acuity: 6 },
  { date: "7/22/2019 20:12:07", shift: "B2", acuity: 3 },
  { date: "7/22/2019 20:22:01", shift: "C2", acuity: 6 },
  { date: "7/22/2019 20:32:32", shift: "A2", acuity: 7 },
  { date: "7/22/2019 20:34:22", shift: "D2", acuity: 3 },
  { date: "7/22/2019 20:56:15", shift: "B2", acuity: 3 },
  { date: "7/22/2019 20:57:06", shift: "C2", acuity: 7 },
  { date: "7/22/2019 21:00:51", shift: "A3", acuity: 6 },
  { date: "7/22/2019 21:09:30", shift: "D2", acuity: 3 },
  { date: "7/22/2019 21:10:23", shift: "B2", acuity: 6 },
  { date: "7/22/2019 21:10:48", shift: "C2", acuity: 6 },
  { date: "7/22/2019 21:11:27", shift: "A3", acuity: 6 },
  { date: "7/22/2019 21:30:18", shift: "A3", acuity: 6 },
  { date: "7/22/2019 21:40:37", shift: "A3", acuity: 6 },
  { date: "7/22/2019 22:00:40", shift: "B3", acuity: 6 },
  { date: "7/22/2019 22:03:12", shift: "D2", acuity: 3 },
  { date: "7/22/2019 22:10:07", shift: "C2", acuity: 6 },
  { date: "7/22/2019 22:10:40", shift: "A3", acuity: 3 },
  { date: "7/22/2019 22:25:22", shift: "B3", acuity: 3 },
  { date: "7/22/2019 22:25:49", shift: "D2", acuity: 3 },
  { date: "7/22/2019 22:37:16", shift: "B3", acuity: 3 },
  { date: "7/22/2019 22:41:03", shift: "B3", acuity: 7 },
  { date: "7/22/2019 23:05:36", shift: "C2", acuity: 3 },
  { date: "7/22/2019 23:05:51", shift: "A3", acuity: 6 },
  { date: "7/22/2019 23:09:02", shift: "D2", acuity: 7 },
  { date: "7/22/2019 23:21:45", shift: "B3", acuity: 3 },
  { date: "7/22/2019 23:21:58", shift: "C2", acuity: 3 },
  { date: "7/22/2019 23:34:30", shift: "A3", acuity: 6 },
  { date: "7/22/2019 23:41:41", shift: "D2", acuity: 3 },
  { date: "7/22/2019 23:41:57", shift: "B3", acuity: 3 },
  { date: "7/22/2019 23:44:11", shift: "C2", acuity: 4 },
  { date: "7/23/2019 00:00:26", shift: "A3", acuity: 6 },
  { date: "7/23/2019 00:28:13", shift: "D2", acuity: 5 },
  { date: "7/23/2019 00:28:30", shift: "B3", acuity: 3 },
  { date: "7/23/2019 00:28:50", shift: "A3", acuity: 4 },
  { date: "7/23/2019 00:40:58", shift: "D2", acuity: 6 },
  { date: "7/23/2019 00:56:45", shift: "B3", acuity: 3 },
  { date: "7/23/2019 00:57:23", shift: "A3", acuity: 6 },
  { date: "7/23/2019 00:57:42", shift: "D2", acuity: 4 },
  { date: "7/23/2019 00:57:59", shift: "B3", acuity: 3 },
  { date: "7/23/2019 01:08:29", shift: "A3", acuity: 4 },
  { date: "7/23/2019 01:13:20", shift: "D2", acuity: 4 },
  { date: "7/23/2019 01:18:13", shift: "B3", acuity: 3 },
  { date: "7/23/2019 01:36:10", shift: "A3", acuity: 3 },
  { date: "7/23/2019 01:36:27", shift: "B3", acuity: 3 },
  { date: "7/23/2019 02:02:21", shift: "A3", acuity: 6 },
  { date: "7/23/2019 02:02:38", shift: "B3", acuity: 3 },
  { date: "7/23/2019 02:02:47", shift: "A3", acuity: 3 },
  { date: "7/23/2019 02:03:02", shift: "B3", acuity: 6 },
  { date: "7/23/2019 02:39:12", shift: "A3", acuity: 6 },
  { date: "7/23/2019 02:56:21", shift: "B3", acuity: 3 },
  { date: "7/23/2019 03:10:14", shift: "A3", acuity: 3 },
  { date: "7/23/2019 03:39:10", shift: "B3", acuity: 3 },
  { date: "7/23/2019 03:57:10", shift: "A3", acuity: 3 },
  { date: "7/23/2019 04:24:19", shift: "B3", acuity: 3 },
  { date: "7/23/2019 04:24:49", shift: "A3", acuity: 6 },
  { date: "7/23/2019 04:26:58", shift: "B3", acuity: 6 },
  { date: "7/23/2019 04:45:04", shift: "A3", acuity: 3 },
  { date: "7/23/2019 04:45:22", shift: "B3", acuity: 6 },
  { date: "7/23/2019 05:41:07", shift: "A1", acuity: 4 },
  { date: "7/23/2019 05:49:18", shift: "A1", acuity: 6 },
  { date: "7/23/2019 06:00:55", shift: "A1", acuity: 3 },
  { date: "7/23/2019 06:29:39", shift: "B1", acuity: 4 },
  { date: "7/23/2019 06:46:20", shift: "B1", acuity: 3 },
  { date: "7/23/2019 06:46:52", shift: "B1", acuity: 4 },
  { date: "7/23/2019 06:47:08", shift: "A1", acuity: 3 },
  { date: "7/23/2019 07:02:23", shift: "B1", acuity: 3 },
  { date: "7/23/2019 08:13:40", shift: "A1", acuity: 3 },
  { date: "7/23/2019 08:27:19", shift: "B1", acuity: 3 },
  { date: "7/23/2019 08:32:59", shift: "C1", acuity: 3 },
  { date: "7/23/2019 08:33:08", shift: "A1", acuity: 6 },
  { date: "7/23/2019 08:44:32", shift: "B1", acuity: 4 },
  { date: "7/23/2019 09:10:48", shift: "C1", acuity: 6 },
  { date: "7/23/2019 09:10:55", shift: "C1", acuity: 4 },
  { date: "7/23/2019 09:41:25", shift: "A1", acuity: 6 },
  { date: "7/23/2019 10:01:04", shift: "D1", acuity: 4 },
  { date: "7/23/2019 10:02:29", shift: "B1", acuity: 6 },
  { date: "7/23/2019 10:03:42", shift: "C1", acuity: 4 },
  { date: "7/23/2019 10:08:27", shift: "A1", acuity: 3 },
  { date: "7/23/2019 10:08:48", shift: "D1", acuity: 4 },
  { date: "7/23/2019 10:21:19", shift: "B1", acuity: 4 },
  { date: "7/23/2019 10:36:53", shift: "D1", acuity: 3 },
  { date: "7/23/2019 10:37:37", shift: "D1", acuity: 6 },
  { date: "7/23/2019 10:37:54", shift: "C1", acuity: 3 },
  { date: "7/23/2019 10:38:12", shift: "A1", acuity: 4 },
  { date: "7/23/2019 10:57:20", shift: "B1", acuity: 3 },
  { date: "7/23/2019 10:59:31", shift: "D1", acuity: 3 },
  { date: "7/23/2019 11:10:07", shift: "C1", acuity: 3 },
  { date: "7/23/2019 11:21:23", shift: "A1", acuity: 6 },
  { date: "7/23/2019 11:28:19", shift: "B1", acuity: 6 },
  { date: "7/23/2019 11:30:30", shift: "D1", acuity: 3 },
  { date: "7/23/2019 11:36:07", shift: "C1", acuity: 3 },
  { date: "7/23/2019 11:44:49", shift: "A1", acuity: 6 },
  { date: "7/23/2019 11:52:09", shift: "B1", acuity: 3 },
  { date: "7/23/2019 11:57:44", shift: "D1", acuity: 3 },
  { date: "7/23/2019 12:09:03", shift: "C1", acuity: 6 },
  { date: "7/23/2019 12:12:03", shift: "A1", acuity: 6 },
  { date: "7/23/2019 12:24:32", shift: "B1", acuity: 6 },
  { date: "7/23/2019 12:24:52", shift: "D1", acuity: 6 },
  { date: "7/23/2019 12:41:50", shift: "C1", acuity: 6 },
  { date: "7/23/2019 12:51:23", shift: "A1", acuity: 6 },
  { date: "7/23/2019 12:59:30", shift: "B1", acuity: 3 },
  { date: "7/23/2019 12:59:59", shift: "D1", acuity: 3 },
  { date: "7/23/2019 13:10:25", shift: "A2", acuity: 6 },
  { date: "7/23/2019 13:34:08", shift: "A2", acuity: 4 },
  { date: "7/23/2019 13:50:30", shift: "A2", acuity: 6 },
  { date: "7/23/2019 14:00:19", shift: "B2", acuity: 6 },
  { date: "7/23/2019 14:05:06", shift: "C1", acuity: 6 },
  { date: "7/23/2019 14:05:33", shift: "D1", acuity: 6 },
  { date: "7/23/2019 14:27:43", shift: "A2", acuity: 6 },
  { date: "7/23/2019 14:38:35", shift: "B2", acuity: 6 },
  { date: "7/23/2019 14:52:39", shift: "B2", acuity: 4 },
  { date: "7/23/2019 14:52:47", shift: "C1", acuity: 3 },
  { date: "7/23/2019 14:56:55", shift: "D1", acuity: 3 },
  { date: "7/23/2019 15:06:19", shift: "A2", acuity: 6 },
  { date: "7/23/2019 15:06:41", shift: "B2", acuity: 6 },
  { date: "7/23/2019 15:18:11", shift: "C1", acuity: 6 },
  { date: "7/23/2019 15:23:11", shift: "D1", acuity: 6 },
  { date: "7/23/2019 15:27:01", shift: "A2", acuity: 6 },
  { date: "7/23/2019 15:34:47", shift: "B2", acuity: 3 },
  { date: "7/23/2019 15:45:56", shift: "C1", acuity: 6 },
  { date: "7/23/2019 15:53:29", shift: "D1", acuity: 6 },
  { date: "7/23/2019 15:54:17", shift: "A2", acuity: 3 },
  { date: "7/23/2019 16:45:29", shift: "C2", acuity: 6 },
  { date: "7/23/2019 16:53:24", shift: "B2", acuity: 6 },
  { date: "7/23/2019 17:10:13", shift: "C2", acuity: 3 },
  { date: "7/23/2019 17:12:55", shift: "C2", acuity: 3 },
  { date: "7/23/2019 17:18:23", shift: "D1", acuity: 6 },
  { date: "7/23/2019 17:26:50", shift: "A2", acuity: 3 },
  { date: "7/23/2019 17:27:12", shift: "B2", acuity: 3 },
  { date: "7/23/2019 17:28:34", shift: "C2", acuity: 3 },
  { date: "7/23/2019 17:38:51", shift: "D1", acuity: 6 },
  { date: "7/23/2019 17:43:43", shift: "A2", acuity: 6 },
  { date: "7/23/2019 17:55:02", shift: "B2", acuity: 4 },
  { date: "7/23/2019 17:58:33", shift: "C2", acuity: 3 },
  { date: "7/23/2019 18:04:29", shift: "D2", acuity: 3 },
  { date: "7/23/2019 18:28:28", shift: "A2", acuity: 6 },
  { date: "7/23/2019 18:54:21", shift: "D2", acuity: 3 },
  { date: "7/23/2019 19:00:13", shift: "D2", acuity: 6 },
  { date: "7/23/2019 19:04:52", shift: "B2", acuity: 3 },
  { date: "7/23/2019 19:26:38", shift: "C2", acuity: 6 },
  { date: "7/23/2019 19:35:07", shift: "A2", acuity: 6 },
  { date: "7/23/2019 19:38:48", shift: "D2", acuity: 3 },
  { date: "7/23/2019 19:45:08", shift: "B2", acuity: 3 },
  { date: "7/23/2019 19:52:40", shift: "C2", acuity: 6 },
  { date: "7/23/2019 19:59:18", shift: "A2", acuity: 3 },
  { date: "7/23/2019 20:06:55", shift: "D2", acuity: 6 },
  { date: "7/23/2019 20:07:27", shift: "B2", acuity: 3 },
  { date: "7/23/2019 20:16:06", shift: "C2", acuity: 3 },
  { date: "7/23/2019 20:17:38", shift: "A2", acuity: 3 },
  { date: "7/23/2019 20:27:20", shift: "D2", acuity: 6 },
  { date: "7/23/2019 20:27:33", shift: "B2", acuity: 3 },
  { date: "7/23/2019 20:39:40", shift: "C2", acuity: 4 },
  { date: "7/23/2019 20:46:15", shift: "A2", acuity: 3 },
  { date: "7/23/2019 20:47:11", shift: "D2", acuity: 3 },
  { date: "7/23/2019 20:48:29", shift: "B2", acuity: 6 },
  { date: "7/23/2019 21:21:07", shift: "A3", acuity: 3 },
  { date: "7/23/2019 21:21:32", shift: "C2", acuity: 3 },
  { date: "7/23/2019 21:21:54", shift: "D2", acuity: 6 },
  { date: "7/23/2019 21:35:32", shift: "A3", acuity: 3 },
  { date: "7/23/2019 21:48:54", shift: "A3", acuity: 6 },
  { date: "7/23/2019 21:52:38", shift: "B2", acuity: 3 },
  { date: "7/23/2019 22:11:25", shift: "B3", acuity: 6 },
  { date: "7/23/2019 22:11:48", shift: "C2", acuity: 4 },
  { date: "7/23/2019 22:15:23", shift: "D2", acuity: 4 },
  { date: "7/23/2019 22:30:45", shift: "B3", acuity: 3 },
  { date: "7/23/2019 22:31:11", shift: "B3", acuity: 6 },
  { date: "7/23/2019 22:36:54", shift: "A3", acuity: 4 },
  { date: "7/23/2019 22:44:08", shift: "C2", acuity: 3 },
  { date: "7/23/2019 22:47:17", shift: "D2", acuity: 6 },
  { date: "7/23/2019 23:12:28", shift: "B3", acuity: 4 },
  { date: "7/23/2019 23:13:01", shift: "A3", acuity: 4 },
  { date: "7/23/2019 23:33:45", shift: "C2", acuity: 6 },
  { date: "7/23/2019 23:34:31", shift: "D2", acuity: 4 },
  { date: "7/23/2019 23:39:18", shift: "B3", acuity: 2 },
  { date: "7/24/2019 00:02:20", shift: "A3", acuity: 3 },
  { date: "7/24/2019 00:02:30", shift: "D2", acuity: 4 },
  { date: "7/24/2019 00:34:32", shift: "B3", acuity: 6 },
  { date: "7/24/2019 01:12:32", shift: "A3", acuity: 3 },
  { date: "7/24/2019 01:21:09", shift: "D2", acuity: 6 },
  { date: "7/24/2019 01:22:12", shift: "B3", acuity: 6 },
  { date: "7/24/2019 01:39:56", shift: "A3", acuity: 3 },
  { date: "7/24/2019 01:47:46", shift: "B3", acuity: 6 },
  { date: "7/24/2019 01:50:46", shift: "A3", acuity: 6 },
  { date: "7/24/2019 02:16:09", shift: "B3", acuity: 3 },
  { date: "7/24/2019 02:34:07", shift: "A3", acuity: 3 },
  { date: "7/24/2019 02:34:18", shift: "B3", acuity: 3 },
  { date: "7/24/2019 02:59:59", shift: "A3", acuity: 4 },
  { date: "7/24/2019 03:00:26", shift: "B3", acuity: 3 },
  { date: "7/24/2019 03:10:30", shift: "A3", acuity: 3 },
  { date: "7/24/2019 03:11:13", shift: "B3", acuity: 6 },
  { date: "7/24/2019 03:19:45", shift: "A3", acuity: 3 },
  { date: "7/24/2019 03:28:08", shift: "B3", acuity: 3 },
  { date: "7/24/2019 03:28:16", shift: "A3", acuity: 3 },
  { date: "7/24/2019 03:28:37", shift: "B3", acuity: 3 },
  { date: "7/24/2019 04:12:13", shift: "A3", acuity: 3 },
  { date: "7/24/2019 04:12:24", shift: "B3", acuity: 4 },
  { date: "7/24/2019 04:44:13", shift: "A3", acuity: 3 },
  { date: "7/24/2019 05:01:44", shift: "A1", acuity: 4 },
  { date: "7/24/2019 05:01:51", shift: "B3", acuity: 6 },
  { date: "7/24/2019 05:17:22", shift: "A1", acuity: 4 },
  { date: "7/24/2019 05:39:01", shift: "A1", acuity: 3 },
  { date: "7/24/2019 05:51:41", shift: "A1", acuity: 6 },
  { date: "7/24/2019 06:22:43", shift: "B1", acuity: 4 },
  { date: "7/24/2019 07:39:43", shift: "B1", acuity: 6 },
  { date: "7/24/2019 07:47:35", shift: "B1", acuity: 3 },
  { date: "7/24/2019 08:06:06", shift: "A1", acuity: 4 },
  { date: "7/24/2019 08:11:25", shift: "B1", acuity: 6 },
  { date: "7/24/2019 08:21:28", shift: "A1", acuity: 4 },
  { date: "7/24/2019 08:21:56", shift: "B1", acuity: 3 },
  { date: "7/24/2019 08:28:57", shift: "A1", acuity: 6 },
  { date: "7/24/2019 09:04:58", shift: "C1", acuity: 4 },
  { date: "7/24/2019 09:13:39", shift: "C1", acuity: 3 },
  { date: "7/24/2019 09:21:00", shift: "C1", acuity: 6 },
  { date: "7/24/2019 09:29:09", shift: "B1", acuity: 6 },
  { date: "7/24/2019 09:43:44", shift: "A1", acuity: 6 },
  { date: "7/24/2019 09:47:10", shift: "C1", acuity: 4 },
  { date: "7/24/2019 09:59:12", shift: "B1", acuity: 6 },
  { date: "7/24/2019 10:04:04", shift: "D1", acuity: 3 },
  { date: "7/24/2019 10:04:33", shift: "A1", acuity: 3 },
  { date: "7/24/2019 10:11:41", shift: "C1", acuity: 3 },
  { date: "7/24/2019 10:12:20", shift: "B1", acuity: 6 },
  { date: "7/24/2019 10:44:32", shift: "D1", acuity: 2 },
  { date: "7/24/2019 10:52:05", shift: "D1", acuity: 3 },
  { date: "7/24/2019 11:05:26", shift: "A1", acuity: 6 },
  { date: "7/24/2019 11:14:24", shift: "C1", acuity: 3 },
  { date: "7/24/2019 11:24:37", shift: "B1", acuity: 3 },
  { date: "7/24/2019 11:28:08", shift: "D1", acuity: 3 },
  { date: "7/24/2019 11:36:49", shift: "A1", acuity: 3 },
  { date: "7/24/2019 11:57:44", shift: "C1", acuity: 3 },
  { date: "7/24/2019 12:04:07", shift: "B1", acuity: 3 },
  { date: "7/24/2019 12:08:46", shift: "D1", acuity: 3 },
  { date: "7/24/2019 12:09:27", shift: "A1", acuity: 6 },
  { date: "7/24/2019 12:24:10", shift: "C1", acuity: 3 },
  { date: "7/24/2019 12:25:03", shift: "B1", acuity: 6 },
  { date: "7/24/2019 12:36:50", shift: "D1", acuity: 2 },
  { date: "7/24/2019 13:02:46", shift: "A2", acuity: 3 },
  { date: "7/24/2019 13:03:15", shift: "C1", acuity: 3 },
  { date: "7/24/2019 13:04:08", shift: "B1", acuity: 6 },
  { date: "7/24/2019 13:04:34", shift: "D1", acuity: 3 },
  { date: "7/24/2019 13:34:42", shift: "A2", acuity: 3 },
  { date: "7/24/2019 13:35:29", shift: "A2", acuity: 3 },
  { date: "7/24/2019 13:55:02", shift: "C1", acuity: 3 },
  { date: "7/24/2019 14:21:51", shift: "B2", acuity: 6 },
  { date: "7/24/2019 14:22:15", shift: "D1", acuity: 3 },
  { date: "7/24/2019 14:44:24", shift: "B2", acuity: 3 },
  { date: "7/24/2019 14:51:03", shift: "B2", acuity: 3 },
  { date: "7/24/2019 15:00:58", shift: "A2", acuity: 6 },
  { date: "7/24/2019 15:17:00", shift: "C1", acuity: 4 },
  { date: "7/24/2019 15:20:34", shift: "D1", acuity: 6 },
  { date: "7/24/2019 15:35:13", shift: "B2", acuity: 3 },
  { date: "7/24/2019 15:35:40", shift: "A2", acuity: 3 },
  { date: "7/24/2019 15:38:58", shift: "C1", acuity: 6 },
  { date: "7/24/2019 15:44:46", shift: "D1", acuity: 3 },
  { date: "7/24/2019 15:51:14", shift: "B2", acuity: 3 },
  { date: "7/24/2019 15:51:38", shift: "A2", acuity: 6 },
  { date: "7/24/2019 16:05:38", shift: "C1", acuity: 3 },
  { date: "7/24/2019 16:06:01", shift: "D1", acuity: 6 },
  { date: "7/24/2019 16:06:21", shift: "B2", acuity: 3 },
  { date: "7/24/2019 16:12:48", shift: "A2", acuity: 3 },
  { date: "7/24/2019 16:45:32", shift: "C2", acuity: 6 },
  { date: "7/24/2019 16:46:21", shift: "D1", acuity: 6 },
  { date: "7/24/2019 16:55:41", shift: "B2", acuity: 3 },
  { date: "7/24/2019 16:58:34", shift: "A2", acuity: 3 },
  { date: "7/24/2019 17:39:20", shift: "C2", acuity: 3 },
  { date: "7/24/2019 17:40:43", shift: "C2", acuity: 3 },
  { date: "7/24/2019 17:53:19", shift: "D1", acuity: 3 },
  { date: "7/24/2019 17:58:55", shift: "B2", acuity: 6 },
  { date: "7/24/2019 18:10:10", shift: "D2", acuity: 6 },
  { date: "7/24/2019 18:22:22", shift: "A2", acuity: 3 },
  { date: "7/24/2019 18:27:13", shift: "C2", acuity: 3 },
  { date: "7/24/2019 18:39:52", shift: "D2", acuity: 3 },
  { date: "7/24/2019 18:40:24", shift: "D2", acuity: 6 },
  { date: "7/24/2019 18:48:18", shift: "B2", acuity: 3 },
  { date: "7/24/2019 18:48:43", shift: "A2", acuity: 3 },
  { date: "7/24/2019 18:49:10", shift: "C2", acuity: 6 },
  { date: "7/24/2019 18:56:42", shift: "D2", acuity: 3 },
  { date: "7/24/2019 19:22:17", shift: "B2", acuity: 3 },
  { date: "7/24/2019 19:40:04", shift: "A2", acuity: 3 },
  { date: "7/24/2019 19:52:30", shift: "C2", acuity: 6 },
  { date: "7/24/2019 19:52:40", shift: "D2", acuity: 3 },
  { date: "7/24/2019 19:52:52", shift: "B2", acuity: 3 },
  { date: "7/24/2019 20:03:51", shift: "A2", acuity: 6 },
  { date: "7/24/2019 20:05:37", shift: "C2", acuity: 6 },
  { date: "7/24/2019 20:07:57", shift: "D2", acuity: 6 },
  { date: "7/24/2019 20:17:54", shift: "B2", acuity: 4 },
  { date: "7/24/2019 20:18:11", shift: "A2", acuity: 3 },
  { date: "7/24/2019 20:30:09", shift: "C2", acuity: 6 },
  { date: "7/24/2019 20:30:31", shift: "D2", acuity: 3 },
  { date: "7/24/2019 20:31:41", shift: "B2", acuity: 6 },
  { date: "7/24/2019 20:51:34", shift: "A2", acuity: 3 },
  { date: "7/24/2019 21:04:17", shift: "A3", acuity: 3 },
  { date: "7/24/2019 21:04:32", shift: "C2", acuity: 3 },
  { date: "7/24/2019 21:04:48", shift: "D2", acuity: 3 },
  { date: "7/24/2019 21:08:30", shift: "B2", acuity: 6 },
  { date: "7/24/2019 21:27:46", shift: "A3", acuity: 3 },
  { date: "7/24/2019 21:27:58", shift: "C2", acuity: 3 },
  { date: "7/24/2019 21:31:04", shift: "A3", acuity: 3 },
  { date: "7/24/2019 21:31:21", shift: "A3", acuity: 3 },
  { date: "7/24/2019 21:31:48", shift: "D2", acuity: 4 },
  { date: "7/24/2019 21:58:20", shift: "B2", acuity: 3 },
  { date: "7/24/2019 22:23:57", shift: "B3", acuity: 3 },
  { date: "7/24/2019 22:31:55", shift: "B3", acuity: 3 },
  { date: "7/24/2019 22:43:38", shift: "B3", acuity: 6 },
  { date: "7/24/2019 22:43:49", shift: "C2", acuity: 3 },
  { date: "7/24/2019 23:05:35", shift: "A3", acuity: 6 },
  { date: "7/24/2019 23:05:52", shift: "D2", acuity: 3 },
  { date: "7/24/2019 23:16:08", shift: "B3", acuity: 3 },
  { date: "7/24/2019 23:41:57", shift: "C2", acuity: 4 },
  { date: "7/24/2019 23:44:33", shift: "A3", acuity: 3 },
  { date: "7/25/2019 00:03:45", shift: "D2", acuity: 3 },
  { date: "7/25/2019 00:03:58", shift: "B3", acuity: 4 },
  { date: "7/25/2019 00:04:13", shift: "A3", acuity: 3 },
  { date: "7/25/2019 00:08:46", shift: "D2", acuity: 4 },
  { date: "7/25/2019 00:22:51", shift: "B3", acuity: 4 },
  { date: "7/25/2019 00:36:05", shift: "A3", acuity: 4 },
  { date: "7/25/2019 00:48:31", shift: "D2", acuity: 3 },
  { date: "7/25/2019 01:01:59", shift: "B3", acuity: 6 },
  { date: "7/25/2019 01:13:41", shift: "A3", acuity: 6 },
  { date: "7/25/2019 01:19:38", shift: "D2", acuity: 6 },
  { date: "7/25/2019 01:30:30", shift: "B3", acuity: 6 },
  { date: "7/25/2019 01:30:43", shift: "A3", acuity: 3 },
  { date: "7/25/2019 01:30:57", shift: "B3", acuity: 4 },
  { date: "7/25/2019 01:31:10", shift: "A3", acuity: 4 },
  { date: "7/25/2019 01:48:52", shift: "B3", acuity: 3 },
  { date: "7/25/2019 01:49:04", shift: "A3", acuity: 3 },
  { date: "7/25/2019 02:00:10", shift: "B3", acuity: 3 },
  { date: "7/25/2019 02:01:05", shift: "A3", acuity: 4 },
  { date: "7/25/2019 02:32:31", shift: "B3", acuity: 3 },
  { date: "7/25/2019 02:54:06", shift: "A3", acuity: 3 },
  { date: "7/25/2019 03:02:45", shift: "B3", acuity: 4 },
  { date: "7/25/2019 03:05:47", shift: "A3", acuity: 3 },
  { date: "7/25/2019 03:44:23", shift: "B3", acuity: 3 },
  { date: "7/25/2019 03:57:20", shift: "A3", acuity: 6 },
  { date: "7/25/2019 04:07:14", shift: "B3", acuity: 3 },
  { date: "7/25/2019 04:39:45", shift: "A3", acuity: 3 },
  { date: "7/25/2019 05:00:20", shift: "A1", acuity: 6 },
  { date: "7/25/2019 06:07:22", shift: "A1", acuity: 6 },
  { date: "7/25/2019 06:50:36", shift: "A1", acuity: 3 },
  { date: "7/25/2019 07:16:53", shift: "B1", acuity: 3 },
  { date: "7/25/2019 07:41:33", shift: "B1", acuity: 3 },
  { date: "7/25/2019 08:02:20", shift: "A1", acuity: 3 },
  { date: "7/25/2019 08:40:41", shift: "C1", acuity: 3 },
  { date: "7/25/2019 08:41:17", shift: "B1", acuity: 6 },
  { date: "7/25/2019 09:21:54", shift: "C1", acuity: 3 },
  { date: "7/25/2019 10:03:28", shift: "C1", acuity: 6 },
  { date: "7/25/2019 10:10:12", shift: "D1", acuity: 3 },
  { date: "7/25/2019 10:27:21", shift: "A1", acuity: 3 },
  { date: "7/25/2019 10:27:56", shift: "B1", acuity: 3 },
  { date: "7/25/2019 10:44:58", shift: "D1", acuity: 6 },
  { date: "7/25/2019 10:48:32", shift: "D1", acuity: 6 },
  { date: "7/25/2019 11:06:17", shift: "C1", acuity: 2 },
  { date: "7/25/2019 11:20:57", shift: "A1", acuity: 6 },
  { date: "7/25/2019 11:38:22", shift: "B1", acuity: 3 },
  { date: "7/25/2019 11:38:43", shift: "D1", acuity: 3 },
  { date: "7/25/2019 11:42:10", shift: "C1", acuity: 6 },
  { date: "7/25/2019 11:42:31", shift: "A1", acuity: 3 },
  { date: "7/25/2019 12:06:44", shift: "B1", acuity: 6 },
  { date: "7/25/2019 12:06:58", shift: "D1", acuity: 3 },
  { date: "7/25/2019 12:07:30", shift: "C1", acuity: 6 },
  { date: "7/25/2019 12:21:34", shift: "A1", acuity: 6 },
  { date: "7/25/2019 12:23:04", shift: "B1", acuity: 3 },
  { date: "7/25/2019 12:23:25", shift: "D1", acuity: 6 },
  { date: "7/25/2019 12:34:28", shift: "C1", acuity: 6 },
  { date: "7/25/2019 12:35:22", shift: "A1", acuity: 3 },
  { date: "7/25/2019 12:55:52", shift: "B1", acuity: 3 },
  { date: "7/25/2019 13:19:30", shift: "A2", acuity: 3 },
  { date: "7/25/2019 13:30:56", shift: "A2", acuity: 6 },
  { date: "7/25/2019 13:33:45", shift: "A2", acuity: 3 },
  { date: "7/25/2019 13:41:12", shift: "D1", acuity: 3 },
  { date: "7/25/2019 13:47:46", shift: "C1", acuity: 6 },
  { date: "7/25/2019 14:06:23", shift: "B2", acuity: 2 },
  { date: "7/25/2019 14:33:34", shift: "B2", acuity: 3 },
  { date: "7/25/2019 14:34:03", shift: "B2", acuity: 3 },
  { date: "7/25/2019 14:43:06", shift: "A2", acuity: 4 },
  { date: "7/25/2019 14:47:03", shift: "D1", acuity: 6 },
  { date: "7/25/2019 15:35:51", shift: "C1", acuity: 3 },
  { date: "7/25/2019 15:54:15", shift: "B2", acuity: 3 },
  { date: "7/25/2019 16:16:30", shift: "A2", acuity: 3 },
  { date: "7/25/2019 16:26:12", shift: "D1", acuity: 3 },
  { date: "7/25/2019 16:35:02", shift: "C2", acuity: 3 },
  { date: "7/25/2019 16:35:32", shift: "B2", acuity: 3 },
  { date: "7/25/2019 16:35:54", shift: "A2", acuity: 6 },
  { date: "7/25/2019 16:40:16", shift: "D1", acuity: 3 },
  { date: "7/25/2019 16:57:32", shift: "C2", acuity: 3 },
  { date: "7/25/2019 16:57:58", shift: "B2", acuity: 3 },
  { date: "7/25/2019 17:17:05", shift: "C2", acuity: 6 },
  { date: "7/25/2019 17:21:40", shift: "C2", acuity: 6 },
  { date: "7/25/2019 17:25:36", shift: "A2", acuity: 5 },
  { date: "7/25/2019 17:34:59", shift: "D1", acuity: 6 },
  { date: "7/25/2019 17:37:31", shift: "B2", acuity: 3 },
  { date: "7/25/2019 17:45:34", shift: "C2", acuity: 6 },
  { date: "7/25/2019 17:46:12", shift: "A2", acuity: 6 },
  { date: "7/25/2019 17:50:53", shift: "D1", acuity: 6 },
  { date: "7/25/2019 18:03:34", shift: "D2", acuity: 6 },
  { date: "7/25/2019 18:20:59", shift: "B2", acuity: 6 },
  { date: "7/25/2019 18:21:27", shift: "C2", acuity: 6 },
  { date: "7/25/2019 18:22:48", shift: "A2", acuity: 3 },
  { date: "7/25/2019 18:28:16", shift: "D2", acuity: 3 },
  { date: "7/25/2019 18:32:57", shift: "D2", acuity: 6 },
  { date: "7/25/2019 18:57:07", shift: "D2", acuity: 6 },
  { date: "7/25/2019 19:06:00", shift: "B2", acuity: 3 },
  { date: "7/25/2019 19:16:00", shift: "C2", acuity: 3 },
  { date: "7/25/2019 19:16:25", shift: "A2", acuity: 3 },
  { date: "7/25/2019 19:36:09", shift: "D2", acuity: 3 },
  { date: "7/25/2019 19:36:37", shift: "B2", acuity: 3 },
  { date: "7/25/2019 19:51:44", shift: "C2", acuity: 6 },
  { date: "7/25/2019 19:57:42", shift: "A2", acuity: 3 },
  { date: "7/25/2019 20:00:18", shift: "D2", acuity: 3 },
  { date: "7/25/2019 20:05:26", shift: "B2", acuity: 3 },
  { date: "7/25/2019 20:21:21", shift: "C2", acuity: 3 },
  { date: "7/25/2019 20:34:31", shift: "A2", acuity: 3 },
  { date: "7/25/2019 20:34:53", shift: "D2", acuity: 3 },
  { date: "7/25/2019 20:53:20", shift: "B2", acuity: 3 },
  { date: "7/25/2019 20:53:54", shift: "C2", acuity: 3 },
  { date: "7/25/2019 21:03:21", shift: "A3", acuity: 3 },
  { date: "7/25/2019 21:09:32", shift: "D2", acuity: 3 },
  { date: "7/25/2019 21:19:07", shift: "B2", acuity: 3 },
  { date: "7/25/2019 21:19:40", shift: "C2", acuity: 3 },
  { date: "7/25/2019 21:20:03", shift: "A3", acuity: 3 },
  { date: "7/25/2019 21:44:40", shift: "A3", acuity: 3 },
  { date: "7/25/2019 21:54:52", shift: "A3", acuity: 4 },
  { date: "7/25/2019 22:17:18", shift: "B3", acuity: 3 },
  { date: "7/25/2019 22:26:04", shift: "D2", acuity: 3 },
  { date: "7/25/2019 22:30:29", shift: "B3", acuity: 3 },
  { date: "7/25/2019 22:52:55", shift: "B3", acuity: 3 },
  { date: "7/25/2019 23:01:23", shift: "C2", acuity: 6 },
  { date: "7/25/2019 23:01:36", shift: "A3", acuity: 3 },
  { date: "7/25/2019 23:01:49", shift: "D2", acuity: 3 },
  { date: "7/25/2019 23:21:35", shift: "B3", acuity: 3 },
  { date: "7/25/2019 23:33:21", shift: "C2", acuity: 3 },
  { date: "7/25/2019 23:42:31", shift: "A3", acuity: 3 },
  { date: "7/25/2019 23:56:18", shift: "D2", acuity: 6 },
  { date: "7/26/2019 00:21:53", shift: "B3", acuity: 4 },
  { date: "7/26/2019 00:34:11", shift: "A3", acuity: 3 },
  { date: "7/26/2019 00:35:54", shift: "D2", acuity: 4 },
  { date: "7/26/2019 00:49:52", shift: "B3", acuity: 3 },
  { date: "7/26/2019 00:54:59", shift: "A3", acuity: 3 },
  { date: "7/26/2019 00:58:42", shift: "D2", acuity: 5 },
  { date: "7/26/2019 01:02:13", shift: "B3", acuity: 3 },
  { date: "7/26/2019 01:03:14", shift: "A3", acuity: 4 },
  { date: "7/26/2019 01:23:00", shift: "D2", acuity: 6 },
  { date: "7/26/2019 02:39:00", shift: "B3", acuity: 6 },
  { date: "7/26/2019 02:56:23", shift: "A3", acuity: 6 },
  { date: "7/26/2019 03:20:52", shift: "B3", acuity: 4 },
  { date: "7/26/2019 03:24:45", shift: "A3", acuity: 3 },
  { date: "7/26/2019 03:35:05", shift: "B3", acuity: 4 },
  { date: "7/26/2019 03:48:53", shift: "A3", acuity: 4 },
  { date: "7/26/2019 03:49:25", shift: "B3", acuity: 4 },
  { date: "7/26/2019 03:53:37", shift: "A3", acuity: 3 },
  { date: "7/26/2019 04:11:19", shift: "B3", acuity: 4 },
  { date: "7/26/2019 04:13:20", shift: "A3", acuity: 3 },
  { date: "7/26/2019 04:13:41", shift: "B3", acuity: 6 },
  { date: "7/26/2019 05:11:12", shift: "A1", acuity: 3 },
  { date: "7/26/2019 05:52:32", shift: "A1", acuity: 5 },
  { date: "7/26/2019 05:52:55", shift: "A1", acuity: 4 },
  { date: "7/26/2019 06:13:08", shift: "B1", acuity: 4 },
  { date: "7/26/2019 06:27:34", shift: "A1", acuity: 3 },
  { date: "7/26/2019 06:40:09", shift: "B1", acuity: 3 },
  { date: "7/26/2019 06:57:47", shift: "B1", acuity: 3 },
  { date: "7/26/2019 06:58:11", shift: "A1", acuity: 4 },
  { date: "7/26/2019 07:17:08", shift: "B1", acuity: 3 },
  { date: "7/26/2019 07:20:19", shift: "A1", acuity: 3 },
  { date: "7/26/2019 07:21:10", shift: "B1", acuity: 4 },
  { date: "7/26/2019 07:30:53", shift: "A1", acuity: 6 },
  { date: "7/26/2019 07:36:25", shift: "B1", acuity: 6 },
  { date: "7/26/2019 07:43:08", shift: "A1", acuity: 3 },
  { date: "7/26/2019 08:21:35", shift: "B1", acuity: 4 },
  { date: "7/26/2019 08:47:00", shift: "C1", acuity: 3 },
  { date: "7/26/2019 08:52:17", shift: "A1", acuity: 6 },
  { date: "7/26/2019 09:03:57", shift: "C1", acuity: 4 },
  { date: "7/26/2019 09:18:02", shift: "C1", acuity: 3 },
  { date: "7/26/2019 09:18:13", shift: "B1", acuity: 4 },
  { date: "7/26/2019 09:21:19", shift: "A1", acuity: 4 },
  { date: "7/26/2019 09:21:30", shift: "C1", acuity: 4 },
  { date: "7/26/2019 09:25:29", shift: "B1", acuity: 3 },
  { date: "7/26/2019 09:45:34", shift: "A1", acuity: 3 },
  { date: "7/26/2019 09:47:22", shift: "C1", acuity: 3 },
  { date: "7/26/2019 09:47:31", shift: "B1", acuity: 4 },
  { date: "7/26/2019 10:00:17", shift: "D1", acuity: 3 },
  { date: "7/26/2019 10:00:56", shift: "A1", acuity: 3 },
  { date: "7/26/2019 10:01:08", shift: "C1", acuity: 4 },
  { date: "7/26/2019 10:01:17", shift: "B1", acuity: 3 },
  { date: "7/26/2019 10:16:07", shift: "D1", acuity: 3 },
  { date: "7/26/2019 10:21:02", shift: "A1", acuity: 3 },
  { date: "7/26/2019 10:31:11", shift: "D1", acuity: 4 },
  { date: "7/26/2019 10:31:40", shift: "D1", acuity: 3 },
  { date: "7/26/2019 10:32:13", shift: "C1", acuity: 3 },
  { date: "7/26/2019 10:34:04", shift: "B1", acuity: 4 },
  { date: "7/26/2019 11:08:24", shift: "A1", acuity: 6 },
  { date: "7/26/2019 11:16:30", shift: "D1", acuity: 3 },
  { date: "7/26/2019 11:18:36", shift: "C1", acuity: 6 },
  { date: "7/26/2019 11:20:18", shift: "B1", acuity: 6 },
  { date: "7/26/2019 11:28:10", shift: "A1", acuity: 2 },
  { date: "7/26/2019 11:41:50", shift: "D1", acuity: 3 },
  { date: "7/26/2019 11:51:15", shift: "C1", acuity: 3 },
  { date: "7/26/2019 11:51:38", shift: "B1", acuity: 3 },
  { date: "7/26/2019 12:08:19", shift: "A1", acuity: 6 },
  { date: "7/26/2019 12:42:34", shift: "D1", acuity: 3 },
  { date: "7/26/2019 12:42:46", shift: "C1", acuity: 3 },
  { date: "7/26/2019 12:51:44", shift: "B1", acuity: 6 },
  { date: "7/26/2019 13:01:26", shift: "A2", acuity: 3 },
  { date: "7/26/2019 13:14:45", shift: "D1", acuity: 6 },
  { date: "7/26/2019 13:41:52", shift: "A2", acuity: 3 },
  { date: "7/26/2019 13:46:21", shift: "A2", acuity: 6 },
  { date: "7/26/2019 14:11:59", shift: "B2", acuity: 3 },
  { date: "7/26/2019 14:16:39", shift: "C1", acuity: 3 },
  { date: "7/26/2019 14:52:18", shift: "B2", acuity: 3 },
  { date: "7/26/2019 14:56:49", shift: "B2", acuity: 6 },
  { date: "7/26/2019 14:59:09", shift: "D1", acuity: 6 },
  { date: "7/26/2019 15:13:45", shift: "A2", acuity: 6 },
  { date: "7/26/2019 15:37:57", shift: "C1", acuity: 6 },
  { date: "7/26/2019 15:39:30", shift: "B2", acuity: 6 },
  { date: "7/26/2019 15:52:12", shift: "D1", acuity: 6 },
  { date: "7/26/2019 15:52:24", shift: "A2", acuity: 2 },
  { date: "7/26/2019 16:09:09", shift: "C1", acuity: 4 },
  { date: "7/26/2019 16:16:59", shift: "B2", acuity: 3 },
  { date: "7/26/2019 16:17:37", shift: "D1", acuity: 6 },
  { date: "7/26/2019 16:22:38", shift: "A2", acuity: 3 },
  { date: "7/26/2019 16:25:50", shift: "C1", acuity: 2 },
  { date: "7/26/2019 16:28:36", shift: "B2", acuity: 4 },
  { date: "7/26/2019 16:36:57", shift: "C2", acuity: 4 },
  { date: "7/26/2019 16:37:13", shift: "D1", acuity: 4 },
  { date: "7/26/2019 16:37:27", shift: "A2", acuity: 3 },
  { date: "7/26/2019 16:46:13", shift: "B2", acuity: 2 },
  { date: "7/26/2019 16:49:34", shift: "C2", acuity: 6 },
  { date: "7/26/2019 17:06:42", shift: "C2", acuity: 3 },
  { date: "7/26/2019 17:31:50", shift: "C2", acuity: 3 },
  { date: "7/26/2019 17:37:09", shift: "D1", acuity: 3 },
  { date: "7/26/2019 17:48:56", shift: "A2", acuity: 3 },
  { date: "7/26/2019 18:02:57", shift: "D2", acuity: 3 },
  { date: "7/26/2019 18:04:46", shift: "B2", acuity: 3 },
  { date: "7/26/2019 18:06:07", shift: "C2", acuity: 3 },
  { date: "7/26/2019 18:10:22", shift: "A2", acuity: 6 },
  { date: "7/26/2019 19:02:28", shift: "D2", acuity: 4 },
  { date: "7/26/2019 19:10:59", shift: "D2", acuity: 3 },
  { date: "7/26/2019 19:11:24", shift: "B2", acuity: 3 },
  { date: "7/26/2019 19:19:20", shift: "C2", acuity: 3 },
  { date: "7/26/2019 19:19:29", shift: "A2", acuity: 3 },
  { date: "7/26/2019 19:36:42", shift: "D2", acuity: 6 },
  { date: "7/26/2019 19:43:38", shift: "B2", acuity: 3 },
  { date: "7/26/2019 19:54:27", shift: "C2", acuity: 3 },
  { date: "7/26/2019 20:09:00", shift: "A2", acuity: 3 },
  { date: "7/26/2019 20:10:41", shift: "D2", acuity: 3 },
  { date: "7/26/2019 20:11:48", shift: "B2", acuity: 3 },
  { date: "7/26/2019 20:26:09", shift: "C2", acuity: 3 },
  { date: "7/26/2019 20:37:45", shift: "A2", acuity: 3 },
  { date: "7/26/2019 20:38:46", shift: "D2", acuity: 3 },
  { date: "7/26/2019 20:39:11", shift: "B2", acuity: 3 },
  { date: "7/26/2019 20:48:54", shift: "C2", acuity: 4 },
  { date: "7/26/2019 20:49:28", shift: "A2", acuity: 4 },
  { date: "7/26/2019 21:05:47", shift: "A3", acuity: 3 },
  { date: "7/26/2019 21:07:51", shift: "D2", acuity: 3 },
  { date: "7/26/2019 21:08:18", shift: "B2", acuity: 3 },
  { date: "7/26/2019 21:09:51", shift: "C2", acuity: 7 },
  { date: "7/26/2019 21:32:38", shift: "A3", acuity: 3 },
  { date: "7/26/2019 21:40:06", shift: "A3", acuity: 6 },
  { date: "7/26/2019 21:42:39", shift: "D2", acuity: 7 },
  { date: "7/26/2019 22:08:42", shift: "B3", acuity: 3 },
  { date: "7/26/2019 22:12:53", shift: "C2", acuity: 6 },
  { date: "7/26/2019 22:13:11", shift: "A3", acuity: 6 },
  { date: "7/26/2019 22:21:11", shift: "D2", acuity: 4 },
  { date: "7/26/2019 22:21:37", shift: "B3", acuity: 3 },
  { date: "7/26/2019 22:27:09", shift: "C2", acuity: 6 },
  { date: "7/26/2019 22:27:35", shift: "A3", acuity: 3 },
  { date: "7/26/2019 22:30:51", shift: "B3", acuity: 3 },
  { date: "7/26/2019 22:40:35", shift: "B3", acuity: 2 },
  { date: "7/26/2019 23:15:51", shift: "D2", acuity: 3 },
  { date: "7/26/2019 23:19:33", shift: "C2", acuity: 3 },
  { date: "7/26/2019 23:19:53", shift: "A3", acuity: 4 },
  { date: "7/26/2019 23:20:26", shift: "B3", acuity: 3 },
  { date: "7/26/2019 23:33:48", shift: "D2", acuity: 6 },
  { date: "7/26/2019 23:42:20", shift: "C2", acuity: 3 },
  { date: "7/26/2019 23:47:26", shift: "A3", acuity: 3 },
  { date: "7/26/2019 23:49:04", shift: "B3", acuity: 3 },
  { date: "7/26/2019 23:49:27", shift: "D2", acuity: 3 },
  { date: "7/27/2019 00:07:51", shift: "A3", acuity: 3 },
  { date: "7/27/2019 00:13:18", shift: "B3", acuity: 4 },
  { date: "7/27/2019 00:25:21", shift: "D2", acuity: 6 },
  { date: "7/27/2019 00:34:41", shift: "A3", acuity: 3 },
  { date: "7/27/2019 00:46:37", shift: "B3", acuity: 3 },
  { date: "7/27/2019 00:47:12", shift: "D2", acuity: 3 },
  { date: "7/27/2019 01:15:48", shift: "A3", acuity: 4 },
  { date: "7/27/2019 01:24:47", shift: "B3", acuity: 4 },
  { date: "7/27/2019 01:41:27", shift: "A3", acuity: 4 },
  { date: "7/27/2019 02:00:15", shift: "B3", acuity: 3 },
  { date: "7/27/2019 02:00:51", shift: "A3", acuity: 3 },
  { date: "7/27/2019 02:01:27", shift: "B3", acuity: 4 },
  { date: "7/27/2019 02:17:01", shift: "A3", acuity: 4 },
  { date: "7/27/2019 02:18:51", shift: "B3", acuity: 4 },
  { date: "7/27/2019 02:30:17", shift: "A3", acuity: 3 },
  { date: "7/27/2019 02:35:07", shift: "B3", acuity: 3 },
  { date: "7/27/2019 02:46:29", shift: "A3", acuity: 3 },
  { date: "7/27/2019 02:52:52", shift: "B3", acuity: 3 },
  { date: "7/27/2019 03:02:08", shift: "A3", acuity: 3 },
  { date: "7/27/2019 03:03:42", shift: "B3", acuity: 6 },
  { date: "7/27/2019 03:12:51", shift: "A3", acuity: 6 },
  { date: "7/27/2019 03:20:28", shift: "B3", acuity: 4 },
  { date: "7/27/2019 03:27:14", shift: "A3", acuity: 6 },
  { date: "7/27/2019 03:38:19", shift: "B3", acuity: 6 },
  { date: "7/27/2019 04:33:21", shift: "A3", acuity: 6 },
  { date: "7/27/2019 05:00:38", shift: "A1", acuity: 3 },
  { date: "7/27/2019 05:11:00", shift: "B3", acuity: 6 },
  { date: "7/27/2019 05:11:36", shift: "A1", acuity: 3 },
  { date: "7/27/2019 05:26:41", shift: "B3", acuity: 3 },
  { date: "7/27/2019 05:28:57", shift: "B3", acuity: 6 },
  { date: "7/27/2019 05:34:18", shift: "A1", acuity: 4 },
  { date: "7/27/2019 05:38:35", shift: "A1", acuity: 4 },
  { date: "7/27/2019 06:05:48", shift: "B1", acuity: 3 },
  { date: "7/27/2019 06:23:49", shift: "A1", acuity: 3 },
  { date: "7/27/2019 06:24:15", shift: "B1", acuity: 3 },
  { date: "7/27/2019 06:47:17", shift: "B1", acuity: 6 },
  { date: "7/27/2019 07:08:49", shift: "B1", acuity: 6 },
  { date: "7/27/2019 07:17:25", shift: "A1", acuity: 4 },
  { date: "7/27/2019 07:27:26", shift: "B1", acuity: 3 },
  { date: "7/27/2019 07:27:36", shift: "A1", acuity: 4 },
  { date: "7/27/2019 08:26:24", shift: "B1", acuity: 3 },
  { date: "7/27/2019 08:35:44", shift: "C1", acuity: 3 },
  { date: "7/27/2019 08:42:23", shift: "A1", acuity: 3 },
  { date: "7/27/2019 08:52:24", shift: "B1", acuity: 3 },
  { date: "7/27/2019 09:05:54", shift: "C1", acuity: 3 },
  { date: "7/27/2019 09:06:01", shift: "C1", acuity: 3 },
  { date: "7/27/2019 09:09:07", shift: "A1", acuity: 6 },
  { date: "7/27/2019 09:57:31", shift: "B1", acuity: 3 },
  { date: "7/27/2019 09:58:00", shift: "C1", acuity: 6 },
  { date: "7/27/2019 10:07:44", shift: "D1", acuity: 5 },
  { date: "7/27/2019 10:18:42", shift: "A1", acuity: 4 },
  { date: "7/27/2019 10:18:53", shift: "B1", acuity: 6 },
  { date: "7/27/2019 10:21:15", shift: "C1", acuity: 3 },
  { date: "7/27/2019 10:30:11", shift: "D1", acuity: 3 },
  { date: "7/27/2019 10:35:53", shift: "D1", acuity: 3 },
  { date: "7/27/2019 10:41:30", shift: "A1", acuity: 6 },
  { date: "7/27/2019 11:15:28", shift: "B1", acuity: 3 },
  { date: "7/27/2019 11:15:38", shift: "C1", acuity: 3 },
  { date: "7/27/2019 11:24:32", shift: "D1", acuity: 6 },
  { date: "7/27/2019 11:46:22", shift: "A1", acuity: 6 },
  { date: "7/27/2019 11:52:36", shift: "B1", acuity: 3 },
  { date: "7/27/2019 12:00:54", shift: "C1", acuity: 3 },
  { date: "7/27/2019 12:09:11", shift: "D1", acuity: 6 },
  { date: "7/27/2019 12:19:33", shift: "A1", acuity: 3 },
  { date: "7/27/2019 12:46:01", shift: "B1", acuity: 6 },
  { date: "7/27/2019 13:15:52", shift: "A2", acuity: 6 },
  { date: "7/27/2019 13:24:17", shift: "C1", acuity: 6 },
  { date: "7/27/2019 13:40:11", shift: "A2", acuity: 3 },
  { date: "7/27/2019 13:43:49", shift: "A2", acuity: 6 },
  { date: "7/27/2019 13:55:29", shift: "D1", acuity: 6 },
  { date: "7/27/2019 13:55:57", shift: "B1", acuity: 6 },
  { date: "7/27/2019 13:58:42", shift: "C1", acuity: 6 },
  { date: "7/27/2019 14:10:41", shift: "B2", acuity: 3 },
  { date: "7/27/2019 14:20:31", shift: "A2", acuity: 6 },
  { date: "7/27/2019 14:22:06", shift: "D1", acuity: 3 },
  { date: "7/27/2019 14:35:42", shift: "B2", acuity: 3 },
  { date: "7/27/2019 15:16:59", shift: "B2", acuity: 3 },
  { date: "7/27/2019 15:17:05", shift: "C1", acuity: 3 },
  { date: "7/27/2019 15:18:14", shift: "A2", acuity: 3 },
  { date: "7/27/2019 15:29:52", shift: "D1", acuity: 3 },
  { date: "7/27/2019 15:46:30", shift: "B2", acuity: 6 },
  { date: "7/27/2019 15:46:42", shift: "C1", acuity: 3 },
  { date: "7/27/2019 15:52:16", shift: "A2", acuity: 3 },
  { date: "7/27/2019 16:08:48", shift: "D1", acuity: 6 },
  { date: "7/27/2019 16:22:14", shift: "B2", acuity: 3 },
  { date: "7/27/2019 16:28:14", shift: "C1", acuity: 6 },
  { date: "7/27/2019 16:36:41", shift: "C2", acuity: 4 },
  { date: "7/27/2019 16:38:41", shift: "A2", acuity: 6 },
  { date: "7/27/2019 17:13:28", shift: "C2", acuity: 3 },
  { date: "7/27/2019 17:13:41", shift: "C2", acuity: 3 },
  { date: "7/27/2019 17:13:58", shift: "D1", acuity: 3 },
  { date: "7/27/2019 17:25:44", shift: "B2", acuity: 3 },
  { date: "7/27/2019 17:35:50", shift: "A2", acuity: 3 },
  { date: "7/27/2019 17:51:14", shift: "C2", acuity: 3 },
  { date: "7/27/2019 18:11:52", shift: "D2", acuity: 3 },
  { date: "7/27/2019 18:12:03", shift: "B2", acuity: 3 },
  { date: "7/27/2019 18:40:16", shift: "D2", acuity: 6 },
  { date: "7/27/2019 18:50:00", shift: "D2", acuity: 3 },
  { date: "7/27/2019 19:03:07", shift: "A2", acuity: 6 },
  { date: "7/27/2019 19:11:06", shift: "C2", acuity: 3 },
  { date: "7/27/2019 19:11:27", shift: "B2", acuity: 3 },
  { date: "7/27/2019 19:11:50", shift: "D2", acuity: 6 },
  { date: "7/27/2019 19:20:15", shift: "A2", acuity: 3 },
  { date: "7/27/2019 19:29:39", shift: "C2", acuity: 3 },
  { date: "7/27/2019 19:41:48", shift: "B2", acuity: 3 },
  { date: "7/27/2019 19:42:56", shift: "D2", acuity: 7 },
  { date: "7/27/2019 19:52:12", shift: "A2", acuity: 6 },
  { date: "7/27/2019 20:02:24", shift: "C2", acuity: 7 },
  { date: "7/27/2019 20:02:45", shift: "B2", acuity: 3 },
  { date: "7/27/2019 20:20:16", shift: "D2", acuity: 3 },
  { date: "7/27/2019 20:29:43", shift: "A2", acuity: 6 },
  { date: "7/27/2019 20:49:29", shift: "C2", acuity: 3 },
  { date: "7/27/2019 20:54:08", shift: "B2", acuity: 6 },
  { date: "7/27/2019 21:08:37", shift: "A3", acuity: 3 },
  { date: "7/27/2019 21:14:22", shift: "D2", acuity: 3 },
  { date: "7/27/2019 21:14:49", shift: "C2", acuity: 3 },
  { date: "7/27/2019 21:20:24", shift: "B2", acuity: 4 },
  { date: "7/27/2019 21:21:06", shift: "A3", acuity: 3 },
  { date: "7/27/2019 21:22:47", shift: "D2", acuity: 3 },
  { date: "7/27/2019 21:36:40", shift: "A3", acuity: 3 },
  { date: "7/27/2019 21:58:47", shift: "A3", acuity: 3 },
  { date: "7/27/2019 21:59:06", shift: "C2", acuity: 3 },
  { date: "7/27/2019 22:11:10", shift: "B3", acuity: 3 },
  { date: "7/27/2019 22:19:05", shift: "D2", acuity: 6 },
  { date: "7/27/2019 22:26:13", shift: "A3", acuity: 3 },
  { date: "7/27/2019 22:31:22", shift: "B3", acuity: 3 },
  { date: "7/27/2019 22:40:27", shift: "B3", acuity: 3 },
  { date: "7/27/2019 23:00:23", shift: "C2", acuity: 4 },
  { date: "7/27/2019 23:00:48", shift: "D2", acuity: 4 },
  { date: "7/27/2019 23:01:21", shift: "A3", acuity: 3 },
  { date: "7/27/2019 23:05:09", shift: "B3", acuity: 4 },
  { date: "7/27/2019 23:11:04", shift: "C2", acuity: 5 },
  { date: "7/27/2019 23:28:24", shift: "D2", acuity: 4 },
  { date: "7/27/2019 23:35:19", shift: "A3", acuity: 4 },
  { date: "7/27/2019 23:42:02", shift: "B3", acuity: 3 },
  { date: "7/28/2019 00:10:33", shift: "D2", acuity: 3 },
  { date: "7/28/2019 00:12:06", shift: "A3", acuity: 3 },
  { date: "7/28/2019 00:12:21", shift: "B3", acuity: 3 },
  { date: "7/28/2019 00:21:20", shift: "D2", acuity: 6 },
  { date: "7/28/2019 00:34:48", shift: "A3", acuity: 3 },
  { date: "7/28/2019 00:50:21", shift: "B3", acuity: 3 },
  { date: "7/28/2019 01:08:34", shift: "D2", acuity: 4 },
  { date: "7/28/2019 01:13:00", shift: "A3", acuity: 3 },
  { date: "7/28/2019 01:13:30", shift: "B3", acuity: 6 },
  { date: "7/28/2019 01:21:59", shift: "D2", acuity: 4 },
  { date: "7/28/2019 01:42:03", shift: "A3", acuity: 4 },
  { date: "7/28/2019 02:05:00", shift: "B3", acuity: 6 },
  { date: "7/28/2019 02:06:08", shift: "A3", acuity: 4 },
  { date: "7/28/2019 02:28:28", shift: "B3", acuity: 5 },
  { date: "7/28/2019 03:26:23", shift: "A3", acuity: 6 },
  { date: "7/28/2019 03:33:20", shift: "B3", acuity: 3 },
  { date: "7/28/2019 03:35:08", shift: "A3", acuity: 5 },
  { date: "7/28/2019 03:38:49", shift: "B3", acuity: 3 },
  { date: "7/28/2019 03:44:36", shift: "A3", acuity: 3 },
  { date: "7/28/2019 04:43:49", shift: "B3", acuity: 4 },
  { date: "7/28/2019 05:27:50", shift: "A1", acuity: 4 },
  { date: "7/28/2019 05:29:33", shift: "B3", acuity: 3 },
  { date: "7/28/2019 05:52:05", shift: "A1", acuity: 3 },
  { date: "7/28/2019 05:52:32", shift: "A1", acuity: 4 },
  { date: "7/28/2019 06:15:29", shift: "B1", acuity: 6 },
  { date: "7/28/2019 06:30:56", shift: "B1", acuity: 6 },
  { date: "7/28/2019 06:31:18", shift: "B1", acuity: 3 },
  { date: "7/28/2019 07:18:49", shift: "A1", acuity: 3 },
  { date: "7/28/2019 07:19:23", shift: "B1", acuity: 6 },
  { date: "7/28/2019 07:19:53", shift: "A1", acuity: 3 },
  { date: "7/28/2019 07:25:44", shift: "B1", acuity: 6 },
  { date: "7/28/2019 07:41:57", shift: "A1", acuity: 3 },
  { date: "7/28/2019 07:57:08", shift: "B1", acuity: 3 },
  { date: "7/28/2019 08:04:09", shift: "A1", acuity: 3 },
  { date: "7/28/2019 08:19:45", shift: "B1", acuity: 3 },
  { date: "7/28/2019 08:45:49", shift: "C1", acuity: 3 },
  { date: "7/28/2019 08:53:16", shift: "A1", acuity: 5 },
  { date: "7/28/2019 08:58:27", shift: "B1", acuity: 3 },
  { date: "7/28/2019 09:00:14", shift: "C1", acuity: 6 },
  { date: "7/28/2019 09:21:58", shift: "C1", acuity: 3 },
  { date: "7/28/2019 09:22:21", shift: "A1", acuity: 3 },
  { date: "7/28/2019 09:23:26", shift: "B1", acuity: 6 },
  { date: "7/28/2019 09:23:52", shift: "C1", acuity: 3 },
  { date: "7/28/2019 09:49:00", shift: "A1", acuity: 3 },
  { date: "7/28/2019 09:49:28", shift: "B1", acuity: 4 },
  { date: "7/28/2019 09:49:48", shift: "C1", acuity: 3 },
  { date: "7/28/2019 09:50:06", shift: "A1", acuity: 6 },
  { date: "7/28/2019 10:14:23", shift: "D1", acuity: 3 },
  { date: "7/28/2019 10:26:00", shift: "B1", acuity: 6 },
  { date: "7/28/2019 10:35:12", shift: "D1", acuity: 6 },
  { date: "7/28/2019 10:37:54", shift: "D1", acuity: 6 },
  { date: "7/28/2019 10:58:24", shift: "C1", acuity: 3 },
  { date: "7/28/2019 11:21:35", shift: "A1", acuity: 3 },
  { date: "7/28/2019 11:24:12", shift: "B1", acuity: 3 },
  { date: "7/28/2019 11:25:59", shift: "D1", acuity: 6 },
  { date: "7/28/2019 11:30:46", shift: "C1", acuity: 3 },
  { date: "7/28/2019 11:48:38", shift: "A1", acuity: 3 },
  { date: "7/28/2019 11:54:42", shift: "B1", acuity: 3 },
  { date: "7/28/2019 12:06:19", shift: "D1", acuity: 3 },
  { date: "7/28/2019 12:28:58", shift: "C1", acuity: 3 },
  { date: "7/28/2019 12:37:24", shift: "A1", acuity: 6 },
  { date: "7/28/2019 13:13:09", shift: "A2", acuity: 4 },
  { date: "7/28/2019 13:13:38", shift: "B1", acuity: 6 },
  { date: "7/28/2019 13:38:33", shift: "A2", acuity: 3 },
  { date: "7/28/2019 13:39:25", shift: "A2", acuity: 3 },
  { date: "7/28/2019 13:40:05", shift: "D1", acuity: 6 },
  { date: "7/28/2019 13:40:36", shift: "C1", acuity: 3 },
  { date: "7/28/2019 13:46:20", shift: "B1", acuity: 3 },
  { date: "7/28/2019 13:46:44", shift: "A2", acuity: 3 },
  { date: "7/28/2019 13:53:34", shift: "D1", acuity: 3 },
  { date: "7/28/2019 14:10:33", shift: "B2", acuity: 6 },
  { date: "7/28/2019 14:17:52", shift: "C1", acuity: 6 },
  { date: "7/28/2019 14:27:31", shift: "A2", acuity: 3 },
  { date: "7/28/2019 14:30:53", shift: "B2", acuity: 6 },
  { date: "7/28/2019 14:34:51", shift: "B2", acuity: 6 },
  { date: "7/28/2019 14:40:54", shift: "D1", acuity: 3 },
  { date: "7/28/2019 14:46:18", shift: "C1", acuity: 3 },
  { date: "7/28/2019 15:09:58", shift: "A2", acuity: 6 },
  { date: "7/28/2019 15:24:02", shift: "B2", acuity: 4 },
  { date: "7/28/2019 15:25:38", shift: "D1", acuity: 4 },
  { date: "7/28/2019 15:28:59", shift: "C1", acuity: 3 },
  { date: "7/28/2019 15:33:34", shift: "A2", acuity: 3 },
  { date: "7/28/2019 15:42:16", shift: "B2", acuity: 6 },
  { date: "7/28/2019 15:47:07", shift: "D1", acuity: 3 },
  { date: "7/28/2019 15:54:16", shift: "C1", acuity: 6 },
  { date: "7/28/2019 16:01:27", shift: "A2", acuity: 3 },
  { date: "7/28/2019 16:05:06", shift: "B2", acuity: 3 },
  { date: "7/28/2019 16:18:55", shift: "D1", acuity: 3 },
  { date: "7/28/2019 16:22:51", shift: "C1", acuity: 3 },
  { date: "7/28/2019 16:29:35", shift: "A2", acuity: 2 },
  { date: "7/28/2019 16:35:36", shift: "C2", acuity: 6 },
  { date: "7/28/2019 16:52:05", shift: "B2", acuity: 3 },
  { date: "7/28/2019 17:09:50", shift: "C2", acuity: 3 },
  { date: "7/28/2019 17:10:37", shift: "C2", acuity: 6 },
  { date: "7/28/2019 17:25:23", shift: "D1", acuity: 3 },
  { date: "7/28/2019 17:51:45", shift: "A2", acuity: 6 },
  { date: "7/28/2019 17:56:13", shift: "B2", acuity: 3 },
  { date: "7/28/2019 18:06:48", shift: "D2", acuity: 6 },
  { date: "7/28/2019 18:07:26", shift: "C2", acuity: 6 },
  { date: "7/28/2019 18:23:19", shift: "A2", acuity: 6 },
  { date: "7/28/2019 18:40:50", shift: "D2", acuity: 6 },
  { date: "7/28/2019 18:50:37", shift: "D2", acuity: 6 },
  { date: "7/28/2019 18:58:03", shift: "B2", acuity: 6 },
  { date: "7/28/2019 19:02:29", shift: "C2", acuity: 4 },
  { date: "7/28/2019 19:23:43", shift: "A2", acuity: 3 },
  { date: "7/28/2019 19:24:00", shift: "D2", acuity: 3 },
  { date: "7/28/2019 19:34:26", shift: "B2", acuity: 3 },
  { date: "7/28/2019 20:00:42", shift: "C2", acuity: 3 },
  { date: "7/28/2019 20:10:59", shift: "A2", acuity: 6 },
  { date: "7/28/2019 20:32:35", shift: "D2", acuity: 6 },
  { date: "7/28/2019 20:41:59", shift: "B2", acuity: 6 },
  { date: "7/28/2019 20:42:22", shift: "C2", acuity: 3 },
  { date: "7/28/2019 20:50:51", shift: "A2", acuity: 6 },
  { date: "7/28/2019 20:51:03", shift: "D2", acuity: 3 },
  { date: "7/28/2019 21:01:27", shift: "A3", acuity: 3 },
  { date: "7/28/2019 21:08:06", shift: "B2", acuity: 6 },
  { date: "7/28/2019 21:51:03", shift: "A3", acuity: 3 },
  { date: "7/28/2019 21:54:55", shift: "A3", acuity: 3 },
  { date: "7/28/2019 22:03:20", shift: "B3", acuity: 6 },
  { date: "7/28/2019 22:23:37", shift: "C2", acuity: 3 },
  { date: "7/28/2019 22:55:04", shift: "B3", acuity: 3 },
  { date: "7/28/2019 22:57:00", shift: "B3", acuity: 6 },
  { date: "7/28/2019 22:57:16", shift: "D2", acuity: 6 },
  { date: "7/28/2019 22:59:49", shift: "A3", acuity: 4 },
  { date: "7/28/2019 23:12:35", shift: "C2", acuity: 3 },
  { date: "7/28/2019 23:20:07", shift: "B3", acuity: 3 },
  { date: "7/28/2019 23:22:15", shift: "D2", acuity: 3 },
  { date: "7/28/2019 23:28:11", shift: "A3", acuity: 3 },
  { date: "7/28/2019 23:36:54", shift: "C2", acuity: 4 },
  { date: "7/28/2019 23:37:15", shift: "B3", acuity: 4 },
  { date: "7/28/2019 23:48:50", shift: "D2", acuity: 3 },
  { date: "7/28/2019 23:49:03", shift: "A3", acuity: 4 },
  { date: "7/28/2019 23:52:04", shift: "C2", acuity: 4 },
  { date: "7/29/2019 00:07:06", shift: "B3", acuity: 6 },
  { date: "7/29/2019 00:25:35", shift: "D2", acuity: 3 },
  { date: "7/29/2019 00:25:46", shift: "A3", acuity: 3 },
  { date: "7/29/2019 00:59:24", shift: "B3", acuity: 4 },
  { date: "7/29/2019 01:11:58", shift: "D2", acuity: 6 },
  { date: "7/29/2019 01:12:11", shift: "A3", acuity: 4 },
  { date: "7/29/2019 01:56:49", shift: "B3", acuity: 3 },
  { date: "7/29/2019 02:04:33", shift: "A3", acuity: 4 },
  { date: "7/29/2019 02:05:07", shift: "B3", acuity: 4 },
  { date: "7/29/2019 02:25:38", shift: "A3", acuity: 3 },
  { date: "7/29/2019 02:50:07", shift: "B3", acuity: 3 },
  { date: "7/29/2019 03:06:35", shift: "A3", acuity: 3 },
  { date: "7/29/2019 03:10:49", shift: "B3", acuity: 4 },
  { date: "7/29/2019 03:32:32", shift: "A3", acuity: 5 },
  { date: "7/29/2019 03:46:02", shift: "B3", acuity: 3 },
  { date: "7/29/2019 03:48:09", shift: "A3", acuity: 4 },
  { date: "7/29/2019 03:53:50", shift: "B3", acuity: 3 },
  { date: "7/29/2019 04:37:30", shift: "A3", acuity: 2 },
  { date: "7/29/2019 05:00:15", shift: "A1", acuity: 3 },
  { date: "7/29/2019 05:20:54", shift: "B3", acuity: 3 },
  { date: "7/29/2019 05:29:14", shift: "A1", acuity: 4 },
  { date: "7/29/2019 06:00:00", shift: "A1", acuity: 3 },
  { date: "7/29/2019 06:09:02", shift: "A1", acuity: 3 },
  { date: "7/29/2019 06:25:05", shift: "B1", acuity: 4 },
  { date: "7/29/2019 06:30:52", shift: "B1", acuity: 4 },
  { date: "7/29/2019 06:38:46", shift: "B1", acuity: 6 },
  { date: "7/29/2019 06:58:20", shift: "A1", acuity: 2 },
  { date: "7/29/2019 07:31:49", shift: "B1", acuity: 3 },
  { date: "7/29/2019 07:34:17", shift: "A1", acuity: 3 },
  { date: "7/29/2019 07:36:22", shift: "B1", acuity: 3 },
  { date: "7/29/2019 07:56:06", shift: "A1", acuity: 6 },
  { date: "7/29/2019 08:29:20", shift: "B1", acuity: 6 },
  { date: "7/29/2019 08:47:40", shift: "C1", acuity: 4 },
  { date: "7/29/2019 09:03:38", shift: "C1", acuity: 3 },
  { date: "7/29/2019 09:07:30", shift: "C1", acuity: 3 },
  { date: "7/29/2019 09:15:09", shift: "A1", acuity: 6 },
  { date: "7/29/2019 09:17:49", shift: "B1", acuity: 4 },
  { date: "7/29/2019 09:18:15", shift: "C1", acuity: 3 },
  { date: "7/29/2019 09:18:40", shift: "A1", acuity: 4 },
  { date: "7/29/2019 09:28:46", shift: "B1", acuity: 3 },
  { date: "7/29/2019 09:39:20", shift: "C1", acuity: 3 },
  { date: "7/29/2019 09:43:12", shift: "A1", acuity: 3 },
  { date: "7/29/2019 09:57:23", shift: "B1", acuity: 6 },
  { date: "7/29/2019 09:59:22", shift: "C1", acuity: 6 },
  { date: "7/29/2019 10:18:00", shift: "D1", acuity: 6 },
  { date: "7/29/2019 10:18:34", shift: "A1", acuity: 6 },
  { date: "7/29/2019 10:19:20", shift: "B1", acuity: 3 },
  { date: "7/29/2019 10:19:55", shift: "C1", acuity: 3 },
  { date: "7/29/2019 10:27:43", shift: "D1", acuity: 6 },
  { date: "7/29/2019 11:07:58", shift: "D1", acuity: 6 },
  { date: "7/29/2019 11:14:11", shift: "D1", acuity: 3 },
  { date: "7/29/2019 11:14:31", shift: "A1", acuity: 3 },
  { date: "7/29/2019 11:34:00", shift: "B1", acuity: 3 },
  { date: "7/29/2019 11:34:25", shift: "C1", acuity: 3 },
  { date: "7/29/2019 11:35:15", shift: "D1", acuity: 3 },
  { date: "7/29/2019 11:37:02", shift: "A1", acuity: 3 },
  { date: "7/29/2019 11:48:01", shift: "B1", acuity: 6 },
  { date: "7/29/2019 12:03:24", shift: "C1", acuity: 6 },
  { date: "7/29/2019 12:04:09", shift: "D1", acuity: 3 },
  { date: "7/29/2019 12:24:36", shift: "A1", acuity: 3 },
  { date: "7/29/2019 12:42:27", shift: "B1", acuity: 6 },
  { date: "7/29/2019 12:45:47", shift: "C1", acuity: 3 },
  { date: "7/29/2019 12:46:31", shift: "D1", acuity: 6 },
  { date: "7/29/2019 12:57:48", shift: "A1", acuity: 6 },
  { date: "7/29/2019 12:58:19", shift: "B1", acuity: 6 },
  { date: "7/29/2019 13:00:26", shift: "A2", acuity: 6 },
  { date: "7/29/2019 13:11:11", shift: "C1", acuity: 6 },
  { date: "7/29/2019 13:11:31", shift: "D1", acuity: 3 },
  { date: "7/29/2019 13:29:04", shift: "B1", acuity: 6 },
  { date: "7/29/2019 13:38:56", shift: "A2", acuity: 6 },
  { date: "7/29/2019 13:42:50", shift: "A2", acuity: 6 },
  { date: "7/29/2019 13:53:23", shift: "C1", acuity: 3 },
  { date: "7/29/2019 14:00:19", shift: "B2", acuity: 4 },
  { date: "7/29/2019 14:00:45", shift: "D1", acuity: 6 },
  { date: "7/29/2019 14:02:15", shift: "A2", acuity: 3 },
  { date: "7/29/2019 14:09:02", shift: "C1", acuity: 6 },
  { date: "7/29/2019 14:30:15", shift: "B2", acuity: 3 },
  { date: "7/29/2019 14:40:53", shift: "B2", acuity: 4 },
  { date: "7/29/2019 14:56:31", shift: "D1", acuity: 6 },
  { date: "7/29/2019 15:35:20", shift: "A2", acuity: 3 },
  { date: "7/29/2019 15:53:12", shift: "C1", acuity: 3 },
  { date: "7/29/2019 16:06:29", shift: "B2", acuity: 6 },
  { date: "7/29/2019 16:13:30", shift: "D1", acuity: 3 },
  { date: "7/29/2019 16:26:27", shift: "A2", acuity: 6 },
  { date: "7/29/2019 16:27:39", shift: "C1", acuity: 3 },
  { date: "7/29/2019 16:34:28", shift: "C2", acuity: 3 },
  { date: "7/29/2019 16:35:53", shift: "B2", acuity: 6 },
  { date: "7/29/2019 16:37:01", shift: "D1", acuity: 6 },
  { date: "7/29/2019 17:01:46", shift: "C2", acuity: 3 },
  { date: "7/29/2019 17:02:16", shift: "C2", acuity: 4 },
  { date: "7/29/2019 17:11:31", shift: "A2", acuity: 6 },
  { date: "7/29/2019 17:24:26", shift: "B2", acuity: 3 },
  { date: "7/29/2019 17:25:38", shift: "D1", acuity: 3 },
  { date: "7/29/2019 17:26:08", shift: "C2", acuity: 6 },
  { date: "7/29/2019 17:26:43", shift: "A2", acuity: 6 },
  { date: "7/29/2019 17:46:35", shift: "B2", acuity: 3 },
  { date: "7/29/2019 18:01:36", shift: "D2", acuity: 3 },
  { date: "7/29/2019 18:02:22", shift: "C2", acuity: 3 },
  { date: "7/29/2019 18:02:50", shift: "A2", acuity: 3 },
  { date: "7/29/2019 18:08:17", shift: "B2", acuity: 6 },
  { date: "7/29/2019 18:24:07", shift: "D2", acuity: 4 },
  { date: "7/29/2019 18:31:40", shift: "D2", acuity: 3 },
  { date: "7/29/2019 18:38:21", shift: "D2", acuity: 6 },
  { date: "7/29/2019 18:43:53", shift: "C2", acuity: 3 },
  { date: "7/29/2019 18:56:28", shift: "A2", acuity: 3 },
  { date: "7/29/2019 19:05:47", shift: "B2", acuity: 3 },
  { date: "7/29/2019 19:10:40", shift: "D2", acuity: 3 },
  { date: "7/29/2019 19:25:24", shift: "C2", acuity: 3 },
  { date: "7/29/2019 19:33:15", shift: "A2", acuity: 3 },
  { date: "7/29/2019 19:33:54", shift: "B2", acuity: 6 },
  { date: "7/29/2019 19:40:42", shift: "D2", acuity: 6 },
  { date: "7/29/2019 19:45:07", shift: "C2", acuity: 6 },
  { date: "7/29/2019 19:46:23", shift: "A2", acuity: 4 },
  { date: "7/29/2019 19:54:19", shift: "B2", acuity: 6 },
  { date: "7/29/2019 20:02:15", shift: "D2", acuity: 6 },
  { date: "7/29/2019 20:13:58", shift: "C2", acuity: 3 },
  { date: "7/29/2019 20:14:12", shift: "A2", acuity: 3 },
  { date: "7/29/2019 20:46:48", shift: "B2", acuity: 3 },
  { date: "7/29/2019 20:51:48", shift: "D2", acuity: 6 },
  { date: "7/29/2019 20:52:06", shift: "C2", acuity: 3 },
  { date: "7/29/2019 21:04:57", shift: "A3", acuity: 6 },
  { date: "7/29/2019 21:07:56", shift: "B2", acuity: 6 },
  { date: "7/29/2019 21:23:57", shift: "D2", acuity: 3 },
  { date: "7/29/2019 21:24:15", shift: "C2", acuity: 3 },
  { date: "7/29/2019 21:34:01", shift: "A3", acuity: 6 },
  { date: "7/29/2019 21:44:10", shift: "A3", acuity: 3 },
  { date: "7/29/2019 22:04:14", shift: "B3", acuity: 3 },
  { date: "7/29/2019 22:06:36", shift: "D2", acuity: 3 },
  { date: "7/29/2019 22:18:45", shift: "C2", acuity: 6 },
  { date: "7/29/2019 22:31:52", shift: "B3", acuity: 6 },
  { date: "7/29/2019 22:35:23", shift: "B3", acuity: 6 },
  { date: "7/29/2019 22:43:18", shift: "A3", acuity: 3 },
  { date: "7/29/2019 22:57:56", shift: "D2", acuity: 6 },
  { date: "7/29/2019 23:05:53", shift: "C2", acuity: 6 },
  { date: "7/29/2019 23:21:18", shift: "B3", acuity: 6 },
  { date: "7/29/2019 23:30:27", shift: "A3", acuity: 3 },
  { date: "7/29/2019 23:32:41", shift: "D2", acuity: 3 },
  { date: "7/29/2019 23:49:25", shift: "C2", acuity: 3 },
  { date: "7/29/2019 23:49:38", shift: "B3", acuity: 6 },
  { date: "7/30/2019 00:13:51", shift: "A3", acuity: 3 },
  { date: "7/30/2019 00:15:06", shift: "D2", acuity: 3 },
  { date: "7/30/2019 00:24:18", shift: "B3", acuity: 3 },
  { date: "7/30/2019 00:31:00", shift: "A3", acuity: 6 },
  { date: "7/30/2019 01:02:03", shift: "D2", acuity: 6 },
  { date: "7/30/2019 01:02:15", shift: "B3", acuity: 4 },
  { date: "7/30/2019 01:11:12", shift: "A3", acuity: 4 },
  { date: "7/30/2019 01:16:45", shift: "D2", acuity: 3 },
  { date: "7/30/2019 01:16:59", shift: "B3", acuity: 6 },
  { date: "7/30/2019 01:36:49", shift: "A3", acuity: 3 },
  { date: "7/30/2019 02:04:26", shift: "B3", acuity: 3 },
  { date: "7/30/2019 02:04:37", shift: "A3", acuity: 3 },
  { date: "7/30/2019 02:13:41", shift: "B3", acuity: 3 },
  { date: "7/30/2019 02:44:45", shift: "A3", acuity: 6 },
  { date: "7/30/2019 02:44:55", shift: "B3", acuity: 3 },
  { date: "7/30/2019 03:14:52", shift: "A3", acuity: 3 },
  { date: "7/30/2019 03:15:02", shift: "B3", acuity: 3 },
  { date: "7/30/2019 03:26:40", shift: "A3", acuity: 3 },
  { date: "7/30/2019 03:47:35", shift: "B3", acuity: 6 },
  { date: "7/30/2019 04:07:35", shift: "A3", acuity: 3 },
  { date: "7/30/2019 04:18:47", shift: "B3", acuity: 3 },
  { date: "7/30/2019 05:19:37", shift: "A1", acuity: 3 },
  { date: "7/30/2019 05:30:51", shift: "A1", acuity: 4 },
  { date: "7/30/2019 06:00:30", shift: "A1", acuity: 3 },
  { date: "7/30/2019 06:22:31", shift: "B1", acuity: 6 },
  { date: "7/30/2019 06:33:11", shift: "B1", acuity: 4 },
  { date: "7/30/2019 06:50:21", shift: "B1", acuity: 6 },
  { date: "7/30/2019 06:50:43", shift: "A1", acuity: 4 },
  { date: "7/30/2019 07:00:19", shift: "B1", acuity: 4 },
  { date: "7/30/2019 07:13:01", shift: "A1", acuity: 6 },
  { date: "7/30/2019 07:21:05", shift: "B1", acuity: 6 },
  { date: "7/30/2019 07:26:23", shift: "A1", acuity: 3 },
  { date: "7/30/2019 08:01:40", shift: "B1", acuity: 3 },
  { date: "7/30/2019 08:10:33", shift: "A1", acuity: 3 },
  { date: "7/30/2019 08:13:19", shift: "B1", acuity: 3 },
  { date: "7/30/2019 08:13:38", shift: "A1", acuity: 3 },
  { date: "7/30/2019 08:30:22", shift: "C1", acuity: 6 },
  { date: "7/30/2019 08:32:03", shift: "B1", acuity: 3 },
  { date: "7/30/2019 08:32:25", shift: "A1", acuity: 6 },
  { date: "7/30/2019 09:01:03", shift: "C1", acuity: 6 },
  { date: "7/30/2019 09:07:21", shift: "C1", acuity: 6 },
  { date: "7/30/2019 09:07:38", shift: "B1", acuity: 3 },
  { date: "7/30/2019 09:31:24", shift: "A1", acuity: 6 },
  { date: "7/30/2019 09:31:59", shift: "C1", acuity: 3 },
  { date: "7/30/2019 09:32:47", shift: "B1", acuity: 3 },
  { date: "7/30/2019 09:40:57", shift: "A1", acuity: 6 },
  { date: "7/30/2019 09:41:13", shift: "C1", acuity: 6 },
  { date: "7/30/2019 09:47:11", shift: "B1", acuity: 6 },
  { date: "7/30/2019 10:09:06", shift: "D1", acuity: 3 },
  { date: "7/30/2019 10:09:37", shift: "A1", acuity: 3 },
  { date: "7/30/2019 10:22:51", shift: "C1", acuity: 4 },
  { date: "7/30/2019 10:24:22", shift: "B1", acuity: 3 },
  { date: "7/30/2019 11:21:46", shift: "D1", acuity: 6 },
  { date: "7/30/2019 11:22:02", shift: "D1", acuity: 3 },
  { date: "7/30/2019 11:24:38", shift: "A1", acuity: 6 },
  { date: "7/30/2019 11:29:19", shift: "C1", acuity: 3 },
  { date: "7/30/2019 11:42:35", shift: "B1", acuity: 3 },
  { date: "7/30/2019 12:03:41", shift: "D1", acuity: 7 },
  { date: "7/30/2019 12:10:27", shift: "A1", acuity: 7 },
  { date: "7/30/2019 12:27:52", shift: "C1", acuity: 3 },
  { date: "7/30/2019 12:31:08", shift: "B1", acuity: 3 },
  { date: "7/30/2019 12:42:54", shift: "D1", acuity: 3 },
  { date: "7/30/2019 12:43:19", shift: "A1", acuity: 6 },
  { date: "7/30/2019 12:53:29", shift: "C1", acuity: 6 },
  { date: "7/30/2019 12:55:18", shift: "B1", acuity: 6 },
  { date: "7/30/2019 13:13:19", shift: "A2", acuity: 3 },
  { date: "7/30/2019 13:13:50", shift: "D1", acuity: 3 },
  { date: "7/30/2019 13:14:09", shift: "C1", acuity: 3 },
  { date: "7/30/2019 13:14:35", shift: "B1", acuity: 3 },
  { date: "7/30/2019 13:52:45", shift: "A2", acuity: 6 },
  { date: "7/30/2019 13:53:12", shift: "A2", acuity: 3 },
  { date: "7/30/2019 13:53:39", shift: "D1", acuity: 6 },
  { date: "7/30/2019 13:54:06", shift: "C1", acuity: 6 },
  { date: "7/30/2019 14:03:30", shift: "B2", acuity: 6 },
  { date: "7/30/2019 14:25:05", shift: "A2", acuity: 3 },
  { date: "7/30/2019 14:36:28", shift: "B2", acuity: 3 },
  { date: "7/30/2019 14:42:02", shift: "B2", acuity: 6 },
  { date: "7/30/2019 14:55:14", shift: "D1", acuity: 3 },
  { date: "7/30/2019 15:05:57", shift: "C1", acuity: 7 },
  { date: "7/30/2019 15:24:41", shift: "A2", acuity: 7 },
  { date: "7/30/2019 15:25:03", shift: "B2", acuity: 3 },
  { date: "7/30/2019 15:33:25", shift: "D1", acuity: 3 },
  { date: "7/30/2019 15:38:01", shift: "C1", acuity: 3 },
  { date: "7/30/2019 15:40:34", shift: "A2", acuity: 3 },
  { date: "7/30/2019 15:53:36", shift: "B2", acuity: 6 },
  { date: "7/30/2019 15:57:15", shift: "D1", acuity: 3 },
  { date: "7/30/2019 15:57:32", shift: "C1", acuity: 3 },
  { date: "7/30/2019 16:10:41", shift: "A2", acuity: 3 },
  { date: "7/30/2019 16:18:10", shift: "B2", acuity: 3 },
  { date: "7/30/2019 16:40:02", shift: "C2", acuity: 3 },
  { date: "7/30/2019 16:56:39", shift: "D1", acuity: 4 },
  { date: "7/30/2019 17:02:30", shift: "C2", acuity: 6 },
  { date: "7/30/2019 17:02:53", shift: "C2", acuity: 3 },
  { date: "7/30/2019 17:13:39", shift: "A2", acuity: 3 },
  { date: "7/30/2019 17:22:45", shift: "B2", acuity: 4 },
  { date: "7/30/2019 17:23:55", shift: "D1", acuity: 6 },
  { date: "7/30/2019 17:35:06", shift: "C2", acuity: 3 },
  { date: "7/30/2019 17:37:13", shift: "A2", acuity: 6 },
  { date: "7/30/2019 17:44:21", shift: "B2", acuity: 3 },
  { date: "7/30/2019 17:49:15", shift: "D1", acuity: 3 },
  { date: "7/30/2019 17:49:37", shift: "C2", acuity: 3 },
  { date: "7/30/2019 17:55:41", shift: "A2", acuity: 4 },
  { date: "7/30/2019 18:02:08", shift: "D2", acuity: 6 },
  { date: "7/30/2019 18:19:28", shift: "B2", acuity: 3 },
  { date: "7/30/2019 18:32:48", shift: "D2", acuity: 6 },
  { date: "7/30/2019 18:46:57", shift: "D2", acuity: 3 },
  { date: "7/30/2019 18:54:19", shift: "C2", acuity: 3 },
  { date: "7/30/2019 18:58:48", shift: "A2", acuity: 6 },
  { date: "7/30/2019 19:04:11", shift: "B2", acuity: 6 },
  { date: "7/30/2019 19:09:45", shift: "D2", acuity: 3 },
  { date: "7/30/2019 19:13:02", shift: "C2", acuity: 3 },
  { date: "7/30/2019 19:25:08", shift: "A2", acuity: 4 },
  { date: "7/30/2019 19:29:45", shift: "B2", acuity: 4 },
  { date: "7/30/2019 19:41:44", shift: "D2", acuity: 3 },
  { date: "7/30/2019 19:44:00", shift: "C2", acuity: 3 },
  { date: "7/30/2019 20:15:56", shift: "A2", acuity: 3 },
  { date: "7/30/2019 20:17:09", shift: "B2", acuity: 4 },
  { date: "7/30/2019 20:17:21", shift: "D2", acuity: 3 },
  { date: "7/30/2019 20:51:56", shift: "C2", acuity: 3 },
  { date: "7/30/2019 21:11:44", shift: "A3", acuity: 4 },
  { date: "7/30/2019 21:22:55", shift: "B2", acuity: 6 },
  { date: "7/30/2019 21:31:52", shift: "A3", acuity: 3 },
  { date: "7/30/2019 21:43:45", shift: "A3", acuity: 3 },
  { date: "7/30/2019 21:51:53", shift: "D2", acuity: 6 },
  { date: "7/30/2019 21:58:58", shift: "C2", acuity: 3 },
  { date: "7/30/2019 22:09:03", shift: "B3", acuity: 3 },
  { date: "7/30/2019 22:09:14", shift: "A3", acuity: 3 },
  { date: "7/30/2019 22:20:11", shift: "D2", acuity: 6 },
  { date: "7/30/2019 22:25:21", shift: "C2", acuity: 3 },
  { date: "7/30/2019 22:34:24", shift: "B3", acuity: 3 },
  { date: "7/30/2019 22:34:36", shift: "B3", acuity: 4 },
  { date: "7/30/2019 22:49:48", shift: "A3", acuity: 6 },
  { date: "7/30/2019 22:52:07", shift: "D2", acuity: 6 },
  { date: "7/30/2019 22:59:25", shift: "C2", acuity: 6 },
  { date: "7/30/2019 23:01:47", shift: "B3", acuity: 3 },
  { date: "7/30/2019 23:09:10", shift: "A3", acuity: 3 },
  { date: "7/30/2019 23:13:09", shift: "D2", acuity: 6 },
  { date: "7/30/2019 23:19:45", shift: "C2", acuity: 3 },
  { date: "7/30/2019 23:39:38", shift: "B3", acuity: 6 },
  { date: "7/30/2019 23:59:44", shift: "A3", acuity: 4 },
  { date: "7/31/2019 00:05:43", shift: "D2", acuity: 6 },
  { date: "7/31/2019 00:05:56", shift: "B3", acuity: 5 },
  { date: "7/31/2019 00:06:10", shift: "A3", acuity: 3 },
  { date: "7/31/2019 00:20:08", shift: "D2", acuity: 3 },
  { date: "7/31/2019 00:20:19", shift: "B3", acuity: 4 },
  { date: "7/31/2019 00:24:25", shift: "A3", acuity: 3 },
  { date: "7/31/2019 00:31:29", shift: "D2", acuity: 6 },
  { date: "7/31/2019 00:41:30", shift: "B3", acuity: 4 },
  { date: "7/31/2019 00:47:58", shift: "A3", acuity: 6 },
  { date: "7/31/2019 00:49:40", shift: "D2", acuity: 3 },
  { date: "7/31/2019 01:13:51", shift: "B3", acuity: 3 },
  { date: "7/31/2019 01:14:01", shift: "A3", acuity: 3 },
  { date: "7/31/2019 01:22:58", shift: "D2", acuity: 3 },
  { date: "7/31/2019 02:06:36", shift: "B3", acuity: 3 },
  { date: "7/31/2019 02:12:14", shift: "A3", acuity: 4 },
  { date: "7/31/2019 02:29:12", shift: "B3", acuity: 6 },
  { date: "7/31/2019 02:35:34", shift: "A3", acuity: 4 },
  { date: "7/31/2019 02:35:46", shift: "B3", acuity: 4 },
  { date: "7/31/2019 03:02:57", shift: "A3", acuity: 3 },
  { date: "7/31/2019 03:07:08", shift: "B3", acuity: 4 },
  { date: "7/31/2019 03:25:30", shift: "A3", acuity: 3 },
  { date: "7/31/2019 03:56:59", shift: "B3", acuity: 6 },
  { date: "7/31/2019 04:13:07", shift: "A3", acuity: 6 },
  { date: "7/31/2019 05:17:01", shift: "A1", acuity: 3 },
  { date: "7/31/2019 05:36:01", shift: "A1", acuity: 4 },
  { date: "7/31/2019 06:26:24", shift: "A1", acuity: 3 },
  { date: "7/31/2019 06:31:44", shift: "A1", acuity: 3 },
  { date: "7/31/2019 07:11:18", shift: "B1", acuity: 3 },
  { date: "7/31/2019 07:16:20", shift: "B1", acuity: 3 },
  { date: "7/31/2019 07:35:34", shift: "B1", acuity: 3 },
  { date: "7/31/2019 08:04:19", shift: "A1", acuity: 3 },
  { date: "7/31/2019 08:08:28", shift: "B1", acuity: 3 },
  { date: "7/31/2019 08:34:21", shift: "C1", acuity: 3 },
  { date: "7/31/2019 08:34:44", shift: "A1", acuity: 3 },
  { date: "7/31/2019 09:00:25", shift: "C1", acuity: 3 },
  { date: "7/31/2019 09:05:03", shift: "C1", acuity: 3 },
  { date: "7/31/2019 09:57:58", shift: "B1", acuity: 3 },
  { date: "7/31/2019 10:15:42", shift: "D1", acuity: 6 },
  { date: "7/31/2019 10:22:12", shift: "A1", acuity: 3 },
  { date: "7/31/2019 10:22:38", shift: "C1", acuity: 4 },
  { date: "7/31/2019 10:28:33", shift: "B1", acuity: 6 },
  { date: "7/31/2019 10:41:10", shift: "D1", acuity: 4 },
  { date: "7/31/2019 10:44:16", shift: "D1", acuity: 4 },
  { date: "7/31/2019 10:49:25", shift: "A1", acuity: 4 },
  { date: "7/31/2019 10:57:30", shift: "C1", acuity: 2 },
  { date: "7/31/2019 11:06:01", shift: "B1", acuity: 3 },
  { date: "7/31/2019 11:06:16", shift: "D1", acuity: 3 },
  { date: "7/31/2019 11:10:12", shift: "A1", acuity: 3 },
  { date: "7/31/2019 11:28:38", shift: "C1", acuity: 6 },
  { date: "7/31/2019 11:33:44", shift: "B1", acuity: 6 },
  { date: "7/31/2019 11:34:01", shift: "D1", acuity: 6 },
  { date: "7/31/2019 11:55:14", shift: "A1", acuity: 6 },
  { date: "7/31/2019 11:55:35", shift: "C1", acuity: 6 },
  { date: "7/31/2019 11:59:10", shift: "B1", acuity: 6 },
  { date: "7/31/2019 12:09:19", shift: "D1", acuity: 6 },
  { date: "7/31/2019 12:09:41", shift: "A1", acuity: 6 },
  { date: "7/31/2019 12:12:46", shift: "C1", acuity: 6 },
  { date: "7/31/2019 12:34:39", shift: "B1", acuity: 3 },
  { date: "7/31/2019 12:34:55", shift: "D1", acuity: 6 },
  { date: "7/31/2019 12:42:28", shift: "A1", acuity: 3 },
  { date: "7/31/2019 12:43:09", shift: "C1", acuity: 3 },
  { date: "7/31/2019 13:03:52", shift: "A2", acuity: 3 },
  { date: "7/31/2019 13:19:44", shift: "B1", acuity: 3 },
  { date: "7/31/2019 13:26:47", shift: "D1", acuity: 3 },
  { date: "7/31/2019 13:44:22", shift: "A2", acuity: 6 },
  { date: "7/31/2019 13:44:43", shift: "A2", acuity: 3 },
  { date: "7/31/2019 13:45:04", shift: "C1", acuity: 4 },
  { date: "7/31/2019 14:08:53", shift: "B2", acuity: 6 },
  { date: "7/31/2019 14:19:44", shift: "D1", acuity: 3 },
  { date: "7/31/2019 14:30:47", shift: "B2", acuity: 3 },
  { date: "7/31/2019 14:31:10", shift: "B2", acuity: 3 },
  { date: "7/31/2019 14:36:51", shift: "A2", acuity: 6 },
  { date: "7/31/2019 14:52:36", shift: "C1", acuity: 3 },
  { date: "7/31/2019 15:11:32", shift: "D1", acuity: 6 },
  { date: "7/31/2019 15:11:56", shift: "B2", acuity: 6 },
  { date: "7/31/2019 15:15:24", shift: "A2", acuity: 3 },
  { date: "7/31/2019 15:21:08", shift: "C1", acuity: 3 },
  { date: "7/31/2019 15:43:07", shift: "D1", acuity: 3 },
  { date: "7/31/2019 15:48:07", shift: "B2", acuity: 6 },
  { date: "7/31/2019 15:55:20", shift: "A2", acuity: 6 },
  { date: "7/31/2019 16:00:49", shift: "C1", acuity: 6 },
  { date: "7/31/2019 16:02:40", shift: "D1", acuity: 6 },
  { date: "7/31/2019 16:10:36", shift: "B2", acuity: 6 },
  { date: "7/31/2019 16:15:53", shift: "A2", acuity: 3 },
  { date: "7/31/2019 16:27:13", shift: "C1", acuity: 3 },
  { date: "7/31/2019 16:28:05", shift: "D1", acuity: 3 },
  { date: "7/31/2019 16:28:25", shift: "B2", acuity: 3 },
  { date: "7/31/2019 16:52:34", shift: "C2", acuity: 3 },
  { date: "7/31/2019 17:02:45", shift: "C2", acuity: 3 },
  { date: "7/31/2019 17:02:58", shift: "C2", acuity: 3 },
  { date: "7/31/2019 17:30:03", shift: "A2", acuity: 3 },
  { date: "7/31/2019 17:30:56", shift: "D1", acuity: 3 },
  { date: "7/31/2019 17:51:20", shift: "B2", acuity: 6 },
  { date: "7/31/2019 17:55:08", shift: "C2", acuity: 3 },
  { date: "7/31/2019 18:10:43", shift: "D2", acuity: 3 },
  { date: "7/31/2019 18:23:21", shift: "A2", acuity: 3 },
  { date: "7/31/2019 18:28:00", shift: "B2", acuity: 6 },
  { date: "7/31/2019 18:28:16", shift: "C2", acuity: 3 },
  { date: "7/31/2019 18:38:56", shift: "D2", acuity: 6 },
  { date: "7/31/2019 18:41:39", shift: "D2", acuity: 3 },
  { date: "7/31/2019 18:43:25", shift: "A2", acuity: 6 },
  { date: "7/31/2019 19:04:36", shift: "B2", acuity: 3 },
  { date: "7/31/2019 19:18:41", shift: "C2", acuity: 6 },
  { date: "7/31/2019 19:23:09", shift: "D2", acuity: 6 },
  { date: "7/31/2019 19:33:53", shift: "A2", acuity: 3 },
  { date: "7/31/2019 19:34:21", shift: "B2", acuity: 3 },
  { date: "7/31/2019 19:45:09", shift: "C2", acuity: 6 },
  { date: "7/31/2019 19:49:08", shift: "D2", acuity: 7 },
  { date: "7/31/2019 20:04:17", shift: "A2", acuity: 3 },
  { date: "7/31/2019 20:04:28", shift: "B2", acuity: 3 },
  { date: "7/31/2019 20:14:04", shift: "C2", acuity: 3 },
  { date: "7/31/2019 20:14:20", shift: "D2", acuity: 3 },
  { date: "7/31/2019 20:14:32", shift: "A2", acuity: 4 },
  { date: "7/31/2019 20:22:19", shift: "B2", acuity: 7 },
  { date: "7/31/2019 20:36:31", shift: "C2", acuity: 6 },
  { date: "7/31/2019 21:01:03", shift: "A3", acuity: 3 },
  { date: "7/31/2019 21:27:00", shift: "D2", acuity: 3 },
  { date: "7/31/2019 21:37:37", shift: "A3", acuity: 7 },
  { date: "7/31/2019 21:38:34", shift: "A3", acuity: 7 },
  { date: "7/31/2019 21:41:04", shift: "B2", acuity: 3 },
  { date: "7/31/2019 21:46:54", shift: "C2", acuity: 6 },
  { date: "7/31/2019 21:49:15", shift: "D2", acuity: 7 },
  { date: "7/31/2019 21:49:32", shift: "A3", acuity: 6 },
  { date: "7/31/2019 22:00:28", shift: "B3", acuity: 3 },
  { date: "7/31/2019 22:17:48", shift: "C2", acuity: 6 },
  { date: "7/31/2019 22:22:39", shift: "D2", acuity: 6 },
  { date: "7/31/2019 22:40:05", shift: "B3", acuity: 3 },
  { date: "7/31/2019 22:40:30", shift: "B3", acuity: 3 },
  { date: "7/31/2019 22:41:37", shift: "A3", acuity: 3 },
  { date: "7/31/2019 22:55:45", shift: "C2", acuity: 4 },
  { date: "7/31/2019 23:10:14", shift: "D2", acuity: 4 },
  { date: "7/31/2019 23:12:38", shift: "B3", acuity: 3 },
  { date: "7/31/2019 23:17:34", shift: "A3", acuity: 6 },
  { date: "7/31/2019 23:17:48", shift: "C2", acuity: 6 },
  { date: "7/31/2019 23:17:59", shift: "D2", acuity: 3 },
  { date: "7/31/2019 23:35:46", shift: "B3", acuity: 7 },
  { date: "7/31/2019 23:36:02", shift: "A3", acuity: 3 },
  { date: "7/31/2019 23:56:02", shift: "C2", acuity: 6 },
  { date: "8/1/2019 00:14:31", shift: "D2", acuity: 3 },
  { date: "8/1/2019 00:29:28", shift: "B3", acuity: 6 },
  { date: "8/1/2019 00:38:05", shift: "A3", acuity: 7 },
  { date: "8/1/2019 00:38:30", shift: "D2", acuity: 6 },
  { date: "8/1/2019 00:38:52", shift: "B3", acuity: 3 },
  { date: "8/1/2019 00:39:11", shift: "A3", acuity: 4 },
  { date: "8/1/2019 00:53:06", shift: "D2", acuity: 3 },
  { date: "8/1/2019 00:59:42", shift: "B3", acuity: 3 },
  { date: "8/1/2019 01:24:48", shift: "A3", acuity: 6 },
  { date: "8/1/2019 01:52:20", shift: "B3", acuity: 7 },
  { date: "8/1/2019 02:06:17", shift: "A3", acuity: 4 },
  { date: "8/1/2019 02:06:31", shift: "B3", acuity: 4 },
  { date: "8/1/2019 02:15:25", shift: "A3", acuity: 3 },
  { date: "8/1/2019 02:31:42", shift: "B3", acuity: 3 },
  { date: "8/1/2019 02:53:25", shift: "A3", acuity: 3 },
  { date: "8/1/2019 03:13:58", shift: "B3", acuity: 2 },
  { date: "8/1/2019 03:48:10", shift: "A3", acuity: 6 },
  { date: "8/1/2019 03:53:12", shift: "B3", acuity: 4 },
  { date: "8/1/2019 04:19:24", shift: "A3", acuity: 4 },
  { date: "8/1/2019 04:35:40", shift: "B3", acuity: 6 },
  { date: "8/1/2019 04:40:00", shift: "A3", acuity: 4 },
  { date: "8/1/2019 04:40:11", shift: "B3", acuity: 3 },
  { date: "8/1/2019 04:44:00", shift: "A3", acuity: 3 },
  { date: "8/1/2019 05:12:54", shift: "A1", acuity: 3 },
  { date: "8/1/2019 05:47:19", shift: "A1", acuity: 3 },
  { date: "8/1/2019 05:47:31", shift: "A1", acuity: 4 },
  { date: "8/1/2019 06:06:23", shift: "B1", acuity: 3 },
  { date: "8/1/2019 06:27:00", shift: "A1", acuity: 3 },
  { date: "8/1/2019 07:09:30", shift: "B1", acuity: 3 },
  { date: "8/1/2019 07:25:16", shift: "B1", acuity: 3 },
  { date: "8/1/2019 07:50:38", shift: "A1", acuity: 3 },
  { date: "8/1/2019 08:19:09", shift: "B1", acuity: 3 },
  { date: "8/1/2019 08:19:24", shift: "A1", acuity: 3 },
  { date: "8/1/2019 08:24:57", shift: "B1", acuity: 6 },
  { date: "8/1/2019 08:58:23", shift: "C1", acuity: 3 },
  { date: "8/1/2019 09:02:46", shift: "C1", acuity: 3 },
  { date: "8/1/2019 09:04:13", shift: "C1", acuity: 3 },
  { date: "8/1/2019 09:04:47", shift: "A1", acuity: 3 },
  { date: "8/1/2019 09:09:46", shift: "B1", acuity: 3 },
  { date: "8/1/2019 09:12:47", shift: "C1", acuity: 3 },
  { date: "8/1/2019 09:24:44", shift: "A1", acuity: 4 },
  { date: "8/1/2019 09:25:02", shift: "B1", acuity: 6 },
  { date: "8/1/2019 09:32:03", shift: "C1", acuity: 3 },
  { date: "8/1/2019 09:32:23", shift: "A1", acuity: 4 },
  { date: "8/1/2019 09:48:14", shift: "B1", acuity: 6 },
  { date: "8/1/2019 09:56:22", shift: "C1", acuity: 6 },
  { date: "8/1/2019 10:28:08", shift: "D1", acuity: 3 },
  { date: "8/1/2019 10:29:05", shift: "A1", acuity: 3 },
  { date: "8/1/2019 10:35:44", shift: "D1", acuity: 6 },
  { date: "8/1/2019 10:51:34", shift: "D1", acuity: 6 },
  { date: "8/1/2019 11:05:04", shift: "B1", acuity: 3 },
  { date: "8/1/2019 11:05:21", shift: "C1", acuity: 3 },
  { date: "8/1/2019 11:08:10", shift: "A1", acuity: 6 },
  { date: "8/1/2019 11:26:56", shift: "D1", acuity: 3 },
  { date: "8/1/2019 11:27:57", shift: "B1", acuity: 3 },
  { date: "8/1/2019 11:37:29", shift: "C1", acuity: 3 },
  { date: "8/1/2019 11:38:46", shift: "A1", acuity: 3 },
  { date: "8/1/2019 11:39:02", shift: "D1", acuity: 3 },
  { date: "8/1/2019 12:09:03", shift: "B1", acuity: 3 },
  { date: "8/1/2019 12:09:37", shift: "C1", acuity: 6 },
  { date: "8/1/2019 12:12:19", shift: "A1", acuity: 6 },
  { date: "8/1/2019 12:24:48", shift: "D1", acuity: 3 },
  { date: "8/1/2019 12:25:10", shift: "B1", acuity: 4 },
  { date: "8/1/2019 12:56:20", shift: "C1", acuity: 6 },
  { date: "8/1/2019 13:12:01", shift: "A2", acuity: 3 },
  { date: "8/1/2019 13:12:29", shift: "D1", acuity: 6 },
  { date: "8/1/2019 13:24:36", shift: "B1", acuity: 6 },
  { date: "8/1/2019 13:25:15", shift: "C1", acuity: 6 },
  { date: "8/1/2019 13:29:39", shift: "A2", acuity: 3 },
  { date: "8/1/2019 13:34:19", shift: "A2", acuity: 3 },
  { date: "8/1/2019 13:49:44", shift: "A2", acuity: 3 },
  { date: "8/1/2019 13:50:06", shift: "D1", acuity: 3 },
  { date: "8/1/2019 13:58:16", shift: "B1", acuity: 6 },
  { date: "8/1/2019 14:25:30", shift: "B2", acuity: 6 },
  { date: "8/1/2019 14:26:35", shift: "C1", acuity: 3 },
  { date: "8/1/2019 14:31:48", shift: "B2", acuity: 3 },
  { date: "8/1/2019 14:35:32", shift: "B2", acuity: 3 },
  { date: "8/1/2019 14:35:48", shift: "A2", acuity: 6 },
  { date: "8/1/2019 15:13:25", shift: "D1", acuity: 6 },
  { date: "8/1/2019 15:23:28", shift: "C1", acuity: 3 },
  { date: "8/1/2019 15:24:08", shift: "B2", acuity: 6 },
  { date: "8/1/2019 15:31:06", shift: "A2", acuity: 6 },
  { date: "8/1/2019 15:32:42", shift: "D1", acuity: 3 },
  { date: "8/1/2019 15:33:27", shift: "C1", acuity: 3 },
  { date: "8/1/2019 15:33:52", shift: "B2", acuity: 3 },
  { date: "8/1/2019 15:48:26", shift: "A2", acuity: 3 },
  { date: "8/1/2019 15:52:39", shift: "D1", acuity: 6 },
  { date: "8/1/2019 16:20:41", shift: "C1", acuity: 3 },
  { date: "8/1/2019 16:21:16", shift: "B2", acuity: 3 },
  { date: "8/1/2019 16:37:09", shift: "C2", acuity: 6 },
  { date: "8/1/2019 16:41:45", shift: "A2", acuity: 3 },
  { date: "8/1/2019 16:42:43", shift: "D1", acuity: 6 },
  { date: "8/1/2019 16:51:34", shift: "B2", acuity: 3 },
  { date: "8/1/2019 16:52:12", shift: "C2", acuity: 3 },
  { date: "8/1/2019 17:01:24", shift: "C2", acuity: 3 },
  { date: "8/1/2019 17:06:41", shift: "C2", acuity: 3 },
  { date: "8/1/2019 17:31:04", shift: "A2", acuity: 6 },
  { date: "8/1/2019 17:33:59", shift: "D1", acuity: 6 },
  { date: "8/1/2019 17:43:20", shift: "B2", acuity: 2 },
  { date: "8/1/2019 17:43:38", shift: "C2", acuity: 6 },
  { date: "8/1/2019 17:58:53", shift: "A2", acuity: 6 },
  { date: "8/1/2019 18:10:19", shift: "D2", acuity: 3 },
  { date: "8/1/2019 18:21:56", shift: "B2", acuity: 6 },
  { date: "8/1/2019 18:34:17", shift: "D2", acuity: 3 },
  { date: "8/1/2019 18:36:47", shift: "D2", acuity: 6 },
  { date: "8/1/2019 18:43:36", shift: "C2", acuity: 3 },
  { date: "8/1/2019 19:09:40", shift: "A2", acuity: 3 },
  { date: "8/1/2019 19:10:11", shift: "B2", acuity: 6 },
  { date: "8/1/2019 19:51:39", shift: "D2", acuity: 6 },
  { date: "8/1/2019 19:51:59", shift: "C2", acuity: 6 },
  { date: "8/1/2019 19:52:21", shift: "A2", acuity: 6 },
  { date: "8/1/2019 19:53:01", shift: "B2", acuity: 6 },
  { date: "8/1/2019 19:55:58", shift: "D2", acuity: 6 },
  { date: "8/1/2019 19:56:13", shift: "C2", acuity: 6 },
  { date: "8/1/2019 19:57:31", shift: "A2", acuity: 6 },
  { date: "8/1/2019 20:01:50", shift: "B2", acuity: 3 },
  { date: "8/1/2019 20:22:43", shift: "D2", acuity: 3 },
  { date: "8/1/2019 20:26:49", shift: "C2", acuity: 3 },
  { date: "8/1/2019 20:27:19", shift: "A2", acuity: 3 },
  { date: "8/1/2019 20:34:55", shift: "B2", acuity: 6 },
  { date: "8/1/2019 20:52:08", shift: "D2", acuity: 3 },
  { date: "8/1/2019 21:02:25", shift: "A3", acuity: 3 },
  { date: "8/1/2019 21:08:28", shift: "C2", acuity: 3 },
  { date: "8/1/2019 21:24:29", shift: "B2", acuity: 6 },
  { date: "8/1/2019 21:34:35", shift: "A3", acuity: 3 },
  { date: "8/1/2019 21:35:11", shift: "A3", acuity: 4 },
  { date: "8/1/2019 21:35:33", shift: "D2", acuity: 3 },
  { date: "8/1/2019 22:03:05", shift: "B3", acuity: 3 },
  { date: "8/1/2019 22:08:15", shift: "C2", acuity: 6 },
  { date: "8/1/2019 22:21:42", shift: "A3", acuity: 3 },
  { date: "8/1/2019 22:28:12", shift: "D2", acuity: 3 },
  { date: "8/1/2019 22:36:52", shift: "B3", acuity: 3 },
  { date: "8/1/2019 22:39:46", shift: "B3", acuity: 3 },
  { date: "8/1/2019 22:53:16", shift: "C2", acuity: 6 },
  { date: "8/1/2019 22:54:06", shift: "A3", acuity: 6 },
  { date: "8/1/2019 23:07:59", shift: "D2", acuity: 3 },
  { date: "8/1/2019 23:11:17", shift: "B3", acuity: 4 },
  { date: "8/1/2019 23:12:40", shift: "C2", acuity: 4 },
  { date: "8/1/2019 23:16:28", shift: "A3", acuity: 3 },
  { date: "8/1/2019 23:24:31", shift: "D2", acuity: 6 },
  { date: "8/1/2019 23:37:36", shift: "B3", acuity: 3 },
  { date: "8/1/2019 23:42:38", shift: "C2", acuity: 4 },
  { date: "8/1/2019 23:54:36", shift: "A3", acuity: 4 },
  { date: "8/1/2019 23:58:35", shift: "D2", acuity: 6 },
  { date: "8/2/2019 00:03:55", shift: "B3", acuity: 4 },
  { date: "8/2/2019 00:14:53", shift: "A3", acuity: 2 },
  { date: "8/2/2019 00:23:19", shift: "D2", acuity: 6 },
  { date: "8/2/2019 00:23:45", shift: "B3", acuity: 6 },
  { date: "8/2/2019 00:30:09", shift: "A3", acuity: 6 },
  { date: "8/2/2019 00:44:56", shift: "D2", acuity: 6 },
  { date: "8/2/2019 01:02:26", shift: "B3", acuity: 3 },
  { date: "8/2/2019 01:02:43", shift: "A3", acuity: 3 },
  { date: "8/2/2019 01:09:11", shift: "D2", acuity: 6 },
  { date: "8/2/2019 01:19:12", shift: "B3", acuity: 4 },
  { date: "8/2/2019 01:46:11", shift: "A3", acuity: 6 },
  { date: "8/2/2019 02:00:40", shift: "B3", acuity: 3 },
  { date: "8/2/2019 02:01:05", shift: "A3", acuity: 3 },
  { date: "8/2/2019 02:23:58", shift: "B3", acuity: 3 },
  { date: "8/2/2019 02:46:03", shift: "A3", acuity: 6 },
  { date: "8/2/2019 03:05:15", shift: "B3", acuity: 6 },
  { date: "8/2/2019 03:06:55", shift: "A3", acuity: 4 },
  { date: "8/2/2019 03:34:24", shift: "B3", acuity: 3 },
  { date: "8/2/2019 03:44:01", shift: "A3", acuity: 3 },
  { date: "8/2/2019 04:15:13", shift: "B3", acuity: 3 },
  { date: "8/2/2019 04:15:39", shift: "A3", acuity: 3 },
  { date: "8/2/2019 04:36:23", shift: "B3", acuity: 3 },
  { date: "8/2/2019 05:04:19", shift: "A1", acuity: 6 },
  { date: "8/2/2019 05:07:54", shift: "B3", acuity: 4 },
  { date: "8/2/2019 05:56:00", shift: "A1", acuity: 4 },
  { date: "8/2/2019 06:05:07", shift: "A1", acuity: 4 },
  { date: "8/2/2019 06:09:53", shift: "B1", acuity: 3 },
  { date: "8/2/2019 06:21:01", shift: "A1", acuity: 3 },
  { date: "8/2/2019 07:19:04", shift: "B1", acuity: 4 },
  { date: "8/2/2019 07:31:48", shift: "B1", acuity: 3 },
  { date: "8/2/2019 07:36:59", shift: "A1", acuity: 7 },
  { date: "8/2/2019 07:40:56", shift: "B1", acuity: 6 },
  { date: "8/2/2019 07:55:38", shift: "A1", acuity: 4 },
  { date: "8/2/2019 08:49:44", shift: "C1", acuity: 6 },
  { date: "8/2/2019 09:05:49", shift: "C1", acuity: 4 },
  { date: "8/2/2019 09:20:32", shift: "C1", acuity: 3 },
  { date: "8/2/2019 09:38:08", shift: "B1", acuity: 3 },
  { date: "8/2/2019 09:56:05", shift: "A1", acuity: 3 },
  { date: "8/2/2019 10:17:32", shift: "D1", acuity: 4 },
  { date: "8/2/2019 10:23:48", shift: "C1", acuity: 4 },
  { date: "8/2/2019 10:33:00", shift: "D1", acuity: 4 },
  { date: "8/2/2019 10:45:29", shift: "D1", acuity: 4 },
  { date: "8/2/2019 10:50:11", shift: "B1", acuity: 3 },
  { date: "8/2/2019 10:50:30", shift: "A1", acuity: 3 },
  { date: "8/2/2019 10:52:44", shift: "C1", acuity: 3 },
  { date: "8/2/2019 11:00:02", shift: "D1", acuity: 6 },
  { date: "8/2/2019 11:12:10", shift: "B1", acuity: 3 },
  { date: "8/2/2019 11:13:21", shift: "A1", acuity: 3 },
  { date: "8/2/2019 11:20:51", shift: "C1", acuity: 6 },
  { date: "8/2/2019 11:28:40", shift: "D1", acuity: 3 },
  { date: "8/2/2019 11:36:25", shift: "B1", acuity: 6 },
  { date: "8/2/2019 11:38:23", shift: "A1", acuity: 6 },
  { date: "8/2/2019 11:59:35", shift: "C1", acuity: 6 },
  { date: "8/2/2019 12:02:29", shift: "D1", acuity: 3 },
  { date: "8/2/2019 12:04:13", shift: "B1", acuity: 3 },
  { date: "8/2/2019 12:10:25", shift: "A1", acuity: 3 },
  { date: "8/2/2019 12:12:43", shift: "C1", acuity: 3 },
  { date: "8/2/2019 12:14:28", shift: "D1", acuity: 3 },
  { date: "8/2/2019 12:28:52", shift: "B1", acuity: 3 },
  { date: "8/2/2019 12:40:43", shift: "A1", acuity: 6 },
  { date: "8/2/2019 12:44:58", shift: "C1", acuity: 6 },
  { date: "8/2/2019 13:00:33", shift: "A2", acuity: 6 },
  { date: "8/2/2019 13:12:02", shift: "D1", acuity: 6 },
  { date: "8/2/2019 13:18:24", shift: "B1", acuity: 3 },
  { date: "8/2/2019 13:18:52", shift: "C1", acuity: 6 },
  { date: "8/2/2019 13:28:06", shift: "A2", acuity: 3 },
  { date: "8/2/2019 13:50:34", shift: "A2", acuity: 6 },
  { date: "8/2/2019 13:53:35", shift: "A2", acuity: 6 },
  { date: "8/2/2019 13:59:06", shift: "D1", acuity: 6 },
  { date: "8/2/2019 14:03:56", shift: "B2", acuity: 6 },
  { date: "8/2/2019 14:08:57", shift: "C1", acuity: 3 },
  { date: "8/2/2019 14:21:57", shift: "A2", acuity: 6 },
  { date: "8/2/2019 14:28:26", shift: "D1", acuity: 6 },
  { date: "8/2/2019 14:36:21", shift: "B2", acuity: 6 },
  { date: "8/2/2019 14:48:34", shift: "B2", acuity: 6 },
  { date: "8/2/2019 14:51:25", shift: "C1", acuity: 3 },
  { date: "8/2/2019 14:58:11", shift: "A2", acuity: 6 },
  { date: "8/2/2019 14:58:41", shift: "D1", acuity: 3 },
  { date: "8/2/2019 15:05:36", shift: "B2", acuity: 3 },
  { date: "8/2/2019 15:06:53", shift: "C1", acuity: 6 },
  { date: "8/2/2019 15:15:50", shift: "A2", acuity: 6 },
  { date: "8/2/2019 15:34:25", shift: "D1", acuity: 6 },
  { date: "8/2/2019 15:34:59", shift: "B2", acuity: 6 },
  { date: "8/2/2019 15:46:10", shift: "C1", acuity: 6 },
  { date: "8/2/2019 16:00:32", shift: "A2", acuity: 2 },
  { date: "8/2/2019 16:06:00", shift: "D1", acuity: 6 },
  { date: "8/2/2019 16:15:47", shift: "B2", acuity: 6 },
  { date: "8/2/2019 16:35:56", shift: "C2", acuity: 3 },
  { date: "8/2/2019 16:36:31", shift: "A2", acuity: 3 },
  { date: "8/2/2019 16:36:55", shift: "D1", acuity: 3 },
  { date: "8/2/2019 16:45:18", shift: "B2", acuity: 6 },
  { date: "8/2/2019 16:48:52", shift: "C2", acuity: 6 },
  { date: "8/2/2019 16:58:13", shift: "A2", acuity: 6 },
  { date: "8/2/2019 16:58:47", shift: "D1", acuity: 6 },
  { date: "8/2/2019 17:00:52", shift: "C2", acuity: 7 },
  { date: "8/2/2019 17:01:18", shift: "C2", acuity: 3 },
  { date: "8/2/2019 17:16:38", shift: "B2", acuity: 3 },
  { date: "8/2/2019 17:50:11", shift: "A2", acuity: 3 },
  { date: "8/2/2019 17:50:39", shift: "D1", acuity: 6 },
  { date: "8/2/2019 17:51:16", shift: "C2", acuity: 3 },
  { date: "8/2/2019 18:20:03", shift: "D2", acuity: 6 },
  { date: "8/2/2019 18:25:06", shift: "B2", acuity: 6 },
  { date: "8/2/2019 18:25:50", shift: "A2", acuity: 6 },
  { date: "8/2/2019 18:36:55", shift: "D2", acuity: 6 },
  { date: "8/2/2019 18:50:16", shift: "D2", acuity: 6 },
  { date: "8/2/2019 18:50:40", shift: "C2", acuity: 3 },
  { date: "8/2/2019 18:55:37", shift: "B2", acuity: 6 },
  { date: "8/2/2019 19:06:40", shift: "A2", acuity: 3 },
  { date: "8/2/2019 19:34:07", shift: "D2", acuity: 6 },
  { date: "8/2/2019 19:41:47", shift: "C2", acuity: 3 },
  { date: "8/2/2019 19:50:58", shift: "B2", acuity: 3 },
  { date: "8/2/2019 19:52:50", shift: "A2", acuity: 6 },
  { date: "8/2/2019 20:01:53", shift: "D2", acuity: 3 },
  { date: "8/2/2019 20:05:52", shift: "C2", acuity: 3 },
  { date: "8/2/2019 20:07:53", shift: "B2", acuity: 3 },
  { date: "8/2/2019 20:13:29", shift: "A2", acuity: 3 },
  { date: "8/2/2019 20:20:04", shift: "D2", acuity: 3 },
  { date: "8/2/2019 20:20:23", shift: "C2", acuity: 3 },
  { date: "8/2/2019 20:26:51", shift: "B2", acuity: 4 },
  { date: "8/2/2019 20:37:08", shift: "A2", acuity: 6 },
  { date: "8/2/2019 20:47:48", shift: "D2", acuity: 6 },
  { date: "8/2/2019 20:48:16", shift: "C2", acuity: 6 },
  { date: "8/2/2019 21:05:15", shift: "A3", acuity: 3 },
  { date: "8/2/2019 21:14:53", shift: "B2", acuity: 3 },
  { date: "8/2/2019 21:23:30", shift: "D2", acuity: 3 },
  { date: "8/2/2019 21:23:45", shift: "C2", acuity: 3 },
  { date: "8/2/2019 21:55:01", shift: "A3", acuity: 6 },
  { date: "8/2/2019 21:55:37", shift: "A3", acuity: 3 },
  { date: "8/2/2019 22:12:43", shift: "B3", acuity: 6 },
  { date: "8/2/2019 22:23:29", shift: "D2", acuity: 3 },
  { date: "8/2/2019 23:00:06", shift: "B3", acuity: 6 },
  { date: "8/2/2019 23:05:32", shift: "B3", acuity: 3 },
  { date: "8/2/2019 23:21:09", shift: "C2", acuity: 3 },
  { date: "8/2/2019 23:27:45", shift: "A3", acuity: 3 },
  { date: "8/2/2019 23:36:29", shift: "D2", acuity: 6 },
  { date: "8/2/2019 23:38:56", shift: "B3", acuity: 3 },
  { date: "8/2/2019 23:40:39", shift: "C2", acuity: 3 },
  { date: "8/2/2019 23:55:40", shift: "A3", acuity: 3 },
  { date: "8/3/2019 00:00:00", shift: "D2", acuity: 4 },
  { date: "8/3/2019 00:05:00", shift: "B3", acuity: 4 },
  { date: "8/3/2019 00:16:12", shift: "A3", acuity: 3 },
  { date: "8/3/2019 00:24:51", shift: "D2", acuity: 6 },
  { date: "8/3/2019 00:29:47", shift: "B3", acuity: 3 },
  { date: "8/3/2019 00:34:42", shift: "A3", acuity: 3 },
  { date: "8/3/2019 00:56:32", shift: "D2", acuity: 3 },
  { date: "8/3/2019 01:05:52", shift: "B3", acuity: 4 },
  { date: "8/3/2019 01:20:41", shift: "A3", acuity: 4 },
  { date: "8/3/2019 01:21:07", shift: "D2", acuity: 3 },
  { date: "8/3/2019 01:55:18", shift: "B3", acuity: 4 },
  { date: "8/3/2019 02:05:12", shift: "A3", acuity: 3 },
  { date: "8/3/2019 02:17:25", shift: "B3", acuity: 4 },
  { date: "8/3/2019 02:42:00", shift: "A3", acuity: 3 },
  { date: "8/3/2019 03:08:05", shift: "B3", acuity: 6 },
  { date: "8/3/2019 03:46:58", shift: "A3", acuity: 4 },
  { date: "8/3/2019 04:01:10", shift: "B3", acuity: 3 },
  { date: "8/3/2019 04:10:56", shift: "A3", acuity: 6 },
  { date: "8/3/2019 05:21:51", shift: "A1", acuity: 6 },
  { date: "8/3/2019 05:47:47", shift: "A1", acuity: 4 },
  { date: "8/3/2019 06:00:35", shift: "A1", acuity: 4 },
  { date: "8/3/2019 06:08:43", shift: "B1", acuity: 6 },
  { date: "8/3/2019 06:58:50", shift: "B1", acuity: 3 },
  { date: "8/3/2019 07:11:44", shift: "B1", acuity: 4 },
  { date: "8/3/2019 07:40:08", shift: "A1", acuity: 3 },
  { date: "8/3/2019 07:50:45", shift: "B1", acuity: 3 },
  { date: "8/3/2019 08:09:09", shift: "A1", acuity: 6 },
  { date: "8/3/2019 08:51:19", shift: "C1", acuity: 3 },
  { date: "8/3/2019 08:51:37", shift: "B1", acuity: 6 },
  { date: "8/3/2019 09:17:55", shift: "C1", acuity: 3 },
  { date: "8/3/2019 09:18:48", shift: "C1", acuity: 6 },
  { date: "8/3/2019 09:19:05", shift: "A1", acuity: 3 },
  { date: "8/3/2019 09:33:40", shift: "B1", acuity: 6 },
  { date: "8/3/2019 09:51:04", shift: "C1", acuity: 6 },
  { date: "8/3/2019 09:52:14", shift: "A1", acuity: 3 },
  { date: "8/3/2019 09:59:51", shift: "B1", acuity: 3 },
  { date: "8/3/2019 10:21:44", shift: "D1", acuity: 4 },
  { date: "8/3/2019 10:21:57", shift: "C1", acuity: 4 },
  { date: "8/3/2019 10:57:04", shift: "D1", acuity: 3 },
  { date: "8/3/2019 10:57:20", shift: "D1", acuity: 3 },
  { date: "8/3/2019 11:03:53", shift: "A1", acuity: 4 },
  { date: "8/3/2019 11:04:19", shift: "B1", acuity: 3 },
  { date: "8/3/2019 11:04:34", shift: "C1", acuity: 3 },
  { date: "8/3/2019 11:18:53", shift: "D1", acuity: 6 },
  { date: "8/3/2019 11:19:13", shift: "A1", acuity: 3 },
  { date: "8/3/2019 11:26:12", shift: "B1", acuity: 3 },
  { date: "8/3/2019 11:26:27", shift: "C1", acuity: 3 },
  { date: "8/3/2019 11:38:18", shift: "D1", acuity: 3 },
  { date: "8/3/2019 11:43:30", shift: "A1", acuity: 3 },
  { date: "8/3/2019 11:43:51", shift: "B1", acuity: 3 },
  { date: "8/3/2019 11:55:10", shift: "C1", acuity: 3 },
  { date: "8/3/2019 11:57:29", shift: "D1", acuity: 3 },
  { date: "8/3/2019 12:11:50", shift: "A1", acuity: 6 },
  { date: "8/3/2019 12:16:52", shift: "B1", acuity: 3 },
  { date: "8/3/2019 12:32:34", shift: "C1", acuity: 3 },
  { date: "8/3/2019 12:44:01", shift: "D1", acuity: 3 },
  { date: "8/3/2019 12:45:54", shift: "A1", acuity: 3 },
  { date: "8/3/2019 13:06:32", shift: "A2", acuity: 6 },
  { date: "8/3/2019 13:06:51", shift: "B1", acuity: 3 },
  { date: "8/3/2019 13:19:03", shift: "C1", acuity: 3 },
  { date: "8/3/2019 13:24:08", shift: "D1", acuity: 3 },
  { date: "8/3/2019 13:31:39", shift: "A2", acuity: 6 },
  { date: "8/3/2019 13:46:58", shift: "A2", acuity: 6 },
  { date: "8/3/2019 13:47:10", shift: "B1", acuity: 3 },
  { date: "8/3/2019 14:01:30", shift: "B2", acuity: 6 },
  { date: "8/3/2019 14:19:20", shift: "C1", acuity: 3 },
  { date: "8/3/2019 14:48:46", shift: "B2", acuity: 3 },
  { date: "8/3/2019 14:49:07", shift: "B2", acuity: 6 },
  { date: "8/3/2019 14:49:32", shift: "D1", acuity: 6 },
  { date: "8/3/2019 14:49:50", shift: "A2", acuity: 3 },
  { date: "8/3/2019 14:52:11", shift: "C1", acuity: 3 },
  { date: "8/3/2019 14:58:20", shift: "B2", acuity: 3 },
  { date: "8/3/2019 15:17:15", shift: "D1", acuity: 3 },
  { date: "8/3/2019 15:17:37", shift: "A2", acuity: 6 },
  { date: "8/3/2019 15:17:52", shift: "C1", acuity: 6 },
  { date: "8/3/2019 15:22:36", shift: "B2", acuity: 3 },
  { date: "8/3/2019 15:49:20", shift: "D1", acuity: 6 },
  { date: "8/3/2019 15:50:24", shift: "A2", acuity: 3 },
  { date: "8/3/2019 15:51:35", shift: "C1", acuity: 3 },
  { date: "8/3/2019 15:58:44", shift: "B2", acuity: 3 },
  { date: "8/3/2019 16:22:01", shift: "D1", acuity: 3 },
  { date: "8/3/2019 16:32:48", shift: "C2", acuity: 2 },
  { date: "8/3/2019 16:35:03", shift: "A2", acuity: 6 },
  { date: "8/3/2019 16:52:56", shift: "B2", acuity: 6 },
  { date: "8/3/2019 17:00:38", shift: "C2", acuity: 6 },
  { date: "8/3/2019 17:07:26", shift: "C2", acuity: 6 },
  { date: "8/3/2019 17:26:48", shift: "D1", acuity: 3 },
  { date: "8/3/2019 17:38:35", shift: "A2", acuity: 3 },
  { date: "8/3/2019 18:18:17", shift: "D2", acuity: 3 },
  { date: "8/3/2019 18:18:44", shift: "B2", acuity: 3 },
  { date: "8/3/2019 18:19:06", shift: "C2", acuity: 6 },
  { date: "8/3/2019 18:19:21", shift: "A2", acuity: 3 },
  { date: "8/3/2019 18:41:22", shift: "D2", acuity: 3 },
  { date: "8/3/2019 18:41:50", shift: "D2", acuity: 3 },
  { date: "8/3/2019 18:42:15", shift: "B2", acuity: 3 },
  { date: "8/3/2019 18:43:35", shift: "C2", acuity: 3 },
  { date: "8/3/2019 18:48:21", shift: "A2", acuity: 3 },
  { date: "8/3/2019 18:52:41", shift: "D2", acuity: 3 },
  { date: "8/3/2019 18:55:47", shift: "B2", acuity: 3 },
  { date: "8/3/2019 19:18:13", shift: "C2", acuity: 7 },
  { date: "8/3/2019 19:22:22", shift: "A2", acuity: 3 },
  { date: "8/3/2019 19:23:19", shift: "D2", acuity: 6 },
  { date: "8/3/2019 19:36:55", shift: "B2", acuity: 6 },
  { date: "8/3/2019 19:39:35", shift: "C2", acuity: 6 },
  { date: "8/3/2019 19:53:19", shift: "A2", acuity: 6 },
  { date: "8/3/2019 19:54:15", shift: "D2", acuity: 6 },
  { date: "8/3/2019 19:56:24", shift: "B2", acuity: 3 },
  { date: "8/3/2019 20:10:57", shift: "C2", acuity: 6 },
  { date: "8/3/2019 20:20:04", shift: "A2", acuity: 3 },
  { date: "8/3/2019 20:36:08", shift: "D2", acuity: 6 },
  { date: "8/3/2019 20:36:30", shift: "B2", acuity: 3 },
  { date: "8/3/2019 20:46:27", shift: "C2", acuity: 3 },
  { date: "8/3/2019 20:53:05", shift: "A2", acuity: 3 },
  { date: "8/3/2019 21:00:33", shift: "A3", acuity: 3 },
  { date: "8/3/2019 21:01:45", shift: "D2", acuity: 3 },
  { date: "8/3/2019 21:13:23", shift: "B2", acuity: 3 },
  { date: "8/3/2019 21:13:43", shift: "C2", acuity: 3 },
  { date: "8/3/2019 21:16:35", shift: "A3", acuity: 3 },
  { date: "8/3/2019 21:17:00", shift: "D2", acuity: 3 },
  { date: "8/3/2019 21:35:01", shift: "A3", acuity: 3 },
  { date: "8/3/2019 21:39:09", shift: "A3", acuity: 3 },
  { date: "8/3/2019 21:51:57", shift: "B2", acuity: 3 },
  { date: "8/3/2019 21:53:58", shift: "C2", acuity: 3 },
  { date: "8/3/2019 22:12:30", shift: "B3", acuity: 6 },
  { date: "8/3/2019 22:38:22", shift: "B3", acuity: 6 },
  { date: "8/3/2019 22:51:34", shift: "B3", acuity: 3 },
  { date: "8/3/2019 22:57:08", shift: "D2", acuity: 3 },
  { date: "8/3/2019 22:57:34", shift: "A3", acuity: 3 },
  { date: "8/3/2019 22:58:02", shift: "C2", acuity: 6 },
  { date: "8/3/2019 23:10:00", shift: "B3", acuity: 3 },
  { date: "8/3/2019 23:26:13", shift: "D2", acuity: 3 },
  { date: "8/3/2019 23:26:30", shift: "A3", acuity: 3 },
  { date: "8/4/2019 00:04:27", shift: "B3", acuity: 6 },
  { date: "8/4/2019 00:10:45", shift: "D2", acuity: 6 },
  { date: "8/4/2019 00:15:10", shift: "A3", acuity: 4 },
  { date: "8/4/2019 00:16:13", shift: "B3", acuity: 6 },
  { date: "8/4/2019 00:32:44", shift: "D2", acuity: 3 },
  { date: "8/4/2019 00:58:32", shift: "A3", acuity: 4 },
  { date: "8/4/2019 00:58:49", shift: "B3", acuity: 4 },
  { date: "8/4/2019 00:59:08", shift: "D2", acuity: 4 },
  { date: "8/4/2019 00:59:29", shift: "A3", acuity: 3 },
  { date: "8/4/2019 01:10:45", shift: "B3", acuity: 3 },
  { date: "8/4/2019 01:30:12", shift: "A3", acuity: 4 },
  { date: "8/4/2019 01:30:36", shift: "B3", acuity: 4 },
  { date: "8/4/2019 01:31:01", shift: "A3", acuity: 3 },
  { date: "8/4/2019 01:40:20", shift: "B3", acuity: 3 },
  { date: "8/4/2019 01:58:37", shift: "A3", acuity: 4 },
  { date: "8/4/2019 02:24:40", shift: "B3", acuity: 3 },
  { date: "8/4/2019 02:32:39", shift: "A3", acuity: 3 },
  { date: "8/4/2019 02:35:28", shift: "B3", acuity: 4 },
  { date: "8/4/2019 02:42:27", shift: "A3", acuity: 3 },
  { date: "8/4/2019 02:47:35", shift: "B3", acuity: 3 },
  { date: "8/4/2019 03:13:03", shift: "A3", acuity: 3 },
  { date: "8/4/2019 03:18:55", shift: "B3", acuity: 3 },
  { date: "8/4/2019 03:24:18", shift: "A3", acuity: 3 },
  { date: "8/4/2019 03:38:37", shift: "B3", acuity: 4 },
  { date: "8/4/2019 03:47:08", shift: "A3", acuity: 3 },
  { date: "8/4/2019 04:10:26", shift: "B3", acuity: 6 },
  { date: "8/4/2019 04:17:25", shift: "A3", acuity: 3 },
  { date: "8/4/2019 04:36:59", shift: "B3", acuity: 4 },
  { date: "8/4/2019 04:40:22", shift: "A3", acuity: 3 },
  { date: "8/4/2019 05:19:09", shift: "A1", acuity: 4 },
  { date: "8/4/2019 05:21:36", shift: "B3", acuity: 3 },
  { date: "8/4/2019 05:25:29", shift: "A1", acuity: 3 },
  { date: "8/4/2019 05:55:35", shift: "A1", acuity: 3 },
  { date: "8/4/2019 05:56:05", shift: "A1", acuity: 4 },
  { date: "8/4/2019 06:29:15", shift: "B1", acuity: 4 },
  { date: "8/4/2019 06:46:15", shift: "B1", acuity: 6 },
  { date: "8/4/2019 06:46:41", shift: "B1", acuity: 3 },
  { date: "8/4/2019 07:10:11", shift: "A1", acuity: 6 },
  { date: "8/4/2019 07:26:29", shift: "B1", acuity: 4 },
  { date: "8/4/2019 07:26:45", shift: "A1", acuity: 6 },
  { date: "8/4/2019 07:28:14", shift: "B1", acuity: 3 },
  { date: "8/4/2019 07:53:55", shift: "A1", acuity: 6 },
  { date: "8/4/2019 08:13:08", shift: "B1", acuity: 4 },
  { date: "8/4/2019 08:27:28", shift: "A1", acuity: 3 },
  { date: "8/4/2019 08:36:05", shift: "C1", acuity: 3 },
  { date: "8/4/2019 08:51:03", shift: "B1", acuity: 3 },
  { date: "8/4/2019 08:51:23", shift: "A1", acuity: 6 },
  { date: "8/4/2019 09:11:41", shift: "C1", acuity: 4 },
  { date: "8/4/2019 09:11:58", shift: "C1", acuity: 3 },
  { date: "8/4/2019 09:12:26", shift: "B1", acuity: 3 },
  { date: "8/4/2019 09:15:07", shift: "A1", acuity: 6 },
  { date: "8/4/2019 09:21:44", shift: "C1", acuity: 3 },
  { date: "8/4/2019 09:44:15", shift: "B1", acuity: 3 },
  { date: "8/4/2019 09:46:46", shift: "A1", acuity: 3 },
  { date: "8/4/2019 09:48:54", shift: "C1", acuity: 2 },
  { date: "8/4/2019 09:51:27", shift: "B1", acuity: 4 },
  { date: "8/4/2019 09:51:46", shift: "A1", acuity: 3 },
  { date: "8/4/2019 10:07:05", shift: "D1", acuity: 3 },
  { date: "8/4/2019 10:23:19", shift: "C1", acuity: 3 },
  { date: "8/4/2019 10:30:15", shift: "D1", acuity: 6 },
  { date: "8/4/2019 10:48:04", shift: "D1", acuity: 6 },
  { date: "8/4/2019 10:51:01", shift: "B1", acuity: 3 },
  { date: "8/4/2019 10:58:28", shift: "A1", acuity: 4 },
  { date: "8/4/2019 11:21:33", shift: "C1", acuity: 6 },
  { date: "8/4/2019 11:33:24", shift: "D1", acuity: 3 },
  { date: "8/4/2019 11:51:03", shift: "B1", acuity: 3 },
  { date: "8/4/2019 11:51:20", shift: "A1", acuity: 3 },
  { date: "8/4/2019 12:09:29", shift: "C1", acuity: 3 },
  { date: "8/4/2019 12:29:31", shift: "D1", acuity: 3 },
  { date: "8/4/2019 12:31:28", shift: "B1", acuity: 3 },
  { date: "8/4/2019 12:47:40", shift: "A1", acuity: 3 },
  { date: "8/4/2019 12:53:02", shift: "C1", acuity: 3 },
  { date: "8/4/2019 12:56:03", shift: "D1", acuity: 6 },
  { date: "8/4/2019 13:18:40", shift: "A2", acuity: 6 },
  { date: "8/4/2019 13:20:05", shift: "B1", acuity: 3 },
  { date: "8/4/2019 13:22:28", shift: "C1", acuity: 3 },
  { date: "8/4/2019 13:30:37", shift: "A2", acuity: 6 },
  { date: "8/4/2019 13:33:29", shift: "A2", acuity: 3 },
  { date: "8/4/2019 13:45:24", shift: "D1", acuity: 6 },
  { date: "8/4/2019 14:03:02", shift: "B2", acuity: 6 },
  { date: "8/4/2019 14:06:05", shift: "C1", acuity: 6 },
  { date: "8/4/2019 14:23:21", shift: "A2", acuity: 6 },
  { date: "8/4/2019 14:23:45", shift: "D1", acuity: 6 },
  { date: "8/4/2019 14:33:45", shift: "B2", acuity: 4 },
  { date: "8/4/2019 14:41:12", shift: "B2", acuity: 6 },
  { date: "8/4/2019 14:41:27", shift: "C1", acuity: 3 },
  { date: "8/4/2019 14:41:54", shift: "A2", acuity: 6 },
  { date: "8/4/2019 14:58:22", shift: "D1", acuity: 6 },
  { date: "8/4/2019 14:58:41", shift: "B2", acuity: 3 },
  { date: "8/4/2019 15:13:57", shift: "C1", acuity: 3 },
  { date: "8/4/2019 15:14:24", shift: "A2", acuity: 3 },
  { date: "8/4/2019 15:58:48", shift: "D1", acuity: 6 },
  { date: "8/4/2019 16:11:01", shift: "B2", acuity: 6 },
  { date: "8/4/2019 16:11:20", shift: "C1", acuity: 6 },
  { date: "8/4/2019 16:11:40", shift: "A2", acuity: 3 },
  { date: "8/4/2019 16:25:09", shift: "D1", acuity: 3 },
  { date: "8/4/2019 16:26:03", shift: "B2", acuity: 3 },
  { date: "8/4/2019 16:33:14", shift: "C2", acuity: 3 },
  { date: "8/4/2019 16:58:33", shift: "A2", acuity: 6 },
  { date: "8/4/2019 17:00:07", shift: "C2", acuity: 6 },
  { date: "8/4/2019 17:00:21", shift: "C2", acuity: 3 },
  { date: "8/4/2019 17:06:51", shift: "D1", acuity: 6 },
  { date: "8/4/2019 17:22:55", shift: "B2", acuity: 6 },
  { date: "8/4/2019 17:23:27", shift: "A2", acuity: 3 },
  { date: "8/4/2019 17:29:17", shift: "C2", acuity: 3 },
  { date: "8/4/2019 17:39:35", shift: "D1", acuity: 6 },
  { date: "8/4/2019 17:46:45", shift: "B2", acuity: 3 },
  { date: "8/4/2019 17:46:58", shift: "A2", acuity: 3 },
  { date: "8/4/2019 18:05:00", shift: "D2", acuity: 6 },
  { date: "8/4/2019 18:20:47", shift: "C2", acuity: 6 },
  { date: "8/4/2019 18:21:09", shift: "B2", acuity: 6 },
  { date: "8/4/2019 18:21:30", shift: "A2", acuity: 6 },
  { date: "8/4/2019 18:24:48", shift: "D2", acuity: 3 },
  { date: "8/4/2019 18:30:25", shift: "D2", acuity: 3 },
  { date: "8/4/2019 18:30:41", shift: "D2", acuity: 6 },
  { date: "8/4/2019 18:41:00", shift: "C2", acuity: 6 },
  { date: "8/4/2019 18:53:23", shift: "B2", acuity: 6 },
  { date: "8/4/2019 19:13:47", shift: "A2", acuity: 3 },
  { date: "8/4/2019 19:21:47", shift: "D2", acuity: 6 },
  { date: "8/4/2019 19:31:00", shift: "C2", acuity: 3 },
  { date: "8/4/2019 19:39:00", shift: "B2", acuity: 6 },
  { date: "8/4/2019 19:49:58", shift: "A2", acuity: 3 },
  { date: "8/4/2019 19:55:22", shift: "D2", acuity: 6 },
  { date: "8/4/2019 20:01:53", shift: "C2", acuity: 3 },
  { date: "8/4/2019 20:22:46", shift: "B2", acuity: 6 },
  { date: "8/4/2019 20:23:25", shift: "A2", acuity: 6 },
  { date: "8/4/2019 20:24:22", shift: "D2", acuity: 6 },
  { date: "8/4/2019 20:28:06", shift: "C2", acuity: 3 },
  { date: "8/4/2019 20:44:06", shift: "B2", acuity: 6 },
  { date: "8/4/2019 20:59:33", shift: "A2", acuity: 6 },
  { date: "8/4/2019 21:01:08", shift: "A3", acuity: 3 },
  { date: "8/4/2019 21:11:21", shift: "D2", acuity: 3 },
  { date: "8/4/2019 21:20:56", shift: "C2", acuity: 3 },
  { date: "8/4/2019 21:21:07", shift: "B2", acuity: 3 },
  { date: "8/4/2019 21:37:48", shift: "A3", acuity: 3 },
  { date: "8/4/2019 21:40:15", shift: "A3", acuity: 3 },
  { date: "8/4/2019 21:41:08", shift: "D2", acuity: 6 },
  { date: "8/4/2019 21:41:25", shift: "C2", acuity: 5 },
  { date: "8/4/2019 21:42:38", shift: "B2", acuity: 3 },
  { date: "8/4/2019 22:02:43", shift: "B3", acuity: 3 },
  { date: "8/4/2019 22:02:59", shift: "A3", acuity: 6 },
  { date: "8/4/2019 22:13:56", shift: "D2", acuity: 6 },
  { date: "8/4/2019 22:25:58", shift: "C2", acuity: 3 },
  { date: "8/4/2019 22:33:00", shift: "B3", acuity: 6 },
  { date: "8/4/2019 22:33:17", shift: "B3", acuity: 6 },
  { date: "8/4/2019 22:36:42", shift: "A3", acuity: 6 },
  { date: "8/4/2019 22:51:38", shift: "D2", acuity: 6 },
  { date: "8/4/2019 22:52:18", shift: "C2", acuity: 6 },
  { date: "8/4/2019 23:03:04", shift: "B3", acuity: 6 },
  { date: "8/4/2019 23:24:53", shift: "A3", acuity: 3 },
  { date: "8/4/2019 23:25:09", shift: "D2", acuity: 3 },
  { date: "8/4/2019 23:31:56", shift: "C2", acuity: 4 },
  { date: "8/4/2019 23:32:19", shift: "B3", acuity: 3 },
  { date: "8/4/2019 23:39:21", shift: "A3", acuity: 3 },
  { date: "8/4/2019 23:40:08", shift: "D2", acuity: 4 },
  { date: "8/5/2019 00:01:13", shift: "B3", acuity: 4 },
  { date: "8/5/2019 00:03:55", shift: "A3", acuity: 6 },
  { date: "8/5/2019 00:04:10", shift: "D2", acuity: 4 },
  { date: "8/5/2019 00:12:23", shift: "B3", acuity: 4 },
  { date: "8/5/2019 00:25:26", shift: "A3", acuity: 4 },
  { date: "8/5/2019 00:25:41", shift: "D2", acuity: 3 },
  { date: "8/5/2019 00:51:54", shift: "B3", acuity: 4 },
  { date: "8/5/2019 00:52:09", shift: "A3", acuity: 3 },
  { date: "8/5/2019 00:56:19", shift: "D2", acuity: 6 },
  { date: "8/5/2019 01:11:42", shift: "B3", acuity: 3 },
  { date: "8/5/2019 01:11:58", shift: "A3", acuity: 4 },
  { date: "8/5/2019 01:33:14", shift: "B3", acuity: 3 },
  { date: "8/5/2019 01:55:51", shift: "A3", acuity: 3 },
  { date: "8/5/2019 01:56:02", shift: "B3", acuity: 3 },
  { date: "8/5/2019 02:04:35", shift: "A3", acuity: 4 },
  { date: "8/5/2019 02:05:04", shift: "B3", acuity: 6 },
  { date: "8/5/2019 02:26:06", shift: "A3", acuity: 3 },
  { date: "8/5/2019 02:30:39", shift: "B3", acuity: 4 },
  { date: "8/5/2019 02:30:53", shift: "A3", acuity: 4 },
  { date: "8/5/2019 02:39:08", shift: "B3", acuity: 4 },
  { date: "8/5/2019 02:51:43", shift: "A3", acuity: 3 },
  { date: "8/5/2019 02:59:21", shift: "B3", acuity: 4 },
  { date: "8/5/2019 03:11:28", shift: "A3", acuity: 3 },
  { date: "8/5/2019 03:11:51", shift: "B3", acuity: 3 },
  { date: "8/5/2019 03:38:47", shift: "A3", acuity: 6 },
  { date: "8/5/2019 05:23:49", shift: "A1", acuity: 3 },
  { date: "8/5/2019 05:50:04", shift: "A1", acuity: 6 },
  { date: "8/5/2019 06:05:18", shift: "A1", acuity: 4 },
  { date: "8/5/2019 06:41:46", shift: "B1", acuity: 6 },
  { date: "8/5/2019 07:36:13", shift: "B1", acuity: 6 },
  { date: "8/5/2019 07:36:30", shift: "B1", acuity: 4 },
  { date: "8/5/2019 07:48:05", shift: "A1", acuity: 3 },
  { date: "8/5/2019 07:48:26", shift: "B1", acuity: 3 },
  { date: "8/5/2019 08:04:24", shift: "A1", acuity: 6 },
  { date: "8/5/2019 08:12:40", shift: "B1", acuity: 4 },
  { date: "8/5/2019 08:28:27", shift: "A1", acuity: 3 },
  { date: "8/5/2019 08:39:18", shift: "C1", acuity: 5 },
  { date: "8/5/2019 08:51:56", shift: "B1", acuity: 6 },
  { date: "8/5/2019 09:05:32", shift: "C1", acuity: 4 },
  { date: "8/5/2019 09:07:24", shift: "C1", acuity: 3 },
  { date: "8/5/2019 09:17:34", shift: "A1", acuity: 3 },
  { date: "8/5/2019 09:30:52", shift: "B1", acuity: 6 },
  { date: "8/5/2019 09:33:06", shift: "C1", acuity: 4 },
  { date: "8/5/2019 09:38:02", shift: "A1", acuity: 3 },
  { date: "8/5/2019 09:42:58", shift: "B1", acuity: 3 },
  { date: "8/5/2019 10:22:16", shift: "D1", acuity: 3 },
  { date: "8/5/2019 10:37:30", shift: "D1", acuity: 4 },
  { date: "8/5/2019 10:37:47", shift: "D1", acuity: 4 },
  { date: "8/5/2019 10:44:14", shift: "C1", acuity: 3 },
  { date: "8/5/2019 10:52:12", shift: "A1", acuity: 3 },
  { date: "8/5/2019 11:06:57", shift: "B1", acuity: 3 },
  { date: "8/5/2019 11:12:09", shift: "D1", acuity: 3 },
  { date: "8/5/2019 11:32:23", shift: "C1", acuity: 3 },
  { date: "8/5/2019 11:36:43", shift: "A1", acuity: 6 },
  { date: "8/5/2019 11:45:43", shift: "B1", acuity: 3 },
  { date: "8/5/2019 11:48:46", shift: "D1", acuity: 3 },
  { date: "8/5/2019 12:07:58", shift: "C1", acuity: 7 },
  { date: "8/5/2019 12:34:18", shift: "A1", acuity: 7 },
  { date: "8/5/2019 12:41:57", shift: "B1", acuity: 7 },
  { date: "8/5/2019 12:42:09", shift: "D1", acuity: 3 },
  { date: "8/5/2019 12:49:51", shift: "C1", acuity: 3 },
  { date: "8/5/2019 12:58:17", shift: "A1", acuity: 3 },
  { date: "8/5/2019 13:06:31", shift: "A2", acuity: 3 },
  { date: "8/5/2019 13:45:25", shift: "A2", acuity: 3 },
  { date: "8/5/2019 13:45:36", shift: "A2", acuity: 3 },
  { date: "8/5/2019 13:45:51", shift: "B1", acuity: 3 },
  { date: "8/5/2019 14:05:00", shift: "B2", acuity: 3 },
  { date: "8/5/2019 14:05:06", shift: "D1", acuity: 4 },
  { date: "8/5/2019 14:09:55", shift: "C1", acuity: 3 },
  { date: "8/5/2019 14:30:18", shift: "B2", acuity: 3 },
  { date: "8/5/2019 14:30:42", shift: "B2", acuity: 3 },
  { date: "8/5/2019 14:30:57", shift: "A2", acuity: 6 },
  { date: "8/5/2019 14:48:24", shift: "D1", acuity: 4 },
  { date: "8/5/2019 14:53:24", shift: "C1", acuity: 6 },
  { date: "8/5/2019 15:30:58", shift: "B2", acuity: 4 },
  { date: "8/5/2019 15:49:47", shift: "A2", acuity: 7 },
  { date: "8/5/2019 16:04:03", shift: "D1", acuity: 3 },
  { date: "8/5/2019 16:04:14", shift: "C1", acuity: 4 },
  { date: "8/5/2019 16:12:38", shift: "B2", acuity: 7 },
  { date: "8/5/2019 16:13:50", shift: "A2", acuity: 3 },
  { date: "8/5/2019 16:19:40", shift: "D1", acuity: 6 },
  { date: "8/5/2019 16:21:24", shift: "C1", acuity: 3 },
  { date: "8/5/2019 16:28:16", shift: "B2", acuity: 6 },
  { date: "8/5/2019 16:28:36", shift: "A2", acuity: 3 },
  { date: "8/5/2019 16:37:05", shift: "C2", acuity: 4 },
  { date: "8/5/2019 16:49:15", shift: "D1", acuity: 6 },
  { date: "8/5/2019 16:50:03", shift: "B2", acuity: 7 },
  { date: "8/5/2019 17:00:09", shift: "C2", acuity: 3 },
  { date: "8/5/2019 17:20:29", shift: "C2", acuity: 3 },
  { date: "8/5/2019 17:20:43", shift: "A2", acuity: 3 },
  { date: "8/5/2019 17:37:31", shift: "D1", acuity: 3 },
  { date: "8/5/2019 17:44:54", shift: "B2", acuity: 3 },
  { date: "8/5/2019 17:50:58", shift: "C2", acuity: 7 },
  { date: "8/5/2019 17:52:39", shift: "A2", acuity: 7 },
  { date: "8/5/2019 18:00:37", shift: "D2", acuity: 3 },
  { date: "8/5/2019 18:15:47", shift: "B2", acuity: 6 },
  { date: "8/5/2019 18:15:57", shift: "C2", acuity: 3 },
  { date: "8/5/2019 18:16:12", shift: "A2", acuity: 6 },
  { date: "8/5/2019 18:44:02", shift: "D2", acuity: 4 },
  { date: "8/5/2019 18:46:51", shift: "D2", acuity: 7 },
  { date: "8/5/2019 19:08:40", shift: "B2", acuity: 3 },
  { date: "8/5/2019 19:08:49", shift: "C2", acuity: 3 },
  { date: "8/5/2019 19:09:02", shift: "A2", acuity: 7 },
  { date: "8/5/2019 19:32:00", shift: "D2", acuity: 3 },
  { date: "8/5/2019 19:37:17", shift: "B2", acuity: 3 },
  { date: "8/5/2019 19:37:39", shift: "C2", acuity: 3 },
  { date: "8/5/2019 19:45:45", shift: "A2", acuity: 7 },
  { date: "8/5/2019 19:51:24", shift: "D2", acuity: 4 },
  { date: "8/5/2019 19:51:40", shift: "B2", acuity: 3 },
  { date: "8/5/2019 19:52:00", shift: "C2", acuity: 3 },
  { date: "8/5/2019 20:11:34", shift: "A2", acuity: 6 },
  { date: "8/5/2019 20:14:00", shift: "D2", acuity: 6 },
  { date: "8/5/2019 20:20:13", shift: "B2", acuity: 2 },
  { date: "8/5/2019 20:25:55", shift: "C2", acuity: 3 },
  { date: "8/5/2019 20:41:40", shift: "A2", acuity: 3 },
  { date: "8/5/2019 21:06:19", shift: "A3", acuity: 3 },
  { date: "8/5/2019 21:08:50", shift: "D2", acuity: 4 },
  { date: "8/5/2019 21:10:13", shift: "B2", acuity: 6 },
  { date: "8/5/2019 21:21:49", shift: "C2", acuity: 3 },
  { date: "8/5/2019 21:32:32", shift: "A3", acuity: 3 },
  { date: "8/5/2019 21:38:29", shift: "A3", acuity: 3 },
  { date: "8/5/2019 21:54:26", shift: "D2", acuity: 4 },
  { date: "8/5/2019 22:08:13", shift: "B3", acuity: 6 },
  { date: "8/5/2019 22:11:48", shift: "C2", acuity: 3 },
  { date: "8/5/2019 22:14:17", shift: "A3", acuity: 3 },
  { date: "8/5/2019 22:24:00", shift: "D2", acuity: 3 },
  { date: "8/5/2019 22:28:06", shift: "B3", acuity: 3 },
  { date: "8/5/2019 22:32:46", shift: "B3", acuity: 4 },
  { date: "8/5/2019 22:41:50", shift: "B3", acuity: 3 },
  { date: "8/5/2019 22:56:28", shift: "C2", acuity: 3 },
  { date: "8/5/2019 23:16:40", shift: "A3", acuity: 6 },
  { date: "8/5/2019 23:33:33", shift: "D2", acuity: 3 },
  { date: "8/5/2019 23:33:49", shift: "B3", acuity: 3 },
  { date: "8/5/2019 23:39:53", shift: "C2", acuity: 3 },
  { date: "8/5/2019 23:48:49", shift: "A3", acuity: 3 },
  { date: "8/6/2019 00:07:26", shift: "D2", acuity: 3 },
  { date: "8/6/2019 00:07:41", shift: "B3", acuity: 3 },
  { date: "8/6/2019 00:24:12", shift: "A3", acuity: 3 },
  { date: "8/6/2019 00:24:32", shift: "D2", acuity: 3 },
  { date: "8/6/2019 00:30:31", shift: "B3", acuity: 3 },
  { date: "8/6/2019 01:17:01", shift: "A3", acuity: 3 },
  { date: "8/6/2019 01:17:14", shift: "D2", acuity: 4 },
  { date: "8/6/2019 01:19:04", shift: "B3", acuity: 6 },
  { date: "8/6/2019 01:19:18", shift: "A3", acuity: 4 },
  { date: "8/6/2019 01:20:51", shift: "D2", acuity: 6 },
  { date: "8/6/2019 01:29:41", shift: "B3", acuity: 3 },
  { date: "8/6/2019 02:50:07", shift: "A3", acuity: 4 },
  { date: "8/6/2019 02:53:07", shift: "B3", acuity: 2 },
  { date: "8/6/2019 03:18:02", shift: "A3", acuity: 3 },
  { date: "8/6/2019 03:19:57", shift: "B3", acuity: 6 },
  { date: "8/6/2019 03:35:14", shift: "A3", acuity: 3 },
  { date: "8/6/2019 03:37:00", shift: "B3", acuity: 4 },
  { date: "8/6/2019 03:45:49", shift: "A3", acuity: 6 },
  { date: "8/6/2019 04:23:28", shift: "B3", acuity: 3 },
  { date: "8/6/2019 04:24:12", shift: "A3", acuity: 4 },
  { date: "8/6/2019 04:33:22", shift: "B3", acuity: 6 },
  { date: "8/6/2019 05:00:33", shift: "A1", acuity: 6 },
  { date: "8/6/2019 05:02:38", shift: "B3", acuity: 3 },
  { date: "8/6/2019 05:30:51", shift: "A1", acuity: 6 },
  { date: "8/6/2019 05:31:08", shift: "A1", acuity: 4 },
  { date: "8/6/2019 05:31:22", shift: "B3", acuity: 4 },
  { date: "8/6/2019 05:51:51", shift: "A1", acuity: 6 },
  { date: "8/6/2019 06:28:50", shift: "B1", acuity: 3 },
  { date: "8/6/2019 06:34:26", shift: "B1", acuity: 6 },
  { date: "8/6/2019 06:38:43", shift: "B1", acuity: 4 },
  { date: "8/6/2019 06:52:30", shift: "A1", acuity: 6 },
  { date: "8/6/2019 06:57:35", shift: "B1", acuity: 4 },
  { date: "8/6/2019 07:22:44", shift: "A1", acuity: 7 },
  { date: "8/6/2019 07:53:27", shift: "B1", acuity: 4 },
  { date: "8/6/2019 08:17:02", shift: "A1", acuity: 3 },
  { date: "8/6/2019 08:17:19", shift: "B1", acuity: 3 },
  { date: "8/6/2019 08:39:21", shift: "C1", acuity: 3 },
  { date: "8/6/2019 08:43:43", shift: "A1", acuity: 3 },
  { date: "8/6/2019 08:44:35", shift: "B1", acuity: 3 },
  { date: "8/6/2019 08:51:33", shift: "C1", acuity: 6 },
  { date: "8/6/2019 09:07:08", shift: "C1", acuity: 3 },
  { date: "8/6/2019 09:40:00", shift: "C1", acuity: 3 },
  { date: "8/6/2019 09:40:16", shift: "A1", acuity: 3 },
  { date: "8/6/2019 09:41:40", shift: "B1", acuity: 6 },
  { date: "8/6/2019 09:52:48", shift: "C1", acuity: 4 },
  { date: "8/6/2019 09:53:04", shift: "A1", acuity: 3 },
  { date: "8/6/2019 09:56:40", shift: "B1", acuity: 6 },
  { date: "8/6/2019 10:10:37", shift: "D1", acuity: 6 },
  { date: "8/6/2019 10:11:24", shift: "C1", acuity: 3 },
  { date: "8/6/2019 10:22:54", shift: "A1", acuity: 6 },
  { date: "8/6/2019 10:26:08", shift: "B1", acuity: 6 },
  { date: "8/6/2019 10:41:22", shift: "D1", acuity: 3 },
  { date: "8/6/2019 10:44:31", shift: "D1", acuity: 3 },
  { date: "8/6/2019 10:58:01", shift: "C1", acuity: 4 },
  { date: "8/6/2019 11:01:58", shift: "A1", acuity: 3 },
  { date: "8/6/2019 11:07:08", shift: "B1", acuity: 6 },
  { date: "8/6/2019 11:20:30", shift: "D1", acuity: 3 },
  { date: "8/6/2019 11:35:16", shift: "C1", acuity: 7 },
  { date: "8/6/2019 11:42:04", shift: "A1", acuity: 3 },
  { date: "8/6/2019 11:47:06", shift: "B1", acuity: 3 },
  { date: "8/6/2019 11:51:33", shift: "D1", acuity: 6 },
  { date: "8/6/2019 11:57:21", shift: "C1", acuity: 7 },
  { date: "8/6/2019 12:06:51", shift: "A1", acuity: 6 },
  { date: "8/6/2019 12:07:14", shift: "B1", acuity: 7 },
  { date: "8/6/2019 12:19:54", shift: "D1", acuity: 3 },
  { date: "8/6/2019 12:20:11", shift: "C1", acuity: 3 },
  { date: "8/6/2019 12:26:14", shift: "A1", acuity: 6 },
  { date: "8/6/2019 12:54:40", shift: "B1", acuity: 6 },
  { date: "8/6/2019 12:59:23", shift: "D1", acuity: 6 },
  { date: "8/6/2019 13:10:32", shift: "A2", acuity: 6 },
  { date: "8/6/2019 13:11:04", shift: "C1", acuity: 7 },
  { date: "8/6/2019 13:19:08", shift: "B1", acuity: 3 },
  { date: "8/6/2019 13:38:29", shift: "A2", acuity: 3 },
  { date: "8/6/2019 13:47:26", shift: "A2", acuity: 6 },
  { date: "8/6/2019 13:51:17", shift: "D1", acuity: 6 },
  { date: "8/6/2019 14:08:40", shift: "B2", acuity: 3 },
  { date: "8/6/2019 14:09:18", shift: "C1", acuity: 6 },
  { date: "8/6/2019 14:26:27", shift: "A2", acuity: 4 },
  { date: "8/6/2019 14:44:38", shift: "B2", acuity: 3 },
  { date: "8/6/2019 14:47:18", shift: "B2", acuity: 6 },
  { date: "8/6/2019 14:50:42", shift: "D1", acuity: 6 },
  { date: "8/6/2019 14:55:13", shift: "C1", acuity: 3 },
  { date: "8/6/2019 15:04:13", shift: "A2", acuity: 3 },
  { date: "8/6/2019 15:14:07", shift: "B2", acuity: 6 },
  { date: "8/6/2019 15:25:40", shift: "D1", acuity: 6 },
  { date: "8/6/2019 15:27:13", shift: "C1", acuity: 6 },
  { date: "8/6/2019 16:08:02", shift: "A2", acuity: 6 },
  { date: "8/6/2019 16:20:57", shift: "B2", acuity: 3 },
  { date: "8/6/2019 16:29:21", shift: "D1", acuity: 3 },
  { date: "8/6/2019 16:42:07", shift: "C2", acuity: 3 },
  { date: "8/6/2019 16:42:36", shift: "A2", acuity: 6 },
  { date: "8/6/2019 16:51:02", shift: "B2", acuity: 4 },
  { date: "8/6/2019 16:57:50", shift: "D1", acuity: 6 },
  { date: "8/6/2019 17:12:13", shift: "C2", acuity: 3 },
  { date: "8/6/2019 17:12:47", shift: "C2", acuity: 3 },
  { date: "8/6/2019 17:13:22", shift: "A2", acuity: 3 },
  { date: "8/6/2019 17:24:40", shift: "B2", acuity: 3 },
  { date: "8/6/2019 17:25:11", shift: "D1", acuity: 6 },
  { date: "8/6/2019 17:40:54", shift: "C2", acuity: 3 },
  { date: "8/6/2019 17:48:33", shift: "A2", acuity: 4 },
  { date: "8/6/2019 17:53:17", shift: "B2", acuity: 3 },
  { date: "8/6/2019 18:00:42", shift: "D2", acuity: 3 },
  { date: "8/6/2019 18:06:21", shift: "C2", acuity: 3 },
  { date: "8/6/2019 18:08:11", shift: "A2", acuity: 6 },
  { date: "8/6/2019 18:13:39", shift: "B2", acuity: 3 },
  { date: "8/6/2019 18:32:04", shift: "D2", acuity: 6 },
  { date: "8/6/2019 18:42:38", shift: "D2", acuity: 3 },
  { date: "8/6/2019 18:48:45", shift: "C2", acuity: 7 },
  { date: "8/6/2019 18:54:41", shift: "A2", acuity: 6 },
  { date: "8/6/2019 19:11:14", shift: "B2", acuity: 7 },
  { date: "8/6/2019 19:12:45", shift: "D2", acuity: 7 },
  { date: "8/6/2019 19:23:39", shift: "C2", acuity: 7 },
  { date: "8/6/2019 19:25:14", shift: "A2", acuity: 6 },
  { date: "8/6/2019 19:42:55", shift: "B2", acuity: 3 },
  { date: "8/6/2019 19:43:06", shift: "D2", acuity: 3 },
  { date: "8/6/2019 19:45:22", shift: "C2", acuity: 6 },
  { date: "8/6/2019 19:55:56", shift: "A2", acuity: 6 },
  { date: "8/6/2019 20:01:55", shift: "B2", acuity: 7 },
  { date: "8/6/2019 20:18:59", shift: "D2", acuity: 6 },
  { date: "8/6/2019 20:26:07", shift: "C2", acuity: 3 },
  { date: "8/6/2019 20:26:36", shift: "A2", acuity: 7 },
  { date: "8/6/2019 20:46:36", shift: "B2", acuity: 3 },
  { date: "8/6/2019 20:48:16", shift: "D2", acuity: 6 },
  { date: "8/6/2019 21:01:27", shift: "A3", acuity: 7 },
  { date: "8/6/2019 21:02:02", shift: "C2", acuity: 6 },
  { date: "8/6/2019 21:02:24", shift: "B2", acuity: 3 },
  { date: "8/6/2019 21:13:03", shift: "D2", acuity: 4 },
  { date: "8/6/2019 21:22:29", shift: "A3", acuity: 3 },
  { date: "8/6/2019 21:22:55", shift: "C2", acuity: 6 },
  { date: "8/6/2019 21:30:01", shift: "A3", acuity: 6 },
  { date: "8/6/2019 21:33:11", shift: "A3", acuity: 6 },
  { date: "8/6/2019 21:43:45", shift: "B2", acuity: 6 },
  { date: "8/6/2019 21:44:03", shift: "D2", acuity: 6 },
  { date: "8/6/2019 21:46:25", shift: "C2", acuity: 6 },
  { date: "8/6/2019 22:00:56", shift: "B3", acuity: 3 },
  { date: "8/6/2019 22:02:00", shift: "A3", acuity: 6 },
  { date: "8/6/2019 22:10:34", shift: "D2", acuity: 6 },
  { date: "8/6/2019 22:12:39", shift: "C2", acuity: 3 },
  { date: "8/6/2019 22:34:38", shift: "B3", acuity: 3 },
  { date: "8/6/2019 22:37:20", shift: "B3", acuity: 3 },
  { date: "8/6/2019 23:25:54", shift: "A3", acuity: 3 },
  { date: "8/6/2019 23:26:10", shift: "D2", acuity: 6 },
  { date: "8/6/2019 23:26:28", shift: "C2", acuity: 6 },
  { date: "8/6/2019 23:26:41", shift: "B3", acuity: 6 },
  { date: "8/6/2019 23:41:25", shift: "A3", acuity: 6 },
  { date: "8/6/2019 23:41:39", shift: "D2", acuity: 3 },
  { date: "8/6/2019 23:54:55", shift: "C2", acuity: 3 },
  { date: "8/6/2019 23:55:10", shift: "B3", acuity: 3 },
  { date: "8/6/2019 23:59:17", shift: "A3", acuity: 3 },
  { date: "8/7/2019 00:13:58", shift: "D2", acuity: 4 },
  { date: "8/7/2019 00:15:23", shift: "B3", acuity: 3 },
  { date: "8/7/2019 00:20:35", shift: "A3", acuity: 3 },
  { date: "8/7/2019 00:40:39", shift: "D2", acuity: 3 },
  { date: "8/7/2019 00:46:23", shift: "B3", acuity: 4 },
  { date: "8/7/2019 00:48:28", shift: "A3", acuity: 3 },
  { date: "8/7/2019 01:09:28", shift: "D2", acuity: 3 },
  { date: "8/7/2019 01:11:05", shift: "B3", acuity: 6 },
  { date: "8/7/2019 01:19:44", shift: "A3", acuity: 4 },
  { date: "8/7/2019 01:51:12", shift: "B3", acuity: 3 },
  { date: "8/7/2019 02:08:37", shift: "A3", acuity: 4 },
  { date: "8/7/2019 02:24:53", shift: "B3", acuity: 6 },
  { date: "8/7/2019 02:51:32", shift: "A3", acuity: 3 },
  { date: "8/7/2019 03:11:44", shift: "B3", acuity: 4 },
  { date: "8/7/2019 03:12:00", shift: "A3", acuity: 4 },
  { date: "8/7/2019 03:44:19", shift: "B3", acuity: 4 },
  { date: "8/7/2019 03:57:36", shift: "A3", acuity: 4 },
  { date: "8/7/2019 04:15:16", shift: "B3", acuity: 3 },
  { date: "8/7/2019 04:16:36", shift: "A3", acuity: 6 },
  { date: "8/7/2019 04:31:03", shift: "B3", acuity: 3 },
  { date: "8/7/2019 04:53:12", shift: "A3", acuity: 3 },
  { date: "8/7/2019 05:03:17", shift: "A1", acuity: 6 },
  { date: "8/7/2019 05:04:50", shift: "B3", acuity: 3 },
  { date: "8/7/2019 05:20:15", shift: "A1", acuity: 3 },
  { date: "8/7/2019 05:24:08", shift: "B3", acuity: 3 },
  { date: "8/7/2019 05:34:22", shift: "A1", acuity: 3 },
  { date: "8/7/2019 05:55:16", shift: "A1", acuity: 3 },
  { date: "8/7/2019 05:55:50", shift: "B3", acuity: 3 },
  { date: "8/7/2019 06:10:45", shift: "B1", acuity: 3 },
  { date: "8/7/2019 06:11:00", shift: "A1", acuity: 4 },
  { date: "8/7/2019 06:44:37", shift: "B1", acuity: 3 },
  { date: "8/7/2019 06:45:32", shift: "B1", acuity: 3 },
  { date: "8/7/2019 07:03:12", shift: "A1", acuity: 3 },
  { date: "8/7/2019 07:28:03", shift: "B1", acuity: 3 },
  { date: "8/7/2019 07:47:24", shift: "A1", acuity: 3 },
  { date: "8/7/2019 08:07:10", shift: "B1", acuity: 6 },
  { date: "8/7/2019 08:17:05", shift: "A1", acuity: 6 },
  { date: "8/7/2019 08:17:21", shift: "B1", acuity: 4 },
  { date: "8/7/2019 08:40:07", shift: "C1", acuity: 3 },
  { date: "8/7/2019 09:09:20", shift: "C1", acuity: 6 },
  { date: "8/7/2019 09:21:06", shift: "C1", acuity: 3 },
  { date: "8/7/2019 09:26:11", shift: "A1", acuity: 6 },
  { date: "8/7/2019 09:32:33", shift: "B1", acuity: 6 },
  { date: "8/7/2019 09:34:14", shift: "C1", acuity: 4 },
  { date: "8/7/2019 09:35:01", shift: "A1", acuity: 3 },
  { date: "8/7/2019 09:42:46", shift: "B1", acuity: 4 },
  { date: "8/7/2019 09:56:25", shift: "C1", acuity: 3 },
  { date: "8/7/2019 09:58:36", shift: "A1", acuity: 3 },
  { date: "8/7/2019 10:01:42", shift: "D1", acuity: 3 },
  { date: "8/7/2019 10:06:30", shift: "B1", acuity: 4 },
  { date: "8/7/2019 10:12:05", shift: "C1", acuity: 3 },
  { date: "8/7/2019 10:42:07", shift: "D1", acuity: 3 },
  { date: "8/7/2019 10:42:15", shift: "D1", acuity: 3 },
  { date: "8/7/2019 10:54:22", shift: "A1", acuity: 3 },
  { date: "8/7/2019 10:59:52", shift: "B1", acuity: 6 },
  { date: "8/7/2019 11:18:17", shift: "C1", acuity: 3 },
  { date: "8/7/2019 11:29:15", shift: "D1", acuity: 3 },
  { date: "8/7/2019 11:41:39", shift: "A1", acuity: 6 },
  { date: "8/7/2019 11:43:20", shift: "B1", acuity: 6 },
  { date: "8/7/2019 11:59:50", shift: "C1", acuity: 3 },
  { date: "8/7/2019 12:16:03", shift: "D1", acuity: 3 },
  { date: "8/7/2019 12:19:18", shift: "A1", acuity: 6 },
  { date: "8/7/2019 12:24:35", shift: "B1", acuity: 3 },
  { date: "8/7/2019 12:41:31", shift: "C1", acuity: 6 },
  { date: "8/7/2019 12:49:10", shift: "D1", acuity: 4 },
  { date: "8/7/2019 12:49:17", shift: "A1", acuity: 3 },
  { date: "8/7/2019 12:53:39", shift: "B1", acuity: 3 },
  { date: "8/7/2019 12:53:51", shift: "C1", acuity: 6 },
  { date: "8/7/2019 13:06:32", shift: "A2", acuity: 3 },
  { date: "8/7/2019 13:07:00", shift: "D1", acuity: 3 },
  { date: "8/7/2019 13:11:16", shift: "B1", acuity: 3 },
  { date: "8/7/2019 13:38:36", shift: "A2", acuity: 6 },
  { date: "8/7/2019 13:39:28", shift: "A2", acuity: 3 },
  { date: "8/7/2019 13:45:17", shift: "C1", acuity: 3 },
  { date: "8/7/2019 13:45:26", shift: "D1", acuity: 3 },
  { date: "8/7/2019 13:57:56", shift: "B1", acuity: 6 },
  { date: "8/7/2019 14:24:59", shift: "B2", acuity: 3 },
  { date: "8/7/2019 14:25:09", shift: "A2", acuity: 3 },
  { date: "8/7/2019 14:28:40", shift: "C1", acuity: 6 },
  { date: "8/7/2019 14:33:35", shift: "B2", acuity: 3 },
  { date: "8/7/2019 14:54:41", shift: "B2", acuity: 3 },
  { date: "8/7/2019 15:06:45", shift: "D1", acuity: 3 },
  { date: "8/7/2019 15:12:23", shift: "A2", acuity: 3 },
  { date: "8/7/2019 15:14:15", shift: "C1", acuity: 6 },
  { date: "8/7/2019 15:38:40", shift: "B2", acuity: 6 },
  { date: "8/7/2019 15:42:38", shift: "D1", acuity: 3 },
  { date: "8/7/2019 15:42:46", shift: "A2", acuity: 3 },
  { date: "8/7/2019 15:48:27", shift: "C1", acuity: 3 },
  { date: "8/7/2019 15:57:23", shift: "B2", acuity: 3 },
  { date: "8/7/2019 16:03:19", shift: "D1", acuity: 3 },
  { date: "8/7/2019 16:03:46", shift: "A2", acuity: 6 },
  { date: "8/7/2019 16:14:58", shift: "C1", acuity: 6 },
  { date: "8/7/2019 16:25:38", shift: "B2", acuity: 3 },
  { date: "8/7/2019 16:36:02", shift: "C2", acuity: 3 },
  { date: "8/7/2019 16:36:11", shift: "D1", acuity: 3 },
  { date: "8/7/2019 16:56:01", shift: "A2", acuity: 6 },
  { date: "8/7/2019 17:16:20", shift: "C2", acuity: 4 },
  { date: "8/7/2019 17:16:45", shift: "C2", acuity: 3 },
  { date: "8/7/2019 17:30:35", shift: "B2", acuity: 3 },
  { date: "8/7/2019 17:50:13", shift: "D1", acuity: 6 },
  { date: "8/7/2019 17:50:49", shift: "A2", acuity: 3 },
  { date: "8/7/2019 17:51:07", shift: "C2", acuity: 3 },
  { date: "8/7/2019 17:51:44", shift: "B2", acuity: 6 },
  { date: "8/7/2019 17:56:03", shift: "D1", acuity: 6 },
  { date: "8/7/2019 17:56:32", shift: "A2", acuity: 3 },
  { date: "8/7/2019 18:00:26", shift: "D2", acuity: 6 },
  { date: "8/7/2019 18:14:43", shift: "C2", acuity: 6 },
  { date: "8/7/2019 18:15:06", shift: "B2", acuity: 6 },
  { date: "8/7/2019 18:17:23", shift: "A2", acuity: 3 },
  { date: "8/7/2019 18:40:16", shift: "D2", acuity: 6 },
  { date: "8/7/2019 18:51:28", shift: "D2", acuity: 6 },
  { date: "8/7/2019 18:54:13", shift: "C2", acuity: 6 },
  { date: "8/7/2019 19:04:01", shift: "B2", acuity: 6 },
  { date: "8/7/2019 19:04:13", shift: "A2", acuity: 3 },
  { date: "8/7/2019 19:13:59", shift: "D2", acuity: 4 },
  { date: "8/7/2019 19:24:58", shift: "C2", acuity: 6 },
  { date: "8/7/2019 19:25:15", shift: "B2", acuity: 3 },
  { date: "8/7/2019 19:36:36", shift: "A2", acuity: 6 },
  { date: "8/7/2019 19:54:50", shift: "D2", acuity: 3 },
  { date: "8/7/2019 20:02:42", shift: "C2", acuity: 6 },
  { date: "8/7/2019 20:04:20", shift: "B2", acuity: 3 },
  { date: "8/7/2019 20:04:42", shift: "A2", acuity: 3 },
  { date: "8/7/2019 20:05:01", shift: "D2", acuity: 6 },
  { date: "8/7/2019 20:23:57", shift: "C2", acuity: 3 },
  { date: "8/7/2019 20:24:09", shift: "B2", acuity: 3 },
  { date: "8/7/2019 20:32:13", shift: "A2", acuity: 3 },
  { date: "8/7/2019 20:33:15", shift: "D2", acuity: 4 },
  { date: "8/7/2019 21:02:12", shift: "A3", acuity: 3 },
  { date: "8/7/2019 21:02:30", shift: "C2", acuity: 4 },
  { date: "8/7/2019 21:02:47", shift: "B2", acuity: 3 },
  { date: "8/7/2019 21:03:02", shift: "D2", acuity: 3 },
  { date: "8/7/2019 21:14:25", shift: "A3", acuity: 6 },
  { date: "8/7/2019 21:17:46", shift: "C2", acuity: 3 },
  { date: "8/7/2019 21:22:51", shift: "B2", acuity: 3 },
  { date: "8/7/2019 21:23:10", shift: "D2", acuity: 6 },
  { date: "8/7/2019 21:23:22", shift: "C2", acuity: 3 },
  { date: "8/7/2019 21:39:56", shift: "A3", acuity: 3 },
  { date: "8/7/2019 21:47:06", shift: "A3", acuity: 3 },
  { date: "8/7/2019 21:55:01", shift: "B2", acuity: 3 },
  { date: "8/7/2019 22:08:54", shift: "B3", acuity: 3 },
  { date: "8/7/2019 22:09:05", shift: "D2", acuity: 3 },
  { date: "8/7/2019 22:26:08", shift: "C2", acuity: 3 },
  { date: "8/7/2019 22:29:37", shift: "A3", acuity: 6 },
  { date: "8/7/2019 22:34:31", shift: "B3", acuity: 3 },
  { date: "8/7/2019 22:42:47", shift: "B3", acuity: 4 },
  { date: "8/7/2019 22:43:47", shift: "D2", acuity: 4 },
  { date: "8/7/2019 22:44:05", shift: "C2", acuity: 3 },
  { date: "8/7/2019 22:44:24", shift: "A3", acuity: 3 },
  { date: "8/7/2019 22:58:31", shift: "B3", acuity: 6 },
  { date: "8/7/2019 23:04:21", shift: "D2", acuity: 6 },
  { date: "8/7/2019 23:09:03", shift: "C2", acuity: 4 },
  { date: "8/7/2019 23:27:06", shift: "A3", acuity: 3 },
  { date: "8/7/2019 23:40:25", shift: "B3", acuity: 4 },
  { date: "8/7/2019 23:47:52", shift: "D2", acuity: 3 },
  { date: "8/7/2019 23:52:42", shift: "C2", acuity: 3 },
  { date: "8/8/2019 00:05:37", shift: "A3", acuity: 4 },
  { date: "8/8/2019 00:11:31", shift: "B3", acuity: 4 },
  { date: "8/8/2019 00:11:48", shift: "D2", acuity: 4 },
  { date: "8/8/2019 00:16:43", shift: "A3", acuity: 6 },
  { date: "8/8/2019 00:33:09", shift: "B3", acuity: 6 },
  { date: "8/8/2019 00:38:18", shift: "D2", acuity: 4 },
  { date: "8/8/2019 00:38:32", shift: "A3", acuity: 6 },
  { date: "8/8/2019 00:48:24", shift: "B3", acuity: 6 },
  { date: "8/8/2019 01:03:23", shift: "D2", acuity: 3 },
  { date: "8/8/2019 01:40:55", shift: "A3", acuity: 3 },
  { date: "8/8/2019 01:46:58", shift: "B3", acuity: 6 },
  { date: "8/8/2019 01:51:41", shift: "A3", acuity: 4 },
  { date: "8/8/2019 01:51:56", shift: "B3", acuity: 4 },
  { date: "8/8/2019 02:06:07", shift: "A3", acuity: 3 },
  { date: "8/8/2019 02:11:20", shift: "B3", acuity: 3 },
  { date: "8/8/2019 02:11:56", shift: "A3", acuity: 6 },
  { date: "8/8/2019 02:39:00", shift: "B3", acuity: 3 },
  { date: "8/8/2019 03:11:37", shift: "A3", acuity: 4 },
  { date: "8/8/2019 03:34:17", shift: "B3", acuity: 3 },
  { date: "8/8/2019 05:06:38", shift: "A1", acuity: 3 },
  { date: "8/8/2019 05:06:44", shift: "B3", acuity: 6 },
  { date: "8/8/2019 05:07:11", shift: "A1", acuity: 3 },
  { date: "8/8/2019 05:15:13", shift: "B3", acuity: 4 },
  { date: "8/8/2019 05:33:07", shift: "A1", acuity: 3 },
  { date: "8/8/2019 05:34:25", shift: "A1", acuity: 6 },
  { date: "8/8/2019 06:00:30", shift: "B1", acuity: 3 },
  { date: "8/8/2019 06:06:07", shift: "A1", acuity: 3 },
  { date: "8/8/2019 06:17:58", shift: "B1", acuity: 6 },
  { date: "8/8/2019 06:44:14", shift: "B1", acuity: 3 },
  { date: "8/8/2019 07:01:09", shift: "B1", acuity: 3 },
  { date: "8/8/2019 07:34:59", shift: "A1", acuity: 3 },
  { date: "8/8/2019 07:39:29", shift: "B1", acuity: 3 },
  { date: "8/8/2019 07:46:39", shift: "A1", acuity: 4 },
  { date: "8/8/2019 07:48:38", shift: "B1", acuity: 3 },
  { date: "8/8/2019 08:04:06", shift: "A1", acuity: 3 },
  { date: "8/8/2019 08:09:54", shift: "B1", acuity: 3 },
  { date: "8/8/2019 08:23:28", shift: "A1", acuity: 6 },
  { date: "8/8/2019 08:34:37", shift: "C1", acuity: 3 },
  { date: "8/8/2019 08:34:58", shift: "B1", acuity: 6 },
  { date: "8/8/2019 08:35:08", shift: "A1", acuity: 3 },
  { date: "8/8/2019 09:00:12", shift: "C1", acuity: 3 },
  { date: "8/8/2019 09:00:24", shift: "C1", acuity: 3 },
  { date: "8/8/2019 09:00:38", shift: "B1", acuity: 3 },
  { date: "8/8/2019 09:02:44", shift: "A1", acuity: 6 },
  { date: "8/8/2019 09:12:53", shift: "C1", acuity: 3 },
  { date: "8/8/2019 09:13:03", shift: "B1", acuity: 6 },
  { date: "8/8/2019 09:18:56", shift: "A1", acuity: 6 },
  { date: "8/8/2019 09:27:31", shift: "C1", acuity: 3 },
  { date: "8/8/2019 09:42:18", shift: "B1", acuity: 6 },
  { date: "8/8/2019 09:49:00", shift: "A1", acuity: 3 },
  { date: "8/8/2019 09:53:34", shift: "C1", acuity: 3 },
  { date: "8/8/2019 10:03:52", shift: "D1", acuity: 6 },
  { date: "8/8/2019 10:30:50", shift: "D1", acuity: 3 },
  { date: "8/8/2019 10:34:54", shift: "D1", acuity: 4 },
  { date: "8/8/2019 10:35:08", shift: "B1", acuity: 6 },
  { date: "8/8/2019 11:04:11", shift: "A1", acuity: 3 },
  { date: "8/8/2019 11:04:22", shift: "C1", acuity: 3 },
  { date: "8/8/2019 11:11:16", shift: "D1", acuity: 3 },
  { date: "8/8/2019 11:17:44", shift: "B1", acuity: 6 },
  { date: "8/8/2019 11:27:50", shift: "A1", acuity: 6 },
  { date: "8/8/2019 11:30:04", shift: "C1", acuity: 6 },
  { date: "8/8/2019 11:36:02", shift: "D1", acuity: 6 },
  { date: "8/8/2019 11:38:08", shift: "B1", acuity: 3 },
  { date: "8/8/2019 11:38:18", shift: "A1", acuity: 6 },
  { date: "8/8/2019 11:56:04", shift: "C1", acuity: 3 },
  { date: "8/8/2019 12:09:08", shift: "D1", acuity: 3 },
  { date: "8/8/2019 12:20:52", shift: "B1", acuity: 2 },
  { date: "8/8/2019 12:25:14", shift: "A1", acuity: 3 },
  { date: "8/8/2019 12:42:56", shift: "C1", acuity: 2 },
  { date: "8/8/2019 12:43:44", shift: "D1", acuity: 6 },
  { date: "8/8/2019 13:22:06", shift: "A2", acuity: 6 },
  { date: "8/8/2019 13:22:21", shift: "B1", acuity: 6 },
  { date: "8/8/2019 13:51:18", shift: "A2", acuity: 6 },
  { date: "8/8/2019 13:51:31", shift: "A2", acuity: 3 },
  { date: "8/8/2019 13:58:59", shift: "C1", acuity: 6 },
  { date: "8/8/2019 14:07:02", shift: "B2", acuity: 3 },
  { date: "8/8/2019 14:08:13", shift: "D1", acuity: 6 },
  { date: "8/8/2019 14:09:52", shift: "A2", acuity: 6 },
  { date: "8/8/2019 15:04:33", shift: "B2", acuity: 6 },
  { date: "8/8/2019 15:15:04", shift: "B2", acuity: 3 },
  { date: "8/8/2019 15:30:45", shift: "C1", acuity: 3 },
  { date: "8/8/2019 15:42:39", shift: "D1", acuity: 4 },
  { date: "8/8/2019 15:42:56", shift: "A2", acuity: 6 },
  { date: "8/8/2019 15:43:12", shift: "B2", acuity: 3 },
  { date: "8/8/2019 15:44:17", shift: "C1", acuity: 3 },
  { date: "8/8/2019 15:56:23", shift: "D1", acuity: 6 },
  { date: "8/8/2019 15:56:36", shift: "A2", acuity: 6 },
  { date: "8/8/2019 15:56:49", shift: "B2", acuity: 6 },
  { date: "8/8/2019 16:06:12", shift: "C1", acuity: 3 },
  { date: "8/8/2019 16:06:32", shift: "D1", acuity: 3 },
  { date: "8/8/2019 16:21:27", shift: "A2", acuity: 6 },
  { date: "8/8/2019 16:29:27", shift: "B2", acuity: 6 },
  { date: "8/8/2019 16:44:30", shift: "C2", acuity: 3 },
  { date: "8/8/2019 16:45:46", shift: "D1", acuity: 3 },
  { date: "8/8/2019 17:01:20", shift: "C2", acuity: 3 },
  { date: "8/8/2019 17:01:37", shift: "C2", acuity: 6 },
  { date: "8/8/2019 17:02:00", shift: "A2", acuity: 3 },
  { date: "8/8/2019 17:06:14", shift: "B2", acuity: 3 },
  { date: "8/8/2019 17:16:24", shift: "D1", acuity: 4 },
  { date: "8/8/2019 17:17:44", shift: "C2", acuity: 6 },
  { date: "8/8/2019 17:54:57", shift: "A2", acuity: 3 },
  { date: "8/8/2019 18:05:47", shift: "D2", acuity: 3 },
  { date: "8/8/2019 18:06:08", shift: "B2", acuity: 6 },
  { date: "8/8/2019 18:26:24", shift: "C2", acuity: 6 },
  { date: "8/8/2019 18:27:13", shift: "A2", acuity: 3 },
  { date: "8/8/2019 18:34:09", shift: "D2", acuity: 3 },
  { date: "8/8/2019 18:34:24", shift: "D2", acuity: 3 },
  { date: "8/8/2019 18:47:08", shift: "B2", acuity: 6 },
  { date: "8/8/2019 18:48:08", shift: "C2", acuity: 6 },
  { date: "8/8/2019 18:48:27", shift: "A2", acuity: 3 },
  { date: "8/8/2019 18:48:40", shift: "D2", acuity: 6 },
  { date: "8/8/2019 19:27:55", shift: "B2", acuity: 3 },
  { date: "8/8/2019 19:28:10", shift: "C2", acuity: 3 },
  { date: "8/8/2019 19:38:45", shift: "A2", acuity: 3 },
  { date: "8/8/2019 19:41:30", shift: "D2", acuity: 6 },
  { date: "8/8/2019 19:56:47", shift: "B2", acuity: 3 },
  { date: "8/8/2019 20:06:52", shift: "C2", acuity: 3 },
  { date: "8/8/2019 20:18:45", shift: "A2", acuity: 3 },
  { date: "8/8/2019 20:24:46", shift: "D2", acuity: 3 },
  { date: "8/8/2019 20:33:20", shift: "B2", acuity: 3 },
  { date: "8/8/2019 20:53:46", shift: "C2", acuity: 3 },
  { date: "8/8/2019 21:01:59", shift: "A3", acuity: 3 },
  { date: "8/8/2019 21:32:36", shift: "A3", acuity: 3 },
  { date: "8/8/2019 21:35:18", shift: "A3", acuity: 3 },
  { date: "8/8/2019 21:36:02", shift: "D2", acuity: 6 },
  { date: "8/8/2019 21:47:04", shift: "B2", acuity: 3 },
  { date: "8/8/2019 21:47:26", shift: "C2", acuity: 3 },
  { date: "8/8/2019 21:47:44", shift: "A3", acuity: 3 },
  { date: "8/8/2019 21:48:08", shift: "D2", acuity: 4 },
  { date: "8/8/2019 21:48:29", shift: "B2", acuity: 6 },
  { date: "8/8/2019 21:48:56", shift: "C2", acuity: 3 },
  { date: "8/8/2019 21:54:08", shift: "A3", acuity: 6 },
  { date: "8/8/2019 22:00:20", shift: "B3", acuity: 3 },
  { date: "8/8/2019 22:07:01", shift: "D2", acuity: 3 },
  { date: "8/8/2019 22:10:05", shift: "C2", acuity: 3 },
  { date: "8/8/2019 22:10:21", shift: "A3", acuity: 6 },
  { date: "8/8/2019 22:24:26", shift: "B3", acuity: 4 },
  { date: "8/8/2019 22:29:42", shift: "D2", acuity: 4 },
  { date: "8/8/2019 22:30:15", shift: "B3", acuity: 6 },
  { date: "8/8/2019 22:42:24", shift: "B3", acuity: 6 },
  { date: "8/8/2019 22:47:06", shift: "C2", acuity: 4 },
  { date: "8/8/2019 22:58:43", shift: "A3", acuity: 3 },
  { date: "8/8/2019 23:14:06", shift: "D2", acuity: 4 },
  { date: "8/8/2019 23:22:42", shift: "B3", acuity: 3 },
  { date: "8/8/2019 23:35:51", shift: "C2", acuity: 4 },
  { date: "8/8/2019 23:39:46", shift: "A3", acuity: 6 },
  { date: "8/8/2019 23:44:55", shift: "D2", acuity: 3 },
  { date: "8/8/2019 23:45:09", shift: "B3", acuity: 4 },
  { date: "8/8/2019 23:57:09", shift: "C2", acuity: 4 },
  { date: "8/9/2019 00:00:51", shift: "A3", acuity: 4 },
  { date: "8/9/2019 00:10:50", shift: "D2", acuity: 4 },
  { date: "8/9/2019 00:20:52", shift: "B3", acuity: 3 },
  { date: "8/9/2019 00:31:55", shift: "A3", acuity: 3 },
  { date: "8/9/2019 00:32:19", shift: "D2", acuity: 6 },
  { date: "8/9/2019 00:55:12", shift: "B3", acuity: 4 },
  { date: "8/9/2019 00:55:35", shift: "A3", acuity: 4 },
  { date: "8/9/2019 01:06:28", shift: "D2", acuity: 4 },
  { date: "8/9/2019 01:27:22", shift: "B3", acuity: 3 },
  { date: "8/9/2019 01:46:16", shift: "A3", acuity: 3 },
  { date: "8/9/2019 02:02:51", shift: "B3", acuity: 6 },
  { date: "8/9/2019 02:04:06", shift: "A3", acuity: 3 },
  { date: "8/9/2019 02:21:43", shift: "B3", acuity: 6 },
  { date: "8/9/2019 02:35:52", shift: "A3", acuity: 4 },
  { date: "8/9/2019 02:38:54", shift: "B3", acuity: 6 },
  { date: "8/9/2019 02:52:22", shift: "A3", acuity: 3 },
  { date: "8/9/2019 03:20:51", shift: "B3", acuity: 6 },
  { date: "8/9/2019 03:34:35", shift: "A3", acuity: 3 },
  { date: "8/9/2019 03:34:52", shift: "B3", acuity: 4 },
  { date: "8/9/2019 03:52:22", shift: "A3", acuity: 3 },
  { date: "8/9/2019 04:24:58", shift: "B3", acuity: 6 },
  { date: "8/9/2019 04:49:53", shift: "A3", acuity: 3 },
  { date: "8/9/2019 04:50:23", shift: "B3", acuity: 3 },
  { date: "8/9/2019 04:58:14", shift: "A3", acuity: 4 },
  { date: "8/9/2019 05:41:30", shift: "A1", acuity: 3 },
  { date: "8/9/2019 06:31:56", shift: "A1", acuity: 4 },
  { date: "8/9/2019 06:35:29", shift: "A1", acuity: 3 },
  { date: "8/9/2019 06:46:11", shift: "B1", acuity: 3 },
  { date: "8/9/2019 06:58:21", shift: "B1", acuity: 3 },
  { date: "8/9/2019 07:02:27", shift: "B1", acuity: 4 },
  { date: "8/9/2019 07:20:28", shift: "A1", acuity: 3 },
  { date: "8/9/2019 07:20:47", shift: "B1", acuity: 3 },
  { date: "8/9/2019 07:24:43", shift: "A1", acuity: 3 },
  { date: "8/9/2019 07:57:42", shift: "B1", acuity: 6 },
  { date: "8/9/2019 08:10:50", shift: "A1", acuity: 4 },
  { date: "8/9/2019 08:11:27", shift: "B1", acuity: 4 },
  { date: "8/9/2019 08:29:58", shift: "A1", acuity: 6 },
  { date: "8/9/2019 09:02:09", shift: "C1", acuity: 4 },
  { date: "8/9/2019 09:05:36", shift: "C1", acuity: 6 },
  { date: "8/9/2019 09:13:10", shift: "C1", acuity: 6 },
  { date: "8/9/2019 09:31:18", shift: "B1", acuity: 4 },
  { date: "8/9/2019 09:31:55", shift: "A1", acuity: 3 },
  { date: "8/9/2019 09:32:26", shift: "C1", acuity: 6 },
  { date: "8/9/2019 09:41:47", shift: "B1", acuity: 3 },
  { date: "8/9/2019 09:57:13", shift: "A1", acuity: 3 },
  { date: "8/9/2019 09:57:59", shift: "C1", acuity: 4 },
  { date: "8/9/2019 10:25:58", shift: "D1", acuity: 3 },
  { date: "8/9/2019 10:35:23", shift: "D1", acuity: 3 },
  { date: "8/9/2019 10:35:44", shift: "D1", acuity: 3 },
  { date: "8/9/2019 10:51:42", shift: "B1", acuity: 3 },
  { date: "8/9/2019 10:57:45", shift: "A1", acuity: 3 },
  { date: "8/9/2019 11:01:16", shift: "C1", acuity: 3 },
  { date: "8/9/2019 11:18:41", shift: "D1", acuity: 3 },
  { date: "8/9/2019 11:19:59", shift: "B1", acuity: 6 },
  { date: "8/9/2019 11:36:50", shift: "A1", acuity: 3 },
  { date: "8/9/2019 11:39:55", shift: "C1", acuity: 3 },
  { date: "8/9/2019 11:59:30", shift: "D1", acuity: 3 },
  { date: "8/9/2019 12:00:18", shift: "B1", acuity: 3 },
  { date: "8/9/2019 12:08:53", shift: "A1", acuity: 2 },
  { date: "8/9/2019 12:16:47", shift: "C1", acuity: 3 },
  { date: "8/9/2019 12:40:00", shift: "D1", acuity: 6 },
  { date: "8/9/2019 12:40:53", shift: "B1", acuity: 3 },
  { date: "8/9/2019 13:06:09", shift: "A2", acuity: 3 },
  { date: "8/9/2019 13:14:36", shift: "C1", acuity: 6 },
  { date: "8/9/2019 13:16:50", shift: "D1", acuity: 6 },
  { date: "8/9/2019 13:33:38", shift: "A2", acuity: 6 },
  { date: "8/9/2019 13:52:34", shift: "A2", acuity: 6 },
  { date: "8/9/2019 13:55:38", shift: "B1", acuity: 3 },
  { date: "8/9/2019 14:02:50", shift: "B2", acuity: 3 },
  { date: "8/9/2019 14:03:18", shift: "C1", acuity: 4 },
  { date: "8/9/2019 14:14:49", shift: "D1", acuity: 3 },
  { date: "8/9/2019 14:24:09", shift: "A2", acuity: 3 },
  { date: "8/9/2019 14:24:41", shift: "B2", acuity: 3 },
  { date: "8/9/2019 14:33:32", shift: "B2", acuity: 3 },
  { date: "8/9/2019 14:54:40", shift: "B2", acuity: 3 },
  { date: "8/9/2019 14:55:06", shift: "C1", acuity: 4 },
  { date: "8/9/2019 14:58:28", shift: "D1", acuity: 6 },
  { date: "8/9/2019 15:12:23", shift: "A2", acuity: 6 },
  { date: "8/9/2019 15:33:56", shift: "B2", acuity: 3 },
  { date: "8/9/2019 15:34:21", shift: "C1", acuity: 3 },
  { date: "8/9/2019 15:34:44", shift: "D1", acuity: 3 },
  { date: "8/9/2019 15:35:03", shift: "A2", acuity: 3 },
  { date: "8/9/2019 15:51:55", shift: "B2", acuity: 6 },
  { date: "8/9/2019 16:00:40", shift: "C1", acuity: 6 },
  { date: "8/9/2019 16:14:32", shift: "D1", acuity: 3 },
  { date: "8/9/2019 16:24:32", shift: "A2", acuity: 3 },
  { date: "8/9/2019 16:30:38", shift: "C2", acuity: 6 },
  { date: "8/9/2019 16:39:51", shift: "B2", acuity: 3 },
  { date: "8/9/2019 16:40:36", shift: "D1", acuity: 6 },
  { date: "8/9/2019 16:46:18", shift: "A2", acuity: 3 },
  { date: "8/9/2019 17:06:47", shift: "C2", acuity: 3 },
  { date: "8/9/2019 17:09:15", shift: "C2", acuity: 3 },
  { date: "8/9/2019 17:23:50", shift: "B2", acuity: 3 },
  { date: "8/9/2019 17:27:02", shift: "D1", acuity: 6 },
  { date: "8/9/2019 17:30:07", shift: "A2", acuity: 4 },
  { date: "8/9/2019 17:39:06", shift: "C2", acuity: 6 },
  { date: "8/9/2019 18:08:18", shift: "D2", acuity: 6 },
  { date: "8/9/2019 18:17:49", shift: "B2", acuity: 6 },
  { date: "8/9/2019 18:24:53", shift: "A2", acuity: 6 },
  { date: "8/9/2019 18:32:12", shift: "D2", acuity: 6 },
  { date: "8/9/2019 18:46:58", shift: "D2", acuity: 3 },
  { date: "8/9/2019 18:47:25", shift: "C2", acuity: 6 },
  { date: "8/9/2019 18:48:06", shift: "B2", acuity: 6 },
  { date: "8/9/2019 18:51:44", shift: "A2", acuity: 6 },
  { date: "8/9/2019 19:00:22", shift: "D2", acuity: 3 },
  { date: "8/9/2019 19:09:18", shift: "C2", acuity: 3 },
  { date: "8/9/2019 19:13:22", shift: "B2", acuity: 3 },
  { date: "8/9/2019 19:15:36", shift: "A2", acuity: 3 },
  { date: "8/9/2019 19:21:02", shift: "D2", acuity: 6 },
  { date: "8/9/2019 19:38:58", shift: "C2", acuity: 3 },
  { date: "8/9/2019 19:52:35", shift: "B2", acuity: 3 },
  { date: "8/9/2019 19:52:54", shift: "A2", acuity: 6 },
  { date: "8/9/2019 20:04:06", shift: "D2", acuity: 6 },
  { date: "8/9/2019 20:09:58", shift: "C2", acuity: 6 },
  { date: "8/9/2019 20:35:52", shift: "B2", acuity: 3 },
  { date: "8/9/2019 20:49:57", shift: "A2", acuity: 3 },
  { date: "8/9/2019 20:51:56", shift: "D2", acuity: 3 },
  { date: "8/9/2019 21:06:58", shift: "A3", acuity: 3 },
  { date: "8/9/2019 21:17:14", shift: "C2", acuity: 3 },
  { date: "8/9/2019 21:48:55", shift: "A3", acuity: 3 },
  { date: "8/9/2019 22:06:22", shift: "A3", acuity: 3 },
  { date: "8/9/2019 22:06:45", shift: "B3", acuity: 3 },
  { date: "8/9/2019 22:28:26", shift: "D2", acuity: 3 },
  { date: "8/9/2019 22:29:04", shift: "C2", acuity: 6 },
  { date: "8/9/2019 22:29:30", shift: "A3", acuity: 3 },
  { date: "8/9/2019 22:42:50", shift: "B3", acuity: 6 },
  { date: "8/9/2019 22:54:51", shift: "B3", acuity: 6 },
  { date: "8/9/2019 23:05:49", shift: "D2", acuity: 3 },
  { date: "8/9/2019 23:12:25", shift: "C2", acuity: 3 },
  { date: "8/9/2019 23:13:07", shift: "A3", acuity: 3 },
  { date: "8/9/2019 23:28:40", shift: "B3", acuity: 3 },
  { date: "8/9/2019 23:29:08", shift: "D2", acuity: 3 },
  { date: "8/9/2019 23:37:37", shift: "C2", acuity: 3 },
  { date: "8/9/2019 23:42:29", shift: "A3", acuity: 3 },
  { date: "8/9/2019 23:53:23", shift: "B3", acuity: 3 },
  { date: "8/9/2019 23:54:01", shift: "D2", acuity: 4 },
  { date: "8/9/2019 23:54:39", shift: "C2", acuity: 4 },
  { date: "8/9/2019 23:54:54", shift: "A3", acuity: 6 },
  { date: "8/10/2019 00:11:02", shift: "B3", acuity: 3 },
  { date: "8/10/2019 00:24:53", shift: "D2", acuity: 3 },
  { date: "8/10/2019 00:37:06", shift: "A3", acuity: 3 },
  { date: "8/10/2019 00:46:46", shift: "B3", acuity: 3 },
  { date: "8/10/2019 00:50:38", shift: "D2", acuity: 4 },
  { date: "8/10/2019 01:00:45", shift: "A3", acuity: 4 },
  { date: "8/10/2019 01:01:17", shift: "B3", acuity: 3 },
  { date: "8/10/2019 01:50:01", shift: "A3", acuity: 5 },
  { date: "8/10/2019 01:53:26", shift: "B3", acuity: 3 },
  { date: "8/10/2019 01:57:57", shift: "A3", acuity: 3 },
  { date: "8/10/2019 02:16:52", shift: "B3", acuity: 3 },
  { date: "8/10/2019 02:19:39", shift: "A3", acuity: 4 },
  { date: "8/10/2019 02:25:44", shift: "B3", acuity: 3 },
  { date: "8/10/2019 02:34:52", shift: "A3", acuity: 4 },
  { date: "8/10/2019 02:40:33", shift: "B3", acuity: 5 },
  { date: "8/10/2019 02:55:27", shift: "A3", acuity: 3 },
  { date: "8/10/2019 03:26:07", shift: "B3", acuity: 4 },
  { date: "8/10/2019 03:35:23", shift: "A3", acuity: 3 },
  { date: "8/10/2019 03:39:43", shift: "B3", acuity: 4 },
  { date: "8/10/2019 03:51:05", shift: "A3", acuity: 3 },
  { date: "8/10/2019 04:03:16", shift: "B3", acuity: 6 },
  { date: "8/10/2019 04:20:24", shift: "A3", acuity: 4 },
  { date: "8/10/2019 04:57:38", shift: "B3", acuity: 3 },
  { date: "8/10/2019 05:09:47", shift: "A1", acuity: 6 },
  { date: "8/10/2019 06:05:01", shift: "A1", acuity: 3 },
  { date: "8/10/2019 06:05:21", shift: "A1", acuity: 3 },
  { date: "8/10/2019 07:06:55", shift: "B1", acuity: 3 },
  { date: "8/10/2019 07:08:15", shift: "B1", acuity: 3 },
  { date: "8/10/2019 07:20:35", shift: "B1", acuity: 3 },
  { date: "8/10/2019 07:27:55", shift: "B1", acuity: 3 },
  { date: "8/10/2019 07:36:42", shift: "A1", acuity: 4 },
  { date: "8/10/2019 08:32:21", shift: "C1", acuity: 3 },
  { date: "8/10/2019 08:32:49", shift: "B1", acuity: 6 },
  { date: "8/10/2019 08:49:47", shift: "A1", acuity: 6 },
  { date: "8/10/2019 09:00:20", shift: "C1", acuity: 3 },
  { date: "8/10/2019 09:10:30", shift: "C1", acuity: 6 },
  { date: "8/10/2019 09:11:03", shift: "B1", acuity: 6 },
  { date: "8/10/2019 09:17:48", shift: "A1", acuity: 6 },
  { date: "8/10/2019 09:28:44", shift: "C1", acuity: 3 },
  { date: "8/10/2019 09:35:30", shift: "B1", acuity: 3 },
  { date: "8/10/2019 10:14:37", shift: "D1", acuity: 6 },
  { date: "8/10/2019 10:17:00", shift: "A1", acuity: 3 },
  { date: "8/10/2019 10:24:29", shift: "C1", acuity: 6 },
  { date: "8/10/2019 10:46:01", shift: "D1", acuity: 6 },
  { date: "8/10/2019 10:49:21", shift: "D1", acuity: 3 },
  { date: "8/10/2019 10:55:35", shift: "B1", acuity: 6 },
  { date: "8/10/2019 11:08:53", shift: "A1", acuity: 3 },
  { date: "8/10/2019 11:20:37", shift: "C1", acuity: 3 },
  { date: "8/10/2019 11:23:15", shift: "D1", acuity: 3 },
  { date: "8/10/2019 11:42:22", shift: "B1", acuity: 3 },
  { date: "8/10/2019 11:46:49", shift: "A1", acuity: 3 },
  { date: "8/10/2019 11:49:20", shift: "C1", acuity: 6 },
  { date: "8/10/2019 11:54:27", shift: "D1", acuity: 3 },
  { date: "8/10/2019 12:00:28", shift: "B1", acuity: 6 },
  { date: "8/10/2019 12:09:04", shift: "A1", acuity: 3 },
  { date: "8/10/2019 12:27:29", shift: "C1", acuity: 3 },
  { date: "8/10/2019 12:27:52", shift: "D1", acuity: 6 },
  { date: "8/10/2019 12:57:47", shift: "B1", acuity: 3 },
  { date: "8/10/2019 13:00:17", shift: "A2", acuity: 3 },
  { date: "8/10/2019 13:13:07", shift: "C1", acuity: 3 },
  { date: "8/10/2019 13:16:33", shift: "D1", acuity: 6 },
  { date: "8/10/2019 13:37:26", shift: "A2", acuity: 6 },
  { date: "8/10/2019 13:44:30", shift: "A2", acuity: 3 },
  { date: "8/10/2019 13:56:49", shift: "B1", acuity: 6 },
  { date: "8/10/2019 14:07:20", shift: "B2", acuity: 6 },
  { date: "8/10/2019 14:08:51", shift: "C1", acuity: 6 },
  { date: "8/10/2019 14:11:33", shift: "D1", acuity: 6 },
  { date: "8/10/2019 14:42:19", shift: "B2", acuity: 3 },
  { date: "8/10/2019 14:43:27", shift: "B2", acuity: 3 },
  { date: "8/10/2019 14:51:26", shift: "A2", acuity: 6 },
  { date: "8/10/2019 15:17:18", shift: "C1", acuity: 6 },
  { date: "8/10/2019 15:30:19", shift: "D1", acuity: 3 },
  { date: "8/10/2019 15:32:48", shift: "B2", acuity: 6 },
  { date: "8/10/2019 15:56:39", shift: "A2", acuity: 3 },
  { date: "8/10/2019 15:57:12", shift: "C1", acuity: 3 },
  { date: "8/10/2019 15:59:15", shift: "D1", acuity: 3 },
  { date: "8/10/2019 16:16:31", shift: "B2", acuity: 3 },
  { date: "8/10/2019 16:17:03", shift: "A2", acuity: 3 },
  { date: "8/10/2019 16:25:19", shift: "C1", acuity: 6 },
  { date: "8/10/2019 16:26:14", shift: "D1", acuity: 6 },
  { date: "8/10/2019 16:42:04", shift: "C2", acuity: 6 },
  { date: "8/10/2019 16:50:02", shift: "B2", acuity: 6 },
  { date: "8/10/2019 16:57:17", shift: "A2", acuity: 6 },
  { date: "8/10/2019 17:02:06", shift: "C2", acuity: 4 },
  { date: "8/10/2019 17:03:46", shift: "C2", acuity: 3 },
  { date: "8/10/2019 17:07:04", shift: "D1", acuity: 3 },
  { date: "8/10/2019 17:14:36", shift: "B2", acuity: 6 },
  { date: "8/10/2019 17:18:58", shift: "A2", acuity: 3 },
  { date: "8/10/2019 17:23:53", shift: "C2", acuity: 3 },
  { date: "8/10/2019 17:24:13", shift: "D1", acuity: 3 },
  { date: "8/10/2019 17:40:27", shift: "B2", acuity: 3 },
  { date: "8/10/2019 17:49:28", shift: "A2", acuity: 3 },
  { date: "8/10/2019 17:49:56", shift: "C2", acuity: 4 },
  { date: "8/10/2019 17:55:24", shift: "D1", acuity: 3 },
  { date: "8/10/2019 18:06:54", shift: "D2", acuity: 6 },
  { date: "8/10/2019 18:16:16", shift: "B2", acuity: 2 },
  { date: "8/10/2019 18:16:42", shift: "A2", acuity: 6 },
  { date: "8/10/2019 18:19:24", shift: "C2", acuity: 3 },
  { date: "8/10/2019 18:47:02", shift: "D2", acuity: 6 },
  { date: "8/10/2019 18:58:54", shift: "D2", acuity: 6 },
  { date: "8/10/2019 19:13:04", shift: "B2", acuity: 6 },
  { date: "8/10/2019 19:25:45", shift: "A2", acuity: 3 },
  { date: "8/10/2019 19:31:44", shift: "C2", acuity: 3 },
  { date: "8/10/2019 19:32:08", shift: "D2", acuity: 3 },
  { date: "8/10/2019 19:46:44", shift: "B2", acuity: 6 },
  { date: "8/10/2019 19:47:20", shift: "A2", acuity: 3 },
  { date: "8/10/2019 20:00:51", shift: "C2", acuity: 6 },
  { date: "8/10/2019 20:08:35", shift: "D2", acuity: 3 },
  { date: "8/10/2019 20:11:24", shift: "B2", acuity: 6 },
  { date: "8/10/2019 20:23:00", shift: "A2", acuity: 4 },
  { date: "8/10/2019 20:28:26", shift: "C2", acuity: 3 },
  { date: "8/10/2019 20:37:18", shift: "D2", acuity: 3 },
  { date: "8/10/2019 20:50:29", shift: "B2", acuity: 3 },
  { date: "8/10/2019 21:13:42", shift: "A3", acuity: 3 },
  { date: "8/10/2019 21:15:32", shift: "C2", acuity: 3 },
  { date: "8/10/2019 21:28:33", shift: "D2", acuity: 3 },
  { date: "8/10/2019 21:28:52", shift: "B2", acuity: 3 },
  { date: "8/10/2019 21:30:26", shift: "A3", acuity: 6 },
  { date: "8/10/2019 21:42:34", shift: "A3", acuity: 3 },
  { date: "8/10/2019 22:02:09", shift: "B3", acuity: 6 },
  { date: "8/10/2019 22:02:30", shift: "C2", acuity: 6 },
  { date: "8/10/2019 22:08:44", shift: "D2", acuity: 3 },
  { date: "8/10/2019 22:14:31", shift: "A3", acuity: 4 },
  { date: "8/10/2019 22:22:15", shift: "B3", acuity: 3 },
  { date: "8/10/2019 22:23:29", shift: "C2", acuity: 3 },
  { date: "8/10/2019 22:37:40", shift: "B3", acuity: 6 },
  { date: "8/10/2019 23:04:42", shift: "B3", acuity: 4 },
  { date: "8/10/2019 23:05:08", shift: "D2", acuity: 6 },
  { date: "8/10/2019 23:10:06", shift: "A3", acuity: 3 },
  { date: "8/10/2019 23:20:15", shift: "C2", acuity: 3 },
  { date: "8/10/2019 23:37:56", shift: "B3", acuity: 3 },
  { date: "8/10/2019 23:48:35", shift: "D2", acuity: 3 },
  { date: "8/10/2019 23:56:14", shift: "A3", acuity: 3 },
  { date: "8/11/2019 00:01:18", shift: "B3", acuity: 4 },
  { date: "8/11/2019 00:07:02", shift: "D2", acuity: 3 },
  { date: "8/11/2019 00:07:47", shift: "A3", acuity: 4 },
  { date: "8/11/2019 00:19:39", shift: "B3", acuity: 6 },
  { date: "8/11/2019 00:33:34", shift: "D2", acuity: 6 },
  { date: "8/11/2019 00:50:56", shift: "A3", acuity: 4 },
  { date: "8/11/2019 00:54:59", shift: "B3", acuity: 3 },
  { date: "8/11/2019 00:58:40", shift: "D2", acuity: 6 },
  { date: "8/11/2019 00:59:35", shift: "A3", acuity: 3 },
  { date: "8/11/2019 01:00:01", shift: "B3", acuity: 3 },
  { date: "8/11/2019 01:19:39", shift: "D2", acuity: 6 },
  { date: "8/11/2019 01:30:23", shift: "A3", acuity: 3 },
  { date: "8/11/2019 01:42:57", shift: "B3", acuity: 3 },
  { date: "8/11/2019 02:03:59", shift: "A3", acuity: 4 },
  { date: "8/11/2019 02:09:58", shift: "B3", acuity: 3 },
  { date: "8/11/2019 02:12:25", shift: "A3", acuity: 3 },
  { date: "8/11/2019 02:20:26", shift: "B3", acuity: 4 },
  { date: "8/11/2019 02:29:29", shift: "A3", acuity: 3 },
  { date: "8/11/2019 02:57:13", shift: "B3", acuity: 6 },
  { date: "8/11/2019 03:23:15", shift: "A3", acuity: 4 },
  { date: "8/11/2019 04:37:43", shift: "B3", acuity: 3 },
  { date: "8/11/2019 05:15:19", shift: "A1", acuity: 3 },
  { date: "8/11/2019 05:15:40", shift: "B3", acuity: 4 },
  { date: "8/11/2019 05:43:26", shift: "A1", acuity: 4 },
  { date: "8/11/2019 06:04:24", shift: "A1", acuity: 6 },
  { date: "8/11/2019 06:06:47", shift: "B1", acuity: 3 },
  { date: "8/11/2019 06:21:03", shift: "A1", acuity: 3 },
  { date: "8/11/2019 06:34:49", shift: "B1", acuity: 3 },
  { date: "8/11/2019 07:21:58", shift: "B1", acuity: 3 },
  { date: "8/11/2019 07:32:37", shift: "B1", acuity: 3 },
  { date: "8/11/2019 07:48:22", shift: "A1", acuity: 6 },
  { date: "8/11/2019 08:34:16", shift: "C1", acuity: 6 },
  { date: "8/11/2019 08:34:42", shift: "B1", acuity: 4 },
  { date: "8/11/2019 08:40:24", shift: "A1", acuity: 6 },
  { date: "8/11/2019 09:11:18", shift: "C1", acuity: 6 },
  { date: "8/11/2019 09:13:08", shift: "C1", acuity: 3 },
  { date: "8/11/2019 09:13:52", shift: "B1", acuity: 3 },
  { date: "8/11/2019 09:24:20", shift: "A1", acuity: 4 },
  { date: "8/11/2019 09:35:47", shift: "C1", acuity: 6 },
  { date: "8/11/2019 09:50:03", shift: "B1", acuity: 3 },
  { date: "8/11/2019 10:08:52", shift: "D1", acuity: 3 },
  { date: "8/11/2019 10:25:49", shift: "A1", acuity: 4 },
  { date: "8/11/2019 10:48:38", shift: "D1", acuity: 3 },
  { date: "8/11/2019 10:54:58", shift: "D1", acuity: 6 },
  { date: "8/11/2019 11:13:48", shift: "C1", acuity: 3 },
  { date: "8/11/2019 11:23:06", shift: "B1", acuity: 6 },
  { date: "8/11/2019 11:23:32", shift: "A1", acuity: 3 },
  { date: "8/11/2019 11:39:35", shift: "D1", acuity: 3 },
  { date: "8/11/2019 11:40:36", shift: "C1", acuity: 3 },
  { date: "8/11/2019 12:01:39", shift: "B1", acuity: 3 },
  { date: "8/11/2019 12:11:14", shift: "A1", acuity: 3 },
  { date: "8/11/2019 12:18:57", shift: "D1", acuity: 3 },
  { date: "8/11/2019 12:31:09", shift: "C1", acuity: 6 },
  { date: "8/11/2019 12:40:13", shift: "B1", acuity: 3 },
  { date: "8/11/2019 12:43:33", shift: "A1", acuity: 6 },
  { date: "8/11/2019 12:56:17", shift: "D1", acuity: 3 },
  { date: "8/11/2019 13:24:59", shift: "A2", acuity: 6 },
  { date: "8/11/2019 13:30:55", shift: "A2", acuity: 6 },
  { date: "8/11/2019 13:42:55", shift: "A2", acuity: 6 },
  { date: "8/11/2019 13:58:03", shift: "C1", acuity: 3 },
  { date: "8/11/2019 14:11:27", shift: "B2", acuity: 3 },
  { date: "8/11/2019 14:15:15", shift: "D1", acuity: 6 },
  { date: "8/11/2019 14:38:33", shift: "B2", acuity: 6 },
  { date: "8/11/2019 14:39:07", shift: "B2", acuity: 3 },
  { date: "8/11/2019 14:59:55", shift: "A2", acuity: 6 },
  { date: "8/11/2019 15:08:40", shift: "C1", acuity: 3 },
  { date: "8/11/2019 15:09:06", shift: "D1", acuity: 3 },
  { date: "8/11/2019 15:15:57", shift: "B2", acuity: 3 },
  { date: "8/11/2019 15:27:32", shift: "A2", acuity: 4 },
  { date: "8/11/2019 15:27:53", shift: "C1", acuity: 3 },
  { date: "8/11/2019 15:35:31", shift: "D1", acuity: 6 },
  { date: "8/11/2019 15:47:33", shift: "B2", acuity: 6 },
  { date: "8/11/2019 16:18:08", shift: "A2", acuity: 3 },
  { date: "8/11/2019 16:19:18", shift: "C1", acuity: 3 },
  { date: "8/11/2019 16:25:26", shift: "D1", acuity: 3 },
  { date: "8/11/2019 16:37:22", shift: "C2", acuity: 6 },
  { date: "8/11/2019 16:47:54", shift: "B2", acuity: 6 },
  { date: "8/11/2019 17:01:05", shift: "C2", acuity: 6 },
  { date: "8/11/2019 17:12:04", shift: "C2", acuity: 4 },
  { date: "8/11/2019 17:12:24", shift: "A2", acuity: 3 },
  { date: "8/11/2019 17:25:45", shift: "D1", acuity: 3 },
  { date: "8/11/2019 17:28:38", shift: "B2", acuity: 3 },
  { date: "8/11/2019 17:28:56", shift: "C2", acuity: 3 },
  { date: "8/11/2019 17:43:50", shift: "A2", acuity: 6 },
  { date: "8/11/2019 17:46:06", shift: "D1", acuity: 6 },
  { date: "8/11/2019 17:55:13", shift: "B2", acuity: 6 },
  { date: "8/11/2019 18:07:07", shift: "D2", acuity: 6 },
  { date: "8/11/2019 18:27:34", shift: "C2", acuity: 3 },
  { date: "8/11/2019 18:30:46", shift: "D2", acuity: 6 },
  { date: "8/11/2019 18:38:08", shift: "D2", acuity: 3 },
  { date: "8/11/2019 18:40:09", shift: "A2", acuity: 4 },
  { date: "8/11/2019 18:53:50", shift: "B2", acuity: 3 },
  { date: "8/11/2019 19:12:34", shift: "C2", acuity: 3 },
  { date: "8/11/2019 19:12:51", shift: "D2", acuity: 3 },
  { date: "8/11/2019 19:24:09", shift: "A2", acuity: 4 },
  { date: "8/11/2019 19:28:44", shift: "B2", acuity: 3 },
  { date: "8/11/2019 19:29:14", shift: "C2", acuity: 3 },
  { date: "8/11/2019 19:32:58", shift: "D2", acuity: 2 },
  { date: "8/11/2019 19:47:39", shift: "A2", acuity: 4 },
  { date: "8/11/2019 19:48:04", shift: "B2", acuity: 3 },
  { date: "8/11/2019 19:54:19", shift: "C2", acuity: 3 },
  { date: "8/11/2019 20:03:50", shift: "D2", acuity: 3 },
  { date: "8/11/2019 20:04:06", shift: "A2", acuity: 6 },
  { date: "8/11/2019 20:04:39", shift: "B2", acuity: 6 },
  { date: "8/11/2019 20:10:32", shift: "C2", acuity: 3 },
  { date: "8/11/2019 20:14:18", shift: "D2", acuity: 5 },
  { date: "8/11/2019 20:18:44", shift: "A2", acuity: 3 },
  { date: "8/11/2019 20:32:26", shift: "B2", acuity: 4 },
  { date: "8/11/2019 20:44:32", shift: "C2", acuity: 4 },
  { date: "8/11/2019 20:46:42", shift: "D2", acuity: 3 },
  { date: "8/11/2019 21:08:36", shift: "A3", acuity: 6 },
  { date: "8/11/2019 21:20:21", shift: "B2", acuity: 4 },
  { date: "8/11/2019 21:20:48", shift: "C2", acuity: 3 },
  { date: "8/11/2019 21:21:02", shift: "D2", acuity: 3 },
  { date: "8/11/2019 21:37:30", shift: "A3", acuity: 3 },
  { date: "8/11/2019 21:52:34", shift: "A3", acuity: 6 },
  { date: "8/11/2019 22:05:06", shift: "B3", acuity: 3 },
  { date: "8/11/2019 22:13:22", shift: "C2", acuity: 6 },
  { date: "8/11/2019 22:23:23", shift: "D2", acuity: 3 },
  { date: "8/11/2019 22:23:37", shift: "A3", acuity: 3 },
  { date: "8/11/2019 22:43:49", shift: "B3", acuity: 4 },
  { date: "8/11/2019 22:50:08", shift: "B3", acuity: 3 },
  { date: "8/11/2019 22:50:28", shift: "C2", acuity: 4 },
  { date: "8/11/2019 22:56:41", shift: "D2", acuity: 3 },
  { date: "8/11/2019 23:07:18", shift: "A3", acuity: 3 },
  { date: "8/11/2019 23:07:33", shift: "B3", acuity: 3 },
  { date: "8/11/2019 23:07:52", shift: "C2", acuity: 4 },
  { date: "8/11/2019 23:26:21", shift: "D2", acuity: 5 },
  { date: "8/11/2019 23:26:35", shift: "A3", acuity: 4 },
  { date: "8/11/2019 23:45:24", shift: "B3", acuity: 3 },
  { date: "8/11/2019 23:45:46", shift: "C2", acuity: 3 },
  { date: "8/11/2019 23:58:42", shift: "D2", acuity: 3 },
  { date: "8/12/2019 00:02:12", shift: "A3", acuity: 4 },
  { date: "8/12/2019 00:02:27", shift: "B3", acuity: 4 },
  { date: "8/12/2019 00:35:49", shift: "D2", acuity: 3 },
  { date: "8/12/2019 00:42:07", shift: "A3", acuity: 3 },
  { date: "8/12/2019 00:51:34", shift: "B3", acuity: 3 },
  { date: "8/12/2019 01:02:27", shift: "D2", acuity: 4 },
  { date: "8/12/2019 01:02:41", shift: "A3", acuity: 6 },
  { date: "8/12/2019 01:02:53", shift: "B3", acuity: 3 },
  { date: "8/12/2019 01:26:56", shift: "D2", acuity: 3 },
  { date: "8/12/2019 01:52:53", shift: "A3", acuity: 4 },
  { date: "8/12/2019 02:10:41", shift: "B3", acuity: 3 },
  { date: "8/12/2019 02:51:17", shift: "A3", acuity: 3 },
  { date: "8/12/2019 04:17:34", shift: "B3", acuity: 3 },
  { date: "8/12/2019 04:33:50", shift: "A3", acuity: 3 },
  { date: "8/12/2019 05:01:10", shift: "A1", acuity: 3 },
  { date: "8/12/2019 05:01:22", shift: "B3", acuity: 4 },
  { date: "8/12/2019 05:27:11", shift: "A1", acuity: 4 },
  { date: "8/12/2019 05:30:54", shift: "A1", acuity: 3 },
  { date: "8/12/2019 06:09:57", shift: "A1", acuity: 6 },
  { date: "8/12/2019 06:11:48", shift: "B1", acuity: 3 },
  { date: "8/12/2019 06:18:43", shift: "A1", acuity: 3 },
  { date: "8/12/2019 06:26:09", shift: "B1", acuity: 6 },
  { date: "8/12/2019 06:39:26", shift: "B1", acuity: 3 },
  { date: "8/12/2019 06:41:58", shift: "B1", acuity: 2 },
  { date: "8/12/2019 07:05:26", shift: "A1", acuity: 4 },
  { date: "8/12/2019 08:16:52", shift: "B1", acuity: 6 },
  { date: "8/12/2019 08:48:42", shift: "C1", acuity: 4 },
  { date: "8/12/2019 08:55:47", shift: "A1", acuity: 6 },
  { date: "8/12/2019 08:58:29", shift: "B1", acuity: 4 },
  { date: "8/12/2019 09:08:42", shift: "C1", acuity: 6 },
  { date: "8/12/2019 09:18:34", shift: "C1", acuity: 2 },
  { date: "8/12/2019 09:21:47", shift: "A1", acuity: 3 },
  { date: "8/12/2019 09:25:37", shift: "B1", acuity: 4 },
  { date: "8/12/2019 09:32:40", shift: "C1", acuity: 3 },
  { date: "8/12/2019 09:56:22", shift: "A1", acuity: 3 },
  { date: "8/12/2019 09:56:57", shift: "B1", acuity: 3 },
  { date: "8/12/2019 10:26:28", shift: "D1", acuity: 3 },
  { date: "8/12/2019 10:28:50", shift: "C1", acuity: 3 },
  { date: "8/12/2019 10:31:26", shift: "D1", acuity: 4 },
  { date: "8/12/2019 10:44:02", shift: "D1", acuity: 4 },
  { date: "8/12/2019 10:56:09", shift: "A1", acuity: 3 },
  { date: "8/12/2019 11:04:44", shift: "B1", acuity: 3 },
  { date: "8/12/2019 11:14:44", shift: "C1", acuity: 3 },
  { date: "8/12/2019 11:22:57", shift: "D1", acuity: 3 },
  { date: "8/12/2019 11:24:27", shift: "A1", acuity: 3 },
  { date: "8/12/2019 11:27:49", shift: "B1", acuity: 4 },
  { date: "8/12/2019 11:50:05", shift: "C1", acuity: 3 },
  { date: "8/12/2019 11:53:25", shift: "D1", acuity: 6 },
  { date: "8/12/2019 11:53:55", shift: "A1", acuity: 3 },
  { date: "8/12/2019 11:55:32", shift: "B1", acuity: 6 },
  { date: "8/12/2019 12:00:56", shift: "C1", acuity: 6 },
  { date: "8/12/2019 12:16:48", shift: "D1", acuity: 6 },
  { date: "8/12/2019 12:17:57", shift: "A1", acuity: 3 },
  { date: "8/12/2019 12:26:45", shift: "B1", acuity: 6 },
  { date: "8/12/2019 12:40:16", shift: "C1", acuity: 3 },
  { date: "8/12/2019 12:48:40", shift: "D1", acuity: 6 },
  { date: "8/12/2019 12:49:07", shift: "A1", acuity: 3 },
  { date: "8/12/2019 12:51:42", shift: "B1", acuity: 3 },
  { date: "8/12/2019 12:58:54", shift: "C1", acuity: 6 },
  { date: "8/12/2019 13:05:22", shift: "A2", acuity: 3 },
  { date: "8/12/2019 13:14:42", shift: "D1", acuity: 6 },
  { date: "8/12/2019 13:25:05", shift: "B1", acuity: 3 },
  { date: "8/12/2019 13:31:35", shift: "A2", acuity: 6 },
  { date: "8/12/2019 13:39:00", shift: "A2", acuity: 6 },
  { date: "8/12/2019 13:39:51", shift: "C1", acuity: 6 },
  { date: "8/12/2019 13:55:52", shift: "D1", acuity: 6 },
  { date: "8/12/2019 13:58:10", shift: "B1", acuity: 6 },
  { date: "8/12/2019 14:03:41", shift: "B2", acuity: 6 },
  { date: "8/12/2019 14:04:11", shift: "A2", acuity: 3 },
  { date: "8/12/2019 14:15:28", shift: "C1", acuity: 6 },
  { date: "8/12/2019 14:18:05", shift: "D1", acuity: 6 },
  { date: "8/12/2019 14:54:38", shift: "B2", acuity: 3 },
  { date: "8/12/2019 14:54:55", shift: "B2", acuity: 3 },
  { date: "8/12/2019 14:55:20", shift: "A2", acuity: 6 },
  { date: "8/12/2019 15:11:03", shift: "C1", acuity: 6 },
  { date: "8/12/2019 15:11:25", shift: "D1", acuity: 6 },
  { date: "8/12/2019 15:18:30", shift: "B2", acuity: 6 },
  { date: "8/12/2019 15:27:50", shift: "A2", acuity: 3 },
  { date: "8/12/2019 15:33:22", shift: "C1", acuity: 6 },
  { date: "8/12/2019 15:40:25", shift: "D1", acuity: 6 },
  { date: "8/12/2019 15:51:26", shift: "B2", acuity: 3 },
  { date: "8/12/2019 16:02:47", shift: "A2", acuity: 6 },
  { date: "8/12/2019 16:13:49", shift: "C1", acuity: 6 },
  { date: "8/12/2019 16:14:06", shift: "D1", acuity: 3 },
  { date: "8/12/2019 16:14:23", shift: "B2", acuity: 3 },
  { date: "8/12/2019 16:20:58", shift: "A2", acuity: 6 },
  { date: "8/12/2019 16:47:50", shift: "C2", acuity: 6 },
  { date: "8/12/2019 17:09:28", shift: "C2", acuity: 3 },
  { date: "8/12/2019 17:25:08", shift: "C2", acuity: 6 },
  { date: "8/12/2019 17:25:34", shift: "D1", acuity: 3 },
  { date: "8/12/2019 17:29:30", shift: "B2", acuity: 3 },
  { date: "8/12/2019 17:53:35", shift: "A2", acuity: 3 },
  { date: "8/12/2019 18:35:31", shift: "D2", acuity: 3 },
  { date: "8/12/2019 18:35:49", shift: "D2", acuity: 6 },
  { date: "8/12/2019 18:46:06", shift: "D2", acuity: 3 },
  { date: "8/12/2019 18:46:24", shift: "C2", acuity: 6 },
  { date: "8/12/2019 19:01:33", shift: "B2", acuity: 3 },
  { date: "8/12/2019 19:28:46", shift: "A2", acuity: 3 },
  { date: "8/12/2019 19:38:00", shift: "D2", acuity: 3 },
  { date: "8/12/2019 19:38:20", shift: "C2", acuity: 6 },
  { date: "8/12/2019 19:47:15", shift: "B2", acuity: 3 },
  { date: "8/12/2019 19:47:29", shift: "A2", acuity: 6 },
  { date: "8/12/2019 19:47:45", shift: "D2", acuity: 6 },
  { date: "8/12/2019 19:48:02", shift: "C2", acuity: 6 },
  { date: "8/12/2019 20:17:01", shift: "B2", acuity: 6 },
  { date: "8/12/2019 20:19:48", shift: "A2", acuity: 6 },
  { date: "8/12/2019 20:32:41", shift: "D2", acuity: 6 },
  { date: "8/12/2019 20:57:56", shift: "C2", acuity: 3 },
  { date: "8/12/2019 21:01:38", shift: "A3", acuity: 3 },
  { date: "8/12/2019 21:28:28", shift: "B2", acuity: 6 },
  { date: "8/12/2019 21:31:18", shift: "A3", acuity: 3 },
  { date: "8/12/2019 21:43:59", shift: "A3", acuity: 3 },
  { date: "8/12/2019 22:17:02", shift: "B3", acuity: 6 },
  { date: "8/12/2019 22:43:00", shift: "B3", acuity: 6 },
  { date: "8/12/2019 22:49:47", shift: "B3", acuity: 3 },
  { date: "8/12/2019 23:01:55", shift: "D2", acuity: 3 },
  { date: "8/12/2019 23:14:50", shift: "C2", acuity: 3 },
  { date: "8/12/2019 23:17:39", shift: "A3", acuity: 3 },
  { date: "8/12/2019 23:17:53", shift: "B3", acuity: 6 },
  { date: "8/12/2019 23:31:00", shift: "D2", acuity: 3 },
  { date: "8/12/2019 23:31:13", shift: "C2", acuity: 3 },
  { date: "8/12/2019 23:34:18", shift: "A3", acuity: 4 },
  { date: "8/12/2019 23:54:18", shift: "B3", acuity: 6 },
  { date: "8/12/2019 23:54:45", shift: "D2", acuity: 6 },
  { date: "8/13/2019 00:13:46", shift: "A3", acuity: 4 },
  { date: "8/13/2019 00:13:57", shift: "B3", acuity: 3 },
  { date: "8/13/2019 00:14:13", shift: "D2", acuity: 3 },
  { date: "8/13/2019 00:14:21", shift: "A3", acuity: 3 },
  { date: "8/13/2019 00:34:12", shift: "B3", acuity: 4 },
  { date: "8/13/2019 00:45:09", shift: "D2", acuity: 4 },
  { date: "8/13/2019 00:45:25", shift: "A3", acuity: 4 },
  { date: "8/13/2019 01:03:39", shift: "B3", acuity: 4 },
  { date: "8/13/2019 01:13:01", shift: "D2", acuity: 4 },
  { date: "8/13/2019 01:14:31", shift: "A3", acuity: 3 },
  { date: "8/13/2019 01:30:13", shift: "B3", acuity: 3 },
  { date: "8/13/2019 01:31:16", shift: "A3", acuity: 5 },
  { date: "8/13/2019 01:31:27", shift: "B3", acuity: 3 },
  { date: "8/13/2019 02:03:05", shift: "A3", acuity: 4 },
  { date: "8/13/2019 02:09:46", shift: "B3", acuity: 6 },
  { date: "8/13/2019 02:44:00", shift: "A3", acuity: 3 },
  { date: "8/13/2019 03:10:43", shift: "B3", acuity: 3 },
  { date: "8/13/2019 03:10:59", shift: "A3", acuity: 3 },
  { date: "8/13/2019 03:19:50", shift: "B3", acuity: 3 },
  { date: "8/13/2019 03:58:47", shift: "A3", acuity: 3 },
  { date: "8/13/2019 04:35:28", shift: "B3", acuity: 3 },
  { date: "8/13/2019 05:19:59", shift: "A1", acuity: 3 },
  { date: "8/13/2019 05:20:15", shift: "B3", acuity: 4 },
  { date: "8/13/2019 05:20:31", shift: "A1", acuity: 3 },
  { date: "8/13/2019 05:38:08", shift: "A1", acuity: 4 },
  { date: "8/13/2019 05:43:29", shift: "A1", acuity: 4 },
  { date: "8/13/2019 05:44:03", shift: "B3", acuity: 4 },
  { date: "8/13/2019 05:44:19", shift: "A1", acuity: 6 },
  { date: "8/13/2019 06:30:38", shift: "B1", acuity: 6 },
  { date: "8/13/2019 06:49:12", shift: "B1", acuity: 4 },
  { date: "8/13/2019 06:49:26", shift: "B1", acuity: 3 },
  { date: "8/13/2019 07:17:42", shift: "A1", acuity: 3 },
  { date: "8/13/2019 07:21:31", shift: "B1", acuity: 6 },
  { date: "8/13/2019 07:32:45", shift: "A1", acuity: 4 },
  { date: "8/13/2019 07:55:43", shift: "B1", acuity: 3 },
  { date: "8/13/2019 07:55:55", shift: "A1", acuity: 3 },
  { date: "8/13/2019 08:22:23", shift: "B1", acuity: 6 },
  { date: "8/13/2019 08:46:57", shift: "C1", acuity: 3 },
  { date: "8/13/2019 08:55:48", shift: "A1", acuity: 4 },
  { date: "8/13/2019 09:01:21", shift: "C1", acuity: 5 },
  { date: "8/13/2019 09:01:57", shift: "C1", acuity: 3 },
  { date: "8/13/2019 09:03:55", shift: "B1", acuity: 3 },
  { date: "8/13/2019 09:06:25", shift: "A1", acuity: 6 },
  { date: "8/13/2019 09:11:53", shift: "C1", acuity: 3 },
  { date: "8/13/2019 09:17:36", shift: "B1", acuity: 6 },
  { date: "8/13/2019 09:18:52", shift: "A1", acuity: 5 },
  { date: "8/13/2019 09:24:20", shift: "C1", acuity: 3 },
  { date: "8/13/2019 09:40:18", shift: "B1", acuity: 2 },
  { date: "8/13/2019 09:47:43", shift: "A1", acuity: 6 },
  { date: "8/13/2019 10:12:16", shift: "D1", acuity: 3 },
  { date: "8/13/2019 10:12:27", shift: "C1", acuity: 4 },
  { date: "8/13/2019 10:21:24", shift: "B1", acuity: 3 },
  { date: "8/13/2019 10:49:01", shift: "D1", acuity: 3 },
  { date: "8/13/2019 10:49:11", shift: "D1", acuity: 6 },
  { date: "8/13/2019 10:49:21", shift: "A1", acuity: 3 },
  { date: "8/13/2019 10:49:31", shift: "C1", acuity: 3 },
  { date: "8/13/2019 10:49:40", shift: "B1", acuity: 3 },
  { date: "8/13/2019 11:24:37", shift: "D1", acuity: 3 },
  { date: "8/13/2019 11:36:29", shift: "A1", acuity: 3 },
  { date: "8/13/2019 11:51:54", shift: "C1", acuity: 3 },
  { date: "8/13/2019 12:05:03", shift: "B1", acuity: 6 },
  { date: "8/13/2019 12:17:37", shift: "D1", acuity: 3 },
  { date: "8/13/2019 12:33:57", shift: "A1", acuity: 4 },
  { date: "8/13/2019 12:34:19", shift: "C1", acuity: 6 },
  { date: "8/13/2019 12:51:24", shift: "B1", acuity: 3 },
  { date: "8/13/2019 12:54:47", shift: "D1", acuity: 6 },
  { date: "8/13/2019 13:02:17", shift: "A2", acuity: 6 },
  { date: "8/13/2019 13:09:09", shift: "C1", acuity: 3 },
  { date: "8/13/2019 13:31:03", shift: "A2", acuity: 3 },
  { date: "8/13/2019 13:31:17", shift: "A2", acuity: 4 },
  { date: "8/13/2019 13:56:17", shift: "B1", acuity: 6 },
  { date: "8/13/2019 14:00:13", shift: "B2", acuity: 3 },
  { date: "8/13/2019 14:13:39", shift: "D1", acuity: 3 },
  { date: "8/13/2019 14:17:49", shift: "C1", acuity: 6 },
  { date: "8/13/2019 14:17:58", shift: "A2", acuity: 6 },
  { date: "8/13/2019 14:18:22", shift: "B2", acuity: 6 },
  { date: "8/13/2019 14:30:18", shift: "B2", acuity: 6 },
  { date: "8/13/2019 14:38:28", shift: "B2", acuity: 2 },
  { date: "8/13/2019 14:46:47", shift: "D1", acuity: 6 },
  { date: "8/13/2019 14:52:49", shift: "C1", acuity: 6 },
  { date: "8/13/2019 15:04:30", shift: "A2", acuity: 3 },
  { date: "8/13/2019 15:10:01", shift: "B2", acuity: 3 },
  { date: "8/13/2019 15:14:35", shift: "D1", acuity: 3 },
  { date: "8/13/2019 15:19:46", shift: "C1", acuity: 3 },
  { date: "8/13/2019 15:21:10", shift: "A2", acuity: 6 },
  { date: "8/13/2019 15:40:12", shift: "B2", acuity: 6 },
  { date: "8/13/2019 15:40:26", shift: "D1", acuity: 6 },
  { date: "8/13/2019 15:47:18", shift: "C1", acuity: 6 },
  { date: "8/13/2019 16:09:08", shift: "A2", acuity: 3 },
  { date: "8/13/2019 16:15:01", shift: "B2", acuity: 3 },
  { date: "8/13/2019 16:32:48", shift: "C2", acuity: 6 },
  { date: "8/13/2019 16:39:59", shift: "D1", acuity: 6 },
  { date: "8/13/2019 16:56:29", shift: "A2", acuity: 6 },
  { date: "8/13/2019 17:15:07", shift: "C2", acuity: 6 },
  { date: "8/13/2019 17:22:57", shift: "C2", acuity: 3 },
  { date: "8/13/2019 17:23:10", shift: "B2", acuity: 6 },
  { date: "8/13/2019 17:53:14", shift: "D1", acuity: 6 },
  { date: "8/13/2019 17:54:08", shift: "A2", acuity: 6 },
  { date: "8/13/2019 17:54:21", shift: "C2", acuity: 6 },
  { date: "8/13/2019 17:55:31", shift: "B2", acuity: 2 },
  { date: "8/13/2019 18:01:45", shift: "D2", acuity: 3 },
  { date: "8/13/2019 18:10:24", shift: "A2", acuity: 6 },
  { date: "8/13/2019 18:11:57", shift: "C2", acuity: 3 },
  { date: "8/13/2019 18:47:03", shift: "D2", acuity: 3 },
  { date: "8/13/2019 18:57:22", shift: "D2", acuity: 6 },
  { date: "8/13/2019 18:59:17", shift: "B2", acuity: 3 },
  { date: "8/13/2019 18:59:35", shift: "A2", acuity: 6 },
  { date: "8/13/2019 19:14:23", shift: "C2", acuity: 2 },
  { date: "8/13/2019 19:14:33", shift: "D2", acuity: 3 },
  { date: "8/13/2019 19:29:30", shift: "B2", acuity: 6 },
  { date: "8/13/2019 19:42:28", shift: "A2", acuity: 6 },
  { date: "8/13/2019 19:42:42", shift: "C2", acuity: 6 },
  { date: "8/13/2019 19:46:41", shift: "D2", acuity: 3 },
  { date: "8/13/2019 19:46:59", shift: "B2", acuity: 6 },
  { date: "8/13/2019 19:59:26", shift: "A2", acuity: 6 },
  { date: "8/13/2019 20:14:37", shift: "C2", acuity: 3 },
  { date: "8/13/2019 20:14:53", shift: "D2", acuity: 3 },
  { date: "8/13/2019 20:15:06", shift: "B2", acuity: 3 },
  { date: "8/13/2019 20:20:01", shift: "A2", acuity: 6 },
  { date: "8/13/2019 20:31:03", shift: "C2", acuity: 4 },
  { date: "8/13/2019 20:31:31", shift: "D2", acuity: 3 },
  { date: "8/13/2019 20:50:19", shift: "B2", acuity: 6 },
  { date: "8/13/2019 20:59:19", shift: "A2", acuity: 3 },
  { date: "8/13/2019 20:59:42", shift: "C2", acuity: 6 },
  { date: "8/13/2019 21:07:06", shift: "A3", acuity: 3 },
  { date: "8/13/2019 21:10:38", shift: "D2", acuity: 3 },
  { date: "8/13/2019 21:51:02", shift: "A3", acuity: 3 },
  { date: "8/13/2019 21:54:35", shift: "A3", acuity: 6 },
  { date: "8/13/2019 22:02:33", shift: "B3", acuity: 3 },
  { date: "8/13/2019 22:10:05", shift: "C2", acuity: 4 },
  { date: "8/13/2019 22:18:02", shift: "D2", acuity: 6 },
  { date: "8/13/2019 22:21:16", shift: "A3", acuity: 6 },
  { date: "8/13/2019 22:33:15", shift: "B3", acuity: 3 },
  { date: "8/13/2019 22:37:22", shift: "B3", acuity: 3 },
  { date: "8/13/2019 22:47:10", shift: "C2", acuity: 3 },
  { date: "8/13/2019 23:01:42", shift: "D2", acuity: 6 },
  { date: "8/13/2019 23:16:54", shift: "A3", acuity: 6 },
  { date: "8/13/2019 23:23:59", shift: "B3", acuity: 3 },
  { date: "8/13/2019 23:37:03", shift: "C2", acuity: 6 },
  { date: "8/13/2019 23:43:56", shift: "D2", acuity: 6 },
  { date: "8/13/2019 23:51:51", shift: "A3", acuity: 3 },
  { date: "8/13/2019 23:53:50", shift: "B3", acuity: 4 },
  { date: "8/14/2019 00:04:39", shift: "D2", acuity: 4 },
  { date: "8/14/2019 00:15:58", shift: "A3", acuity: 4 },
  { date: "8/14/2019 00:16:14", shift: "B3", acuity: 6 },
  { date: "8/14/2019 00:29:35", shift: "D2", acuity: 3 },
  { date: "8/14/2019 00:52:58", shift: "A3", acuity: 3 },
  { date: "8/14/2019 01:14:15", shift: "B3", acuity: 4 },
  { date: "8/14/2019 01:14:59", shift: "D2", acuity: 6 },
  { date: "8/14/2019 01:38:04", shift: "A3", acuity: 3 },
  { date: "8/14/2019 01:43:11", shift: "B3", acuity: 4 },
  { date: "8/14/2019 01:43:21", shift: "A3", acuity: 3 },
  { date: "8/14/2019 01:57:48", shift: "B3", acuity: 6 },
  { date: "8/14/2019 01:58:10", shift: "A3", acuity: 4 },
  { date: "8/14/2019 02:06:03", shift: "B3", acuity: 4 },
  { date: "8/14/2019 02:20:04", shift: "A3", acuity: 3 },
  { date: "8/14/2019 02:21:46", shift: "B3", acuity: 6 },
  { date: "8/14/2019 02:50:31", shift: "A3", acuity: 3 },
  { date: "8/14/2019 03:46:53", shift: "B3", acuity: 3 },
  { date: "8/14/2019 03:54:58", shift: "A3", acuity: 3 },
  { date: "8/14/2019 03:59:19", shift: "B3", acuity: 3 },
  { date: "8/14/2019 04:15:23", shift: "A3", acuity: 4 },
  { date: "8/14/2019 04:15:39", shift: "B3", acuity: 3 },
  { date: "8/14/2019 04:15:55", shift: "A3", acuity: 4 },
  { date: "8/14/2019 04:33:56", shift: "B3", acuity: 3 },
  { date: "8/14/2019 05:04:16", shift: "A1", acuity: 3 },
  { date: "8/14/2019 05:30:23", shift: "A1", acuity: 4 },
  { date: "8/14/2019 05:39:04", shift: "A1", acuity: 4 },
  { date: "8/14/2019 05:45:36", shift: "B3", acuity: 3 },
  { date: "8/14/2019 06:00:28", shift: "B1", acuity: 4 },
  { date: "8/14/2019 06:20:12", shift: "A1", acuity: 3 },
  { date: "8/14/2019 06:25:40", shift: "B1", acuity: 3 },
  { date: "8/14/2019 06:37:22", shift: "B1", acuity: 3 },
  { date: "8/14/2019 07:18:10", shift: "B1", acuity: 3 },
  { date: "8/14/2019 07:27:19", shift: "A1", acuity: 3 },
  { date: "8/14/2019 07:42:17", shift: "B1", acuity: 4 },
  { date: "8/14/2019 08:08:30", shift: "A1", acuity: 6 },
  { date: "8/14/2019 08:18:23", shift: "B1", acuity: 2 },
  { date: "8/14/2019 08:21:53", shift: "A1", acuity: 3 },
  { date: "8/14/2019 09:00:27", shift: "C1", acuity: 3 },
  { date: "8/14/2019 09:04:24", shift: "C1", acuity: 4 },
  { date: "8/14/2019 09:10:55", shift: "C1", acuity: 3 },
  { date: "8/14/2019 09:12:18", shift: "B1", acuity: 4 },
  { date: "8/14/2019 09:25:11", shift: "A1", acuity: 3 },
  { date: "8/14/2019 09:56:20", shift: "C1", acuity: 6 },
  { date: "8/14/2019 10:09:32", shift: "D1", acuity: 6 },
  { date: "8/14/2019 10:20:45", shift: "B1", acuity: 6 },
  { date: "8/14/2019 10:21:00", shift: "A1", acuity: 4 },
  { date: "8/14/2019 10:21:11", shift: "C1", acuity: 3 },
  { date: "8/14/2019 10:34:11", shift: "D1", acuity: 6 },
  { date: "8/14/2019 10:52:59", shift: "D1", acuity: 6 },
  { date: "8/14/2019 10:53:13", shift: "B1", acuity: 3 },
  { date: "8/14/2019 10:53:29", shift: "A1", acuity: 3 },
  { date: "8/14/2019 11:14:45", shift: "C1", acuity: 6 },
  { date: "8/14/2019 11:21:47", shift: "D1", acuity: 3 },
  { date: "8/14/2019 11:45:12", shift: "B1", acuity: 4 },
  { date: "8/14/2019 11:46:08", shift: "A1", acuity: 3 },
  { date: "8/14/2019 12:00:13", shift: "C1", acuity: 3 },
  { date: "8/14/2019 12:00:28", shift: "D1", acuity: 3 },
  { date: "8/14/2019 12:00:43", shift: "B1", acuity: 3 },
  { date: "8/14/2019 12:11:47", shift: "A1", acuity: 6 },
  { date: "8/14/2019 12:26:28", shift: "C1", acuity: 3 },
  { date: "8/14/2019 12:37:23", shift: "D1", acuity: 3 },
  { date: "8/14/2019 13:01:35", shift: "A2", acuity: 3 },
  { date: "8/14/2019 13:13:48", shift: "B1", acuity: 4 },
  { date: "8/14/2019 13:22:23", shift: "C1", acuity: 3 },
  { date: "8/14/2019 13:27:06", shift: "D1", acuity: 3 },
  { date: "8/14/2019 13:28:14", shift: "A2", acuity: 3 },
  { date: "8/14/2019 13:51:59", shift: "A2", acuity: 6 },
  { date: "8/14/2019 14:02:15", shift: "A2", acuity: 4 },
  { date: "8/14/2019 14:18:33", shift: "B2", acuity: 3 },
  { date: "8/14/2019 14:21:46", shift: "C1", acuity: 6 },
  { date: "8/14/2019 14:35:24", shift: "B2", acuity: 6 },
  { date: "8/14/2019 14:48:53", shift: "B2", acuity: 6 },
  { date: "8/14/2019 14:51:24", shift: "D1", acuity: 6 },
  { date: "8/14/2019 15:08:26", shift: "A2", acuity: 6 },
  { date: "8/14/2019 15:09:08", shift: "C1", acuity: 6 },
  { date: "8/14/2019 15:12:54", shift: "B2", acuity: 3 },
  { date: "8/14/2019 15:13:08", shift: "D1", acuity: 4 },
  { date: "8/14/2019 15:17:59", shift: "A2", acuity: 3 },
  { date: "8/14/2019 15:26:57", shift: "C1", acuity: 6 },
  { date: "8/14/2019 15:33:48", shift: "B2", acuity: 3 },
  { date: "8/14/2019 15:46:10", shift: "D1", acuity: 6 },
  { date: "8/14/2019 16:01:04", shift: "A2", acuity: 6 },
  { date: "8/14/2019 16:03:58", shift: "C1", acuity: 6 },
  { date: "8/14/2019 16:16:30", shift: "B2", acuity: 3 },
  { date: "8/14/2019 16:22:51", shift: "D1", acuity: 6 },
  { date: "8/14/2019 16:40:09", shift: "C2", acuity: 4 },
  { date: "8/14/2019 16:45:48", shift: "A2", acuity: 3 },
  { date: "8/14/2019 16:45:57", shift: "B2", acuity: 6 },
  { date: "8/14/2019 16:48:30", shift: "D1", acuity: 3 },
  { date: "8/14/2019 17:04:14", shift: "C2", acuity: 4 },
  { date: "8/14/2019 17:04:27", shift: "C2", acuity: 6 },
  { date: "8/14/2019 17:23:47", shift: "A2", acuity: 4 },
  { date: "8/14/2019 17:42:52", shift: "B2", acuity: 6 },
  { date: "8/14/2019 17:43:15", shift: "D1", acuity: 6 },
  { date: "8/14/2019 17:44:52", shift: "C2", acuity: 3 },
  { date: "8/14/2019 18:00:34", shift: "D2", acuity: 3 },
  { date: "8/14/2019 18:18:53", shift: "A2", acuity: 6 },
  { date: "8/14/2019 18:19:10", shift: "B2", acuity: 3 },
  { date: "8/14/2019 18:21:21", shift: "C2", acuity: 3 },
  { date: "8/14/2019 18:42:05", shift: "D2", acuity: 6 },
  { date: "8/14/2019 18:51:49", shift: "D2", acuity: 3 },
  { date: "8/14/2019 19:17:24", shift: "A2", acuity: 3 },
  { date: "8/14/2019 19:17:39", shift: "B2", acuity: 3 },
  { date: "8/14/2019 19:24:08", shift: "C2", acuity: 6 },
  { date: "8/14/2019 19:36:26", shift: "D2", acuity: 6 },
  { date: "8/14/2019 19:38:49", shift: "A2", acuity: 6 },
  { date: "8/14/2019 19:47:47", shift: "B2", acuity: 3 },
  { date: "8/14/2019 19:48:04", shift: "C2", acuity: 6 },
  { date: "8/14/2019 19:56:32", shift: "D2", acuity: 3 },
  { date: "8/14/2019 19:56:52", shift: "A2", acuity: 6 },
  { date: "8/14/2019 20:05:03", shift: "B2", acuity: 3 },
  { date: "8/14/2019 20:20:39", shift: "C2", acuity: 6 },
  { date: "8/14/2019 20:21:03", shift: "D2", acuity: 3 },
  { date: "8/14/2019 20:42:39", shift: "A2", acuity: 3 },
  { date: "8/14/2019 20:43:03", shift: "B2", acuity: 3 },
  { date: "8/14/2019 20:48:19", shift: "C2", acuity: 6 },
  { date: "8/14/2019 20:48:57", shift: "D2", acuity: 3 },
  { date: "8/14/2019 20:56:46", shift: "A2", acuity: 3 },
  { date: "8/14/2019 21:16:10", shift: "A3", acuity: 3 },
  { date: "8/14/2019 21:20:03", shift: "B2", acuity: 3 },
  { date: "8/14/2019 21:20:26", shift: "C2", acuity: 3 },
  { date: "8/14/2019 21:37:32", shift: "A3", acuity: 6 },
  { date: "8/14/2019 22:00:02", shift: "A3", acuity: 3 },
  { date: "8/14/2019 22:01:56", shift: "B3", acuity: 3 },
  { date: "8/14/2019 22:07:12", shift: "D2", acuity: 3 },
  { date: "8/14/2019 22:19:07", shift: "C2", acuity: 3 },
  { date: "8/14/2019 22:31:33", shift: "B3", acuity: 3 },
  { date: "8/14/2019 22:41:17", shift: "B3", acuity: 4 },
  { date: "8/14/2019 22:41:33", shift: "A3", acuity: 4 },
  { date: "8/14/2019 23:06:50", shift: "D2", acuity: 3 },
  { date: "8/14/2019 23:07:07", shift: "C2", acuity: 3 },
  { date: "8/14/2019 23:09:12", shift: "B3", acuity: 3 },
  { date: "8/14/2019 23:10:02", shift: "A3", acuity: 6 },
  { date: "8/14/2019 23:21:32", shift: "D2", acuity: 4 },
  { date: "8/14/2019 23:21:45", shift: "C2", acuity: 3 },
  { date: "8/14/2019 23:28:29", shift: "B3", acuity: 3 },
  { date: "8/14/2019 23:46:25", shift: "A3", acuity: 3 },
  { date: "8/14/2019 23:46:43", shift: "D2", acuity: 3 },
  { date: "8/15/2019 00:02:19", shift: "B3", acuity: 3 },
  { date: "8/15/2019 00:02:38", shift: "A3", acuity: 6 },
  { date: "8/15/2019 00:05:14", shift: "D2", acuity: 3 },
  { date: "8/15/2019 00:29:20", shift: "B3", acuity: 4 },
  { date: "8/15/2019 00:41:03", shift: "A3", acuity: 6 },
  { date: "8/15/2019 00:41:18", shift: "D2", acuity: 4 },
  { date: "8/15/2019 00:41:35", shift: "B3", acuity: 3 },
  { date: "8/15/2019 01:06:25", shift: "A3", acuity: 3 },
  { date: "8/15/2019 01:06:50", shift: "D2", acuity: 4 },
  { date: "8/15/2019 01:17:15", shift: "B3", acuity: 3 },
  { date: "8/15/2019 01:21:19", shift: "A3", acuity: 3 },
  { date: "8/15/2019 01:26:16", shift: "D2", acuity: 6 },
  { date: "8/15/2019 01:40:50", shift: "B3", acuity: 6 },
  { date: "8/15/2019 01:41:21", shift: "A3", acuity: 3 },
  { date: "8/15/2019 01:56:55", shift: "B3", acuity: 3 },
  { date: "8/15/2019 02:14:11", shift: "A3", acuity: 3 },
  { date: "8/15/2019 02:14:51", shift: "B3", acuity: 3 },
  { date: "8/15/2019 02:26:53", shift: "A3", acuity: 3 },
  { date: "8/15/2019 02:29:28", shift: "B3", acuity: 4 },
  { date: "8/15/2019 02:30:47", shift: "A3", acuity: 3 },
  { date: "8/15/2019 02:47:47", shift: "B3", acuity: 6 },
  { date: "8/15/2019 03:28:37", shift: "A3", acuity: 3 },
  { date: "8/15/2019 03:56:31", shift: "B3", acuity: 6 },
  { date: "8/15/2019 04:22:21", shift: "A3", acuity: 3 },
  { date: "8/15/2019 04:29:24", shift: "B3", acuity: 3 },
  { date: "8/15/2019 05:01:06", shift: "A1", acuity: 3 },
  { date: "8/15/2019 05:07:56", shift: "B3", acuity: 3 },
  { date: "8/15/2019 05:28:07", shift: "A1", acuity: 4 },
  { date: "8/15/2019 06:06:05", shift: "A1", acuity: 5 },
  { date: "8/15/2019 06:13:20", shift: "A1", acuity: 4 },
  { date: "8/15/2019 06:30:06", shift: "B1", acuity: 3 },
  { date: "8/15/2019 07:38:55", shift: "B1", acuity: 6 },
  { date: "8/15/2019 08:03:21", shift: "A1", acuity: 3 },
  { date: "8/15/2019 08:14:14", shift: "B1", acuity: 3 },
  { date: "8/15/2019 08:14:31", shift: "A1", acuity: 3 },
  { date: "8/15/2019 08:39:20", shift: "C1", acuity: 6 },
  { date: "8/15/2019 08:47:18", shift: "B1", acuity: 3 },
  { date: "8/15/2019 08:48:55", shift: "A1", acuity: 3 },
  { date: "8/15/2019 09:17:09", shift: "C1", acuity: 3 },
  { date: "8/15/2019 09:28:43", shift: "C1", acuity: 6 },
  { date: "8/15/2019 09:29:03", shift: "B1", acuity: 3 },
  { date: "8/15/2019 09:36:02", shift: "A1", acuity: 3 },
  { date: "8/15/2019 09:38:51", shift: "C1", acuity: 4 },
  { date: "8/15/2019 09:41:19", shift: "B1", acuity: 6 },
  { date: "8/15/2019 09:53:48", shift: "A1", acuity: 4 },
  { date: "8/15/2019 09:54:08", shift: "C1", acuity: 3 },
  { date: "8/15/2019 10:09:18", shift: "D1", acuity: 3 },
  { date: "8/15/2019 10:14:14", shift: "B1", acuity: 3 },
  { date: "8/15/2019 10:18:46", shift: "A1", acuity: 2 },
  { date: "8/15/2019 10:20:29", shift: "C1", acuity: 4 },
  { date: "8/15/2019 10:47:13", shift: "D1", acuity: 3 },
  { date: "8/15/2019 11:13:43", shift: "D1", acuity: 3 },
  { date: "8/15/2019 11:14:04", shift: "B1", acuity: 6 },
  { date: "8/15/2019 11:18:18", shift: "A1", acuity: 4 },
  { date: "8/15/2019 11:25:32", shift: "C1", acuity: 3 },
  { date: "8/15/2019 11:35:49", shift: "D1", acuity: 3 },
  { date: "8/15/2019 11:42:54", shift: "B1", acuity: 3 },
  { date: "8/15/2019 11:47:13", shift: "A1", acuity: 6 },
  { date: "8/15/2019 12:07:41", shift: "C1", acuity: 6 },
  { date: "8/15/2019 12:11:16", shift: "D1", acuity: 3 },
  { date: "8/15/2019 12:20:09", shift: "B1", acuity: 3 },
  { date: "8/15/2019 12:41:57", shift: "A1", acuity: 6 },
  { date: "8/15/2019 12:46:00", shift: "C1", acuity: 6 },
  { date: "8/15/2019 13:01:06", shift: "A2", acuity: 6 },
  { date: "8/15/2019 13:23:17", shift: "D1", acuity: 3 },
  { date: "8/15/2019 13:23:45", shift: "B1", acuity: 3 },
  { date: "8/15/2019 13:33:11", shift: "A2", acuity: 3 },
  { date: "8/15/2019 13:42:48", shift: "A2", acuity: 6 },
  { date: "8/15/2019 14:04:22", shift: "B2", acuity: 3 },
  { date: "8/15/2019 14:24:41", shift: "C1", acuity: 6 },
  { date: "8/15/2019 14:25:55", shift: "D1", acuity: 6 },
  { date: "8/15/2019 14:26:35", shift: "A2", acuity: 3 },
  { date: "8/15/2019 14:40:22", shift: "B2", acuity: 6 },
  { date: "8/15/2019 14:40:56", shift: "B2", acuity: 3 },
  { date: "8/15/2019 14:59:56", shift: "C1", acuity: 3 },
  { date: "8/15/2019 15:09:05", shift: "D1", acuity: 3 },
  { date: "8/15/2019 15:27:26", shift: "A2", acuity: 3 },
  { date: "8/15/2019 15:39:44", shift: "B2", acuity: 6 },
  { date: "8/15/2019 15:41:04", shift: "C1", acuity: 6 },
  { date: "8/15/2019 15:42:09", shift: "D1", acuity: 3 },
  { date: "8/15/2019 15:44:02", shift: "A2", acuity: 6 },
  { date: "8/15/2019 15:58:36", shift: "B2", acuity: 3 },
  { date: "8/15/2019 16:02:02", shift: "C1", acuity: 3 },
  { date: "8/15/2019 16:02:17", shift: "D1", acuity: 3 },
  { date: "8/15/2019 16:28:40", shift: "A2", acuity: 3 },
  { date: "8/15/2019 16:41:51", shift: "C2", acuity: 6 },
  { date: "8/15/2019 16:42:44", shift: "B2", acuity: 6 },
  { date: "8/15/2019 16:48:47", shift: "D1", acuity: 3 },
  { date: "8/15/2019 17:02:52", shift: "C2", acuity: 6 },
  { date: "8/15/2019 17:18:58", shift: "C2", acuity: 3 },
  { date: "8/15/2019 17:19:35", shift: "A2", acuity: 3 },
  { date: "8/15/2019 17:19:54", shift: "B2", acuity: 6 },
  { date: "8/15/2019 17:25:06", shift: "D1", acuity: 6 },
  { date: "8/15/2019 17:29:34", shift: "C2", acuity: 6 },
  { date: "8/15/2019 17:31:01", shift: "A2", acuity: 3 },
  { date: "8/15/2019 17:31:30", shift: "B2", acuity: 6 },
  { date: "8/15/2019 17:43:45", shift: "D1", acuity: 6 },
  { date: "8/15/2019 17:48:07", shift: "C2", acuity: 3 },
  { date: "8/15/2019 17:52:22", shift: "A2", acuity: 6 },
  { date: "8/15/2019 18:01:35", shift: "D2", acuity: 6 },
  { date: "8/15/2019 18:24:15", shift: "B2", acuity: 6 },
  { date: "8/15/2019 18:34:44", shift: "D2", acuity: 6 },
  { date: "8/15/2019 18:45:55", shift: "D2", acuity: 6 },
  { date: "8/15/2019 18:58:29", shift: "C2", acuity: 6 },
  { date: "8/15/2019 18:59:12", shift: "A2", acuity: 6 },
  { date: "8/15/2019 19:16:04", shift: "B2", acuity: 6 },
  { date: "8/15/2019 19:18:35", shift: "D2", acuity: 6 },
  { date: "8/15/2019 19:18:55", shift: "C2", acuity: 3 },
  { date: "8/15/2019 19:21:22", shift: "A2", acuity: 6 },
  { date: "8/15/2019 19:26:27", shift: "B2", acuity: 6 },
  { date: "8/15/2019 19:26:58", shift: "D2", acuity: 6 },
  { date: "8/15/2019 19:49:24", shift: "C2", acuity: 7 },
  { date: "8/15/2019 19:56:16", shift: "A2", acuity: 6 },
  { date: "8/15/2019 19:56:59", shift: "B2", acuity: 6 },
  { date: "8/15/2019 20:04:29", shift: "D2", acuity: 6 },
  { date: "8/15/2019 20:04:57", shift: "C2", acuity: 3 },
  { date: "8/15/2019 20:10:52", shift: "A2", acuity: 6 },
  { date: "8/15/2019 20:16:32", shift: "B2", acuity: 3 },
  { date: "8/15/2019 20:25:03", shift: "D2", acuity: 6 },
  { date: "8/15/2019 20:36:46", shift: "C2", acuity: 3 },
  { date: "8/15/2019 20:47:15", shift: "A2", acuity: 3 },
  { date: "8/15/2019 21:14:50", shift: "A3", acuity: 6 },
  { date: "8/15/2019 21:15:25", shift: "B2", acuity: 2 },
  { date: "8/15/2019 21:25:35", shift: "D2", acuity: 3 },
  { date: "8/15/2019 21:26:38", shift: "C2", acuity: 3 },
  { date: "8/15/2019 21:27:01", shift: "A3", acuity: 3 },
  { date: "8/15/2019 21:27:40", shift: "B2", acuity: 6 },
  { date: "8/15/2019 21:33:47", shift: "A3", acuity: 3 },
  { date: "8/15/2019 21:52:06", shift: "A3", acuity: 6 },
  { date: "8/15/2019 21:52:58", shift: "D2", acuity: 3 },
  { date: "8/15/2019 22:03:01", shift: "B3", acuity: 3 },
  { date: "8/15/2019 22:16:44", shift: "C2", acuity: 6 },
  { date: "8/15/2019 22:31:40", shift: "B3", acuity: 3 },
  { date: "8/15/2019 22:41:26", shift: "B3", acuity: 6 },
  { date: "8/15/2019 22:55:31", shift: "A3", acuity: 3 },
  { date: "8/15/2019 23:10:18", shift: "D2", acuity: 6 },
  { date: "8/15/2019 23:15:52", shift: "C2", acuity: 3 },
  { date: "8/15/2019 23:18:56", shift: "B3", acuity: 3 },
  { date: "8/15/2019 23:36:04", shift: "A3", acuity: 3 },
  { date: "8/15/2019 23:38:13", shift: "D2", acuity: 3 },
  { date: "8/15/2019 23:38:36", shift: "C2", acuity: 3 },
  { date: "8/15/2019 23:39:24", shift: "B3", acuity: 6 },
  { date: "8/16/2019 00:05:32", shift: "A3", acuity: 3 },
  { date: "8/16/2019 00:17:05", shift: "D2", acuity: 4 },
  { date: "8/16/2019 00:22:28", shift: "B3", acuity: 3 },
  { date: "8/16/2019 00:31:20", shift: "A3", acuity: 4 },
  { date: "8/16/2019 01:00:35", shift: "D2", acuity: 4 },
  { date: "8/16/2019 01:01:06", shift: "B3", acuity: 3 },
  { date: "8/16/2019 01:02:06", shift: "A3", acuity: 3 },
  { date: "8/16/2019 01:30:16", shift: "B3", acuity: 3 },
  { date: "8/16/2019 01:43:15", shift: "A3", acuity: 4 },
  { date: "8/16/2019 02:03:15", shift: "B3", acuity: 6 },
  { date: "8/16/2019 02:03:35", shift: "A3", acuity: 4 },
  { date: "8/16/2019 02:03:54", shift: "B3", acuity: 3 },
  { date: "8/16/2019 02:44:30", shift: "A3", acuity: 4 },
  { date: "8/16/2019 03:09:31", shift: "B3", acuity: 3 },
  { date: "8/16/2019 03:45:28", shift: "A3", acuity: 3 },
  { date: "8/16/2019 04:03:33", shift: "B3", acuity: 3 },
  { date: "8/16/2019 04:23:46", shift: "A3", acuity: 5 },
  { date: "8/16/2019 04:37:26", shift: "B3", acuity: 6 },
  { date: "8/16/2019 04:49:51", shift: "A3", acuity: 6 },
  { date: "8/16/2019 05:29:25", shift: "A1", acuity: 6 },
  { date: "8/16/2019 05:32:49", shift: "A1", acuity: 3 },
  { date: "8/16/2019 06:27:39", shift: "A1", acuity: 4 },
  { date: "8/16/2019 06:54:35", shift: "A1", acuity: 4 },
  { date: "8/16/2019 07:50:13", shift: "B1", acuity: 3 },
  { date: "8/16/2019 07:57:20", shift: "B1", acuity: 3 },
  { date: "8/16/2019 08:00:47", shift: "A1", acuity: 3 },
  { date: "8/16/2019 08:14:52", shift: "B1", acuity: 3 },
  { date: "8/16/2019 08:18:29", shift: "A1", acuity: 3 },
  { date: "8/16/2019 08:45:00", shift: "B1", acuity: 3 },
  { date: "8/16/2019 08:45:00", shift: "C1", acuity: 3 },
  { date: "8/16/2019 08:57:09", shift: "A1", acuity: 6 },
  { date: "8/16/2019 09:11:18", shift: "C1", acuity: 6 },
  { date: "8/16/2019 09:11:28", shift: "C1", acuity: 6 },
  { date: "8/16/2019 09:11:38", shift: "B1", acuity: 6 },
  { date: "8/16/2019 09:33:39", shift: "A1", acuity: 6 },
  { date: "8/16/2019 09:35:46", shift: "C1", acuity: 6 },
  { date: "8/16/2019 09:49:57", shift: "B1", acuity: 3 },
  { date: "8/16/2019 09:50:12", shift: "A1", acuity: 6 },
  { date: "8/16/2019 10:03:41", shift: "D1", acuity: 6 },
  { date: "8/16/2019 10:04:27", shift: "C1", acuity: 6 },
  { date: "8/16/2019 10:29:38", shift: "B1", acuity: 3 },
  { date: "8/16/2019 10:33:07", shift: "D1", acuity: 3 },
  { date: "8/16/2019 10:55:50", shift: "D1", acuity: 6 },
  { date: "8/16/2019 10:55:57", shift: "A1", acuity: 6 },
  { date: "8/16/2019 10:58:50", shift: "C1", acuity: 3 },
  { date: "8/16/2019 10:59:02", shift: "B1", acuity: 3 },
  { date: "8/16/2019 11:25:44", shift: "D1", acuity: 3 },
  { date: "8/16/2019 11:25:53", shift: "A1", acuity: 6 },
  { date: "8/16/2019 11:44:34", shift: "C1", acuity: 6 },
  { date: "8/16/2019 11:56:11", shift: "B1", acuity: 6 },
  { date: "8/16/2019 12:12:41", shift: "D1", acuity: 6 },
  { date: "8/16/2019 12:28:31", shift: "A1", acuity: 6 },
  { date: "8/16/2019 12:28:41", shift: "C1", acuity: 6 },
  { date: "8/16/2019 12:49:35", shift: "B1", acuity: 3 },
  { date: "8/16/2019 12:56:02", shift: "D1", acuity: 6 },
  { date: "8/16/2019 13:10:11", shift: "A2", acuity: 3 },
  { date: "8/16/2019 13:11:37", shift: "C1", acuity: 3 },
  { date: "8/16/2019 13:14:45", shift: "B1", acuity: 6 },
  { date: "8/16/2019 13:16:17", shift: "D1", acuity: 3 },
  { date: "8/16/2019 13:18:38", shift: "A2", acuity: 3 },
  { date: "8/16/2019 13:30:55", shift: "A2", acuity: 6 },
  { date: "8/16/2019 13:47:42", shift: "A2", acuity: 3 },
  { date: "8/16/2019 14:01:00", shift: "B2", acuity: 6 },
  { date: "8/16/2019 14:11:58", shift: "C1", acuity: 6 },
  { date: "8/16/2019 14:18:03", shift: "D1", acuity: 6 },
  { date: "8/16/2019 14:19:34", shift: "A2", acuity: 6 },
  { date: "8/16/2019 14:19:44", shift: "B2", acuity: 3 },
  { date: "8/16/2019 14:39:21", shift: "B2", acuity: 6 },
  { date: "8/16/2019 14:39:36", shift: "B2", acuity: 3 },
  { date: "8/16/2019 14:55:07", shift: "C1", acuity: 3 },
  { date: "8/16/2019 14:55:18", shift: "D1", acuity: 3 },
  { date: "8/16/2019 14:55:41", shift: "A2", acuity: 3 },
  { date: "8/16/2019 14:59:21", shift: "B2", acuity: 3 },
  { date: "8/16/2019 15:12:34", shift: "C1", acuity: 6 },
  { date: "8/16/2019 15:37:35", shift: "D1", acuity: 6 },
  { date: "8/16/2019 15:42:17", shift: "A2", acuity: 6 },
  { date: "8/16/2019 15:50:13", shift: "B2", acuity: 3 },
  { date: "8/16/2019 16:14:13", shift: "C1", acuity: 6 },
  { date: "8/16/2019 16:16:09", shift: "D1", acuity: 6 },
  { date: "8/16/2019 16:30:10", shift: "C2", acuity: 3 },
  { date: "8/16/2019 16:30:33", shift: "A2", acuity: 6 },
  { date: "8/16/2019 16:31:51", shift: "B2", acuity: 6 },
  { date: "8/16/2019 16:43:56", shift: "D1", acuity: 3 },
  { date: "8/16/2019 17:00:28", shift: "C2", acuity: 3 },
  { date: "8/16/2019 17:00:57", shift: "C2", acuity: 6 },
  { date: "8/16/2019 17:01:07", shift: "A2", acuity: 3 },
  { date: "8/16/2019 17:05:37", shift: "B2", acuity: 6 },
  { date: "8/16/2019 17:05:54", shift: "D1", acuity: 3 },
  { date: "8/16/2019 17:08:52", shift: "C2", acuity: 6 },
  { date: "8/16/2019 17:10:00", shift: "A2", acuity: 3 },
  { date: "8/16/2019 17:14:40", shift: "B2", acuity: 6 },
  { date: "8/16/2019 17:15:24", shift: "D1", acuity: 3 },
  { date: "8/16/2019 17:25:23", shift: "C2", acuity: 3 },
  { date: "8/16/2019 17:45:02", shift: "A2", acuity: 6 },
  { date: "8/16/2019 18:33:10", shift: "D2", acuity: 3 },
  { date: "8/16/2019 18:33:16", shift: "D2", acuity: 3 },
  { date: "8/16/2019 18:33:31", shift: "D2", acuity: 6 },
  { date: "8/16/2019 18:33:38", shift: "B2", acuity: 3 },
  { date: "8/16/2019 18:38:52", shift: "C2", acuity: 3 },
  { date: "8/16/2019 18:51:25", shift: "A2", acuity: 3 },
  { date: "8/16/2019 19:14:43", shift: "D2", acuity: 6 },
  { date: "8/16/2019 19:26:11", shift: "B2", acuity: 6 },
  { date: "8/16/2019 19:27:42", shift: "C2", acuity: 4 },
  { date: "8/16/2019 19:29:49", shift: "A2", acuity: 3 },
  { date: "8/16/2019 19:35:34", shift: "D2", acuity: 6 },
  { date: "8/16/2019 19:35:50", shift: "B2", acuity: 3 },
  { date: "8/16/2019 19:39:55", shift: "C2", acuity: 3 },
  { date: "8/16/2019 19:51:57", shift: "A2", acuity: 6 },
  { date: "8/16/2019 20:01:39", shift: "D2", acuity: 6 },
  { date: "8/16/2019 20:01:56", shift: "B2", acuity: 3 },
  { date: "8/16/2019 20:10:04", shift: "C2", acuity: 3 },
  { date: "8/16/2019 20:23:33", shift: "A2", acuity: 6 },
  { date: "8/16/2019 20:31:35", shift: "D2", acuity: 3 },
  { date: "8/16/2019 20:32:08", shift: "B2", acuity: 3 },
  { date: "8/16/2019 20:33:38", shift: "C2", acuity: 3 },
  { date: "8/16/2019 20:39:56", shift: "A2", acuity: 3 },
  { date: "8/16/2019 20:50:35", shift: "D2", acuity: 3 },
  { date: "8/16/2019 20:55:33", shift: "B2", acuity: 3 },
  { date: "8/16/2019 21:00:13", shift: "A3", acuity: 3 },
  { date: "8/16/2019 21:11:12", shift: "C2", acuity: 6 },
  { date: "8/16/2019 21:13:30", shift: "D2", acuity: 3 },
  { date: "8/16/2019 21:13:54", shift: "B2", acuity: 3 },
  { date: "8/16/2019 21:27:01", shift: "A3", acuity: 6 },
  { date: "8/16/2019 21:31:25", shift: "A3", acuity: 4 },
  { date: "8/16/2019 21:31:44", shift: "A3", acuity: 3 },
  { date: "8/16/2019 21:32:17", shift: "C2", acuity: 4 },
  { date: "8/16/2019 21:55:02", shift: "D2", acuity: 3 },
  { date: "8/16/2019 22:01:53", shift: "B3", acuity: 6 },
  { date: "8/16/2019 22:27:15", shift: "A3", acuity: 3 },
  { date: "8/16/2019 22:27:44", shift: "C2", acuity: 6 },
  { date: "8/16/2019 22:42:00", shift: "B3", acuity: 6 },
  { date: "8/16/2019 22:43:29", shift: "B3", acuity: 6 },
  { date: "8/16/2019 23:00:47", shift: "D2", acuity: 3 },
  { date: "8/16/2019 23:06:56", shift: "A3", acuity: 6 },
  { date: "8/16/2019 23:08:00", shift: "C2", acuity: 3 },
  { date: "8/16/2019 23:23:40", shift: "B3", acuity: 2 },
  { date: "8/16/2019 23:37:46", shift: "D2", acuity: 6 },
  { date: "8/16/2019 23:51:39", shift: "A3", acuity: 5 },
  { date: "8/17/2019 00:03:34", shift: "B3", acuity: 3 },
  { date: "8/17/2019 00:03:58", shift: "D2", acuity: 3 },
  { date: "8/17/2019 00:33:34", shift: "A3", acuity: 3 },
  { date: "8/17/2019 00:53:22", shift: "B3", acuity: 4 },
  { date: "8/17/2019 01:14:23", shift: "D2", acuity: 3 },
  { date: "8/17/2019 01:30:44", shift: "A3", acuity: 6 },
  { date: "8/17/2019 01:32:06", shift: "B3", acuity: 3 },
  { date: "8/17/2019 02:27:51", shift: "A3", acuity: 3 },
  { date: "8/17/2019 02:50:16", shift: "B3", acuity: 3 },
  { date: "8/17/2019 03:08:55", shift: "A3", acuity: 3 },
  { date: "8/17/2019 03:45:53", shift: "B3", acuity: 3 },
  { date: "8/17/2019 03:59:06", shift: "A3", acuity: 3 },
  { date: "8/17/2019 04:13:47", shift: "B3", acuity: 3 },
  { date: "8/17/2019 05:07:15", shift: "A1", acuity: 6 },
  { date: "8/17/2019 05:27:06", shift: "B3", acuity: 4 },
  { date: "8/17/2019 05:37:56", shift: "A1", acuity: 4 },
  { date: "8/17/2019 05:47:35", shift: "A1", acuity: 6 },
  { date: "8/17/2019 06:03:44", shift: "B1", acuity: 4 },
  { date: "8/17/2019 06:04:15", shift: "A1", acuity: 3 },
  { date: "8/17/2019 06:29:21", shift: "B1", acuity: 4 },
  { date: "8/17/2019 06:29:51", shift: "A1", acuity: 6 },
  { date: "8/17/2019 06:30:09", shift: "B1", acuity: 3 },
  { date: "8/17/2019 06:39:17", shift: "B1", acuity: 3 },
  { date: "8/17/2019 06:59:10", shift: "A1", acuity: 3 },
  { date: "8/17/2019 07:14:53", shift: "B1", acuity: 3 },
  { date: "8/17/2019 07:41:23", shift: "A1", acuity: 4 },
  { date: "8/17/2019 07:41:44", shift: "B1", acuity: 3 },
  { date: "8/17/2019 08:00:29", shift: "A1", acuity: 3 },
  { date: "8/17/2019 08:19:14", shift: "B1", acuity: 2 },
  { date: "8/17/2019 08:55:52", shift: "C1", acuity: 4 },
  { date: "8/17/2019 08:56:05", shift: "A1", acuity: 4 },
  { date: "8/17/2019 09:20:40", shift: "C1", acuity: 3 },
  { date: "8/17/2019 09:20:56", shift: "C1", acuity: 3 },
  { date: "8/17/2019 09:30:21", shift: "B1", acuity: 3 },
  { date: "8/17/2019 09:41:28", shift: "A1", acuity: 4 },
  { date: "8/17/2019 09:59:00", shift: "C1", acuity: 3 },
  { date: "8/17/2019 10:05:27", shift: "D1", acuity: 3 },
  { date: "8/17/2019 10:05:42", shift: "B1", acuity: 3 },
  { date: "8/17/2019 10:14:15", shift: "A1", acuity: 3 },
  { date: "8/17/2019 10:27:31", shift: "C1", acuity: 3 },
  { date: "8/17/2019 11:03:26", shift: "D1", acuity: 3 },
  { date: "8/17/2019 11:03:48", shift: "D1", acuity: 3 },
  { date: "8/17/2019 11:06:17", shift: "B1", acuity: 3 },
  { date: "8/17/2019 11:07:44", shift: "A1", acuity: 3 },
  { date: "8/17/2019 11:38:25", shift: "C1", acuity: 3 },
  { date: "8/17/2019 11:46:25", shift: "D1", acuity: 3 },
  { date: "8/17/2019 11:54:48", shift: "B1", acuity: 2 },
  { date: "8/17/2019 12:06:02", shift: "A1", acuity: 3 },
  { date: "8/17/2019 12:25:53", shift: "C1", acuity: 6 },
  { date: "8/17/2019 12:29:09", shift: "D1", acuity: 6 },
  { date: "8/17/2019 12:31:34", shift: "B1", acuity: 6 },
  { date: "8/17/2019 13:00:44", shift: "A2", acuity: 3 },
  { date: "8/17/2019 13:02:45", shift: "C1", acuity: 3 },
  { date: "8/17/2019 13:25:06", shift: "D1", acuity: 6 },
  { date: "8/17/2019 13:29:19", shift: "B1", acuity: 6 },
  { date: "8/17/2019 13:35:14", shift: "A2", acuity: 3 },
  { date: "8/17/2019 13:41:07", shift: "A2", acuity: 6 },
  { date: "8/17/2019 13:49:33", shift: "C1", acuity: 6 },
  { date: "8/17/2019 14:01:06", shift: "B2", acuity: 6 },
  { date: "8/17/2019 14:16:47", shift: "D1", acuity: 3 },
  { date: "8/17/2019 14:20:53", shift: "A2", acuity: 6 },
  { date: "8/17/2019 14:25:08", shift: "C1", acuity: 3 },
  { date: "8/17/2019 14:25:35", shift: "B2", acuity: 3 },
  { date: "8/17/2019 14:30:45", shift: "B2", acuity: 3 },
  { date: "8/17/2019 14:57:20", shift: "B2", acuity: 6 },
  { date: "8/17/2019 15:24:06", shift: "D1", acuity: 3 },
  { date: "8/17/2019 15:30:23", shift: "A2", acuity: 3 },
  { date: "8/17/2019 15:31:01", shift: "C1", acuity: 6 },
  { date: "8/17/2019 15:40:44", shift: "B2", acuity: 3 },
  { date: "8/17/2019 15:43:59", shift: "D1", acuity: 3 },
  { date: "8/17/2019 15:45:43", shift: "A2", acuity: 3 },
  { date: "8/17/2019 16:54:43", shift: "C2", acuity: 3 },
  { date: "8/17/2019 16:55:02", shift: "B2", acuity: 6 },
  { date: "8/17/2019 17:00:14", shift: "C2", acuity: 3 },
  { date: "8/17/2019 17:12:13", shift: "C2", acuity: 3 },
  { date: "8/17/2019 17:17:18", shift: "D1", acuity: 3 },
  { date: "8/17/2019 17:20:48", shift: "A2", acuity: 3 },
  { date: "8/17/2019 17:35:12", shift: "B2", acuity: 6 },
  { date: "8/17/2019 17:40:45", shift: "C2", acuity: 2 },
  { date: "8/17/2019 17:57:07", shift: "D1", acuity: 3 },
  { date: "8/17/2019 18:04:54", shift: "D2", acuity: 6 },
  { date: "8/17/2019 18:11:38", shift: "A2", acuity: 6 },
  { date: "8/17/2019 18:23:28", shift: "B2", acuity: 6 },
  { date: "8/17/2019 18:43:16", shift: "D2", acuity: 6 },
  { date: "8/17/2019 18:45:27", shift: "D2", acuity: 3 },
  { date: "8/17/2019 18:45:51", shift: "C2", acuity: 6 },
  { date: "8/17/2019 18:55:08", shift: "A2", acuity: 4 },
  { date: "8/17/2019 19:24:00", shift: "B2", acuity: 3 },
  { date: "8/17/2019 19:31:25", shift: "D2", acuity: 3 },
  { date: "8/17/2019 19:44:46", shift: "C2", acuity: 6 },
  { date: "8/17/2019 19:57:26", shift: "A2", acuity: 6 },
  { date: "8/17/2019 20:01:19", shift: "B2", acuity: 3 },
  { date: "8/17/2019 20:02:22", shift: "D2", acuity: 4 },
  { date: "8/17/2019 20:02:41", shift: "C2", acuity: 3 },
  { date: "8/17/2019 20:08:18", shift: "A2", acuity: 3 },
  { date: "8/17/2019 20:14:30", shift: "B2", acuity: 3 },
  { date: "8/17/2019 20:25:37", shift: "D2", acuity: 3 },
  { date: "8/17/2019 20:51:41", shift: "C2", acuity: 3 },
  { date: "8/17/2019 20:53:02", shift: "A2", acuity: 6 },
  { date: "8/17/2019 20:54:31", shift: "B2", acuity: 4 },
  { date: "8/17/2019 21:04:27", shift: "A3", acuity: 3 },
  { date: "8/17/2019 21:08:29", shift: "D2", acuity: 4 },
  { date: "8/17/2019 21:11:36", shift: "C2", acuity: 3 },
  { date: "8/17/2019 21:15:20", shift: "B2", acuity: 4 },
  { date: "8/17/2019 21:23:50", shift: "A3", acuity: 3 },
  { date: "8/17/2019 21:29:09", shift: "D2", acuity: 4 },
  { date: "8/17/2019 21:38:06", shift: "A3", acuity: 3 },
  { date: "8/17/2019 21:38:30", shift: "A3", acuity: 3 },
  { date: "8/17/2019 22:02:37", shift: "B3", acuity: 3 },
  { date: "8/17/2019 22:06:16", shift: "C2", acuity: 3 },
  { date: "8/17/2019 22:17:52", shift: "D2", acuity: 3 },
  { date: "8/17/2019 22:18:51", shift: "A3", acuity: 3 },
  { date: "8/17/2019 22:30:00", shift: "B3", acuity: 4 },
  { date: "8/17/2019 22:34:23", shift: "B3", acuity: 4 },
  { date: "8/17/2019 22:35:00", shift: "C2", acuity: 4 },
  { date: "8/17/2019 22:48:36", shift: "D2", acuity: 3 },
  { date: "8/17/2019 22:48:43", shift: "A3", acuity: 6 },
  { date: "8/17/2019 22:53:41", shift: "B3", acuity: 7 },
  { date: "8/17/2019 23:07:05", shift: "C2", acuity: 4 },
  { date: "8/17/2019 23:07:32", shift: "D2", acuity: 4 },
  { date: "8/17/2019 23:21:25", shift: "A3", acuity: 3 },
  { date: "8/17/2019 23:38:57", shift: "B3", acuity: 4 },
  { date: "8/17/2019 23:40:46", shift: "C2", acuity: 4 },
  { date: "8/18/2019 00:07:10", shift: "D2", acuity: 5 },
  { date: "8/18/2019 00:07:31", shift: "A3", acuity: 3 },
  { date: "8/18/2019 00:07:47", shift: "B3", acuity: 3 },
  { date: "8/18/2019 00:10:07", shift: "D2", acuity: 3 },
  { date: "8/18/2019 00:20:37", shift: "A3", acuity: 4 },
  { date: "8/18/2019 00:20:57", shift: "B3", acuity: 4 },
  { date: "8/18/2019 00:38:22", shift: "D2", acuity: 4 },
  { date: "8/18/2019 00:50:07", shift: "A3", acuity: 4 },
  { date: "8/18/2019 01:04:59", shift: "B3", acuity: 5 },
  { date: "8/18/2019 01:12:43", shift: "D2", acuity: 4 },
  { date: "8/18/2019 01:46:03", shift: "A3", acuity: 3 },
  { date: "8/18/2019 02:13:01", shift: "B3", acuity: 4 },
  { date: "8/18/2019 02:36:53", shift: "A3", acuity: 6 },
  { date: "8/18/2019 03:32:10", shift: "B3", acuity: 3 },
  { date: "8/18/2019 03:44:30", shift: "A3", acuity: 6 },
  { date: "8/18/2019 03:51:39", shift: "B3", acuity: 4 },
  { date: "8/18/2019 04:01:25", shift: "A3", acuity: 3 },
  { date: "8/18/2019 05:03:59", shift: "A1", acuity: 6 },
  { date: "8/18/2019 07:15:03", shift: "B1", acuity: 3 },
  { date: "8/18/2019 07:22:25", shift: "B1", acuity: 4 },
  { date: "8/18/2019 07:25:31", shift: "B1", acuity: 4 },
  { date: "8/18/2019 07:32:36", shift: "A1", acuity: 3 },
  { date: "8/18/2019 07:48:45", shift: "B1", acuity: 3 },
  { date: "8/18/2019 07:52:31", shift: "A1", acuity: 6 },
  { date: "8/18/2019 07:52:51", shift: "B1", acuity: 4 },
  { date: "8/18/2019 07:53:06", shift: "A1", acuity: 3 },
  { date: "8/18/2019 08:17:15", shift: "B1", acuity: 6 },
  { date: "8/18/2019 08:29:03", shift: "A1", acuity: 2 },
  { date: "8/18/2019 08:38:45", shift: "C1", acuity: 3 },
  { date: "8/18/2019 08:44:50", shift: "B1", acuity: 4 },
  { date: "8/18/2019 08:50:23", shift: "A1", acuity: 3 },
  { date: "8/18/2019 09:09:29", shift: "C1", acuity: 3 },
  { date: "8/18/2019 09:30:35", shift: "C1", acuity: 3 },
  { date: "8/18/2019 09:33:26", shift: "B1", acuity: 6 },
  { date: "8/18/2019 09:45:08", shift: "A1", acuity: 3 },
  { date: "8/18/2019 10:11:02", shift: "D1", acuity: 6 },
  { date: "8/18/2019 10:16:39", shift: "C1", acuity: 6 },
  { date: "8/18/2019 10:26:24", shift: "B1", acuity: 3 },
  { date: "8/18/2019 10:45:54", shift: "D1", acuity: 6 },
  { date: "8/18/2019 10:47:10", shift: "D1", acuity: 6 },
  { date: "8/18/2019 10:59:53", shift: "A1", acuity: 3 },
  { date: "8/18/2019 11:00:35", shift: "C1", acuity: 3 },
  { date: "8/18/2019 11:00:49", shift: "B1", acuity: 3 },
  { date: "8/18/2019 11:18:52", shift: "D1", acuity: 3 },
  { date: "8/18/2019 11:20:43", shift: "A1", acuity: 3 },
  { date: "8/18/2019 11:23:37", shift: "C1", acuity: 4 },
  { date: "8/18/2019 11:26:18", shift: "B1", acuity: 6 },
  { date: "8/18/2019 11:27:04", shift: "D1", acuity: 3 },
  { date: "8/18/2019 11:34:25", shift: "A1", acuity: 3 },
  { date: "8/18/2019 11:46:59", shift: "C1", acuity: 3 },
  { date: "8/18/2019 11:47:11", shift: "B1", acuity: 3 },
  { date: "8/18/2019 12:18:49", shift: "D1", acuity: 6 },
  { date: "8/18/2019 12:28:43", shift: "A1", acuity: 3 },
  { date: "8/18/2019 12:49:35", shift: "C1", acuity: 3 },
  { date: "8/18/2019 12:49:50", shift: "B1", acuity: 3 },
  { date: "8/18/2019 13:21:05", shift: "A2", acuity: 3 },
  { date: "8/18/2019 13:21:20", shift: "D1", acuity: 3 },
  { date: "8/18/2019 13:21:37", shift: "C1", acuity: 3 },
  { date: "8/18/2019 13:21:56", shift: "B1", acuity: 3 },
  { date: "8/18/2019 13:38:14", shift: "A2", acuity: 3 },
  { date: "8/18/2019 13:53:25", shift: "A2", acuity: 3 },
  { date: "8/18/2019 13:53:44", shift: "D1", acuity: 3 },
  { date: "8/18/2019 14:04:56", shift: "B2", acuity: 6 },
  { date: "8/18/2019 14:10:29", shift: "C1", acuity: 3 },
  { date: "8/18/2019 14:34:31", shift: "B2", acuity: 6 },
  { date: "8/18/2019 14:46:47", shift: "B2", acuity: 6 },
  { date: "8/18/2019 14:46:58", shift: "A2", acuity: 3 },
  { date: "8/18/2019 14:47:20", shift: "D1", acuity: 6 },
  { date: "8/18/2019 15:05:01", shift: "C1", acuity: 3 },
  { date: "8/18/2019 15:06:56", shift: "B2", acuity: 6 },
  { date: "8/18/2019 15:29:48", shift: "A2", acuity: 6 },
  { date: "8/18/2019 15:33:53", shift: "D1", acuity: 6 },
  { date: "8/18/2019 15:36:48", shift: "C1", acuity: 3 },
  { date: "8/18/2019 15:41:54", shift: "B2", acuity: 6 },
  { date: "8/18/2019 15:54:01", shift: "A2", acuity: 3 },
  { date: "8/18/2019 15:55:54", shift: "D1", acuity: 6 },
  { date: "8/18/2019 16:08:55", shift: "C1", acuity: 3 },
  { date: "8/18/2019 16:13:31", shift: "B2", acuity: 6 },
  { date: "8/18/2019 16:42:40", shift: "C2", acuity: 3 },
  { date: "8/18/2019 16:43:35", shift: "A2", acuity: 3 },
  { date: "8/18/2019 16:44:35", shift: "D1", acuity: 6 },
  { date: "8/18/2019 16:48:22", shift: "B2", acuity: 6 },
  { date: "8/18/2019 17:00:15", shift: "C2", acuity: 3 },
  { date: "8/18/2019 17:07:19", shift: "C2", acuity: 3 },
  { date: "8/18/2019 17:12:08", shift: "A2", acuity: 4 },
  { date: "8/18/2019 17:16:03", shift: "D1", acuity: 3 },
  { date: "8/18/2019 17:16:43", shift: "B2", acuity: 6 },
  { date: "8/18/2019 17:26:28", shift: "C2", acuity: 3 },
  { date: "8/18/2019 17:40:00", shift: "A2", acuity: 3 },
  { date: "8/18/2019 18:00:39", shift: "D2", acuity: 6 },
  { date: "8/18/2019 18:07:15", shift: "B2", acuity: 3 },
  { date: "8/18/2019 18:15:45", shift: "C2", acuity: 3 },
  { date: "8/18/2019 18:17:38", shift: "A2", acuity: 3 },
  { date: "8/18/2019 18:23:05", shift: "D2", acuity: 6 },
  { date: "8/18/2019 18:46:26", shift: "D2", acuity: 6 },
  { date: "8/18/2019 18:47:08", shift: "D2", acuity: 6 },
  { date: "8/18/2019 18:52:48", shift: "B2", acuity: 2 },
  { date: "8/18/2019 19:09:37", shift: "C2", acuity: 3 },
  { date: "8/18/2019 19:40:51", shift: "A2", acuity: 3 },
  { date: "8/18/2019 19:48:19", shift: "D2", acuity: 6 },
  { date: "8/18/2019 19:57:59", shift: "B2", acuity: 6 },
  { date: "8/18/2019 19:59:19", shift: "C2", acuity: 6 },
  { date: "8/18/2019 19:59:33", shift: "A2", acuity: 6 },
  { date: "8/18/2019 20:00:34", shift: "D2", acuity: 3 },
  { date: "8/18/2019 20:23:08", shift: "B2", acuity: 6 },
  { date: "8/18/2019 20:31:19", shift: "C2", acuity: 3 },
  { date: "8/18/2019 20:47:59", shift: "A2", acuity: 6 },
  { date: "8/18/2019 20:53:51", shift: "D2", acuity: 6 },
  { date: "8/18/2019 21:10:03", shift: "A3", acuity: 6 },
  { date: "8/18/2019 21:10:31", shift: "B2", acuity: 3 },
  { date: "8/18/2019 21:27:00", shift: "C2", acuity: 3 },
  { date: "8/18/2019 21:39:18", shift: "A3", acuity: 4 },
  { date: "8/18/2019 21:41:49", shift: "A3", acuity: 3 },
  { date: "8/18/2019 21:49:05", shift: "D2", acuity: 6 },
  { date: "8/18/2019 22:03:05", shift: "B3", acuity: 6 },
  { date: "8/18/2019 22:06:41", shift: "C2", acuity: 3 },
  { date: "8/18/2019 22:07:35", shift: "A3", acuity: 3 },
  { date: "8/18/2019 22:09:04", shift: "D2", acuity: 4 },
  { date: "8/18/2019 22:12:51", shift: "B3", acuity: 2 },
  { date: "8/18/2019 22:14:10", shift: "C2", acuity: 6 },
  { date: "8/18/2019 22:31:14", shift: "B3", acuity: 3 },
  { date: "8/18/2019 22:54:22", shift: "B3", acuity: 3 },
  { date: "8/18/2019 23:06:19", shift: "A3", acuity: 3 },
  { date: "8/18/2019 23:13:12", shift: "D2", acuity: 4 },
  { date: "8/18/2019 23:44:10", shift: "C2", acuity: 4 },
  { date: "8/18/2019 23:44:23", shift: "B3", acuity: 3 },
  { date: "8/18/2019 23:46:23", shift: "A3", acuity: 4 },
  { date: "8/19/2019 00:00:49", shift: "D2", acuity: 3 },
  { date: "8/19/2019 00:18:30", shift: "B3", acuity: 3 },
  { date: "8/19/2019 00:18:45", shift: "A3", acuity: 4 },
  { date: "8/19/2019 00:31:50", shift: "D2", acuity: 3 },
  { date: "8/19/2019 00:38:13", shift: "B3", acuity: 3 },
  { date: "8/19/2019 00:42:03", shift: "A3", acuity: 3 },
  { date: "8/19/2019 00:42:22", shift: "D2", acuity: 6 },
  { date: "8/19/2019 00:42:40", shift: "B3", acuity: 3 },
  { date: "8/19/2019 01:00:52", shift: "A3", acuity: 6 },
  { date: "8/19/2019 01:32:27", shift: "B3", acuity: 3 },
  { date: "8/19/2019 01:49:48", shift: "A3", acuity: 3 },
  { date: "8/19/2019 01:50:00", shift: "B3", acuity: 3 },
  { date: "8/19/2019 02:45:12", shift: "A3", acuity: 4 },
  { date: "8/19/2019 02:45:27", shift: "B3", acuity: 4 },
  { date: "8/19/2019 02:45:44", shift: "A3", acuity: 4 },
  { date: "8/19/2019 02:53:54", shift: "B3", acuity: 4 },
  { date: "8/19/2019 03:01:36", shift: "A3", acuity: 3 },
  { date: "8/19/2019 03:31:34", shift: "B3", acuity: 4 },
  { date: "8/19/2019 03:48:35", shift: "A3", acuity: 3 },
  { date: "8/19/2019 03:48:53", shift: "B3", acuity: 3 },
  { date: "8/19/2019 04:04:51", shift: "A3", acuity: 3 },
  { date: "8/19/2019 04:10:04", shift: "B3", acuity: 4 },
  { date: "8/19/2019 04:37:39", shift: "A3", acuity: 4 },
  { date: "8/19/2019 05:01:16", shift: "A1", acuity: 3 },
  { date: "8/19/2019 05:01:33", shift: "B3", acuity: 4 },
  { date: "8/19/2019 05:06:27", shift: "A1", acuity: 3 },
  { date: "8/19/2019 05:44:15", shift: "A1", acuity: 3 },
  { date: "8/19/2019 06:00:08", shift: "A1", acuity: 4 },
  { date: "8/19/2019 06:16:34", shift: "B1", acuity: 3 },
  { date: "8/19/2019 06:41:22", shift: "B1", acuity: 3 },
  { date: "8/19/2019 06:41:49", shift: "B1", acuity: 4 },
  { date: "8/19/2019 07:16:38", shift: "A1", acuity: 3 },
  { date: "8/19/2019 07:21:34", shift: "B1", acuity: 3 },
  { date: "8/19/2019 08:04:15", shift: "A1", acuity: 3 },
  { date: "8/19/2019 08:11:24", shift: "B1", acuity: 3 },
  { date: "8/19/2019 08:33:37", shift: "C1", acuity: 6 },
  { date: "8/19/2019 08:40:54", shift: "A1", acuity: 3 },
  { date: "8/19/2019 09:10:40", shift: "C1", acuity: 3 },
  { date: "8/19/2019 09:11:13", shift: "C1", acuity: 3 },
  { date: "8/19/2019 09:11:31", shift: "B1", acuity: 4 },
  { date: "8/19/2019 09:14:55", shift: "A1", acuity: 6 },
  { date: "8/19/2019 09:35:17", shift: "C1", acuity: 3 },
  { date: "8/19/2019 09:54:57", shift: "B1", acuity: 3 },
  { date: "8/19/2019 09:55:22", shift: "A1", acuity: 3 },
  { date: "8/19/2019 10:10:20", shift: "D1", acuity: 4 },
  { date: "8/19/2019 10:12:48", shift: "C1", acuity: 3 },
  { date: "8/19/2019 10:30:56", shift: "D1", acuity: 6 },
  { date: "8/19/2019 10:37:57", shift: "D1", acuity: 6 },
  { date: "8/19/2019 10:38:12", shift: "B1", acuity: 6 },
  { date: "8/19/2019 10:45:20", shift: "A1", acuity: 6 },
  { date: "8/19/2019 11:01:00", shift: "C1", acuity: 3 },
  { date: "8/19/2019 11:16:22", shift: "D1", acuity: 6 },
  { date: "8/19/2019 11:16:36", shift: "B1", acuity: 6 },
  { date: "8/19/2019 11:16:46", shift: "A1", acuity: 6 },
  { date: "8/19/2019 11:39:58", shift: "C1", acuity: 6 },
  { date: "8/19/2019 11:45:57", shift: "D1", acuity: 6 },
  { date: "8/19/2019 12:03:48", shift: "B1", acuity: 6 },
  { date: "8/19/2019 12:10:20", shift: "A1", acuity: 3 },
  { date: "8/19/2019 12:11:38", shift: "C1", acuity: 3 },
  { date: "8/19/2019 12:13:33", shift: "D1", acuity: 6 },
  { date: "8/19/2019 12:31:04", shift: "B1", acuity: 6 },
  { date: "8/19/2019 12:33:36", shift: "A1", acuity: 6 },
  { date: "8/19/2019 12:36:25", shift: "C1", acuity: 6 },
  { date: "8/19/2019 13:02:20", shift: "A2", acuity: 6 },
  { date: "8/19/2019 13:04:25", shift: "D1", acuity: 4 },
  { date: "8/19/2019 13:21:47", shift: "B1", acuity: 6 },
  { date: "8/19/2019 13:24:02", shift: "C1", acuity: 3 },
  { date: "8/19/2019 13:35:01", shift: "A2", acuity: 3 },
  { date: "8/19/2019 14:00:46", shift: "A2", acuity: 3 },
  { date: "8/19/2019 14:35:01", shift: "B2", acuity: 3 },
  { date: "8/19/2019 14:35:30", shift: "B2", acuity: 3 },
  { date: "8/19/2019 14:35:52", shift: "B2", acuity: 3 },
  { date: "8/19/2019 15:18:12", shift: "D1", acuity: 6 },
  { date: "8/19/2019 15:27:23", shift: "C1", acuity: 3 },
  { date: "8/19/2019 15:27:38", shift: "A2", acuity: 6 },
  { date: "8/19/2019 15:45:09", shift: "B2", acuity: 3 },
  { date: "8/19/2019 15:53:57", shift: "D1", acuity: 6 },
  { date: "8/19/2019 16:00:18", shift: "C1", acuity: 3 },
  { date: "8/19/2019 16:00:26", shift: "A2", acuity: 6 },
  { date: "8/19/2019 16:23:20", shift: "B2", acuity: 6 },
  { date: "8/19/2019 16:23:51", shift: "D1", acuity: 3 },
  { date: "8/19/2019 16:30:17", shift: "C2", acuity: 3 },
  { date: "8/19/2019 16:30:29", shift: "A2", acuity: 3 },
  { date: "8/19/2019 16:30:49", shift: "B2", acuity: 6 },
  { date: "8/19/2019 16:38:20", shift: "D1", acuity: 6 },
  { date: "8/19/2019 17:00:11", shift: "C2", acuity: 3 },
  { date: "8/19/2019 17:00:22", shift: "C2", acuity: 3 },
  { date: "8/19/2019 17:15:08", shift: "A2", acuity: 4 },
  { date: "8/19/2019 17:18:29", shift: "B2", acuity: 6 },
  { date: "8/19/2019 17:18:36", shift: "D1", acuity: 3 },
  { date: "8/19/2019 17:39:22", shift: "C2", acuity: 3 },
  { date: "8/19/2019 17:53:01", shift: "A2", acuity: 6 },
  { date: "8/19/2019 17:53:08", shift: "B2", acuity: 6 },
  { date: "8/19/2019 18:00:31", shift: "D2", acuity: 6 },
  { date: "8/19/2019 18:03:01", shift: "C2", acuity: 6 },
  { date: "8/19/2019 18:07:51", shift: "A2", acuity: 6 },
  { date: "8/19/2019 18:19:30", shift: "B2", acuity: 3 },
  { date: "8/19/2019 18:34:30", shift: "D2", acuity: 6 },
  { date: "8/19/2019 18:34:38", shift: "D2", acuity: 6 },
  { date: "8/19/2019 18:36:12", shift: "C2", acuity: 6 },
  { date: "8/19/2019 18:57:40", shift: "A2", acuity: 6 },
  { date: "8/19/2019 19:07:31", shift: "B2", acuity: 3 },
  { date: "8/19/2019 19:07:44", shift: "D2", acuity: 4 },
  { date: "8/19/2019 20:03:34", shift: "C2", acuity: 3 },
  { date: "8/19/2019 20:04:39", shift: "A2", acuity: 6 },
  { date: "8/19/2019 20:20:06", shift: "B2", acuity: 3 },
  { date: "8/19/2019 20:24:34", shift: "D2", acuity: 3 },
  { date: "8/19/2019 20:29:40", shift: "C2", acuity: 6 },
  { date: "8/19/2019 20:38:47", shift: "A2", acuity: 6 },
  { date: "8/19/2019 20:47:04", shift: "B2", acuity: 3 },
  { date: "8/19/2019 20:48:54", shift: "D2", acuity: 3 },
  { date: "8/19/2019 20:52:53", shift: "C2", acuity: 4 },
  { date: "8/19/2019 21:00:16", shift: "A3", acuity: 6 },
  { date: "8/19/2019 21:00:35", shift: "B2", acuity: 6 },
  { date: "8/19/2019 21:02:29", shift: "D2", acuity: 3 },
  { date: "8/19/2019 21:03:41", shift: "C2", acuity: 3 },
  { date: "8/19/2019 21:28:04", shift: "A3", acuity: 6 },
  { date: "8/19/2019 21:28:40", shift: "B2", acuity: 6 },
  { date: "8/19/2019 21:30:34", shift: "A3", acuity: 3 },
  { date: "8/19/2019 21:44:21", shift: "A3", acuity: 3 },
  { date: "8/19/2019 21:54:42", shift: "D2", acuity: 6 },
  { date: "8/19/2019 22:01:14", shift: "B3", acuity: 3 },
  { date: "8/19/2019 22:05:31", shift: "C2", acuity: 3 },
  { date: "8/19/2019 22:22:41", shift: "A3", acuity: 4 },
  { date: "8/19/2019 22:50:59", shift: "B3", acuity: 4 },
  { date: "8/19/2019 23:14:05", shift: "B3", acuity: 6 },
  { date: "8/19/2019 23:14:20", shift: "D2", acuity: 4 },
  { date: "8/19/2019 23:24:15", shift: "C2", acuity: 6 },
  { date: "8/19/2019 23:49:34", shift: "A3", acuity: 4 },
  { date: "8/19/2019 23:54:18", shift: "B3", acuity: 3 },
  { date: "8/20/2019 00:00:25", shift: "D2", acuity: 3 },
  { date: "8/20/2019 00:07:04", shift: "A3", acuity: 6 },
  { date: "8/20/2019 00:11:34", shift: "B3", acuity: 6 },
  { date: "8/20/2019 00:23:51", shift: "D2", acuity: 4 },
  { date: "8/20/2019 00:41:54", shift: "A3", acuity: 3 },
  { date: "8/20/2019 00:50:56", shift: "B3", acuity: 3 },
  { date: "8/20/2019 01:02:11", shift: "D2", acuity: 4 },
  { date: "8/20/2019 01:02:32", shift: "A3", acuity: 6 },
  { date: "8/20/2019 01:03:23", shift: "B3", acuity: 4 },
  { date: "8/20/2019 01:03:53", shift: "D2", acuity: 3 },
  { date: "8/20/2019 01:10:59", shift: "A3", acuity: 4 },
  { date: "8/20/2019 01:11:23", shift: "B3", acuity: 3 },
  { date: "8/20/2019 01:21:46", shift: "A3", acuity: 3 },
  { date: "8/20/2019 01:37:55", shift: "B3", acuity: 3 },
  { date: "8/20/2019 01:39:21", shift: "A3", acuity: 4 },
  { date: "8/20/2019 01:44:50", shift: "B3", acuity: 3 },
  { date: "8/20/2019 02:03:36", shift: "A3", acuity: 6 },
  { date: "8/20/2019 02:28:42", shift: "B3", acuity: 3 },
  { date: "8/20/2019 02:41:21", shift: "A3", acuity: 4 },
  { date: "8/20/2019 02:54:23", shift: "B3", acuity: 4 },
  { date: "8/20/2019 03:10:28", shift: "A3", acuity: 3 },
  { date: "8/20/2019 03:24:56", shift: "B3", acuity: 4 },
  { date: "8/20/2019 03:34:12", shift: "A3", acuity: 3 },
  { date: "8/20/2019 04:02:20", shift: "B3", acuity: 6 },
  { date: "8/20/2019 04:22:03", shift: "A3", acuity: 3 },
  { date: "8/20/2019 05:01:02", shift: "A1", acuity: 3 },
  { date: "8/20/2019 05:01:54", shift: "B3", acuity: 3 },
  { date: "8/20/2019 05:33:45", shift: "A1", acuity: 3 },
  { date: "8/20/2019 06:06:04", shift: "A1", acuity: 6 },
  { date: "8/20/2019 06:32:38", shift: "B1", acuity: 3 },
  { date: "8/20/2019 06:44:52", shift: "B1", acuity: 4 },
  { date: "8/20/2019 06:59:58", shift: "B1", acuity: 3 },
  { date: "8/20/2019 08:25:52", shift: "A1", acuity: 3 },
  { date: "8/20/2019 08:33:01", shift: "C1", acuity: 6 },
  { date: "8/20/2019 08:34:58", shift: "B1", acuity: 6 },
  { date: "8/20/2019 08:58:50", shift: "A1", acuity: 3 },
  { date: "8/20/2019 08:59:07", shift: "C1", acuity: 3 },
  { date: "8/20/2019 09:29:29", shift: "C1", acuity: 6 },
  { date: "8/20/2019 09:29:40", shift: "C1", acuity: 3 },
  { date: "8/20/2019 09:30:10", shift: "B1", acuity: 4 },
  { date: "8/20/2019 09:43:51", shift: "A1", acuity: 4 },
  { date: "8/20/2019 09:52:26", shift: "C1", acuity: 3 },
  { date: "8/20/2019 10:24:52", shift: "D1", acuity: 6 },
  { date: "8/20/2019 10:25:06", shift: "B1", acuity: 3 },
  { date: "8/20/2019 10:30:30", shift: "D1", acuity: 6 },
  { date: "8/20/2019 10:35:39", shift: "D1", acuity: 3 },
  { date: "8/20/2019 10:42:20", shift: "A1", acuity: 3 },
  { date: "8/20/2019 10:49:48", shift: "C1", acuity: 2 },
  { date: "8/20/2019 11:05:10", shift: "B1", acuity: 7 },
  { date: "8/20/2019 11:11:17", shift: "D1", acuity: 4 },
  { date: "8/20/2019 11:11:41", shift: "A1", acuity: 6 },
  { date: "8/20/2019 11:12:00", shift: "C1", acuity: 3 },
  { date: "8/20/2019 11:12:24", shift: "B1", acuity: 7 },
  { date: "8/20/2019 11:31:11", shift: "D1", acuity: 3 },
  { date: "8/20/2019 11:31:41", shift: "A1", acuity: 6 },
  { date: "8/20/2019 11:34:14", shift: "C1", acuity: 3 },
  { date: "8/20/2019 11:44:41", shift: "B1", acuity: 7 },
  { date: "8/20/2019 11:45:01", shift: "D1", acuity: 6 },
  { date: "8/20/2019 12:05:00", shift: "A1", acuity: 2 },
  { date: "8/20/2019 12:46:16", shift: "C1", acuity: 6 },
  { date: "8/20/2019 13:17:58", shift: "A2", acuity: 6 },
  { date: "8/20/2019 13:38:11", shift: "A2", acuity: 3 },
  { date: "8/20/2019 13:40:28", shift: "A2", acuity: 3 },
  { date: "8/20/2019 13:40:43", shift: "B1", acuity: 6 },
  { date: "8/20/2019 13:56:36", shift: "D1", acuity: 6 },
  { date: "8/20/2019 13:56:50", shift: "C1", acuity: 6 },
  { date: "8/20/2019 13:57:05", shift: "A2", acuity: 6 },
  { date: "8/20/2019 14:04:32", shift: "B2", acuity: 3 },
  { date: "8/20/2019 14:20:52", shift: "D1", acuity: 6 },
  { date: "8/20/2019 14:39:35", shift: "B2", acuity: 3 },
  { date: "8/20/2019 14:39:49", shift: "B2", acuity: 3 },
  { date: "8/20/2019 14:52:43", shift: "C1", acuity: 3 },
  { date: "8/20/2019 14:58:38", shift: "A2", acuity: 7 },
  { date: "8/20/2019 15:07:54", shift: "D1", acuity: 3 },
  { date: "8/20/2019 15:12:36", shift: "B2", acuity: 6 },
  { date: "8/20/2019 15:20:29", shift: "C1", acuity: 3 },
  { date: "8/20/2019 15:20:50", shift: "A2", acuity: 3 },
  { date: "8/20/2019 15:26:00", shift: "D1", acuity: 3 },
  { date: "8/20/2019 15:34:21", shift: "B2", acuity: 3 },
  { date: "8/20/2019 15:42:29", shift: "C1", acuity: 6 },
  { date: "8/20/2019 15:54:29", shift: "A2", acuity: 7 },
  { date: "8/20/2019 15:54:50", shift: "D1", acuity: 6 },
  { date: "8/20/2019 15:59:57", shift: "B2", acuity: 3 },
  { date: "8/20/2019 16:00:18", shift: "C1", acuity: 3 },
  { date: "8/20/2019 16:06:36", shift: "A2", acuity: 7 },
  { date: "8/20/2019 16:20:35", shift: "D1", acuity: 2 },
  { date: "8/20/2019 16:25:14", shift: "B2", acuity: 6 },
  { date: "8/20/2019 16:33:47", shift: "C2", acuity: 6 },
  { date: "8/20/2019 16:50:58", shift: "A2", acuity: 3 },
  { date: "8/20/2019 16:53:48", shift: "D1", acuity: 3 },
  { date: "8/20/2019 17:00:38", shift: "C2", acuity: 6 },
  { date: "8/20/2019 17:10:23", shift: "C2", acuity: 6 },
  { date: "8/20/2019 17:19:33", shift: "B2", acuity: 6 },
  { date: "8/20/2019 17:21:34", shift: "A2", acuity: 6 },
  { date: "8/20/2019 17:25:05", shift: "D1", acuity: 3 },
  { date: "8/20/2019 17:25:25", shift: "C2", acuity: 3 },
  { date: "8/20/2019 17:25:52", shift: "B2", acuity: 3 },
  { date: "8/20/2019 17:39:56", shift: "A2", acuity: 6 },
  { date: "8/20/2019 18:00:21", shift: "D2", acuity: 3 },
  { date: "8/20/2019 18:07:37", shift: "C2", acuity: 3 },
  { date: "8/20/2019 18:16:45", shift: "B2", acuity: 3 },
  { date: "8/20/2019 18:45:56", shift: "D2", acuity: 6 },
  { date: "8/20/2019 18:54:57", shift: "D2", acuity: 6 },
  { date: "8/20/2019 19:01:13", shift: "A2", acuity: 3 },
  { date: "8/20/2019 19:27:13", shift: "C2", acuity: 6 },
  { date: "8/20/2019 19:33:11", shift: "B2", acuity: 6 },
  { date: "8/20/2019 19:42:50", shift: "D2", acuity: 3 },
  { date: "8/20/2019 19:56:54", shift: "A2", acuity: 6 },
  { date: "8/20/2019 19:58:27", shift: "C2", acuity: 3 },
  { date: "8/20/2019 20:05:47", shift: "B2", acuity: 6 },
  { date: "8/20/2019 20:16:22", shift: "D2", acuity: 6 },
  { date: "8/20/2019 20:17:32", shift: "A2", acuity: 3 },
  { date: "8/20/2019 20:19:25", shift: "C2", acuity: 3 },
  { date: "8/20/2019 20:55:27", shift: "B2", acuity: 6 },
  { date: "8/20/2019 20:56:24", shift: "D2", acuity: 3 },
  { date: "8/20/2019 20:57:10", shift: "A2", acuity: 3 },
  { date: "8/20/2019 20:57:34", shift: "C2", acuity: 6 },
  { date: "8/20/2019 20:59:06", shift: "B2", acuity: 3 },
  { date: "8/20/2019 21:11:16", shift: "A3", acuity: 3 },
  { date: "8/20/2019 21:12:14", shift: "D2", acuity: 3 },
  { date: "8/20/2019 21:14:00", shift: "C2", acuity: 3 },
  { date: "8/20/2019 21:17:49", shift: "B2", acuity: 3 },
  { date: "8/20/2019 21:50:28", shift: "A3", acuity: 3 },
  { date: "8/20/2019 21:51:32", shift: "A3", acuity: 3 },
  { date: "8/20/2019 21:52:03", shift: "D2", acuity: 6 },
  { date: "8/20/2019 21:58:58", shift: "C2", acuity: 6 },
  { date: "8/20/2019 22:00:55", shift: "B3", acuity: 3 },
  { date: "8/20/2019 22:01:26", shift: "A3", acuity: 3 },
  { date: "8/20/2019 22:09:40", shift: "D2", acuity: 6 },
  { date: "8/20/2019 22:25:56", shift: "C2", acuity: 3 },
  { date: "8/20/2019 22:26:53", shift: "B3", acuity: 6 },
  { date: "8/20/2019 22:46:54", shift: "B3", acuity: 3 },
  { date: "8/20/2019 23:08:05", shift: "B3", acuity: 3 },
  { date: "8/20/2019 23:19:06", shift: "A3", acuity: 6 },
  { date: "8/20/2019 23:22:13", shift: "D2", acuity: 3 },
  { date: "8/20/2019 23:36:45", shift: "C2", acuity: 3 },
  { date: "8/20/2019 23:37:14", shift: "B3", acuity: 3 },
  { date: "8/20/2019 23:38:53", shift: "A3", acuity: 4 },
  { date: "8/20/2019 23:44:08", shift: "D2", acuity: 4 },
  { date: "8/20/2019 23:55:48", shift: "C2", acuity: 3 },
  { date: "8/21/2019 00:01:50", shift: "B3", acuity: 3 },
  { date: "8/21/2019 00:05:53", shift: "A3", acuity: 3 },
  { date: "8/21/2019 00:27:04", shift: "D2", acuity: 6 },
  { date: "8/21/2019 00:37:10", shift: "B3", acuity: 6 },
  { date: "8/21/2019 00:53:02", shift: "A3", acuity: 3 },
  { date: "8/21/2019 00:54:43", shift: "D2", acuity: 4 },
  { date: "8/21/2019 01:19:02", shift: "B3", acuity: 4 },
  { date: "8/21/2019 01:24:53", shift: "A3", acuity: 4 },
  { date: "8/21/2019 01:31:25", shift: "B3", acuity: 6 },
  { date: "8/21/2019 01:41:33", shift: "A3", acuity: 4 },
  { date: "8/21/2019 01:42:10", shift: "B3", acuity: 5 },
  { date: "8/21/2019 01:48:42", shift: "A3", acuity: 3 },
  { date: "8/21/2019 01:54:35", shift: "B3", acuity: 4 },
  { date: "8/21/2019 01:57:44", shift: "A3", acuity: 3 },
  { date: "8/21/2019 02:29:28", shift: "B3", acuity: 3 },
  { date: "8/21/2019 02:30:51", shift: "A3", acuity: 6 },
  { date: "8/21/2019 02:52:01", shift: "B3", acuity: 2 },
  { date: "8/21/2019 04:02:26", shift: "A3", acuity: 4 },
  { date: "8/21/2019 04:15:11", shift: "B3", acuity: 4 },
  { date: "8/21/2019 04:20:01", shift: "A3", acuity: 3 },
  { date: "8/21/2019 04:39:34", shift: "B3", acuity: 6 },
  { date: "8/21/2019 05:02:01", shift: "A1", acuity: 3 },
  { date: "8/21/2019 05:14:38", shift: "B3", acuity: 6 },
  { date: "8/21/2019 05:50:12", shift: "A1", acuity: 3 },
  { date: "8/21/2019 05:55:47", shift: "A1", acuity: 4 },
  { date: "8/21/2019 06:00:51", shift: "B1", acuity: 3 },
  { date: "8/21/2019 06:23:24", shift: "A1", acuity: 4 },
  { date: "8/21/2019 07:27:17", shift: "B1", acuity: 6 },
  { date: "8/21/2019 07:32:00", shift: "B1", acuity: 6 },
  { date: "8/21/2019 07:32:18", shift: "A1", acuity: 4 },
  { date: "8/21/2019 08:06:27", shift: "B1", acuity: 3 },
  { date: "8/21/2019 08:13:55", shift: "A1", acuity: 6 },
  { date: "8/21/2019 08:39:32", shift: "C1", acuity: 6 },
  { date: "8/21/2019 08:44:27", shift: "B1", acuity: 3 },
  { date: "8/21/2019 08:46:45", shift: "A1", acuity: 6 },
  { date: "8/21/2019 08:48:56", shift: "C1", acuity: 3 },
  { date: "8/21/2019 09:09:28", shift: "C1", acuity: 3 },
  { date: "8/21/2019 09:09:42", shift: "C1", acuity: 3 },
  { date: "8/21/2019 09:50:59", shift: "B1", acuity: 6 },
  { date: "8/21/2019 09:57:13", shift: "A1", acuity: 3 },
  { date: "8/21/2019 10:13:08", shift: "D1", acuity: 3 },
  { date: "8/21/2019 10:27:16", shift: "C1", acuity: 6 },
  { date: "8/21/2019 10:44:28", shift: "D1", acuity: 6 },
  { date: "8/21/2019 11:06:01", shift: "D1", acuity: 7 },
  { date: "8/21/2019 11:22:11", shift: "B1", acuity: 6 },
  { date: "8/21/2019 11:22:27", shift: "A1", acuity: 3 },
  { date: "8/21/2019 11:27:55", shift: "C1", acuity: 6 },
  { date: "8/21/2019 11:29:38", shift: "D1", acuity: 3 },
  { date: "8/21/2019 11:36:47", shift: "B1", acuity: 3 },
  { date: "8/21/2019 11:45:30", shift: "A1", acuity: 3 },
  { date: "8/21/2019 11:49:59", shift: "C1", acuity: 4 },
  { date: "8/21/2019 12:03:21", shift: "D1", acuity: 6 },
  { date: "8/21/2019 12:10:36", shift: "B1", acuity: 4 },
  { date: "8/21/2019 12:20:42", shift: "A1", acuity: 6 },
  { date: "8/21/2019 12:22:58", shift: "C1", acuity: 3 },
  { date: "8/21/2019 12:33:31", shift: "D1", acuity: 6 },
  { date: "8/21/2019 12:52:15", shift: "B1", acuity: 7 },
  { date: "8/21/2019 13:15:07", shift: "A2", acuity: 4 },
  { date: "8/21/2019 13:15:37", shift: "C1", acuity: 7 },
  { date: "8/21/2019 13:16:15", shift: "D1", acuity: 7 },
  { date: "8/21/2019 13:16:34", shift: "B1", acuity: 6 },
  { date: "8/21/2019 13:22:53", shift: "A2", acuity: 3 },
  { date: "8/21/2019 13:34:33", shift: "A2", acuity: 6 },
  { date: "8/21/2019 13:41:49", shift: "A2", acuity: 3 },
  { date: "8/21/2019 14:00:15", shift: "B2", acuity: 6 },
  { date: "8/21/2019 14:14:24", shift: "C1", acuity: 6 },
  { date: "8/21/2019 14:16:28", shift: "D1", acuity: 3 },
  { date: "8/21/2019 14:16:56", shift: "A2", acuity: 6 },
  { date: "8/21/2019 14:31:44", shift: "B2", acuity: 6 },
  { date: "8/21/2019 14:32:18", shift: "B2", acuity: 3 },
  { date: "8/21/2019 14:42:08", shift: "C1", acuity: 3 },
  { date: "8/21/2019 14:50:18", shift: "D1", acuity: 3 },
  { date: "8/21/2019 14:58:26", shift: "A2", acuity: 3 },
  { date: "8/21/2019 15:21:51", shift: "B2", acuity: 6 },
  { date: "8/21/2019 15:34:17", shift: "C1", acuity: 6 },
  { date: "8/21/2019 15:56:04", shift: "D1", acuity: 3 },
  { date: "8/21/2019 16:03:09", shift: "A2", acuity: 6 },
  { date: "8/21/2019 16:09:22", shift: "B2", acuity: 3 },
  { date: "8/21/2019 16:25:05", shift: "C1", acuity: 6 },
  { date: "8/21/2019 16:34:14", shift: "C2", acuity: 3 },
  { date: "8/21/2019 16:39:09", shift: "D1", acuity: 6 },
  { date: "8/21/2019 16:39:28", shift: "A2", acuity: 3 },
  { date: "8/21/2019 17:00:24", shift: "C2", acuity: 3 },
  { date: "8/21/2019 17:11:45", shift: "C2", acuity: 6 },
  { date: "8/21/2019 17:18:12", shift: "B2", acuity: 3 },
  { date: "8/21/2019 17:44:35", shift: "D1", acuity: 4 },
  { date: "8/21/2019 17:45:49", shift: "A2", acuity: 6 },
  { date: "8/21/2019 17:57:50", shift: "C2", acuity: 3 },
  { date: "8/21/2019 18:08:26", shift: "D2", acuity: 6 },
  { date: "8/21/2019 18:21:32", shift: "B2", acuity: 3 },
  { date: "8/21/2019 18:26:04", shift: "A2", acuity: 6 },
  { date: "8/21/2019 18:27:59", shift: "C2", acuity: 6 },
  { date: "8/21/2019 18:30:39", shift: "D2", acuity: 6 },
  { date: "8/21/2019 18:33:03", shift: "D2", acuity: 6 },
  { date: "8/21/2019 18:44:24", shift: "B2", acuity: 6 },
  { date: "8/21/2019 18:51:13", shift: "A2", acuity: 3 },
  { date: "8/21/2019 18:52:38", shift: "C2", acuity: 3 },
  { date: "8/21/2019 18:59:17", shift: "D2", acuity: 3 },
  { date: "8/21/2019 19:07:43", shift: "B2", acuity: 3 },
  { date: "8/21/2019 19:08:20", shift: "A2", acuity: 3 },
  { date: "8/21/2019 19:16:51", shift: "C2", acuity: 6 },
  { date: "8/21/2019 19:29:09", shift: "D2", acuity: 3 },
  { date: "8/21/2019 19:37:36", shift: "B2", acuity: 3 },
  { date: "8/21/2019 19:37:49", shift: "A2", acuity: 3 },
  { date: "8/21/2019 19:58:31", shift: "C2", acuity: 3 },
  { date: "8/21/2019 19:58:39", shift: "D2", acuity: 6 },
  { date: "8/21/2019 20:19:41", shift: "B2", acuity: 3 },
  { date: "8/21/2019 20:27:21", shift: "A2", acuity: 3 },
  { date: "8/21/2019 20:27:38", shift: "C2", acuity: 6 },
  { date: "8/21/2019 20:36:50", shift: "D2", acuity: 3 },
  { date: "8/21/2019 20:39:30", shift: "B2", acuity: 6 },
  { date: "8/21/2019 20:40:10", shift: "A2", acuity: 6 },
  { date: "8/21/2019 21:07:40", shift: "A3", acuity: 3 },
  { date: "8/21/2019 21:07:50", shift: "C2", acuity: 6 },
  { date: "8/21/2019 21:08:29", shift: "D2", acuity: 6 },
  { date: "8/21/2019 21:31:52", shift: "A3", acuity: 3 },
  { date: "8/21/2019 21:40:57", shift: "A3", acuity: 3 },
  { date: "8/21/2019 22:06:00", shift: "B3", acuity: 3 },
  { date: "8/21/2019 22:09:25", shift: "C2", acuity: 6 },
  { date: "8/21/2019 22:12:15", shift: "D2", acuity: 3 },
  { date: "8/21/2019 22:24:32", shift: "A3", acuity: 3 },
  { date: "8/21/2019 22:31:50", shift: "B3", acuity: 4 },
  { date: "8/21/2019 22:32:13", shift: "B3", acuity: 3 },
  { date: "8/21/2019 22:48:19", shift: "C2", acuity: 3 },
  { date: "8/21/2019 22:48:39", shift: "D2", acuity: 6 },
  { date: "8/21/2019 22:55:38", shift: "A3", acuity: 3 },
  { date: "8/21/2019 23:12:47", shift: "B3", acuity: 3 },
  { date: "8/21/2019 23:16:16", shift: "C2", acuity: 3 },
  { date: "8/21/2019 23:28:11", shift: "D2", acuity: 3 },
  { date: "8/21/2019 23:53:00", shift: "A3", acuity: 4 },
  { date: "8/21/2019 23:53:11", shift: "B3", acuity: 6 },
  { date: "8/22/2019 00:00:51", shift: "D2", acuity: 3 },
  { date: "8/22/2019 00:01:29", shift: "A3", acuity: 3 },
  { date: "8/22/2019 00:01:56", shift: "B3", acuity: 4 },
  { date: "8/22/2019 00:20:05", shift: "D2", acuity: 3 },
  { date: "8/22/2019 00:32:22", shift: "A3", acuity: 4 },
  { date: "8/22/2019 00:32:49", shift: "B3", acuity: 3 },
  { date: "8/22/2019 00:55:48", shift: "D2", acuity: 3 },
  { date: "8/22/2019 00:56:00", shift: "A3", acuity: 3 },
  { date: "8/22/2019 00:59:07", shift: "B3", acuity: 5 },
  { date: "8/22/2019 01:04:51", shift: "D2", acuity: 5 },
  { date: "8/22/2019 01:12:09", shift: "A3", acuity: 4 },
  { date: "8/22/2019 01:18:08", shift: "B3", acuity: 3 },
  { date: "8/22/2019 01:30:21", shift: "A3", acuity: 3 },
  { date: "8/22/2019 01:35:28", shift: "B3", acuity: 6 },
  { date: "8/22/2019 01:42:11", shift: "A3", acuity: 3 },
  { date: "8/22/2019 01:46:29", shift: "B3", acuity: 4 },
  { date: "8/22/2019 01:54:55", shift: "A3", acuity: 6 },
  { date: "8/22/2019 02:00:01", shift: "B3", acuity: 4 },
  { date: "8/22/2019 02:11:07", shift: "A3", acuity: 3 },
  { date: "8/22/2019 03:49:08", shift: "B3", acuity: 3 },
  { date: "8/22/2019 05:07:45", shift: "A1", acuity: 3 },
  { date: "8/22/2019 05:07:57", shift: "B3", acuity: 3 },
  { date: "8/22/2019 05:30:22", shift: "A1", acuity: 3 },
  { date: "8/22/2019 06:02:44", shift: "A1", acuity: 3 },
  { date: "8/22/2019 06:22:05", shift: "B1", acuity: 3 },
  { date: "8/22/2019 06:36:53", shift: "B1", acuity: 3 },
  { date: "8/22/2019 06:50:29", shift: "B1", acuity: 4 },
  { date: "8/22/2019 06:50:47", shift: "A1", acuity: 4 },
  { date: "8/22/2019 06:59:16", shift: "B1", acuity: 3 },
  { date: "8/22/2019 07:17:14", shift: "A1", acuity: 3 },
  { date: "8/22/2019 07:46:37", shift: "B1", acuity: 3 },
  { date: "8/22/2019 08:27:56", shift: "A1", acuity: 4 },
  { date: "8/22/2019 09:14:26", shift: "C1", acuity: 4 },
  { date: "8/22/2019 09:19:23", shift: "C1", acuity: 3 },
  { date: "8/22/2019 09:19:46", shift: "C1", acuity: 4 },
  { date: "8/22/2019 09:21:12", shift: "B1", acuity: 3 },
  { date: "8/22/2019 09:49:50", shift: "A1", acuity: 3 },
  { date: "8/22/2019 09:50:09", shift: "C1", acuity: 6 },
  { date: "8/22/2019 09:52:42", shift: "B1", acuity: 6 },
  { date: "8/22/2019 09:58:30", shift: "A1", acuity: 3 },
  { date: "8/22/2019 09:59:16", shift: "C1", acuity: 4 },
  { date: "8/22/2019 10:25:19", shift: "D1", acuity: 3 },
  { date: "8/22/2019 10:49:07", shift: "D1", acuity: 3 },
  { date: "8/22/2019 10:55:02", shift: "D1", acuity: 6 },
  { date: "8/22/2019 10:57:54", shift: "B1", acuity: 6 },
  { date: "8/22/2019 10:58:51", shift: "A1", acuity: 6 },
  { date: "8/22/2019 11:08:43", shift: "C1", acuity: 6 },
  { date: "8/22/2019 11:22:13", shift: "D1", acuity: 6 },
  { date: "8/22/2019 11:40:13", shift: "B1", acuity: 3 },
  { date: "8/22/2019 11:52:11", shift: "A1", acuity: 4 },
  { date: "8/22/2019 12:11:49", shift: "C1", acuity: 3 },
  { date: "8/22/2019 12:28:54", shift: "D1", acuity: 3 },
  { date: "8/22/2019 12:29:10", shift: "B1", acuity: 3 },
  { date: "8/22/2019 12:37:19", shift: "A1", acuity: 6 },
  { date: "8/22/2019 12:37:34", shift: "C1", acuity: 6 },
  { date: "8/22/2019 12:56:24", shift: "D1", acuity: 6 },
  { date: "8/22/2019 13:11:05", shift: "A2", acuity: 6 },
  { date: "8/22/2019 13:11:44", shift: "B1", acuity: 6 },
  { date: "8/22/2019 13:20:51", shift: "C1", acuity: 6 },
  { date: "8/22/2019 13:42:04", shift: "A2", acuity: 6 },
  { date: "8/22/2019 14:00:01", shift: "A2", acuity: 6 },
  { date: "8/22/2019 14:34:56", shift: "B2", acuity: 3 },
  { date: "8/22/2019 14:35:22", shift: "B2", acuity: 6 },
  { date: "8/22/2019 14:39:34", shift: "B2", acuity: 3 },
  { date: "8/22/2019 15:09:51", shift: "D1", acuity: 3 },
  { date: "8/22/2019 15:18:14", shift: "C1", acuity: 6 },
  { date: "8/22/2019 15:18:31", shift: "A2", acuity: 6 },
  { date: "8/22/2019 15:23:45", shift: "B2", acuity: 6 },
  { date: "8/22/2019 15:34:12", shift: "D1", acuity: 3 },
  { date: "8/22/2019 15:34:44", shift: "C1", acuity: 6 },
  { date: "8/22/2019 15:39:14", shift: "A2", acuity: 3 },
  { date: "8/22/2019 15:48:09", shift: "B2", acuity: 6 },
  { date: "8/22/2019 15:53:00", shift: "D1", acuity: 3 },
  { date: "8/22/2019 16:01:31", shift: "C1", acuity: 3 },
  { date: "8/22/2019 16:01:52", shift: "A2", acuity: 6 },
  { date: "8/22/2019 16:24:51", shift: "B2", acuity: 3 },
  { date: "8/22/2019 16:30:18", shift: "C2", acuity: 3 },
  { date: "8/22/2019 16:32:03", shift: "D1", acuity: 3 },
  { date: "8/22/2019 16:36:06", shift: "A2", acuity: 3 },
  { date: "8/22/2019 16:37:15", shift: "B2", acuity: 3 },
  { date: "8/22/2019 16:50:43", shift: "C2", acuity: 3 },
  { date: "8/22/2019 16:51:00", shift: "D1", acuity: 3 },
  { date: "8/22/2019 16:56:37", shift: "A2", acuity: 3 },
  { date: "8/22/2019 17:03:32", shift: "C2", acuity: 3 },
  { date: "8/22/2019 17:09:52", shift: "C2", acuity: 3 },
  { date: "8/22/2019 17:18:38", shift: "B2", acuity: 3 },
  { date: "8/22/2019 17:31:32", shift: "D1", acuity: 3 },
  { date: "8/22/2019 17:42:05", shift: "A2", acuity: 3 },
  { date: "8/22/2019 18:12:32", shift: "D2", acuity: 3 },
  { date: "8/22/2019 18:13:06", shift: "C2", acuity: 3 },
  { date: "8/22/2019 18:24:38", shift: "B2", acuity: 3 },
  { date: "8/22/2019 18:27:18", shift: "A2", acuity: 3 },
  { date: "8/22/2019 18:32:17", shift: "D2", acuity: 3 },
  { date: "8/22/2019 18:32:43", shift: "D2", acuity: 4 },
  { date: "8/22/2019 18:33:43", shift: "C2", acuity: 4 },
  { date: "8/22/2019 18:36:51", shift: "B2", acuity: 3 },
  { date: "8/22/2019 18:39:05", shift: "A2", acuity: 3 },
  { date: "8/22/2019 18:51:28", shift: "D2", acuity: 6 },
  { date: "8/22/2019 18:58:22", shift: "C2", acuity: 6 },
  { date: "8/22/2019 19:01:52", shift: "B2", acuity: 6 },
  { date: "8/22/2019 19:20:00", shift: "A2", acuity: 3 },
  { date: "8/22/2019 19:20:22", shift: "D2", acuity: 6 },
  { date: "8/22/2019 19:30:01", shift: "C2", acuity: 6 },
  { date: "8/22/2019 19:56:25", shift: "B2", acuity: 6 },
  { date: "8/22/2019 19:56:57", shift: "A2", acuity: 3 },
  { date: "8/22/2019 20:00:47", shift: "D2", acuity: 3 },
  { date: "8/22/2019 20:03:35", shift: "C2", acuity: 6 },
  { date: "8/22/2019 20:11:11", shift: "B2", acuity: 6 },
  { date: "8/22/2019 20:19:17", shift: "A2", acuity: 3 },
  { date: "8/22/2019 20:19:39", shift: "D2", acuity: 4 },
  { date: "8/22/2019 20:53:32", shift: "C2", acuity: 3 },
  { date: "8/22/2019 20:58:31", shift: "B2", acuity: 3 },
  { date: "8/22/2019 21:07:13", shift: "A3", acuity: 6 },
  { date: "8/22/2019 21:20:58", shift: "D2", acuity: 3 },
  { date: "8/22/2019 21:22:08", shift: "C2", acuity: 6 },
  { date: "8/22/2019 21:32:53", shift: "A3", acuity: 6 },
  { date: "8/22/2019 21:38:37", shift: "A3", acuity: 6 },
  { date: "8/22/2019 21:55:01", shift: "B2", acuity: 6 },
  { date: "8/22/2019 22:03:03", shift: "B3", acuity: 6 },
  { date: "8/22/2019 22:06:46", shift: "D2", acuity: 3 },
  { date: "8/22/2019 22:20:59", shift: "C2", acuity: 3 },
  { date: "8/22/2019 22:22:45", shift: "A3", acuity: 6 },
  { date: "8/22/2019 22:23:12", shift: "B3", acuity: 6 },
  { date: "8/22/2019 22:30:18", shift: "B3", acuity: 6 },
  { date: "8/22/2019 22:33:15", shift: "B3", acuity: 3 },
  { date: "8/22/2019 22:46:44", shift: "D2", acuity: 4 },
  { date: "8/22/2019 22:50:28", shift: "C2", acuity: 3 },
  { date: "8/22/2019 23:14:04", shift: "A3", acuity: 6 },
  { date: "8/22/2019 23:14:27", shift: "B3", acuity: 3 },
  { date: "8/22/2019 23:14:48", shift: "D2", acuity: 3 },
  { date: "8/22/2019 23:15:35", shift: "C2", acuity: 3 },
  { date: "8/22/2019 23:15:53", shift: "A3", acuity: 6 },
  { date: "8/22/2019 23:33:16", shift: "B3", acuity: 4 },
  { date: "8/22/2019 23:48:27", shift: "D2", acuity: 4 },
  { date: "8/22/2019 23:48:52", shift: "C2", acuity: 3 },
  { date: "8/22/2019 23:57:20", shift: "A3", acuity: 4 },
  { date: "8/23/2019 00:09:54", shift: "B3", acuity: 4 },
  { date: "8/23/2019 00:10:24", shift: "D2", acuity: 3 },
  { date: "8/23/2019 00:31:33", shift: "A3", acuity: 4 },
  { date: "8/23/2019 00:47:49", shift: "B3", acuity: 6 },
  { date: "8/23/2019 00:53:14", shift: "D2", acuity: 3 },
  { date: "8/23/2019 00:56:48", shift: "A3", acuity: 3 },
  { date: "8/23/2019 02:10:02", shift: "B3", acuity: 3 },
  { date: "8/23/2019 02:20:01", shift: "A3", acuity: 3 },
  { date: "8/23/2019 02:41:10", shift: "B3", acuity: 3 },
  { date: "8/23/2019 03:19:20", shift: "A3", acuity: 6 },
  { date: "8/23/2019 03:44:04", shift: "B3", acuity: 4 },
  { date: "8/23/2019 03:44:49", shift: "A3", acuity: 4 },
  { date: "8/23/2019 03:56:16", shift: "B3", acuity: 6 },
  { date: "8/23/2019 04:43:23", shift: "A3", acuity: 4 },
  { date: "8/23/2019 04:48:29", shift: "B3", acuity: 3 },
  { date: "8/23/2019 05:13:05", shift: "A1", acuity: 3 },
  { date: "8/23/2019 05:42:26", shift: "A1", acuity: 3 },
  { date: "8/23/2019 05:46:38", shift: "A1", acuity: 3 },
  { date: "8/23/2019 06:10:35", shift: "B1", acuity: 6 },
  { date: "8/23/2019 06:28:48", shift: "A1", acuity: 3 },
  { date: "8/23/2019 06:30:44", shift: "B1", acuity: 6 },
  { date: "8/23/2019 07:06:48", shift: "B1", acuity: 5 },
  { date: "8/23/2019 07:07:18", shift: "A1", acuity: 3 },
  { date: "8/23/2019 07:07:36", shift: "B1", acuity: 6 },
  { date: "8/23/2019 07:26:33", shift: "A1", acuity: 3 },
  { date: "8/23/2019 07:26:44", shift: "B1", acuity: 4 },
  { date: "8/23/2019 07:35:23", shift: "A1", acuity: 4 },
  { date: "8/23/2019 07:50:07", shift: "B1", acuity: 6 },
  { date: "8/23/2019 07:54:30", shift: "A1", acuity: 3 },
  { date: "8/23/2019 08:30:25", shift: "C1", acuity: 3 },
  { date: "8/23/2019 09:07:41", shift: "C1", acuity: 6 },
  { date: "8/23/2019 09:07:52", shift: "C1", acuity: 6 },
  { date: "8/23/2019 09:11:06", shift: "B1", acuity: 4 },
  { date: "8/23/2019 09:17:42", shift: "A1", acuity: 3 },
  { date: "8/23/2019 09:20:59", shift: "C1", acuity: 6 },
  { date: "8/23/2019 09:37:30", shift: "B1", acuity: 3 },
  { date: "8/23/2019 09:48:27", shift: "A1", acuity: 6 },
  { date: "8/23/2019 09:52:03", shift: "C1", acuity: 3 },
  { date: "8/23/2019 10:01:32", shift: "D1", acuity: 6 },
  { date: "8/23/2019 10:25:02", shift: "B1", acuity: 6 },
  { date: "8/23/2019 10:53:38", shift: "D1", acuity: 6 },
  { date: "8/23/2019 11:00:08", shift: "D1", acuity: 3 },
  { date: "8/23/2019 11:01:05", shift: "A1", acuity: 3 },
  { date: "8/23/2019 11:09:55", shift: "C1", acuity: 6 },
  { date: "8/23/2019 11:15:42", shift: "B1", acuity: 6 },
  { date: "8/23/2019 11:29:29", shift: "D1", acuity: 4 },
  { date: "8/23/2019 11:30:52", shift: "A1", acuity: 3 },
  { date: "8/23/2019 11:46:19", shift: "C1", acuity: 6 },
  { date: "8/23/2019 11:48:22", shift: "B1", acuity: 3 },
  { date: "8/23/2019 11:59:00", shift: "D1", acuity: 4 },
  { date: "8/23/2019 12:14:47", shift: "A1", acuity: 3 },
  { date: "8/23/2019 12:39:06", shift: "C1", acuity: 4 },
  { date: "8/23/2019 12:48:43", shift: "B1", acuity: 2 },
  { date: "8/23/2019 13:08:13", shift: "A2", acuity: 6 },
  { date: "8/23/2019 13:19:57", shift: "D1", acuity: 2 },
  { date: "8/23/2019 13:30:16", shift: "A2", acuity: 6 },
  { date: "8/23/2019 14:19:46", shift: "A2", acuity: 6 },
  { date: "8/23/2019 14:28:04", shift: "B2", acuity: 3 },
  { date: "8/23/2019 14:32:51", shift: "B2", acuity: 3 },
  { date: "8/23/2019 14:34:00", shift: "B2", acuity: 3 },
  { date: "8/23/2019 14:40:06", shift: "C1", acuity: 6 },
  { date: "8/23/2019 14:45:48", shift: "D1", acuity: 6 },
  { date: "8/23/2019 14:58:51", shift: "A2", acuity: 3 },
  { date: "8/23/2019 14:59:02", shift: "B2", acuity: 3 },
  { date: "8/23/2019 14:59:10", shift: "C1", acuity: 6 },
  { date: "8/23/2019 15:07:21", shift: "D1", acuity: 6 },
  { date: "8/23/2019 15:30:42", shift: "A2", acuity: 6 },
  { date: "8/23/2019 15:33:27", shift: "B2", acuity: 6 },
  { date: "8/23/2019 15:38:09", shift: "C1", acuity: 3 },
  { date: "8/23/2019 15:42:41", shift: "D1", acuity: 3 },
  { date: "8/23/2019 15:55:09", shift: "A2", acuity: 6 },
  { date: "8/23/2019 15:55:56", shift: "B2", acuity: 6 },
  { date: "8/23/2019 16:03:57", shift: "C1", acuity: 6 },
  { date: "8/23/2019 16:06:38", shift: "D1", acuity: 6 },
  { date: "8/23/2019 16:13:39", shift: "A2", acuity: 3 },
  { date: "8/23/2019 16:17:31", shift: "B2", acuity: 6 },
  { date: "8/23/2019 16:39:29", shift: "C2", acuity: 3 },
  { date: "8/23/2019 16:39:57", shift: "D1", acuity: 3 },
  { date: "8/23/2019 16:42:24", shift: "A2", acuity: 6 },
  { date: "8/23/2019 16:53:44", shift: "B2", acuity: 6 },
  { date: "8/23/2019 17:01:32", shift: "C2", acuity: 6 },
  { date: "8/23/2019 17:01:50", shift: "C2", acuity: 3 },
  { date: "8/23/2019 17:14:04", shift: "D1", acuity: 3 },
  { date: "8/23/2019 17:14:17", shift: "A2", acuity: 3 },
  { date: "8/23/2019 17:25:27", shift: "B2", acuity: 3 },
  { date: "8/23/2019 17:37:24", shift: "C2", acuity: 6 },
  { date: "8/23/2019 17:44:04", shift: "D1", acuity: 3 },
  { date: "8/23/2019 17:44:20", shift: "A2", acuity: 6 },
  { date: "8/23/2019 17:55:34", shift: "B2", acuity: 3 },
  { date: "8/23/2019 18:01:19", shift: "D2", acuity: 3 },
  { date: "8/23/2019 18:15:45", shift: "C2", acuity: 3 },
  { date: "8/23/2019 18:15:57", shift: "A2", acuity: 3 },
  { date: "8/23/2019 18:29:33", shift: "B2", acuity: 3 },
  { date: "8/23/2019 18:31:54", shift: "D2", acuity: 6 },
  { date: "8/23/2019 18:39:18", shift: "D2", acuity: 3 },
  { date: "8/23/2019 18:39:55", shift: "C2", acuity: 3 },
  { date: "8/23/2019 18:40:08", shift: "A2", acuity: 6 },
  { date: "8/23/2019 18:44:08", shift: "B2", acuity: 6 },
  { date: "8/23/2019 18:55:20", shift: "D2", acuity: 3 },
  { date: "8/23/2019 19:03:55", shift: "C2", acuity: 6 },
  { date: "8/23/2019 19:19:22", shift: "A2", acuity: 3 },
  { date: "8/23/2019 19:19:53", shift: "B2", acuity: 3 },
  { date: "8/23/2019 19:25:28", shift: "D2", acuity: 3 },
  { date: "8/23/2019 19:29:35", shift: "C2", acuity: 3 },
  { date: "8/23/2019 19:42:23", shift: "A2", acuity: 3 },
  { date: "8/23/2019 19:43:09", shift: "B2", acuity: 3 },
  { date: "8/23/2019 19:43:39", shift: "D2", acuity: 3 },
  { date: "8/23/2019 19:49:46", shift: "C2", acuity: 3 },
  { date: "8/23/2019 20:00:44", shift: "A2", acuity: 6 },
  { date: "8/23/2019 20:01:58", shift: "B2", acuity: 6 },
  { date: "8/23/2019 20:12:39", shift: "D2", acuity: 6 },
  { date: "8/23/2019 20:26:48", shift: "C2", acuity: 3 },
  { date: "8/23/2019 20:28:50", shift: "A2", acuity: 3 },
  { date: "8/23/2019 20:29:14", shift: "B2", acuity: 3 },
  { date: "8/23/2019 20:40:57", shift: "D2", acuity: 3 },
  { date: "8/23/2019 20:41:37", shift: "C2", acuity: 3 },
  { date: "8/23/2019 20:49:02", shift: "A2", acuity: 3 },
  { date: "8/23/2019 20:49:35", shift: "B2", acuity: 6 },
  { date: "8/23/2019 20:54:08", shift: "D2", acuity: 6 },
  { date: "8/23/2019 21:04:55", shift: "A3", acuity: 3 },
  { date: "8/23/2019 21:09:20", shift: "C2", acuity: 3 },
  { date: "8/23/2019 21:10:51", shift: "B2", acuity: 6 },
  { date: "8/23/2019 21:29:46", shift: "D2", acuity: 6 },
  { date: "8/23/2019 21:45:47", shift: "A3", acuity: 3 },
  { date: "8/23/2019 21:53:27", shift: "A3", acuity: 6 },
  { date: "8/23/2019 22:21:10", shift: "B3", acuity: 3 },
  { date: "8/23/2019 22:33:09", shift: "B3", acuity: 3 },
  { date: "8/23/2019 22:45:14", shift: "B3", acuity: 3 },
  { date: "8/23/2019 22:45:36", shift: "C2", acuity: 3 },
  { date: "8/23/2019 22:55:06", shift: "D2", acuity: 4 },
  { date: "8/23/2019 23:14:12", shift: "A3", acuity: 3 },
  { date: "8/23/2019 23:14:31", shift: "B3", acuity: 3 },
  { date: "8/23/2019 23:25:35", shift: "C2", acuity: 3 },
  { date: "8/23/2019 23:44:48", shift: "D2", acuity: 3 },
  { date: "8/23/2019 23:52:44", shift: "A3", acuity: 3 },
  { date: "8/23/2019 23:53:35", shift: "B3", acuity: 3 },
  { date: "8/24/2019 00:44:10", shift: "D2", acuity: 3 },
  { date: "8/24/2019 01:02:48", shift: "A3", acuity: 3 },
  { date: "8/24/2019 01:43:33", shift: "B3", acuity: 3 },
  { date: "8/24/2019 01:45:02", shift: "A3", acuity: 4 },
  { date: "8/24/2019 01:52:44", shift: "B3", acuity: 4 },
  { date: "8/24/2019 01:58:00", shift: "A3", acuity: 3 },
  { date: "8/24/2019 02:08:36", shift: "B3", acuity: 3 },
  { date: "8/24/2019 02:18:25", shift: "A3", acuity: 4 },
  { date: "8/24/2019 02:37:36", shift: "B3", acuity: 3 },
  { date: "8/24/2019 02:43:20", shift: "A3", acuity: 3 },
  { date: "8/24/2019 02:49:51", shift: "B3", acuity: 3 },
  { date: "8/24/2019 03:03:53", shift: "A3", acuity: 3 },
  { date: "8/24/2019 03:35:30", shift: "B3", acuity: 3 },
  { date: "8/24/2019 03:35:44", shift: "A3", acuity: 4 },
  { date: "8/24/2019 04:21:20", shift: "B3", acuity: 3 },
  { date: "8/24/2019 04:33:26", shift: "A3", acuity: 6 },
  { date: "8/24/2019 05:01:43", shift: "A1", acuity: 3 },
  { date: "8/24/2019 06:13:20", shift: "A1", acuity: 3 },
  { date: "8/24/2019 06:23:47", shift: "A1", acuity: 3 },
  { date: "8/24/2019 06:31:16", shift: "B1", acuity: 6 },
  { date: "8/24/2019 06:37:42", shift: "B1", acuity: 4 },
  { date: "8/24/2019 06:42:14", shift: "B1", acuity: 4 },
  { date: "8/24/2019 06:42:56", shift: "A1", acuity: 4 },
  { date: "8/24/2019 06:53:39", shift: "B1", acuity: 6 },
  { date: "8/24/2019 07:17:22", shift: "A1", acuity: 4 },
  { date: "8/24/2019 07:25:44", shift: "B1", acuity: 3 },
  { date: "8/24/2019 07:31:58", shift: "A1", acuity: 4 },
  { date: "8/24/2019 08:22:11", shift: "B1", acuity: 3 },
  { date: "8/24/2019 08:25:48", shift: "A1", acuity: 4 },
  { date: "8/24/2019 08:44:53", shift: "C1", acuity: 7 },
  { date: "8/24/2019 09:01:00", shift: "C1", acuity: 4 },
  { date: "8/24/2019 09:12:03", shift: "C1", acuity: 3 },
  { date: "8/24/2019 09:12:13", shift: "B1", acuity: 3 },
  { date: "8/24/2019 09:18:51", shift: "A1", acuity: 3 },
  { date: "8/24/2019 09:18:59", shift: "C1", acuity: 4 },
  { date: "8/24/2019 09:28:19", shift: "B1", acuity: 4 },
  { date: "8/24/2019 09:33:32", shift: "A1", acuity: 4 },
  { date: "8/24/2019 09:33:43", shift: "C1", acuity: 4 },
  { date: "8/24/2019 09:42:48", shift: "B1", acuity: 3 },
  { date: "8/24/2019 09:47:04", shift: "A1", acuity: 3 },
  { date: "8/24/2019 10:02:35", shift: "D1", acuity: 4 },
  { date: "8/24/2019 10:02:46", shift: "C1", acuity: 6 },
  { date: "8/24/2019 10:08:09", shift: "B1", acuity: 6 },
  { date: "8/24/2019 10:13:02", shift: "A1", acuity: 3 },
  { date: "8/24/2019 10:25:21", shift: "D1", acuity: 6 },
  { date: "8/24/2019 10:40:03", shift: "D1", acuity: 6 },
  { date: "8/24/2019 10:51:23", shift: "D1", acuity: 6 },
  { date: "8/24/2019 10:51:34", shift: "C1", acuity: 3 },
  { date: "8/24/2019 11:20:16", shift: "B1", acuity: 3 },
  { date: "8/24/2019 11:32:38", shift: "A1", acuity: 3 },
  { date: "8/24/2019 11:32:44", shift: "D1", acuity: 3 },
  { date: "8/24/2019 11:50:35", shift: "C1", acuity: 3 },
  { date: "8/24/2019 11:50:43", shift: "B1", acuity: 6 },
  { date: "8/24/2019 11:56:57", shift: "A1", acuity: 3 },
  { date: "8/24/2019 12:22:00", shift: "D1", acuity: 3 },
  { date: "8/24/2019 12:39:19", shift: "C1", acuity: 6 },
  { date: "8/24/2019 12:40:07", shift: "B1", acuity: 3 },
  { date: "8/24/2019 13:02:37", shift: "A2", acuity: 4 },
  { date: "8/24/2019 13:08:47", shift: "D1", acuity: 6 },
  { date: "8/24/2019 13:11:43", shift: "C1", acuity: 4 },
  { date: "8/24/2019 13:20:59", shift: "B1", acuity: 6 },
  { date: "8/24/2019 13:45:33", shift: "A2", acuity: 6 },
  { date: "8/24/2019 13:45:41", shift: "A2", acuity: 6 },
  { date: "8/24/2019 13:55:30", shift: "D1", acuity: 3 },
  { date: "8/24/2019 14:04:07", shift: "B2", acuity: 3 },
  { date: "8/24/2019 14:04:18", shift: "C1", acuity: 3 },
  { date: "8/24/2019 14:41:30", shift: "B2", acuity: 6 },
  { date: "8/24/2019 14:41:37", shift: "B2", acuity: 3 },
  { date: "8/24/2019 14:52:35", shift: "A2", acuity: 6 },
  { date: "8/24/2019 14:52:46", shift: "D1", acuity: 6 },
  { date: "8/24/2019 15:06:27", shift: "C1", acuity: 6 },
  { date: "8/24/2019 15:10:02", shift: "B2", acuity: 3 },
  { date: "8/24/2019 15:26:21", shift: "A2", acuity: 6 },
  { date: "8/24/2019 15:40:33", shift: "D1", acuity: 6 },
  { date: "8/24/2019 15:52:19", shift: "C1", acuity: 6 },
  { date: "8/24/2019 15:52:29", shift: "B2", acuity: 6 },
  { date: "8/24/2019 15:52:37", shift: "A2", acuity: 6 },
  { date: "8/24/2019 16:19:29", shift: "D1", acuity: 3 },
  { date: "8/24/2019 16:26:57", shift: "C1", acuity: 6 },
  { date: "8/24/2019 16:35:40", shift: "C2", acuity: 3 },
  { date: "8/24/2019 16:49:47", shift: "B2", acuity: 3 },
  { date: "8/24/2019 17:02:47", shift: "C2", acuity: 6 },
  { date: "8/24/2019 17:17:59", shift: "C2", acuity: 3 },
  { date: "8/24/2019 18:04:48", shift: "D2", acuity: 6 },
  { date: "8/24/2019 18:41:35", shift: "D2", acuity: 3 },
  { date: "8/24/2019 18:54:50", shift: "D2", acuity: 3 },
  { date: "8/24/2019 19:00:30", shift: "A2", acuity: 6 },
  { date: "8/24/2019 19:04:53", shift: "B2", acuity: 3 },
  { date: "8/24/2019 19:16:16", shift: "C2", acuity: 3 },
  { date: "8/24/2019 19:16:38", shift: "D2", acuity: 3 },
  { date: "8/24/2019 19:16:57", shift: "A2", acuity: 6 },
  { date: "8/24/2019 19:31:20", shift: "B2", acuity: 3 },
  { date: "8/24/2019 19:31:45", shift: "C2", acuity: 3 },
  { date: "8/24/2019 19:39:03", shift: "D2", acuity: 4 },
  { date: "8/24/2019 19:39:28", shift: "A2", acuity: 6 },
  { date: "8/24/2019 19:47:11", shift: "B2", acuity: 6 },
  { date: "8/24/2019 19:53:56", shift: "C2", acuity: 4 },
  { date: "8/24/2019 19:57:42", shift: "D2", acuity: 3 },
  { date: "8/24/2019 20:24:04", shift: "A2", acuity: 4 },
  { date: "8/24/2019 20:25:16", shift: "B2", acuity: 4 },
  { date: "8/24/2019 20:27:27", shift: "C2", acuity: 3 },
  { date: "8/24/2019 20:33:09", shift: "D2", acuity: 4 },
  { date: "8/24/2019 20:33:26", shift: "A2", acuity: 4 },
  { date: "8/24/2019 20:34:19", shift: "B2", acuity: 3 },
  { date: "8/24/2019 20:48:53", shift: "C2", acuity: 3 },
  { date: "8/24/2019 21:09:32", shift: "A3", acuity: 6 },
  { date: "8/24/2019 21:16:19", shift: "D2", acuity: 6 },
  { date: "8/24/2019 21:23:51", shift: "B2", acuity: 3 },
  { date: "8/24/2019 21:24:21", shift: "C2", acuity: 4 },
  { date: "8/24/2019 21:54:20", shift: "A3", acuity: 3 },
  { date: "8/24/2019 21:56:02", shift: "A3", acuity: 6 },
  { date: "8/24/2019 22:05:54", shift: "B3", acuity: 3 },
  { date: "8/24/2019 22:15:16", shift: "D2", acuity: 3 },
  { date: "8/24/2019 22:42:24", shift: "B3", acuity: 4 },
  { date: "8/24/2019 22:42:54", shift: "B3", acuity: 6 },
  { date: "8/24/2019 22:44:00", shift: "C2", acuity: 4 },
  { date: "8/24/2019 22:44:50", shift: "A3", acuity: 4 },
  { date: "8/24/2019 22:45:23", shift: "D2", acuity: 5 },
  { date: "8/24/2019 23:13:35", shift: "B3", acuity: 2 },
  { date: "8/24/2019 23:17:19", shift: "C2", acuity: 3 },
  { date: "8/24/2019 23:23:04", shift: "A3", acuity: 3 },
  { date: "8/24/2019 23:27:44", shift: "D2", acuity: 4 },
  { date: "8/24/2019 23:28:16", shift: "B3", acuity: 5 },
  { date: "8/24/2019 23:39:21", shift: "C2", acuity: 3 },
  { date: "8/24/2019 23:39:39", shift: "A3", acuity: 3 },
  { date: "8/24/2019 23:47:38", shift: "D2", acuity: 4 },
  { date: "8/25/2019 00:01:29", shift: "B3", acuity: 6 },
  { date: "8/25/2019 00:05:00", shift: "A3", acuity: 3 },
  { date: "8/25/2019 00:09:17", shift: "D2", acuity: 4 },
  { date: "8/25/2019 00:10:13", shift: "B3", acuity: 6 },
  { date: "8/25/2019 00:16:49", shift: "A3", acuity: 4 },
  { date: "8/25/2019 00:21:01", shift: "D2", acuity: 3 },
  { date: "8/25/2019 00:30:33", shift: "B3", acuity: 3 },
  { date: "8/25/2019 00:59:26", shift: "A3", acuity: 3 },
  { date: "8/25/2019 00:59:52", shift: "D2", acuity: 3 },
  { date: "8/25/2019 01:27:09", shift: "B3", acuity: 6 },
  { date: "8/25/2019 01:36:41", shift: "A3", acuity: 4 },
  { date: "8/25/2019 01:52:55", shift: "B3", acuity: 3 },
  { date: "8/25/2019 02:03:36", shift: "A3", acuity: 3 },
  { date: "8/25/2019 02:25:41", shift: "B3", acuity: 3 },
  { date: "8/25/2019 02:56:36", shift: "A3", acuity: 4 },
  { date: "8/25/2019 03:24:32", shift: "B3", acuity: 6 },
  { date: "8/25/2019 03:37:16", shift: "A3", acuity: 3 },
  { date: "8/25/2019 04:40:37", shift: "B3", acuity: 6 },
  { date: "8/25/2019 05:00:27", shift: "A1", acuity: 3 },
  { date: "8/25/2019 05:17:31", shift: "B3", acuity: 3 },
  { date: "8/25/2019 05:31:00", shift: "A1", acuity: 2 },
  { date: "8/25/2019 06:18:14", shift: "A1", acuity: 3 },
  { date: "8/25/2019 06:44:14", shift: "B1", acuity: 3 },
  { date: "8/25/2019 07:04:33", shift: "B1", acuity: 6 },
  { date: "8/25/2019 07:26:49", shift: "B1", acuity: 3 },
  { date: "8/25/2019 08:10:47", shift: "A1", acuity: 3 },
  { date: "8/25/2019 08:10:56", shift: "B1", acuity: 3 },
  { date: "8/25/2019 08:23:52", shift: "A1", acuity: 3 },
  { date: "8/25/2019 08:42:08", shift: "C1", acuity: 3 },
  { date: "8/25/2019 09:00:16", shift: "C1", acuity: 4 },
  { date: "8/25/2019 09:00:22", shift: "C1", acuity: 4 },
  { date: "8/25/2019 09:00:34", shift: "B1", acuity: 3 },
  { date: "8/25/2019 09:07:10", shift: "A1", acuity: 4 },
  { date: "8/25/2019 09:07:25", shift: "C1", acuity: 3 },
  { date: "8/25/2019 09:07:38", shift: "B1", acuity: 3 },
  { date: "8/25/2019 09:29:13", shift: "A1", acuity: 3 },
  { date: "8/25/2019 09:33:50", shift: "C1", acuity: 4 },
  { date: "8/25/2019 09:34:01", shift: "B1", acuity: 3 },
  { date: "8/25/2019 09:38:26", shift: "A1", acuity: 6 },
  { date: "8/25/2019 09:45:42", shift: "C1", acuity: 3 },
  { date: "8/25/2019 09:45:48", shift: "B1", acuity: 3 },
  { date: "8/25/2019 09:49:13", shift: "A1", acuity: 4 },
  { date: "8/25/2019 10:07:34", shift: "D1", acuity: 4 },
  { date: "8/25/2019 10:15:19", shift: "C1", acuity: 6 },
  { date: "8/25/2019 10:27:13", shift: "B1", acuity: 3 },
  { date: "8/25/2019 10:46:08", shift: "D1", acuity: 3 },
  { date: "8/25/2019 10:56:58", shift: "D1", acuity: 3 },
  { date: "8/25/2019 11:20:29", shift: "A1", acuity: 6 },
  { date: "8/25/2019 11:20:41", shift: "C1", acuity: 6 },
  { date: "8/25/2019 11:29:03", shift: "B1", acuity: 3 },
  { date: "8/25/2019 11:43:01", shift: "D1", acuity: 3 },
  { date: "8/25/2019 11:43:12", shift: "A1", acuity: 3 },
  { date: "8/25/2019 12:14:00", shift: "C1", acuity: 3 },
  { date: "8/25/2019 12:17:32", shift: "B1", acuity: 6 },
  { date: "8/25/2019 12:19:23", shift: "D1", acuity: 6 },
  { date: "8/25/2019 12:26:03", shift: "A1", acuity: 6 },
  { date: "8/25/2019 12:33:33", shift: "C1", acuity: 3 },
  { date: "8/25/2019 12:33:57", shift: "B1", acuity: 3 },
  { date: "8/25/2019 12:39:05", shift: "D1", acuity: 6 },
  { date: "8/25/2019 12:44:31", shift: "A1", acuity: 3 },
  { date: "8/25/2019 12:50:09", shift: "C1", acuity: 6 },
  { date: "8/25/2019 12:52:48", shift: "B1", acuity: 6 },
  { date: "8/25/2019 13:17:05", shift: "A2", acuity: 6 },
  { date: "8/25/2019 13:19:34", shift: "D1", acuity: 3 },
  { date: "8/25/2019 13:19:50", shift: "C1", acuity: 3 },
  { date: "8/25/2019 13:55:16", shift: "A2", acuity: 6 },
  { date: "8/25/2019 13:55:27", shift: "A2", acuity: 3 },
  { date: "8/25/2019 14:02:39", shift: "B2", acuity: 6 },
  { date: "8/25/2019 14:14:48", shift: "D1", acuity: 4 },
  { date: "8/25/2019 14:31:37", shift: "B2", acuity: 3 },
  { date: "8/25/2019 14:31:47", shift: "B2", acuity: 6 },
  { date: "8/25/2019 14:56:27", shift: "C1", acuity: 3 },
  { date: "8/25/2019 14:57:48", shift: "A2", acuity: 6 },
  { date: "8/25/2019 14:59:54", shift: "D1", acuity: 3 },
  { date: "8/25/2019 15:04:39", shift: "B2", acuity: 3 },
  { date: "8/25/2019 15:13:44", shift: "C1", acuity: 4 },
  { date: "8/25/2019 15:13:54", shift: "A2", acuity: 3 },
  { date: "8/25/2019 15:34:29", shift: "D1", acuity: 6 },
  { date: "8/25/2019 15:37:52", shift: "B2", acuity: 3 },
  { date: "8/25/2019 15:42:14", shift: "C1", acuity: 3 },
  { date: "8/25/2019 15:58:56", shift: "A2", acuity: 6 },
  { date: "8/25/2019 15:59:05", shift: "D1", acuity: 3 },
  { date: "8/25/2019 16:14:59", shift: "B2", acuity: 4 },
  { date: "8/25/2019 16:18:13", shift: "C1", acuity: 6 },
  { date: "8/25/2019 16:30:10", shift: "C2", acuity: 6 },
  { date: "8/25/2019 16:33:15", shift: "A2", acuity: 6 },
  { date: "8/25/2019 16:36:40", shift: "D1", acuity: 3 },
  { date: "8/25/2019 16:50:40", shift: "B2", acuity: 6 },
  { date: "8/25/2019 16:50:52", shift: "C2", acuity: 4 },
  { date: "8/25/2019 16:52:09", shift: "A2", acuity: 3 },
  { date: "8/25/2019 17:00:18", shift: "C2", acuity: 6 },
  { date: "8/25/2019 17:09:27", shift: "C2", acuity: 6 },
  { date: "8/25/2019 17:18:22", shift: "D1", acuity: 3 },
  { date: "8/25/2019 17:41:05", shift: "B2", acuity: 6 },
  { date: "8/25/2019 17:42:00", shift: "A2", acuity: 3 },
  { date: "8/25/2019 17:42:14", shift: "C2", acuity: 6 },
  { date: "8/25/2019 17:54:04", shift: "D1", acuity: 3 },
  { date: "8/25/2019 17:55:31", shift: "B2", acuity: 6 },
  { date: "8/25/2019 18:00:46", shift: "D2", acuity: 3 },
  { date: "8/25/2019 18:20:25", shift: "A2", acuity: 6 },
  { date: "8/25/2019 18:30:39", shift: "D2", acuity: 6 },
  { date: "8/25/2019 18:33:24", shift: "D2", acuity: 3 },
  { date: "8/25/2019 18:51:21", shift: "C2", acuity: 6 },
  { date: "8/25/2019 18:56:42", shift: "B2", acuity: 3 },
  { date: "8/25/2019 18:58:27", shift: "A2", acuity: 3 },
  { date: "8/25/2019 19:03:14", shift: "D2", acuity: 3 },
  { date: "8/25/2019 19:22:16", shift: "C2", acuity: 3 },
  { date: "8/25/2019 19:22:41", shift: "B2", acuity: 3 },
  { date: "8/25/2019 19:27:05", shift: "A2", acuity: 3 },
  { date: "8/25/2019 19:44:40", shift: "D2", acuity: 3 },
  { date: "8/25/2019 19:45:03", shift: "C2", acuity: 3 },
  { date: "8/25/2019 19:54:22", shift: "B2", acuity: 3 },
  { date: "8/25/2019 20:02:27", shift: "A2", acuity: 3 },
  { date: "8/25/2019 20:03:02", shift: "D2", acuity: 3 },
  { date: "8/25/2019 20:14:18", shift: "C2", acuity: 3 },
  { date: "8/25/2019 20:33:59", shift: "B2", acuity: 6 },
  { date: "8/25/2019 20:36:31", shift: "A2", acuity: 6 },
  { date: "8/25/2019 20:37:42", shift: "D2", acuity: 4 },
  { date: "8/25/2019 20:44:02", shift: "C2", acuity: 4 },
  { date: "8/25/2019 20:48:55", shift: "B2", acuity: 3 },
  { date: "8/25/2019 20:51:26", shift: "A2", acuity: 6 },
  { date: "8/25/2019 21:07:39", shift: "A3", acuity: 3 },
  { date: "8/25/2019 21:11:53", shift: "D2", acuity: 3 },
  { date: "8/25/2019 21:12:33", shift: "C2", acuity: 6 },
  { date: "8/25/2019 21:20:05", shift: "B2", acuity: 3 },
  { date: "8/25/2019 21:20:47", shift: "A3", acuity: 3 },
  { date: "8/25/2019 21:44:44", shift: "A3", acuity: 3 },
  { date: "8/25/2019 21:47:05", shift: "A3", acuity: 3 },
  { date: "8/25/2019 22:07:15", shift: "B3", acuity: 3 },
  { date: "8/25/2019 22:10:25", shift: "D2", acuity: 4 },
  { date: "8/25/2019 22:10:54", shift: "C2", acuity: 6 },
  { date: "8/25/2019 22:28:00", shift: "A3", acuity: 3 },
  { date: "8/25/2019 22:41:09", shift: "B3", acuity: 4 },
  { date: "8/25/2019 22:42:40", shift: "B3", acuity: 6 },
  { date: "8/25/2019 23:02:14", shift: "D2", acuity: 6 },
  { date: "8/25/2019 23:10:56", shift: "C2", acuity: 3 },
  { date: "8/25/2019 23:18:01", shift: "A3", acuity: 6 },
  { date: "8/25/2019 23:31:44", shift: "B3", acuity: 3 },
  { date: "8/25/2019 23:32:20", shift: "D2", acuity: 4 },
  { date: "8/25/2019 23:42:44", shift: "C2", acuity: 6 },
  { date: "8/25/2019 23:45:03", shift: "A3", acuity: 4 },
  { date: "8/25/2019 23:50:51", shift: "B3", acuity: 4 },
  { date: "8/25/2019 23:55:32", shift: "D2", acuity: 3 },
  { date: "8/26/2019 00:20:38", shift: "A3", acuity: 6 },
  { date: "8/26/2019 00:22:34", shift: "B3", acuity: 3 },
  { date: "8/26/2019 00:29:48", shift: "D2", acuity: 4 },
  { date: "8/26/2019 00:44:33", shift: "A3", acuity: 4 },
  { date: "8/26/2019 00:49:10", shift: "B3", acuity: 3 },
  { date: "8/26/2019 00:56:39", shift: "D2", acuity: 4 },
  { date: "8/26/2019 01:08:45", shift: "A3", acuity: 3 },
  { date: "8/26/2019 01:09:21", shift: "B3", acuity: 4 },
  { date: "8/26/2019 01:23:47", shift: "D2", acuity: 4 },
  { date: "8/26/2019 01:24:10", shift: "A3", acuity: 3 },
  { date: "8/26/2019 01:35:31", shift: "B3", acuity: 3 },
  { date: "8/26/2019 01:45:33", shift: "A3", acuity: 4 },
  { date: "8/26/2019 02:05:01", shift: "B3", acuity: 3 },
  { date: "8/26/2019 02:05:17", shift: "A3", acuity: 4 },
  { date: "8/26/2019 02:21:01", shift: "B3", acuity: 4 },
  { date: "8/26/2019 02:21:23", shift: "A3", acuity: 4 },
  { date: "8/26/2019 02:49:21", shift: "B3", acuity: 3 },
  { date: "8/26/2019 02:49:57", shift: "A3", acuity: 3 },
  { date: "8/26/2019 03:00:10", shift: "B3", acuity: 4 },
  { date: "8/26/2019 03:00:21", shift: "A3", acuity: 4 },
  { date: "8/26/2019 03:22:57", shift: "B3", acuity: 3 },
  { date: "8/26/2019 03:49:10", shift: "A3", acuity: 3 },
  { date: "8/26/2019 03:53:01", shift: "B3", acuity: 4 },
  { date: "8/26/2019 04:12:56", shift: "A3", acuity: 4 },
  { date: "8/26/2019 04:16:37", shift: "B3", acuity: 3 },
  { date: "8/26/2019 04:22:19", shift: "A3", acuity: 6 },
  { date: "8/26/2019 04:26:38", shift: "B3", acuity: 3 },
  { date: "8/26/2019 05:05:11", shift: "A1", acuity: 6 },
  { date: "8/26/2019 05:13:32", shift: "B3", acuity: 3 },
  { date: "8/26/2019 06:34:51", shift: "A1", acuity: 6 },
  { date: "8/26/2019 06:47:37", shift: "A1", acuity: 4 },
  { date: "8/26/2019 06:50:38", shift: "B1", acuity: 3 },
  { date: "8/26/2019 06:51:20", shift: "B1", acuity: 3 },
  { date: "8/26/2019 07:24:50", shift: "B1", acuity: 4 },
  { date: "8/26/2019 07:35:58", shift: "A1", acuity: 6 },
  { date: "8/26/2019 07:41:14", shift: "B1", acuity: 4 },
  { date: "8/26/2019 08:00:38", shift: "A1", acuity: 6 },
  { date: "8/26/2019 08:00:46", shift: "B1", acuity: 6 },
  { date: "8/26/2019 08:10:55", shift: "A1", acuity: 6 },
  { date: "8/26/2019 08:11:04", shift: "B1", acuity: 3 },
  { date: "8/26/2019 08:43:21", shift: "C1", acuity: 3 },
  { date: "8/26/2019 08:44:51", shift: "A1", acuity: 3 },
  { date: "8/26/2019 08:52:51", shift: "B1", acuity: 3 },
  { date: "8/26/2019 08:53:12", shift: "C1", acuity: 3 },
  { date: "8/26/2019 08:56:58", shift: "A1", acuity: 4 },
  { date: "8/26/2019 08:58:19", shift: "B1", acuity: 6 },
  { date: "8/26/2019 09:25:11", shift: "C1", acuity: 2 },
  { date: "8/26/2019 09:44:19", shift: "C1", acuity: 3 },
  { date: "8/26/2019 10:10:36", shift: "D1", acuity: 3 },
  { date: "8/26/2019 10:10:44", shift: "A1", acuity: 4 },
  { date: "8/26/2019 10:11:58", shift: "B1", acuity: 3 },
  { date: "8/26/2019 10:13:52", shift: "C1", acuity: 3 },
  { date: "8/26/2019 10:36:39", shift: "D1", acuity: 3 },
  { date: "8/26/2019 10:51:31", shift: "D1", acuity: 6 },
  { date: "8/26/2019 11:01:23", shift: "A1", acuity: 6 },
  { date: "8/26/2019 11:04:17", shift: "B1", acuity: 3 },
  { date: "8/26/2019 11:04:30", shift: "C1", acuity: 3 },
  { date: "8/26/2019 11:15:02", shift: "D1", acuity: 3 },
  { date: "8/26/2019 11:18:43", shift: "A1", acuity: 3 },
  { date: "8/26/2019 11:18:50", shift: "B1", acuity: 3 },
  { date: "8/26/2019 11:18:57", shift: "C1", acuity: 6 },
  { date: "8/26/2019 11:38:24", shift: "D1", acuity: 6 },
  { date: "8/26/2019 11:56:14", shift: "A1", acuity: 3 },
  { date: "8/26/2019 12:11:51", shift: "B1", acuity: 6 },
  { date: "8/26/2019 12:19:34", shift: "C1", acuity: 3 },
  { date: "8/26/2019 12:25:51", shift: "D1", acuity: 6 },
  { date: "8/26/2019 12:26:42", shift: "A1", acuity: 3 },
  { date: "8/26/2019 12:28:16", shift: "B1", acuity: 3 },
  { date: "8/26/2019 12:47:06", shift: "C1", acuity: 6 },
  { date: "8/26/2019 13:19:39", shift: "A2", acuity: 6 },
  { date: "8/26/2019 13:21:49", shift: "D1", acuity: 6 },
  { date: "8/26/2019 13:23:36", shift: "B1", acuity: 3 },
  { date: "8/26/2019 13:42:06", shift: "A2", acuity: 3 },
  { date: "8/26/2019 14:05:39", shift: "A2", acuity: 6 },
  { date: "8/26/2019 14:14:24", shift: "B2", acuity: 6 },
  { date: "8/26/2019 14:14:36", shift: "C1", acuity: 6 },
  { date: "8/26/2019 14:22:05", shift: "D1", acuity: 6 },
  { date: "8/26/2019 14:26:14", shift: "A2", acuity: 3 },
  { date: "8/26/2019 14:30:46", shift: "B2", acuity: 3 },
  { date: "8/26/2019 14:31:04", shift: "B2", acuity: 6 },
  { date: "8/26/2019 14:49:14", shift: "C1", acuity: 3 },
  { date: "8/26/2019 14:57:06", shift: "D1", acuity: 6 },
  { date: "8/26/2019 15:01:33", shift: "A2", acuity: 3 },
  { date: "8/26/2019 15:10:04", shift: "B2", acuity: 3 },
  { date: "8/26/2019 15:21:56", shift: "C1", acuity: 6 },
  { date: "8/26/2019 15:39:10", shift: "D1", acuity: 3 },
  { date: "8/26/2019 15:39:31", shift: "A2", acuity: 6 },
  { date: "8/26/2019 16:05:33", shift: "B2", acuity: 3 },
  { date: "8/26/2019 16:07:03", shift: "C1", acuity: 3 },
  { date: "8/26/2019 16:07:48", shift: "D1", acuity: 3 },
  { date: "8/26/2019 16:08:51", shift: "A2", acuity: 4 },
  { date: "8/26/2019 16:22:57", shift: "B2", acuity: 3 },
  { date: "8/26/2019 16:32:32", shift: "C2", acuity: 6 },
  { date: "8/26/2019 16:45:51", shift: "D1", acuity: 3 },
  { date: "8/26/2019 16:46:59", shift: "A2", acuity: 5 },
  { date: "8/26/2019 16:48:18", shift: "B2", acuity: 6 },
  { date: "8/26/2019 16:54:17", shift: "C2", acuity: 3 },
  { date: "8/26/2019 17:00:13", shift: "C2", acuity: 3 },
  { date: "8/26/2019 17:12:23", shift: "C2", acuity: 6 },
  { date: "8/26/2019 17:28:23", shift: "D1", acuity: 6 },
  { date: "8/26/2019 17:37:08", shift: "A2", acuity: 6 },
  { date: "8/26/2019 17:38:53", shift: "B2", acuity: 6 },
  { date: "8/26/2019 17:39:05", shift: "C2", acuity: 4 },
  { date: "8/26/2019 17:45:07", shift: "D1", acuity: 6 },
  { date: "8/26/2019 17:52:02", shift: "A2", acuity: 3 },
  { date: "8/26/2019 18:06:51", shift: "D2", acuity: 6 },
  { date: "8/26/2019 18:13:17", shift: "B2", acuity: 6 },
  { date: "8/26/2019 18:20:13", shift: "C2", acuity: 6 },
  { date: "8/26/2019 18:30:54", shift: "D2", acuity: 4 },
  { date: "8/26/2019 18:57:07", shift: "D2", acuity: 7 },
  { date: "8/26/2019 19:14:09", shift: "A2", acuity: 6 },
  { date: "8/26/2019 19:23:54", shift: "B2", acuity: 3 },
  { date: "8/26/2019 19:24:56", shift: "C2", acuity: 6 },
  { date: "8/26/2019 19:25:20", shift: "D2", acuity: 3 },
  { date: "8/26/2019 19:25:51", shift: "A2", acuity: 6 },
  { date: "8/26/2019 19:37:55", shift: "B2", acuity: 6 },
  { date: "8/26/2019 19:38:40", shift: "C2", acuity: 6 },
  { date: "8/26/2019 19:39:06", shift: "D2", acuity: 3 },
  { date: "8/26/2019 19:42:42", shift: "A2", acuity: 6 },
  { date: "8/26/2019 19:57:51", shift: "B2", acuity: 6 },
  { date: "8/26/2019 20:03:50", shift: "C2", acuity: 6 },
  { date: "8/26/2019 20:11:04", shift: "D2", acuity: 6 },
  { date: "8/26/2019 20:11:27", shift: "A2", acuity: 6 },
  { date: "8/26/2019 20:25:41", shift: "B2", acuity: 3 },
  { date: "8/26/2019 20:36:06", shift: "C2", acuity: 3 },
  { date: "8/26/2019 20:53:25", shift: "D2", acuity: 3 },
  { date: "8/26/2019 20:55:49", shift: "A2", acuity: 3 },
  { date: "8/26/2019 21:05:14", shift: "A3", acuity: 3 },
  { date: "8/26/2019 21:10:20", shift: "B2", acuity: 3 },
  { date: "8/26/2019 21:28:39", shift: "C2", acuity: 6 },
  { date: "8/26/2019 21:29:29", shift: "D2", acuity: 6 },
  { date: "8/26/2019 21:52:00", shift: "A3", acuity: 6 },
  { date: "8/26/2019 21:52:51", shift: "A3", acuity: 3 },
  { date: "8/26/2019 21:53:15", shift: "B2", acuity: 3 },
  { date: "8/26/2019 22:10:27", shift: "B3", acuity: 4 },
  { date: "8/26/2019 22:13:48", shift: "C2", acuity: 6 },
  { date: "8/26/2019 22:21:24", shift: "D2", acuity: 3 },
  { date: "8/26/2019 22:32:06", shift: "B3", acuity: 3 },
  { date: "8/26/2019 22:36:52", shift: "B3", acuity: 4 },
  { date: "8/26/2019 22:38:41", shift: "A3", acuity: 3 },
  { date: "8/26/2019 22:52:32", shift: "C2", acuity: 6 },
  { date: "8/26/2019 22:52:47", shift: "D2", acuity: 4 },
  { date: "8/26/2019 22:54:23", shift: "B3", acuity: 3 },
  { date: "8/26/2019 22:59:08", shift: "A3", acuity: 3 },
  { date: "8/26/2019 23:04:59", shift: "C2", acuity: 6 },
  { date: "8/26/2019 23:05:08", shift: "D2", acuity: 3 },
  { date: "8/26/2019 23:11:14", shift: "B3", acuity: 3 },
  { date: "8/26/2019 23:20:19", shift: "A3", acuity: 6 },
  { date: "8/26/2019 23:24:15", shift: "C2", acuity: 6 },
  { date: "8/26/2019 23:26:01", shift: "D2", acuity: 3 },
  { date: "8/26/2019 23:38:32", shift: "B3", acuity: 6 },
  { date: "8/26/2019 23:45:02", shift: "A3", acuity: 3 },
  { date: "8/26/2019 23:49:26", shift: "C2", acuity: 3 },
  { date: "8/27/2019 00:02:59", shift: "D2", acuity: 6 },
  { date: "8/27/2019 00:03:36", shift: "B3", acuity: 4 },
  { date: "8/27/2019 00:18:06", shift: "A3", acuity: 3 },
  { date: "8/27/2019 00:31:59", shift: "D2", acuity: 3 },
  { date: "8/27/2019 00:32:21", shift: "B3", acuity: 4 },
  { date: "8/27/2019 00:39:28", shift: "A3", acuity: 4 },
  { date: "8/27/2019 01:05:39", shift: "D2", acuity: 6 },
  { date: "8/27/2019 01:25:06", shift: "B3", acuity: 6 },
  { date: "8/27/2019 01:30:54", shift: "A3", acuity: 3 },
  { date: "8/27/2019 01:39:48", shift: "B3", acuity: 3 },
  { date: "8/27/2019 01:52:18", shift: "A3", acuity: 3 },
  { date: "8/27/2019 02:15:39", shift: "B3", acuity: 5 },
  { date: "8/27/2019 03:30:19", shift: "A3", acuity: 4 },
  { date: "8/27/2019 03:46:18", shift: "B3", acuity: 3 },
  { date: "8/27/2019 04:02:39", shift: "A3", acuity: 6 },
  { date: "8/27/2019 04:07:17", shift: "B3", acuity: 3 },
  { date: "8/27/2019 04:26:07", shift: "A3", acuity: 4 },
  { date: "8/27/2019 05:04:52", shift: "A1", acuity: 4 },
  { date: "8/27/2019 05:32:08", shift: "A1", acuity: 6 },
  { date: "8/27/2019 06:15:08", shift: "A1", acuity: 4 },
  { date: "8/27/2019 06:30:10", shift: "B1", acuity: 4 },
  { date: "8/27/2019 07:01:44", shift: "B1", acuity: 3 },
  { date: "8/27/2019 07:17:12", shift: "B1", acuity: 4 },
  { date: "8/27/2019 07:27:48", shift: "A1", acuity: 4 },
  { date: "8/27/2019 07:35:12", shift: "B1", acuity: 6 },
  { date: "8/27/2019 07:36:48", shift: "A1", acuity: 3 },
  { date: "8/27/2019 07:58:46", shift: "B1", acuity: 3 },
  { date: "8/27/2019 07:59:09", shift: "A1", acuity: 6 },
  { date: "8/27/2019 08:09:01", shift: "B1", acuity: 3 },
  { date: "8/27/2019 08:12:18", shift: "A1", acuity: 3 },
  { date: "8/27/2019 08:26:37", shift: "B1", acuity: 4 },
  { date: "8/27/2019 08:39:07", shift: "C1", acuity: 4 },
  { date: "8/27/2019 08:47:05", shift: "A1", acuity: 3 },
  { date: "8/27/2019 08:47:19", shift: "B1", acuity: 3 },
  { date: "8/27/2019 08:48:26", shift: "C1", acuity: 4 },
  { date: "8/27/2019 08:50:17", shift: "A1", acuity: 3 },
  { date: "8/27/2019 09:13:44", shift: "C1", acuity: 6 },
  { date: "8/27/2019 09:20:16", shift: "C1", acuity: 5 },
  { date: "8/27/2019 09:39:27", shift: "B1", acuity: 3 },
  { date: "8/27/2019 09:45:07", shift: "A1", acuity: 3 },
  { date: "8/27/2019 09:47:19", shift: "C1", acuity: 3 },
  { date: "8/27/2019 09:47:34", shift: "B1", acuity: 3 },
  { date: "8/27/2019 09:49:44", shift: "A1", acuity: 3 },
  { date: "8/27/2019 09:49:58", shift: "C1", acuity: 3 },
  { date: "8/27/2019 09:57:01", shift: "B1", acuity: 2 },
  { date: "8/27/2019 10:09:19", shift: "D1", acuity: 3 },
  { date: "8/27/2019 10:11:53", shift: "A1", acuity: 6 },
  { date: "8/27/2019 10:31:11", shift: "D1", acuity: 6 },
  { date: "8/27/2019 10:40:24", shift: "D1", acuity: 6 },
  { date: "8/27/2019 11:19:25", shift: "C1", acuity: 3 },
  { date: "8/27/2019 11:20:56", shift: "B1", acuity: 3 },
  { date: "8/27/2019 11:26:53", shift: "A1", acuity: 2 },
  { date: "8/27/2019 11:31:41", shift: "D1", acuity: 6 },
  { date: "8/27/2019 11:41:16", shift: "C1", acuity: 6 },
  { date: "8/27/2019 11:47:53", shift: "B1", acuity: 3 },
  { date: "8/27/2019 12:01:08", shift: "A1", acuity: 3 },
  { date: "8/27/2019 12:04:17", shift: "D1", acuity: 3 },
  { date: "8/27/2019 12:09:55", shift: "C1", acuity: 6 },
  { date: "8/27/2019 12:25:07", shift: "B1", acuity: 3 },
  { date: "8/27/2019 12:47:13", shift: "A1", acuity: 6 },
  { date: "8/27/2019 12:49:28", shift: "D1", acuity: 3 },
  { date: "8/27/2019 12:49:51", shift: "C1", acuity: 3 },
  { date: "8/27/2019 13:05:30", shift: "A2", acuity: 6 },
  { date: "8/27/2019 13:08:19", shift: "B1", acuity: 6 },
  { date: "8/27/2019 13:14:26", shift: "D1", acuity: 6 },
  { date: "8/27/2019 13:33:47", shift: "A2", acuity: 6 },
  { date: "8/27/2019 13:50:01", shift: "A2", acuity: 3 },
  { date: "8/27/2019 13:50:27", shift: "C1", acuity: 6 },
  { date: "8/27/2019 14:00:24", shift: "B2", acuity: 6 },
  { date: "8/27/2019 14:03:19", shift: "D1", acuity: 3 },
  { date: "8/27/2019 14:06:32", shift: "A2", acuity: 6 },
  { date: "8/27/2019 14:12:55", shift: "C1", acuity: 6 },
  { date: "8/27/2019 14:25:23", shift: "B2", acuity: 3 },
  { date: "8/27/2019 14:54:19", shift: "B2", acuity: 6 },
  { date: "8/27/2019 15:10:29", shift: "B2", acuity: 3 },
  { date: "8/27/2019 15:19:31", shift: "D1", acuity: 6 },
  { date: "8/27/2019 15:23:59", shift: "A2", acuity: 3 },
  { date: "8/27/2019 15:35:09", shift: "C1", acuity: 6 },
  { date: "8/27/2019 15:37:00", shift: "B2", acuity: 6 },
  { date: "8/27/2019 15:37:45", shift: "D1", acuity: 6 },
  { date: "8/27/2019 15:52:27", shift: "A2", acuity: 6 },
  { date: "8/27/2019 15:52:52", shift: "C1", acuity: 3 },
  { date: "8/27/2019 15:53:10", shift: "B2", acuity: 3 },
  { date: "8/27/2019 15:53:36", shift: "D1", acuity: 3 },
  { date: "8/27/2019 16:10:03", shift: "A2", acuity: 4 },
  { date: "8/27/2019 16:16:31", shift: "C1", acuity: 3 },
  { date: "8/27/2019 16:20:42", shift: "B2", acuity: 6 },
  { date: "8/27/2019 16:20:58", shift: "D1", acuity: 3 },
  { date: "8/27/2019 16:29:41", shift: "A2", acuity: 6 },
  { date: "8/27/2019 16:40:57", shift: "C2", acuity: 3 },
  { date: "8/27/2019 16:44:35", shift: "B2", acuity: 3 },
  { date: "8/27/2019 16:50:13", shift: "D1", acuity: 3 },
  { date: "8/27/2019 17:14:15", shift: "C2", acuity: 6 },
  { date: "8/27/2019 18:14:17", shift: "C2", acuity: 3 },
  { date: "8/27/2019 18:17:44", shift: "D2", acuity: 3 },
  { date: "8/27/2019 18:23:33", shift: "A2", acuity: 3 },
  { date: "8/27/2019 18:27:38", shift: "B2", acuity: 6 },
  { date: "8/27/2019 18:31:48", shift: "D2", acuity: 6 },
  { date: "8/27/2019 18:33:50", shift: "D2", acuity: 6 },
  { date: "8/27/2019 18:34:40", shift: "C2", acuity: 3 },
  { date: "8/27/2019 18:35:05", shift: "A2", acuity: 3 },
  { date: "8/27/2019 19:13:20", shift: "B2", acuity: 3 },
  { date: "8/27/2019 19:27:48", shift: "D2", acuity: 3 },
  { date: "8/27/2019 19:36:40", shift: "C2", acuity: 3 },
  { date: "8/27/2019 19:44:04", shift: "A2", acuity: 6 },
  { date: "8/27/2019 19:46:50", shift: "B2", acuity: 3 },
  { date: "8/27/2019 19:54:44", shift: "D2", acuity: 3 },
  { date: "8/27/2019 20:04:12", shift: "C2", acuity: 3 },
  { date: "8/27/2019 20:11:31", shift: "A2", acuity: 3 },
  { date: "8/27/2019 20:18:46", shift: "B2", acuity: 3 },
  { date: "8/27/2019 20:21:11", shift: "D2", acuity: 6 },
  { date: "8/27/2019 20:38:53", shift: "C2", acuity: 3 },
  { date: "8/27/2019 20:41:02", shift: "A2", acuity: 4 },
  { date: "8/27/2019 20:56:26", shift: "B2", acuity: 3 },
  { date: "8/27/2019 21:23:52", shift: "A3", acuity: 3 },
  { date: "8/27/2019 21:29:20", shift: "D2", acuity: 3 },
  { date: "8/27/2019 21:37:37", shift: "A3", acuity: 3 },
  { date: "8/27/2019 21:57:40", shift: "A3", acuity: 3 },
  { date: "8/27/2019 22:06:40", shift: "B3", acuity: 6 },
  { date: "8/27/2019 22:31:26", shift: "B3", acuity: 4 },
  { date: "8/27/2019 22:39:47", shift: "B3", acuity: 3 },
  { date: "8/27/2019 22:40:47", shift: "C2", acuity: 3 },
  { date: "8/27/2019 22:47:09", shift: "D2", acuity: 3 },
  { date: "8/27/2019 22:47:29", shift: "A3", acuity: 3 },
  { date: "8/27/2019 22:47:56", shift: "B3", acuity: 4 },
  { date: "8/27/2019 22:51:37", shift: "C2", acuity: 3 },
  { date: "8/27/2019 23:06:45", shift: "D2", acuity: 4 },
  { date: "8/27/2019 23:23:38", shift: "A3", acuity: 4 },
  { date: "8/27/2019 23:23:52", shift: "B3", acuity: 4 },
  { date: "8/27/2019 23:28:38", shift: "C2", acuity: 4 },
  { date: "8/27/2019 23:34:55", shift: "D2", acuity: 3 },
  { date: "8/27/2019 23:45:34", shift: "A3", acuity: 6 },
  { date: "8/27/2019 23:46:00", shift: "B3", acuity: 3 },
  { date: "8/28/2019 00:31:43", shift: "D2", acuity: 6 },
  { date: "8/28/2019 00:38:35", shift: "A3", acuity: 6 },
  { date: "8/28/2019 00:58:02", shift: "B3", acuity: 4 },
  { date: "8/28/2019 01:53:17", shift: "A3", acuity: 3 },
  { date: "8/28/2019 02:06:06", shift: "B3", acuity: 3 },
  { date: "8/28/2019 02:11:21", shift: "A3", acuity: 4 },
  { date: "8/28/2019 02:27:16", shift: "B3", acuity: 4 },
  { date: "8/28/2019 02:37:23", shift: "A3", acuity: 4 },
  { date: "8/28/2019 02:48:54", shift: "B3", acuity: 4 },
  { date: "8/28/2019 04:16:34", shift: "A3", acuity: 4 },
  { date: "8/28/2019 04:22:47", shift: "B3", acuity: 6 },
  { date: "8/28/2019 05:01:54", shift: "A1", acuity: 3 },
  { date: "8/28/2019 05:02:14", shift: "B3", acuity: 6 },
  { date: "8/28/2019 05:58:49", shift: "A1", acuity: 6 },
  { date: "8/28/2019 06:27:37", shift: "A1", acuity: 5 },
  { date: "8/28/2019 07:36:14", shift: "B1", acuity: 4 },
  { date: "8/28/2019 08:03:10", shift: "A1", acuity: 3 },
  { date: "8/28/2019 08:06:09", shift: "B1", acuity: 6 },
  { date: "8/28/2019 08:14:06", shift: "A1", acuity: 4 },
  { date: "8/28/2019 08:54:24", shift: "C1", acuity: 4 },
  { date: "8/28/2019 09:02:02", shift: "C1", acuity: 3 },
  { date: "8/28/2019 09:08:50", shift: "C1", acuity: 5 },
  { date: "8/28/2019 09:09:11", shift: "B1", acuity: 4 },
  { date: "8/28/2019 09:22:48", shift: "A1", acuity: 3 },
  { date: "8/28/2019 09:26:20", shift: "C1", acuity: 4 },
  { date: "8/28/2019 09:26:38", shift: "B1", acuity: 3 },
  { date: "8/28/2019 09:50:04", shift: "A1", acuity: 3 },
  { date: "8/28/2019 10:01:22", shift: "D1", acuity: 6 },
  { date: "8/28/2019 10:20:21", shift: "C1", acuity: 3 },
  { date: "8/28/2019 10:22:03", shift: "B1", acuity: 3 },
  { date: "8/28/2019 10:36:19", shift: "D1", acuity: 3 },
  { date: "8/28/2019 10:36:37", shift: "D1", acuity: 3 },
  { date: "8/28/2019 10:50:50", shift: "A1", acuity: 3 },
  { date: "8/28/2019 10:58:16", shift: "C1", acuity: 3 },
  { date: "8/28/2019 11:17:55", shift: "B1", acuity: 7 },
  { date: "8/28/2019 11:25:55", shift: "D1", acuity: 3 },
  { date: "8/28/2019 11:46:07", shift: "A1", acuity: 7 },
  { date: "8/28/2019 11:49:26", shift: "C1", acuity: 7 },
  { date: "8/28/2019 12:03:30", shift: "B1", acuity: 6 },
  { date: "8/28/2019 12:03:47", shift: "D1", acuity: 3 },
  { date: "8/28/2019 12:05:23", shift: "A1", acuity: 7 },
  { date: "8/28/2019 12:11:14", shift: "C1", acuity: 3 },
  { date: "8/28/2019 12:17:21", shift: "B1", acuity: 3 },
  { date: "8/28/2019 12:17:40", shift: "D1", acuity: 3 },
  { date: "8/28/2019 12:49:13", shift: "A1", acuity: 2 },
  { date: "8/28/2019 12:51:56", shift: "C1", acuity: 3 },
  { date: "8/28/2019 12:52:52", shift: "B1", acuity: 6 },
  { date: "8/28/2019 13:04:19", shift: "A2", acuity: 3 },
  { date: "8/28/2019 13:06:09", shift: "D1", acuity: 7 },
  { date: "8/28/2019 13:21:34", shift: "C1", acuity: 6 },
  { date: "8/28/2019 13:35:40", shift: "A2", acuity: 3 },
  { date: "8/28/2019 13:50:24", shift: "A2", acuity: 3 },
  { date: "8/28/2019 13:50:40", shift: "B1", acuity: 3 },
  { date: "8/28/2019 14:16:24", shift: "B2", acuity: 6 },
  { date: "8/28/2019 14:18:33", shift: "D1", acuity: 7 },
  { date: "8/28/2019 14:32:25", shift: "B2", acuity: 7 },
  { date: "8/28/2019 14:37:15", shift: "B2", acuity: 6 },
  { date: "8/28/2019 14:53:25", shift: "C1", acuity: 3 },
  { date: "8/28/2019 15:25:44", shift: "A2", acuity: 3 },
  { date: "8/28/2019 15:32:06", shift: "D1", acuity: 3 },
  { date: "8/28/2019 15:32:28", shift: "B2", acuity: 3 },
  { date: "8/28/2019 15:32:57", shift: "C1", acuity: 6 },
  { date: "8/28/2019 15:33:27", shift: "A2", acuity: 6 },
  { date: "8/28/2019 15:33:51", shift: "D1", acuity: 6 },
  { date: "8/28/2019 15:40:52", shift: "B2", acuity: 3 },
  { date: "8/28/2019 15:45:52", shift: "C1", acuity: 6 },
  { date: "8/28/2019 15:57:03", shift: "A2", acuity: 3 },
  { date: "8/28/2019 15:59:12", shift: "D1", acuity: 7 },
  { date: "8/28/2019 16:04:28", shift: "B2", acuity: 7 },
  { date: "8/28/2019 16:04:51", shift: "C1", acuity: 3 },
  { date: "8/28/2019 16:07:31", shift: "A2", acuity: 3 },
  { date: "8/28/2019 16:07:51", shift: "D1", acuity: 3 },
  { date: "8/28/2019 16:30:10", shift: "C2", acuity: 3 },
  { date: "8/28/2019 16:33:21", shift: "B2", acuity: 6 },
  { date: "8/28/2019 16:40:52", shift: "A2", acuity: 3 },
  { date: "8/28/2019 17:15:06", shift: "C2", acuity: 6 },
  { date: "8/28/2019 17:49:50", shift: "C2", acuity: 6 },
  { date: "8/28/2019 17:55:59", shift: "D1", acuity: 3 },
  { date: "8/28/2019 18:11:59", shift: "D2", acuity: 6 },
  { date: "8/28/2019 18:12:24", shift: "B2", acuity: 3 },
  { date: "8/28/2019 18:12:54", shift: "A2", acuity: 6 },
  { date: "8/28/2019 18:13:14", shift: "C2", acuity: 3 },
  { date: "8/28/2019 18:31:09", shift: "D2", acuity: 2 },
  { date: "8/28/2019 19:01:51", shift: "D2", acuity: 6 },
  { date: "8/28/2019 19:06:38", shift: "B2", acuity: 3 },
  { date: "8/28/2019 19:14:05", shift: "A2", acuity: 4 },
  { date: "8/28/2019 19:14:27", shift: "C2", acuity: 3 },
  { date: "8/28/2019 19:28:44", shift: "D2", acuity: 3 },
  { date: "8/28/2019 19:30:10", shift: "B2", acuity: 2 },
  { date: "8/28/2019 19:38:41", shift: "A2", acuity: 4 },
  { date: "8/28/2019 19:44:27", shift: "C2", acuity: 3 },
  { date: "8/28/2019 19:49:41", shift: "D2", acuity: 3 },
  { date: "8/28/2019 19:57:53", shift: "B2", acuity: 3 },
  { date: "8/28/2019 20:03:42", shift: "A2", acuity: 3 },
  { date: "8/28/2019 20:09:41", shift: "C2", acuity: 3 },
  { date: "8/28/2019 20:13:37", shift: "D2", acuity: 3 },
  { date: "8/28/2019 20:13:52", shift: "B2", acuity: 3 },
  { date: "8/28/2019 20:46:02", shift: "A2", acuity: 4 },
  { date: "8/28/2019 20:46:14", shift: "C2", acuity: 4 },
  { date: "8/28/2019 20:51:20", shift: "D2", acuity: 6 },
  { date: "8/28/2019 20:52:32", shift: "B2", acuity: 6 },
  { date: "8/28/2019 21:18:57", shift: "A3", acuity: 3 },
  { date: "8/28/2019 21:19:14", shift: "C2", acuity: 3 },
  { date: "8/28/2019 21:27:06", shift: "D2", acuity: 4 },
  { date: "8/28/2019 21:33:03", shift: "A3", acuity: 3 },
  { date: "8/28/2019 21:53:34", shift: "A3", acuity: 3 },
  { date: "8/28/2019 21:55:59", shift: "B2", acuity: 6 },
  { date: "8/28/2019 22:17:47", shift: "B3", acuity: 3 },
  { date: "8/28/2019 22:18:01", shift: "C2", acuity: 3 },
  { date: "8/28/2019 22:25:42", shift: "D2", acuity: 4 },
  { date: "8/28/2019 22:29:33", shift: "A3", acuity: 6 },
  { date: "8/28/2019 22:48:25", shift: "B3", acuity: 3 },
  { date: "8/28/2019 23:02:59", shift: "B3", acuity: 3 },
  { date: "8/28/2019 23:06:41", shift: "C2", acuity: 4 },
  { date: "8/28/2019 23:08:12", shift: "D2", acuity: 3 },
  { date: "8/28/2019 23:12:21", shift: "A3", acuity: 3 },
  { date: "8/28/2019 23:19:18", shift: "B3", acuity: 4 },
  { date: "8/28/2019 23:19:31", shift: "C2", acuity: 6 },
  { date: "8/28/2019 23:27:46", shift: "D2", acuity: 3 },
  { date: "8/28/2019 23:44:09", shift: "A3", acuity: 3 },
  { date: "8/28/2019 23:44:25", shift: "B3", acuity: 4 },
  { date: "8/28/2019 23:50:43", shift: "C2", acuity: 6 },
  { date: "8/29/2019 00:05:59", shift: "D2", acuity: 6 },
  { date: "8/29/2019 00:06:28", shift: "A3", acuity: 3 },
  { date: "8/29/2019 01:03:46", shift: "B3", acuity: 3 },
  { date: "8/29/2019 01:04:10", shift: "D2", acuity: 6 },
  { date: "8/29/2019 01:04:49", shift: "A3", acuity: 6 },
  { date: "8/29/2019 01:15:00", shift: "B3", acuity: 2 },
  { date: "8/29/2019 01:22:13", shift: "D2", acuity: 6 },
  { date: "8/29/2019 01:38:57", shift: "A3", acuity: 4 },
  { date: "8/29/2019 02:25:36", shift: "B3", acuity: 3 },
  { date: "8/29/2019 02:25:46", shift: "A3", acuity: 6 },
  { date: "8/29/2019 02:57:15", shift: "B3", acuity: 4 },
  { date: "8/29/2019 03:48:18", shift: "A3", acuity: 6 },
  { date: "8/29/2019 03:50:36", shift: "B3", acuity: 3 },
  { date: "8/29/2019 04:15:40", shift: "A3", acuity: 3 },
  { date: "8/29/2019 04:43:38", shift: "B3", acuity: 3 },
  { date: "8/29/2019 05:11:44", shift: "A1", acuity: 3 },
  { date: "8/29/2019 06:05:23", shift: "A1", acuity: 6 },
  { date: "8/29/2019 06:35:00", shift: "A1", acuity: 6 },
  { date: "8/29/2019 07:12:34", shift: "B1", acuity: 6 },
  { date: "8/29/2019 07:37:12", shift: "B1", acuity: 3 },
  { date: "8/29/2019 07:37:41", shift: "B1", acuity: 3 },
  { date: "8/29/2019 07:44:48", shift: "A1", acuity: 3 },
  { date: "8/29/2019 07:53:31", shift: "B1", acuity: 3 },
  { date: "8/29/2019 08:05:55", shift: "A1", acuity: 3 },
  { date: "8/29/2019 08:11:44", shift: "B1", acuity: 3 },
  { date: "8/29/2019 08:19:24", shift: "A1", acuity: 3 },
  { date: "8/29/2019 08:24:04", shift: "B1", acuity: 3 },
  { date: "8/29/2019 08:31:23", shift: "C1", acuity: 6 },
  { date: "8/29/2019 08:36:27", shift: "A1", acuity: 3 },
  { date: "8/29/2019 08:40:53", shift: "B1", acuity: 3 },
  { date: "8/29/2019 08:51:21", shift: "C1", acuity: 6 },
  { date: "8/29/2019 09:01:24", shift: "C1", acuity: 6 },
  { date: "8/29/2019 09:06:16", shift: "C1", acuity: 6 },
  { date: "8/29/2019 09:06:48", shift: "A1", acuity: 6 },
  { date: "8/29/2019 09:16:04", shift: "B1", acuity: 4 },
  { date: "8/29/2019 09:16:23", shift: "C1", acuity: 6 },
  { date: "8/29/2019 09:36:09", shift: "A1", acuity: 3 },
  { date: "8/29/2019 09:56:14", shift: "B1", acuity: 3 },
  { date: "8/29/2019 09:56:31", shift: "C1", acuity: 4 },
  { date: "8/29/2019 10:07:03", shift: "D1", acuity: 6 },
  { date: "8/29/2019 10:20:29", shift: "A1", acuity: 3 },
  { date: "8/29/2019 10:20:56", shift: "B1", acuity: 6 },
  { date: "8/29/2019 10:24:10", shift: "C1", acuity: 3 },
  { date: "8/29/2019 10:32:22", shift: "D1", acuity: 3 },
  { date: "8/29/2019 10:48:42", shift: "D1", acuity: 6 },
  { date: "8/29/2019 10:53:16", shift: "A1", acuity: 6 },
  { date: "8/29/2019 11:05:07", shift: "B1", acuity: 6 },
  { date: "8/29/2019 11:08:53", shift: "C1", acuity: 3 },
  { date: "8/29/2019 11:18:56", shift: "D1", acuity: 6 },
  { date: "8/29/2019 11:23:54", shift: "A1", acuity: 3 },
  { date: "8/29/2019 11:24:31", shift: "B1", acuity: 6 },
  { date: "8/29/2019 11:52:08", shift: "C1", acuity: 6 },
  { date: "8/29/2019 11:58:43", shift: "D1", acuity: 3 },
  { date: "8/29/2019 11:59:11", shift: "A1", acuity: 4 },
  { date: "8/29/2019 12:11:44", shift: "B1", acuity: 6 },
  { date: "8/29/2019 12:14:38", shift: "C1", acuity: 6 },
  { date: "8/29/2019 12:27:24", shift: "D1", acuity: 3 },
  { date: "8/29/2019 12:50:03", shift: "A1", acuity: 6 },
  { date: "8/29/2019 12:54:27", shift: "B1", acuity: 6 },
  { date: "8/29/2019 13:13:00", shift: "A2", acuity: 6 },
  { date: "8/29/2019 13:25:16", shift: "C1", acuity: 6 },
  { date: "8/29/2019 13:25:59", shift: "D1", acuity: 3 },
  { date: "8/29/2019 13:26:19", shift: "B1", acuity: 6 },
  { date: "8/29/2019 13:29:11", shift: "A2", acuity: 6 },
  { date: "8/29/2019 13:39:42", shift: "A2", acuity: 3 },
  { date: "8/29/2019 13:48:16", shift: "A2", acuity: 4 },
  { date: "8/29/2019 13:49:07", shift: "C1", acuity: 3 },
  { date: "8/29/2019 13:49:24", shift: "D1", acuity: 3 },
  { date: "8/29/2019 14:08:10", shift: "B2", acuity: 6 },
  { date: "8/29/2019 14:13:49", shift: "A2", acuity: 6 },
  { date: "8/29/2019 14:21:05", shift: "C1", acuity: 3 },
  { date: "8/29/2019 14:31:29", shift: "B2", acuity: 3 },
  { date: "8/29/2019 14:31:59", shift: "B2", acuity: 4 },
  { date: "8/29/2019 14:32:22", shift: "D1", acuity: 3 },
  { date: "8/29/2019 14:49:19", shift: "A2", acuity: 3 },
  { date: "8/29/2019 14:56:36", shift: "C1", acuity: 6 },
  { date: "8/29/2019 15:01:24", shift: "B2", acuity: 6 },
  { date: "8/29/2019 15:11:34", shift: "D1", acuity: 6 },
  { date: "8/29/2019 15:12:21", shift: "A2", acuity: 6 },
  { date: "8/29/2019 15:19:02", shift: "C1", acuity: 3 },
  { date: "8/29/2019 15:19:32", shift: "B2", acuity: 3 },
  { date: "8/29/2019 15:30:11", shift: "D1", acuity: 6 },
  { date: "8/29/2019 15:43:34", shift: "A2", acuity: 6 },
  { date: "8/29/2019 15:56:11", shift: "C1", acuity: 3 },
  { date: "8/29/2019 15:56:31", shift: "B2", acuity: 3 },
  { date: "8/29/2019 16:03:59", shift: "D1", acuity: 3 },
  { date: "8/29/2019 16:04:17", shift: "A2", acuity: 3 },
  { date: "8/29/2019 16:20:30", shift: "C1", acuity: 6 },
  { date: "8/29/2019 16:20:53", shift: "B2", acuity: 6 },
  { date: "8/29/2019 16:32:32", shift: "C2", acuity: 3 },
  { date: "8/29/2019 16:35:32", shift: "D1", acuity: 6 },
  { date: "8/29/2019 16:35:52", shift: "A2", acuity: 3 },
  { date: "8/29/2019 16:51:16", shift: "B2", acuity: 3 },
  { date: "8/29/2019 16:57:49", shift: "C2", acuity: 3 },
  { date: "8/29/2019 17:04:48", shift: "C2", acuity: 3 },
  { date: "8/29/2019 17:05:08", shift: "C2", acuity: 3 },
  { date: "8/29/2019 17:05:28", shift: "D1", acuity: 4 },
  { date: "8/29/2019 17:13:05", shift: "A2", acuity: 2 },
  { date: "8/29/2019 17:16:52", shift: "B2", acuity: 6 },
  { date: "8/29/2019 17:41:12", shift: "C2", acuity: 5 },
  { date: "8/29/2019 17:46:38", shift: "D1", acuity: 6 },
  { date: "8/29/2019 17:47:16", shift: "A2", acuity: 4 },
  { date: "8/29/2019 18:02:41", shift: "D2", acuity: 3 },
  { date: "8/29/2019 18:11:04", shift: "B2", acuity: 4 },
  { date: "8/29/2019 18:15:45", shift: "C2", acuity: 3 },
  { date: "8/29/2019 18:23:02", shift: "A2", acuity: 4 },
  { date: "8/29/2019 18:29:56", shift: "D2", acuity: 3 },
  { date: "8/29/2019 18:34:59", shift: "D2", acuity: 6 },
  { date: "8/29/2019 19:04:20", shift: "D2", acuity: 3 },
  { date: "8/29/2019 19:04:39", shift: "B2", acuity: 3 },
  { date: "8/29/2019 19:14:48", shift: "C2", acuity: 3 },
  { date: "8/29/2019 19:35:10", shift: "A2", acuity: 7 },
  { date: "8/29/2019 19:35:35", shift: "D2", acuity: 3 },
  { date: "8/29/2019 19:40:54", shift: "B2", acuity: 6 },
  { date: "8/29/2019 19:59:58", shift: "C2", acuity: 6 },
  { date: "8/29/2019 20:25:22", shift: "A2", acuity: 3 },
  { date: "8/29/2019 20:29:25", shift: "D2", acuity: 6 },
  { date: "8/29/2019 20:39:13", shift: "B2", acuity: 3 },
  { date: "8/29/2019 20:43:16", shift: "C2", acuity: 6 },
  { date: "8/29/2019 20:44:11", shift: "A2", acuity: 3 },
  { date: "8/29/2019 21:08:47", shift: "A3", acuity: 3 },
  { date: "8/29/2019 21:12:08", shift: "D2", acuity: 3 },
  { date: "8/29/2019 21:16:29", shift: "B2", acuity: 3 },
  { date: "8/29/2019 21:25:54", shift: "C2", acuity: 4 },
  { date: "8/29/2019 21:40:33", shift: "A3", acuity: 3 },
  { date: "8/29/2019 21:41:35", shift: "A3", acuity: 3 },
  { date: "8/29/2019 22:02:40", shift: "B3", acuity: 3 },
  { date: "8/29/2019 22:03:18", shift: "D2", acuity: 4 },
  { date: "8/29/2019 22:04:35", shift: "C2", acuity: 3 },
  { date: "8/29/2019 22:14:54", shift: "A3", acuity: 6 },
  { date: "8/29/2019 22:31:00", shift: "B3", acuity: 2 },
  { date: "8/29/2019 22:49:52", shift: "B3", acuity: 6 },
  { date: "8/29/2019 23:10:13", shift: "D2", acuity: 3 },
  { date: "8/29/2019 23:22:28", shift: "C2", acuity: 3 },
  { date: "8/29/2019 23:22:50", shift: "A3", acuity: 3 },
  { date: "8/29/2019 23:23:52", shift: "B3", acuity: 6 },
  { date: "8/29/2019 23:42:04", shift: "D2", acuity: 6 },
  { date: "8/29/2019 23:43:12", shift: "C2", acuity: 3 },
  { date: "8/29/2019 23:49:20", shift: "A3", acuity: 3 },
  { date: "8/29/2019 23:58:48", shift: "B3", acuity: 6 },
  { date: "8/30/2019 00:20:55", shift: "D2", acuity: 2 },
  { date: "8/30/2019 00:24:25", shift: "A3", acuity: 3 },
  { date: "8/30/2019 00:24:42", shift: "B3", acuity: 3 },
  { date: "8/30/2019 00:47:26", shift: "D2", acuity: 3 },
  { date: "8/30/2019 00:49:10", shift: "A3", acuity: 3 },
  { date: "8/30/2019 00:53:13", shift: "B3", acuity: 4 },
  { date: "8/30/2019 01:07:53", shift: "D2", acuity: 3 },
  { date: "8/30/2019 01:41:15", shift: "A3", acuity: 4 },
  { date: "8/30/2019 01:41:44", shift: "B3", acuity: 3 },
  { date: "8/30/2019 01:45:49", shift: "A3", acuity: 4 },
  { date: "8/30/2019 01:46:07", shift: "B3", acuity: 4 },
  { date: "8/30/2019 02:30:18", shift: "A3", acuity: 3 },
  { date: "8/30/2019 02:41:09", shift: "B3", acuity: 3 },
  { date: "8/30/2019 03:20:56", shift: "A3", acuity: 3 },
  { date: "8/30/2019 03:23:43", shift: "B3", acuity: 4 },
  { date: "8/30/2019 03:51:32", shift: "A3", acuity: 3 },
  { date: "8/30/2019 04:07:12", shift: "B3", acuity: 6 },
  { date: "8/30/2019 04:12:50", shift: "A3", acuity: 3 },
  { date: "8/30/2019 04:26:32", shift: "B3", acuity: 3 },
  { date: "8/30/2019 05:16:34", shift: "A1", acuity: 3 },
  { date: "8/30/2019 05:27:35", shift: "B3", acuity: 3 },
  { date: "8/30/2019 05:52:55", shift: "A1", acuity: 2 },
  { date: "8/30/2019 06:12:41", shift: "A1", acuity: 6 },
  { date: "8/30/2019 06:14:04", shift: "B1", acuity: 3 },
  { date: "8/30/2019 06:36:00", shift: "B1", acuity: 4 },
  { date: "8/30/2019 06:52:35", shift: "B1", acuity: 3 },
  { date: "8/30/2019 07:22:49", shift: "A1", acuity: 6 },
  { date: "8/30/2019 07:26:38", shift: "B1", acuity: 5 },
  { date: "8/30/2019 07:36:00", shift: "A1", acuity: 3 },
  { date: "8/30/2019 07:46:16", shift: "B1", acuity: 6 },
  { date: "8/30/2019 08:00:26", shift: "A1", acuity: 4 },
  { date: "8/30/2019 08:03:34", shift: "B1", acuity: 3 },
  { date: "8/30/2019 08:20:38", shift: "A1", acuity: 6 },
  { date: "8/30/2019 08:40:15", shift: "C1", acuity: 4 },
  { date: "8/30/2019 09:04:00", shift: "C1", acuity: 6 },
  { date: "8/30/2019 09:07:15", shift: "C1", acuity: 4 },
  { date: "8/30/2019 09:17:34", shift: "B1", acuity: 6 },
  { date: "8/30/2019 09:17:40", shift: "A1", acuity: 5 },
  { date: "8/30/2019 09:21:10", shift: "C1", acuity: 3 },
  { date: "8/30/2019 09:31:09", shift: "B1", acuity: 6 },
  { date: "8/30/2019 09:50:09", shift: "A1", acuity: 3 },
  { date: "8/30/2019 10:04:53", shift: "D1", acuity: 6 },
  { date: "8/30/2019 10:15:33", shift: "C1", acuity: 6 },
  { date: "8/30/2019 10:20:29", shift: "B1", acuity: 6 },
  { date: "8/30/2019 10:34:41", shift: "D1", acuity: 6 },
  { date: "8/30/2019 10:41:09", shift: "D1", acuity: 6 },
  { date: "8/30/2019 10:45:49", shift: "A1", acuity: 6 },
  { date: "8/30/2019 11:12:38", shift: "C1", acuity: 3 },
  { date: "8/30/2019 11:21:00", shift: "B1", acuity: 6 },
  { date: "8/30/2019 11:21:16", shift: "D1", acuity: 3 },
  { date: "8/30/2019 11:26:28", shift: "A1", acuity: 3 },
  { date: "8/30/2019 11:26:44", shift: "C1", acuity: 3 },
  { date: "8/30/2019 11:49:29", shift: "B1", acuity: 3 },
  { date: "8/30/2019 12:08:19", shift: "D1", acuity: 3 },
  { date: "8/30/2019 12:29:05", shift: "A1", acuity: 3 },
  { date: "8/30/2019 12:31:55", shift: "C1", acuity: 6 },
  { date: "8/30/2019 12:35:32", shift: "B1", acuity: 3 },
  { date: "8/30/2019 12:51:29", shift: "D1", acuity: 3 },
  { date: "8/30/2019 13:01:39", shift: "A2", acuity: 3 },
  { date: "8/30/2019 13:01:49", shift: "C1", acuity: 3 },
  { date: "8/30/2019 13:30:10", shift: "A2", acuity: 6 },
  { date: "8/30/2019 13:54:53", shift: "A2", acuity: 6 },
  { date: "8/30/2019 14:09:42", shift: "B2", acuity: 3 },
  { date: "8/30/2019 14:34:04", shift: "B2", acuity: 3 },
  { date: "8/30/2019 14:52:40", shift: "B2", acuity: 6 },
  { date: "8/30/2019 14:58:34", shift: "D1", acuity: 6 },
  { date: "8/30/2019 15:05:39", shift: "C1", acuity: 3 },
  { date: "8/30/2019 15:33:23", shift: "A2", acuity: 6 },
  { date: "8/30/2019 15:45:07", shift: "B2", acuity: 6 },
  { date: "8/30/2019 15:45:24", shift: "D1", acuity: 3 },
  { date: "8/30/2019 16:03:40", shift: "C1", acuity: 3 },
  { date: "8/30/2019 16:03:50", shift: "A2", acuity: 6 },
  { date: "8/30/2019 16:04:02", shift: "B2", acuity: 3 },
  { date: "8/30/2019 16:09:37", shift: "D1", acuity: 3 },
  { date: "8/30/2019 16:20:10", shift: "C1", acuity: 6 },
  { date: "8/30/2019 16:24:48", shift: "A2", acuity: 3 },
  { date: "8/30/2019 16:47:55", shift: "C2", acuity: 3 },
  { date: "8/30/2019 16:48:07", shift: "B2", acuity: 3 },
  { date: "8/30/2019 16:52:17", shift: "D1", acuity: 3 },
  { date: "8/30/2019 17:00:12", shift: "C2", acuity: 3 },
  { date: "8/30/2019 17:05:32", shift: "C2", acuity: 7 },
  { date: "8/30/2019 17:25:49", shift: "A2", acuity: 3 },
  { date: "8/30/2019 17:25:58", shift: "B2", acuity: 6 },
  { date: "8/30/2019 17:26:09", shift: "D1", acuity: 3 },
  { date: "8/30/2019 17:40:39", shift: "C2", acuity: 3 },
  { date: "8/30/2019 18:00:59", shift: "D2", acuity: 6 },
  { date: "8/30/2019 18:05:02", shift: "A2", acuity: 6 },
  { date: "8/30/2019 18:07:34", shift: "B2", acuity: 3 },
  { date: "8/30/2019 18:32:17", shift: "D2", acuity: 6 },
  { date: "8/30/2019 18:33:05", shift: "D2", acuity: 4 },
  { date: "8/30/2019 18:35:12", shift: "C2", acuity: 3 },
  { date: "8/30/2019 19:06:43", shift: "A2", acuity: 6 },
  { date: "8/30/2019 19:16:31", shift: "B2", acuity: 6 },
  { date: "8/30/2019 19:16:43", shift: "D2", acuity: 6 },
  { date: "8/30/2019 19:47:06", shift: "C2", acuity: 3 },
  { date: "8/30/2019 19:51:50", shift: "A2", acuity: 3 },
  { date: "8/30/2019 20:06:17", shift: "B2", acuity: 3 },
  { date: "8/30/2019 20:11:15", shift: "D2", acuity: 3 },
  { date: "8/30/2019 20:15:10", shift: "C2", acuity: 3 },
  { date: "8/30/2019 20:26:21", shift: "A2", acuity: 3 },
  { date: "8/30/2019 20:33:18", shift: "B2", acuity: 4 },
  { date: "8/30/2019 20:33:45", shift: "D2", acuity: 6 },
  { date: "8/30/2019 20:35:20", shift: "C2", acuity: 6 },
  { date: "8/30/2019 20:53:26", shift: "A2", acuity: 6 },
  { date: "8/30/2019 21:16:42", shift: "A3", acuity: 3 },
  { date: "8/30/2019 21:22:50", shift: "B2", acuity: 3 },
  { date: "8/30/2019 21:24:12", shift: "D2", acuity: 6 },
  { date: "8/30/2019 21:27:32", shift: "C2", acuity: 3 },
  { date: "8/30/2019 21:30:00", shift: "A3", acuity: 2 },
  { date: "8/30/2019 21:39:28", shift: "A3", acuity: 3 },
  { date: "8/30/2019 21:50:21", shift: "B2", acuity: 3 },
  { date: "8/30/2019 21:50:38", shift: "D2", acuity: 6 },
  { date: "8/30/2019 22:00:19", shift: "B3", acuity: 3 },
  { date: "8/30/2019 22:12:29", shift: "C2", acuity: 3 },
  { date: "8/30/2019 22:19:12", shift: "A3", acuity: 3 },
  { date: "8/30/2019 22:19:53", shift: "D2", acuity: 3 },
  { date: "8/30/2019 22:33:21", shift: "B3", acuity: 6 },
  { date: "8/30/2019 22:36:14", shift: "B3", acuity: 6 },
  { date: "8/30/2019 22:49:54", shift: "C2", acuity: 3 },
  { date: "8/30/2019 22:50:10", shift: "A3", acuity: 3 },
  { date: "8/30/2019 22:51:14", shift: "D2", acuity: 6 },
  { date: "8/30/2019 22:59:31", shift: "B3", acuity: 3 },
  { date: "8/30/2019 23:27:16", shift: "C2", acuity: 3 },
  { date: "8/30/2019 23:28:43", shift: "A3", acuity: 3 },
  { date: "8/30/2019 23:47:38", shift: "D2", acuity: 3 },
  { date: "8/31/2019 00:03:49", shift: "B3", acuity: 4 },
  { date: "8/31/2019 00:15:32", shift: "A3", acuity: 3 },
  { date: "8/31/2019 00:26:47", shift: "D2", acuity: 3 },
  { date: "8/31/2019 00:41:06", shift: "B3", acuity: 6 },
  { date: "8/31/2019 01:00:46", shift: "A3", acuity: 3 },
  { date: "8/31/2019 01:18:05", shift: "D2", acuity: 3 },
  { date: "8/31/2019 01:27:05", shift: "B3", acuity: 3 },
  { date: "8/31/2019 01:28:49", shift: "A3", acuity: 3 },
  { date: "8/31/2019 01:50:24", shift: "B3", acuity: 4 },
  { date: "8/31/2019 01:55:43", shift: "A3", acuity: 3 },
  { date: "8/31/2019 01:58:57", shift: "B3", acuity: 3 },
  { date: "8/31/2019 02:26:17", shift: "A3", acuity: 6 },
  { date: "8/31/2019 02:26:52", shift: "B3", acuity: 3 },
  { date: "8/31/2019 02:27:25", shift: "A3", acuity: 3 },
  { date: "8/31/2019 03:08:37", shift: "B3", acuity: 6 },
  { date: "8/31/2019 03:13:17", shift: "A3", acuity: 3 },
  { date: "8/31/2019 03:20:02", shift: "B3", acuity: 4 },
  { date: "8/31/2019 03:23:45", shift: "A3", acuity: 3 },
  { date: "8/31/2019 03:28:40", shift: "B3", acuity: 6 },
  { date: "8/31/2019 03:55:14", shift: "A3", acuity: 3 },
  { date: "8/31/2019 04:27:55", shift: "B3", acuity: 4 },
  { date: "8/31/2019 04:28:14", shift: "A3", acuity: 5 },
  { date: "8/31/2019 05:02:32", shift: "A1", acuity: 4 },
  { date: "8/31/2019 05:36:00", shift: "A1", acuity: 4 },
  { date: "8/31/2019 05:56:17", shift: "A1", acuity: 3 },
  { date: "8/31/2019 06:07:33", shift: "B1", acuity: 6 },
  { date: "8/31/2019 06:16:39", shift: "A1", acuity: 3 },
  { date: "8/31/2019 06:25:30", shift: "B1", acuity: 6 },
  { date: "8/31/2019 06:37:34", shift: "B1", acuity: 3 },
  { date: "8/31/2019 07:09:51", shift: "B1", acuity: 3 },
  { date: "8/31/2019 07:20:36", shift: "A1", acuity: 4 },
  { date: "8/31/2019 07:20:50", shift: "B1", acuity: 4 },
  { date: "8/31/2019 07:21:05", shift: "A1", acuity: 3 },
  { date: "8/31/2019 07:37:02", shift: "B1", acuity: 3 },
  { date: "8/31/2019 08:00:00", shift: "A1", acuity: 3 },
  { date: "8/31/2019 08:02:42", shift: "B1", acuity: 3 },
  { date: "8/31/2019 08:39:18", shift: "C1", acuity: 6 },
  { date: "8/31/2019 08:46:00", shift: "A1", acuity: 3 },
  { date: "8/31/2019 09:21:28", shift: "C1", acuity: 4 },
  { date: "8/31/2019 09:26:32", shift: "C1", acuity: 3 },
  { date: "8/31/2019 09:26:51", shift: "B1", acuity: 4 },
  { date: "8/31/2019 09:27:12", shift: "A1", acuity: 5 },
  { date: "8/31/2019 09:31:39", shift: "C1", acuity: 3 },
  { date: "8/31/2019 09:45:55", shift: "B1", acuity: 3 },
  { date: "8/31/2019 09:53:24", shift: "A1", acuity: 3 },
  { date: "8/31/2019 09:56:00", shift: "C1", acuity: 3 },
  { date: "8/31/2019 09:59:01", shift: "B1", acuity: 7 },
  { date: "8/31/2019 10:03:41", shift: "D1", acuity: 4 },
  { date: "8/31/2019 10:12:38", shift: "A1", acuity: 4 },
  { date: "8/31/2019 10:18:43", shift: "C1", acuity: 7 },
  { date: "8/31/2019 10:20:42", shift: "B1", acuity: 3 },
  { date: "8/31/2019 10:43:56", shift: "D1", acuity: 3 },
  { date: "8/31/2019 10:52:09", shift: "D1", acuity: 6 },
  { date: "8/31/2019 10:52:32", shift: "A1", acuity: 3 },
  { date: "8/31/2019 10:58:07", shift: "C1", acuity: 7 },
  { date: "8/31/2019 10:59:43", shift: "B1", acuity: 6 },
  { date: "8/31/2019 11:15:46", shift: "D1", acuity: 3 },
  { date: "8/31/2019 11:25:13", shift: "A1", acuity: 3 },
  { date: "8/31/2019 11:25:37", shift: "C1", acuity: 6 },
  { date: "8/31/2019 11:27:41", shift: "B1", acuity: 2 },
  { date: "8/31/2019 11:42:25", shift: "D1", acuity: 7 },
  { date: "8/31/2019 11:45:58", shift: "A1", acuity: 3 },
  { date: "8/31/2019 11:59:58", shift: "C1", acuity: 6 },
  { date: "8/31/2019 12:09:32", shift: "B1", acuity: 6 },
  { date: "8/31/2019 12:13:42", shift: "D1", acuity: 6 },
  { date: "8/31/2019 12:15:00", shift: "A1", acuity: 7 },
  { date: "8/31/2019 12:15:20", shift: "C1", acuity: 7 },
  { date: "8/31/2019 12:31:50", shift: "B1", acuity: 6 },
  { date: "8/31/2019 12:57:40", shift: "D1", acuity: 3 },
  { date: "8/31/2019 12:57:59", shift: "A1", acuity: 3 },
  { date: "8/31/2019 12:58:12", shift: "C1", acuity: 3 },
  { date: "8/31/2019 13:03:23", shift: "A2", acuity: 3 },
  { date: "8/31/2019 13:12:43", shift: "B1", acuity: 6 },
  { date: "8/31/2019 13:18:23", shift: "D1", acuity: 6 },
  { date: "8/31/2019 13:27:21", shift: "C1", acuity: 6 },
  { date: "8/31/2019 13:46:36", shift: "A2", acuity: 6 },
  { date: "8/31/2019 14:25:51", shift: "A2", acuity: 3 },
  { date: "8/31/2019 14:26:07", shift: "B2", acuity: 3 },
  { date: "8/31/2019 14:27:09", shift: "D1", acuity: 6 },
  { date: "8/31/2019 15:00:29", shift: "B2", acuity: 3 },
  { date: "8/31/2019 15:00:48", shift: "B2", acuity: 3 },
  { date: "8/31/2019 15:10:57", shift: "C1", acuity: 6 },
  { date: "8/31/2019 15:11:17", shift: "A2", acuity: 3 },
  { date: "8/31/2019 15:39:16", shift: "D1", acuity: 3 },
  { date: "8/31/2019 15:47:58", shift: "B2", acuity: 6 },
  { date: "8/31/2019 16:00:52", shift: "C1", acuity: 6 },
  { date: "8/31/2019 16:01:11", shift: "A2", acuity: 6 },
  { date: "8/31/2019 16:06:38", shift: "D1", acuity: 3 },
  { date: "8/31/2019 16:12:45", shift: "B2", acuity: 3 },
  { date: "8/31/2019 16:15:18", shift: "C1", acuity: 3 },
  { date: "8/31/2019 16:18:12", shift: "A2", acuity: 3 },
  { date: "8/31/2019 16:29:17", shift: "D1", acuity: 3 },
  { date: "8/31/2019 16:31:00", shift: "C2", acuity: 3 },
  { date: "8/31/2019 16:42:37", shift: "B2", acuity: 7 },
  { date: "8/31/2019 16:47:48", shift: "A2", acuity: 7 },
  { date: "8/31/2019 16:56:54", shift: "D1", acuity: 6 },
  { date: "8/31/2019 17:00:00", shift: "C2", acuity: 7 },
  { date: "8/31/2019 17:01:00", shift: "C2", acuity: 3 },
  { date: "8/31/2019 17:30:24", shift: "B2", acuity: 6 },
  { date: "8/31/2019 17:31:17", shift: "A2", acuity: 3 },
  { date: "8/31/2019 17:39:49", shift: "D1", acuity: 3 },
  { date: "8/31/2019 17:43:33", shift: "C2", acuity: 3 },
  { date: "8/31/2019 17:54:02", shift: "B2", acuity: 3 },
  { date: "8/31/2019 17:55:58", shift: "A2", acuity: 3 },
  { date: "8/31/2019 18:05:09", shift: "D2", acuity: 3 },
  { date: "8/31/2019 18:17:06", shift: "C2", acuity: 3 },
  { date: "8/31/2019 18:35:59", shift: "D2", acuity: 3 },
  { date: "8/31/2019 18:36:20", shift: "D2", acuity: 4 },
  { date: "8/31/2019 18:36:43", shift: "B2", acuity: 3 },
  { date: "8/31/2019 18:45:15", shift: "A2", acuity: 6 },
  { date: "8/31/2019 18:57:57", shift: "C2", acuity: 3 },
  { date: "8/31/2019 19:14:33", shift: "D2", acuity: 6 },
  { date: "8/31/2019 19:41:54", shift: "B2", acuity: 6 },
  { date: "8/31/2019 19:42:22", shift: "A2", acuity: 6 },
  { date: "8/31/2019 19:52:11", shift: "C2", acuity: 3 },
  { date: "8/31/2019 19:59:40", shift: "D2", acuity: 6 },
  { date: "8/31/2019 20:20:42", shift: "B2", acuity: 3 },
  { date: "8/31/2019 20:32:46", shift: "A2", acuity: 3 },
  { date: "8/31/2019 20:45:59", shift: "C2", acuity: 4 },
  { date: "8/31/2019 20:46:27", shift: "D2", acuity: 6 },
  { date: "8/31/2019 20:59:22", shift: "B2", acuity: 4 },
  { date: "8/31/2019 21:12:56", shift: "A3", acuity: 3 },
  { date: "8/31/2019 21:14:47", shift: "C2", acuity: 3 },
  { date: "8/31/2019 21:15:23", shift: "D2", acuity: 4 },
  { date: "8/31/2019 21:22:41", shift: "B2", acuity: 3 },
  { date: "8/31/2019 21:32:55", shift: "A3", acuity: 6 },
  { date: "8/31/2019 21:35:10", shift: "A3", acuity: 3 },
  { date: "8/31/2019 21:37:41", shift: "C2", acuity: 3 },
  { date: "8/31/2019 21:57:00", shift: "D2", acuity: 2 },
  { date: "8/31/2019 21:58:24", shift: "B2", acuity: 3 },
  { date: "8/31/2019 22:07:05", shift: "B3", acuity: 3 },
  { date: "8/31/2019 22:42:49", shift: "B3", acuity: 3 },
  { date: "8/31/2019 22:53:11", shift: "B3", acuity: 3 },
  { date: "8/31/2019 23:01:51", shift: "A3", acuity: 5 },
  { date: "8/31/2019 23:07:23", shift: "C2", acuity: 4 },
  { date: "8/31/2019 23:10:33", shift: "D2", acuity: 6 },
  { date: "8/31/2019 23:36:23", shift: "B3", acuity: 4 },
  { date: "8/31/2019 23:36:47", shift: "A3", acuity: 3 },
  { date: "8/31/2019 23:37:10", shift: "C2", acuity: 3 },
  { date: "8/31/2019 23:38:32", shift: "D2", acuity: 3 },
  { date: "8/31/2019 23:48:17", shift: "B3", acuity: 3 },
  { date: "9/1/2019 00:02:23", shift: "A3", acuity: 3 },
  { date: "9/1/2019 00:08:47", shift: "D2", acuity: 3 },
  { date: "9/1/2019 00:20:04", shift: "B3", acuity: 6 },
  { date: "9/1/2019 00:34:47", shift: "A3", acuity: 3 },
  { date: "9/1/2019 00:37:59", shift: "D2", acuity: 6 },
  { date: "9/1/2019 01:06:04", shift: "B3", acuity: 3 },
  { date: "9/1/2019 01:15:04", shift: "A3", acuity: 3 },
  { date: "9/1/2019 01:29:26", shift: "D2", acuity: 3 },
  { date: "9/1/2019 01:29:58", shift: "B3", acuity: 4 },
  { date: "9/1/2019 01:33:11", shift: "A3", acuity: 3 },
  { date: "9/1/2019 02:00:26", shift: "B3", acuity: 5 },
  { date: "9/1/2019 02:11:33", shift: "A3", acuity: 3 },
  { date: "9/1/2019 02:17:27", shift: "B3", acuity: 6 },
  { date: "9/1/2019 02:58:46", shift: "A3", acuity: 2 },
  { date: "9/1/2019 02:59:32", shift: "B3", acuity: 3 },
  { date: "9/1/2019 02:59:40", shift: "A3", acuity: 3 },
  { date: "9/1/2019 03:12:54", shift: "B3", acuity: 4 },
  { date: "9/1/2019 03:14:04", shift: "A3", acuity: 4 },
  { date: "9/1/2019 04:14:54", shift: "B3", acuity: 3 },
  { date: "9/1/2019 04:38:02", shift: "A3", acuity: 3 },
  { date: "9/1/2019 04:38:20", shift: "B3", acuity: 3 },
  { date: "9/1/2019 05:16:15", shift: "A1", acuity: 3 },
  { date: "9/1/2019 05:19:40", shift: "B3", acuity: 3 },
  { date: "9/1/2019 05:36:51", shift: "A1", acuity: 3 },
  { date: "9/1/2019 06:00:36", shift: "A1", acuity: 6 },
  { date: "9/1/2019 06:33:11", shift: "B1", acuity: 3 },
  { date: "9/1/2019 06:53:49", shift: "B1", acuity: 6 },
  { date: "9/1/2019 07:11:08", shift: "B1", acuity: 3 },
  { date: "9/1/2019 07:41:29", shift: "A1", acuity: 6 },
  { date: "9/1/2019 07:53:51", shift: "B1", acuity: 3 },
  { date: "9/1/2019 08:14:36", shift: "A1", acuity: 3 },
  { date: "9/1/2019 08:18:09", shift: "B1", acuity: 6 },
  { date: "9/1/2019 08:49:19", shift: "C1", acuity: 6 },
  { date: "9/1/2019 08:51:45", shift: "A1", acuity: 3 },
  { date: "9/1/2019 09:16:07", shift: "C1", acuity: 3 },
  { date: "9/1/2019 09:17:10", shift: "C1", acuity: 3 },
  { date: "9/1/2019 09:31:08", shift: "B1", acuity: 6 },
  { date: "9/1/2019 09:31:33", shift: "A1", acuity: 6 },
  { date: "9/1/2019 09:41:44", shift: "C1", acuity: 3 },
  { date: "9/1/2019 09:41:54", shift: "B1", acuity: 3 },
  { date: "9/1/2019 10:14:17", shift: "D1", acuity: 4 },
  { date: "9/1/2019 10:16:20", shift: "A1", acuity: 4 },
  { date: "9/1/2019 10:16:28", shift: "C1", acuity: 4 },
  { date: "9/1/2019 10:38:17", shift: "D1", acuity: 2 },
  { date: "9/1/2019 10:46:55", shift: "D1", acuity: 6 },
  { date: "9/1/2019 10:50:35", shift: "B1", acuity: 3 },
  { date: "9/1/2019 10:50:53", shift: "A1", acuity: 3 },
  { date: "9/1/2019 10:51:25", shift: "C1", acuity: 6 },
  { date: "9/1/2019 11:09:22", shift: "D1", acuity: 6 },
  { date: "9/1/2019 11:13:24", shift: "B1", acuity: 2 },
  { date: "9/1/2019 11:36:15", shift: "A1", acuity: 3 },
  { date: "9/1/2019 11:37:39", shift: "C1", acuity: 6 },
  { date: "9/1/2019 11:41:52", shift: "D1", acuity: 2 },
  { date: "9/1/2019 12:18:43", shift: "B1", acuity: 3 },
  { date: "9/1/2019 12:19:13", shift: "A1", acuity: 6 },
  { date: "9/1/2019 12:19:42", shift: "C1", acuity: 6 },
  { date: "9/1/2019 12:21:29", shift: "D1", acuity: 3 },
  { date: "9/1/2019 12:49:04", shift: "B1", acuity: 3 },
  { date: "9/1/2019 13:01:18", shift: "A2", acuity: 3 },
  { date: "9/1/2019 13:08:51", shift: "C1", acuity: 3 },
  { date: "9/1/2019 13:14:51", shift: "D1", acuity: 6 },
  { date: "9/1/2019 13:30:15", shift: "A2", acuity: 3 },
  { date: "9/1/2019 13:38:16", shift: "A2", acuity: 3 },
  { date: "9/1/2019 13:58:04", shift: "B1", acuity: 6 },
  { date: "9/1/2019 13:58:36", shift: "C1", acuity: 3 },
  { date: "9/1/2019 14:01:06", shift: "B2", acuity: 4 },
  { date: "9/1/2019 14:04:51", shift: "D1", acuity: 3 },
  { date: "9/1/2019 14:28:30", shift: "A2", acuity: 4 },
  { date: "9/1/2019 14:28:46", shift: "C1", acuity: 6 },
  { date: "9/1/2019 14:30:26", shift: "B2", acuity: 6 },
  { date: "9/1/2019 14:31:00", shift: "B2", acuity: 3 },
  { date: "9/1/2019 14:48:03", shift: "D1", acuity: 6 },
  { date: "9/1/2019 14:57:21", shift: "A2", acuity: 6 },
  { date: "9/1/2019 15:23:32", shift: "C1", acuity: 3 },
  { date: "9/1/2019 15:26:24", shift: "B2", acuity: 3 },
  { date: "9/1/2019 15:27:23", shift: "D1", acuity: 3 },
  { date: "9/1/2019 15:28:06", shift: "A2", acuity: 3 },
  { date: "9/1/2019 15:28:18", shift: "C1", acuity: 3 },
  { date: "9/1/2019 15:46:50", shift: "B2", acuity: 3 },
  { date: "9/1/2019 15:56:54", shift: "D1", acuity: 6 },
  { date: "9/1/2019 16:17:19", shift: "A2", acuity: 4 },
  { date: "9/1/2019 16:22:52", shift: "C1", acuity: 3 },
  { date: "9/1/2019 16:41:45", shift: "C2", acuity: 6 },
  { date: "9/1/2019 17:07:37", shift: "C2", acuity: 6 },
  { date: "9/1/2019 17:08:07", shift: "C2", acuity: 3 },
  { date: "9/1/2019 17:25:17", shift: "B2", acuity: 4 },
  { date: "9/1/2019 17:25:38", shift: "D1", acuity: 3 },
  { date: "9/1/2019 17:49:31", shift: "A2", acuity: 3 },
  { date: "9/1/2019 17:58:19", shift: "C2", acuity: 3 },
  { date: "9/1/2019 18:11:11", shift: "D2", acuity: 3 },
  { date: "9/1/2019 18:21:34", shift: "B2", acuity: 3 },
  { date: "9/1/2019 18:22:02", shift: "A2", acuity: 6 },
  { date: "9/1/2019 18:23:09", shift: "C2", acuity: 6 },
  { date: "9/1/2019 18:34:42", shift: "D2", acuity: 3 },
  { date: "9/1/2019 18:53:05", shift: "D2", acuity: 3 },
  { date: "9/1/2019 18:53:26", shift: "B2", acuity: 2 },
  { date: "9/1/2019 19:17:25", shift: "A2", acuity: 3 },
  { date: "9/1/2019 19:17:40", shift: "C2", acuity: 6 },
  { date: "9/1/2019 19:17:57", shift: "D2", acuity: 6 },
  { date: "9/1/2019 19:21:39", shift: "B2", acuity: 3 },
  { date: "9/1/2019 19:22:41", shift: "A2", acuity: 6 },
  { date: "9/1/2019 19:50:08", shift: "C2", acuity: 6 },
  { date: "9/1/2019 20:04:31", shift: "D2", acuity: 6 },
  { date: "9/1/2019 20:05:11", shift: "B2", acuity: 3 },
  { date: "9/1/2019 20:16:40", shift: "A2", acuity: 3 },
  { date: "9/1/2019 20:23:32", shift: "C2", acuity: 6 },
  { date: "9/1/2019 20:27:24", shift: "D2", acuity: 6 },
  { date: "9/1/2019 20:52:42", shift: "B2", acuity: 3 },
  { date: "9/1/2019 20:52:58", shift: "A2", acuity: 6 },
  { date: "9/1/2019 21:00:53", shift: "A3", acuity: 3 },
  { date: "9/1/2019 21:08:38", shift: "C2", acuity: 6 },
  { date: "9/1/2019 21:18:26", shift: "D2", acuity: 6 },
  { date: "9/1/2019 21:19:09", shift: "B2", acuity: 6 },
  { date: "9/1/2019 21:19:22", shift: "A3", acuity: 6 },
  { date: "9/1/2019 21:23:41", shift: "C2", acuity: 3 },
  { date: "9/1/2019 21:35:05", shift: "A3", acuity: 3 },
  { date: "9/1/2019 21:42:23", shift: "A3", acuity: 6 },
  { date: "9/1/2019 21:43:15", shift: "D2", acuity: 6 },
  { date: "9/1/2019 22:01:47", shift: "B3", acuity: 4 },
  { date: "9/1/2019 22:02:00", shift: "C2", acuity: 6 },
  { date: "9/1/2019 22:16:15", shift: "A3", acuity: 3 },
  { date: "9/1/2019 22:32:52", shift: "B3", acuity: 6 },
  { date: "9/1/2019 22:33:23", shift: "B3", acuity: 6 },
  { date: "9/1/2019 22:38:27", shift: "D2", acuity: 6 },
  { date: "9/1/2019 22:48:47", shift: "C2", acuity: 4 },
  { date: "9/1/2019 22:53:31", shift: "A3", acuity: 6 },
  { date: "9/1/2019 23:05:54", shift: "B3", acuity: 6 },
  { date: "9/1/2019 23:17:20", shift: "D2", acuity: 3 },
  { date: "9/1/2019 23:23:07", shift: "C2", acuity: 3 },
  { date: "9/1/2019 23:26:11", shift: "A3", acuity: 3 },
  { date: "9/1/2019 23:42:19", shift: "B3", acuity: 3 },
  { date: "9/2/2019 00:00:14", shift: "D2", acuity: 3 },
  { date: "9/2/2019 00:00:24", shift: "A3", acuity: 4 },
  { date: "9/2/2019 00:00:30", shift: "B3", acuity: 3 },
  { date: "9/2/2019 00:04:27", shift: "D2", acuity: 3 },
  { date: "9/2/2019 00:06:38", shift: "A3", acuity: 3 },
  { date: "9/2/2019 00:24:46", shift: "B3", acuity: 3 },
  { date: "9/2/2019 00:25:07", shift: "D2", acuity: 3 },
  { date: "9/2/2019 00:32:01", shift: "A3", acuity: 3 },
  { date: "9/2/2019 00:39:54", shift: "B3", acuity: 3 },
  { date: "9/2/2019 01:00:12", shift: "D2", acuity: 3 },
  { date: "9/2/2019 01:02:23", shift: "A3", acuity: 3 },
  { date: "9/2/2019 01:10:30", shift: "B3", acuity: 6 },
  { date: "9/2/2019 01:10:48", shift: "D2", acuity: 4 },
  { date: "9/2/2019 01:16:36", shift: "A3", acuity: 3 },
  { date: "9/2/2019 01:20:07", shift: "B3", acuity: 4 },
  { date: "9/2/2019 01:33:27", shift: "A3", acuity: 3 },
  { date: "9/2/2019 01:49:58", shift: "B3", acuity: 3 },
  { date: "9/2/2019 02:16:12", shift: "A3", acuity: 3 },
  { date: "9/2/2019 02:16:28", shift: "B3", acuity: 6 },
  { date: "9/2/2019 02:16:41", shift: "A3", acuity: 3 },
  { date: "9/2/2019 02:21:01", shift: "B3", acuity: 3 },
  { date: "9/2/2019 02:31:55", shift: "A3", acuity: 4 },
  { date: "9/2/2019 02:42:58", shift: "B3", acuity: 5 },
  { date: "9/2/2019 03:00:05", shift: "A3", acuity: 3 },
  { date: "9/2/2019 03:07:55", shift: "B3", acuity: 3 },
  { date: "9/2/2019 03:08:17", shift: "A3", acuity: 6 },
  { date: "9/2/2019 03:11:12", shift: "B3", acuity: 3 },
  { date: "9/2/2019 03:12:57", shift: "A3", acuity: 4 },
  { date: "9/2/2019 03:17:30", shift: "B3", acuity: 5 },
  { date: "9/2/2019 03:49:08", shift: "A3", acuity: 3 },
  { date: "9/2/2019 03:50:13", shift: "B3", acuity: 6 },
  { date: "9/2/2019 03:56:51", shift: "A3", acuity: 4 },
  { date: "9/2/2019 04:37:53", shift: "B3", acuity: 3 },
  { date: "9/2/2019 04:46:00", shift: "A3", acuity: 3 },
  { date: "9/2/2019 05:20:57", shift: "A1", acuity: 3 },
  { date: "9/2/2019 05:21:10", shift: "B3", acuity: 3 },
  { date: "9/2/2019 05:30:28", shift: "A1", acuity: 6 },
  { date: "9/2/2019 05:55:45", shift: "A1", acuity: 3 },
  { date: "9/2/2019 06:05:47", shift: "B1", acuity: 3 },
  { date: "9/2/2019 06:10:27", shift: "A1", acuity: 3 },
  { date: "9/2/2019 06:30:15", shift: "B1", acuity: 3 },
  { date: "9/2/2019 06:30:21", shift: "B1", acuity: 4 },
  { date: "9/2/2019 06:38:43", shift: "A1", acuity: 3 },
  { date: "9/2/2019 07:10:52", shift: "B1", acuity: 3 },
  { date: "9/2/2019 07:11:25", shift: "A1", acuity: 3 },
  { date: "9/2/2019 07:14:26", shift: "B1", acuity: 3 },
  { date: "9/2/2019 07:23:41", shift: "A1", acuity: 3 },
  { date: "9/2/2019 07:42:11", shift: "B1", acuity: 6 },
  { date: "9/2/2019 08:21:56", shift: "A1", acuity: 3 },
  { date: "9/2/2019 08:29:48", shift: "B1", acuity: 3 },
  { date: "9/2/2019 08:37:50", shift: "C1", acuity: 6 },
  { date: "9/2/2019 08:49:32", shift: "A1", acuity: 6 },
  { date: "9/2/2019 08:56:35", shift: "B1", acuity: 6 },
  { date: "9/2/2019 09:06:51", shift: "C1", acuity: 3 },
  { date: "9/2/2019 09:11:22", shift: "C1", acuity: 3 },
  { date: "9/2/2019 09:12:32", shift: "A1", acuity: 6 },
  { date: "9/2/2019 09:28:48", shift: "B1", acuity: 3 },
  { date: "9/2/2019 09:42:35", shift: "C1", acuity: 3 },
  { date: "9/2/2019 09:43:12", shift: "A1", acuity: 3 },
  { date: "9/2/2019 09:45:59", shift: "B1", acuity: 3 },
  { date: "9/2/2019 09:52:39", shift: "C1", acuity: 6 },
  { date: "9/2/2019 10:00:26", shift: "D1", acuity: 3 },
  { date: "9/2/2019 10:11:49", shift: "A1", acuity: 3 },
  { date: "9/2/2019 10:33:16", shift: "D1", acuity: 3 },
  { date: "9/2/2019 10:34:40", shift: "D1", acuity: 4 },
  { date: "9/2/2019 10:39:46", shift: "B1", acuity: 3 },
  { date: "9/2/2019 10:51:31", shift: "C1", acuity: 6 },
  { date: "9/2/2019 10:59:57", shift: "A1", acuity: 3 },
  { date: "9/2/2019 11:02:28", shift: "D1", acuity: 6 },
  { date: "9/2/2019 11:19:23", shift: "B1", acuity: 6 },
  { date: "9/2/2019 11:40:15", shift: "C1", acuity: 3 },
  { date: "9/2/2019 11:44:28", shift: "A1", acuity: 3 },
  { date: "9/2/2019 12:01:55", shift: "D1", acuity: 3 },
  { date: "9/2/2019 12:13:24", shift: "B1", acuity: 3 },
  { date: "9/2/2019 12:26:04", shift: "C1", acuity: 3 },
  { date: "9/2/2019 12:27:03", shift: "A1", acuity: 4 },
  { date: "9/2/2019 12:27:38", shift: "D1", acuity: 3 },
  { date: "9/2/2019 12:36:33", shift: "B1", acuity: 3 },
  { date: "9/2/2019 12:37:06", shift: "C1", acuity: 6 },
  { date: "9/2/2019 12:48:45", shift: "A1", acuity: 3 },
  { date: "9/2/2019 12:56:28", shift: "D1", acuity: 3 },
  { date: "9/2/2019 13:04:31", shift: "A2", acuity: 6 },
  { date: "9/2/2019 13:15:47", shift: "B1", acuity: 3 },
  { date: "9/2/2019 13:28:56", shift: "C1", acuity: 3 },
  { date: "9/2/2019 13:39:57", shift: "A2", acuity: 6 },
  { date: "9/2/2019 13:40:23", shift: "A2", acuity: 4 },
  { date: "9/2/2019 13:41:12", shift: "D1", acuity: 6 },
  { date: "9/2/2019 13:43:51", shift: "B1", acuity: 3 },
  { date: "9/2/2019 13:59:16", shift: "C1", acuity: 3 },
  { date: "9/2/2019 14:14:07", shift: "B2", acuity: 6 },
  { date: "9/2/2019 14:16:11", shift: "A2", acuity: 3 },
  { date: "9/2/2019 14:18:30", shift: "D1", acuity: 6 },
  { date: "9/2/2019 14:30:12", shift: "B2", acuity: 2 },
  { date: "9/2/2019 14:30:49", shift: "B2", acuity: 6 },
  { date: "9/2/2019 14:49:51", shift: "C1", acuity: 4 },
  { date: "9/2/2019 14:50:01", shift: "A2", acuity: 6 },
  { date: "9/2/2019 14:53:57", shift: "D1", acuity: 3 },
  { date: "9/2/2019 14:54:06", shift: "B2", acuity: 3 },
  { date: "9/2/2019 15:09:45", shift: "C1", acuity: 4 },
  { date: "9/2/2019 15:13:12", shift: "A2", acuity: 6 },
  { date: "9/2/2019 15:14:48", shift: "D1", acuity: 3 },
  { date: "9/2/2019 15:41:59", shift: "B2", acuity: 2 },
  { date: "9/2/2019 15:55:03", shift: "C1", acuity: 3 },
  { date: "9/2/2019 15:59:01", shift: "A2", acuity: 3 },
  { date: "9/2/2019 16:04:13", shift: "D1", acuity: 3 },
  { date: "9/2/2019 16:05:19", shift: "B2", acuity: 3 },
  { date: "9/2/2019 16:13:39", shift: "C1", acuity: 6 },
  { date: "9/2/2019 16:24:41", shift: "A2", acuity: 3 },
  { date: "9/2/2019 16:46:10", shift: "C2", acuity: 3 },
  { date: "9/2/2019 16:55:13", shift: "D1", acuity: 3 },
  { date: "9/2/2019 17:09:21", shift: "C2", acuity: 3 },
  { date: "9/2/2019 17:14:00", shift: "C2", acuity: 4 },
  { date: "9/2/2019 17:18:18", shift: "B2", acuity: 3 },
  { date: "9/2/2019 17:38:33", shift: "A2", acuity: 3 },
  { date: "9/2/2019 17:50:58", shift: "D1", acuity: 3 },
  { date: "9/2/2019 17:51:14", shift: "C2", acuity: 3 },
  { date: "9/2/2019 18:06:11", shift: "D2", acuity: 3 },
  { date: "9/2/2019 18:11:02", shift: "B2", acuity: 3 },
  { date: "9/2/2019 18:11:56", shift: "A2", acuity: 3 },
  { date: "9/2/2019 18:19:25", shift: "C2", acuity: 3 },
  { date: "9/2/2019 18:20:00", shift: "D2", acuity: 3 },
  { date: "9/2/2019 18:22:53", shift: "B2", acuity: 6 },
  { date: "9/2/2019 18:28:30", shift: "A2", acuity: 3 },
  { date: "9/2/2019 18:37:30", shift: "D2", acuity: 3 },
  { date: "9/2/2019 18:45:50", shift: "D2", acuity: 6 },
  { date: "9/2/2019 18:53:50", shift: "C2", acuity: 3 },
  { date: "9/2/2019 19:08:39", shift: "B2", acuity: 6 },
  { date: "9/2/2019 19:14:12", shift: "A2", acuity: 6 },
  { date: "9/2/2019 19:16:01", shift: "D2", acuity: 3 },
  { date: "9/2/2019 19:22:39", shift: "C2", acuity: 3 },
  { date: "9/2/2019 19:52:40", shift: "B2", acuity: 3 },
  { date: "9/2/2019 19:53:21", shift: "A2", acuity: 4 },
  { date: "9/2/2019 19:55:28", shift: "D2", acuity: 6 },
  { date: "9/2/2019 20:19:24", shift: "C2", acuity: 6 },
  { date: "9/2/2019 20:30:52", shift: "B2", acuity: 3 },
  { date: "9/2/2019 20:31:18", shift: "A2", acuity: 3 },
  { date: "9/2/2019 20:41:54", shift: "D2", acuity: 3 },
  { date: "9/2/2019 20:46:48", shift: "C2", acuity: 4 },
  { date: "9/2/2019 20:47:08", shift: "B2", acuity: 6 },
  { date: "9/2/2019 20:52:24", shift: "A2", acuity: 3 },
  { date: "9/2/2019 20:59:32", shift: "D2", acuity: 3 },
  { date: "9/2/2019 21:41:22", shift: "A3", acuity: 3 },
  { date: "9/2/2019 21:41:42", shift: "A3", acuity: 6 },
  { date: "9/2/2019 21:48:22", shift: "A3", acuity: 6 },
  { date: "9/2/2019 22:07:29", shift: "B3", acuity: 3 },
  { date: "9/2/2019 22:07:43", shift: "C2", acuity: 3 },
  { date: "9/2/2019 22:12:29", shift: "D2", acuity: 3 },
  { date: "9/2/2019 22:30:17", shift: "B3", acuity: 3 },
  { date: "9/2/2019 22:36:19", shift: "B3", acuity: 6 },
  { date: "9/2/2019 22:36:38", shift: "A3", acuity: 6 },
  { date: "9/2/2019 22:45:49", shift: "C2", acuity: 6 },
  { date: "9/2/2019 22:54:37", shift: "D2", acuity: 6 },
  { date: "9/2/2019 22:54:50", shift: "B3", acuity: 3 },
  { date: "9/2/2019 23:20:00", shift: "A3", acuity: 3 },
  { date: "9/2/2019 23:28:00", shift: "C2", acuity: 4 },
  { date: "9/2/2019 23:39:48", shift: "D2", acuity: 3 },
  { date: "9/2/2019 23:44:11", shift: "B3", acuity: 4 },
  { date: "9/2/2019 23:44:27", shift: "A3", acuity: 3 },
  { date: "9/2/2019 23:53:59", shift: "C2", acuity: 3 },
  { date: "9/2/2019 23:54:13", shift: "D2", acuity: 3 },
  { date: "9/2/2019 23:58:20", shift: "B3", acuity: 4 },
  { date: "9/3/2019 00:05:51", shift: "A3", acuity: 6 },
  { date: "9/3/2019 00:25:44", shift: "D2", acuity: 6 },
  { date: "9/3/2019 00:31:38", shift: "B3", acuity: 3 },
  { date: "9/3/2019 00:58:38", shift: "A3", acuity: 3 },
  { date: "9/3/2019 01:08:10", shift: "D2", acuity: 4 },
  { date: "9/3/2019 01:15:33", shift: "B3", acuity: 4 },
  { date: "9/3/2019 01:26:07", shift: "A3", acuity: 6 },
  { date: "9/3/2019 02:12:24", shift: "B3", acuity: 6 },
  { date: "9/3/2019 02:34:28", shift: "A3", acuity: 6 },
  { date: "9/3/2019 02:34:43", shift: "B3", acuity: 6 },
  { date: "9/3/2019 02:35:12", shift: "A3", acuity: 4 },
  { date: "9/3/2019 02:35:43", shift: "B3", acuity: 6 },
  { date: "9/3/2019 02:36:06", shift: "A3", acuity: 4 },
  { date: "9/3/2019 02:40:36", shift: "B3", acuity: 3 },
  { date: "9/3/2019 02:41:39", shift: "A3", acuity: 6 },
  { date: "9/3/2019 02:57:51", shift: "B3", acuity: 3 },
  { date: "9/3/2019 03:25:10", shift: "A3", acuity: 3 },
  { date: "9/3/2019 03:28:36", shift: "B3", acuity: 4 },
  { date: "9/3/2019 03:46:07", shift: "A3", acuity: 3 },
  { date: "9/3/2019 04:14:20", shift: "B3", acuity: 3 },
  { date: "9/3/2019 04:21:09", shift: "A3", acuity: 3 },
  { date: "9/3/2019 05:19:28", shift: "A1", acuity: 3 },
  { date: "9/3/2019 05:36:01", shift: "A1", acuity: 3 },
  { date: "9/3/2019 05:45:09", shift: "A1", acuity: 4 },
  { date: "9/3/2019 06:01:23", shift: "B1", acuity: 6 },
  { date: "9/3/2019 06:29:25", shift: "A1", acuity: 3 },
  { date: "9/3/2019 06:29:56", shift: "B1", acuity: 4 },
  { date: "9/3/2019 06:43:30", shift: "B1", acuity: 4 },
  { date: "9/3/2019 06:47:28", shift: "B1", acuity: 3 },
  { date: "9/3/2019 07:49:31", shift: "A1", acuity: 3 },
  { date: "9/3/2019 08:04:49", shift: "B1", acuity: 3 },
  { date: "9/3/2019 08:08:47", shift: "A1", acuity: 3 },
  { date: "9/3/2019 08:19:27", shift: "B1", acuity: 3 },
  { date: "9/3/2019 09:00:32", shift: "C1", acuity: 3 },
  { date: "9/3/2019 09:18:28", shift: "C1", acuity: 3 },
  { date: "9/3/2019 09:19:47", shift: "C1", acuity: 6 },
  { date: "9/3/2019 09:38:25", shift: "A1", acuity: 6 },
  { date: "9/3/2019 09:45:51", shift: "B1", acuity: 6 },
  { date: "9/3/2019 09:47:50", shift: "C1", acuity: 3 },
  { date: "9/3/2019 09:48:25", shift: "A1", acuity: 3 },
  { date: "9/3/2019 10:00:39", shift: "D1", acuity: 3 },
  { date: "9/3/2019 10:02:36", shift: "B1", acuity: 3 },
  { date: "9/3/2019 10:09:32", shift: "C1", acuity: 6 },
  { date: "9/3/2019 10:30:25", shift: "D1", acuity: 3 },
  { date: "9/3/2019 10:33:58", shift: "D1", acuity: 4 },
  { date: "9/3/2019 10:35:16", shift: "A1", acuity: 6 },
  { date: "9/3/2019 10:36:02", shift: "B1", acuity: 6 },
  { date: "9/3/2019 10:57:34", shift: "C1", acuity: 6 },
  { date: "9/3/2019 10:57:42", shift: "D1", acuity: 3 },
  { date: "9/3/2019 11:02:19", shift: "A1", acuity: 6 },
  { date: "9/3/2019 11:03:46", shift: "B1", acuity: 6 },
  { date: "9/3/2019 11:18:18", shift: "C1", acuity: 4 },
  { date: "9/3/2019 11:26:37", shift: "D1", acuity: 3 },
  { date: "9/3/2019 11:36:37", shift: "A1", acuity: 3 },
  { date: "9/3/2019 11:38:44", shift: "B1", acuity: 3 },
  { date: "9/3/2019 11:59:30", shift: "C1", acuity: 6 },
  { date: "9/3/2019 12:14:59", shift: "D1", acuity: 6 },
  { date: "9/3/2019 12:20:03", shift: "A1", acuity: 6 },
  { date: "9/3/2019 12:32:26", shift: "B1", acuity: 3 },
  { date: "9/3/2019 12:39:24", shift: "C1", acuity: 3 },
  { date: "9/3/2019 12:44:13", shift: "D1", acuity: 3 },
  { date: "9/3/2019 12:50:57", shift: "A1", acuity: 6 },
  { date: "9/3/2019 12:54:34", shift: "B1", acuity: 6 },
  { date: "9/3/2019 13:02:24", shift: "A2", acuity: 6 },
  { date: "9/3/2019 13:09:41", shift: "C1", acuity: 6 },
  { date: "9/3/2019 13:20:42", shift: "D1", acuity: 3 },
  { date: "9/3/2019 13:50:43", shift: "A2", acuity: 3 },
  { date: "9/3/2019 14:06:18", shift: "A2", acuity: 3 },
  { date: "9/3/2019 14:23:56", shift: "B2", acuity: 3 },
  { date: "9/3/2019 14:24:55", shift: "C1", acuity: 3 },
  { date: "9/3/2019 14:30:55", shift: "B2", acuity: 3 },
  { date: "9/3/2019 14:46:33", shift: "B2", acuity: 3 },
  { date: "9/3/2019 15:22:26", shift: "D1", acuity: 6 },
  { date: "9/3/2019 15:35:35", shift: "A2", acuity: 3 },
  { date: "9/3/2019 15:36:28", shift: "C1", acuity: 3 },
  { date: "9/3/2019 15:36:54", shift: "B2", acuity: 3 },
  { date: "9/3/2019 15:38:50", shift: "D1", acuity: 3 },
  { date: "9/3/2019 15:40:11", shift: "A2", acuity: 3 },
  { date: "9/3/2019 15:44:00", shift: "C1", acuity: 3 },
  { date: "9/3/2019 15:50:50", shift: "B2", acuity: 3 },
  { date: "9/3/2019 16:01:10", shift: "D1", acuity: 3 },
  { date: "9/3/2019 16:01:22", shift: "A2", acuity: 3 },
  { date: "9/3/2019 16:25:41", shift: "C1", acuity: 3 },
  { date: "9/3/2019 16:32:51", shift: "C2", acuity: 3 },
  { date: "9/3/2019 16:33:10", shift: "B2", acuity: 6 },
  { date: "9/3/2019 16:37:54", shift: "D1", acuity: 3 },
  { date: "9/3/2019 16:44:26", shift: "A2", acuity: 3 },
  { date: "9/3/2019 17:06:07", shift: "C2", acuity: 3 },
  { date: "9/3/2019 17:07:43", shift: "C2", acuity: 3 },
  { date: "9/3/2019 17:15:33", shift: "B2", acuity: 3 },
  { date: "9/3/2019 17:20:26", shift: "D1", acuity: 6 },
  { date: "9/3/2019 17:20:52", shift: "A2", acuity: 3 },
  { date: "9/3/2019 17:34:21", shift: "C2", acuity: 6 },
  { date: "9/3/2019 17:41:01", shift: "B2", acuity: 6 },
  { date: "9/3/2019 17:41:23", shift: "D1", acuity: 3 },
  { date: "9/3/2019 17:53:32", shift: "A2", acuity: 3 },
  { date: "9/3/2019 18:16:27", shift: "D2", acuity: 6 },
  { date: "9/3/2019 18:16:52", shift: "C2", acuity: 3 },
  { date: "9/3/2019 18:25:04", shift: "B2", acuity: 3 },
  { date: "9/3/2019 18:49:50", shift: "D2", acuity: 6 },
  { date: "9/3/2019 18:54:42", shift: "D2", acuity: 3 },
  { date: "9/3/2019 19:07:27", shift: "A2", acuity: 3 },
  { date: "9/3/2019 19:17:14", shift: "C2", acuity: 6 },
  { date: "9/3/2019 19:32:05", shift: "B2", acuity: 3 },
  { date: "9/3/2019 19:33:45", shift: "D2", acuity: 6 },
  { date: "9/3/2019 19:40:29", shift: "A2", acuity: 3 },
  { date: "9/3/2019 19:51:06", shift: "C2", acuity: 6 },
  { date: "9/3/2019 19:54:55", shift: "B2", acuity: 6 },
  { date: "9/3/2019 19:58:36", shift: "D2", acuity: 3 },
  { date: "9/3/2019 20:20:57", shift: "A2", acuity: 4 },
  { date: "9/3/2019 20:27:18", shift: "C2", acuity: 4 },
  { date: "9/3/2019 20:47:47", shift: "B2", acuity: 6 },
  { date: "9/3/2019 20:56:30", shift: "D2", acuity: 4 },
  { date: "9/3/2019 20:56:46", shift: "A2", acuity: 6 },
  { date: "9/3/2019 20:58:09", shift: "C2", acuity: 3 },
  { date: "9/3/2019 21:08:55", shift: "A3", acuity: 3 },
  { date: "9/3/2019 21:09:17", shift: "B2", acuity: 6 },
  { date: "9/3/2019 21:25:03", shift: "D2", acuity: 3 },
  { date: "9/3/2019 21:46:58", shift: "A3", acuity: 3 },
  { date: "9/3/2019 22:05:01", shift: "A3", acuity: 6 },
  { date: "9/3/2019 22:28:53", shift: "B3", acuity: 3 },
  { date: "9/3/2019 22:30:48", shift: "B3", acuity: 4 },
  { date: "9/3/2019 22:40:43", shift: "B3", acuity: 3 },
  { date: "9/3/2019 22:45:36", shift: "C2", acuity: 3 },
  { date: "9/3/2019 22:46:09", shift: "D2", acuity: 3 },
  { date: "9/3/2019 22:57:40", shift: "A3", acuity: 3 },
  { date: "9/3/2019 23:06:18", shift: "B3", acuity: 3 },
  { date: "9/3/2019 23:08:06", shift: "C2", acuity: 6 },
  { date: "9/3/2019 23:19:32", shift: "D2", acuity: 3 },
  { date: "9/3/2019 23:29:11", shift: "A3", acuity: 4 },
  { date: "9/3/2019 23:34:56", shift: "B3", acuity: 3 },
  { date: "9/3/2019 23:37:39", shift: "C2", acuity: 3 },
  { date: "9/3/2019 23:59:19", shift: "D2", acuity: 3 },
  { date: "9/4/2019 00:39:18", shift: "A3", acuity: 3 },
  { date: "9/4/2019 00:39:30", shift: "B3", acuity: 3 },
  { date: "9/4/2019 00:51:53", shift: "D2", acuity: 3 },
  { date: "9/4/2019 01:03:01", shift: "A3", acuity: 3 },
  { date: "9/4/2019 01:07:53", shift: "B3", acuity: 3 },
  { date: "9/4/2019 01:16:05", shift: "D2", acuity: 6 },
  { date: "9/4/2019 01:16:19", shift: "A3", acuity: 6 },
  { date: "9/4/2019 01:22:09", shift: "B3", acuity: 4 },
  { date: "9/4/2019 01:22:21", shift: "D2", acuity: 3 },
  { date: "9/4/2019 01:41:34", shift: "A3", acuity: 3 },
  { date: "9/4/2019 02:01:27", shift: "B3", acuity: 4 },
  { date: "9/4/2019 02:26:47", shift: "A3", acuity: 3 },
  { date: "9/4/2019 02:33:27", shift: "B3", acuity: 3 },
  { date: "9/4/2019 02:45:16", shift: "A3", acuity: 6 },
  { date: "9/4/2019 02:50:51", shift: "B3", acuity: 2 },
  { date: "9/4/2019 02:55:21", shift: "A3", acuity: 3 },
  { date: "9/4/2019 03:25:41", shift: "B3", acuity: 6 },
  { date: "9/4/2019 03:47:28", shift: "A3", acuity: 3 },
  { date: "9/4/2019 04:03:57", shift: "B3", acuity: 4 },
  { date: "9/4/2019 05:02:02", shift: "A1", acuity: 3 },
  { date: "9/4/2019 05:02:16", shift: "B3", acuity: 4 },
  { date: "9/4/2019 05:20:06", shift: "A1", acuity: 6 },
  { date: "9/4/2019 06:11:12", shift: "A1", acuity: 3 },
  { date: "9/4/2019 06:37:58", shift: "A1", acuity: 4 },
  { date: "9/4/2019 07:24:29", shift: "B1", acuity: 3 },
  { date: "9/4/2019 07:31:05", shift: "B1", acuity: 3 },
  { date: "9/4/2019 08:04:08", shift: "A1", acuity: 3 },
  { date: "9/4/2019 08:19:53", shift: "B1", acuity: 4 },
  { date: "9/4/2019 08:20:12", shift: "A1", acuity: 4 },
  { date: "9/4/2019 08:21:46", shift: "B1", acuity: 4 },
  { date: "9/4/2019 08:27:00", shift: "A1", acuity: 4 },
  { date: "9/4/2019 08:29:42", shift: "B1", acuity: 6 },
  { date: "9/4/2019 08:32:20", shift: "C1", acuity: 3 },
  { date: "9/4/2019 08:40:00", shift: "A1", acuity: 3 },
  { date: "9/4/2019 08:41:42", shift: "B1", acuity: 6 },
  { date: "9/4/2019 08:42:44", shift: "C1", acuity: 3 },
  { date: "9/4/2019 09:11:00", shift: "C1", acuity: 3 },
  { date: "9/4/2019 09:19:37", shift: "C1", acuity: 6 },
  { date: "9/4/2019 09:20:03", shift: "A1", acuity: 4 },
  { date: "9/4/2019 09:48:05", shift: "B1", acuity: 3 },
  { date: "9/4/2019 09:48:54", shift: "C1", acuity: 3 },
  { date: "9/4/2019 09:49:12", shift: "A1", acuity: 3 },
  { date: "9/4/2019 09:51:44", shift: "B1", acuity: 3 },
  { date: "9/4/2019 09:51:59", shift: "C1", acuity: 5 },
  { date: "9/4/2019 10:04:56", shift: "D1", acuity: 3 },
  { date: "9/4/2019 10:15:06", shift: "A1", acuity: 6 },
  { date: "9/4/2019 10:18:04", shift: "B1", acuity: 3 },
  { date: "9/4/2019 10:20:11", shift: "C1", acuity: 3 },
  { date: "9/4/2019 10:47:59", shift: "D1", acuity: 7 },
  { date: "9/4/2019 11:00:08", shift: "D1", acuity: 6 },
  { date: "9/4/2019 11:00:26", shift: "A1", acuity: 3 },
  { date: "9/4/2019 11:01:35", shift: "B1", acuity: 3 },
  { date: "9/4/2019 11:07:05", shift: "C1", acuity: 3 },
  { date: "9/4/2019 11:09:00", shift: "D1", acuity: 6 },
  { date: "9/4/2019 11:10:40", shift: "A1", acuity: 3 },
  { date: "9/4/2019 11:11:39", shift: "B1", acuity: 3 },
  { date: "9/4/2019 11:20:25", shift: "C1", acuity: 6 },
  { date: "9/4/2019 11:23:27", shift: "D1", acuity: 3 },
  { date: "9/4/2019 11:24:01", shift: "A1", acuity: 3 },
  { date: "9/4/2019 11:51:24", shift: "B1", acuity: 3 },
  { date: "9/4/2019 11:57:15", shift: "C1", acuity: 3 },
  { date: "9/4/2019 11:57:39", shift: "D1", acuity: 3 },
];
