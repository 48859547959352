import React, { useEffect, useState, useContext } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  Grid,
  Collapse,
  Button,
  withStyles,
} from "@material-ui/core";

// import ScheduleIcon from "../images/schedule.png";
import ScheduleIcon from "@material-ui/icons/Today";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import TimelineIcon from "@material-ui/icons/Timeline";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import AccountIcon from "@material-ui/icons/AccountBox";
import ListAlt from "@material-ui/icons/ListAlt";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import PieChartIcon from "@material-ui/icons/PieChart";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import StorageIcon from "@material-ui/icons/Storage";

import { useThemeContext } from "../ThemeProviderHost";
import { useLayoutContext } from "./Layout";
import { useAuth0 } from "../react-auth0-spa";

import { useHasPermission, userHasPermission } from "../utils/utilities";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  innerContainer: {
    position: "relative",
    top: "88px",
    left: "16px",
  },
  headerButtonIcon: {
    fontSize: "2.5em",
    fontFamily: "Material Icons",
    color: theme.palette.text.primary,
  },
  headerButtonText: {
    marginTop: "-2px",
    marginLeft: "0.5rem",
    fontWeight: "500",
    fontFamily: "Kanit",
    textTransform: "uppercase",
    fontSize: "1.5rem",
    color: theme.palette.text.primary,
  },
  menuControlDiv: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    width: "310px",
  },
  menuControlIcon: {
    height: "48px",
    width: "48px",
    color: theme.palette.text.primary,
  },
  menuControlLabel: {
    fontWeight: "500",
    fontSize: "1.5em",
    fontFamily: "Montserrat",
    marginTop: "8px",
    marginLeft: "16px",
    textTransform: "none",
  },
  selectedMenuControlDiv: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    width: "310px",
  },
  selectedMenuControlIcon: {
    height: "48px",
    width: "48px",
    color: theme.palette.text.primary,
  },
  selectedMenuControlLabel: {
    fontWeight: "500",
    fontSize: "1.5em",
    fontFamily: "Montserrat",
    marginTop: "8px",
    marginLeft: "16px",
    textTransform: "none",
    color: theme.palette.text.primary,
  },
}));

export const MenuControls = (props) => {
  const [staffingOpen, setStaffingOpen] = useState(true);
  const [providerToolsOpen, setProviderToolsOpen] = useState(true);
  const [settingsOpen, setSettingsOpen] = useState(true);
  const { toggleMenuOpen, currentView } = useLayoutContext();
  const { toggleTheme, usingDarkMode } = useThemeContext();
  const classes = useStyles();
  const { isAuthorized, userInfo, logout } = useAuth0();

  const toggleStaffing = () => {
    setStaffingOpen(!staffingOpen);
  };

  const toggleProvider = () => {
    setProviderToolsOpen(!providerToolsOpen);
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const logoutClicked = () => {
    if (logout) {
      logout();
    }
  };

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <MenuControlButton
          icon={<HomeIcon className={classes.menuControlIcon} />}
          label="Home"
          selected={currentView === "Home"}
          onClick={toggleMenuOpen}
          component={RouterLink}
          to="/"
        />
        {isAuthorized && userHasPermission(userInfo, "scheduler") && (
          <HeaderGroup
            id="staffing"
            label="Staffing Tools"
            in={staffingOpen}
            onClick={toggleStaffing}
          >
            <MenuControlButton
              icon={<ScheduleIcon className={classes.menuControlIcon} />}
              label="Staffing Planner"
              selected={currentView === "Staffing"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/staffing"
            />
            <MenuControlButton
              icon={<LibraryBooksIcon className={classes.menuControlIcon} />}
              label="Schedule Home"
              selected={currentView === "Schedules"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/schedules"
            />
            <MenuControlButton
              icon={<TimelineIcon className={classes.menuControlIcon} />}
              label="Live Schedule Analysis"
              selected={currentView === "LiveAnalysis"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/liveAnalysis"
            />
            <MenuControlButton
              icon={<HistoryIcon className={classes.menuControlIcon} />}
              label="Assignment History"
              selected={currentView === "AssignmentHistory"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/assignmentHistory"
            />
            <MenuControlButton
              icon={<PieChartIcon className={classes.menuControlIcon} />}
              label="Simulate Schedules"
              selected={currentView === "Simulation"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/simulation"
            />
            <MenuControlButton
              icon={<StorageIcon className={classes.menuControlIcon} />}
              label="Dataset Home"
              selected={currentView === "Datasets"}
              onClick={toggleMenuOpen}
              component={RouterLink}
              to="/datasets"
            />
          </HeaderGroup>
        )}
        {isAuthorized &&
          (userHasPermission(userInfo, "provider") ||
            userHasPermission(userInfo, "coordinator")) && (
            <HeaderGroup
              id="provider"
              label="Provider Tools"
              in={providerToolsOpen}
              onClick={toggleProvider}
            >
              {userHasPermission(userInfo, "coordinator") && (
                <MenuControlButton
                  icon={
                    <LocalHospitalIcon className={classes.menuControlIcon} />
                  }
                  label="Patient Assignments"
                  selected={currentView === "Assignment"}
                  onClick={toggleMenuOpen}
                  component={RouterLink}
                  to="/assignment"
                />
              )}
              {userHasPermission(userInfo, "provider") && (
                <MenuControlButton
                  icon={<ListAlt className={classes.menuControlIcon} />}
                  label="Up Next"
                  selected={currentView === "UpNext"}
                  onClick={toggleMenuOpen}
                  component={RouterLink}
                  to="/upNext"
                />
              )}
            </HeaderGroup>
          )}
        <HeaderGroup
          id="settings"
          label="Settings"
          in={settingsOpen}
          onClick={toggleSettings}
        >
          <MenuControlButton
            icon={<AccountIcon className={classes.menuControlIcon} />}
            label="Your Account"
            selected={currentView === "Account"}
            onClick={toggleMenuOpen}
            component={RouterLink}
            to="/account"
          />
          {isAuthorized &&
            (userHasPermission(userInfo, "orgadmin") ||
              userHasPermission(userInfo, "sysadmin")) && (
              <MenuControlButton
                icon={<PeopleAltIcon className={classes.menuControlIcon} />}
                label="Manage Users"
                selected={currentView === "ManageUsers"}
                onClick={toggleMenuOpen}
                component={RouterLink}
                to="/manageUsers"
              />
            )}
          <MenuControlButton
            icon={<Brightness4Icon className={classes.menuControlIcon} />}
            label="Toggle Dark Mode"
            onClick={toggleTheme}
          />
        </HeaderGroup>
        <MenuControlButton
          icon={<LogoutIcon className={classes.menuControlIcon} />}
          label="Logout"
          onClick={logoutClicked}
        />
      </div>
    </div>
  );
};

export function HeaderGroup(props) {
  const classes = useStyles();
  const onClick = (event) => {
    event.preventDefault();
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <div>
      <button className="header-button" id={props.id} onClick={onClick}>
        <span id={props.id} className={classes.headerButtonIcon}>
          {props.in ? "expand_more" : "chevron_right"}
        </span>
        <span id={props.id} className={classes.headerButtonText}>
          {props.label}
        </span>
      </button>
      <Collapse in={props.in}>{props.children}</Collapse>
    </div>
  );
}

const MenuControlButton = (props) => {
  const { icon, label, selected, ...buttonProps } = props;
  const classes = useStyles();

  if (selected) {
    return (
      <Button {...buttonProps} disabled>
        <div className={classes.selectedMenuControlDiv}>
          <div className={classes.selectedMenuControlIcon}>{icon}</div>
          <div className={classes.selectedMenuControlLabel}>{label}</div>
        </div>
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps}>
        <div className={classes.menuControlDiv}>
          <div className={classes.menuControlIcon}>{icon}</div>
          <div className={classes.menuControlLabel}>{label}</div>
        </div>
      </Button>
    );
  }
};

export default MenuControls;
