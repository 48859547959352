import React from "react";

import { makeStyles, CircularProgress } from "@material-ui/core";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  circularProgress: {
    position: "absolute",
    top: "calc(50% - 64px)",
    left: "calc(50% - 64px)",
  },
}));

export const Loading = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <CircularProgress
        color="primary"
        size={128}
        className={classes.circularProgress}
      />
    </div>
  );
};

export default Loading;
