import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Typography,
  Grid,
  Collapse,
  IconButton,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import clsx from "clsx";
import { useLayoutContext } from "./Layout";

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    backgroundColor: theme.palette.text.primary,
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  headerText: {
    color: theme.palette.background.default,
    marginLeft: "16px",
  },
  stickyHeader: {
    top: "72px",
    position: "sticky",
  },
  width100: {
    width: "100%",
  },
  width100weird: {
    width: "calc(100% - 1px)",
  },
  stickyCollapse: {
    position: "sticky",
    background: theme.palette.background.default,
    zIndex: "101",
    top: "72px",
  },
  bottomLine: {
    borderBottom: "1px solid",
    borderColor: theme.palette.text.primary,
  },
  toggleCollapseButton: {
    width: "32px",
    height: "32px",
    marginLeft: "16px",
    marginRight: "-8px",
  },
  toggleCollapseIcon: {
    width: "32px",
    height: "32px",
    color: theme.palette.background.default,
  },
}));

export const Header = (props) => {
  const { leftAdornment, rightAdornment, sticky } = props;
  const classes = useStyles();
  const { portraitMode } = useLayoutContext();
  return (
    <Grid
      item
      className={clsx(
        classes.headerDiv,
        props.tweakWidth && !portraitMode && classes.width100weird,
        (!props.tweakWidth || portraitMode) && classes.width100,
        sticky && classes.stickyHeader
      )}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "0 0 auto" }}>
          {leftAdornment ? leftAdornment : <div />}
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <Typography
            variant="h5"
            className={classes.headerText}
            style={props.style}
          >
            {props.children}
          </Typography>
        </div>
        <div style={{ flex: "0 0 auto" }}>
          {rightAdornment ? rightAdornment : <div />}
        </div>
      </div>
    </Grid>
  );
};

export default Header;

export const CollapsableHeader = (props) => {
  const { title, sticky, startCollapsed, onCollapse } = props;
  const classes = useStyles();
  const { portraitMode } = useLayoutContext();
  const [collapsed, setCollapsed] = useState(startCollapsed);

  useEffect(() => {
    setCollapsed(collapsed);
  }, [startCollapsed]);

  const toggleCollapse = () => {
    if (onCollapse) {
      onCollapse(!collapsed);
    }
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={clsx(
        sticky && "sticky-collapse",
        sticky && classes.stickyCollapse,
        sticky && !collapsed && classes.bottomLine
      )}
    >
      <Grid
        item
        className={clsx(
          classes.headerDiv,
          props.tweakWidth && !portraitMode && classes.width100weird,
          (!props.tweakWidth || portraitMode) && classes.width100
        )}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <IconButton
            onClick={toggleCollapse}
            className={classes.toggleCollapseButton}
          >
            {collapsed ? (
              <ExpandLess className={classes.toggleCollapseIcon} />
            ) : (
              <ExpandMore className={classes.toggleCollapseIcon} />
            )}
          </IconButton>
          <Typography
            variant="h5"
            className={classes.headerText}
            style={props.style}
          >
            {title}
          </Typography>
        </div>
      </Grid>
      <Collapse in={!collapsed}>
        <div>{props.children}</div>
      </Collapse>
    </div>
  );
};
