import React from "react";

import { withStyles, Button } from "@material-ui/core";

export const LogoButton = withStyles({
  root: {
    background: "#F1592A",
    color: "#F1F2F6",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#C6380D",
    },
  },
})(Button);

export const ErrorButton = withStyles({
  root: {
    background: "#C31209",
    color: "#F1F2F6",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#920E07",
    },
  },
})(Button);

export const SuccessButton = withStyles({
  root: {
    background: "#379925",
    color: "#F1F2F6",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#2F8320",
    },
  },
})(Button);

export const InfoButton = withStyles({
  root: {
    background: "#4361EE",
    color: "#F1F2F6",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#1F44EA",
    },
  },
})(Button);

export const WarningButton = withStyles({
  root: {
    background: "#F5BB00",
    color: "#3A3A3C",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#CC9C00",
    },
  },
})(Button);
